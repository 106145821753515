<template>
  <div>
    <v-menu offset-y v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" :on="on" :attrs="attrs">
          <v-btn
            data-testid="showFormMoreMenuButton"
            v-bind="attrs"
            v-on="on"
            icon
            small
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </slot>
      </template>

      <v-list dense v-if="form">
        <RenameFormDialog
          :formUri="formUri"
          v-on:didRenameForm="didRenameForm"
        />
        <DeleteFormDialog
          :disabled="disableDelete"
          :formUri="formUri"
          v-on:didDeleteForm="didDeleteForm"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import DeleteFormDialog from '@/components/form/DeleteFormDialog.vue'
import RenameFormDialog from '@/components/form/RenameFormDialog.vue'
export default {
  props: {
    formUri: null,
    disableDelete: null
  },
  data() {
    return {
      menu: false,
      form: undefined
    }
  },
  watch: {
    menu: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) {
          return
        }
        this.loading = true
        try {
          this.form = await this.$store.dispatch('AGReadFormOperation', {
            formUri: this.formUri,
            cached: true
          })
        } finally {
          this.loading = false
        }
      }
    }
  },
  components: {
    DeleteFormDialog,
    RenameFormDialog
  },
  methods: {
    didDeleteForm() {
      this.$router.back()
    },
    didRenameForm() {
      this.menu = false
    }
  }
}
</script>

<style lang="css"></style>
