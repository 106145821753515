<template>
  <div>
    <component
      v-for="(dialog, index) in dialogs"
      :key="`dialog${index}`"
      :is="dialog.component"
      v-model="dialog.model"
      :disabled="dialog.disabled()"
      :spaceUri="spaceUri"
      :spaceName="spaceName"
    />
    <v-menu offset-x close-on-content-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          data-testid="showSpaceMoreMenuButton"
          v-bind="attrs"
          v-on="on"
          icon
          small
        >
        <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <MenuItem
          v-for="(dialog, index) in dialogs"
          :key="`menuItem${index}`"
          :disabled="dialog.disabled()"
          v-bind="dialog.menuItemProps"
          @click="dialog.model = true"
        />
        <MenuItem
          @click="setURLClipboard(spaceUri)"
          icon="mdi-content-copy"
          :text="$t('dialogs.copyEntryUri')"
          data-testid="copyspaceUri"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import RenameSpaceDialog from '@/components/space/RenameSpaceDialog.vue'
import ShareSpaceDialog from '@/components/space/ShareSpaceDialog.vue'
import CreateGridFromCsvDialog from '@/components/space/CreateGridFromCsvDialog.vue'
import MenuItem from '@/components/MenuItem.vue'
import SpaceIconPickerDialog from './SpaceIconPickerDialog.vue'

export default {
  name: 'SpaceMoreMenu',
  props: {
    spaceUri: null,
    spaceName: null,
    disableRename: null,
    disableShare: null,
    disableUploadCsv: null
  },
  data() {
    return {
      dialogs: [
        {
          model: false,
          disabled: () => this.disableRename,
          menuItemProps: {
            icon: 'mdi-pencil',
            text: this.$t('dialogs.renameSpaceButton'),
            'data-testid': 'renameSpaceActivator'
          },
          component: RenameSpaceDialog
        },
        {
          model: false,
          disabled: () => this.disableRename,
          menuItemProps: {
            icon: 'mdi-format-color-fill',
            text: this.$t('dialogs.spaceIcon.menuItem'),
            'data-testid': 'setSpaceIconActivator'
          },
          component: SpaceIconPickerDialog
        },
        {
          model: false,
          disabled: () => this.disableShare,
          menuItemProps: {
            icon: 'mdi-account-multiple-plus',
            text: this.$t('dialogs.shareSpaceButton'),
            'data-testid': 'shareSpaceDialogActivator'
          },
          component: ShareSpaceDialog
        },
        {
          model: false,
          disabled: () => this.disableUploadCsv,
          menuItemProps: {
            icon: 'mdi-table-arrow-left',
            text: this.$t('dialogs.gridFromCsvButton'),
            'data-testid': 'createGridFromCsvDialogActivator'
          },
          component: CreateGridFromCsvDialog
        }
      ]
    }
  },
  methods: {
    setURLClipboard(value) {
      navigator.clipboard.writeText(`${window.location.origin}${value}`)
    }
  },
  components: {
    MenuItem
  }
}
</script>
