import {EditorView} from 'prosemirror-view'
import {EditorState} from 'prosemirror-state'
import {schema, defaultMarkdownParser,
        defaultMarkdownSerializer} from 'prosemirror-markdown'
import {exampleSetup} from 'prosemirror-example-setup'

export default class MarkdownEditor {
  constructor(target, content, domEventHandlers) {
    this.view = new EditorView(target, {
      state: EditorState.create(this._initialStateConfig(content)),
      handleDOMEvents: domEventHandlers,
    })
  }

  _initialStateConfig(content) {
    return {
      doc: defaultMarkdownParser.parse(content),
      plugins: exampleSetup({schema})
    }
  }

  get content() {
    return defaultMarkdownSerializer.serialize(this.view.state.doc)
  }

  setContent(content) {
    const tr = this.view.state.tr.replaceWith(0, this.view.state.doc.content.size, defaultMarkdownParser.parse(content))
    this.view.dispatch( tr )
  }

  focus() { this.view.focus() }
  destroy() { this.view.destroy() }
}