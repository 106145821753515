import ApiClient from '@/ApiClient'

export default {
  state() {
    return {
      flows: []
    }
  },
  mutations: {
    setFlowList(state, flows) {
      state.flows = flows
    }
  },
  actions: {
    async listFlows(context) {
      const link = await context.getters.userHalLink('flows')
      const response = await ApiClient.getUri(link)
      const list = [
        ...response.data.items
      ]
      return new Promise((resolve) => {
        context.commit('setFlowList', list)
        resolve(context.getters.flowList)
      })
    }
  },
  getters: {
    flowsUris(state) {
      return state.flows.map((flow) => {
        return flow._links.self.href
      }) || []
    },
    flowList(state) {
      return state.flows.map((flow) => {
        return {
          uri: flow._links.self.href,
          name: flow.name
        }
      }) || []
    }
  }
}
