<template>
  <div>
    <div v-if="loading">
      <FullHeightLoader
       v-model="loading" />
    </div>
    <slot v-else-if="error" name="error" :error="error">
      <div>{{ error }}</div>
    </slot>
    <slot v-else :data="data" />
  </div>
</template>

<script>
import FullHeightLoader from '@/components/FullHeightLoader.vue'

export default {
  props: {
    promise: {
      type: Promise
    },
    initialValue: {
      type: [Number, String, Array, Object],
      default: null
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      data: this.initialValue
    }
  },
  watch: {
    promise: {
      immediate: true,
      handler(newVal) {
        if(!newVal) return
        this.loading = true
        newVal
          .then(result => this.data = result)
          .catch(error => this.error = error)
          .finally(() => this.loading = false)
      }
    }
  },
  components: {
    FullHeightLoader
  }
}
</script>
