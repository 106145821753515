<template>
<v-dialog
  ref="dialog"
  v-model="modal"
  :return-value.sync="time"
  persistent
  width="350px"
>
  <template v-slot:activator="{ on, attrs }">
    <slot name="activator" :on="on" :attrs="attrs"/>
  </template>

  <v-time-picker :color="color" v-if="timeMode" v-model="time" scrollable format="24hr" :locale="_i18n.locale">
    <v-btn text :color="color" @click="timeMode = false">
      {{ dateFormated }}
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn text color="grey" @click="modal = false">
      {{ $t("dialogs.cancelButton") }}
    </v-btn>
    <v-btn text :color="color" @click="saveTime">
      {{ $t("dialogs.okButton") }}
    </v-btn>
  </v-time-picker>
  <v-date-picker :color="color" v-if="timeMode == false" v-model="date" scrollable :locale="_i18n.locale" :first-day-of-week="1">
    <v-btn text :color="color" @click="timeMode = true">
      {{ timeFormated }}
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn text color="grey" @click="modal = false">
      {{ $t("dialogs.cancelButton") }}
    </v-btn>
    <v-btn text :color="color" @click="saveTime">
      {{ $t("dialogs.okButton") }}
    </v-btn>
  </v-date-picker>
</v-dialog>
</template>

<script>
import moment from 'moment'
import { DATE_DISPLAY_FORMAT, DATE_PICKER_FORMAT, DATETIME_PICKER_FORMAT, TIME_FORMAT } from '@/constants/dateFormats.js'

export default {
  name: 'DateAndTimePicker',
  props: {
    color: {
      default: 'primary',
      type: String
    },
    value: {}
  },
  data() {
    return {
      modal: false,
      timeMode: true,
      time: undefined,
      date: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.time = newVal
          ? moment(newVal).format(TIME_FORMAT)
          : moment().format(TIME_FORMAT) ,
        this.date = newVal
          ? moment(newVal).format(DATE_PICKER_FORMAT)
          : moment().format(DATE_PICKER_FORMAT)
      }
    }
  },
  computed: {
    timeFormated() {
      return this.time || this.$t('dialogs.setTime')
    },
    dateFormated() {
      return moment(this.date).format(DATE_DISPLAY_FORMAT)
    }
  },
  methods: {
    saveTime() {
      if (this.time == null) return
      if (this.date == null) return
      this.$refs.dialog.save(this.time)
      let dateTime = moment(
        this.date + ' ' + this.time,
        DATETIME_PICKER_FORMAT
      )
      this.emitInput(dateTime)
    },
    emitInput(moment) {
      if (moment.isValid()) {
        this.date = moment.format(DATE_PICKER_FORMAT)
        this.time = moment.format(TIME_FORMAT)
        this.$emit('input', moment.toISOString(), this.valueId)
      } else {
        this.date = moment().format(DATE_PICKER_FORMAT)
        this.time = null
        this.$emit('input', null, this.valueId)
      }
      this.$emit('blur')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input {
  width: 100px;
  height: 30px;
  color: gray;
}
</style>
