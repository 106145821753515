<template>
<SingleInputDialog
  :disabled="disabled"
  v-model="externalModel"
  :title="$t('dialogs.copySpaceTitle', { spaceName })"
  :text="$t('dialogs.copySpaceText')"
  :okButtonLabel="$t('dialogs.copy')"
  :okHandler="save"
  :initialInputValue="defaultCopyName"
  :max="$apptive.constants.nameLengths.space"
  max-width="290"
>
  <template v-slot:activator="{ on, attrs }">
    <slot name="activator" :on="on" :attrs="attrs">
    </slot>
  </template>
  <v-checkbox
    v-model="includeEntities"
    dense hide-details
    :label="$t('dialogs.copyData')"
    data-testid="copyDataCheckbox"
  />
</SingleInputDialog>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  props: {
    spaceUri: null,
    spaceName: null,
    disabled: null
  },
  data() {
    return {
      includeEntities: true
    }
  },
  computed: {
    defaultCopyName() {
      return this.$t('dialogs.copyOf', { spaceName: this.spaceName })
    }
  },
  methods: {
    save(name) {
      return this.$store.dispatch('AGCopySpaceOperation', {
        spaceUri: this.spaceUri,
        name,
        includeEntities: this.includeEntities
      })
    }
  },
  components: {
    SingleInputDialog
  }
}
</script>
