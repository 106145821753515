<template>
<SingleInputDialog
  :disabled="disabled"
  v-model="externalModel"
  :title="$t('dialogs.setSpaceKeyTitle', { spaceName })"
  :text="$t('dialogs.setSpaceKeyText')"
  :okButtonLabel="$t('dialogs.okButton')"
  :okHandler="save"
  :initialInputValue="spaceKey"
  :max="$apptive.constants.nameLengths.default"
  :nameRequired="false"
  max-width="290"
/>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  props: {
    spaceUri: null,
    spaceName: null,
    spaceKey: null,
    disabled: null
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    }
  },
  methods: {
    save(key) {
      return this.space.setKey(key)
    }
  },
  components: {
    SingleInputDialog
  }
}
</script>
