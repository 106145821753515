<template>
<v-select
    v-bind="$attrs"
    :value="value"
    v-on="$listeners"
    class="text-caption compact-select"
    :class="{'less-inner-space': lessInnerSpace, 'hide-controls': !showControls}"
    solo
    flat
    filled
    dense
    hide-details
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  </v-select>
</template>

<script>
export default {
  props: {
    value: null,
    lessInnerSpace: {
      type: Boolean,
      default: () => false
    },
    showControls: {
      type: Boolean,
      default: () => true
    }
  }
}
</script>

<style lang="scss" scoped>
.compact-select {
  border: solid thin #d3d2d4;

  ::v-deep .v-input__append-inner {
    margin-right: -12px;
  }
  ::v-deep .v-input__control,
  ::v-deep .v-input__slot {
    min-height: 30px !important;
    height: 30px;
  }

  ::v-deep label {
    top: unset !important;
  }
}

.compact-select.v-input--is-dirty {
  ::v-deep input {
    display: none;
  }

  ::v-deep .v-select__selection,
  ::v-deep .v-label {
    max-width: none;
    margin-left: -4px;
    margin-right: -12px;
  }
}

.less-inner-space {
  ::v-deep .v-input__append-inner {
    padding-left: 0px;
  }

  ::v-deep .v-select__selection {
    margin-right: 0px;
  }

  ::v-deep .v-select__selections {
    justify-content: center;
  }
}

.hide-controls {
  ::v-deep .v-input__append-inner {
    opacity: 0;
  }
}
</style>