<template>
  <div class="ma-6" style="position: relative;">
    <LoadingOverlay :show="loading" />
    <v-sheet rounded elevation="2" color="white" class="pa-10">
      <div class="text-h6">{{$t('links.title')}}</div>
      <v-divider class="mt-2 mb-4"></v-divider>
      <div class="my-5 text-subtitle-1">{{$t('links.description')}}</div>
      <div class="d-flex flex-column gap-3">
        <LinkCard
          v-for="link in links"
          :key="link.uri"
          :link="link"
          @newName="newName => patchLink(link, newName)"
          @delete="() => deleteLink(link)"
        />
      </div>
    </v-sheet>
  </div>
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import LinkCard from '../LinkCard.vue'

export default {
  props: {
    spaceUri: null,
  },
  data() {
    return {
      links: [],
      loading: false
    }
  },
  async mounted() {
    await this.$store.dispatch('AGReadSpaceOperation', { spaceUri: this.spaceUri})
    await this.listLinks()
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    }
  },
  methods: {
    async listLinks() {
      this.loading = true
      try {
        const allLinks = await this.$store.dispatch('AGReadSpaceLinks', this.space)
        this.links = allLinks.filter(link => link.type != 'spaceInvitation')
      } finally {
        this.loading = false
      }
    },
    async deleteLink(link) {
      await link.delete()
      this.listLinks()
    },
    async patchLink(link, newName) {
      await link.patchName(newName)
      this.listLinks()
    }
  },
  components: {
    LoadingOverlay,
    LinkCard
}
}
</script>