<template>
  <div class="map-container">
    <div class="google-map" ref="map" />
    <v-progress-linear
      v-show="loading"
      ref="progressBar"
      class="ma-0 loader"
      indeterminate
      color="primary"
      height="6"
    />
    <input
      v-if="searchBar"
      ref="searchBox"
      class="search-box elevation-2"
      type="text"
      :placeholder="$t('map.searchHere')"
    />

    <v-btn
      v-if="userLocationEnabled"
      fab
      :ripple="false"
      small
      class="location-fab"
      ref="locationFab"
      @click.native="centerOnUserLocation"
    >
      <v-icon color="secondary" size="24">my_location</v-icon>
    </v-btn>
    <div ref="bottomLeftWidget">
      <slot name="bottomLeftWidget" />
    </div>
    <slot name="markers" :mapPromise="mapPromise" />
  </div>
</template>

<script>
/* global google */
import { loadGoogleMaps, onGoogleMapsLoaded } from '@/components/map/googleUtils.js'
import Vue from 'vue'

export default {
  props: {
    options: {
      default: null
    },
    panTarget: {
      default: null
    },
    onIdleHandler: {
      default: null
    },
    defaultZoom: {
      default: null
    },
    userLocationEnabled: {
      default: null
    },
    loading: {
      default: null
    },
    searchBar: {
      type: Boolean,
      default: () => true
    },
    onSearchBarClicked: {
      default: null
    }
  },
  data() {
    return {
      map: {},
      locationWatchId: undefined,
      mapPromise: undefined,
      mapPromiseControls: undefined
    }
  },
  watch: {
    panTarget(newValue) {
      if (newValue) {
        this.panMap(newValue.latlng, newValue.verticalOffsetPx)
      }
    }
  },
  methods: {
    initMap() {
      // HACKY : setTimeout gives the opportunity to the browser to render
      // the map element before the initMap if it is in a modal
      // See Late Timeouts example here :
      // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#reasons_for_delays_longer_than_specified
      setTimeout(
        function() {
          const element = this.$refs.map
          if (!(this.options.center instanceof google.maps.LatLng)) {
            this.options.center = new google.maps.LatLng(
              this.options.center.lat,
              this.options.center.lng
            )
          }
          this.map = new google.maps.Map(element, this.options)

          this.bindClickHandler()
          this.bindIdleHandler()
          if (this.searchBar) {
            this.initSearchBox()
          }
          this.initBottomLeftWidget()
          this.initLoader()
          this.bindCenterChangedHandler()
          this.bindDragendHandler()
          if (this.userLocationEnabled) {
            this.initLocationFab()
            this.centerOnUserLocation()
          }
          this.mapPromiseControls.resolve(this.map)
        }.bind(this),
        100
      )
    },
    bindClickHandler() {
      this.map.addListener('click', event => {
        this.$emit('mapClicked', event)
      })
    },
    bindIdleHandler() {
      if (this.onIdleHandler) {
        this.map.addListener('idle', () => {
          this.onIdleHandler(this.map)
        })
      }
    },
    bindCenterChangedHandler() {
      this.map.addListener('center_changed', () => {
        this.$emit('centerChanged', this.map.center)
      })
    },
    bindDragendHandler() {
      this.map.addListener('dragend', () => {
        this.$emit('dragend', this.map.center)
      })
    },
    initSearchBox() {
      let input = this.$refs.searchBox
      let searchBox = new google.maps.places.SearchBox(input)
      searchBox.addListener('places_changed', () => {
        let places = searchBox.getPlaces()

        if (places.length === 0) {
          return
        }
        let bounds = new google.maps.LatLngBounds()
        let place = places[0]
        this.$emit('placeFound', place)
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }

        this.map.fitBounds(bounds)
      })
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(input)
    },
    initBottomLeftWidget() {
      let bottomLeftWidget = this.$refs.bottomLeftWidget
      this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
        bottomLeftWidget
      )
    },
    initLoader() {
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
        this.$refs.progressBar.$el
      )
    },
    initLocationFab() {
      let locationFab = this.$refs.locationFab.$el
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        locationFab
      )
    },
    latLng2Point(latLng, map) {
      let topRight = map
        .getProjection()
        .fromLatLngToPoint(map.getBounds().getNorthEast())
      let bottomLeft = map
        .getProjection()
        .fromLatLngToPoint(map.getBounds().getSouthWest())
      let scale = Math.pow(2, map.getZoom())
      let worldPoint = map.getProjection().fromLatLngToPoint(latLng)
      return new google.maps.Point(
        (worldPoint.x - bottomLeft.x) * scale,
        (worldPoint.y - topRight.y) * scale
      )
    },
    point2LatLng(point, map) {
      let topRight = map
        .getProjection()
        .fromLatLngToPoint(map.getBounds().getNorthEast())
      let bottomLeft = map
        .getProjection()
        .fromLatLngToPoint(map.getBounds().getSouthWest())
      let scale = Math.pow(2, map.getZoom())
      let worldPoint = new google.maps.Point(
        point.x / scale + bottomLeft.x,
        point.y / scale + topRight.y
      )
      return map.getProjection().fromPointToLatLng(worldPoint)
    },
    panMap(latlng, verticalOffsetPx) {
      Vue.nextTick(() => {
        let targetLatLng = latlng
        if (verticalOffsetPx) {
          let point = this.latLng2Point(latlng, this.map)
          point.y = point.y + verticalOffsetPx
          targetLatLng = this.point2LatLng(point, this.map)
        }
        this.map.panTo(targetLatLng)
      })
    },
    fitBounds(latLngBounds) {
      this.map.fitBounds(latLngBounds)
    },
    async fitMapToMarkers(markers) {
      if (markers.length == 0) {
        return
      }
      var bounds = new google.maps.LatLngBounds()
      for (var i = 0; i < markers.length; i++) {
        bounds.extend(markers[i])
      }
      await this.mapPromise
      this.fitBounds(bounds)
    },
    searchBoxClicked() {
      this.$refs.searchBox.value = ''
      if (this.onSearchBarClicked) {
        this.onSearchBarClicked()
      }
    }
  },
  beforeMount() {
    this.mapPromise = new Promise((resolve, reject) => {
      this.mapPromiseControls = { resolve, reject }
    })
  },
  mounted() {
    loadGoogleMaps('de')
    onGoogleMapsLoaded(this.initMap)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.google-map {
  min-width: calc(50px);
  width: 100%;
  max-height: 100vh;
  height: 100%;
  min-height: calc(50px);
  margin: 0 auto;
  background: gray;
}

.map-container {
  position: relative;
  min-width: calc(50px);
  width: 100%;
  max-height: 100vh;
  height: 100%;
  min-height: calc(50px);
  margin: 0 auto;
  background: gray;
}

.search-box {
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 16px;
  height: 24px;
  text-overflow: ellipsis;
  width: 400px;
  max-width: 80%;
  margin-top: 16px;
  border-radius: 2px;
}

.search-box:focus {
  outline: none;
}

.search-box {
  font-family: 'regular', sans-serif;
}

.location-fab {
  margin: 24px;
}

.search-bar {
  margin-top: 16px;
  font-size: 14px;
  width: 400px;
  max-width: 80%;
}
</style>