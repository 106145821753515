import Vue from 'vue'
import VueRouter from 'vue-router'
import Spaces from '@/views/Spaces.vue'
import Space from '@/views/Space.vue'
import GridPage from '@/views/GridPage.vue'
import AccountSettingsPage from '@/views/AccountSettingsPage.vue'
import ApiCredentialsPage from '@/views/ApiCredentialsPage.vue'
import ExternalForm from '@/views/ExternalForm.vue'
import Template from '@/views/Template.vue'
import VirtualGrid from '@/components/gridView/GridView.vue'
import FormBuilder from '@/components/form/formbuilder/FormBuilder.vue'
import KanbanView from '@/components/kanbanView/KanbanView.vue'
import CalendarView from '@/components/calendar/CalendarView.vue'
import MapView from '@/components/mapView/MapView.vue'
import StandaloneView from '@/components/StandaloneView.vue'
import Profile from '@/components/user/Profile.vue'
import SideNavigation from '@/components/space/SideNavigation.vue'
import FlowEditor from '@/components/flow/FlowEditor.vue'
import FlowList from '@/components/flow/FlowList.vue'
import FlowPage from '@/components/flow/FlowPage.vue'
import FlowView from '@/components/flow/FlowView.vue'
import WebhooksPage from '@/components/webhooks/WebhooksPage.vue'
import LinksPage from '@/components/webhooks/LinksPage.vue'
import GeneralSpaceSettingsPage from '@/views/GeneralSpaceSettingsPage.vue'
import FlowInstancesList from '@/components/flow/FlowInstancesList.vue'
import PaymentPage from '@/views/PaymentPage.vue'
import SpaceSettingsPage from '@/views/SpaceSettingsPage.vue'
import EmbeddedEditor from '@/views/EmbeddedEditor.vue'
import GalleryView from '@/components/galleryView/GalleryView.vue'
import Invitation from '@/views/Invitation.vue'
import PrintEntity from '@/views/PrintEntity.vue'
import BlockEditor from '@/components/block/editor/BlockEditor.vue'
import FlowInstanceLinkView from '@/components/FlowInstanceLinkView.vue'
import SpacesNavigation from '@/components/SpacesNavigation.vue'

import { base64DecodeUrl } from '@/utils/base64Url.js'
import BlockPage from '@/components/block/BlockPage.vue'

Vue.use(VueRouter)

function decodeProps(route) {
  let props = {}
  Object.entries(route.params).forEach(entry => {
    props[entry[0]] = decodeURIComponent(entry[1])
  })
  return props
}

function base64DecodeProps(route) {
  let props = {}
  Object.entries(route.params).forEach(entry => {
    props[entry[0]] = base64DecodeUrl(entry[1])
  })
  return props
}

const routes = [
  { path: '*', redirect: '/' },
  { 
    path: '/', 
    name: 'Spaces', 
    components: { MainContent: Spaces, SideBar: SpacesNavigation },
    props: { MainContent: decodeProps },
    beforeEnter: (to, from, next) => {
      // Check if the 'view' query parameter is present
      if (!to.query.view) {
        // Redirect to the same path with 'view=all' if it's missing
        next({ ...to, query: { ...to.query, view: 'all' } })
      } else {
        next()
      }
    }
  },
  {
    path: '/template/:id',
    name: 'Template',
    components: { MainContent: Template},
    props: { MainContent: decodeProps }
  },
  {
    path: '/spaceinvitation',
    name: 'Invitation',
    components: { MainContent: Invitation},
    props: { MainContent: decodeProps },
  },
  {
    path: '/:virtualGridUri/:entityUri/print',
    name: 'PrintEntity',
    components: { MainContent: PrintEntity},
    props: { MainContent: decodeProps },
  },
  {
    path: '/space/:spaceUri',
    name: 'Space',
    components: { MainContent: Space, SideBar: SideNavigation },
    props: { MainContent: decodeProps, SideBar: decodeProps },
    children: [
      {
        path: ':gridUri',
        name: 'Grid',
        component: GridPage,
        props: decodeProps,
        children: [
          {
            path: 'virtualGrid/:statefulViewUri',
            name: 'VirtualGrid',
            component: VirtualGrid,
            props: decodeProps
          },
          {
            path: 'form/:formUri',
            name: 'FormBuilder',
            component: FormBuilder,
            props: decodeProps
          },
          {
            path: 'kanban/:statefulViewUri',
            name: 'KanbanView',
            component: KanbanView,
            props: decodeProps
          },
          {
            path: 'calendar/:statefulViewUri',
            name: 'CalendarView',
            component: CalendarView,
            props: decodeProps
          },
          {
            path: 'map/:statefulViewUri',
            name: 'MapView',
            component: MapView,
            props: decodeProps
          },
          {
            path: 'gallery/:statefulViewUri',
            name: 'GalleryView',
            component: GalleryView,
            props: decodeProps
          },
        ]
      },
    ]
  },
  {
    path: '/view/:statefulViewUri',
    name: 'StandaloneView',
    components: { MainContent: StandaloneView},
    props: { MainContent: base64DecodeProps }
  },
  {
    path: '/view',
    name: 'StandaloneViewSearchParams',
    components: { MainContent: StandaloneView },
    props: { MainContent: decodeProps }
  },
  {
    path: '/flows/:spaceUri',
    name: 'FlowPage',
    components: { MainContent: FlowPage, SideBar: SideNavigation },
    props: { MainContent: decodeProps, SideBar: decodeProps },
    children: [
      {
        path: 'list/:scope',
        name: 'FlowList',
        component: FlowList,
        props: decodeProps,
      },
      {
        path: 'flow/:flowUri',
        name: 'FlowView',
        component: FlowView,
        props: decodeProps,
        children: [
          {
            path: 'editor',
            name: 'FlowEditor',
            component: FlowEditor,
            props: decodeProps,
            meta: {
              showBackButton: true
            },
          },
          {
            path: 'instances',
            name: 'FlowInstancesList',
            component: FlowInstancesList,
            props: decodeProps,
            meta: {
              showBackButton: true
            },
          }
        ]
      },
    ]
  },
  {
    path: '/blocks/:spaceUri',
    name: 'BlockEditor',
    components: { MainContent: BlockEditor, SideBar: SideNavigation },
    props: { MainContent: decodeProps, SideBar: decodeProps },
    children : [
      {
        path: 'block/:blockUri',
        name: 'BlockPage',
        component: BlockPage,
        props: (route) => ({ editorMode: true, ...decodeProps(route)}),
      },
    ]
  },
  {
    path: '/form',
    name: 'ExternalForm',
    components: { MainContent: ExternalForm },
    props: { MainContent: decodeProps }
  },
  {
    path: '/settings',
    name: 'Settings',
    components: { MainContent: AccountSettingsPage, SideBar: SpacesNavigation },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: 'payment',
        name: 'PaymentPage',
        component: PaymentPage
      },
      {
        path: 'credentials',
        name: 'ApiCredentials',
        component: ApiCredentialsPage
      },
    ]
  },
  {
    path: '/spacesettings/:spaceUri',
    name: 'SpaceSettings',
    components: { MainContent: SpaceSettingsPage, SideBar: SideNavigation },
    props: { MainContent: decodeProps, SideBar: decodeProps },
    children: [
      {
        path: 'general',
        name: 'GeneralSpaceSettingsPage',
        component: GeneralSpaceSettingsPage,
        props: decodeProps
      },
      {
        path: 'webhooks',
        name: 'WebhooksPage',
        component: WebhooksPage,
        props: decodeProps
      },
      {
        path: 'links',
        name: 'LinksPage',
        component: LinksPage,
        props: decodeProps
      }
    ]
  },
  {
    path: '/embedded/:sharedUrl',
    name: 'EmbeddedEditor',
    components: { MainContent: EmbeddedEditor },
    props: { MainContent: decodeProps }  
  },
  {
    path: '/embeddedForm/:sharedUrl',
    name: 'EmbeddedFormEditor',
    components: { MainContent: EmbeddedEditor },
    props: { MainContent: (route) => ({ isForm: true , ...decodeProps(route)}), }
  },
  {
    path: '/flowInstance',
    name: 'FlowInstance',
    components: { MainContent: FlowInstanceLinkView },
    props: { MainContent: decodeProps }  
  },
]

const router = new VueRouter({
  routes // short for `routes: routes`
})


export default router
