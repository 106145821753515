<template>
  <v-menu v-model="externalModel" v-bind="$attrs">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"/>
    </template>
    <v-list dense>
      <MenuItem
        icon="mdi-help-circle-outline"
        :text="$t('helpMenu.helpCenter')"
        @click="toHelpCenter"
      />
      <MenuItem
        icon="mdi-chat-outline"
        :text="$t('helpMenu.chatWithUs')"
        @click="openIntercomChat"
        :badge="showUnreadBadge"
      />
      <MenuItem
        icon="mdi-comment-quote-outline"
        :text="$t('helpMenu.feedback')"
        :href="mailtoSupport"
      />
    </v-list>
  </v-menu>
</template>

<script>
import externalModel from '../mixins/externalModel'
import MenuItem from '@/components/MenuItem.vue'

export default {
  mixins: [externalModel],
  data() {
    return {
      mailtoSupport: 'mailto:support@apptivegrid.de'
    }
  },
  computed: {
    showUnreadBadge() {
      return this.$intercom?.unreadCount != null && this.$intercom?.unreadCount > 0
    }
  },
  methods: {
    toHelpCenter() {
      window.open('https://intercom.help/apptivegrid/de/', '_blank')
    },
    openIntercomChat() {
      this.$intercom.show()
    }
  },
  components: {
    MenuItem
  }
}
</script>
