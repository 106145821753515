<template>

  <div v-if="space" style="height:100%" data-testid="SpacePage">

    <AppBar :space="space" extension-height="32px" height="48px" app flat clipped-right>
      <v-app-bar-nav-icon v-if="this.$vuetify.breakpoint.mobile" class="ml-auto" @click="sideNavigation = !sideNavigation"/>

      <v-toolbar-title class="font-weight-medium">
        <EditableText
          :readonly="!canRenameSpace"
          class="full-width textOverflow pa-1"
          :text="space.title()"
          @submit="renameSpace"
          :maxLength="$apptive.constants.nameLengths.space"
          data-testid="spaceTitle"
        />
      </v-toolbar-title>
      <SpaceMoreMenu
        :spaceUri="spaceUri"
        :spaceName="space.name"
        :disableRename="!canRenameSpace"
        :disableShare="!canShareSpace"
        :disableUploadCsv="!canUploadCsv"
      />
      <v-spacer></v-spacer>

        <div v-if="apptiveFeature">
        <v-btn
          class="mr-2"
          icon
          width="35"
          height="35"
          @click="toggleApptiveDrawer">
          <v-icon>mdi-apps</v-icon>
        </v-btn>
      </div>
      <SpaceCollaborators :space="space" class="mr-3"/>
      <UserProfile v-if="userLoggedIn"/>


      <template  v-slot:extension>
 
        <v-tabs
          ref="tabs"
          class="tabs"
          active-class="activeTab"
          :align-with-title="false"
          hide-slider
        >
        <AGDraggable
          @end="onTabDragEnd"
          class="v-slide-group__content v-tabs-bar__content flex-grow-0"
        >
          <v-tab
            v-for="(aGrid, index) in grids"
            :key="index"
            class="pr-2"
            :exact="false"
            :to="{name: 'Grid', params: { gridUri: aGrid.uri }}"
            @contextmenu.prevent="() => openGridMoreMenu(index)"
            :data-testid="`grid-tab-${index}`"
          >
            <EditableText
              :readonly="!canRenameGrid(aGrid)"
              class="full-width textOverflow pa-1"
              :text="aGrid.name"
              @submit="val => renameGrid(aGrid, val)"
              :maxLength="$apptive.constants.nameLengths.grid"
              data-testid="gridTab-text"
            />
            <GridMoreMenu
              ref="gridMoreMenus"
              :disabled="aGrid.uri != gridUri"
              :gridUri="aGrid.uri"
              :disableDelete="!canDeleteGrid(aGrid)"
              :disableRename="!canRenameGrid(aGrid)"
              :disableSetKey="!canSetGridKey(aGrid)"
              @deleteGrid="deleteGrid(aGrid.uri, index)"
            />
          </v-tab>

        </AGDraggable>
         
          <div class="d-flex align-center">
            <AddGridMenu :spaceUri="spaceUri">
              <template v-slot:activator="{ on, attrs }">
              <v-btn
              v-if="!$vuetify.breakpoint.mobile"
              :disabled="!canAddGrid"
              class="ml-2"
              plain icon small
                v-on="on"
              v-bind="attrs"
              data-testid="openAddGridMenu"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
            </AddGridMenu>

          </div>

        </v-tabs>
        <v-menu offset-y color="white">
            <template v-slot:activator="{on, attrs}">
              <v-btn icon small v-bind="attrs" v-on="on"  class="mr-0 pr-0"> <v-icon>mdi-chevron-down</v-icon> </v-btn>
            </template>
            <v-sheet>
              <v-subheader>Grids</v-subheader>
              <v-divider></v-divider>
              <v-list 
                width="320"  style="max-height: 70vh"
                class="overflow-y-auto" dense>
              <v-list-item v-for="(aGrid, index) in grids" :key="index" @click="()=> navigate(aGrid.uri)" >
                <v-list-item-content>
                  <v-list-item-title>{{ aGrid.name }}</v-list-item-title>
                 </v-list-item-content>
                <v-list-item-action v-show="aGrid.uri == gridUri">
                  <v-icon  dense>mdi-check</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list-item dense :disabled="!canAddGrid" @click="()=> addGrid()" >
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{ $t('virtualGrid.addGridButton')}}</v-list-item-title>
                 </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary" dense>mdi-plus</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-sheet>
            </v-menu>
      </template>
    </AppBar>

    <div :style="{ height: '100%' }">
      <router-view />
    </div>

    <div class="text-center">
      <v-progress-circular
        indeterminate
        v-if="loading"
        color="primary"
        width="2"
        size="48"
        class="loader"
      />
    </div>
    <SeatsConsumedAlert :spaceUri="spaceUri" />
    <OnboardingCustomer v-if="user && this.$settings.apptivecom" :user=user></OnboardingCustomer>
</div>
</template>

<script>

import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import SpaceMoreMenu from '@/components/space/SpaceMoreMenu.vue'
import GridMoreMenu from '@/components/grid/GridMoreMenu.vue'
import errorBus from '@/utils/errorBus.js'
import EditableText from '@/components/EditableText.vue'
import UserProfile from '../components/user/UserProfile.vue'
import { bootIntercom } from '@/plugins/intercom.js'
import AppBar from '../components/AppBar.vue'
import AGDraggable from '../components/AGDraggable.vue'
import SpaceCollaborators from '../components/SpaceCollaborators.vue'
import SeatsConsumedAlert from '@/components/paywall/SeatsConsumedAlert.vue'
import OnboardingCustomer from '../components/onboarding/OnboardingCustomer.vue'
import AddGridMenu from '@/components/AddGridMenu.vue'

export default {
  name: 'Space',
  props: {
    spaceUri: null,
    gridUri: null,
    sharedSpaceUri: null
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    this.loading = true
  },
  watch: {
    spaceUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: newVal,
            customErrorHandling: [403, 404]
          })
          const metaDescription = document.querySelector('meta[name="theme-color"]')
          if( metaDescription != null) {
            metaDescription.setAttribute('content', this.spaceColor)
          }
          document.title = this.space.title()
          // load grid uris
          const gridList = await this.$store.dispatch('loadGridsUris', newVal)
          if (this.$route.name === 'Space' && gridList.length > 0) {
            this.$router.replace({name: 'Grid', params: {gridUri: gridList[0].uri}})
          }
          //boot intercom here when unser is loaded
          bootIntercom( { appId: this.$settings?.intercom.appID })

        } catch(error) {
          const errorOkHandler = async () => {
            if(this.sharedSpaceUri) {
              const sharedSpace = await this.$store.dispatch('AGReadSpaceOperation', {
                spaceUri: this.sharedSpaceUri
              })
              if (sharedSpace && sharedSpace.type === 'sharedSpace' && hasPermission(sharedSpace, [PERMISSIONS.remove])) {
                this.$store.dispatch('AGDeleteSpaceOperation', this.sharedSpaceUri)
              }
            }
            this.$router.replace({name: 'Spaces'})
          }
          if (error.response.status === 404) {
            errorBus.$emit('ERROR', {
              type: 'spaceNotFound',
              okHandler: errorOkHandler
            })
          } else if (error.response.status === 403) {
            errorBus.$emit('ERROR', {
              type: 'spaceAccessDenied',
              okHandler: errorOkHandler
            })
          }
          this.$apptiveErrorReporter.captureException(error)
        } finally {
          this.loading = false
        }
        
      }
    },
    // This allows navigating to the space page without specifying a
    // grid and being redirected to the first grid in this space
    gridUri(newVal) {
      if (!newVal && this.grids && this.grids.length) {
        this.$router.replace({name: 'Grid', params: {gridUri: this.grids[0].uri}})
      }
    }
  },
  provide() {
    return {
      plan: () => this.plan,
      spaceUri: this.spaceUri,
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    plan() {
      return this.space?.plan
    },
    grids() {
      return this.$store.getters.gridsOfSpace(this.spaceUri)
    },
    apptiveFeature() {
      return this.$store.getters.alphaMode
    },
    canAddGrid() {
      return hasPermission(this.space, [PERMISSIONS.addGrid])
    },
    canRenameSpace() {
      return hasPermission(this.space, [PERMISSIONS.patch])
    },
    canShareSpace() {
      return hasPermission(this.space, [PERMISSIONS.addShare])
    },
    canUploadCsv() {
      return hasPermission(this.space, [PERMISSIONS.uploadCSV])
    },
    sideNavigation: {
      get() {
        return this.$store.state.sideNavigation
      },
      set(newVal) {
        return this.$store.commit('setSideNavigation', newVal)
      }
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    user() {
      return this.$store.state.user.user
    },
    spaceColor() {
      return this.space?.color || 'space'
    }
  },
  methods: {
    navigate(gridUri) {
      this.$router.push({name: 'Grid', params: {gridUri: gridUri}})
    },
    addGrid() {
      this.$store.dispatch('AGAddGridOperation', {
        spaceUri: this.space.uri
      }).then((grid) => {
        this.navigate(grid.uri)
      })
    },
    deleteGrid(gridUri, index) {
      this.$store.dispatch('AGDeleteGridOperation', gridUri).then(() => {
        const newIndex = Math.max(index - 1, 0)
        const previousGrid = this.grids[newIndex]
        if (previousGrid) {
          this.navigate(previousGrid.uri)
        } else {
          this.$router.push({name: 'Space', params: {spaceUri: this.spaceUri}})
        }
        this.$store.commit('setNavigationDrawer', this.grids.length > 0)
      })
    },
    fullGridFromEmbedded(embeddedGrid) {
      // TODO : Hacky, we rely on the fact that the full grid with hal links gets loaded by
      // active grid page.
      const gridUri = embeddedGrid._links?.self.href ?? embeddedGrid.uri
      return this.$store.getters.gridWithUri(gridUri)
    },
    canDeleteGrid(embeddedGrid) {
      const grid = this.fullGridFromEmbedded(embeddedGrid)
      return hasPermission(grid, [PERMISSIONS.remove])
    },
    canRenameGrid(embeddedGrid) {
      const grid = this.fullGridFromEmbedded(embeddedGrid)
      return hasPermission(grid, [PERMISSIONS.rename])
    },
    canSetGridKey(embeddedGrid) {
      const grid = this.fullGridFromEmbedded(embeddedGrid)
      return hasPermission(grid, [PERMISSIONS.patch])
    },
    renameGrid(grid, newName) {
      if (grid.name == newName) {
        return
      }
      return this.$store.dispatch('AGRenameGridOperation', {
        gridUri: grid.uri,
        newName: newName
      })
    },
    openGridMoreMenu(index) {
      if(this.grids[index].uri === this.gridUri) {
        this.$refs.gridMoreMenus[index].open()
      }
    },
    toggleApptiveDrawer() {
      this.$store.commit('setApptivesDrawer', !this.$store.state.apptivesDrawer)
    },
    renameSpace(newName) {
      if (this.space.name == newName) {
        return
      }
      return this.$store.dispatch('AGRenameSpaceOperation', {
        spaceUri: this.space.uri,
        newName: newName
      })
    },
    onTabDragEnd(event) {
      if (event.oldIndex === event.newIndex) return
      const gridUri = this.grids[event.oldIndex].uri
      this.$store.dispatch('AGChangeGridPositionOperation', {
        space: this.space,
        gridUri,
        newPosition: event.newIndex
      })
    }
  },
  components: {
    GridMoreMenu,
    SpaceMoreMenu,
    EditableText,
    UserProfile,
    AppBar,
    AGDraggable,
    SpaceCollaborators,
    SeatsConsumedAlert,
    OnboardingCustomer,
    AddGridMenu
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.activeTab.v-tab {
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  color: black;
}
.activeTab.v-tab:before {
  background-color : white;
}
.v-tabs-bar .v-btn i {
  opacity: .5;
}
.v-tabs-bar .v-btn i:hover{
  opacity: 1;
}
.tabs {
  padding-right: 50px;
  padding-left: 8px;
}
.moreMenu {
  width: 50px;
  position: absolute;
  right: 0px;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
}
</style>
