import Vue from 'vue'
import ApiClient from '@/ApiClient'

export default {
  state() {
    return {
      formLists: {}
    }
  },
  mutations: {
    addFormList(state, { gridUri, list }) {
      Vue.set(state.formLists, gridUri, list)
    }
  },
  actions: {
    loadFormUris(context, gridUri) {
      const grid = context.getters.gridWithUri(gridUri)
      const link = grid.getLink('forms')
      return ApiClient.getUri(link, undefined, { headers: {Accept: 'application/vnd.apptivegrid.hal'}})
        .then(response => {
          context.commit('addFormList', { gridUri, list: response.data.items })
          return response.data.items
        })
    }
  },
  getters: {
    formsOfGrid: (state) => gridUri => {
      return state.formLists[gridUri]?.map((form) => {
        return {
          uri: form._links.self.href,
          name: form.name,
          id: form.id
        }
      }) || []
    }
  }
}