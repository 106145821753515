<template>
    <div id="proofReadMenu">
      <!-- Proofread Button -->
      <v-speed-dial
        v-model="fab"
        :direction="expandDirection"
        :open-on-hover="false"
        transition="slide-y-reverse-transition"
        
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            elevation="0"
            light
            x-small
            fab
            :loading="loading"
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon color="accent" v-else>
              mdi-auto-fix
            </v-icon>
          </v-btn>
        </template>

        <v-tooltip v-if="textHistory.length > 0" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab x-small elevation="0" @click="back" v-bind="attrs" v-on="on">
              <v-icon>mdi-undo-variant</v-icon>
            </v-btn>
          </template>
          <span>{{$t('proofread.undo.tooltip')}}</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab x-small elevation="0" @click="friendly" v-bind="attrs" v-on="on">
              <v-icon>mdi-emoticon-happy-outline</v-icon>
            </v-btn>
          </template>
          <span>{{$t('proofread.friendly.tooltip')}}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab x-small elevation="0" @click="professional" v-bind="attrs" v-on="on">
              <v-icon>mdi-briefcase-variant-outline</v-icon>
            </v-btn>
          </template>
          <span>{{$t('proofread.professional.tooltip')}}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab x-small elevation="0" @click="rewrite" v-bind="attrs" v-on="on">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>{{$t('proofread.rewrite.tooltip')}}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab x-small elevation="0" @click="handleProofread" v-bind="attrs" v-on="on">
              <v-icon>mdi-spellcheck</v-icon>
            </v-btn>
          </template>
          <span>{{$t('proofread.proofread.tooltip')}}</span>
        </v-tooltip>
    </v-speed-dial>
     
    </div>
  </template>
  
  <script>
  export default {
    props: ['originalText'],
    data() {
      return {
        fab: false,
        loading: false,
        textHistory: [],
        expandDirection: 'top'
      }
    },
    watch: {
      fab(val) {
        if (val) {
          this.checkFabSpace()
        }
      }
    },
    methods: {
      checkFabSpace() {
        // Check available space to determine expansion direction
        this.$nextTick(() => {
          const fabElement = this.$el.querySelector('.v-speed-dial')
          if (fabElement) {
            const rect = fabElement.getBoundingClientRect()
            const availableSpaceAbove = rect.top
            this.expandDirection = availableSpaceAbove < 260 ? 'bottom' : 'top'
          }
        })
      },
      back() {
        let lastElement =  this.textHistory.pop()
        this.$emit('apply', lastElement)
      },
      async friendly() {
        this.sendRequest('make the text more friendly. reply only with the new text')
      },
      async professional() {
        this.sendRequest('make that text more professional. reply only with the new text')
      },
      async rewrite() {
        this.sendRequest('rewrite the text. Act as a ghostwriter. reply only with the new text')
      },
      async handleProofread() {
        this.sendRequest('proofread this text. reply only with the proofread text')
      },
      async sendRequest(systemContent) {
        try {
            this.loading = true
          let config = { 
            url: 'https://irpxjumiq7.execute-api.eu-central-1.amazonaws.com/completion',
            requestContent: JSON.stringify({
              model: 'gpt-4o',
              messages: [ 
                { role: 'user', content: this.originalText },
                { role: 'system', content: systemContent }
              ]
            }),
            headers: { 'Content-Type': 'application/json', 'client_fingerprint' : 'writing-tools-0_1_3f5tg32sed56tzg78ijk' }
          }
          const response = await fetch(config.url, {
            method: 'POST',
            headers: config.headers,
            body: config.requestContent
          })
  
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`)
          }
  
          const data = await response.json()
          this.setText(data.choices[0].message.content)
          this.loading = false

        } catch (error) {
            this.loading = false
            console.error('Proofread API error:', error)
        }
      },

      setText(text) {
        // Implement to set text in the editor or input field
        this.textHistory.push(this.originalText)
        this.$emit('apply', text)
      }
    }
  }
  </script>
  <style lang="scss" scoped>

  .text-field {
    outline: unset;
    width: 100%;
    overflow: hidden;
    height: fit-content;
  }
  </style>
  