import ApiClient from '@/ApiClient/index.js'
import Link from '@/store/models/Link.js'

async function loadLinks(holder) {
  const listLink = holder.getLink('externalHooks')
  const response = await ApiClient.getUri(listLink)
  return response.data.items
    .map(item => new Link(item))
}

export default {
  actions: {
    async AGReadLinkOperation(context, uri) {
      const response = await ApiClient.getUri(uri)
      return new Link(response.data)
    },
    async AGReadSpaceLinks(context, space) {
      return loadLinks(space)
    },
    AGListFormLinksOperation(context, form) {
      return loadLinks(form)
    },
    async AGListEntityEditLinks(context, entity) {
      return loadLinks(entity)
    },
    async AGListGridLinks(context, grid) {
      return loadLinks(grid)
    },
    async AGListViewLinks( context, { statefulView }) {
      return loadLinks(statefulView)
    },
    async AGCreateViewLink(context, { statefulView, role, username, password }) {
      const link = statefulView.getLink('addHook')
      return ApiClient.postUri(link, {
        role,
        ...(username && { username }),
        ...(password && { password })
      })
    },
    async AGCreateEditLink(context, { grid, formId, name}) {
      const link = grid.getLink('editLink')
      const response = await ApiClient.postUri(link, {
        formId,
        name
      })
      return await context.dispatch('AGReadLinkOperation', response.headers.location)
    },
    async AGPostEditLinkEntityUri(context, {link, entity}) {
      const editEntityUri = link.getLink('editEntityUri')
      const response = await ApiClient.postUri(editEntityUri, {
        entityId: entity._id
      })
      return response.data
    },
    async AGPatchLinkNameOperation(context, {link, newName}) {
      const fullLink = await context.dispatch('AGReadLinkOperation', link.getLink('self'))
      const patchLink = fullLink.getLink('patch')
      return ApiClient.patchUri(patchLink, {
        name: newName
      })
    },
    async AGPatchLinkRestrictionOperation(context, {link, role, requiresAuthorization, publicAccess, requiresPassword, username, password}) {
      const fullLink = await context.dispatch('AGReadLinkOperation', link.getLink('self'))
      const patchLink = fullLink.getLink('patch')
      return ApiClient.patchUri(patchLink, {
        role,
        requiresAuthorization,
        publicAccess,
        requiresPassword,
        username,
        password
      })
    },
    async AGPatchViewLinkRestrictionOperation(context, {link,  role, publicAccess, username, password, requiresPassword}) {
      const fullLink = await context.dispatch('AGReadLinkOperation', link.getLink('self'))
      const patchLink = fullLink.getLink('patch')
      return ApiClient.patchUri(patchLink, {
        role,
        publicAccess,
        requiresPassword,
        ...(username && { username }),
        ...(password && { password })
      })
    },
    async AGDeleteLinkOperation(context, {link}) {
      const fullLink = await context.dispatch('AGReadLinkOperation', link.getLink('self'))
      const deleteLink = fullLink.getLink('remove')
      return ApiClient.deleteUri(deleteLink)
    }
  }
}