<template>
<SingleInputDialog
  v-model="dialogModel"
  :title="$t('dialogs.renameFormTitle', { formViewTitle: form.name })"
  :text="$t('dialogs.renameFormText')"
  :okButtonLabel="$t('dialogs.saveButton')"
  :okHandler="save"
  :initialInputValue="form.name"
  :max="$apptive.constants.nameLengths.default"
  max-width="290"
>
  <template v-slot:activator="{ on, attrs }">
    <MenuItem
      v-on="on" v-bind="attrs"
      icon="mdi-pencil"
      :text="$t('dialogs.renameFormButton')"
    />
  </template>
</SingleInputDialog>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import MenuItem from '@/components/MenuItem.vue'

export default {
  name: 'RenameFormDialog',
  props: {
    formUri: null
  },
  data() {
    return {
      dialogModel: false
    }
  },
  computed: {
    form() {
      return this.$store.getters.formWithUri(this.formUri)
    }
  },
  methods: {
    save(newName) {
      if (this.form.name == newName) {
        return
      }
      return this.form.rename(newName)
    }
  },
  components: {
    SingleInputDialog,
    MenuItem
  }
}
</script>
