import Vue from 'vue'
import App from './App.vue'
import store from './store/store.js'
import router from './router'
import { initializeVuetify } from '@/plugins/vuetify' // Adjust the path if needed
import feature from '@/plugins/feature'
import i18n from '@/plugins/i18n' // path to vuetify export
import $apptive from './$apptive.js'
import { loadConfig } from '@/plugins/config'
import { loadWebVersion, webVersion } from '@/plugins/version'
import { initErrorReporter } from '@/plugins/apptiveErrorHandler.js'

Vue.config.productionTip = false

document.documentElement.setAttribute('lang', i18n.locale)
initializeApp()
feature.initialize()

Vue.prototype.$apptive = $apptive


// Initialize the app after loading the config
async function initializeApp() {
  const config = await loadConfig()
  Vue.prototype.$settings = config // Make the settings globally accessible

  const vuetify = initializeVuetify(config)

  // init web version code 
  await loadWebVersion()
  Vue.prototype.$webVersion = webVersion // Make the version globally accessible

  const app = new Vue({
    i18n,
    vuetify,
    router,
    store: store(),
    render: h => h(App)
  })

   // init error reporter 
   const apptiveErrorReporter = initErrorReporter( config.error?.form , webVersion, app )
   Vue.prototype.$apptiveErrorReporter = apptiveErrorReporter
   Vue.config.errorHandler = (error) => apptiveErrorReporter.captureException(error)

   app.$mount('#app')
}