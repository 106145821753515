<template>
  <div class="d-flex">
    <GridHideColumnMenu
      :disabled="!canUpdateGrid"
      class="ml-1"
      :virtualGrid="virtualGrid"
    />
    <GridFilterMenu
      :disabled="!canUpdateFilter"
      :virtualGrid="virtualGrid"
      @mouseenter="$emit('filterMouseEnter')"
      @mouseleave="$emit('filterMouseLeave')"
    />
    <GridSortingMenu
      v-if="sorting"
      :disabled="!canUpdateGrid"
      :virtualGrid="virtualGrid"
    />
    <VirtualGridShareMenu
      v-if="canShareView"
      :virtualGridUri="virtualGridUri"
      :statefulViewUri="statefulViewUri"
      class="ml-3"
    >
      <v-btn
        slot="dialogButton"
        color="accent"
        text
        small>
        <v-icon small class="mr-1">mdi-share</v-icon>
        <span class="d-none d-sm-flex">{{$t('virtualGrid.qrCode.buttonText')}}</span>
      </v-btn>
    </VirtualGridShareMenu>
  </div>
</template>

<script>
import GridFilterMenu from '@/components/gridView/filter/GridFilterMenu.vue'
import GridHideColumnMenu from '@/components/gridView/filter/GridHideColumnMenu.vue'
import GridSortingMenu from '@/components/gridView/sorting/GridSortingMenu.vue'
import VirtualGridShareMenu from '@/components/gridView/GridViewShareMenu.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'

export default {
  props: {
    virtualGrid: {
      default: null
    },
    statefulViewUri: null,
    sorting: {
      default: () => true
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    canUpdateGrid() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.update])
    },
    canUpdateFilter() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.updateFilter])
    },
    canShareView() {
      return hasPermission(this.statefulView, [PERMISSIONS.addShare])
    },
    virtualGridUri() {
      return this.virtualGrid?.uri
    }
  },
  components: {
    GridFilterMenu,
    GridHideColumnMenu,
    GridSortingMenu,
    VirtualGridShareMenu
  }
}
</script>