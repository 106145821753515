<template>
<v-dialog
  v-model="externalModel"
  max-width="450"
>
  <v-sheet>
    <v-toolbar flat dark color="primary" dense>
      <v-toolbar-title class="font-weight-medium">{{$t('flow.condition.title')}}</v-toolbar-title>
    </v-toolbar>
    <div class="pa-5">
      <FlowTransitionCondition
        v-model="condition"
        :flow="flow"
        :transition="transition"
        @delete="clear"
      />
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          text
          class="mr-1 mt-3"
          @click="externalModel = false"
        >{{$t('dialogs.cancelButton')}}</v-btn>
        <v-btn
          :loading="updateConditionLoading"
          class="mt-3"
          color="primary"
          @click="updateCondition"
        >{{$t('flow.condition.saveButton')}}</v-btn>
      </div>
    </div>
  </v-sheet>
</v-dialog>
</template>

<script>
import externalModel from '../../mixins/externalModel'
import FlowTransitionCondition from './FlowTransitionCondition.vue'

export default {
  mixins: [externalModel],
  props: {
    flow: null,
    transition: null
  },
  data() {
    return {
      condition: undefined,
      updateConditionLoading: false
    }
  },
  watch: {
    transition: {
      immediate: true,
      handler(newVal) {
        this.condition = newVal.condition
      }
    }
  },
  methods: {
    clear() {
      this.condition = null
      this.updateCondition(false)
    },
    async updateCondition(close = true) {
      try {
        this.updateConditionLoading = true
        await this.transition.updateCondition(this.condition)
        if (close) {
          this.externalModel = false
        }
      } finally {
        this.updateConditionLoading = false
      }
    }
  },
  components: {
    FlowTransitionCondition
  }
}
</script>
