import { ASHolder } from '@/apptivescript/model/ASHolder'

export default class ASTemplateHolder extends ASHolder{
  constructor(data) {
    super()
    this._template = data._template
  }

  get value() {
    return this._template
  }

    // HACK : this is used to keep the curly braces around
  // an expression when building a SpecialisedHttpRequestNode mapped property
  // (see SpecialisedHttpRequestNode:updateProperties)
  get componentOutputValue() {
    return `${this.value}`
  }
}