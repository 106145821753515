<template>
  <v-select
    ref="input"
    class="user-select"
    v-model="selectModel"
    :items="items"
    :menu-props="{'content-class': 'reference-cell-menu', 'bottom': true, 'offset-y': true}"
    :readonly="readonly"
    :append-icon="null"
    :clearable="showControls && !readonly"
    :clear-icon="clearIcon"
    @focus="onFocus"
    @blur="$emit('blur')"
    v-bind="$attrs"
    data-testid="userSelect"
    :multiple="isCollection"
    :deletable-chips="isCollection"
  >
    <template v-slot:item="slotProps">
      <v-list-item dense v-on="slotProps.on" :key="slotProps.item.key" class="select-row-item py-1">
        <v-chip
          pill
          small
        >
          <v-avatar
            left
            max-height="24"
            max-width="24"
          >
            <v-img
              :src="slotProps.item.avatarUrl"
              lazy-src="avatar.svg"
              max-height="24"
              max-width="24"
            />
          </v-avatar>
          {{slotProps.item.text}}
        </v-chip>
      </v-list-item>
    </template>
    <template v-slot:selection="slotProps">
      <v-chip
        pill
        small
      >
        <v-avatar
          left
          max-height="24"
          max-width="24"
        >
          <v-img
            :src="avatarUrlOf(slotProps.item.value.uri.split('/').pop())"
            lazy-src="avatar.svg"
            max-height="24"
            max-width="24"
          />
        </v-avatar>
        {{slotProps.item.text}}
      </v-chip>
    </template>
    <template v-slot:prepend-item>
      <v-list-item dense class="pa-0">
        <v-text-field
          v-model="filter"
          @input="query"
          :placeholder="$t('virtualGrid.enumFilterPlaceholder')"
          solo
          flat
          dense
          clearable
          hide-details
          :loading="loading"
          data-testid="entitySelectInput"
        />
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: null,
    field: null,
    readonly: null,
    showControls: {
      type: Boolean,
      default: true
    },
    isCollection: {
      type: Boolean,
      default: false
    },
    preventSelectionScroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filter: '',
      queryResult: undefined,
      loading: false
    }
  },
  watch: {
    field() {
      this.queryResult = undefined
    }
  },
  mounted() {
    //HACK prevents v-select scrolling when clicking
    // combined with overflow: hidden, prevents AC-1402
    if(this.preventSelectionScroll) {
      const el = this.$el.querySelector('.v-select__selections')
      if (el) {
        el.addEventListener('click', () => {
          el.scrollTo(0, 0)
        })
      }
    }
  },
  computed: {
    initialItems() {
      if (!this.value) return []
      if (this.isCollection) {
        return this.value.map((ref, index) => {
          return {
            key: `initial${index}`,
            value: ref,
            text: ref?.displayValue,
            avatarUrl : this.avatarUrlOf(ref?.uri.split('/').pop())
          }
        })
      }
      return [{
        key: 'initial',
        value: this.value,
        text: this.value?.displayValue,
        avatarUrl : this.avatarUrlOf(this.value?.uri.split('/').pop())
      }]
    },
    selectModel: {
      get() {
        if (this.isCollection) {
          return this.items.filter((item) => {
            return this.value?.some(ref => ref.uri === item.value.uri)
          })
        } else {
          const item = this.items.find((item) => {
            return item.value?.uri === this.value?.uri
          })
          return item
        }
      },
      set(newVal) {
        this.$emit('input', newVal ?? null)
        this.$emit('blur')
      }
    },
    items() {
      if (!this.queryResult) {
        return this.value ? this.initialItems : []
      }
      let items = this.queryResult
        .map((user, index) => {
          return {
            key: user.uri,
            value: user,
            index: index,
            text: user.displayValue,
            avatarUrl : this.avatarUrlOf(user.id)
          }
        })
        // If the initial value is not included in the query result,
        // add it to the select items
        if (this.value != null && !items.some(item => item.value.uri === this.value.uri)) {
          items = items.concat(this.initialItems)
        }
        return items
    },
    appendIcon() {
      return this.showControls ? '$dropdown' : null
    },
    clearIcon() {
      return this.showControls ? '$clear' : null
    }
  },
  methods: {
    onFocus() {
      this.initialQuery()
      this.$emit('focus')
    },
    initialQuery() {
      if (!this.queryResult){
        this.query()
      }
    },
    async query() {
      if (!this.filter) {
        // make sure the filter is not null, this happens when the field was cleared
        this.filter = ''
      }
      if (this.field) {
        this.loading = true
        this.queryResult = await this.$store.dispatch('AGQueryUsersOperation', {
          field: this.field,
          query: this.filter
        })
          .finally(() => this.loading = false)
      } else {
        this.queryResult = undefined
      }
    },
    select() {
      this.$refs.input.focus()
      this.$refs.input.activateMenu()
    },
    avatarUrlOf(userId) {
      return this.$store.getters.avatarUrlOf(userId)
    }
  }
}
</script>

<style>
.reference-cell-menu {
  overflow-x: auto;
  background: white;
  padding: 8px;
}
</style>

<style scoped>
.v-text-field {
  padding-top: 0;
  margin-top: 0;
}

.select-row-item {
  min-height: unset;
}
</style>

<style scoped lang="scss">
::v-deep.user-select {
  .v-input__slot {
    min-height: unset;
    padding: 0 !important;
    background: unset !important;
  }

  .v-select__selections {
    flex-wrap: unset;
    overflow: hidden;
  }

  .v-input__append-inner {
    background: white;
    z-index: 1;
  }

  .v-chip--select {
    flex: 0 0 auto;
  }

  // Allow the chip in the select to be clicked through
  .v-select__selections .v-chip {
    pointer-events: none;
  }

  .v-chip__close {
    pointer-events: all;
  }

  //AC-1441 Improving Readonly Views readability
  .v-chip--disabled {
    opacity: 1;
  }
}
</style>
