<template>
  <v-dialog :disabled="disabled" v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <MenuItem
        :disabled="disabled"
        v-on="on" v-bind="attrs"
        icon="mdi-delete"
        :text="$t('dialogs.deleteVirtualGridButton')"
        data-testid="deleteVirtualGridDialogButton"
      />
    </template>
    <v-card color="black">
      <v-card-title
        class="text-h5 white--text word-break"
        v-html="title"
      />
      <v-card-text class="white--text">{{
        $t("dialogs.areYouSure")
      }}</v-card-text>
      <v-card-text class="white--text">{{
        $t("dialogs.deleteVirtualGridInfo")
      }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-1"
          text
          @click="onCancelPressed"
          data-testid="cancelButton"
        >
          {{ $t("dialogs.cancelButton") }}
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          data-testid="deleteButton"
          @click="onDeletePressed"
        >
          {{ $t("dialogs.deleteButton") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'

export default {
  props: {
    virtualGrid: null,
    statefulView: null,
    disabled: null
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    title() {
      if (this.statefulView != null) {
        return this.$t('dialogs.deleteVirtualGridTitle', {gridTitle: this.statefulView.name})
      }
      return this.$t('dialogs.deleteVirtualGridTitle', {gridTitle: this.virtualGrid?.title()})
    }
  },
  methods: {
    onCancelPressed() {
      this.dialog = false
    },
    onDeletePressed() {
      this.$store
        .dispatch('AGRemoveVirtualGridOperation', {
          virtualGridUri: this.virtualGrid.uri
        })
        .then(() => {
          this.$emit('viewDeleted')
          if (this.$route.params.statefulViewUri === this.statefulView.uri) {
            this.$router.push({name: 'Grid', params: {gridUri: this.$route.params.gridUri}})
          }
        })
    }
  },
  components: {
    MenuItem
  }
}
</script>

<style scoped lang="css">
.normal-text {
  font-weight: normal;
}

.word-break {
  word-break: break-word;
}
</style>
