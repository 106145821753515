<template>
  <div
    class="footer d-flex align-center"
    :style="{ width: width + 'px'}"
  >

  <div class="d-flex align-center sticky" v-if="virtualGrid.selectedEntities.length" >
    <EntityBatchDelete
      :virtualGrid="virtualGrid">
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          v-on="on" v-bind="attrs" 
          x-small text 
          data-testid="openBatchDelete"
          color="destructive"> 
          <v-icon small class="mr-1">mdi-delete</v-icon>
          {{$t('virtualGrid.batchDelete.openDialogButton', { numberOfEntities: virtualGrid.selectedEntities.length}) }}
          <span v-if="virtualGrid.selectedEntities.length >= batchDelete.maxEntities">(max. {{batchDelete.maxEntities}})</span>
      </v-btn>
      </template>
      </EntityBatchDelete>
      
      <v-btn x-small @click="virtualGrid.deselectAllEntities()" text v-if="virtualGrid.selectedEntities.length" color="primary"> {{$t('virtualGrid.batchDelete.cancel')}} </v-btn>
    </div>
    <div class="d-flex align-center sticky" v-else>
    <v-btn
        v-if="!loading && addButton"
        :disabled="disabled"
        color="grey"
        elevation="0"
        icon
        width="28"
        height="28"
        @click="addRow"
        data-testid="addRowButton"
        data-tutorial="add-row-button"
      >
        <v-icon >mdi-plus</v-icon>
    </v-btn>
    <v-progress-circular
      v-else-if="loading"
      indeterminate
      :width="1"
      :size="16"
      color="rgb(158, 158, 158)"
      class="ml-2"
    />

    
      <span :data-testid="`entitiesCount-${numberOfEntities}`" v-if="numberOfEntities > 3" class="caption ml-2 accent--text">{{ $t('entityCount', { count: numberOfEntities })  }}</span>
    </div>
  </div>
</template>

<script>
import EntityBatchDelete from './EntityBatchDelete.vue'
import batchDelete from '@/constants/batchDelete.js'

export default {
    props: {
        width: null,
        disabled: null,
        loading: null,
        virtualGrid: null,
        addButton: {
          type: Boolean,
          default: true
        }
    },
    data() {
      return {
        batchDelete: batchDelete
      }
    },
    methods: {
        addRow() {
            this.$emit('addRow')
        }
    },
    computed: {
      numberOfEntities() {
        return this.virtualGrid?.numberOfEntities()
      }
    },
    components: { EntityBatchDelete }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  height: 32px;

  margin-left: 0px;
  background-color: white;
}

.sticky {
  position: sticky;
  left: 4px;
}
</style>
