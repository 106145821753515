<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <img width="22" height="22" class="" src="/color_wheel.png" alt="">
      </v-btn>
    </template>

    <v-color-picker :swatches="swatches" :show-swatches="swatches != null" v-model="externalModel" mode="hexa"/>
  </v-menu>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'
export default {
  mixins: [externalModel],
  props: ['swatches'],
  data() {
    return {
      menu: false,
    }
  },
  watch: {
    menu(newVal) {
      if (newVal) return
      this.$emit('change', this.externalModel)
    }
  }
}
</script>

<style>

</style>
