<template>
  <div class="d-flex align-center gap-3">
    <span class="param-label flex-shrink-0">{{ label }}</span>
    <template v-if="!noPicker">
      <v-btn
        v-if="externalModel === undefined"
        class="px-3 py-2"
        color="primary lighten-0"
        elevation="0"
        text
        @click="externalModel = null"
      >{{ placeholder }}</v-btn>
      <FormFieldsFactory
        v-else
        class="flex-grow-1"
        v-model="externalModel"
        :form="form"
        :component="fieldComponent"
        :fieldStyle="fieldStyle"
      />
      <v-btn
        v-show="externalModel !== undefined"
        @click="externalModel = undefined"
        icon
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <v-select
      v-else
      outlined dense hide-details
      v-model="toggle"
      :items="toggleItems"
    />
  </div>
</template>

<script>
import externalModel from '../../mixins/externalModel'
import FormFieldsFactory from '../form/FormFieldsFactory.vue'
import Form from '@/store/models/Form.js'
import FormComponent from '@/store/models/FormComponent.js'
import formFieldStyles from '@/constants/formFieldStyles.js'

export default {
  mixins: [ externalModel ],
  props: {
    grid: null,
    fieldId: null,
    label: null,
    placeholder: null,
    noPicker: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    toggle: {
      get() {
        return this.externalModel === undefined ? 0 : 1
      },
      set(newVal) {
        this.externalModel = (newVal === 0 ? undefined : null)
      } 
    },
    toggleItems() {
      return [
        {
          text: this.$t('entityUpdatedCondition.anyValue'),
          value: 0
        },
        {
          text: this.$t('entityUpdatedCondition.noValue'),
          value: 1
        },
      ]
    },
    form() {
      if (this.grid == null) {
        return undefined
      }
      return new Form({
        fields: this.grid.fields,
        components: []
      })
    },
    fieldComponent() {
      if (this.fieldId == null || this.grid == null) return
      const field = this.grid.fields.find(field => field.id === this.fieldId)
      return new FormComponent(
        field.name,
        field.id,
        field.columnType.entityUpdatedConditionFormFieldType ?? field.columnType.defaultFormFieldType
      )
    },
    fieldStyle() {
      return formFieldStyles.outlinedWithoutLabel
    },
  },
  components: {
    FormFieldsFactory
  }
}
</script>

<style scoped>
.param-label {
  width: 50px;
}

.param-placeholder {
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
}
</style>