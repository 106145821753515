<template>
  <v-card elevation="0" v-if="webhook" v-bind="$attrs">
    <v-card-title>
      {{title}}
    </v-card-title>
    <v-card-text class="pl-6 pr-6">
      <v-text-field
        v-if="showNameField"
        :disabled="!canSaveWebhook"
        v-model="webhook.name"
        label="Name"
        persistent-hint
        data-testid=""
      ></v-text-field>
      <WebhookTriggerEvent
        :disabled="!canSaveWebhook"
        :webhook="webhook"
        :spaceUri="spaceUri"
        :event="event"
      />
      <div v-if="!hideUriInput">
        <div class="text-body-2">
          {{ $t("webhooks.description") }}
        </div>
        <v-text-field
          v-if="webhook.type == 'internalFlowTrigger'"
          v-model="webhook.flowUri"
          label="Flow"
          :disabled="true"
          persistent-hint
          data-testid="webhookWebHookURLInput"
        ></v-text-field>
        <v-text-field
          v-else
          v-model="webhook.webUri"
          label="URL"
          hint="https://my-webhook.com/example"
          persistent-hint
          data-testid="webhookWebHookURLInput"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="!canDeleteWebhook || invalid"
        color="grey darken-1"
        text
        @click="deleteHook">
        {{$t("dialogs.deleteButton")}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="showCancelButton" color="grey darken-1" text @click="closeDialog">
        {{$t("dialogs.cancelButton")}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        :disabled="!canSaveWebhook || invalid"
        @click="saveHook"
        data-testid="webhookSaveButton"
        :loading="loading"
      >
        {{$t("webhooks.buttons.save")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import WebhookTriggerEvent from '@/components/webhooks/WebhookTriggerEvent.vue'
import { Webhook } from '@/store/models/flow/webhooks/Webhook'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'

export default {
  props: {
    hookUri: null,
    flowUri: null,
    spaceUri: null,
    hideUriInput: {
      default: false
    },
    showCancelButton: {
      type: Boolean,
      default: () => true
    },
    showNameField: {
      type: Boolean,
      default: () => true
    },
    title: {
      type: String
    },
    event: null
  },
  data() {
    return {
      loading: false,
      webhookDraft: null,
      space: undefined
    }
  },
  mounted() {
    this.webhookDraft = new Webhook({})
    if(this.flowUri){
      this.webhookDraft.type = 'internalFlowTrigger'
      this.webhookDraft.flowUri = this.flowUri
    }
  },
  watch: {
    selectedSpaceUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null) {
          return
        }
        this.space = this.$store.getters.spaceWithUri(newVal)
        if (this.space == null) {
          this.space = await this.$store.dispatch('AGReadSpaceOperation', newVal)
        }
      }
    }
  },
  computed: {
    webhook() {
      if (this.hookUri == null) {
        return this.webhookDraft
      }
      return this.$store.getters.webhookWithUri(this.hookUri)
    },
    invalid() {
      return this.webhook.validate() == false
    },
    selectedSpaceUri() {
      return this.webhook?.spaceUri() ?? this.spaceUri
    },
    canSaveWebhook() {
      if (this.webhook.isLocal()) {
          return hasPermission(this.space, [PERMISSIONS.addHook])
        } else {
          return hasPermission(this.webhook, [PERMISSIONS.update])
        }
    },
    canDeleteWebhook() {
      return hasPermission(this.webhook, [PERMISSIONS.remove])
    }
  },
  methods: {
    async saveHook() {
      this.loading = true
      try {
        if (this.webhook.isLocal()) {
          await this.$store.dispatch('AGPostWebhook', this.webhook)
          this.$intercom?.trackEvent( 'User created a automation' )
        } else {
          await this.$store.dispatch('saveWebhook', this.webhook)
        }
        if (this.flowUri != null) {
          await this.$store.dispatch('AGReadFlowOperation', this.flowUri)
          await this.$store.dispatch('AGReadFlowHookOperation', this.flowUri)
        }
        this.closeDialog()
      } finally {
        this.loading = false
      }

    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    deleteHook() {
      this.$emit('deleteHook', this.webhook)
    }
  },
  components: {
    WebhookTriggerEvent
  }
}
</script>

<style lang="css"></style>
