<template>
  <div class="mt-n3 mb-3 ">

  <v-list dense >
    <v-list-item>
     <v-list-item-content>
      <div class="subtitle-2 accent--text">{{$t('forms.builder.label')}}</div>
      <v-text-field
       v-model="label"
       class="textField"
       solo dense flat
       elevation="0"
       hide-details
       :placeholder="this.field.name"
       data-testid="builder-field-label-input"
       />
     </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <div class="subtitle-2 accent--text">{{$t('forms.builder.description')}}</div>
        <v-text-field
         v-model="description"
         :placeholder="$t('forms.builder.description')"
         class="textField"
         solo dense flat
         elevation="0"
         hide-details
         data-testid="builder-field-description-input"
       />
      </v-list-item-content>
    </v-list-item>

    <div class="spacer mt-3"></div>

    <ColumnSettingsMenu
      :virtualGrid="grid"
      :field="field"
      @columnUpdated="reloadForm"
    >
      <template v-slot:customActivator="{ on }">
        <v-list-item v-on="on">
          <v-list-item-content>
            <span class="subtitle-2 accent--text ">{{$t('forms.builderFormField.fieldSettings')}}</span>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon small>mdi-cog-outline</v-icon>
          </v-list-item-action>
      </v-list-item>
      </template>
    </ColumnSettingsMenu>

    <v-list-item>
      <v-list-item-content>
        <span class="subtitle-2 accent--text ">{{$t('forms.builderFormField.required')}}</span>
      </v-list-item-content>
      <v-list-item-action>
        <v-switch
          class="ma-0 pa-0"
          hide-details
          dense
          v-model="required"
          data-testid="builder-field-required-switch"/>
      </v-list-item-action>
  </v-list-item>

    <v-list-item v-if="hasMultiline">
      <v-list-item-content>
        <span class="subtitle-2 accent--text ">{{$t('forms.builderFormField.multiline')}}</span>
      </v-list-item-content>
      <v-list-item-action>
        <v-switch
          class="ma-0 pa-0"
          hide-details
          dense
          v-model="multiline"
          data-testid="builder-field-multiline-switch"/>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-if="componentTypes">
      <v-select
        :label="$t('forms.builderFormField.componentType')"
        :items="componentTypes"
        :value="component.type"
        @input="updateComponentType"
      />
    </v-list-item>

    <v-list-item v-if="componentTypeOverrides && alphaMode">
      <v-select
        :label="$t('forms.builderFormField.componentType')"
        :items="componentTypeOverrides"
        :value="typeOverride"
        @input="updateComponentTypeOverride"
      />
    </v-list-item>

    <PaywallMenu :feature="$apptive.constants.features.FORM_DEFAULT_VALUES">

  <v-list-item>
      <v-list-item-content>
          <div class="subtitle-2 accent--text">{{$t('forms.builderFormField.defaultValueTitle')}}</div>
        </v-list-item-content>
        <v-list-item-action>
        <v-switch
          class="ma-0 pa-0"
          hide-details
          dense
          @change="defaultValueSwitchChanged"
          v-model="defaultValueSwitch"
          data-testid="builder-field-defaultValue-switch"/>
        </v-list-item-action>
    </v-list-item>

    <v-list-item v-if="defaultValueSwitch">
      <v-list-item-content class="overflow-auto">
          <FormFieldsFactory
            class="mt-n1"
            :key="component.fieldId"
            :component="settingsComponent"
            :form="form"
            v-model="defaultValue"
            :hidden="false"
            data-testid="builder-field-defaultValue-input"
          ></FormFieldsFactory>
        </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="defaultValueSwitch">
      <v-list-item-content>
          <div class="subtitle-2 accent--text">{{$t('forms.builderFormField.hidden')}}</div>
        </v-list-item-content>
        <v-list-item-action>
        <v-switch
          class="ma-0 pa-0"
          hide-details
          dense
          :disabled="defaultValue == null"
          v-model="hidden"
          data-testid="builder-field-hidden-switch"/>
        </v-list-item-action>
    </v-list-item>
  </PaywallMenu>

  <PaywallMenu :feature="$apptive.constants.features.FORM_READ_ONLY_FIELDS">
    <v-list-item>
        <v-list-item-content>
          <span class="subtitle-2 accent--text ">{{$t('forms.builderFormField.disabled')}}</span>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
            class="ma-0 pa-0"
            hide-details
            dense
            v-model="disabled"
            :disabled="required"
            data-testid="builder-field-disabled-switch"/>
        </v-list-item-action>
    </v-list-item>
  </PaywallMenu>


  </v-list>
    <div class="d-flex justify-end">
      <v-btn
        class="ma-3"
        text
        color="primary"
        @click="done"
      >
        {{ $t("dialogs.saveButton") }}
        </v-btn>
      </div>
    </div>
</template>

<script>
import ColumnSettingsMenu from '@/components/gridView/ColumnSettingsMenu.vue'
import FormFieldsFactory from '../FormFieldsFactory.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'

export default {
  props: ['component', 'form', 'spaceUri', 'grid'],
  data() {
    return {
      defaultValueSwitch: false,
    }
  },
  mounted() {
    this.defaultValueSwitch = this.defaultValue != null
  },
  computed: {
    alphaMode() {
      return this.$store.getters.alphaMode
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri) ?? {}
    },
    field() {
      return this.form.fields.find(field => field.id === this.component.fieldId)
    },
    type() {
      return this.field.columnType
    },
    componentTypes() {
      return this.type.formFieldTypes?.map(type => ({
        text: this.$t(`forms.builderFormField.componentTypes.${type}`),
        value: type
      }))
    },
    componentTypeOverrides() {
      return this.type.formFieldTypesOverride?.map(type => ({
        text: this.$t(`forms.builderFormField.componentTypes.${type}`),
        value: type
      }))
    },
    label: {
      get () {
        return this.component.options.label
      },
      set (value) {
        this.component.options.label = value || null
      }
    },
    description: {
      get () {
        return this.component.options.description
      },
      set (value) {
        this.component.options.description = value
      }
    },
    required: {
      get () {
        return this.component.required
      },
      set (value) {
        this.component.required = value
        // readonly and required does not make sence
        if(value == true) {
          this.disabled = false
        }
      }
    },
    disabled: {
      get () {
        return this.form.fieldProperties[this.component.fieldId]?.disabled
      },
      set (value) {
        this.form.setFieldProperty( this.component.fieldId, 'disabled' , value )
      }
    },
    hidden: {
      get () {
        return this.form.fieldProperties[this.component.fieldId]?.hidden
      },
      set (value) {
        this.form.setFieldProperty( this.component.fieldId, 'hidden' , value )
      }
    },
    defaultValue: {
      get () {
        return this.form.fieldProperties[this.component.fieldId]?.defaultValue
      },
      set (value) {
        this.form.setFieldProperty( this.component.fieldId, 'defaultValue', value )
        this.component.value = value
        if(value == null) {
          this.hidden = false
        }
      }  
    },
    hasMultiline() {
      return 'multi' in this.component.options
    },
    multiline: {
      get () {
        return this.component.options.multi
      },
      set (value) {
        this.component.options.multi = value
      }
    },
    settingsComponent() {
      const comp = Object.assign( {} , this.component )
      comp.settingMode = true
      return  comp
    },
    typeOverride() {
      return this.form.fieldProperties[this.component.fieldId]?.typeOverride
    }
  },
  methods: {
    done() {
      this.$emit('finishComponentDetails', this.component)
    },
    updateComponentType(type) {
      this.form.changeComponentType(this.component, type)
    },
    updateComponentTypeOverride(type){
      this.form.setFieldProperty( this.component.fieldId, 'typeOverride' , type )
    },
    defaultValueSwitchChanged(value) {
      if(!value) {
        // delete defaultValue
        this.form.deleteFieldProperty( this.component.fieldId, 'defaultValue' )
        // unhide field
        this.form.deleteFieldProperty( this.component.fieldId, 'hidden' )
      }
    },
    reloadForm() {
      this.form.reload()
    }
  },
  components: {
    FormFieldsFactory,
    PaywallMenu,
    ColumnSettingsMenu
  }
}
</script>

<style lang="css" scoped>
.textField >>> .v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.overflow-auto {
  overflow: auto;
}
</style>
