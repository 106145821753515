<template>
  <transition name="fade">
    <div v-show="show" class="loading-overlay" :class="{border: error}" @click="reload">
      <v-progress-circular
        v-if="!error"
        :width="2"
        :size="32"
        indeterminate
        color="primary"
      />
      <v-icon size="32" color="primary" v-else>refresh</v-icon>
    </div>
  </transition>
</template>

<script>
// Shows a loading overlay. The overlay is absolute and requires the parent
// to have position:relative to be positioned properly

export default {
  props: {
    show: {},
    error: {},
    reloadHandler: {
      default: () => {}
    }
  },
  methods: {
    reload(event) {
      if (this.error) {
        this.reloadHandler()
        event.stopPropagation()
      }
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.loading-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  left: 0;
  z-index: 1;
}

.border {
  border: 1px dotted grey;
  background-color: #f1f1f1;
}
</style>
