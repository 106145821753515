<template>
  <v-dialog
    v-model="externalModel"
    v-bind="$attrs"
    max-width="350"
  >
  <v-card>
    <v-card-title class="text-h5" v-text="$t('dialogs.extractFieldTitle')"/>
    <v-card-text
      >{{$t('dialogs.extractFieldText')}}
      <v-form ref="form">
      <v-select
        v-model="fields"
        multiple
        chips
        :items="items"
        class="my-3"
        :rules="rules.required"
        solo outlined hide-details flat
        @keyup.enter.native="okPressed"
      />
      {{$t('dialogs.extractFieldGridNameText')}}
      <v-text-field
        v-model="gridName"
         solo outlined flat dense
         class="mt-3"
        :rules="rules.required"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey darken-1" text @click="externalModel = false">
        {{$t("dialogs.cancelButton")}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click="okPressed"
        :loading="loading"
        data-testid="dialogOkButton"
      >
        {{$t('dialogs.extractFieldButton')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import { columnTypes } from '@/constants/columnTypes.js'
import {textFieldRules} from '@/mixins/textFieldRules.js'

export default {
  mixins: [externalModel, textFieldRules],
  props: {
    virtualGrid: null,
  },
  data() {
    return {
      loading: false,
      fields: undefined,
      gridName: null
    }
  },
  computed: {
    items() {
      if (!this.virtualGrid) {
        return []
      }
      return this.virtualGrid.fields
        .filter(field => hasPermission(field, [PERMISSIONS.extractToGrid]))
        .filter(field => !field.hasOneOfTypes( [columnTypes.references, columnTypes.sumup, columnTypes.lookup, columnTypes.formula, columnTypes.crossReference] ))
        .map(field => ({
          text: field.name,
          value: field
        }))
    },
    space() {
      if (!this.virtualGrid) {
        return
      }
      return this.$store.getters.spaceIncludesGridWithUri(this.virtualGrid.parentGrid().uri)
    }
  },
  methods: {
    async okPressed() {
      if(!this.$refs.form.validate()) return

      this.loading = true
      try {
        await this.$store.dispatch('AGExtractFieldsOperation', {
          grid: this.virtualGrid,
          fieldIds: this.fields.map( item => item.id),
          gridName: this.gridName
        })
        const gridList = await this.$store.dispatch('loadGridsUris', this.space.uri)
        const lastGrid = gridList[gridList.length - 1]
        const grid = await this.$store.dispatch('AGReadGridOperation', { uri: lastGrid.uri })
        const view = await this.$store.dispatch('AGAddSpreadsheetViewOperation', { grid })
        this.externalModel = false
        this.$router.push({name: 'VirtualGrid', params: { gridUri: grid.uri, statefulViewUri: view.uri }})
      } finally {
        this.loading = false
      }
    }
  }
}
</script>