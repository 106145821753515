<template>
  <v-menu
    ref="menu"
    v-model="externalModel"
    offset-x
    nudge-left="470"
    :max-width="$vuetify.breakpoint.smAndDown ? null : '800px'"
    max-height="80vh"
    :close-on-content-click="false"
    :close-on-click="false"
    v-bind="$attrs"
  >
    <v-card v-if="pickerValue != null" class="pb-3 px-3 d-flex flex-column expression-editor-menu-body">
      <v-card-title>
        <span>{{$t('expressionEditor.menuTitle')}}</span>
        <v-spacer/>
        <v-btn icon small @click="externalModel = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="d-flex align-center">
        <div class="text-caption mr-1">{{$t('expressionEditor.expressionLabel')}}</div>
        <v-menu
              bottom
              dense
            >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pt-0 pb-0" dense>
            <v-list-item  @click="displayRaw" v-if="!rawTextMode">
              <v-list-item-title>{{$t('formulaOptions.showAsText')}}</v-list-item-title>
            </v-list-item>
            <v-list-item  @click="displayToken" v-else>
              <v-list-item-title>{{$t('formulaOptions.showAsToken')}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <ApptiveExpressionInput
        v-show="!rawTextMode"
        ref="input"
        v-model="expressionEdit"
        :flow="flow"
        @change="computeLocalProperties"
        @blur="() => expressionEditHandler(expressionEdit)"
      />
      <v-textarea
        v-show="rawTextMode"
        rows="3"
        ref="rawInput"
        v-model="expressionEdit"
        hide-details solo
        @blur="() => expressionEditHandler(expressionEdit)"
      />
      <v-list-group
        class="mt-1"
        v-model="resultGroup"
        v-if="evaluationResult != null"
      >
        <template v-slot:activator>
          <div class="text-caption ml-n2">{{$t('expressionEditor.sampleResult')}}</div>
        </template>
        <div class="accent--text mb-3">{{ evaluationSampleType }}</div>
        <div class="sample-value-display">{{ evaluationSampleResult }}</div>
      </v-list-group>
      <ExpressionPicker
        class="flex-shrink-0 mt-3"
        @input="addExpression"
        :supportedTypes="supportedTypes"
        :methods="methods"
        :showOperators="showOperators"
        customTabTitle="Flow"
      >
        <template v-slot:customTab="{ onItemPicked }">
          <FlowTreeView
            draggable
            :value="pickerValue"
            :flow="flow"
            :filterNode="filterNode"
            :search="search"
            :openAll="searchFieldActive"
            @itemClicked="onItemPicked"
            @itemDragged="onTokenDragStart"
            @update:open="activate"
          />
          <div class="ma-3">
            <div class="subtitle-2 mb-2">{{$t('flow.variablePicker.flowVariables')}}</div>
            <Token
              v-for="item in globalExpressionItems"
              class="mx-1 mb-1"
              :key="item.key"
              :text="item.text"
              :value="item.value"
              :color="item.color"
              :draggable="true"
              @dragstart.native="() => onTokenDragStart({expression: () => item.value})"
              @click.native="onItemPicked(item.value)"
            />
          </div>
        </template>
      </ExpressionPicker>
    </v-card>
    <v-card class="pa-3 d-flex flex-column" v-else>
      <v-btn
        icon
        class="align-self-end mr-2"
        @click="externalModel = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="text-center text-h6">{{$t('flow.variablePicker.headerEmpty')}}</div>
      <div class="text-center text-body-1">{{$t('flow.variablePicker.descriptionEmpty')}}</div>
      <FlowTestRunner
        class="mt-4 align-self-center"
        :flow="flow"
      />
    </v-card>
  </v-menu>
</template>

<script>
/* global ASParser ASFlowInstanceScope */
import ApptiveExpressionInput from '@/components/ApptiveExpressionInput.vue'
import ExpressionPicker from '@/components/ExpressionPicker.vue'
import FlowTestRunner from '@/components/flow/FlowTestRunner.vue'
import FlowTreeView from '@/components/flow/FlowTreeView.vue'
import Token from '@/components/flow/Token.vue'
import variablePickerBus from '@/components/flow/variablePickerBus'
import { columnTypes } from '@/constants/columnTypes'
import { AGOwnerEmailExpression, AGOwnerFirstNameExpression, AGOwnerLastNameExpression } from '@/constants/expressions/index.js'
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [externalModel],
  props: {
    flow: null,
    pickerValue: null,
    filterNode: null,
  },
  mounted() {
    variablePickerBus.onExpressionEdit(this.onExpressionEdit)
  },
  beforeDestroy() {
    variablePickerBus.expressionEditOff(this.onExpressionEdit)
  },
  data() {
    return {
      expressionEdit: undefined,
      rawTextMode: false,
      expressionEditHandler: () => { },
      resultGroup: false,
      evaluationResult: undefined,
      methods: [],
      search: null,
      globalExpressions: [
        AGOwnerEmailExpression,
        AGOwnerFirstNameExpression,
        AGOwnerLastNameExpression,
      ],
    }
  },
  computed: {
    evaluationSampleResult() {
      if (this.evaluationResult == null) {
        return null
      }
      let value = this.evaluationResult.value
      try {
        const prettified = JSON.stringify(value, null, 2)
        return prettified
      } catch {
        return value
      }
    },
    evaluationSampleType() {
      if (this.evaluationResult == null) {
        return null
      }
      return this.$t('expressionEditor.typeDisplay', {type: this.evaluationResult.constructor.as_apptiveExternalName()})
    },
    supportedTypes() {
      return [
        columnTypes.string,
        columnTypes.integer,
        columnTypes.decimal,
        columnTypes.dateTime,
        columnTypes.date,
        columnTypes.createdAt,
        columnTypes.boolean,
        columnTypes.currency,
      ]
    },
    showOperators() {
      const numericalTypes = [
        'ASNumber',
        'ASDecimal',
        'ASInteger'
      ]
      return numericalTypes.includes(this.expressionReturnType)
    },
    searchFieldActive() {
      return this.search ? this.search.length > 0 : false
    },
    globalExpressionItems() {
      return this.globalExpressions.map(expression => {
        const instance = new expression()
        return {
          key: instance.exportValue(),
          text: instance.displayString(),
          value: instance.exportValue(),
          color: instance.color()
        }
      })
    },
  },
  watch: {
    expressionEdit: {
      immediate: true,
      handler(newVal) {
        this.computeLocalProperties(newVal)
      }
    },
    tab() {
      setTimeout(() => {
        this.activate()
      }, 0)
    },
    resultGroup() {
      setTimeout(() => {
        this.activate()
      }, 0)
    },
    rawTextMode() {
      setTimeout(() => {
        this.activate()
      }, 0)
    }
  },
  methods: {
    onExpressionEdit(expression, handler) {
      this.expressionEdit = expression
      this.expressionEditHandler = handler
    },
    computeLocalProperties(expression) {
      this.invalidExpression = true
      this.methods = []
      this.expressionReturnType = undefined
      this.evaluationResult = undefined
      if (!expression) {
        return
      }

      this.expressionReturnType = undefined
      try {
        const ast = ASParser.as_parse_(expression)
        const scope = new ASFlowInstanceScope()
        scope.as_onStepDo_((stepId) => this.pickerValue[ stepId ])
        const evaluated = ast.as_evaluateIn_(scope)
        this.evaluationResult = evaluated
        const localProperties = evaluated.as_localProperties()
        const returnType = evaluated.constructor.name
        this.expressionReturnType = returnType

        if (localProperties != null) {
          this.methods = localProperties
        }
        this.invalidExpression = false
      } catch (error) {
        console.error(error)
      }
    },
    async insert(expressionString) {
      if (this.rawTextMode) {
        const textArea = this.$refs.rawInput.$el.querySelector('textarea')
        const selectionStart = textArea.selectionStart
        const selectionEnd = textArea.selectionEnd
        this.expressionEdit = this.expressionEdit.slice(0, selectionStart) + expressionString + this.expressionEdit.slice(selectionEnd)
        await this.$nextTick()
        textArea.setSelectionRange(selectionStart + expressionString.length, selectionStart + expressionString.length)
        textArea.focus()
      } else {
        this.$refs.input.insert(expressionString)
      }
    },
    insertTokens(tokens) {
      if (this.rawTextMode) {
        const tokenConcat = tokens.map(token => token.value).join('')
        this.insert(tokenConcat)
      } else {
        this.$refs.input.insertTokens(tokens)
      }
    },
    async addExpression(expression) {
      if (typeof expression === 'string') {
        this.insert(expression)
        return
      }
      if ('stepExpression' in expression) {
        this.insert(expression.expression())
        return
      }
      if ('tokens' in expression) {
        this.insertTokens(expression.tokens)
      }
    },
    onTokenDragStart(item) {
      variablePickerBus.tokenDragStart(item.expression())
    },
    activate() {
      this.$refs.menu.activate()
    },
    async displayRaw() {
      await this.$nextTick()
      this.rawTextMode = true
    },
    async displayToken() {
      await this.$nextTick()
      this.rawTextMode = false
    },
  },
  components: {
    ApptiveExpressionInput,
    ExpressionPicker,
    FlowTreeView,
    Token,
    FlowTestRunner
  }
}
</script>

<style lang="scss" scoped>
.expression-editor-menu-body {
  max-height: 80vh;
}

.sample-value-display {
  white-space: pre;
  background: #dedede;
  color: #000000;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
}
</style>