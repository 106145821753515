<template>
  <DefaultDialog
    v-model="externalModel"
    :title="$t('flow.flowFormPicker.editPage')"
    :maxWidth="'80%'"
  >
    <BlockPage
      :blockUri="blockUri"
      editorMode
    />
  </DefaultDialog>
</template>

<script>
import DefaultDialog from '@/components/DefaultDialog.vue'
import BlockPage from '@/components/block/BlockPage.vue'
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [ externalModel ],
  props: {
    blockUri: null,
  },
  components: {
    DefaultDialog,
    BlockPage
  }
}
</script>

<style
  lang="scss"
  scoped
></style>