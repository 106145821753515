import { v4 as uuidv4 } from 'uuid'

export const STYLES = {
  HEADER: 'header',
  PARAGRAPH: 'paragraph'
}

const BINDS = {
  [STYLES.HEADER]: {
    class: ['text-h6'],
    style: {
      'white-space': 'pre-line'
    }
  },
  [STYLES.PARAGRAPH]: {
    class: [ 'text-h7 font-weight-medium accent--text'],
    style: {
      'white-space': 'pre-line'
    }
  }
}

export default class TextBlock {
  constructor(data) {
    this.id = data.id ?? uuidv4()
    this.type = 'textBlock'
    this.text = data.text
    this.style = data.style ?? STYLES.PARAGRAPH
    this.pageId = data.pageId
    this.fieldIndex = data.fieldIndex
    this.i18n = data.i18n
  }

  get props() {
    return BINDS[this.style]
  }
}