<template>
<div v-show="!hidden">

  <v-input
    v-if="!disabled"
    v-model="inputValue"
    :label="label"
    :rules="[rules.required, rules.schema]"
    :placeholder="placeholder"
    :hide-details="description || errors ? 'auto' : false"
    :readonly="readonly"
    :disabled="disabled"

  >
    <file-pond
      :unauthenticated="true"
      :label-idle="$t('attachments.pondLabel')"
      :allow-multiple="true"
      :existingFiles="files"
      @fileUploaded="onFileUploaded"
      @fileDeleted="onFileDeleted"
      @fileRenamed="onFileRenamed"
      :disabled="disabled"
      class="mt-1"
    />
  </v-input>
  <div v-else>
    <v-list  >
      <v-list-item class="pl-0 pr-0" v-for="(file, index) in files"
      :key="index">
        <v-list-item-content>
          <v-list-item-title v-text="file.name"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon>
            <v-icon @click="() => showPreview(index)" :color="color">mdi-eye-outline</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <a style="text-decoration: none;" :href="file.url" :download="file.name" >
          <v-btn icon>
            <v-icon :color="color">mdi-file-download-outline</v-icon>
          </v-btn>
          </a>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <AttachmentsPreviewDialog
     v-model="showPreviewDialog"
      :files="files"
     :showIndex="fileClickedIndex"
    />
  </div>
  <div class="text-caption mt-1">
    {{ description }}
  </div>
</div>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import FilePond from '@/components/attachments/FilePond.vue'
import AttachmentsPreviewDialog from '@/components/attachments/AttachmentsPreviewDialog.vue'

export default {
  mixins: [formFields],
  data () {
    return {
      showPreviewDialog: false,
      fileClickedIndex: undefined
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    files() {
      return Array.isArray(this.value) ? this.value : []
    }
  },
  methods: {
    showPreview(index) {
      this.fileClickedIndex = index
      this.showPreviewDialog = true
    },
    onFileUploaded(newFiles) {
      this.inputValue = newFiles
    },
    onFileDeleted(fileUrl) {
      const newFiles = [...this.files]
      const deleteIndex = newFiles.findIndex(file => file.url === fileUrl)
      if (deleteIndex >= 0) {
        newFiles.splice(deleteIndex, 1)
        this.inputValue = newFiles
      }
    },
    onFileRenamed(fileUrl, newName) {
      const newFiles = [...this.files]
      const updateIndex = newFiles.findIndex(file => file.url === fileUrl)
      newFiles[updateIndex].name = newName
      this.inputValue = newFiles
    }
  },
  components: {
    FilePond,
    AttachmentsPreviewDialog
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  display: unset;
}
</style>