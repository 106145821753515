import HalObject from './HalObject'
import store from '@/store/store.js'

export default class Space extends HalObject {
  constructor(data) {
    super(data)
    this.id = data.id
    this.key = data.key
    this.uri = data.uri
    this.name = data.name
    this.belongsTo = data.belongsTo
    this.type = data.type
    this.realSpace = data.realSpace
    this.backendPlan = data.plan
    this.color = data.color
    this.icon = data.icon
    this.owner = data._embedded?.owner
    this.availableSeats = data.availableSeats
  }

  title() {
    return this.name ? this.name : this.id
  }

  get plan() {
    // Hack for having no restrictions in a bmp space
    if (this.belongsTo === 'bmpMyProject') {
      return 'unlimited'
    }
    return this.backendPlan
  }

  async setKey(key) {
    const newKey = key || null
    if (this.key === newKey) {
      return
    }
    await store().dispatch('AGSetSpaceKeyOperation', {
      spaceUri: this.uri,
      key: newKey
    })
    this.key = newKey
    return newKey
  }

  async getRealSpace() {
    if (this.type !== 'sharedSpace') {
      return this
    }
    return await store().dispatch('AGReadSpaceOperation', {
      spaceUri: this.realSpace
    })
  }

  loadLinks() {
    return store().dispatch('AGReadSpaceLinks', this)
  }
}
