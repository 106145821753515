<template>
  <div
    class="wrapper"
    @pointerenter="showControls = true"
    @pointerleave="showControls = false"
  >
    <!-- Container for controls -->
    <div class="control-icons">
      <v-btn
        v-if="canAddBlock"
        class="control"
        :class="{shown: showControls}"
        :tabindex="tabIndex"
        icon
        small
        @click="onAdd"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-menu v-if="canMove" v-model="menu" left offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="control grabbable"
            :class="{shown: showControls || menu}"
            :tabindex="tabIndex"
            v-bind="attrs"
            v-on="on"
          >mdi-drag-vertical</v-icon>
        </template>
        <v-list dense>
          <slot name="menu" />
          <MenuItem
            icon="mdi-delete"
            :text="$t('blocks.deleteBlock')"
            @click.stop="onDelete"
          />
        </v-list>
      </v-menu>
    </div>

    <!-- Slot for other content -->
    <div class="content">
      <slot :showControls="showControls" />
    </div>

    <!-- Slot for other content -->
    <div class="control right-controls"
    :class="{shown: showControls}">
      <slot name="right-controls" :showControls="showControls" ></slot>
    </div>


  </div>
</template>

<script>
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import MenuItem from '@/components/MenuItem.vue'

export default {
  inject: ['canAddChild'],
  props: {
    blockUri: {
      type: String
    },
    editorMode: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      showControls: false,
      menu: false
    }
  },
  computed: {
    canAddBlock() {
      return this.editorMode && this.canAddChild
    },
    canDelete() {
      return this.editorMode && hasPermission(this.block, [ PERMISSIONS.remove ])
    },
    canMove() {
      return this.editorMode && hasPermission(this.block, [ PERMISSIONS.patch ])
    },
    tabIndex() {
      return this.showControls ? undefined : -1
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    }
  },
  methods: {
    onAdd() {
      this.$emit('addBlockAfter')
    },
    onDrag() {
      this.$emit('drag')
    },
    onDelete() {
      this.$emit('delete')
    }
  },
  components: {
    MenuItem
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: start;
  position: relative;
}

.right-controls {
  position: absolute;
  right: -60px;
}
.control-icons {
  position: absolute;
  left: -60px;
  top: -8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  /* Spacing between controls and content */
  margin-top: 8px;
}

.control {
  opacity: 0;
  transition: opacity 0.3s;
}

.shown {
  opacity: 1;
}

.content {
  flex: 1;
  max-width: 100%;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.wrapper:focus-within .control {
  opacity: 1;
}
</style>