<template>
  <v-sheet class="d-flex flex-column step-inspector">
    <v-toolbar flat class="pl-6" dense>
      <v-toolbar-title class="font-weight-medium">{{title}}</v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <FlowInstanceDetails :flow="flow" :step="step" noTitle/>
  </v-sheet>
</template>

<script>
import FlowInstanceDetails from './FlowInstanceDetails.vue'
import externalModel from '../../mixins/externalModel'

export default {
  mixins: [externalModel],
  props: {
    node: null,
    flow: null,
    positionX: null,
    positionY: null,
  },
  data() {
    return {
      outputGroup: true
    }
  },
  computed: {
    title() {
      return this.node?.name
    },
    step() {
      return this.flow?.testInstance?.steps.find( step => step?.getLink('node') == this.node?.uri)
    }
  },
  components: {
    FlowInstanceDetails
  }
}
</script>

<style lang="css" scoped>
.step-inspector {
  max-height: 100%;
}
</style>
