export default {
    filterAllButIntegers(event) {
        // If the event has a modifier key, return true to allow
        // copy/paste on Safari
        if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
            return true
        }
        if (event.key == '-' || event.keyCode >= 48 && event.keyCode <= 57 ) {
            return true
        } else {
            event.preventDefault()
            return false
        }
    },
    filterAllButDecimals(event) {
        // If the event has a modifier key, return true to allow
        // copy/paste on Safari
        if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
            return true
        }
        const authorizedCharacters = ['-', '.', ',']
        if (authorizedCharacters.includes(event.key) || event.keyCode >= 48 && event.keyCode <= 57 ) {
            return true
        } else {
            event.preventDefault()
            return false
        }
    },
    validEmail(email) {
      const parts = email.split('@')
      return parts.length == 2 && parts[1].includes('.') && !email.includes(' ')
    }
}
