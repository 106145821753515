<template>
  <div class="d-flex align-center"
    @mouseenter="showControls = true"
    @mouseleave="showControls = false"
  >
      <input
        :readonly="readonly"
        ref="input"
        class="ml-2 input grey--text text--darken-3"
        type="text"
        name=""
        append-icon="mdi-calendar-range-outline"
        v-model="inputModel"
        v-mask="dateTimeMask"
        @focus="$emit('focus')"
        @select="$emit('focus')"
        @blur="finishEditing"
        @keyup.exact.enter="nextLine"
        @paste.prevent="handlePaste"
      />
      <DateAndTimePicker v-model="pickerModel">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!readonly"
            v-show="showControls"
            class="mr-1 grey--text text--darken-3"
            icon
            x-small
            v-bind="attrs"
            v-on="on"
            color="grey"
          >
            <v-icon>mdi-calendar-clock</v-icon>
          </v-btn>
        </template>
      </DateAndTimePicker>
    </div>
</template>

<script>
import moment from 'moment'
import { gridCells } from '@/mixins/gridCells.js'
import { DATETIME_MASK, DATETIME_DISPLAY_FORMAT } from '@/constants/dateFormats.js'
import { VueMaskDirective } from 'v-mask'
import errorBus from '@/utils/errorBus.js'

export default {
  mixins: [gridCells],
  data() {
    return {
      modal: false,
      showControls: false,
      timeMode: true,
      inputModel: this.value
        ? moment(this.value).format(DATETIME_DISPLAY_FORMAT)
        : ''
    }
  },
  watch: {
    value(newVal) {
      this.inputModel = this.value
        ? moment(newVal).format(DATETIME_DISPLAY_FORMAT)
        : ''
    }
  },
  computed: {
    pickerModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
        this.$emit('blur')
      }
    },
    dateTimeMask() {
      return DATETIME_MASK
    }
  },
  methods: {
    emitInput(moment) {
      if (moment.isValid()) {
        this.$emit('input', moment.toISOString())
      } else {
        this.inputModel = ''
        this.$emit('input', null)
      }
      this.$emit('blur')
    },
    finishEditing() {
      const dateTime = moment(this.inputModel, DATETIME_DISPLAY_FORMAT)
      this.emitInput(dateTime)
    },
    handlePaste(event) {
      const content = (event.clipboardData || window.clipboardData).getData('text')
      let date = moment(content, DATETIME_DISPLAY_FORMAT)
      if (!date.isValid()) {
        date = moment(content)
      }
      if (!date.isValid()) {
        errorBus.$emit('ERROR', {message: this.$t('errorMessages.unrecognisedDate', {date: content})})
      }
      this.emitInput(date)
    }
  },
  components: {
    DateAndTimePicker: () => import('@/components/DateAndTimePicker.vue')
  },
  directives: {
    mask: VueMaskDirective
  }
}
</script>
