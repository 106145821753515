<template>
  <div
    class="d-flex textCell align-center"
    @mouseenter="showOpenMail = true"
    @mouseleave="showOpenMail = false"
  >
    <v-btn
      v-if="model"
      class="expand-button"
      icon dark x-small
      color="primary"
      v-show="showOpenMail"
      @click="openMail"
    >
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
    <v-menu
      :value="error"
      bottom offset-y
      nudge-bottom="8px"
      :close-on-click="false"
      :close-on-content-click="false"
      min-width="0"
      transition="none"
      content-class="counter-menu"
    >
      <template v-slot:activator="{}">
        <input
          ref="input"
          class="pl-2 grey--text text--darken-3"
          type="text"
          :readonly="readonly"
          v-model="model"
          @focus="$emit('focus')"
          @select="$emit('focus')"
          @keyup.exact.enter="nextLine"
          @blur="finishEditing"
          data-testid="cellInput"
        />
      </template>
     <div class="error-message pa-1 error--text">{{error}}</div>
    </v-menu>
  </div>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import validation from '@/utils/inputValidation.js'

export default {
  mixins: [gridCells],
  data() {
    return {
      showOpenMail: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        const sanitizedValue = newVal === '' ? null : newVal.trim()
        this.$emit('input', sanitizedValue)
      }
    },
    error() {
      if (this.model != null && !validation.validEmail(this.model)) {
        return this.$t('validation.emailError')
      }
      return undefined
    }
  },
  methods: {
    openMail() {
      const win = window.open(`mailto:${this.model}`, '_blank')
      win.focus()
    },
    async finishEditing() {
      if (this.model && !validation.validEmail(this.model)) {
        this.$emit('input', null)
      }
      this.$emit('blur')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textCell {
  position: relative;
  overflow: visible;
}

.expand-button {
  position: absolute;
  background: white;
  right: 4px;
  height: 24px;
  width: 24px;
  border-radius: 4px !important;
}

.error-message {
  font-size: 14px;
  background: white;
}
</style>
