<template>
  <v-dialog
    v-model="dialog"
    persistent 
    max-width="500px"
    transition="dialog-transition"
    v-bind="$attrs"
  >
  <template v-slot:activator="{ on, attrs }">
    <slot name="activator" :on="on" :attrs="attrs" />
  </template>
    <v-card>
        <v-card-title primary-title>
            {{$t('virtualGrid.batchDelete.titel', { numberOfEntities:  entities.length }) }}
        </v-card-title>
        <v-card-text>
            <v-progress-linear data-testid="batch-delete-progress" v-if="loading" :value="progress"></v-progress-linear>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelHandler" color="accent">{{$t('virtualGrid.batchDelete.cancel')}}</v-btn>
            <v-btn text :loading="loading" data-testid="batchDelete" @click="deleteEntites" color="destructive">{{$t('virtualGrid.batchDelete.confirmDelete')}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import batchDelete from '@/constants/batchDelete.js'

export default {
    props: ['virtualGrid'],
    data() {
        return {
            dialog: false,
            progress: 0,
            shouldStoppBatch: false,
            loading: false
        }
    },
    computed: {
        entities() {
            return this.virtualGrid.selectedEntities
        },
        cancelHandler() {
            return this.loading ? this.cancelDelete : this.close
        }
    },
    methods:{
        async deleteEntites() {
            const progressPerStep = 100 / this.entities.length
            this.loading = true
            for (const entity of [...this.entities]) {
                if (this.shouldStopBatch) {
                    this.loading = false
                    return
                }
                try {
                    await this.virtualGrid.deleteEntity(entity)
                    this.progress += progressPerStep
                    this.virtualGrid.deselectEntity(entity)
                 } catch (error) {
                    // Handle the error as needed (e.g., logging, error reporting, etc.)
                    this.$apptiveErrorReporter.captureException(error)
                }
                // Delay for .1 second before deleting next entity
                await new Promise(resolve => setTimeout(resolve, batchDelete.threshold))
            }
            this.loading = false
            this.close()
        },
        cancelDelete() {
            this.shouldStopBatch = true
        },
        close() {
            this.dialog = false
            this.virtualGrid.deselectAllEntities()
        }
    }
}
</script>

<style>

</style>