<template>
  <div />
</template>

<script>
import { bootIntercom } from '@/plugins/intercom.js'

export default {
  props: ['id'],
  mounted() {
    this.createSpaceFromTemplate()
  },
  methods: {
    createSpaceFromTemplate() {
      this.loading = true
      this.$store.dispatch('copyTemplate', {
        templateId: this.id
      })
        .then((uri)=> {
          bootIntercom( { templateId: this.id, appId: this.$settings.intercom.appID })
          this.$router.replace({
            name: 'Space',
            params: {spaceUri: uri}
          })
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>