<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <MenuButton
        v-on="on"
        icon="mdi-application-cog-outline"
        :text="$t('map.stateSelection.activtor')"
      />
    </template>
    <MapStateSelection :mapView="mapView"/>
  </v-menu>
</template>

<script>
import MapStateSelection from '@/components/mapView/MapStateSelection.vue'
import MenuButton from '@/components/MenuButton.vue'

export default {
  props: ['mapView'],
  components: {
    MapStateSelection,
    MenuButton
  }
}
</script>
