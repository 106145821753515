import Vue from 'vue'
const bus = new Vue()

const _LINK_AUTH_NEEDED = 'linkAuthorizationNeeded'

export default {
  requestAuthorization() {
    bus.$emit(_LINK_AUTH_NEEDED)
  },
  onLinkAuthorizationNeeded(handler) {
    bus.$on(_LINK_AUTH_NEEDED, handler)
  },
  linkAuthorizationNeededOff(handler) {
    bus.$off(_LINK_AUTH_NEEDED, handler)
  }
}