<template>
  <v-card class="map-state-selection">
    <template v-if="items && items.length > 0">
      <v-card-title>{{ $t('map.stateSelection.title') }}</v-card-title>
      <v-card-subtitle>{{ $t('map.stateSelection.text') }}</v-card-subtitle>
      <v-card-text>
        <v-select
          v-if="items && items.length > 0"
          solo
          flat
          outlined
          dense
          v-model="selectModel"
          @input="fieldIdSelected"
          :items="items"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </v-card-text>
    </template>
    <div v-show="!items || items.length <= 0">
      <v-card-title>{{ $t('map.stateSelection.createTitle') }}</v-card-title>
      <v-card-subtitle>{{
        $t('map.stateSelection.noGeolocation')
      }}</v-card-subtitle>
      <v-card-text>
        <ColumnSettings
          :virtualGrid="virtualGrid"
          createOnSave
          noAdvancedSettings
          noCancel
          flat
          :typeFilter="typeFilter"
          @columnCreated="selectFirstGeolocation"
        />
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { columnTypes } from '@/constants/columnTypes.js'
import ColumnSettings from '../gridView/ColumnSettings.vue'

export default {
  props: ['mapView'],
  data() {
    return {
      selectModel: undefined,
      showEnumOptions: false,
      enumLoading: false,
      typeFilter: [columnTypes.geolocation]
    }
  },
  watch: {
    mapView: {
      immediate: true,
      handler(newVal) {
        this.selectModel = newVal.stateFieldId
      }
    }
  },
  computed: {
    virtualGrid() {
      return this.mapView?.parentGrid
    },
    items() {
      if (this.virtualGrid == null) {
        return []
      }
      return this.virtualGrid
        .parentGrid()
        .fields.filter(
          field => field.columnType.name === columnTypes.geolocation.name
        )
        .map(field => ({
          text: field.name,
          value: field.id
        }))
    }
  },
  methods: {
    async fieldIdSelected(fieldId) {
      if (this.virtualGrid.hiddenFields.some(field => field.id === fieldId)) {
        const parentFieldIds = this.virtualGrid
          .parentGrid()
          .fields.map(field => field.id)
        const fieldIds = this.virtualGrid.fields.map(field => field.id)
        const newFieldIds = parentFieldIds.filter(
          id => id === fieldId || fieldIds.includes(id)
        )
        await this.virtualGrid.updateShownFields(newFieldIds)
      }
      this.mapView.setStateFieldId(fieldId)
    },
    selectFirstGeolocation() {
      this.mapView.setStateFieldId(this.items[0].value)
    }
  },
  components: {
    ColumnSettings
  }
}
</script>

<style scoped>
.map-state-selection {
  max-width: 400px;
}
</style>