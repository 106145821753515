export default {
  '/api/users/me': {
    spaceUris: [
      '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida',
      '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida2'
    ],
    id: 'blnt8h4hr0di5399dz8pyqm1k'
  },
  '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida': {
    id: 'blnt8h49n7jz5xq5kmdcv4ida',
    name: 'Name',
    gridUris: [
      '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida/grids/blnt8h81gfxqsc0ur39fnqlab',
      '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida/grids/blnt8h81gfxqsc0ur39fnqlab2'
    ]
  },
  '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida2': {
    id: 'blnt8h49n7jz5xq5kmdcv4ida',
    name: 'Name2',
    gridUris: [
      '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida/grids/blnt8h81gfxqsc0ur39fnqlab2'
    ]
  },
  '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida/grids/blnt8h81gfxqsc0ur39fnqlab': {
    schema: {
      type: 'object',
      properties: {
        fields: {
          type: 'array',
          items: [
            {
              type: 'string'
            },
            {
              type: 'string'
            }
          ]
        },
        _id: {
          type: 'string'
        }
      }
    },
    entities: [
      {
        fields: ['admin', 'adgrid'],
        _id: 'anyjf3pbnmwfga09dknzd0v8w'
      }
    ],
    fieldNames: ['username', 'password'],
    name: 'Users'
  },
  '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida/grids/blnt8h81gfxqsc0ur39fnqlab/forms': [
    '/api/users/8svn2gjcn61p9objyfbg2avpq/spaces/8svn2gmfw11yja9kxafy4i7ez/grids/8svn2gnz24rzascph4rikdx1q/forms/8svn2gmu4e72ld0juc1uczgn8'
  ],
  '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida/grids/blnt8h81gfxqsc0ur39fnqlab2': {
    schema: {
      type: 'object',
      properties: {
        fields: {
          type: 'array',
          items: [
            {
              type: 'string'
            },
            {
              type: 'string'
            }
          ]
        },
        _id: {
          type: 'string'
        }
      }
    },
    entities: [
      {
        fields: ['admin', 'adgrid'],
        _id: 'anyjf3pbnmwfga09dknzd0v8w'
      }
    ],
    fieldNames: ['username', 'password'],
    name: 'Users'
  },
  '/api/users/8svn2gjcn61p9objyfbg2avpq/spaces/8svn2gmfw11yja9kxafy4i7ez/grids/8svn2gnz24rzascph4rikdx1q/forms/8svn2gmu4e72ld0juc1uczgn8': {
    actions: [
      {
        name: 'add',
        uri:
          '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida/grids/blnt8h81gfxqsc0ur39fnqlab/objects',
        method: 'POST'
      }
    ],
    title: 'New title',
    components: [
      {
        property: 'firstName',
        options: {
          multi: false,
          placeholder: 'Max Mustermann',
          description: 'Bitte geben Sie vor und Nachnamen ein',
          label: 'Name'
        },
        type: 'textfield'
      },
      {
        property: 'Nachname',
        options: {
          multi: false,
          placeholder: '',
          description: '',
          label: 'textfield'
        },
        type: 'textfield'
      },
      {
        property: 'Anschrift',
        options: {
          multi: false,
          placeholder: '',
          description: '',
          label: 'textfield'
        },
        type: 'textfield'
      },
      {
        property: 'Datum',
        options: {},
        type: 'datePicker'
      },
      {
        property: 'Tisch',
        options: {
          multi: false,
          placeholder: '',
          description: '',
          label: 'textfield'
        },
        type: 'textfield'
      },
      {
        property: 'AGB',
        options: {},
        type: 'checkbox'
      }
    ]
  },
  '/api/users/blnt8h4hr0di5399dz8pyqm1k/spaces/blnt8h49n7jz5xq5kmdcv4ida/grids/blnt8h81gfxqsc0ur39fnqlab/AddLink': {
    uri: '/api/r/cci8orimpn33fberqpcahihp5'
  },
  '/api/a/cci8orimpn33fberqpcahihp5': {
    fields: [
      {
        id: 'id1',
        type: {
          name: 'string',
          jsonSchema: {
            type: 'string'
          }
        },
      },
      {
        id: 'id2',
        type: {
          name: 'string',
          jsonSchema: {
            type: 'string'
          }
        },
      },
      {
        id: 'id3',
        type: {
          name: 'integer',
          jsonSchema: {
            type: 'integer'
          }
        },
      },
    ],
    actions: [
      {
        name: 'Add',
        uri: '/api/a/cci8orimpn33fberqpcahihp5',
        method: 'POST'
      }
    ],
    components: [
      {
        fieldId: 'id1',
        property: 'username',
        type: 'textfield'
      },
      {
        fieldId: 'id2',
        property: 'password',
        type: 'textfield'
      },
      {
        fieldId: 'id3',
        property: 'age',
        type: 'textfield'
      }
    ],
    title: 'New title'
  }
}
