import HalObject from '@/store/models/HalObject'
import Transition from '@/store/models/flow/Transition.js'
import store from '@/store/store'
import TemplateInput from '@/components/flow/TemplateInput.vue'
import MultiTemplateInput from '@/components/flow/MultiTemplateInput.vue'
import TemplateTextArea from '@/components/flow/TemplateTextArea.vue'
import TemplateCheckbox from '@/components/flow/TemplateCheckbox.vue'
import TemplateSelect from '@/components/flow/TemplateSelect.vue'
import MultiTemplateSelect from '@/components/flow/MultiTemplateSelect.vue'
import TemplateKeyValues from '@/components/flow/TemplateKeyValues.vue'
import MultiTemplateFilePicker from '@/components/flow/MultiTemplateFilePicker.vue'
import SchemaTemplateInput from '@/components/flow/SchemaTemplateInput.vue'
import BlockTemplateInput from '@/components/flow/BlockTemplateInput.vue'
import i18n from '@/plugins/i18n'
import { createHolder } from '@/apptivescript/model'

const NODE_LAYOUT_PREFIX = 'nodeLayout'

const propertyComponents = {
  multiTextInput: {
    component: MultiTemplateInput
  },
  multitext: {
    component: MultiTemplateInput
  },
  textfield: {
    component: TemplateInput
  },
  textInput: {
    component: TemplateInput
  },
  textarea: {
    component: TemplateTextArea
  },
  checkbox: {
    component: TemplateCheckbox
  },
  booleanInput: {
    component: TemplateCheckbox
  },
  selectBox: {
    component: TemplateSelect
  },
  multiSelectDropdown: {
    component: MultiTemplateSelect
  },
  multikeyvalue: {
    component: TemplateKeyValues
  },
  filePicker: {
    component: MultiTemplateFilePicker
  },
  mimeEntityCollectionInput: {
    component: MultiTemplateFilePicker
  },
  schemaComponent: {
    component: SchemaTemplateInput
  },
  datePicker: {
    component: TemplateInput
  },
  block: {
    component: BlockTemplateInput
  },
  enumInput: {
    component: TemplateSelect
  },
  uriInput: {
    component: TemplateInput
  },
  stringDictionaryInput: {
    component: TemplateKeyValues
  }
}

const DEFAULT_LAYOUT = {
  position: {
    x: 0,
    y: 0
  }
}

export default class Node extends HalObject {
  constructor(data) {
    super(data)
    this.id = data.id
    this.type = data.type
    this.name = data.name
    this.tag = data.tag
    this.outgoing = []
    this.incoming = []
    this.setupView = undefined
    this.components = []
    this.updatePropertiesLink = undefined

    this.icon = 'mdi-plus'
    this.image = null
    this.color = 'grey'
    this.typeName = 'unknown'
    this.docuLink = 'https://intercom.help/apptivegrid/de/collections/5704835-knoten'

    if (data._embedded != null) {
      this.initPropertyHolders(data._embedded.properties)
    }

    this.layout = data.layout ?? DEFAULT_LAYOUT
    this.loadSavedLayout()
  }

  initPropertyHolders(properties) {
    this.properties = {}
    for (let key in properties) {
      if (key.startsWith('_')) {
        continue
      } else {
        this.properties[ key ] = createHolder(properties[ key ])
      }
    }
    return this.properties
  }

  hasDefaultLayout() {
    return this.layout.position.x === 0 || this.layout.position.y === 0
  }

  ensureOneOutgoing() {
    // Adding one incomplete outgoing transition by default
    if (this.outgoing.length === 0 && this.type !== 'end') {
      this.addOutgoing()
    }
  }

  loadSavedLayout() {
    const savedLayoutData = localStorage.getItem(NODE_LAYOUT_PREFIX + this.uri)
    if (savedLayoutData) {
      const savedLayout = JSON.parse(savedLayoutData)
      this.layout = savedLayout
    }
  }

  saveLayout() {
    localStorage.setItem(NODE_LAYOUT_PREFIX + this.uri, JSON.stringify(this.layout))
  }

  canHaveMultipleOutgoings() {
    return false
  }

  connectableTo(aNode) {
    // node is connectable if no incoming connection is set and it is not a trigger or start node
    return this.id !== aNode.id && this.type !== 'start' && this.type !== 'requestTrigger'
  }

  hasOutoingTo(node) {
    return this.outgoing.find(aOutgoing => aOutgoing.target === node._links.self.href)
  }

  removeOutgoingTo(nodeUri) {
    let outgoingIndex = this.outgoing.findIndex(aOutgoing => aOutgoing.target === nodeUri)
    if (outgoingIndex > -1) {
      this.outgoing.splice(outgoingIndex, 1)
    }
    // enshure at least one outgoing to see handle
    if (this.outgoing.length === 0) {
      this.addOutgoing()
    }

  }

  addOutgoing() {
    this.outgoing.push(new Transition({}))
  }

  indexOfTransiton(transition) {
    return this.outgoing.indexOf(transition)
  }

  // Resource values are asymetric, the value expected by
  // the backend has a different shape than the one we receive.
  // Consequently, before updating properties, we need to make
  // sure that all resource values have the proper shape.
  sanitizeResources(properties) {
    Object.keys(properties).forEach(key => {
    const block = this.setupBlock.children.find(block => block.formKey === key)
      if (block?.type === 'resourceInput') {
        const resource = createHolder(properties[ key ])
        const href = resource?._links?.self?.href?.value || resource?.href?.value
        properties[ key ] = createHolder({ href: createHolder(href) })
      }
    })
  }

  sanitize(properties) {
    Object.keys(properties).forEach(key => {
      const value = properties[ key ]
      if (value != null && typeof value === 'object') {
        properties[ key ] = this.sanitize(value)
      }
      if (key.trim() === '') {
        delete properties[ key ]
      }
    })
    return properties
  }

  async updateProperties(properties) {
    const copy = JSON.parse(JSON.stringify(properties))
    let sanitizedProperties = this.sanitizeResources(copy)
    sanitizedProperties = this.sanitize(copy)

    await store().dispatch('AGPutNodeProperties', {
      node: this,
      properties: sanitizedProperties
    })
    this.properties = this.initPropertyHolders(sanitizedProperties)
    return this.properties
  }

  patchName() {
    return store().dispatch('AGPatchNodeOperation', {
      node: this,
      name: this.name,
      tag: this.tag
    })
  }

  unconnected() {
    return !this.outgoing.some(transition => !transition.unconnected())
  }

  async loadSetupView(space) {
    if (this.overideHttpNode != null) {
      return
    }
    const setupView = await store().dispatch('AGReadNodeSetupView', {
      node: this
    })
    const slotSchema = setupView._embedded.schema.slots
    const components = new Array(Object.values(slotSchema).length)
    Object.keys(slotSchema).forEach(key => {
      const name = slotSchema[ key ].name
      const jsonSchema = slotSchema[ key ].type.jsonSchema
      const component = this._component(key, name, setupView.slotProperties[ key ].type, slotSchema[ key ].type?.options, space, jsonSchema)
      components[ slotSchema[ key ].position - 1 ] = component
    })
    this.components = components
    this.setupView = setupView
  }

  async loadSetupBlock(space) {
    if (this.overideHttpNode != null) {
      return
    }
    const setupBlock = await store().dispatch('AGReadNodeSetupBlock', {
      node: this
    })
    const block = this._buildBlock(setupBlock, space)
    this.components = block.props.children
    this.setupBlock = setupBlock
  }

  _buildBlock(block, space) {
    let type = block.textInput?.multiline ? 'textarea' : block.type
    return this._component(
      block.formKey,
      block[ type ]?.label ?? block.formKey,
      type,
      block[ type ]?.type?.options ?? block[ type ],
      space,
      block.type,
      block.children?.map(child => this._buildBlock(child, space)),
      block
    )
  }

  _component(key, label, componentType, options, space, blockType, children) {
    let component = propertyComponents[ componentType ]?.component
    if (component == null) {
      console.error(`Unknown component type : ${componentType}`)
      component = TemplateInput
    }
    return {
      component,
      props: {
        label: i18n.te(`flow.nodeProperties.${this.type}.${key}`) ? i18n.t(`flow.nodeProperties.${this.type}.${key}`) : label,
        key,
        items: options,
        blockType,
        children
      }
    }
  }
}
