<template>
      <div
        v-if="hasBlocks"
      >
        <div v-for="(block, index) in blocks" :key="block.id || index" >
          <BlockNavigationItem
          :blockUri="block.uri" 
          :spaceUri="spaceUri"
          :index="index"
          class="pr-1"
          />
        </div>
      </div>
</template>

<script>
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import BlockNavigationItem from '@/components/block/editor/BlockNavigationItem.vue'

export default {
  props: {
    spaceUri: null,
    showNavigationList: {
      type: Boolean,
      default: () => true
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    hasBlocks() {
      return this.blocks.length > 0
    },
    canAddPage() {
      return hasPermission(this.space, [PERMISSIONS.addBlock])
    },
    blocks () {
      return this.$store.getters.blocks
    }
  },
  methods: {
    initialNavigation() {
      if (this.blocks.length >= 1) {
        this.$router.replace({ name: 'BlockPage', params: { blockUri: this.blocks[ 0 ].uri } })
      }
      else if (this.$route.name !== 'BlockEditor') {
        // once the last blockpage was deleted, switch to the BlockEditor route
        this.$router.replace({ name: 'BlockEditor'})
      }
    },
    async listBlocks() {
        await this.$store.dispatch('AGListSpaceBlocks', { space: this.space })
    },
    blockName(block, index) {
      return block.name || `${this.$t('blocks.emptyPageTitle')} ${index + 1}`
    },
    async addChildBlock() {
      this.loading = true
      try {
        const newBlock = await this.$store.dispatch('AGAddPageToBlockOrSpace', this.space)
        this.$router.push({ name: 'BlockPage', params: { blockUri: newBlock.uri } }) 
      } finally {
        this.loading = false
      }
    },
  },
  components: {
    BlockNavigationItem
  }
}
</script>

<style
  lang="scss"
  scoped
>



</style>