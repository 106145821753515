export const gridCells = {
  props: {
    value: null,
    field: null,
    readonly: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    editEntityMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    nextLine() {
      this.$emit('nextLine')
    },
    selectInput() {
      this.$refs.input?.select?.()
      this.$refs.input?.activateMenu?.()
      this.$refs.input?.focus?.()
    }
  }
}
