<template>
  <v-menu
    v-model="externalModel"
    v-bind="$attrs"
    :allow-overflow="true"
    :close-on-content-click="false"
    bottom
    absolute
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
      small
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <MenuItem
        :disabled="!canRenameNode"
        icon="mdi-pencil-outline"
        :text="$t('flow.nodeMenu.rename')"
        @click="editNodeNameDialog = true"
      />
      <MenuItem
        :disabled="!canDeleteNode"
        icon="mdi-delete-outline"
        :text="$t('flow.nodeMenu.delete')"
        @click="deleteNode"
      />

    </v-list>
    <SingleInputDialog
      v-model="editNodeNameDialog"
      :title="'Rename Node'"
      :okButtonLabel="$t('dialogs.saveButton')"
      :okHandler="renameNode"
      :initialInputValue="node.name"
      max-width="350"
    />
  </v-menu>
</template>

<script>
import externalModel from '../../mixins/externalModel'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import MenuItem from '@/components/MenuItem.vue'

export default {
  props: ['node', 'flow', 'attach'],
  mixins: [externalModel],
  data() {
    return {
      editNodeNameDialog: false,
    }
  },
  methods: {
    deleteNode() {
      this.$emit('deleteNode', this.node)
      this.externalModel = false
    },
    renameNode(newName) {
      this.node.name = newName
      this.node.patchName()
      this.externalModel = false
    },
  },
  computed: {
      canPatchNode() {
        return hasPermission(this.node, [PERMISSIONS.patch])
      },
      canRenameNode() {
        return this.canPatchNode
       },
      canDeleteNode() {
        return hasPermission(this.node, [PERMISSIONS.remove])
      },
      canAddTransition() {
        return hasPermission(this.flow, [PERMISSIONS.addTransition])
      },
  },
  components: {
    SingleInputDialog,
    MenuItem
  }
}
</script>
<style lang="css" scoped>

</style>
