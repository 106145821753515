<template>  
    <div>
        <v-snackbar timeout="-1" v-model="snackbar" multi-line vertical light class="error-snack" max-width="500px">
            <v-btn icon class="closeButton" @click="close"><v-icon>mdi-close</v-icon></v-btn>
            <div class="text-h6 red--text">{{$t('errorReport.title')}}</div>
            <div class="">{{$t('errorReport.subtitle')}}</div>
            <v-sheet color="destructive" rounded="">
                <div class="font-italic ma-2 white--text pa-2">{{ text }}</div>
            </v-sheet>
            <v-textarea v-if="addText" class="pt-2" outlined multiline v-model="textFieldValue" :placeholder="$t('errorReport.placeholder')"></v-textarea>

            <template v-slot:action="{ attrs }">
                <v-btn v-show="!addText" text @click="addText = true">
                {{ $t("errorReport.addReportButton") }}
                </v-btn>
                <v-btn text v-bind="attrs" @click="sendError">
                {{ $t("dialogs.sendButton") }}
                </v-btn>
            </template>
        </v-snackbar>

        <animated-dialog
            error
            v-model="animatedDialog"
            :text="animatedDialogText"
            :okHandler="animatedDialogOkHandler">
        </animated-dialog>
    </div>
</template>

<script>
import errorBus from '@/utils/errorBus.js'
import AnimatedDialog from '@/components/AnimatedDialog'

export default {
    data() {
        return {
            textFieldValue: null,
            addText: false,
            animatedDialog: false,
            animatedDialogOkHandler: undefined,
            animatedDialogText: undefined,
            snackbar: false,
            text: null,
            error: null
        }
  },
  async mounted() {
    errorBus.$on('ERROR', (error) => {
        this.error = error
        if (this.$te('errorMessages.' + error.type)) {
            this.animatedDialog = true
            this.animatedDialogOkHandler = () => {
                error.okHandler?.()
                this.animatedDialogOkHandler = undefined
            }
            this.animatedDialogText = this.$t('errorMessages.' + error.type)
        } else {
            this.snackbar = true
            this.text = error.message
        }
    })
  },
  methods: {
    sendError() {
        // error was alredy send. Send again if a text was added.
        if( this.textFieldValue ) {
            this.$apptiveErrorReporter.errorSent = false
            this.$apptiveErrorReporter.captureException(this.error, { 'user-message' : this.textFieldValue })
        }
        this.close()
    },
    close() {
        this.snackbar = false
        this.error.okHandler?.()
    }
  },
  components: {
    AnimatedDialog,
  }
}
</script>

<style scoped>
.closeButton {
    position: absolute;
    right: 10px;
}
.v-snack--bottom {
  top: auto;
}

.error-snack {
  word-break: break-word;
}
</style>