<template>
  <div class="pa-2 address-picker-body">
    <v-form ref="form">
      <v-autocomplete
        v-if="googleAutocomplete"
        v-model="country"
        :items="countries"
        item-text="name"
        item-value="name"
        @change="item => onCountryChanged(item)"
        :label="$t('addressPicker.country')"
        :rules="requiredIfStrict"
      />
      <v-text-field
        v-show="googleAutocomplete && (showAllFields || country != null)"
        ref="searchBox"
        v-model="line1"
        :label="$t('addressPicker.line1')"
        @blur="debouncedEmit"
        :rules="requiredIfStrict"
      />
      <template v-if="showAllFields">
        <v-text-field
          v-model="line2"
          :label="$t('addressPicker.line2')"
          @blur="debouncedEmit"
        />
        <div :class="{'desktop-row': !$vuetify.breakpoint.mobile}">
          <v-text-field
            v-model="city"
            :label="$t('addressPicker.city')"
            @blur="debouncedEmit"
            :rules="requiredIfStrict"
            style="flex-basis: 60%;"
          />
          <v-text-field
            v-model="postCode"
            :label="$t('addressPicker.postCode')"
            @blur="debouncedEmit"
            :rules="requiredIfStrict"
            style="flex-basis: 35%;"
          />
        </div>
        <div :class="{'desktop-row': !$vuetify.breakpoint.mobile}">
          <v-autocomplete
            v-if="!googleAutocomplete"
            v-model="country"
            :items="countries"
            item-text="name"
            item-value="name"
            @change="item => onCountryChanged(item)"
            :label="$t('addressPicker.country')"
            :rules="requiredIfStrict"
            style="flex-basis: 60%;"
          />
          <v-text-field
            v-model="state"
            :label="$t('addressPicker.state')"
            @blur="debouncedEmit"
            style="flex-basis: 35%;"
          />
        </div>
      </template>
    </v-form>
  </div>
</template>

<script>
/* global google */
import externalModel from '@/mixins/externalModel'
import { loadGoogleMaps, onGoogleMapsLoaded } from '@/components/map/googleUtils.js'
import AGAddress from '@/store/models/AGAddress.js'
import countries from '@/constants/countries/index.js'
import debounce from '@/utils/debounce.js'
import { textFieldRules } from '@/mixins/textFieldRules'

  export default {
    mixins: [externalModel, textFieldRules],
    props: {
      googleAutocomplete: {
        type: Boolean,
        default: () => true
      },
      strict: {
        type: Boolean,
        default: () => false
      },
    },
    beforeMount() {
      this.debouncedEmit = debounce(function () {
        this.emit()
      }, 200).bind(this)
    },
    mounted() {
      this.countries = countries[this._i18n.locale] ?? countries.en
      const countryItem = this.countries.find(item => item.name === this.country)
      if (this.googleAutocomplete) {
        loadGoogleMaps(this._i18n.locale)
        onGoogleMapsLoaded(() => this.initSearchBox(countryItem?.alpha2))
      }
    },
    data() {
      return {
        debouncedEmit: undefined,
        countries: undefined,
        searchBox: undefined,

        line1: undefined,
        line2: undefined,
        city: undefined,
        postCode: undefined,
        state: undefined,
        country: undefined,
        geoLocation: undefined,
      }
    },
    computed: {
      requiredIfStrict() {
        return this.strict ? this.rules.required : []
      },
      showAllFields() {
        if (!this.googleAutocomplete) {
          return true
        }
        return this.line1 != null ||
          this.line2 != null ||
          this.city != null ||
          this.postCode != null ||
          this.state != null
      }
    },
    watch: {
      externalModel: {
        immediate: true,
        handler(newVal) {
          this.syncModel(newVal)
          if (this.country == null && this._i18n.locale === 'de') {
            this.country = 'Deutschland'
          }
        }
      }
    },
    methods: {
      syncModel(address) {
        this.syncModelWithoutCountry(address)
        this.country = address?.country || null
      },
      syncModelWithoutCountry(address) {
        this.line1 = address?.line1 || null
        this.line2 = address?.line2 || null
        this.city = address?.city || null
        this.postCode = address?.postCode || null
        this.state = address?.state || null
        this.geoLocation = address?.geoLocation || null
      },
      initSearchBox(countryCode) {
        let input = this.$refs.searchBox.$el.querySelector('input')
        // Prevent google default placeholder
        input.setAttribute('placeholder', '')
        let options = {
          fields: ['address_components', 'geometry', 'name', 'types']
        }
        if (countryCode != null) {
          options.componentRestrictions = {
            country: countryCode
          }
        }
        if (this.searchBox == null) {
          this.searchBox = new google.maps.places.Autocomplete(input, options)
          this.searchBox.addListener('place_changed', async () => {
            const place = this.searchBox.getPlace()
  
            if (place == null) {
              return
            }
            let address = AGAddress.fromGooglePlace(place)
            this.syncModelWithoutCountry(address)
            await this.$nextTick()
            this.debouncedEmit()
          })
        } else {
          this.searchBox.setOptions(options)
        }
      },
      onCountryChanged(country) {
        if (!this.googleAutocomplete || country == null) {
          return
        }
        const countryItem = this.countries.find(item => item.name === country)
        onGoogleMapsLoaded(() => this.initSearchBox(countryItem.alpha2))
      },
      validate() {
        return this.$refs.form.validate()
      },
      emit() {
        this.$emit('validate')
        if (!this.validate()) {
          return
        }
        this.$emit('input', new AGAddress({
          line1: this.line1,
          line2: this.line2,
          city: this.city,
          postCode: this.postCode,
          state: this.state,
          country: this.country,
          geoLocation: this.geoLocation,
        }))
      }
    }
  }
</script>

<style lang="scss" scoped>
.address-picker-body {
  min-width: clamp(200px, 400px, 100%);
  container-type: inline-size;
}

@container (width > 300px) {
  .desktop-row {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}
</style>