import newAjv from '@/utils/newAjv.js'

export default {

  _validatorsFor(schemas) {
    var ajv = newAjv()
    return schemas.map(schema => ajv.compile(schema))
  },
  validate(entity, schemas){
    var validators = this._validatorsFor(schemas)
    return validators.every((validator, index) => validator(entity.fields[index]))
  },
  validationErrors(entity, schemas) {
    let validators = this._validatorsFor(schemas)
    let errors = []
    validators.forEach((validator, index) => {
      validator(entity.fields[index])
      if (validator.errors) {
        errors = [errors, ...validator.errors]
      }
    })
    return errors
  },

  sanitizedEntityValue ( type, value ) {
    var sanitizedValue = type.sanitizedValue(value)
    if( sanitizedValue === undefined || sanitizedValue === ''){
      return null
    }
    return sanitizedValue
  }
}
