<template>
  <div class="d-flex flex-column">
    <v-tabs align-with-title class="tabs">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab :to="{ name: 'FlowEditor' }">
        {{$t('flow.flowView.editorTab')}}
      </v-tab>
      <v-tab :to="{ name: 'FlowInstancesList' }">
        {{$t('flow.flowView.historyTab')}}
      </v-tab>
    </v-tabs>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { PERMISSIONS, hasPermission } from '@/utils/halUtils.js'

export default {
  props: {
    flowUri: null,
    spaceUri: null
  },
  async mounted() {
    const flow = await this.$store.dispatch('AGReadFlowOperation', {flowUri: this.flowUri, loadNodes: true})
    if (hasPermission(flow, [PERMISSIONS.hook])) {
      await this.$store.dispatch('AGReadFlowHookOperation', this.flowUri)
    }
    await this.$nextTick()
    this.$route.meta.title = this.flow.name
  },
  computed: {
    flow() {
      return this.$store.getters.flowWithUri(this.flowUri)
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    }
  }
}
</script>

<style lang="css" scoped>
.tabs {
  border-bottom: 1px solid #e0e0e0;
}
</style>
