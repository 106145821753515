import Space from '../models/Space.js'
import ApiClient from '@/ApiClient'
import Vue from 'vue'
import { PERMISSIONS } from '../../utils/halUtils.js'

export default {
  state() {
    return {
      spaces: {}
    }
  },
  mutations: {
    addSpace(state, space) {
      Vue.set(state.spaces, space.uri, space)
    },
    removeSpace(state, space) {
      Vue.set(state.spaces, space.uri, null)
    }
  },
  actions: {
    AGReadSpaceOperation(context, {spaceUri, customErrorHandling, cached = false}) {
      spaceUri = spaceUri ?? arguments[1]
      const cachedSpace = context.getters.spaceWithUri(spaceUri)
      if (cached && cachedSpace) {
        return Promise.resolve(cachedSpace)
      }
      return ApiClient.getUri(spaceUri, undefined, {customErrorHandling}).then(response => {
        response.data['uri'] = spaceUri
        const space = new Space(response.data)
        context.commit('addSpace', space)
        return space
      })
    },
    async AGAddSpaceOperation(context, name) {
      const response = await ApiClient.postUri(context.getters.userHalLink('addSpace'), {
        name: name
      })
      const newSpace = await context.dispatch('AGReadSpaceOperation', response.headers['location'])
      await context.dispatch('loadSpaces', context.rootGetters.spacesUri)
      return newSpace
    },
    AGRenameSpaceOperation(context, { spaceUri, newName }) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink('patch')
      return ApiClient.patchUri(link, {
        name: newName
      }).then(response => {
        context.dispatch('AGReadSpaceOperation', spaceUri)
        context.dispatch('loadSpaces', context.rootGetters.spacesUri)
        return response
      })
    },
    async AGDeleteSpaceOperation(context, spaceUri) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink('remove')
      const response = await ApiClient.deleteUri(link)
      if (space) {
        context.commit('removeSpace', space)
      }
      await context.dispatch('loadSpaces', context.rootGetters.spacesUri)
      return response
    },
    async AGCopySpaceOperation(context, { spaceUri, name, includeEntities }) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink(PERMISSIONS.copy)
      const copyResponse = await ApiClient.postUri(link, {
        name,
        includeEntities
      })
      context.dispatch('loadSpaces', context.rootGetters.spacesUri)
      return copyResponse.headers.location
    },
    AGSetSpaceKeyOperation(context, { spaceUri, key}) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink(PERMISSIONS.patch)
      return ApiClient.patchUri(link, {key})
    },
    AGSetSpaceBelongsToOperation(context, { spaceUri, belongsTo }) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink(PERMISSIONS.patch)
      return ApiClient.patchUri(link, { belongsTo })
    },
    async AGSetSpaceIcon(context, { spaceUri, icon, iconset = 'mdi' }) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink(PERMISSIONS.patch)
      await ApiClient.patchUri(link, { icon, iconset })
      context.commit('setSpaceListItemIcon', { spaceUri, icon, iconset})
      space.icon = icon
      space.iconset = iconset
    },
    async AGSetSpaceColor(context, { spaceUri, color, push = true }) {
      const space = context.getters.spaceWithUri(spaceUri)
      if (push) {
        const link = space.getLink(PERMISSIONS.patch)
        await ApiClient.patchUri(link, { color })
      }
      context.commit('setSpaceListItemColor', { spaceUri, color})
      space.color = color
    },
    async AGChangeSpacePositionOperation(context, { spaceUri, newPosition }) {
      const link = context.rootGetters.userHalLink('changeSpacePosition')
      // backend Arrays are one-based
      return ApiClient.postUri(link, {
        position: newPosition + 1,
        uri: spaceUri
      })
    },
  },
  getters: {
    spaces(state, getters) {
      const spaces = getters.spacesList.map(space => state.spaces[space.uri] ?? state.spaces[space.realSpace])
      const filtered = spaces.filter(space => space != null)
      return filtered
    },
    spaceWithUri: state => uri => {
      return state.spaces[uri]
    },
    spaceIncludesGridWithUri: (state, getters, rootState, rootGetters) => gridUri => {
      return getters.spaces.find(space => rootGetters.gridsOfSpace(space.uri).some(grid => grid.uri === gridUri))
    },
  }
}
