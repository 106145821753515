<template>
  <v-dialog v-model="dialogModel" width="400" :fullscreen="$vuetify.breakpoint.mobile">
    <template v-slot:activator="{ on, attrs }">
        <slot name="customActivator"
          :attrs="attrs"
          :on="on"
        >
          <v-list-item
            data-testid="createColumnActivator"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small class="mr-3" color="primary">mdi-plus</v-icon>
            <v-list-item-title class="text-subtitle-2 primary--text">{{$t('forms.builder.createFieldButton')}}</v-list-item-title>
        </v-list-item>
      </slot>
    </template>
    <ColumnSettings
      v-bind="$props"
      @reload="$emit('reload')"
      @close="dialogModel = false"
      createOnSave
      :data-testid="`columnSettingsMenu-${index}`"
    />
  </v-dialog>
</template>

<script>
import ColumnSettings from '@/components/gridView/ColumnSettings.vue'

export default {
  props: {
    value: null,
    virtualGrid: null,
    index: null
  },
  data () {
    return {
      dialogModel: false,
    }
  },
  components: {
    ColumnSettings
  }
}
</script>
