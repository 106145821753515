<template>
  <v-menu v-model="menu" :close-on-content-click="false" v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <SpaceIconPicker :spaceUri="spaceUri" :active="menu"/>
  </v-menu>
</template>

<script>
import SpaceIconPicker from './SpaceIconPicker.vue'

export default {
  props: ['spaceUri'],
  data() {
    return {
      menu: false
    }
  },
  components: {
    SpaceIconPicker
  }
}
</script>