<template>
  <BaseKanbanAttribute
    v-bind="{ ...$props, ...$attrs }"
    hideValue
    :renderIfEmpty="true"
    :height="24"
    :icon="icon"
    :iconColor="iconColor"
  />
</template>

<script>
import BaseKanbanAttribute from './BaseKanbanAttribute.vue'

export default {
  props: ['attribute'],
  computed: {
    icon() {
      return this.attribute.value ? 'mdi-check-bold' : 'mdi-checkbox-blank-outline'
    },
    iconColor() {
      return this.attribute.value ? 'primary' : 'grey'
    }
  },
  components: { BaseKanbanAttribute }
}
</script>
