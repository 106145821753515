<template>
  <BaseKanbanAttribute v-bind="{...$props, ...$attrs}" :height="height" :titleHeight="titleHeight">
    <template v-slot:default>
      <div class="mt-1">
        <v-chip
          v-for="(label, i) in labels"
          :key="i"
          class="mr-1 px-1 grow"
          small
          label
        ><span class="font-weight-medium">{{label}}</span></v-chip>
      </div>
    </template>
  </BaseKanbanAttribute>
</template>

<script>
import BaseKanbanAttribute from './BaseKanbanAttribute.vue'

export default {
  props: ['attribute'],
  data() {
    return {
      height: 55,
      titleHeight: 18
    }
  },
  computed: {
    labels() {
      return this.attribute.fieldValue?.map(item => item.displayValue)
    }
  },
  components: {
    BaseKanbanAttribute
  }
}
</script>
