/**
 * Register the copy path component by inserting the copy icon
 */
export const registerCopyPath = (item, el, labelButtonCopyPath) => {
  const info = el.querySelector('.filepond--file-info-main')
  const copyIcon = getCopyIcon(labelButtonCopyPath)

  info.prepend(copyIcon)
  copyIcon.addEventListener('click', (e) => {
    copyPath(item)
    e.stopPropagation()
  })
}

export const getCopyIcon = (labelCopyPath) => {
  let icon = document.createElement('span')
  icon.className = 'filepond--copypath-icon'
  icon.title = labelCopyPath
  return icon
}

/**
 * Triggered when icon clicked. Copy uploaded file path at clipboard
 */
export const copyPath = (item) => {
  navigator.clipboard.writeText(item.serverId)
}