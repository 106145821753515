<template functional>
  <div
    class="cell-content pl-1 border-right cell"
    :class="$options.classesFromData(data)"
    :style="data.style"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <a class="disabled" :href="props.value" target="_blank">{{props.value}}</a>
  </div>
</template>

<script>
import { classesFromData } from '@/utils/vueUtils.js'

export default {
  props: ['value'],
  classesFromData
}
</script>

<style>
.cell-content{
  position: relative;
  font-size: 0.9em !important;
  white-space: nowrap;
  color: gray;
  line-height: 31px;
}

a.disabled {
  pointer-events: none;
}
</style>