<template>
  <BaseFormInput v-bind="$props" :hideLabel="true">
    <v-checkbox
      v-if="component.type == 'checkbox'"
      v-model="inputValue"
      :label="label"
      :rules="[rules.required, rules.schema]"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      :color="color"
      :disabled="disabled"
      v-bind="fieldStyle"
      :light="false"
    ></v-checkbox>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  name: 'FormCheckbox',
  mixins: [formFields],
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BaseFormInput
  }
}
</script>
<style lang="css" scoped>
.outlined >>> .v-input__slot {
  border: none !important;
  box-shadow: none;
}
</style>
