import i18n from '@/plugins/i18n'
import HalObject from '@/store/models/HalObject'
import store from '../../../store'

export function isFormEvent(event) {
  return ['addEntityFormSubmitted', 'editEntityFormSubmitted'].includes(event)
}

class Webhook extends HalObject {
  constructor(data) {
    //     {
    // 	"object" : "/api/users/akf8fpox3qkifqbqkrctiew1f/spaces/akf8fpk72lj1muvmq1ikllxar/grids/akf8fpqcnytzuixfmtllsu18e",
    // 	"event" : "entityAdded",
    // 	"uri" : "http://bam.boom.bang"
    // }
    super(data)
    this.object = data.object
    this.event = data.event
    this.name = data.name ?? i18n.t('webhooks.defaultWebhookTitle')
    this.type = data.type  // flow / web
    this.flowUri = data.flow // flowuri
    this.webUri = data.uri
    this.enabled = data.enabled ?? false
    this.condition = data.condition
  }

  asPayload() {
    return {
      object: this.object,
      event: this.event,
      uri: this.webUri,
      name: this.name,
      type: this.type,
      flow: this.flowUri,
      enabled: this.enabled,
      condition: this.condition
    }
  }

  validate() {
    return this.object && this.event && (this.webUri || this.flowUri ) ? true : false
  }

  isLocal() {
    return this._links == null
  }

  isInScope(uri) {
    return this.object.includes(uri)
  }

  spaceUri(){
    // backend should provide that
    return this.object?.split('/grids').reverse().pop()
  }

  subtitle() {
    return this.event ? i18n.t(`webhooks.trigger.${this.event}`) : '-'
  }

  testRun () {
    return store().dispatch('AGFlowHookTestOperation', this)
  }

  get hasFormEvent() {
    return isFormEvent(this.event)
  }

  get eventType() {
    return this.event
  }
}

export { Webhook}
