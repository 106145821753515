<template>
<v-card width="420px" v-bind="$attrs">
  <v-col>
    <v-list dense key="typeSelection">
      <v-list-item>
        <v-text-field
          :disabled="renameColumnDisabled"
          v-model="columnName"
          class="mb-2"
          dense
          filled
          rounded
          autofocus
          hide-details="auto"
          :label="$t('virtualGrid.columnNameLabel')"
          :rules="rules.maxLength($apptive.constants.nameLengths.fieldName)"
          :counter="$apptive.constants.nameLengths.fieldName"
          @focus="$event.target.select(); $emit('focus')"
          @focusout="$emit('focusout')"
          data-testid="columnNameTextInput"
        ></v-text-field>
      </v-list-item>

      <v-divider/>

      <v-list-group
        :disabled="typeSelectionDisabled"
        :ripple="!typeSelectionDisabled"
        v-model="typeSelectionGroup"
        @click="$emit('typeGroupClicked')"
      >
        <template v-slot:activator>
          <v-list-item v-if="newType" dense data-testid="columnType" class="pa-0">
            <v-icon small>{{ iconForType(newType) }}</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title
                v-text="newType.displayString"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
          @click="selectType(type)"
          v-for="type in types"
          :key="type.name"
          :data-testid="`columnType-${type.name}`"
        >
          <v-icon small>{{ type.typeIcon }}</v-icon>
          <v-list-item-content class="ml-3">
            <v-list-item-title v-text="type.displayString" />
          </v-list-item-content>
        </v-list-item>
        <span></span>
      </v-list-group>

      <v-divider v-if="typeSelectionGroup"/>

    </v-list>
    <v-sheet  color="background" rounded v-if="statefulType">
    <v-flex  grow>
      <component
        :is="statefulType.component"
        :disabled="typeSelectionDisabled"
        v-model="statefulTypeModel"
        @resized="$emit('typeGroupClicked')"
        :space="space"
        :grid="grid"
        :field="field"
      />
    </v-flex>
    </v-sheet>

    <SingleInputDialog
      :disabled="advancedSettingsDisabled"
      v-model="editKeyDialog"
      :title="$t('dialogs.editFieldKeyTitle')"
      :text="`${$t('dialogs.editFieldKeyText')} ${fieldIdString}`"
      :okButtonLabel="$t('dialogs.saveButton')"
      :okHandler="updateFieldKey"
      :initialInputValue="fieldKey"
      @textFieldInput="val => fieldKey = val"
      max-width="290"
      :max="$apptive.constants.nameLengths.default"
      :nameRequired="false"
    >
      <template v-slot:activator="{on}">

        <v-list-item
          v-if="!noAdvancedSettings"
          :disabled="advancedSettingsDisabled"
          dense
          v-on="on"
        >
          <v-icon small>mdi-cog-outline</v-icon>
          <v-list-item-content class="ml-3">
            <v-list-item-title>{{$t('virtualGrid.advancedFieldSettings')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </SingleInputDialog>
  </v-col>

  <v-card-actions>
    <DeleteColumnDialog
      v-if="!createOnSave && !loading"
      :disabled="deleteColumnDisabled"
      :virtualGrid="virtualGrid"
      :field="field"
    />
    <v-spacer></v-spacer>
    <v-btn text v-if="!noCancel && !loading" @click="$emit('close')">
      {{ $t("dialogs.cancelButton") }}
    </v-btn>
    <v-btn
      v-if="!loading"
      color="primary"
      text
      data-testid="columnSettingsSaveButton"
      :disabled="saveButtonDisabled"
      @click="save"
    >
      {{ $t("dialogs.saveButton") }}
    </v-btn>
    <v-progress-circular
      v-else
      :width="2"
      :height="16"
      indeterminate
      class="mr-6"
      color="primary"
    />
  </v-card-actions>
</v-card>
</template>

<script>
import { columnTypes } from '@/constants/columnTypes.js'
import { textFieldRules } from '@/mixins/textFieldRules.js'
import reloadVirtualGrid from '@/mixins/reloadVirtualGrid.js'
import DeleteColumnDialog from '@/components/gridView/DeleteColumnDialog.vue'
import SingleInputDialog from '@/components/SingleInputDialog.vue'

export default {
  inject: ['space', 'canRemoveField', 'canUpdateFieldName', 'canUpdateFieldType', 'canUpdateFieldKey'],
  mixins: [textFieldRules, reloadVirtualGrid],
  props: {
    virtualGrid: null, // HACK : in the context of the FormBuilder, this will be a persistent grid
    field: null,
    attach: null,
    createOnSave: {
      type: Boolean,
      default: () => false
    },
    createPosition: undefined,
    typeFilter: {
      type: Array,
      default: () => []
    },
    noAdvancedSettings: {
      type: Boolean,
      default: () => false
    },
    noCancel: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    columnName: null,
    newType: 0,
    statefulTypeModel: undefined,
    typeSelectionGroup: false,
    featureTypes: [
    ],
    loading: null,
    editKeyDialog: false,
    fieldKey: undefined,
  }),
  watch: {
    // _applyColumnRename does not trigger the field watcher, use a dedicated one
    'field.name': {
      immediate: true,
      handler(newVal) {
        this.columnName = newVal
      }
    },
    field: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.newType = newVal.columnType
          this.columnName = newVal.name
          this.fieldKey = newVal.key
          if (newVal.columnType.state) {
            this.statefulTypeModel = newVal.columnType.state.initialisation(newVal.type)
          }
        } else {
          this.reset()
        }
      }
    }
  },
  computed: {
    types() {
      return Object.values(columnTypes)
        .filter(type => type.name != this.newType.name)
        .filter(type => !this.featureTypes.includes(type) || this.apptiveFeature)
        .filter(type => this.typeFilter.length === 0 || this.typeFilter.includes(type))
    },
    statefulType() {
      return this.newType?.state
    },
    grid() {
      return this.virtualGrid.parentGrid() ?? this.virtualGrid
    },
    saveButtonDisabled() {
      if (!this.canUpdateFieldType() && !this.canUpdateFieldName()) {
        return true
      }

      if (this.columnName == null || this.columnName.length < 1) {
        return true
      }

      if (this.columnName && this.columnName.length > this.$apptive.constants.nameLengths.fieldName) {
        return true
      }

      if (this.statefulType && !this.statefulType.validate(this.statefulTypeModel)) {
        return true
      }
      return false
    },
    hasTypeChanged() {
      return (
        this.newType !== this.field?.columnType || this.statefulType?.hasChanged(this.field.type, this.statefulTypeModel)
      )
    },
    apptiveFeature() {
      return this.$store.getters.alphaMode
    },
    deleteColumnDisabled() {
      return !this.canRemoveField()
    },
    renameColumnDisabled() {
      return !this.canUpdateFieldName()
    },
    typeSelectionDisabled() {
      return !this.canUpdateFieldType()
    },
    advancedSettingsDisabled() {
      if (this.canUpdateFieldKey == null) {
        return false
      }
      return !this.canUpdateFieldKey()
    },
    defaultKey() {
      return this.field?.key
    },
    fieldIdString() {
      return this.field?.id ? `(${this.field.id})` : ''
    }
  },
  methods: {
    save() {
      (this.createOnSave ? this.createNewColumn() : this.updateColumn())
        .then(() => {
            this.$emit('reload') // reload additional stuff if needed
            this.$emit('close')
            if (!this.field) {
              this.reset()
            }
        }).finally(() => {
          this.loading = false
        })
    },
    async createNewColumn() {
      this.loading = true
      await this.virtualGrid.addColumn(
        this.createPosition,
        {
          virtualGridUri: this.virtualGrid.uri,
          name: this.columnName,
          type: {
            name: this.newType.name,
            ...this.newTypeAttributes()
          },
          key: this.fieldKey
        }
      )
      await this.reloadVirtualGrid()
      this.$emit('columnCreated')
    },
    async updateColumn() {
      await this.saveType()
      await this.saveName()
      this.reloadVirtualGrid()
      this.$emit('columnUpdated')
    },
    saveName() {
      if (this.field.name == this.columnName) return Promise.resolve()
      this.loading = true
      return this.virtualGrid.renameColumn(this.field.name, this.columnName)
    },
    newTypeAttributes() {
       return this.statefulType ? this.statefulType.attributesFrom(this.statefulTypeModel) : undefined
    },
    saveType() {
      if (!this.hasTypeChanged) return Promise.resolve()
      this.loading = true
      let operationArgs = {
        grid: this.grid,
        fieldId: this.field.id,
        newType: {
          name: this.newType.name,
          ...this.newTypeAttributes()
        }
      }
      return this.$store.dispatch('AGColumnTypeChangeOperation', operationArgs)
    },
    iconForType(type) {
      return type.typeIcon
    },
    selectType(type) {
      if (type.state?.component !== this.newType.state?.component) {
        this.statefulTypeModel = type.state?.defaultValue()
      }
      this.typeSelectionGroup = false
      this.newType = type
    },
    updateFieldKey(newKey) {
      if (!this.createOnSave) {
        this.field.setKey(this.virtualGrid.uri, newKey)
      }
    },
    reset() {
      this.statefulTypeModel = this.newType.state?.defaultValue()
      this.newType = this.types[0]
      this.columnName = this.virtualGrid.newFieldName()
      this.fieldKey = undefined
    }
  },
  components: {
    DeleteColumnDialog,
    SingleInputDialog
  }
}
</script>
