export default class KeepLastPromise {
  constructor() {
    this.promises = []
    this.settledPromises = []
  }

  async add(promise, thenCallback, finallyCallback) {
    this.promises.push(promise)
    try {
      const result = await promise
      if (promise === this.promises[this.promises.length - 1]) {
        thenCallback(result)
      }
    } finally {
      this.settledPromises.push(promise)
      if (promise === this.promises[this.promises.length - 1]) {
        finallyCallback()
      }
      // When all promises are settled, empty arrays
      if (this.settledPromises.length === this.promises.length) {
        this.settledPromises = []
        this.promises = []
      }
    }
  }
}