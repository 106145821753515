export default {
  props: {
    value: null
  },
  computed: {
    externalModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}