<template>
  <div
    :style="`width: ${cellWidth}px;`"
    no-gutters
    justify="center"
    align="center"
    class="headerCell"
  >
  <v-progress-circular
    v-show="reloading"
    indeterminate
    :width="1"
    :size="16"
    color="rgb(158, 158, 158)"
    class="mt-1"
    data-testid="grid-reloading-indicator"
  />
  </div>
</template>

<script>

// Import image preview and file type validation plug

export default {
  props: {
    cellWidth: null,
    reloading: null
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headerCell {
  overflow: hidden;
}
</style>
