<template>
  <BaseFormInput v-bind="$props" :hideLabel="true">
    <v-input
      v-model="externalModel"
      :label="label"
      :rules="[rules.required, rules.schema]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      :disabled="disabled"
    >
    <div class="d-flex mt-1" :disabled="disabled" @click="showSignaturePad">
      <img
        v-if="previewSrc"
        :src="previewSrc"
        class="signature-preview"
        height="100"
        weight="200"
      />
      <div
        v-else
        class="signature-placeholder d-flex align-center justify-center"
      >
      <v-btn text :color="color">
        <v-icon class="pr-2" :color="color">mdi-draw-pen</v-icon>
        {{$t('forms.view.tapToSign')}}
      </v-btn>
      
      </div>
    </div>

    <SignaturePadDialog
      v-model="dialog"
      :signatureFile="externalModel"
      @signatureSaved="signatureSaved"
      :color="buttonColor"
      :disabled="disabled"
    />
    </v-input>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components/form/BaseFormInput.vue'
import SignaturePadDialog from '../gridView/SignaturePadDialog.vue'
import externalModel from '../../mixins/externalModel'

export default {
  mixins: [formFields, externalModel],
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    previewSrc() {
      return this.externalModel?.url
    }
  },
  methods: {
    signatureSaved(file) {
      this.externalModel = file
    },
    showSignaturePad() {
      this.dialog = true && !this.disabled
    }
  },
  components: {
    BaseFormInput,
    SignaturePadDialog,
  }
}
</script>

<style scoped>
.signature-preview {
  background: white;
  border: solid 1px #d3d2d4;
  border-radius: 4px;
  height: 100px;
  width: 100%;
  cursor: pointer;
}
.signature-placeholder {
  height: 100px;
  width: 100%;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
  ::v-deep .v-input__slot {
    display: unset;
  }
  </style>
