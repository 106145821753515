<template>
  <div v-if="gridUri" class="d-flex flex-column gap-3">
    <div class="d-flex  gap-3 align-center">
      <div>{{$t('entityUpdatedCondition.hint')}}</div>
      <v-btn
      v-if="!fieldSelectShown"
      class="px-3 py-2"
      color="primary lighten-0"
      elevation="0"
      text
      @click="fieldSelectShown = true"
    >{{ $t('entityUpdatedCondition.fieldSelectPlaceholder') }}</v-btn>
      <v-select
        v-else
        v-model="fieldId"
        @change="fieldIdChanged"
        :items="fieldItems"
        :placeholder="$t('entityUpdatedCondition.fieldSelectPlaceholder')"
        outlined dense hide-details clearable
      />
      
      <span class="flex-shrink-0">{{$t('entityUpdatedCondition.fieldSelectLabel')}}</span>
    </div>
    <FullHeightLoader v-model="loading" />
    <template v-if="fieldId && grid">
      <EntityUpdatedConditionParameter 
        v-model="from"
        :grid="grid"
        :fieldId="fieldId"
        :label="$t('entityUpdatedCondition.from')"
        :placeholder="$t('entityUpdatedCondition.parameterPlaceholder')"
        :noPicker="noPicker"
      />
      <EntityUpdatedConditionParameter
        v-model="to"
        :grid="grid"
        :fieldId="fieldId"
        :label="$t('entityUpdatedCondition.to')"
        :placeholder="$t('entityUpdatedCondition.parameterPlaceholder')"
        :noPicker="noPicker"
      />
    </template>
  </div>
</template>

<script>
import externalModel from '../../mixins/externalModel'
import FullHeightLoader from '../FullHeightLoader.vue'
import EntityUpdatedConditionParameter from './EntityUpdatedConditionParameter.vue'

export default {
  mixins: [externalModel],
  props: ['gridUri'],
  data() {
    return {
      loading: false,
      fieldSelectShown: false,
      fieldId: undefined,
      from: undefined,
      to: undefined
    }
  },
  watch: {
    gridUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null) return
        this.loading = true
        try {
          this.$store.dispatch('AGReadGridOperation', {uri: newVal, cached: true})
        } finally {
          this.loading = false
        }
      }
    },
    externalModel: {
      immediate: true,
      handler(newVal) {
        if (newVal == null || typeof newVal != 'object') {
          this.reset()
          return
        }

        this.fieldSelectShown = true
        this.fieldId = Object.keys(newVal)[0]
        const fieldCondition = newVal[this.fieldId]
        if (typeof fieldCondition != 'object') {
          this.from = undefined
          this.to = undefined
          return
        }
        this.from = newVal[this.fieldId].$updated?.from
        this.to = newVal[this.fieldId].$updated?.to
      }
    },
    conditionBlob() {
      if (this.fieldId == null) {
        this.$emit('input', undefined)
        return
      }
      let condition = { $updated: { from: this.from, to: this.to } }
      if (this.from === undefined && this.to === undefined) {
        condition = { $isUpdated: true }
      }
      
      this.$emit('input', {
        [this.fieldId]: condition
      })
    }
  },
  computed: {
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    fieldItems() {
      if (this.grid == null) return []
      return this.grid.fields
      .filter(field => !field.columnType.entityUpdatedCondition?.disabled)
      .map(field => ({
        text: field.name,
        value: field.id
      }))
    },
    conditionBlob() {
      return `${this.fieldId} ${JSON.stringify(this.from)} ${JSON.stringify(this.to)}`
    },
    noPicker() {
      const field = this.grid.fields.find(field => field.id === this.fieldId)
      return field.columnType.entityUpdatedCondition?.noPicker
    }
  },
  methods: {
    reset() {
      this.fieldId = undefined
      this.from = undefined
      this.to = undefined
      this.fieldSelectShown = false
    },
    fieldIdChanged() {
      this.from = undefined
      this.to = undefined
    }
  },
  components: {
    EntityUpdatedConditionParameter,
    FullHeightLoader
}
}
</script>