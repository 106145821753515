let config

// Function to load the configuration from the web
async function loadConfig() {
  try {
    const response = await fetch('/config.json')
    if (!response.ok) {
      throw new Error(`Failed to fetch config: ${response.statusText}`)
    }
    config = await response.json()
    return config
  } catch (error) {
    window.location.href = '/configError.html'
  }
}

export { loadConfig, config }