<template>
  <BaseFormInput v-bind="$props" forceLabel>
    <v-text-field
      ref="searchInput"
      v-model="addressLine1"
      :label="$t('addressPicker.line1')"
      :rules="[rules.required, addressRule]"
      :hide-details="description || errors ? 'auto' : false"
      :color="color"
      :disabled="disabled"
      placeholder=""
      clearable
      @focus="showDetails = true"
      @keydown.enter.prevent
      @click:clear="clear"
      v-bind="fieldStyle"
    >
      <template v-slot:append-outer>
        <v-btn small :color="labelColor" icon text @click="showDetails = !showDetails">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <div class="expandable" :style="detailsStyle">
      <AddressPicker
        v-show="showDetails"
        v-model="externalModel"
        ref="content"
        :googleAutocomplete="false"
        @validate="validate"
        strict
             />
    </div>
  </BaseFormInput>
</template>

<script>
/* global google */
import AddressPicker from '@/components/AddressPicker.vue'
import BaseFormInput from '@/components/form/BaseFormInput.vue'
import externalModel from '@/mixins/externalModel'
import { formFields } from '@/mixins/formFields'
import AGAddress from '@/store/models/AGAddress'
import { loadGoogleMaps, onGoogleMapsLoaded } from '@/components/map/googleUtils.js'

  export default {
    mixins: [formFields, externalModel],
    mounted() {
      loadGoogleMaps(this._i18n.locale)
      onGoogleMapsLoaded(() => this.initSearchBox())
      this.watchContentSize()
    },
    data() {
      return {
        showDetails: false,
        contextHeight: 0
      }
    },
    computed: {
      addressLine1: {
        get() {
          return this.externalModel?.line1
        },
        set(newVal) {
          this.externalModel = {
            ...(this.externalModel ?? {}),
            line1: newVal
          }
        }
      },
      addressRule() {
        return v => v == null || this.$refs.content?.validate() || this.$t('validation.address')
      },
      detailsStyle() {
        if (this.showDetails) {
          return {
            maxHeight: `${this.contextHeight}px`
          }
        }
        return {
          maxHeight: 0
        }
      },
      displayString() {
        if (this.externalModel == null) {
          return undefined
        }
        return new AGAddress(this.externalModel).displayString
      }
    },
    methods: {
      watchContentSize() {
        this.contextHeight = this.$refs.content.$el.clientHeight
        const observer = new ResizeObserver((entries) => {
          for (const entry of entries) {
            this.contextHeight = entry.contentRect.height
          }
        })
        observer.observe(this.$refs.content.$el)
      },
      async initSearchBox(countryCode) {
        let input = this.$refs.searchInput.$el.querySelector('input')
        let options = {
          fields: ['address_components', 'geometry', 'name', 'types']
        }
        if (countryCode != null) {
          options.componentRestrictions = {
            country: countryCode
          }
        }
        if (this.searchBox == null) {
          this.searchBox = new google.maps.places.Autocomplete(input, options)
          this.searchBox.addListener('place_changed', async () => {
            const place = this.searchBox.getPlace()
  
            if (place == null) {
              return
            }
            let address = AGAddress.fromGooglePlace(place)
            this.externalModel = address
          })
        } else {
          this.searchBox.setOptions(options)
        }
        // Prevent google default placeholder (Hack for Safari)
        setTimeout(() => {
          input.setAttribute('placeholder', '')
        }, 1)
      },
      clear() {
        this.externalModel = null
      },
      validate() {
        this.$refs.searchInput?.validate()
      }
    },
    components: { BaseFormInput, AddressPicker }
}
</script>

<style lang="scss" scoped>
.expandable {
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

</style>