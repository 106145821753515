<template>
    
    <v-card 
      @pointermove="hover = true"
      @pointerleave="hover = false"
      :width="width" 
      color="white" 
      :elevation="hover ? 8 : 3" 
      v-bind="$attrs"
      class="card"
      ref="cardRef"
      >
      <v-img
        v-if="showImage"
        v-resize="onResize"
        class="image"
        :src="file"
        ref="imageRef"
      >
      </v-img>

      <div v-if="showDetails" class="d-flex flex-column pa-3">

        <div class="min-height">
          <div v-if="entity.entity">
              <div v-if="title" data-testid="galleryCardTitle" class="textOverflow font-weight-bold">{{title}}</div>
              <div v-else class="no-text-overflow font-weight-medium grey--text">{{$t('kanban.unnamedEntry')}}</div>

          </div>
          <v-skeleton-loader v-else type="text" width="60" />
        </div>

        <component
            ref="component"
            v-for="(attribute, index) in attributes"
            :key="index"
            :renderIfEmpty="true"
            :hideTypeIcon="hideTypeIcon"
            :hideFieldName="hideFieldName"
            :attribute="attribute"
            :is="component(attribute)"
            :animateIfEmpty="entity.entity != null"
            />
      </div>

    </v-card>
  </template>
  
  <script>
  export default {
    props: ['entity', 'width', 'shouldReportHeight', 'showImage', 'hideTypeIcon' , 'hideFieldName', 'cardSize'],
    data () {
      return {
        hover: false
      }
    },
    mounted() {
      if(!this.entity.entity) {
        // inform gridView to load content 
        this.$emit('load-entity', this.entity)
      }
    },
    watch: {
      attributeLength: {
        immediate: true,
        async handler() {
          this.reportHeight()
        } 
      },
      showImage: {
        async handler() {
          this.reportHeight()
        } 
      },
      hideTypeIcon: {
        async handler() {
          this.reportHeight()
        } 
      },
      hideFieldName: {
        async handler() {
          this.reportHeight()
        } 
      },
      cardSize: {
        async handler() {
          this.reportHeight()
        } 
      },
    },
    computed: {
      attributes() {
        return this.entity.attributes.filter( (attr, index) => index != 0 )
      },
      attributeLength() {
        return this.entity.attributes.length
      },
      showDetails() {
        return this.entity.attributes.length > 0
      },
      title() {
        return this.entity.attributes[0]?.value
      },
      file() {
        return this.entity.attachments && this.entity.attachments[0]?.url
      },
    },
    methods: {
      onResize() {
        if(this.shouldReportHeight) this.reportHeight()
      },
      component(attribute) {
        return attribute.property.kanbanAttributeComponent
      },
      reportHeight() {
        this.$nextTick(() => {
            // the gallery view sets the last estimated height of a card in the estimatedHeight prop.
            // this emit only if the card height is different. e.g. a field was hidden or added
            if(this.shouldReportHeight) {
              let offsetHeight = this.$refs.cardRef.$el.offsetHeight
              this.$emit('height-updated', offsetHeight)
            }
        })
      }
    },
  }
  </script>
  
  <style scoped>
.textOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}
  .min-height {
    min-height: 40px !important;
    height: 40px !important;
  }
  .card {
    cursor: pointer;
  }

  .image {
    width: 100%; 
    height: 0; 
    padding-bottom: 56.25%; 
    position: relative;
    background-color: #fbfbfb;
  }

  </style>
  