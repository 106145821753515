<template>
  <div>
    <div :style="pageStyle">

      <v-breadcrumbs :items="breadcrumbItems" class="flow-breadcrumb">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>

      <AsyncFetcher :promise="listFlowsPromise" :initialValue="[]">
        <template #default="{data}">
          <template v-if="data.length">
            <div class="d-flex justify-end mr-4">
            <PaywallMenu
              :feature="$apptive.constants.features.FLOWS"
              :count="data.length"
            >
              <v-btn
                :disabled="!canAddFlow"
                rounded
                class=""
                large
                dark
                elevation="5"
                color="primary"
                data-testid="addFlowButton"
                @click="addFlow"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                <span class="bold">  {{ $t("flow.flowlist.buttons.addFlow") }}</span>
              </v-btn>
            </PaywallMenu>
          </div>
            <PaywallBanner
              :feature="$apptive.constants.features.FLOWS"
              :count="data.length"
              :text="$t('paywall.flows.longHint')"
              class="ma-4"
            />

          <div class="mt-8 ma-4 pb-0 pt-0" >
            <FlowListItem
              v-for="(flow, index) in data"
              :key="index"
              :flowUri="flow.uri"
              @loadFlows="loadFlows"
              />
          </div>

        </template>

          <div v-else class="d-flex flex-column align-center justify-center mt-12">
           <v-img src="/apptivegrid-launch.svg"></v-img>
           <div class="text-h6 grey--text">
               {{ $t("flow.flowlist.labels.emptyStateHeadline") }}
           </div>
           <v-btn
             :disabled="!canAddFlow"
             data-testid="addFlowButtonEmptyState"
             color="primary"
             class="mt-3"
             @click="addFlow"
           >
               {{ $t("flow.flowlist.buttons.createNewFlowButton") }}</v-btn>
          </div>
          <router-view />
        </template>
      </AsyncFetcher>
    </div>
  </div>
</template>

<script>
import AsyncFetcher from '@/components/AsyncFetcher.vue'
import PaywallBanner from '@/components/paywall/PaywallBanner.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'
import FlowListItem from '@/components/flow/FlowListItem.vue'

import { hasPermission, PERMISSIONS } from '../../utils/halUtils'

export default {
  props: {
    spaceUri: null,
    scope: null
  },
  data() {
    return {
      listFlowsPromise: undefined,
      spaceFlowsPromise: undefined,
    }
  },
  watch: {
    space: {
      immediate: true,
      handler(newVal) {
        if (newVal?.id == null) return
        this.loadFlows()
      }
    },
    scope: {
      handler() {
        this.loadFlows()
      }
    }
  },
  computed: {
    pageStyle() {
      return {
        height: `calc(100vh - ${this.$vuetify.application.top}px)`
      }
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri) ?? {}
    },
    canAddFlow() {
      if (this.scope === 'all') {
        return this.$store.getters.userHalLink(PERMISSIONS.addFlow) != null
      } else {
        return hasPermission(this.space, [PERMISSIONS.addFlow])
      }
    },
    spaceName() {
      return this.space?.name
    },
    breadcrumbItems() {
      return [
        // { text: this.$t('flow.flowlist.allFlows'), to: { name: 'FlowList' , params: {scope: 'all'}} },
        { text: this.spaceName, to: { name: 'FlowList' , params: {scope: 'space'}} },
      ]
    },
  },
  methods: {
    navigateTo(flowUri) {
      this.$router.push({ name: 'FlowEditor', params: { flowUri: flowUri } })
    },
    loadFlows() {
      if (this.scope === 'all') {
        this.listFlowsPromise = this.$store.dispatch('AGListFlowsOperation')
      } else {
        this.listFlowsPromise = this.$store.dispatch('AGListSpaceFlowsOperation', { spaceUri: this.spaceUri })
      }
    },
    async addFlow() {
      let flow = await this.$store.dispatch('AGAddFlowFlowOperation', {
        name: 'New Flow',
        parentSpaceUri: this.scope === 'space' ? this.spaceUri : undefined
      })
      this.$intercom?.trackEvent( 'User created a Flow' )
      this.navigateTo(flow.uri)
    },
  },
  components: {
    AsyncFetcher,
    PaywallBanner,
    PaywallMenu,
    FlowListItem
  }
}
</script>

<style lang="css" scoped>

.text-bold {
  font-weight: bold;
}
</style>

<style lang="scss" scoped>
.flow-breadcrumb {
  :not(.v-breadcrumbs__item--disabled) {
    color: rgba(0, 0, 0, 0.38);
    font-weight: normal;
  }
  ::v-deep .v-breadcrumbs__item--disabled {
    font-weight: bold;
    color: black !important;
  }
}
</style>
