<template>
  <div class="cell" :class="{solo, focused, roundedBorders}" :style="style">
    <component
      ref="component"
      class="full-height"
      :is="component"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      :focused="focused"
      v-on="$listeners"
      :data-testid="`cell-${index}`"
    />
  </div>
</template>

<script>

export default {
  props: {
    solo: {
      type: Boolean,
      default: () => false
    },
    type: null,
    width: null,
    error: null,
    roundedBorders: {
      type: Boolean,
      default: () => false
    },
    index: null,
    fontSize: null
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    component() {
      return this.type?.cellComponent
    },
    borderColor() {
      if (this.error) return 'red'
      if (this.focused) return '#1664c0'
      if (this.solo) return '#d3d2d4'
      return undefined
    },
    style() {
      return {
        width: this.width ? `${this.width}px` : undefined,
        borderColor: this.borderColor,
        '--cell-font-size': this.fontSize ?? '0.9em'
      }
    }
  },
  methods: {
    selectCellInput() {
      this.$refs.component?.selectInput?.()
    }
  }
}
</script>

<style scoped>
.full-height {
  height: 100%;
  width: 100%;
  min-height: inherit;
}

.cell {
  overflow: hidden;
}

@supports(overflow: clip) {
  .cell {
    overflow: clip;
  }
}

/deep/ input, /deep/ .cell-content{
  font-size: var(--cell-font-size) !important;
  width: 100%;
  height: 100%;
  color: gray;
}

/deep/ textarea, /deep/ .cell-content{
  font-size: var(--cell-font-size) !important;
  width: 100%;
  height: 100%;
}

/deep/ input:focus {
  outline: none;
}

/deep/ .v-input__control {
  min-height: unset !important;
}

.focused {
  border-width: 1px;
  border-style: solid solid solid solid !important;
}

.solo {
  display: flex;
  border-style: solid;
  border-width: 1px;
}

.roundedBorders {
  border-radius: 4px;
}
</style>
