<template>
  <BaseKanbanAttribute v-bind="{...$props, ...$attrs}" :height="80" :titleHeight="18">
    <template v-slot:default>
      <div class="pt-1 d-flex horizontal-scroll">
        <v-tooltip
          v-for="(file, index) in files"
          :key="index"
          bottom
          :open-delay="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <FileThumbnail
              v-on="on" v-bind="attrs"
              :large="true"
              class="pl-1 pt-1 pointer flex-grow-0"
              :file="file"
             />
          </template>
          <span>{{file.name}}</span>
        </v-tooltip>
      </div>
    </template>
  </BaseKanbanAttribute>
</template>

<script>
import FileThumbnail from '@/components/FileThumbnail.vue'
import BaseKanbanAttribute from './BaseKanbanAttribute.vue'

export default {
  props: ['attribute'],
  computed: {
    files() {
      return this.attribute.fieldValue || []
    }
  },
  components: {
    FileThumbnail,
    BaseKanbanAttribute
}
}
</script>

<style scoped>
.horizontal-scroll {
  max-height: 100%;
  overflow-x: auto;
}
</style>
