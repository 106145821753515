<template>
<input
  ref="input"
  class="px-2 grey--text text--darken-3"
  type="number"
  v-model="model"
  :readonly="readonly"
  @keypress="filterAllButIntegers"
  @keyup.exact.enter="nextLine"
  @focus="$emit('focus')"
  @select="$emit('focus')"
  @blur="finishedEditing()"
/>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import inputValidation from '@/utils/inputValidation.js'

export default {
  name: 'GridIntegerCell',
  mixins: [gridCells],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        const parsed = parseInt(newVal)
        this.$emit('input', isNaN(parsed) ? null : parsed)
      }
    }
  },
  methods: {
    finishedEditing() {
      this.$emit('blur')
    },
    filterAllButIntegers(event) {
      return inputValidation.filterAllButIntegers(event)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
