<template>
<v-dialog v-model="externalModel" persistent max-width="290" :disabled="disabled">
  <v-card color="black">
    <v-card-title
      class="text-h5 white--text"
      v-html="$t('dialogs.deleteSpaceTitle', { spaceTitle: spaceName })"
    />
    <v-card-text class="white--text">{{
      $t("dialogs.areYouSure")
    }}</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey darken-1" text @click="externalModel = false">
        {{ $t("dialogs.cancelButton") }}
      </v-btn>
      <v-btn
        color="red darken-1"
        text
        data-testid="confirmDeleteSpaceButton"
        :loading="loading"
        @click="deleteSpace"
      >
        {{ $t("dialogs.deleteButton") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  name: 'DeleteSpaceDialog',
  props: {
    spaceUri: null,
    spaceName: null,
    disabled: null
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    deleteSpace() {
      this.loading = true
      this.$store.dispatch('AGDeleteSpaceOperation', this.spaceUri)
      .then(() => {
        this.dialog = false
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="css"></style>
