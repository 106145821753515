<template>
  <BaseKanbanAttribute v-bind="{...$props, ...$attrs}" :height="height" :titleHeight="titleHeight">
    <template v-slot:default="{ attribute }">
      <div class="mt-1">
        <v-chip
          v-for="(item, i) in attribute.fieldValue"
          :key="i"
          class="mr-1 mb-1 flex-shrink-0"
          small
        ><strong>{{item}}</strong></v-chip>
      </div>
    </template>
  </BaseKanbanAttribute>
</template>

<script>
import BaseKanbanAttribute from './BaseKanbanAttribute.vue'

export default {
  data() {
    return {
      height: 55,
      titleHeight: 18
    }
  },
  components: {
    BaseKanbanAttribute
  }
}
</script>