import { render, staticRenderFns } from "./GeneralSpaceSettingsPage.vue?vue&type=template&id=990f1934&"
import script from "./GeneralSpaceSettingsPage.vue?vue&type=script&lang=js&"
export * from "./GeneralSpaceSettingsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports