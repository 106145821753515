<template>
<div
  class="attachment-cell d-flex align-center"
  :class="{'control-padding': showControls}"
  @mouseenter="showControls = true"
  @mouseleave="showControls = false"
  @click="selectInput"
>
  <div class="d-flex flex-row align-center attachment-list">
    <v-tooltip
      v-for="(file, index) in files"
      :key="index"
      bottom
      :open-delay="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <a :href="file.url" class="d-inline-flex" @click.prevent v-on="on" v-bind="attrs">
          <FileThumbnail
            class="ml-1 pointer"
            :file="file"
            @click="() => showPreview(index)"
          />
        </a>
      </template>
      <span>{{file.name}}</span>
    </v-tooltip>
  </div>
  <AttachmentDetailsDialog
    v-if="dialog"
    v-model="dialog"
    :files="files"
    :readonly="readonly"
    @fileUploaded="onFileUploaded"
    @fileDeleted="onFileDeleted"
    @fileRenamed="onFileRenamed"
  />
  <AttachmentsPreviewDialog
    v-model="showPreviewDialog"
    :files="files"
    :showIndex="fileClickedIndex"
  />
  <v-btn
    v-if="!readonly"
    class="control-button mx-1"
    :class="{invisible: !showControls}"
    @click="showDialog"
    icon
    x-small
    color="accent"
    ref="input"
    @focus.native="$emit('focus')"
    @blur.native="$emit('blur')"
  >
    <v-icon small>mdi-plus</v-icon>
  </v-btn>
</div>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import AttachmentDetailsDialog from '@/components/attachments/AttachmentDetailsDialog.vue'
import FileThumbnail from '@/components/FileThumbnail.vue'
import AttachmentsPreviewDialog from '@/components/attachments/AttachmentsPreviewDialog.vue'

export default {
  name: 'GridAttachmentCell',
  mixins: [gridCells],
  data () {
    return {
      showControls: false,
      dialog: false,
      showPreviewDialog: false,
      fileClickedIndex: undefined
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    files() {
      return Array.isArray(this.model) ? this.model : []
    }
  },
  methods: {
    showDialog() {
      this.dialog = true
    },
    showPreview(index) {
      this.fileClickedIndex = index
      this.showPreviewDialog = true
    },
    onFileUploaded(newFiles) {
      this.model = newFiles
      this.finishedEditing()
    },
    onFileDeleted(fileUrl) {
      const newFiles = [...this.files]
      const deleteIndex = newFiles.findIndex(file => file.url === fileUrl)
      if (deleteIndex >= 0) {
        newFiles.splice(deleteIndex, 1)
        this.model = newFiles
        this.finishedEditing()
      }
    },
    onFileRenamed(fileUrl, newName) {
      const newFiles = JSON.parse(JSON.stringify(this.files))
      const updateIndex = newFiles.findIndex(file => file.url === fileUrl)
      newFiles[updateIndex].name = newName
      this.model = newFiles
      this.finishedEditing()
    },
    finishedEditing() {
      this.$emit('blur')
    },
    selectInput() {
      this.$refs.input.$el.focus()
    }
  },
  components: {
    AttachmentDetailsDialog,
    FileThumbnail,
    AttachmentsPreviewDialog
  }
}
</script>

<style scoped>
.attachment-cell {
  position: relative;
}

.attachment-list {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.control-padding {
  padding-right: 32px;
}

.control-button {
  position: absolute;
  right: 0;
}

.invisible {
  opacity: 0;
}

.pointer {
  cursor: pointer;
}
</style>
