// Source : 
// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1)
  }))
}

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

// Inspired from : https://jsfiddle.net/magikMaker/7bjaT/

export function base64EncodeUrl(str){
  const base64 = b64EncodeUnicode(str)
  // eslint-disable-next-line no-useless-escape
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '')
}


export function base64DecodeUrl(str) {
  try {
    let base64 = str.replace(/-/g, '+').replace(/_/g, '/')
    switch (base64.length % 4) {
      case 2: base64 += '==' ; break
      case 3: base64 += '=' ; break
    }
    return b64DecodeUnicode(base64)
  } catch (e) {
    console.error('URL-safe Decoding Error:', e)
    return null
  }
}