<!-- eslint-disable vue/valid-v-slot -->
<!-- Prevent wrong eslint for v-slot:item.state -->
<!-- See https://v2.vuetifyjs.com/en/api/v-data-table/#slots-item.%3Cname%3E -->
<template>
  <div :style="pageStyle" class="overflow-auto">
        <v-data-table
          :headers="headers"
          :items="flowInstances"
          :server-items-length="totalFlowInstances"
          :loading="loading"
          :expanded.sync="expanded"
          :options.sync="options"
          :footer-props="footerProps"
          item-key="uri"
          show-expand
          class="elevation-1 flow-table"
        >
          <template v-slot:item.state="{ item }">
            <v-chip
              :color="stateColor(item.state)"
              filter
              label
              pill
              small
              dark
            >{{item.stateLabel}}</v-chip>
          </template>
          <template v-slot:item.link="{ item }">
            <v-btn v-if="showOpenButton(item)" small text @click="linkClicked(item)" color="primary"> <v-icon>mdi-share</v-icon>{{$t('flow.instanceList.labels.open')}}</v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :style="tableStyle"  class="pa-0" :colspan="headers.length">
                <div v-for="(step, index) in item.instance.steps" :key="index">
                  <FlowInstanceDetails :flow="flow" :step="step"/>
                </div>
            </td>
          </template>
        </v-data-table>
        <router-view />
  </div>
</template>

<script>
const TABS_HEIGHT = 48
import FlowInstanceDetails from './FlowInstanceDetails.vue'
import moment from 'moment'
import { SIDE_NAVIGATION_WIDTH } from '@/components/space/SideNavigation.vue'
import {instanceStates} from '@/store/models/flow/FlowInstance.js'
import { DATETIME_SECONDS_DISPLAY_FORMAT } from '@/constants/dateFormats'
import FlowFormNode from '@/store/models/flow/nodes/FlowFormNode'

export default {
  props: {
    flowUri: null
  },
  data() {
    return {
      expanded: [],
      flowInstances: [],
      totalFlowInstances: 0,
      options: {},
      footerProps: {
        'items-per-page-options': [ 5, 10, 15, 20 ]
      },
      loading: false,
    }
  },
  computed: {
    pageStyle() {
      return {
        height: `calc(100vh - ${TABS_HEIGHT}px - ${this.$vuetify.application.top}px)`
      }
    },
    tableStyle() {
      return {
        'max-width': `calc(100vw - ${this.sideBarWidth}px)`
      }
    },
    sideBarWidth() {
      const sideNavigationWidth = this.$store.getters.sideNavigationShown ? SIDE_NAVIGATION_WIDTH : 0
      return sideNavigationWidth
    },
    flow() {
      return this.$store.getters.flowWithUri(this.flowUri)
    },
    headers() {
      return [
        {text: this.$t('flow.instanceList.labels.started'), value: 'start'},
        {text: this.$t('flow.instanceList.labels.steps'), value: 'steps'},
        {text: this.$t('flow.instanceList.labels.state'), value: 'state'},
        {text: this.$t('flow.instanceList.labels.open'), value: 'link'},
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.loadFlowInstances()
      },
      deep: true,
    },
    flow: {
      immediate: true,
      handler () {
        this.loadFlowInstances()
      },
    },
  },
  methods: {
    dateTimeDisplay(dateTimeString) {
      if (dateTimeString == null) {
        return this.$t('flow.instanceList.unknownDate')
      }
      return moment(dateTimeString).format(DATETIME_SECONDS_DISPLAY_FORMAT)
    },
    async loadFlowInstances () {
        const { page, itemsPerPage } = this.options
        // Do not load if the data-table options are not yet initialised
        if (!page || ! itemsPerPage) {
          return
        }
        if(this.flow == null) return
        this.loading = true
        try {
          const instances = await this.$store.dispatch('AGListFlowsInstancesOperation', { flow: this.flow, pageSize: itemsPerPage , pageIndex: page })
          const promises = instances.items.map(instance => this.$store.dispatch('AGReadFlowInstanceOperation', instance))
          const instanceList = await Promise.all(promises)
          const modelList = instanceList.map(instance => ({
            start: this.dateTimeDisplay(instance.createdAt),
            steps: instance.steps.length,
            state: instance.state,
            stateLabel: this.stateLabels(instance.state),
            uri: instance.uri,
            instance
          }))
          this.flowInstances = modelList
          this.totalFlowInstances = instances.numberOfItems
        } finally {
          this.loading = false
        }
    },
    stateColor(state) {
      const colorMap = {
        [instanceStates.done]: 'green',
        [instanceStates.error]: 'error',
        [instanceStates.created]: 'orange',
      }
      return colorMap[state] ?? 'grey'
    },
    stateLabels(state) {
      return this.$te(`flow.instanceList.states.${state}`) ? this.$t(`flow.instanceList.states.${state}`) : state
    },
    async linkClicked(item) {
      // get the flow instance link 
      // open it
      const link =  await item.instance.getFlowFormLink()
      const url = window.origin + link.redirectUri
      var win = window.open( url, '_blank')
      win.focus()
    },
    showOpenButton(item) {
      return ![instanceStates.error, instanceStates.done].includes(item.state) && this.flow.nodes.some(node => node instanceof FlowFormNode)
    }
  },
  components: {
    FlowInstanceDetails
  }
}
</script>

<style scoped lang="scss">
::v-deep.flow-table {
  .v-data-table__expanded__content {
    box-shadow: none !important;
  }
}
</style>
