<template>
  <div class="d-flex flex-column ma-6">
      <v-sheet rounded elevation="2" color="white" class="profile pa-10">
        <div class="text-h6">{{$t('profile.title')}}</div>
        <v-divider class="mt-2 mb-4"></v-divider>
        <div class="text-subtitle-2 mb-3">{{$t('profile.profileImage')}}</div>

        <AvatarEditor/>
        <div class="mt-4" style="max-width: 420px;">


          <div class="text-subtitle-2 bold mb-1">{{$t('profile.firstName')}}</div>
          <v-text-field
            v-model="firstName"
            dense
            flat solo
            readonly
            background-color="white"
            class="textField"
          ></v-text-field>

          <div class="text-subtitle-2 mb-1">{{$t('profile.lastName')}}</div>
          <v-text-field
            v-model="lastName"
            dense
            flat solo
            readonly
            background-color="white"
            class="textField"
          ></v-text-field>

          <div class="text-subtitle-2 bold mb-1">{{$t('profile.email')}}</div>
          <v-text-field
            v-model="email"
            dense
            flat solo
            readonly
            background-color="white"
            class="textField"
          ></v-text-field>


          <v-btn v-if="!confirmDeletion" @click="confirmDeletion = true" text dense color="destructive">{{$t('profile.delete.buttonLabel')}}</v-btn>

          <v-alert
            dense
            v-else
            color="red darken-2"
            border="left"
            elevation="0"
            type="error"  text
            >
            <span>{{$t('profile.delete.description')}}</span>
          <v-text-field
            v-if="confirmDeletion" light
            v-model="confirmDeletionText"
            placeholder="DELETE"

            color="red darken-2"
            dense
            class="mt-6"
            solo flat>
          </v-text-field>
          <v-btn color="red darken-4" text light @click="confirmDeletion = false">{{$t('profile.delete.cancelButtonLabel')}}</v-btn>
          <v-btn :disabled="confirmDeletionText != 'DELETE' " @click="deleteAccount"  color="red darken-4" text light>{{$t('profile.delete.buttonLabel')}}</v-btn>

          </v-alert>
          <v-list two-line >
            <v-list-item @dblclick="showHiddenFeature = true">
              <v-list-item-content>
                <div class="mr-2 subtitle-2">{{ $t("user.userDevSwitch") }}</div>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  dense 
                  data-testid="userDevSwitch"
                  v-model="alphaMode"
                  color="primary"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="developerMode | showHiddenFeature">
              <v-list-item-content>
                <v-list-item-title>Show developer mode</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  data-testid="developerSwitch"
                  v-model="developerMode"
                  color="primary"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>

            
            <v-list-item v-if="developerMode && showHiddenFeature">
              <v-list-item-content>
                <v-list-item-title>Invoke undefined method</v-list-item-title>
                <v-list-item-subtitle>methodNotDefinedTest</v-list-item-subtitle>                
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text @click="triggerError" color="error">Trigger error</v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="developerMode && showHiddenFeature">
              <v-list-item-content>
                <v-list-item-title>Report error</v-list-item-title>
                <v-list-item-subtitle>captureException</v-list-item-subtitle>                
                <v-list-item-subtitle></v-list-item-subtitle>                
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text @click="reportError" color="error">Report error</v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="developerMode && showHiddenFeature">
              <v-list-item-content>
                <v-list-item-title>Report error</v-list-item-title>
                <v-list-item-subtitle>captureException</v-list-item-subtitle>                
                <v-list-item-subtitle></v-list-item-subtitle>                
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text @click="triggerAPIError" color="error">API error (get on /test)</v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="developerMode && showHiddenFeature">
              <v-list-item-content>
                <v-list-item-title>Show Error Popup</v-list-item-title>
                <v-list-item-subtitle>test Error Message</v-list-item-subtitle>                
                <v-list-item-subtitle></v-list-item-subtitle>                
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text @click="errorPopup" color="error">error popup</v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="developerMode && showHiddenFeature">
              <v-list-item-content>
                <v-list-item-title>Invalidate Access Token</v-list-item-title>
                <v-list-item-subtitle>set an ivalid token</v-list-item-subtitle>                
                <v-list-item-subtitle></v-list-item-subtitle>                
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text @click="invalidateToken" color="error">invalidate</v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="developerMode && showHiddenFeature">
              <v-list-item-content>
                <v-list-item-title>Invalidate Refresh Token</v-list-item-title>
                <v-list-item-subtitle>set an ivalid refresh and access token</v-list-item-subtitle>                
                <v-list-item-subtitle></v-list-item-subtitle>                
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text @click="invalidateRefreshToken" color="error">invalidate</v-btn>
              </v-list-item-action>
            </v-list-item>



          </v-list>
        </div>
      </v-sheet>
  </div>
</template>

<script>
import AvatarEditor from '@/components/user/AvatarEditor.vue'
import errorBus from '@/utils/errorBus.js'
import ApiClient from '@/ApiClient'
import { setAccessToken, setRefreshToken } from '@/utils/auth'

export default {
  data: () => ({
    showHiddenFeature: false,
    confirmDeletion: null,
    confirmDeletionText: null
  }),
  mounted() {
    document.title = `${this.$t('profile.title')}`
  },
  computed: {
    email() {
      return this.$store.state.user.user?.email
    },
    firstName() {
      return this.$store.state.user.user?.firstName
    },
    lastName() {
      return this.$store.state.user.user?.lastName
    },
    alphaMode: {
      get() {
        return this.$store.getters.alphaMode
      },
      set(value) {
        this.$store.commit('setAlphaMode', value)
      }
    },
    developerMode: {
      get() {
        return this.$store.getters.developerMode
      },
      set(value) {
        this.$store.commit('setDeveloperMode', value)
      }
    },
  },
  methods: {
    async deleteAccount() {
      this.$intercom?.update({
        deleteAccount: true
      })
      await this.$store.dispatch('deleteUser')
      window.location = 'https://www.apptivegrid.de'
    },
    triggerError() {
      this.methodNotDefinedTest()
    },
    triggerAPIError() {
      ApiClient.getUri('api/exception').catch( error => {
        error.sendAlways = true
       this.$apptiveErrorReporter.captureException(error)

      })
    },
    reportError() {
      try {
        this.methodNotDefinedTest()
      } catch (error) {
        this.$apptiveErrorReporter.captureException(error)
      }
    },
    errorPopup() {
      const error = new Error('test Error Message')
      error.sendAlways = true
      errorBus.$emit('ERROR', error)
    },
    invalidateToken() {
      ApiClient.setAuth('invalid-set-by-apptivegrid-web-app-test')
      setAccessToken('invalid-set-by-apptivegrid-web-app-test')
    },
    invalidateRefreshToken() {
      this.invalidateToken()
      setRefreshToken('invalid-set-by-apptivegrid-web-app-test')
    }
  },
  components: {
    AvatarEditor,
  },
}
</script>

<style lang="css" scoped>
.textField >>> .v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
</style>
