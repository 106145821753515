export const GALLERY_CARD_POSITION = 'galleryCardPosition'
export const GALLERY_CARD_IMAGE_MODE = 'galleryCardImageMode'
export const GALLERY_CARD_IMAGE_MODE_NONE = 'noImage'
export const GALLERY_CARD_IMAGE_MODE_FIRST = 'firstImage'
export const GALLERY_CARD_HIDE_FIELD_TYPE_ICON = 'hideFieldTypeIcon'

export const GALLERY_CARD_SIZE = 'galleryCardSize'
export const GALLERY_CARD_SIZE_SMALL = 'small'
export const GALLERY_CARD_SIZE_MEDIUM = 'medium'
export const GALLERY_CARD_SIZE_LARGE = 'large'

export const GALLERY_CARD_IMAGE_MODES = [
  GALLERY_CARD_IMAGE_MODE_NONE,
  GALLERY_CARD_IMAGE_MODE_FIRST
]

export const GALLERY_CARD_SIZES = [
  GALLERY_CARD_SIZE_SMALL,
  GALLERY_CARD_SIZE_MEDIUM,
  GALLERY_CARD_SIZE_LARGE
]