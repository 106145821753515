<template>
   <v-dialog
    :disabled="disabled"
     v-model="dialog"
     persistent
   >
     <template v-slot:activator="{ on, attrs }">
      <MenuItem
        :disabled="disabled"
        v-on="on" v-bind="attrs"
        @click="steps = 1"
        icon="mdi-file-delimited"
        :text="$t('csvImport.buttons.openComponent')"
      />
     </template>
     <v-card>
       <v-card-title class="text-h5">
         {{$t('csvImport.componentTitle')}}
       </v-card-title>
       <v-card-text>
<v-stepper
 v-model="steps"
 vertical
>

 <v-stepper-step
  :complete="steps > 1"
  step="1"
  editable
>
  {{$t('csvImport.steps.loadFile.title')}}
</v-stepper-step>

<v-stepper-content step="1">
  {{$t('csvImport.steps.loadFile.fileFormatConstrainsInfo')}}
  <vue-csv-import
      :key="componentKey"
      v-model="csv"
      :mapFields="mapFields"
      :autoMatchFields="true"
      :autoMatchIgnoreCase="true"
      :canIgnore="true"
      url="false"
      :finally="csvParsed"
      tableClass="tableClass"
      tableSelectClass="tableSelectClass"
      :ignoreOptionText="$t('csvImport.steps.loadFile.ignoreOptionText')"
      >
      <template slot="hasHeaders" slot-scope="{headers, toggle}">
        <v-checkbox id="hasHeaders" :value="headers" @change="toggle" :label="$t('csvImport.steps.loadFile.hasHeaderRow')"></v-checkbox>
      </template>

      <template slot="error">
        {{$t('csvImport.steps.loadFile.fileFormatInvalid')}}
      </template>

      <template slot="next" slot-scope="{load}">
          <v-btn
              class="mt-2 mb-2"
              color="primary"
              @click.prevent="load"
            >
            {{$t('csvImport.steps.loadFile.loadFileButton')}}
            </v-btn>
      </template>

      <template slot="thead">
        <tr>
            <th>{{$t('csvImport.steps.loadFile.mappingTableHeaderGrid')}}</th>
            <th>{{$t('csvImport.steps.loadFile.mappingTableHeaderCSV')}}</th>
        </tr>
      </template>
      <template slot="submit" slot-scope="{submit}">
          <v-btn
              class="mt-2 mb-2"
              color="primary"
              @click.prevent="() => {fileLoading = true; submit()}"
            >
              <!-- The process is freezing the UI, using a text indicator instead of a spinner : -->
              {{fileLoading ? $t('csvImport.buttons.loading') : $t('csvImport.steps.loadFile.loadCSVButton')}}
            </v-btn>
      </template>
    </vue-csv-import>
</v-stepper-content>

<v-stepper-step
 :complete="steps > 2"
 step="2"
>
   {{$t('csvImport.steps.preview.title')}}
</v-stepper-step>

<v-stepper-content step="2">
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th
            class="text-left"
            v-for="(field, index) in virtualGrid.fields"
            :key="`field ${index}`">
              {{field.name}}
              <v-chip
              class=""
              x-small chip
              >
              {{field.columnType.displayString}}
              </v-chip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entity, entityIndex) in entities"
          :key="`entity ${entityIndex}`"
        >
          <td
            v-for="(fieldValue, index) in entity.fields"
            :key="`value ${entityIndex} ${index}`"
          >
            <div v-if="fieldValue">
              <span
                :class="hasError(entityIndex, index) ? 'red--text': ''">
              {{ fieldValue }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-btn
      class="mt-2 mb-2"
      color="primary"
      :disabled="this.error != null || !this.entities.length"
      @click.prevent="createEntities"
    >

      {{$t('csvImport.steps.preview.importButton')}}
    </v-btn>
</v-stepper-content>

<v-stepper-step
 :complete="steps > 3"
 step="3"
>
 {{$t('csvImport.steps.import.title')}}

</v-stepper-step>

<v-stepper-content step="3">
  <div v-if="entities">
     {{$t('csvImport.steps.import.progressDescription', { numberOfEntities: entities.length })}}
    <v-progress-linear :value="importingProgress"></v-progress-linear>
  </div>

</v-stepper-content>

<v-stepper-step
 :complete="steps == 4"
 step="4"
>
 {{$t('csvImport.steps.done.title')}}

</v-stepper-step>

<v-stepper-content step="4">
  <div v-if="sentEntities">
     {{$t('csvImport.steps.done.result', { numberOfImportedEntities: sentEntities.length })}}
  </div>

</v-stepper-content>


</v-stepper>

</v-card-text>
       <v-card-actions>
         <v-spacer></v-spacer>

         <v-btn
           color="grey darken-1"
           text
           @click="done"
         >
           {{doneButtonTitle}}
         </v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
</template>

<script>
import {
  VueCsvImport
} from 'vue-csv-import'
import typeValidation from '@/utils/typeValidation.js'
import MenuItem from '@/components/MenuItem.vue'

export default {
  name: 'CSVImportEntitiesDialog',
  props: {
    virtualGrid: null,
    disabled: null
  },
  data() {
    return {
      dialog: false,
      newName: null,
      csv: null,
      steps: 1,
      entities: [],
      validationErrors: [],
      error: null,
      componentKey: 0,
      shouldStoppImport: false,
      addColumnLoading: false,
      fileLoading: false
    }
  },
  computed: {
    mapFields() {
      return this.virtualGrid?.fields.map(field => field.name)
    },
    sentEntities() {
      return this.entities.filter(entity => entity.sent)
    },
    importingProgress() {
      return (this.sentEntities.length / this.entities.length) * 100
    },
    doneButtonTitle() {
      return this.steps == 4 ? this.$t('dialogs.closeButton') : this.$t('dialogs.cancelButton')
    }
  },
  methods: {
    async createEntities() {
      this.steps = 3
      this.shouldStoppImport = false
      for (let i = 0; i < this.entities.length; i++) {
        let entity = this.entities[i]
        if(this.shouldStoppImport) return
        await this.$store.dispatch('AGAddEntityOperation', {
          uri: this.virtualGrid.uri,
          entity: entity
        })
        this.$set(entity, 'sent', true)
      }
      this.steps = 4
    },
    csvParsed() {
      // create entities
      this.entities = []
      this.errors = []
      this.error = null

      this.csv.forEach((row) => {
          const newEntity = this.virtualGrid.emptyEntity()
          // add a value per entity field
          this.virtualGrid.fields.forEach((field, index) => {
            const newValue = row[field.name]
            const sanitizedValue = typeValidation.sanitizedEntityValue(field.columnType, newValue)
            newEntity.fields[index] = sanitizedValue
          })
          // reject empty lines
          if(!newEntity.fields.every(element => element === null)) {
            this.validate(newEntity)
            this.entities.push(newEntity)
          }
      })
      this.fileLoading = false
      this.steps = 2
    },
    validate( entity ){
      const errors = typeValidation.validationErrors(entity, this.virtualGrid.fields.map(field => field.type.jsonSchema))
      this.validationErrors.push( errors )
    },
    hasError(entityIndex, position) {
      const instancePath = '/fields/' + position
      const error = this.validationErrors[entityIndex].find(error => error.instancePath == instancePath)
      if (error) {
        this.error = error
      }
      return error
    },
    done() {
      // reset data
      this.shouldStoppImport = true
      this.dialog = false
      this.entities = []
      this.error = null
      this.csv = null
      // rerender csv importer component to uncache data
      this.forceRerender()
      // show grid view. reload gridview
      this.virtualGrid.reload()
      this.$intercom?.trackEvent( 'User imported entities from csv' )
    },
    forceRerender() {
      this.componentKey += 1
    }
  },
  components: {
    VueCsvImport,
    MenuItem
  }
}
</script>

<style lang="css">
.tableClass tr:nth-child(even) {
  background-color: #f2f2f2;
}
.tableClass th {
  color: black;
}
.tableClass th, .tableClass td {
  padding: 3px;
}
select, .tableSelectClass {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  border-radius: 2px;
  padding-right: 15px;

}
.tableClass {
  border: none;
}

</style>
