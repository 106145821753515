<template>
    <v-sheet v-if="form != null && externalModel" class="f">
      <v-btn text color="primary"  @click="externalModel = false" class="ml-n4">
        <v-icon>mdi-arrow-left</v-icon>{{ $t('forms.builder.i18n.dialog.backButton') }}
      </v-btn>
      <div class="text-h6">{{ $t('forms.builder.i18n.dialog.title') }}</div>

          <v-select
            v-model="currentLanguage"
            :items="formAdditionalLanguages"
            :label="$t('forms.builder.i18n.dialog.currentLanguage')"
          />
          <v-subheader class="pl-0 mt-5 mb-3">{{ $t('forms.builder.i18n.dialog.globalTexts') }}</v-subheader>
          <v-text-field
            v-for="(key, index) in Object.keys(form.defaultModel)"
            v-model="currentLanguageI18n[key]"
            :key="index"
            :label="$t(`forms.builder.i18n.dialog.globalTextLabels.${key}`)"
            :hint="form.defaultModel[key]"
            outlined
            dense
            @input="dirty = true"
            @blur="onInputBlur"
          />
  
          <v-subheader class="pl-0 mt-5 mb-3">{{ $t('forms.builder.i18n.dialog.componentTexts') }}</v-subheader>
          <template v-for="component in form.components">
            <div class="text-subtitle-2 mb-3" :key="component.property">{{ component.property }}</div>
            <v-text-field
              v-for="key in componentKeys"
              :key="`${component.fieldId}_${key}`"
              :value="componentValue(component.fieldId, key)"
              :label="$t(`forms.builder.${key}`)"
              :hint="defaultValue(component.fieldId, key)"
              outlined
              dense
              @input="newVal => updateComponent(component.fieldId, key, newVal)"
              @blur="onInputBlur"
            />
          </template>
  
          <v-subheader v-show="form.textBlocks.lenght" class="pl-0 mt-5 mb-3">{{ $t('forms.builder.i18n.dialog.textBlocks') }}</v-subheader>
          <template v-for="(block, index) in form.textBlocks">
            <v-text-field
              v-if="block.style === 'header'"
              :key="block.id"
              :value="blockValue(block.id)"
              :label="`text ${index + 1}`"
              :hint="block.text"
              outlined
              dense
              @input="newVal => updateBlockText(block.id, newVal)"
              @blur="onInputBlur"
            />
            <v-textarea
              v-else
              :key="block.id"
              :value="blockValue(block.id)"
              :label="`text ${index + 1}`"
              :hint="block.text"
              outlined
              @input="newVal => updateBlockText(block.id, newVal)"
              @blur="onInputBlur"
            />
          </template>
    </v-sheet>
</template>

<script>
import externalModel from '@/mixins/externalModel'
import Vue from 'vue'

export default {
    mixins: [externalModel],
    props: {
        form: null,
    },
    data() {
        return {
            currentLanguage: undefined,
            internalModel: undefined,
            dirty: false
        }
    },
    watch: {
        form: {
            immediate: true,
            handler(newVal) {
                if (newVal == null) {
                  return
                }
                this.internalModel = JSON.parse(JSON.stringify(newVal.i18n ?? {}))
                this.currentLanguage = newVal.additionalLanguages?.[0]
                for (const language of newVal.additionalLanguages) {
                    if (this.internalModel[language] == null) {
                        Vue.set(this.internalModel, language, this.form.blankI18nLanguageModel())
                    }
                }
            }
        }
    },
    computed: {
        formAdditionalLanguages: {
            get() {
                return this.form.additionalLanguages
            },
            set(newVal) {
                this.form.additionalLanguages = newVal
                for (const language of newVal) {
                    if (this.internalModel[language] == null) {
                        Vue.set(this.internalModel, language, this.form.blankI18nLanguageModel())
                    }
                }
                if (this.currentLanguage == null) {
                    this.currentLanguage = newVal[0]
                }
            }
        },
        currentLanguageI18n() {
            return this.internalModel?.[this.currentLanguage] ?? {}
        },
        componentKeys() {
            return ['label', 'description']
        }
    },
    methods: {
        componentValue(fieldId, key) {
            return this.form.fieldProperties[fieldId]?.i18n?.[this.currentLanguage]?.[key]
        },
        updateComponent(fieldId, key, newVal) {
            this.form.fieldProperties[fieldId].i18n = this.form.fieldProperties[fieldId].i18n ?? {}
            this.form.fieldProperties[fieldId].i18n[this.currentLanguage] = this.form.fieldProperties[fieldId].i18n[this.currentLanguage] ?? {}
            this.form.fieldProperties[fieldId].i18n[this.currentLanguage][key] = newVal
            this.dirty = true
        },
        defaultValue(fieldId, key) {
            const component = this.form.components.find(item => item.fieldId === fieldId)
            return component.options[key]
        },
        blockValue(id) {
            const block = this.form.textBlocks.find(item => item.id === id)
            return block?.i18n?.[this.currentLanguage]?.text
        },
        updateBlockText(id, newVal) {
            const blockIndex = this.form.textBlocks.findIndex(item => item.id === id)
            this.form.textBlocks[blockIndex].i18n = this.form.textBlocks[blockIndex].i18n ?? {}
            this.form.textBlocks[blockIndex].i18n[this.currentLanguage] = this.form.textBlocks[blockIndex].i18n[this.currentLanguage] ?? {}
            this.form.textBlocks[blockIndex].i18n[this.currentLanguage].text = newVal
            this.dirty = true
        },
        async onInputBlur() {
          await this.$nextTick()
          if (!this.dirty) {
            return
          }
          await this.save()
          this.dirty = false
        },
        save() {
            this.form.i18n = this.internalModel
            return this.form.updateComponents()
        }
    },
    components: { }
}
</script>

<style lang="scss" scoped>


.body {
  align-items: center;
}


.close-button {
  position: fixed;
  top: 12px;
  right: 12px;
}
</style>