<template>
  <BaseFormInput v-bind="$props">
    <v-text-field
      ref="input"
      class="grey--text text--darken-1"
      v-model="inputValue"
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema, rules.coordinates]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      @keypress="filterAllButDecimals"
      :color="color"
      :disabled="disabled"
      v-bind="fieldStyle"

    >
      <template v-if="!disabled" v-slot:append>
        <LocationPicker
          :value="value"
          @input="newVal => inputValue = newVal"
          :mapOptions="mapOptions"
        />
      </template>
    </v-text-field>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import inputValidation from '@/utils/inputValidation.js'
import LocationPicker from '@/components/map/LocationPicker.vue'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

const DISPLAY_LAT = 0
const DISPLAY_LON = 1

export default {
  mixins: [formFields],
  computed: {
    inputValue: {
      get() {
        if (!this.value) {
          return undefined
        }
        // Displayed coordinates are inverted compared to the API format
        // to allow copy pasting from google maps
        return `${this.value.lat},${this.value.lon}`
      },
      set(newVal) {
        if (!newVal || typeof newVal === 'object') {
          this.$emit('input', newVal)
        } else {
          const coordinatesRegexp = new RegExp(/^-?\d+(\.\d+)?,\s?-?\d+(\.\d+)?$/)
          if (!newVal || !coordinatesRegexp.test(newVal)) {
            return
          }
          const parsedValue = newVal.split(',').map(string => parseFloat(string))
          // Displayed coordinates are inverted compared to the API format
          // to allow copy pasting from google maps
          this.$emit('input', {lat: parsedValue[DISPLAY_LAT], lon: parsedValue[DISPLAY_LON]})
        }
      }
    },
    mapOptions() {
      return {
        zoom: 14,
        center: {
          lat: this.value ? parseFloat(this.value.lat) : 50.941248064035904,
          lng: this.value ? parseFloat(this.value.lon) : 6.958303812540371
        },
        mapTypeControl: false,
        streetViewControl: false,
        styles: [{
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }]
      }
    }
  },
  methods: {
    filterAllButDecimals(event) {
      return inputValidation.filterAllButDecimals(event)
    }
  },
  components: {
    LocationPicker,
    BaseFormInput
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map-menu {
  width: 400px;
  height: 400px;
}
</style>
