export default class HalObject {
  constructor(data) {
    this._links = data._links
    this._embedded = data._embedded
    this.uri = this._links?.self?.href ?? this._links?.read?.href
  }

  getLink(link) {
    const href = this._links?.[link]?.href
    if (href == null) {
      throw `${this.uri} Missing ${link} permission`
    }
    return href
  }
}
