const defaultsOptions = {
  multi: false,
  placeholder: null,
  description: null,
  label: null
}

export default class FormComponent {
  constructor(name, fieldId, fieldType) {
    this.property = name
    this.fieldId = fieldId
    this.options = Object.assign({}, defaultsOptions)
    this.type = fieldType
    this.required = false
  }
}
