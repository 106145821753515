<template>
  <v-dialog v-model="externalModel" persistent max-width="800"  >
    <v-card color="black">
      <v-card-title>
        <span class="white--text"> {{ label }}</span>
        <v-spacer></v-spacer>
        <div class="timer" :class="{ 'recording': isRecording }">
          <span>{{ formattedDuration }}</span>
        </div>
      </v-card-title>

      <!-- Pre-camera access message -->
      <div v-if="!cameraReady && !isRecording" class="camera-info d-flex align-center flex-column justify-center">
        <span class="white--text text-center"> {{$t('dialogs.camera.permissionText')}}</span>
        <v-btn color="red" text @click="requestCameraAccess">{{$t('dialogs.camera.permissionButton')}}</v-btn>
      </div>

      <!-- Recording element -->
      <div v-show="videoURL == null && cameraReady">
        <v-card-text class="ma-0 pa-0">
            <div class="description pa-2 pl-8">
              <span class="white--text text-center"> {{ description }}</span>
            </div>
            
            <!-- Video element -->
            <video ref="videoElement" class="video-feed" playsinline webkit-playsinline autoplay muted></video>
            <!-- Canvas element to draw the video and timestamp -->
            <canvas ref="canvas" class="canvas" width="300" height="200"></canvas>
            <v-btn
              fab
              large
              :color="isRecording ? 'white' : 'red'"
              class="fab-record"
              :class="{ 'recording': isRecording }"
              @click="isRecording ? stopRecording() : startRecording()"
            >
              <v-icon large v-if="isRecording">mdi-stop</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn color="white" text @click="close()">{{$t('dialogs.cancelButton')}}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
      </div>

      <!-- Preview element -->

      <div v-show="videoURL">
      <v-card-text class="ma-0 pa-0">

      <video ref="playback" class="video-playback" playsinline webkit-playsinline controls muted></video>

      </v-card-text>
        <v-card-actions>
          <v-btn color="white" text @click="retry()">{{$t('dialogs.retryButton')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="white" text :disabled="videoURL == null" :loading="saveLoading" @click="save()">{{$t('dialogs.saveButton')}}</v-btn>
        </v-card-actions>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
const VIDEO_TYPE = 'video/mp4'

import externalModel from '../../mixins/externalModel'
import { uploadFileToS3 } from '@/utils/s3Utils.js'
import { v4 as uuidv4 } from 'uuid'
import ApiClient from '@/ApiClient'
export default {
  mixins: [externalModel],
  props: ['videoFile', 'color', 'description', 'label'],
  data () {
    return {
      cameraReady: false,  // Track if the camera is ready
      mediaRecorder: null,
      recordedChunks: [],
      isRecording: false,
      videoStream: null,
      videoURL: null,
      saveLoading: false,
      timer: 0,
      interval: null,
      currentDateTime: null, // To store the timestamp
      canvasContext: null, // For drawing on the canvas
      canvasStream: null, // For capturing the canvas stream
      serverTime: null
    }
  },
  watch: {
    // Watch the modal state and trigger camera when opened
    externalModel(newVal) {
      if (!newVal) {   
        this.stopCamera()
     
      } 
    }
  },
  mounted () {
  },
  computed: {
    formattedTime () {
      return `${this.formattedDuration} ${this.serverTime}`
    },
    formattedDuration() {
      const hours = String(Math.floor(this.timer / 3600)).padStart(2, '0')
      const minutes = String(Math.floor((this.timer % 3600) / 60)).padStart(2, '0')
      const seconds = String(this.timer % 60).padStart(2, '0')
      return `${hours}:${minutes}:${seconds}`
    }
  },
  methods: {
    async loadCurrentTime () {
      try {
        const response = await  ApiClient.getUri('/api/version')
        const serverTime = new Date(response.headers.date) // Get date from response headers
        this.serverTime = serverTime
      } catch (error) {
        console.error('Error fetching server time: ', error)
      }
    },
    async requestCameraAccess () {
      try {
        // get current time 
        await this.loadCurrentTime()
        // Get video stream from user's camera
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { ideal: 'environment' },
            advanced: [{ torch: true }] // Try to enable torch if supported
          }
        })
        this.$refs.videoElement.srcObject = this.videoStream
        this.canvasContext = this.$refs.canvas.getContext('2d')
        
        // Wait until the video metadata is loaded to get video dimensions
        this.$refs.videoElement.addEventListener('loadedmetadata', () => {
        // Set canvas width and height to match the video dimensions
        if(this.$refs.videoElement) {
          this.$refs.canvas.width = this.$refs.videoElement.videoWidth
          this.$refs.canvas.height = this.$refs.videoElement.videoHeight
        }
        this.cameraReady = true
      })
      } catch (error) {
        console.error('Error accessing camera: ', error)
      }
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop())
        this.videoStream = null
      }
      if (this.canvasStream) {
        this.canvasStream.getTracks().forEach(track => track.stop())
        this.canvasStream = null
      }
      this.mediaRecorder = null
      this.videoURL = null
      this.timer = 0
      this.canvasContext = null // For drawing on the canvas
      this.cameraReady = false
    },
    retry() {
      this.stopCamera()
      this.requestCameraAccess()
    },
    drawFrame () {
      if (!this.isRecording) return // Stop if not recording
      // Draw the video frame onto the canvas
      const videoElement = this.$refs.videoElement
      const canvasElement = this.$refs.canvas
      this.canvasContext.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height)

      // Overlay the timestamp on the canvas
      this.canvasContext.font = '9px Arial'

      // Draw black background rectangle behind the text
      const textPadding = 5
      const textWidth = this.canvasContext.measureText(this.formattedTime).width
      const textHeight = 10 // Approximate height of the text
      this.canvasContext.fillStyle = 'black'
      this.canvasContext.fillRect(0, 0, textWidth + textPadding * 2, textHeight + textPadding * 2)

      // Draw white text for the timestamp
      this.canvasContext.fillStyle = 'white'
      this.canvasContext.fillText(this.formattedTime, 5, 15)
      // Continue drawing frames
      requestAnimationFrame(this.drawFrame)
    },
    async startRecording () {
      this.isRecording = true

      // Capture canvas stream for recording
      this.canvasStream = this.$refs.canvas.captureStream(30)
      if (!this.canvasStream) {
          console.error('Failed to capture canvas stream')
          return
      }

      this.mediaRecorder = new MediaRecorder(this.canvasStream)
      this.mediaRecorder.ondataavailable = this.hanldeOndataavailable
      this.mediaRecorder.onstop = this.handleRecordingStop
      this.mediaRecorder.start()

      // Start timer
      this.timer = 0
      this.interval = setInterval(() => {
        this.timer++
      }, 1000)

      // Start drawing frames with timestamp
      this.drawFrame()
    },
    stopRecording () {
      this.isRecording = false
      this.mediaRecorder.stop()
      this.recordedChunks = [] 
      // Stop the timer
      clearInterval(this.interval)
    },
    hanldeOndataavailable (event) {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data)
        } else {
          console.warn('Empty data chunk received')
        }
    },
    handleRecordingStop () {
      const blob = new Blob(this.recordedChunks, { type: VIDEO_TYPE })
      const url = URL.createObjectURL(blob)
      this.videoURL = url

      // Now point the video element to the recorded video URL
      const playbackElement = this.$refs.playback
      playbackElement.srcObject = null // Clear the live stream
      playbackElement.src = this.videoURL // Assign the recorded video URL
      playbackElement.load()

    },
    async save () {
      this.saveLoading = true
      try {
        const blob = new Blob(this.recordedChunks, { type: VIDEO_TYPE })
        this.recordedChunks = [] // Reset chunks for future recordings
        const name = this.fileName()
        const s3FileUrl = await uploadFileToS3(name, VIDEO_TYPE, blob)
        const videoFile = {
          name,
          type: VIDEO_TYPE,
          url: s3FileUrl
        }
        this.$emit('videoSaved', videoFile)
        this.close()
      } finally {
        this.saveLoading = false
      }
    },
    close () {
      this.externalModel = false
    },
    fileName () {
      return `video_${uuidv4()}.mp4`
    }
  } 
}
</script>

<style lang="scss" scoped>
.video-feed {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-playback{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fab-record {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.fab-record.recording {
  color: red;
}

.timer {
  padding: 5px;
  font-size: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  font-family: monospace;
}

.timer.recording {
  background-color: rgba(255, 0, 0, 1);
}

.canvas {
  display: none;
}
.description {
  position:absolute;
  top: 68px;
  left: 0px;
  width: 100%;
  min-height: 80px;
  background-color: rgba(0, 0, 0, 0.2);

}
.camera-info {
  min-height: 400px;
}
</style>
