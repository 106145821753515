<template>
    <div>
      <v-tabs grow icons-and-text height="52">
        <v-tab>
          <span class="caption">{{$t('forms.builder.pagesTabTitle')}}</span>
          <v-icon dense class="mt-3">mdi-list-box-outline</v-icon>
        </v-tab>
        <v-tab data-testid="layout-tab">
          <span class="caption">{{$t('forms.builder.styleTabLabel')}}</span>
          <v-icon dense class="mt-3">mdi-palette-outline</v-icon>
        </v-tab>
        <v-tab data-testid="settings-tab">
          <span class="caption">{{$t('forms.builder.moreTabLabel')}}</span>
          <v-icon dense class="mt-3">mdi-cog-outline</v-icon>
        </v-tab>
        <v-tab-item>
          <FormBuilderPagesTab
            :spaceUri="spaceUri"
            :gridUri="gridUri"
            :formUri="formUri"
            v-on:addField="addField"
            v-on:removeField="removeField"
          />
        </v-tab-item>
        <v-tab-item>
          <FormBuilderLayoutTab
            :gridUri="gridUri"
            :formUri="formUri"
          />
        </v-tab-item>
        <v-tab-item>
          <FormBuilderSettingsTab
            :spaceUri="spaceUri"
            :gridUri="gridUri"
            :formUri="formUri"
          />
        </v-tab-item>
      </v-tabs>
    </div>
</template>

<script>

import FormBuilderPagesTab from '@/components/form/formbuilder/FormBuilderPagesTab.vue'
import FormBuilderLayoutTab from '@/components/form/formbuilder/FormBuilderLayoutTab.vue'
import FormBuilderSettingsTab from '@/components/form/formbuilder/FormBuilderSettingsTab.vue'

export default {
  props: {
    spaceUri: null,
    gridUri: null,
    formUri: null
  },
  methods: {
    addField(payload) {
      this.$emit('addField', payload)
    },
    removeField(payload) {
      this.$emit('removeField', payload)
    },
  },
  components: {
    FormBuilderPagesTab,
    FormBuilderLayoutTab,
    FormBuilderSettingsTab
  }
}
</script>
