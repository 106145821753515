<template>
  <div class="d-flex align-center">
    <v-btn
      v-if="externalModel"
      ref="input"
      text
      color="primary"
      class="ml-1 pa-0"
      @click="dialog = true"
      @focus.native="$emit('focus')"
      @blur.native="$emit('blur')"
    >
      <FileThumbnail
        :file="externalModel"
      />
  </v-btn>
    <v-btn
      v-else
      ref="input"
      class="flex-grow-1 control-button mx-1"
      @click="dialog = true"
      @focus.native="$emit('focus')"
      @blur.native="$emit('blur')"
      text
      x-small
      color="primary"
    >
      <v-icon small>mdi-draw-pen</v-icon>
    </v-btn>
    <SignaturePadDialog
      v-model="dialog"
      :signatureFile="externalModel"
      @signatureSaved="signatureSaved"
      color="primary"
    />
  </div>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import FileThumbnail from '@/components/FileThumbnail.vue'
import externalModel from '../../mixins/externalModel'
import SignaturePadDialog from '../gridView/SignaturePadDialog.vue'

export default {
  mixins: [externalModel, gridCells],
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    signatureSaved(file) {
      this.externalModel = file
      this.finishedEditing()
    },
    finishedEditing() {
      this.$emit('blur')
    },
    selectInput() {
      this.$refs.input.$el.focus()
    }
  },
  components: {
    FileThumbnail,
    SignaturePadDialog
  }
}
</script>
