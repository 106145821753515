<template>
    <div class="safari-window">
      <div class="safari-header">
        <div class="safari-buttons">
          <span class="close"></span>
          <span class="minimize"></span>
          <span class="maximize"></span>
        </div>
        <input type="text" readonly :placeholder="placeholder" class="safari-address-bar">
      </div>
      <div class="safari-content">
        <!-- Slot for inserting custom content like forms -->
        <slot></slot>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'SafariWindow',
    props: ['url'],
    computed: {
        placeholder() {
            return this.url
        }
    }
    // You can add props or data if you need to dynamically control aspects of the component
  }
  </script>

  <style scoped>
  .safari-window {
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
      font-family: 'Arial', sans-serif;
      display: flex;
      flex-direction: column;
  }

  .safari-header {
      background-color: #f0f0f0;
      padding: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      align-items: center;
  }

  .safari-buttons {
      width: 50px;
      display: flex;
      justify-content: space-between;
  }

  .safari-buttons span {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: inline-block;
  }

  .close {
      background-color: #ff605c;
  }

  .minimize {
      background-color: #ffbd44;
  }

  .maximize {
      background-color: #00ca56;
  }

  .safari-address-bar {
      border: none;
      padding: 8px;
      margin-left: 10px;
      width: calc(100% - 90px);
      border-radius: 10px;
  }

  .safari-content {
      padding: 20px;
      background-color: #fff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: auto;
  }

  /* Additional styles for the form can go here */
  </style>
