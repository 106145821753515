<template>
  <BaseNodeComponent v-model="externalModel" v-bind="$attrs" @blur="$emit('blur')">
    <TemplateMapper
      v-model="externalModel"
      :disabled="mapperDisabled"
      @blur="$emit('blur')"
      ref="templateMapper"
      v-bind="$attrs"
    >
    <div
      class="d-flex flex-row align-center"
      v-for="(child, index) in children"
      :key="index"
    >
      <component
        :is="child.component"
        class="mt-1 flex-grow-1"
        :value="internalModel[child.props.key]"
        :error="errors[child.props.key]"
        :label="child.props.key"
        @input="newVal => update(child.props.key, newVal)"
        @click.native="lastActiveInput = child.props.key"
        @focus="lastActiveInput = child.props.key; $emit('focus')"
        @blur="$emit('blur')"
        :selected="selected && lastActiveInput === child.props.key"
        v-bind="{...$attrs, ...child.props}"
        :ref="`input-${child.props.key}`"
        data-testid="templateInput"
        mapperDisabled
        topLabel
      />
    </div>
  </TemplateMapper>
  </BaseNodeComponent>
</template>

<script>
import { createHolder } from '@/apptivescript/model'
import BaseNodeComponent from '@/components/flow/BaseNodeComponent.vue'
import TemplateInput from '@/components/flow/TemplateInput.vue'
import TemplateMapper from '@/components/flow/TemplateMapper.vue'
import externalModel from '@/mixins/externalModel.js'
import Vue from 'vue'

export default {
  mixins: [externalModel],
  props: {
    selected: {
      type: Boolean,
      default: () => false
    },
    children: null,
    items: null,
    mapperDisabled: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      internalModel: undefined,
      errors: undefined,
      lastActiveInput: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        const unwrappedValue = newVal?.value
        if (unwrappedValue == null || typeof unwrappedValue !== 'object') {
          this.internalModel = this.initialModel()
        } else {
          this.internalModel = {...unwrappedValue}
        }
        this.errors = this.initialErrors()
      }
    }
  },
  methods: {
    initialModel() {
      if (this.children == null) {
        return {}
      }
      let model = {}
      
      this.children
        .forEach(child => model[child.props.key] = null)
      return model
    },
    initialErrors() {
      if (this.children == null) {
        return {}
      }
      let model = {}
      
      this.children
        .forEach(child => model[child.props.key] = null)
      return model
    },
    update(key, newVal) {
      Vue.set(this.internalModel, key, newVal)

      // Allow setting all values to null :
      if (Object.values(this.internalModel).every(val => val == null || val === '')) {
        this.externalModel = null
        this.errors = this.initialErrors()
        return
      }

      const requiredKeys = ['lat', 'lon', 'uri', 'url', 'type']

      let hasErrors = false
      for (const key in this.internalModel) {
        if (requiredKeys.includes(key) && (this.internalModel[key] == null || this.internalModel[key].length === 0)) {
          hasErrors = true
          this.errors[key] = this.$t('validation.required')
        } else {
          this.errors[key] = null
        }
      }
      if (hasErrors) return
      this.externalModel = createHolder(this.internalModel)
    },
    insert(text) {
      const mapperInserted = this.$refs.templateMapper.insert(text)
      if (!mapperInserted) {
        const lastActiveInput = this.$refs[`input-${this.lastActiveInput}`][0]
        lastActiveInput.insert(text)
      }
    },
    focus() {
      const lastActiveKey = this.lastActiveInput ?? this.children[0].props.key
      const lastActiveInput = this.$refs[`input-${lastActiveKey}`][0]
      lastActiveInput.focus()
    }
  },
  components: {
    BaseNodeComponent,
    TemplateInput,
    TemplateMapper
}
}
</script>