<template>
  <div style="background-color: white;">

    <v-container>
      <v-toolbar class="mb-5" flat>
        <a href="https://www.apptivegrid.de?utm_source=app-apptivegrid&utm_medium=shared-link&utm_campaign=apptive-shared-view" target="_blank" >
          <img class="d-flex self-align-center" width="130px" src="icon_name.png" />
        </a>
      </v-toolbar>
      <v-row>
        <v-col cols="5">
          <v-sheet rounded="lg" class="pa-2">
            <div class="font-weight-medium mb-1">
              <!-- Embed View -->
              {{ $t("forms.shareForm.embed.title") }}
            </div>
            <div class="text-h5 font-weight-bold">
              <!-- Form -->
              {{ isForm == true ? $t("forms.shareForm.embed.subtitle") : 'View' }}
            </div>
            <v-switch v-if="isForm" v-model="resize" flat dense>
              <template v-slot:label>
                <div class="">
                  <div class="font-weight-medium">{{ $t("forms.shareForm.embed.autoresizeTitle") }}</div>
                  <div class="caption">{{ $t("forms.shareForm.embed.autorisizeDescription") }}</div>
                </div>
              </template>
            </v-switch>
          </v-sheet>
        </v-col>
        <v-col cols="7">
          <div class="ma-2 font-weight-medium">
            <v-icon class="mr-2">mdi-code-tags</v-icon>
              <!-- Embed code -->
              {{ $t("forms.shareForm.embed.codePaneHeader") }}
          </div>
          <v-sheet rounded="lg" style="word-wrap: break-word;" class="codePane" color="grey darken-4 pa-4 white--text white--text">
            <v-btn @click="copyCode()" class="copyButton" small dark icon ><v-icon small>mdi-content-copy</v-icon></v-btn>
              <code ref="code">{{tag}}{{demo}}</code>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <div class="ma-2 font-weight-medium">
            <v-icon class="mr-2">mdi-monitor</v-icon>
              <!-- Desktop embed preview -->
              {{ $t("forms.shareForm.embed.previewDesktop") }}

          </div>

          <div :key="reload">
            <iframe :class="iframeCssClass" frameborder="0" onmousewheel="" width="100%" height="550" style="background: transparent; border: 1px solid #ccc;"  :src="url" ></iframe>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="ma-2 font-weight-medium">
            <v-icon class="mr-2">mdi-cellphone</v-icon>
            <!-- Mobile embed preview -->
            {{ $t("forms.shareForm.embed.previewMobile") }}
          </div>
          <div :key="reload">
            <iframe :class="iframeCssClass" frameborder="0" onmousewheel="" width="100%" height="550" style="background: transparent; border: 1px solid #ccc;" :src="url" ></iframe>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    sharedUrl: null,
    isForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      script: null,
      resize: null,
      reload: null,
    }
  },
  watch: {
    resize(newVal) {
      newVal ? this.loadScript() : this.unloadScript()
      this.reload = this.reload + 1
    }
  },
  methods: {
    loadScript() {
      // Create a script tag and add it to the head of the document
      this.script = document.createElement('script')
      this.script.src = window.location.origin + '/apptive-embedded.js'
      document.head.appendChild(this.script)
    },
    unloadScript() {
      // Remove the script tag from the document
      this.script.remove()
    },
    copyCode() {
      try {
        let text = `${this.tag}${this.demo}`
        navigator.clipboard.writeText(text)
      } catch (error) {
        alert('Unable to copy')
      }
    },
  },
  computed: {
    url() {
      return this.sharedUrl + '?embedded=true'
    },
    scriptUrl() {
      return window.location.origin + '/apptive-embedded.js'
    },
    iframeCssClass() {
      return this.resize ? 'apptive-embed apptive-dynamic-height' : 'apptive-embed'
    },
    tag() {
      // When not escaping the / of the closing script tag, Webpack tries to resolve a module
      // Not sure why...
      // eslint-disable-next-line no-useless-escape
      return this.resize ? `<script src="${this.scriptUrl}"><\/script>`: ''
    },
    demo() {
      // eslint-disable-next-line no-use-before-define
      console.log(this.sharedUrl)
      const iframe = `<iframe class="${this.iframeCssClass}" src="${this.url}" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>`
      return this.resize ? iframe : iframe
    }
  }
}
</script>

<style lang="css" scoped>
.codePane {
  position: relative;
}
.copyButton {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
