
function getAddressComponent(place, type) {
  let component = place.address_components.find(component => component.types.includes(type))
  if (component == null) {
    // console.warn(`component not found : ${type}`)
    // console.warn(place)
    return null
  }
  return component.long_name
}

function buildLine(comp1, comp2) {
  if (comp1 == null && comp2 == null) {
    return null
  }
  return [comp1, comp2]
    .filter(item => item != null)
    .join(' ')
}

function buildStreetString(country, street, streetNumber) {
  if (['germany', 'deutschland'].includes(country?.toLowerCase())) {
    return buildLine(street, streetNumber)
  }

  return buildLine(streetNumber, street)
}

export default class AGAddress {
  constructor({
    line1,
    line2,
    city,
    postCode,
    state,
    country,
    geoLocation,
  }) {
    this.line1 = line1
    this.line2 = line2
    this.city = city
    this.postCode = postCode
    this.state = state
    this.country = country
    this.geoLocation = geoLocation
  }

  static fromGooglePlace(place) {
    let street = getAddressComponent(place, 'route')
    let streetNumber = getAddressComponent(place, 'street_number')
    let country = getAddressComponent(place, 'country')
    let line1, line2
    line1 = buildStreetString(country, street, streetNumber)
    if (!place.types?.includes('street_address') && line1 !== place.name) {
      line2 = place.name
    }
    let city = getAddressComponent(place, 'locality')
    let postCode = getAddressComponent(place, 'postal_code')
    let state  = getAddressComponent(place, 'administrative_area_level_1')
    let geoLocation = {
      lat: place.geometry.location.lat(),
      lon: place.geometry.location.lng(),
    }
    return new AGAddress({
      line1,
      line2,
      city,
      postCode,
      state,
      country,
      geoLocation,
    })
  }

  get displayString() {
    let line3 = buildLine(this.postCode, this.city)
    let line4 = buildLine(this.state, this.country)
    return [
      this.line1,
      this.line2,
      line3,
      line4
    ]
    .filter(item => item != null)
    .join(', ')
  }
}