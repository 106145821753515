<template>
  <div
    class="single-condition-filter d-flex align-center"
  >
    <slot></slot>
    <CompactSelect
      :showControls="showControls"
      :disabled="disabled"
      v-model="fieldId"
      class="grid-filter-name-select"
      :items="fieldSelectItems"
      @change="fieldNameChanged"
      :no-data-text="$t('virtualGrid.filter.noAvailableFilter')"
      data-testid="gridFilterFieldSelect"
    >
      <template v-slot:label>
        <div class="text-caption">
          {{$t('virtualGrid.filter.fieldNameLabel')}}
        </div>
      </template>
    </CompactSelect>
    <CompactSelect
      :showControls="showControls"
      v-show="fieldId"
      :disabled="disabled"
      v-model="condition"
      class="grid-filter-condition-select ma-0"
      :items="conditionSelectItems"
      @change="conditionChanged"
      data-testid="gridFilterConditionSelect"
    >
      <template v-slot:label>
        <div class="text-caption">
          {{$t('virtualGrid.filter.conditionSelectLabel')}}
        </div>
      </template>
    </CompactSelect>
    <FilterValue
      v-if="selectedCondition && !selectedCondition.prototype.noArguments"
      class="filter-value"
      :field="field"
      :type="type"
      :disabled="disabled"
      :showControls="showControls"
      :cellValueValid="comparisonRightValid"
      :value="comparisonRight"
      v-on:input="debouncedComparisonRightChanged"
    />
    <v-btn
      :class="{invisible: !showControls}"
      :disabled="disabled"
      @click="removeFilter"
      class="compact-button"
      color="white"
      depressed
      data-testid="gridFilterDeleteButton"
    >
      <v-icon small>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import FilterValue from '@/components/gridView/filter/FilterValue.vue'
import CompactSelect from '@/components/CompactSelect.vue'
import { AGFieldReference, AGNoCondition } from '@/filter/conditions.js'
import { columnTypes } from '@/constants/columnTypes.js'
import debounce from '@/utils/debounce.js'
import { isEqual } from 'lodash'

export default {
  props: {
    virtualGrid: null,
    filter: null,
    disabled: {
      type: Boolean,
      default: () => false
    },
    showControls: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      fieldId: undefined,
      condition: undefined,
      expression: undefined,
      comparisonRight: undefined,
      comparisonRightValid: true,
      debouncedComparisonRightChanged: undefined
    }
  },
  beforeMount() {
    this.debouncedComparisonRightChanged = debounce(function (newVal) {
      this.comparisonRightChanged(newVal)
    }, 200).bind(this)
  },
  computed: {
    field() {
      return this.virtualGrid.allFields().find(field => field.id === this.fieldId)
    },
    availableConditions() {
      return this.field.columnType.filterConditions
    },
    type() {
      if (this.selectedCondition?.prototype.valueType) {
        return columnTypes[this.selectedCondition?.prototype.valueType]
      }
      return this.field?.columnType
    },
    conditionSelectItems() {
      return this.availableConditions.map(condition => this.$t((new condition()).displayString(this.field.columnType)))
    },
    selectedCondition() {
      if (!this.condition) {
        return
      }
      return this.availableConditions[this.conditionSelectItems.indexOf(this.condition)]
    },
    fieldSelectItems() {
      if (!this.virtualGrid) {
        return []
      }
      return this.virtualGrid.allFields()
        .filter(field => field.columnType.filterConditions.length > 0)
        .map(field => {
          return {
            value: field.id,
            text: field.name
          }
        })
    },
    disableTextField() {
      return !this.filter
    }
  },
  watch: {
    selectedCondition() {
      this.filterChanged()
    },
    filter: {
      immediate: true,
      handler(newVal) {
        if (newVal !== undefined && !(newVal instanceof AGNoCondition)) {
          this.fieldId = this.filter.left.fieldId
          this.$nextTick(() => {
            this.condition = this.$t(this.filter.displayString(this.field.columnType))
            this.comparisonRight = this.filter.right
          })
        } else {
          this.fieldId = this.fieldSelectItems[0].value
          this.fieldNameChanged()
        }
      }
    }
  },
  methods: {
    fieldNameChanged() {
      this.$nextTick(() => {
        this.condition = this.conditionSelectItems[0]
        this.comparisonRight = undefined
        this.comparisonRightValid = true
      })
    },
    conditionChanged() {
      this.comparisonRight = undefined
      this.comparisonRightValid = true
    },
    comparisonRightChanged(newVal) {
      this.comparisonRight = newVal
      this.filterChanged()
    },
    removeFilter() {
      this.$emit('removeFilter')
    },
    filterChanged() {
      if (this.fieldId && this.selectedCondition) {
        const newFilter = new this.selectedCondition(new AGFieldReference(this.fieldId), this.comparisonRight)
        if (!this.filter || !isEqual(newFilter.exportObject(), this.filter.exportObject())) {
          if(newFilter.isValid()) {
            this.comparisonRightValid = true
            this.$emit('filterChanged', newFilter)
          } else {
            this.comparisonRightValid = false
          }
        } else {
          this.comparisonRightValid = true
        }
      }
    }
  },
  components: {
    FilterValue,
    CompactSelect
  }
}
</script>

<style lang="scss" scoped>
.composite-operator-field {
  width: 70px;
}
.grid-filter-name-select {
  width: 100px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.grid-filter-condition-select {
  width: 8.5rem;
  border-left-style: none;
  border-radius: 0;
}
.filter-value {
  width: 200px
}

.compact-button {
  height: 32px !important;
  padding: 8px !important;
  min-width: unset !important;
  border: solid thin #d3d2d4 !important;
  margin: 0 !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left-style: none !important;
}

.invisible {
  opacity: 0;
}
</style>
