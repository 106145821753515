import i18n from '@/plugins/i18n'
import validation from '@/utils/inputValidation.js'
import FormFieldStyles from '@/constants/formFieldStyles.js'
import moment from 'moment'
import { DATE_DISPLAY_FORMAT, DATETIME_DISPLAY_FORMAT } from '@/constants/dateFormats.js'
import {darkColor} from '@/utils/color.js'

function missingValue(value) {
  if(value === undefined || value === null) {
    return true
  }

  if(typeof value === 'string' && value === '') {
    return true
  }

  if(Array.isArray(value) && value.length <= 0) {
    return true
  }
  return false
}

export const formFields = {
  data() {
    return {
      rules: {
        required: value =>
          !missingValue(value) || !this.required || i18n.t('validation.required'),
        integer: value =>
          /^-?\d*/.test(value) || this.$t('validation.integer'),
        schema: value =>
          this.errors?.length > 0 && value ? this.errors?.join() : true,
        decimal: value =>
          /^-?(\d+[.,]?\d*|[.,]\d+|\d*)/.test(value) || this.$t('validation.decimal'),
        coordinates: value =>
          missingValue(value) || /^-?\d+(\.\d+)?,\s?-?\d+(\.\d+)?$/.test(value) || this.$t('validation.coordinates'),
        email: v => (v == null || validation.validEmail(v)) || this.$t('validation.emailError'),
        dateTime: v => moment(v, DATETIME_DISPLAY_FORMAT).isValid() || !this.required,
        date: v => moment(v, DATE_DISPLAY_FORMAT).isValid() || !this.required,
        phoneNumber: [
          value => !value || /^[0-9+\s#*]*$/.test(value) || this.$t('validation.invalidPhoneNumber'),
          value => !value || value.startsWith('+') || this.$t('validation.phoneNumberInternationalIndicator'),
        ]
      }
    }
  },
  props: {
    component: null,
    form: null,
    value: null,
    readonly: {
      type: Boolean,
      default: false
    },
    language: null,
    designer: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    fieldStyle() {
      let style = this.form?.fieldStyle || 'default'
      return FormFieldStyles[style]
    },
    fieldId() {
      return this.component.fieldId
    },
    schemaPath() {
      // Assuming that the schema is not nested
      return `#/properties/${this.component?.fieldId}/type`
    },
    validationErrorsInForm() {
      if (this.form == null) {
        return undefined
      }
      return this.form.validationErrors()
    },
    errors() {
      return this.validationErrorsInForm
        ?.filter(error => error.schemaPath === this.schemaPath)
        ?.map(error => error.message)
    },
    description() {
      const i18n = this.form?.fieldProperties?.[this.component?.fieldId]?.i18n
      if (this.form?.i18nFeature && this.language != null && i18n != null) {
        return i18n[this.language]?.description ?? this.component?.options?.description
      }
      return this.component?.options ? this.component.options.description : null
    },
    placeholder() {
      return this.component?.options ? this.component.options.placeholder : null
    },
    schema() {
      return this.form.fields.find(field => field.id === this.fieldId).schema
    },
    field() {
      return this.form.fields.find(field => field.id === this.fieldId)
    },
    fieldType() {
      return this.form.fields.find(field => field.id === this.fieldId).type
    },
    fieldName() {
      return this.form.fields.find(field => field.id === this.fieldId).name
    },
    label() {
        var label = this.component?.property
        if (this.component?.options) {
          label = this.component?.options.label ?? label
        }
        const i18n = this.form?.fieldProperties?.[this.component?.fieldId]?.i18n
        if (this.form?.i18nFeature && this.language != null && i18n != null) {
          label = i18n[this.language]?.label ?? label
        }
        if (this.required) {
          label = label + ' *'
        }
        return label
    },
    color() {
      return this.form?.primaryColor || 'primary'
    },
    cardColor() {
      return this.form.cardColor
    },
    labelColor() {
      // use black or whitle label color based on the primary or button color
      return darkColor(this.form.cardColor) ? 'grey' : 'white'
    },
    buttonColor() {
      return this.form.buttonColor || this.color
    },
    required() {
      if(this.component?.settingMode) return false
      return this.component?.required
    },
    disabled() {
      // to be able to use this field while building forms settingMode overrides disabled behaviour
      if(this.component?.settingMode) return false
      return this.form?.fieldProperties?.[this.fieldId]?.disabled
    },
    hidden() {
      // to be able to use this field while building forms settingMode overrides hidden behaviour
      if(this.component?.settingMode) return false
      return this.form?.fieldProperties?.[this.fieldId]?.hidden
    }
  },
  methods: {
    saveFieldLabel(newValue) {
      // if the form has multiple langanges we need to set the mew field label
      // for the correct language. In this case the label is stored in the fieldproperties
      // not in the component.options.label
      let key = 'label'
      if (this.form?.i18nFeature && this.language != null) {
        // save to i18n 
        this.form.fieldProperties[this.fieldId].i18n = this.form.fieldProperties[this.fieldId].i18n ?? {}
        this.form.fieldProperties[this.fieldId].i18n[this.language] = this.form.fieldProperties[this.fieldId].i18n[this.language] ?? {}
        this.form.fieldProperties[this.fieldId].i18n[this.language][key] = newValue
      }
      else {
        this.component.options.label = newValue || null
      }
      this.form.updateComponents()
    }
  }
}
