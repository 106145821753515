<template>
  <BaseFormInput v-bind="$props">
    <div class="d-flex">
      <v-autocomplete
        v-model="selectedCodeItem"
        class="country-code mr-2"
        :label="this.$t('forms.country')"
        :items="countryDialCodesItems"
        :filter="autocompleteFilter"
        item-text="dial_code"
        lessInnerSpace
        @input="updateInput"
        @change="selectPhoneInput"
        :disabled="disabled"
        v-bind="fieldStyle"
      >
        <template v-slot:item="{item}">
          {{`${item.name} (${item.dial_code})`}}
        </template>
      </v-autocomplete>
      <v-text-field
        ref="phoneInput"
        v-model="phoneNumber"
        :label="fieldStyle.labelAbove ? null : label"
        :rules="[rules.required, rules.schema, rules.phoneNumber[0]]"
        :placeholder="placeholder"
        :hide-details="description || errors ? 'auto' : false"
        :readonly="readonly"
        :color="color"
        type="tel"
        @input="updateInput"
        @paste.prevent="handlePaste"
        :disabled="disabled"
        v-bind="fieldStyle"
      />
    </div>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components/form/BaseFormInput.vue'
import countryDialCodes from '@/constants/countryDialCodes.js'
import { columnTypes } from '@/constants/columnTypes'

export default {
  mixins: [formFields],
  data() {
    return {
      selectedCodeItem: undefined,
      phoneNumber: undefined
    }
  },
  mounted() {
    this.initWith(this.value)
  },
  computed: {
    countryDialCodesItems() {
      return countryDialCodes.map(code => ({
        ...code,
        value: `${code.code} ${code.dial_code}`
      }))
    },
    selectedCode() {
      return this.selectedCodeItem?.split(' ')[1]
    }
  },
  methods: {
    initWith(value) {
      if (!value) {
        this.selectedCodeItem = 'DE +49'
      } else {
        const item = this.findCodeFrom(value)
        if (!item) {
          return
        }
        this.selectedCodeItem = item.value
        this.phoneNumber = value.slice(item.dial_code.length)
      }
    },
    autocompleteFilter(item, queryText) {
      const text = `${item.name} (${item.dial_code})`.toLowerCase()

      return item.header ||
        text.indexOf(queryText.toLowerCase()) > -1
    },
    updateInput() {
      if (!this.selectedCode || !this.phoneNumber) {
        return
      }
      this.$emit('input', this.selectedCode + this.phoneNumber)
    },
    selectPhoneInput() {
      this.$refs.phoneInput.focus()
    },
    findCodeFrom(value) {
      if (!value) {
        return undefined
      }

      for (let i = 3; i <= 5 ; i ++) {
        const code = value.slice(0, i)
        const item = this.countryDialCodesItems.find(item => item.dial_code === code)
        if (item != null) {
          return item
        }
      }
      return undefined
    },
    handlePaste(event) {
      const content = (event.clipboardData || window.clipboardData).getData('text')
      const sanitizedValue = columnTypes.phoneNumber.sanitizedValue(content)
      this.initWith(sanitizedValue)
    }
  },
  components: {
    BaseFormInput
}
}
</script>

<style scoped>
.country-code {
  max-width: 100px;
}
</style>
