<template>
  <BaseFormInput v-bind="$props">
    <v-text-field
      :readonly="readonly"
      v-model="inputModel"
      @blur="finishEditing"
      :rules="[rules.required, rules.schema, rules.date]"
      :label="fieldStyle.labelAbove ? null : label"
      :hide-details="description || errors ? 'auto' : false"
      v-mask="dateMask"
      data-testid="dateFieldText"
      :color="color"
      @paste.prevent="handlePaste"
      v-bind="fieldStyle"
      :disabled="disabled"
    >
      <template v-if="!disabled" v-slot:append>
        <v-dialog
          ref="dialog"
          v-model="modal"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon>
              <v-icon v-on="on" v-bind="attrs">mdi-calendar</v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="pickerModel"
            scrollable
            :readonly="readonly"
            :locale="_i18n.locale"
            :first-day-of-week="1"
            :color="color"
           
          >
            <v-spacer></v-spacer>
            <v-btn text :color="color" @click="modal = false">
              {{ $t('dialogs.cancelButton') }}
            </v-btn>
            <v-btn text :color="color" @click="saveDatePicker">
              {{ $t('dialogs.okButton') }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </template>
    </v-text-field>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import dateFieldMixin from '@/mixins/dateFieldMixin.js'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  mixins: [formFields, dateFieldMixin],
  name: 'FormDateField',
  components: {
    BaseFormInput
  }
}
</script>
