import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'

import de from 'vuetify/lib/locale/de.js'

Vue.use(Vuetify)

const defaultColors = {
  primary: colors.blue.darken3,
  secondary: colors.blue,
  accent: colors.grey.darken2,
  error: colors.red.accent3,
  destructive: colors.red.lighten1,
  background: colors.grey.lighten4,
  space: colors.blue.darken3,
}

let vuetifyColors = { ...defaultColors }

const createVuetifyOpts = (settings) => {
  const themeColors = { ...vuetifyColors }

  if (settings && settings.colors) {
    themeColors.primary = settings.colors.primary
    themeColors.space = settings.colors.space
  } 
  const opts = {
    theme: {
      themes: {
        light: themeColors,
        dark: {
          primary: themeColors.primary,
        },
      },
    },
    breakpoint: {
      mobileBreakpoint: 'xs',
    },
    lang: {
      locales: { de },
      current: navigator.language?.includes('de') ? 'de' : 'en',
    },
  }
  vuetifyColors = themeColors

  return opts
}

export const initializeVuetify = (settings = {}) => {
  const vuetifyOpts = createVuetifyOpts(settings)
  return new Vuetify(vuetifyOpts)
}

export { vuetifyColors }



