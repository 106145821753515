/**
 * Register the download component by inserting the download icon
 */
export const registerDownloadComponent = (item, el, labelButtonDownload, labelButtonRename, allowDownloadByUrl, allowRename) => {
  const downloadIcon = getDownloadIcon(labelButtonDownload)
  const renameIcon = getRenameIcon(labelButtonRename)
  
  el.prepend(downloadIcon)
  
  downloadIcon.addEventListener('click', (e) => {
    downloadFile(item, allowDownloadByUrl)
    e.stopPropagation()
  })
  if (allowRename) {
    el.prepend(renameIcon)
    renameIcon.addEventListener('click', (e) => {
      const event = new CustomEvent('FilePond:rename', {detail: item})
      const wrapper = el.closest('.filepond--wrapper')
      wrapper.dispatchEvent(event)
      e.stopPropagation()
    })
  }
}

/**
 * Generates the download icon
 */
export const getDownloadIcon = (labelButtonDownload) => {
  let icon = document.createElement('span')
  icon.className = 'filepond--download-icon'
  icon.title = labelButtonDownload
  return icon
}

/**
 * Generates the rename icon
 */
export const getRenameIcon = (labelButtonRename) => {
  let icon = document.createElement('span')
  icon.className = 'filepond--rename-icon'
  icon.title = labelButtonRename
  return icon
}

/**
 * Triggers the actual download of the uploaded file
 */
export const downloadFile = (item, allowDownloadByUrl) => {
  // if client want to download file from remote server
  if (allowDownloadByUrl && item.getMetadata('url')) {
    location.href = item.getMetadata('url') // full path to remote server is stored in metadata with key 'url'
  } else {
    // create a temporary hyperlink to force the browser to download the file
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(item.file)
    document.body.appendChild(a)
    a.style.display = 'none'
    a.href = url
    a.download = item.file.name
    a.click()

    window.URL.revokeObjectURL(url)
    a.remove()
  }
}
