<template>
  <div class="print-area position-relative background-color" ref="card">
      <v-row no-gutters v-for="(item, i) in items" :key="i" class="mt-2" :class="!$vuetify.breakpoint.mobile ? 'mx-8' : ''">
        <v-col
          cols="12"
          sm="4"
          class=" mb-3"
          >
          <div
            class="d-flex align-start mr-2"
            data-testid="editEntityFieldHeader"
            >
          <v-icon class="mt-1" small>{{item.field.columnType.typeIcon}}</v-icon>
          <div
            class="ml-2 textOverflow text-subtitle-2 grey--text text--darken-2"
            data-testid="editEntityFieldName"
          >{{item.field.name}}</div>
            </div>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          class=" mb-4"
        >
        <div
            v-if="isTextCell(item)"
            v-text="item.value"
          />
        <GridCellFactory
          v-else-if="!gridRef(item)"
          :readonly="true"
          class="field-cell pa-2"
          :class="{'fit-content': !isTextCell(item)}"
          solo
          roundedBorders
          :editEntityMode="true"
          :value="item.value"
          :displayValue="displayValues[i]"
          :field="item.field"
          :type="item.field.columnType"
          />
          <EntityPicker
            v-else-if="gridRef(item)"
            :gridUri="gridRef(item)"
            :value="item.value"
            :fieldName="item.field.name"
            :isCollection="item.field.type.jsonSchema.items != null"
            :readonly="true"
          />
        </v-col>
      </v-row>
    </div>
</template>

<script>
import GridCellFactory from '@/components/gridView/GridCellFactory.vue'
import EntityPicker from '@/components/gridView/EntityPicker.vue'
import { columnTypes } from '@/constants/columnTypes.js'

export default {
  props: {
    virtualGridUri: null,
    entityUri: null
  },
  data() {
    return {
      virtualGrid: undefined,
      entity: undefined
    }
  },
  async mounted() {
    this.virtualGrid = await this.$store.dispatch('AGReadVirtualGridOperation', {virtualGridUri: this.virtualGridUri, loadEntities: false})
    this.entity = await this.$store.dispatch('AGFetchEntityOperation', this.entityUri)
    if (this.entity != null && this.virtualGrid != null) {
      await this.$nextTick()
      // wait for all images to be loaded
      const allImagesPromise = Array.from(document.images)
        .filter(img => !img.complete)
        .map(img => new Promise(resolve => {
          img.addEventListener('load', resolve)
          img.addEventListener('error', resolve)
        }))
      await Promise.all(allImagesPromise)
      window.print()
    }
  },
  computed: {
    items() {
      if (!this.virtualGrid || !this.entity) {
        return []
      }
      return this.virtualGrid.fields.map((field, index) => {
        return {
          field,
          value: this.entity.fields[index]
        }
      })
    },
    displayValues() {
      return this.virtualGrid.fields.map((field, index) => {
        const entityValue = this.entity.fields[index]
        return field.columnType.displayFormat
          ? field.columnType.displayFormat(entityValue)
          : entityValue
      })
    },
  },
  methods: {
    isTextCell(item) {
      return item.field.columnType === columnTypes.string
    },
    gridRef(item) {
      return item.field.type.gridUri
    },
  },
  components: {
    GridCellFactory,
    EntityPicker
  }
}
</script>

<style scoped>
.background-color {
  background-color: #FFFFFF;
}
</style>