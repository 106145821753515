<template>
  <div v-if="block" class="block-page-root" data-testid="blockPage">
    <LoadingOverlay :show="loading" />
    <v-img
      v-if="headerImageUrl"
      :src="headerImageUrl"
      class="headerImage"
    />
    <v-container
      v-if="block != null"
    >
      <!-- Grid system with a single row and centered content -->
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          md="9"
          lg="8"
        >
       
          <div
            class=""
            v-for="(child, i) in block.children"
            :key="i"
          >
            <div class="col-12">

              <component
                :ref="`block_${child.id}`"
                :is="component(child)"
                :blockUri="child.uri"
                :editorMode="false"
              />
              <!-- Conditionally display the block picker after the block if it's the chosen index -->
            </div>
          
          </div>
        
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import CellInputBlock from '@/components/block/CellInputBlock.vue'
import ParagraphBlock from '@/store/models/blocks/ParagraphBlock'
import ParagraphBlockVue from '@/components/block/ParagraphBlock.vue'
import HeaderBlock from '@/store/models/blocks/HeaderBlock'
import HeaderBlockVue from '@/components/block/HeaderBlock.vue'
import PageBlockVue from '@/components/block/PageBlockVue.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import PageBlock from '@/store/models/blocks/PageBlock'
import EmbedBlock from '@/store/models/blocks/EmbedBlock'
import EmbedBlockVue from '@/components/block/EmbedBlock.vue'
import VideoBlock from '@/store/models/blocks/VideoBlock'
import VideoBlockVue from '@/components/block/VideoBlock.vue'

export default {
  props: {
    block: {
      type: PageBlock
    },
  },
  data() {
    return {
      loading: false,
      PageBlock
    }
  },
  computed: {
    headerImageUrl() {
      return this.block?.headerImageUrl
    },
    title() {
      return this.block?.name || this.$t('blocks.emptyPageTitle')
    },
  },
  watch: {
    title: {
      immediate: true,
         handler(newVal) {
          document.title = newVal
        }
    }
  },
  provide() {
    return {
      canAddChild: () => false
    }
  },
  methods: {
    component(block) {
      if (block instanceof ParagraphBlock) {
        return ParagraphBlockVue
      } else if (block instanceof HeaderBlock) {
        return HeaderBlockVue
      } else if (block instanceof EmbedBlock) {
        return EmbedBlockVue
      }else if (block instanceof PageBlock) {
        return PageBlockVue
      } else if (block instanceof VideoBlock) {
        return VideoBlockVue
      }

      return CellInputBlock
    },
    focus(block) {
      this.$refs[ `block_${block.id}` ][ 0 ].focus?.()
    },
  },
  components: {
    CellInputBlock,
    LoadingOverlay,
  }
}
</script>

<style scoped>
.block-page-root {
  width: 100%;
  background: white;
  position: inherit;
  overflow: auto;
}

.headerImage {
  object-fit: cover;
  max-height: 240px;
  min-height: 220px;
}

</style>