import { columnTypes } from './columnTypes'

export default () => ({
  ASBoolean: [
    columnTypes.boolean
  ],
  ASNumber: [
    columnTypes.integer,
    columnTypes.decimal,
    columnTypes.currency,
  ],
  ASDecimal: [
    columnTypes.decimal,
    columnTypes.currency,
  ],
  ASInteger: [
    columnTypes.integer,
    columnTypes.decimal,
    columnTypes.currency,
  ],
  ASString: [
    columnTypes.string,
  ],
  ASDate: [
    columnTypes.date,
  ],
  ASDateAndTime: [
    columnTypes.dateTime
  ]
})