<template>
  <div class="flex-column pa-3 not-displayed">
    <div v-if="!disabled">{{ $t("virtualGrid.resourceOptionsTitle") }}</div>
    <v-select
      :disabled="disabled"
      v-model="externalModel"
      :items="items"
      :label="$t('columnTypes.resource')"
      multiple
      data-testid="resourceTypeSelect"
    />
  </div>
</template>


<script>
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [externalModel],
  props: {
    value: null,
    disabled: null,
  },
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    if (this.value == null) {
      this.selectModel = this.items[0].value
    }
  },
  computed: {
    items() {
      return [
        'view',
        'grid',
        'space',
        'form',
        'block',
      ].map(item => ({
        text: this.$t(`resourceTypes.${item}`),
        value: item
      }))
    }
  }
}
</script>

<style scoped>
.not-displayed {
  display: none;
}
</style>
