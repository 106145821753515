<template>
  <!-- pages here -->

  <v-expansion-panels v-model="pagePanel" flat>

      <v-expansion-panel
      :data-testid="
        `form-builder-page_${pageIndex}`
      "
       :key="pageIndex" v-for="(page, pageIndex) in form.pages">
        <v-sheet rounded class="mr-2 ml-2 mt-2 border" color="white">

        <v-expansion-panel-header @click="selectPage(pageIndex)" class="pa-4 text-subtitle-2">
          {{$t('forms.builder.page') }} {{ pageIndex +1 }}
          <template v-slot:actions>
            <v-icon>
              mdi-menu-down
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content ref="pageContent" >
        <draggable
          :animation="200"
          ghost-class="ghost-card"
          handle=".handle"
          :disabled="isEditingField"
          @end="(event) => onEnd(event, page)"
        >
          <template v-for="(component, fieldIndex) in fieldComponentsInForm(pageIndex)">
            <v-list-item class="component-item mb-3" v-if="component.type === 'textBlock'" :key="component.id">
              <v-icon class="mr-3 handle" small>mdi-drag-vertical</v-icon>

              <v-icon class="mr-3" small>mdi-format-text</v-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-subtitle-2 grey--text text--darken-2"
                  >{{ $t('forms.builder.textBlock') }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  small
                  :disabled="isEditingField"
                  @click="openTextBlockDetails(component)"
                  icon
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  small
                  :disabled="isEditingField"
                  :data-testid="
                    `builder-field-remove-button:${component.property}`
                  "
                  @click="removeField(component, page)"
                  icon
                >
                  <v-icon color="primary" small>mdi-delete-outline</v-icon>
                </v-btn>
              </v-list-item-action>


            </v-list-item>


            <v-list-item v-else class="component-item mb-3" :key="component.fieldId">
              <v-icon class="mr-3 handle" small>mdi-drag-vertical</v-icon>

              <v-icon class="mr-3" small>{{
                iconFor(component.fieldId)
              }}</v-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-subtitle-2 grey--text text--darken-2"
                  >{{ component.property }}</v-list-item-title
                >

              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  small
                  :disabled="isEditingField"
                  :data-testid="
                    `builder-field-open-details-button:${component.property}`
                  "
                  @click="openFieldComponentDetails(component)"
                  icon
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  small
                  :disabled="isEditingField"
                  :data-testid="
                    `builder-field-remove-button:${component.property}`
                  "
                  @click="removeField(component, page)"
                  icon
                >
                  <v-icon color="primary">mdi-toggle-switch</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-expand-transition :key="`details-${fieldIndex}`">
            <FormFieldDetails
              v-if="selectedComponent === component"
              :component="selectedComponent"
              :form="form"
              :spaceUri="spaceUri"
              :grid="grid"
              v-on:finishComponentDetails="finishComponentDetails"
            />

            <TextBlockDetails
              v-if="selectedTextBlock === component"
              :component="selectedTextBlock"
              v-on:finishTextBlockDetails="finishTextBlockDetails"
            />
          </v-expand-transition>
          </template>
        </draggable>

        <v-list-item
          class="pt-4"
          dense
          v-if="
            remainingFieldComponents && remainingFieldComponents.length > 0
          "
        >
          <v-list-item-content>
            <v-list-item-title>{{
              $t('forms.builder.remainingFields')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-for="component in remainingFieldComponents">
          <v-list-item

            class=""
            @click="addField(component, page)"
            :data-testid="`form-builder-add-${component.property}-page_${pageIndex}`"
            :key="component.fieldId"
          >
            <v-icon class="mr-3" small>{{
              iconFor(component.fieldId)
            }}</v-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text-subtitle-2 grey--text text--darken-2"
                >{{ component.property }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-toggle-switch-off-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
        <CreateColumnDialog
          :virtualGrid="grid"
          @reload="reload"
          createOnSave
          :index="pageIndex"
        />
        <v-list-item @click="() => addTextBlock(page)">
          <v-icon small class="mr-3" color="primary">mdi-plus</v-icon>
          <v-list-item-title class="text-subtitle-2 primary--text">{{$t('forms.builder.createTextBlockButton')}}</v-list-item-title>
        </v-list-item>
        <v-btn v-if="form.pages.indexOf(page) > 0" color="red" text @click="deletePage(page)">{{$t('forms.builder.deletePageButtonTitle')}}</v-btn>

      </v-expansion-panel-content>
    </v-sheet>

    </v-expansion-panel>


    <v-btn text class="ma-2 accent--text" @click="addPage" data-testid="add-page-button"><v-icon class="mr-1" small>mdi-plus</v-icon>{{$t('forms.builder.addPageButton') }}</v-btn>

      <v-expansion-panel @click="selectSuccessPage">
        <v-sheet rounded class="mr-2 ml-2 mt-2 border"  color="white">

        <v-expansion-panel-header class="pa-4 text-subtitle-2">
          {{$t('forms.builder.titleSuccesPageHeader') }}
          <template v-slot:actions>
            <v-icon>
              mdi-menu-down
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SuccessPage :spaceUri="spaceUri" :formUri="formUri"/>

        </v-expansion-panel-content>
      </v-sheet>
      </v-expansion-panel>

  </v-expansion-panels>
</template>

<script>

import CreateColumnDialog from '@/components/gridView/CreateColumnDialog.vue'
import FormFieldDetails from '@/components/form/formbuilder/FormFieldDetails.vue'
import TextBlockDetails from '@/components/form/formbuilder/TextBlockDetails.vue'
import formPageBus from '@/utils/formPageBus.js'
import SuccessPage from '@/components/form/formbuilder/SuccessPage.vue'
import draggable from 'vuedraggable'

export default {
  props: {
    spaceUri: null,
    gridUri: null,
    formUri: null
  },
  data() {
    return {
      pagePanel: 0,
      selectedComponent: null,
      selectedTextBlock: null,
    }
  },
  watch: {
    form(newVal) {
      this.selectedComponent = newVal.components.find(component => component.fieldId === this.selectedComponent?.fieldId)
    }
  },
  computed: {
    form() {
      return this.$store.getters.formWithUri(this.formUri)
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    remainingFieldComponents() {
      return this.grid.remainingFieldComponentsInForm(this.form)
    },
    isEditingField(){
      return (this.selectedComponent || this.selectedTextBlock) !== null
    },
  },
  methods: {
    saveForm() {
      this.form.updateComponents()
    },
    reload() {
      this.form.reload()
    },
    onEnd(event, page) {
      page.items.splice(event.newIndex, 0, page.items.splice(event.oldIndex, 1)[0])
      this.form.componentMovedOnPage()
    },
    fieldComponentsInForm(page) {
      return this.form.componentsOnVisiblePage(page)
    },
    addField(aComponent, page) {
      this.$emit('addField', {aComponent, page})
    },
    removeField(aComponent, page) {
      this.$emit('removeField', {aComponent, page})
    },
    openFieldComponentDetails(aComponent) {
      this.selectedComponent = aComponent
    },
    openTextBlockDetails(aComponent) {
      this.selectedTextBlock = aComponent
    },
    formContainsFieldComponent(component) {
      return this.form.components.find(
        comp => component.fieldId === comp.fieldId
      )
    },
    finishComponentDetails() {
      this.selectedComponent = null
      this.saveForm()
    },
    finishTextBlockDetails() {
      this.selectedTextBlock = null
      this.saveForm()
    },
    async addPage(){
      await this.form.addPage()
      let index = this.form.pages.length -1
      this.selectPage(index)
      this.panel = [this.form.pages.length]
    },
    async deletePage(page) {
      let index = this.form.pages.indexOf(page)
      await this.form.removePage(page)
      this.selectPage(index -1)
      this.panel = [index]
    },
    selectPage(index){
      formPageBus.requestPage(index)
    },
    selectSuccessPage() {
      formPageBus.requestSuccessPage()
    },
    addTextBlock(page) {
      this.form.addTextBlock(page)
    },
    iconFor(fieldId) {
      return this.grid.iconForField(fieldId)
    },
  },
  components: {
    CreateColumnDialog,
    draggable,
    FormFieldDetails,
    TextBlockDetails,
    SuccessPage,
  }
}
</script>

<style lang="css" scoped>
.handle {
  cursor: move;
}
.component-item {
  background: #f7f7f7;
}
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
.border {
  border: #e0e0e0 1px solid !important;
}
</style>
