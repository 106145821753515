<template>
  <div
  class="d-flex align-center relative"
  :class="{'control-padding': showControls}"
  @mouseenter="showControls = true"
  @mouseleave="showControls = false"
>
  <input
    ref="input"
    step="any"
    class="px-2 grey--text text--darken-3"
    :value="modelDisplay"
    readonly
    @keyup.exact.enter="nextLine"
    @focus="$emit('focus')"
    @select="$emit('focus')"
    @blur="$emit('blur')"
  />
  <v-menu
    ref="menu"
    v-model="menu"
    offset-y
    left
    :close-on-content-click="false"
    content-class="picker-menu"
  >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="control-button mx-1"
          :class="{invisible: !showControls}"
          v-bind="attrs"
          v-on="on"
          icon
          x-small
          color="accent"
          data-testid="addressPickerActivator"
        >
          <v-icon small>mdi-map-marker</v-icon>
        </v-btn>
    </template>
    <v-card class="pa-2">
      <AddressPicker
      :readonly="readonly"
      :value="value"
      :fullScreenMenu="$vuetify.breakpoint.mobile"
      @input="addressPicked"
    />      
    </v-card>

  </v-menu>
</div>
</template>

<script>
import AddressPicker from '@/components/AddressPicker.vue'
import externalModel from '@/mixins/externalModel'
import { gridCells } from '@/mixins/gridCells'
import AGAddress from '@/store/models/AGAddress'
import { isEqual } from 'lodash'

  export default {
    mixins: [gridCells, externalModel],
    data() {
      return {
        showControls: false,
        menu: false
      }
    },
    methods: {
      addressPicked(value) {
        if (isEqual(value, this.externalModel)) {
          return
        }
        this.externalModel = value
        this.$emit('blur')
      }
    },
    computed: {
      modelDisplay() {
        if (this.value == null) {
          return null
        }
        return (new AGAddress(this.externalModel)).displayString
      }
    },
    components: { AddressPicker }
  }
</script>

<style lang="scss" scoped>
.picker-menu {
  width: clamp(200px, 400px, 100%);
}

.relative {
  position: relative
}

.control-button {
  position: absolute;
  right: 0;
}

.control-padding {
  padding-right: 32px;
}
.invisible {
  opacity: 0;
}
</style>