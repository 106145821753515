<template>
  <div
    v-if="!editMode"
    class="text-div d-flex"
    :class="computedTextClasses"
    v-bind="$attrs"
    @[event]="toEditMode"
  >{{ textDisplay }} 
      <v-icon 
        v-if="showPencil"
        @click="toEditMode" 
        class="ml-2" dense>
          mdi-pencil-outline
      </v-icon>
  </div>
  
  <div v-else>
    <v-menu
      :value="error"
      bottom offset-y
      right offset-x
      nudge-left="10px"
      :close-on-click="false"
      :close-on-content-click="false"
      min-width="0"
      transition="none"
      content-class="counter-menu"
    >
      <template v-slot:activator="{}">
        <input
          v-if="!multiline"
          ref="input"
          autofocus
          class="full-width editable-text-input"
          :class="textClass"
          :style="customOutline"
          v-model="inputModel"
          @keyup.enter="blur"
          @blur="submitModel"
          :placeholder="placeholder"
        />
        <textarea
          v-else
          ref="input"
          autofocus
          class="full-width editable-textarea-input autogrow-textarea"
          :class="textClass"
          :style="customOutline"
          v-model="inputModel"
          rows="1"
          @input="autoResize"
          @focus="autoResize"
          :placeholder="placeholder"
          
        />
        <div v-if="multiline" class="actions">
          <v-btn @click="cancel" icon color="accent"> 
            <v-icon>mdi-close</v-icon> 
          </v-btn>
          <v-btn @click="submitModel" icon color="accent"> 
            <v-icon>mdi-check</v-icon> 
          </v-btn>
        </div>
      </template>
     <div class="counter pa-1" :class="counterClass">{{error}}</div>
    </v-menu>
  </div>
</template>

<script>
import nameLengths from '@/constants/nameLengths.js'

export default {
  props: { 
    text: null,
    placeholder: null,
    multiline: {
      type: Boolean,
      default: false
    },
    singleClick: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    textClass: null,
    readonly: null,
    maxLength: {
      type: Number,
      default: () => nameLengths.default
    },
    showPencil: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editMode: false,
      inputModel: this.text,
      initalValue: null
    }
  },
  watch: {
    text(newVal) {
      this.inputModel = newVal
      this.initalValue = newVal
    }
  },
  computed: {
    textDisplay() {
      if (this.readonly) {
        return this.text
      }
      return this.text || this.placeholder || this.$t('editableTexPlaceholder')
    },
    computedTextClasses() {
      let classes = this.textClass ?? ''

      if (!this.readonly) {
        if (!this.singleClick) {
          classes += ' pointer'
        }

        if (this.multiline) {
          classes += ' hover multiLines'
        } else {
          classes += ' single-line'
        }

        if (!this.text) {
          classes += ' editable-textarea-input'
        }
      } else {
        classes += this.multiline ? ' multiLines' : ' single-line'
      }
      return classes.trim()
    },
    counterText() {
      return `${this.inputModel?.length ?? 0} / ${this.maxLength}`
    },
    counterClass() {
      return [
        this.error ? 'error--text' : 'primary--text'
      ]
    },
    customOutline() {
      return {
        '--outline-color': !this.error ? this.$vuetify.theme.themes.light.primary : this.$vuetify.theme.themes.light.error
      }
    },
    error() {
      if (this.required && (!this.inputModel || this.inputModel.length <= 0)) {
        return this.$t('validation.notEmpty')
      }
      if (this.inputModel?.length > this.maxLength) {
        return `${this.inputModel.length} / ${this.maxLength}`
      }
      return undefined
    },
    event() {
      return this.singleClick ? 'click' : 'dblclick'
    }
  },
  methods: {
    toEditMode() {
      if(this.readonly) {
        return
      }
      this.editMode = true
      this.$nextTick().then(() => {
        this.$refs.input.focus()
        this.$refs.input.select()
      })
    },
    blur() {
      if (!this.error) {
        this.$refs.input.blur()
      }
    },
    submitModel() {
      if (!this.error) {
        this.$emit('submit', this.inputModel)
      }
      this.editMode = false
      this.inputModel = this.text
    },
    cancel() {
      this.editMode = false
    },
    autoResize(event) {
      const textarea = event.target
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
   }
  }
}
</script>

<style scoped>
.text-div {
  min-height: 1em;
}

.pointer {
  cursor: pointer;
}


.editable-text-input {
  width: 100%;
  outline: none;
  color: inherit;
}

.editable-text-input:focus {
  border: none;
  border-radius: 2pt;
  outline: none;
  box-shadow: 0 0 3pt 2pt var(--outline-color);
}

.hover:hover {
  background-color: rgb(235, 235, 235);
  border-radius: 2pt;
}

.editable-textarea-input {
  background-color: rgb(235, 235, 235);
  border-radius: 2pt;
}

.counter {
  font-size: 14px;
  background: white;
}

.multiLines {
  white-space: pre-line;
  word-break: break-word;
}

.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.relative {
  position: relative;
  max-width: 100%;
  display: flex;
  overflow: visible;
}

.counter-menu {
  margin-top: 4px;
}
.autogrow-textarea {
    width: 100%;
    resize: none;
    overflow: hidden;
    font-family: inherit;
  }
</style>