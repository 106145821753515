<template>
<div class="single-criteria-sorting d-flex align-center">
    <CompactSelect
      :disabled="disabled"
      v-model="fieldId"
      class="sorting-field-select"
      :items="fieldSelectItems"
      @change="fieldNameChanged"
      data-testid="sortingFieldSelect"
    >
      <template v-slot:label>
        <div class="text-caption">
          {{$t('virtualGrid.filter.fieldNameLabel')}}
        </div>
      </template>
    </CompactSelect>
    <CompactSelect
      v-if="functionItems.length > 0"
      v-model="selectedFunction"
      :disabled="disabled"
      class="px-1 sorting-function-select"
      data-testid="sortingFunctionSelect"
      :items="functionItems"
      @change="fieldNameChanged"
    >
      <template v-slot:label>
        <div class="text-caption">
          {{$t('virtualGrid.filter.functionLabel')}}
        </div>
      </template>
    </CompactSelect>
    <GridCellFactory
      v-for="(argument, index) in functionArguments"
      :key="index"
      :readonly="disabled"
      class="grid-filter-value-cell"
      :solo="true"
      :value="functionValue"
      :type="argument.type()"
      v-on:input="functionValueChanged"
      data-testid="sortingValueCell"
    />
    <v-btn
      :disabled="disabled"
      class="sorting-criteria-button"
      @click="changeOrder"
      icon
      data-testid="sortingOrderToggle"
    >
      <v-icon size="20">{{orderIcon}}</v-icon>
    </v-btn>
    <v-btn
      class="sorting-criteria-button"
      :disabled="disabled"
      @click="$emit('delete')"
      icon
      data-testid="gridSortingDeleteButton"
    >
      <v-icon small>mdi-close</v-icon>
    </v-btn>
</div>
</template>

<script>
import CompactSelect from '@/components/CompactSelect.vue'
import GridCellFactory from '@/components/gridView/GridCellFactory.vue'
import typeValidation from '@/utils/typeValidation.js'
import {parseSortingCriteria} from '@/sorting/sorting.js'
import {noValue} from '@/utils/jsUtils.js'
import { isEqual } from 'lodash'

export default {
  props: ['virtualGrid', 'sorting', 'disabled'],
  data() {
    return {
      fieldId: undefined,
      selectedFunction: undefined,
      functionValue: undefined,
      order: 'ascending'
    }
  },
  computed: {
    sortedColumnIds() {
      return this.virtualGrid?.sortedColumnIds() ?? []
    },
    fieldSelectItems() {
      if (!this.virtualGrid) {
        return []
      }
      return this.virtualGrid.allFields()
        .filter(field => field.id === this.fieldId || !this.sortedColumnIds.includes(field.id))
        .filter(field => !field.columnType.disableSorting)
        .map(field => {
          return {
            value: field.id,
            text: field.name
          }
        })
    },
    field() {
      if (!this.fieldId) {
        return
      }
      return this.virtualGrid.allFields().find(field => field.id == this.fieldId)
    },
    availableSortingFunctions() {
      return this.field?.columnType.sortFunctions ?? []
    },
    functionItems() {
      return this.availableSortingFunctions.map(sortingFunction => this.$t(sortingFunction.displayString))
    },
    selectedSortingFunction() {
      if (!this.selectedFunction) {
        return undefined
      }
      return this.availableSortingFunctions[this.functionItems.indexOf(this.selectedFunction)]
    },
    functionArguments() {
      return this.selectedSortingFunction?.args ?? []
    },
    orders() {
      return [
        {text: this.$t('sorting.ascending'), value: 'ascending', icon: 'mdi-sort-alphabetical-ascending'},
        {text: this.$t('sorting.descending'), value: 'descending', icon: 'mdi-sort-alphabetical-descending'}
      ]
    },
    orderIcon() {
      const order = this.orders.find(order => order.value === this.order)
      return order?.icon ?? ''
    }
  },
  watch: {
    sorting: {
      immediate: true,
      handler(newVal) {
        if (typeof newVal === 'object' && Object.entries(newVal).length > 0) {
          const sortingCriteria = parseSortingCriteria(newVal)
          this.fieldId = sortingCriteria.fieldId
          this.selectedFunction = this.$t(sortingCriteria.sortingFunction?.displayString)
          this.functionValue = sortingCriteria.sortingFunction?.args[0].value
          this.order = sortingCriteria.order
        } else {
          this.fieldId = undefined
          this.selectedFunction = undefined
          this.functionValue = undefined
          this.order = 'ascending'
        }
      }
    }
  },
  methods: {
    fieldNameChanged() {
      this.$nextTick().then(() => {
        if (this.functionItems.length > 0) {
          this.selectedFunction = this.functionItems[0]
        } else {
          this.selectedFunction = undefined
        }
        this.functionValue = undefined
        if (this.order && this.fieldId) {
          this.sortingChanged()
        }
      })
    },
    sortingChanged() {
      if(!this.fieldId || !this.order) {
        return
      }
      let newSorting = {[this.fieldId] : this.order}
      if (!this.selectedFunction && noValue(this.functionValue)) {
        if (!isEqual(newSorting, this.sorting)) {
          this.$emit('sortingChanged', newSorting)
        }
      } else if (this.selectedFunction) {
        const functionArguments = {}
        this.selectedSortingFunction.args.forEach(arg => {
          functionArguments[arg.name] = this.functionValue
        })
        const sortingFunction = new this.selectedSortingFunction(functionArguments)
        newSorting = {[this.fieldId] : {
          $order: this.order,
          ...sortingFunction.exportFormat()
        }}
        if (!noValue(this.functionValue)) {
          if (!isEqual(newSorting, this.sorting)) {
            this.$emit('sortingChanged', newSorting)
          }
        }
      }
    },
    changeOrder() {
      const orderIndex = this.orders.findIndex(order => order.value === this.order)
      const newIndex = (orderIndex + 1) % this.orders.length
      this.order = this.orders[newIndex].value
      this.sortingChanged()
    },
    functionValueChanged(newVal) {
      const type = this.field.columnType
      const sanitizedValue = typeValidation.sanitizedEntityValue(type, newVal)
      this.functionValue = sanitizedValue
      this.sortingChanged()
    }
  },
  components: {
    CompactSelect,
    GridCellFactory
  }
}
</script>

<style lang="scss" scoped>
.sorting-field-select {
  width: 100px;
}

.sorting-function-select {
  width: 8.5rem;
}

.grid-filter-value-cell {
  width: 140px;
  height: 32px;
}

.sorting-criteria-button {
  border: solid thin #d3d2d4;
  height: 32px;
  width: 32px;
}

.single-criteria-sorting > * {
  border-radius: 0;
  border-left-style: none;
}

.single-criteria-sorting > *:first-child {
  border-radius: 0;
}

.single-criteria-sorting > *:first-child{
  border-left-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.single-criteria-sorting > *:last-child{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>