import ApiClient from '@/ApiClient'
import Vue from 'vue'
import Share from '@/store/models/Share.js'

export default {
  state() {
    return {
      shares: {}
    }
  },
  mutations: {
    addShare(state, share) {
      Vue.set(state.shares, share.getLink('self'), share)
    },
    removeShare(state, share) {
      delete state.shares[share]
    }
  },
  actions: {
    AGReadShareOperation(context, shareUri) {
      return ApiClient.getUri(shareUri).then(response => {
        const share = new Share(response.data)
        context.commit('addShare', share)
        return share
      })
    },
    AGDeleteShareOperation(context, {spaceUri, share}) {
      const link = share.getLink('remove')
      return ApiClient.deleteUri(link).then(() => {
        return context.dispatch('loadShareUris', spaceUri)
      })
    },
    AGShareSpaceOperation(context, { spaceUri, email, role }) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink('addShare')
      return ApiClient.postUri(link, { email, role })
        .then(() => {
          return context.dispatch('loadShareUris', spaceUri)
        })
    },
    AGUpdateShareOperation(context, {share, role}) {
      const link = share.getLink('update')
      return ApiClient.putUri(link, {
        role
      }).then(() => {
        share.role = role
      })
    }
  },
  getters: {
    shareWithUri: state => uri => {
      return state.shares[uri]
    },
    fullSharesOfSpace: (state, getters) => spaceUri => {
      const shares = getters.sharesOfSpace(spaceUri)
      return shares
        .map(share => state.shares[share._links.self.href] ?? share)
        .filter(share => share !== undefined)
    }
  }
}
