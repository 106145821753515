import store from '../store'
import StatefulView from './StatefulView'
import { columnTypes } from '@/constants/columnTypes.js'
import { parseFilter } from '@/filter/filter.js'

// Version 1 :
const START_FIELD_ID = 'startFieldId'
const END_FIELD_ID = 'endFieldId'
// Version 2 :
const IS_START_FIELD = 'isStartField'
const IS_END_FIELD = 'isEndField'

export default class CalendarView extends StatefulView {
  constructor(data) {
    super(data)
    this.migrate()
  }

  migrate() {
    if (this.version === 1) {
      const startFieldId = this.properties[START_FIELD_ID]
      this.setFieldProperty(startFieldId, IS_START_FIELD, true)

      const endFieldId = this.properties[END_FIELD_ID]
      this.setFieldProperty(endFieldId, IS_END_FIELD, true)

      this.version = 2
      store().dispatch('AGPatchStatefulViewOperation', {
        statefulView: this,
        properties: this.properties,
        slotProperties: this.slotProperties
      })
    }
  }

  isOfAcceptedType(field) {
    return field.hasOneOfTypes([columnTypes.date, columnTypes.dateTime])
  }

  get startFieldId() {
    return this.getFieldProperty(IS_START_FIELD)
  }

  get endFieldId() {
    return this.getFieldProperty(IS_END_FIELD)
  }

  reload() {
    return store().dispatch('AGReadStatefulViewOperation', this.uri)
  }

  async setStates(startFieldId, endFieldId) {
    if (this.parentGrid.hiddenFields.some(field => [startFieldId, endFieldId].includes(field.id))) {
      const parentFieldIds = this.parentGrid.parentGrid().fields.map(field => field.id)
      const fieldIds = this.parentGrid.fields.map(field => field.id)
      const newFieldIds = parentFieldIds.filter(id => [startFieldId, endFieldId].includes(id) || fieldIds.includes(id))
      await this.parentGrid.updateShownFields(newFieldIds)
    }

    this.deleteFieldProperty(this.startFieldId, IS_START_FIELD)
    this.setFieldProperty(startFieldId, IS_START_FIELD, true)

    this.deleteFieldProperty(this.endFieldId, IS_END_FIELD)
    this.setFieldProperty(endFieldId, IS_END_FIELD, true)

    return store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      slotProperties: this.slotProperties
    })
      .then(() => this.reload())
  }


  calendarModel() {
    if (this.startFieldId) {
      const startFieldIndex = this.parentGrid.fields.findIndex(field => field.id === this.startFieldId)
      const startField = this.parentGrid.fields[startFieldIndex]

      const hasTime = startField.columnType.name === columnTypes.dateTime.name

      const endFieldIndex = this.parentGrid.fields.findIndex(field => field.id === this.endFieldId)
      const endField = this.parentGrid.fields[endFieldIndex]

      const calendarEvents = this.calendarModelEvents(
        startFieldIndex,
        endFieldIndex !== -1 ? endFieldIndex : null,
        hasTime
      )
      return { events: calendarEvents, startField, endField, hasTime }
    }
  }

  calendarModelEvents(startIndex, endIndex, timed) {
    let calendarEvents = this.parentGrid.entities.map((entity) => {
      const event = {
        name: this.calendarTitleFor(entity),
        entity: entity,
        color: 'primary',
        timed: timed,
      }
      if (startIndex != null) {
        if (entity.fields[startIndex]) {
          event['start'] = new Date(entity.fields[startIndex])
        }
      }
      if (endIndex != null) {
        if (entity.fields[endIndex]) {
          event['end'] = new Date(entity.fields[endIndex])
        }
      }
      return event
    })
    calendarEvents = calendarEvents.filter((event) => event.start)
    return calendarEvents
  }

  calendarTitleFor(entity) {
    return this.parentGrid.displayFormat(entity.fields[0], 0) ?? ''
  }

  entityFilter(startDate, endDate) {
    if (this.startFieldId == null) {
      return undefined
    }

    const startField = this.parentGrid.fields.find(field => field.id === this.startFieldId)
    const type = startField.columnType
    const start = type.sanitizedValue(startDate)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)
    const end = type.sanitizedValue(endDate)

    // If the calendar only has a startField and no endField,
    // the filter is simple, the start date has to be between
    // the start and end of the visible range (inclusive)
    const startOnlyFilter = [
      {
        $or: [
          {[this.startFieldId]: { $gt: start }},
          {[this.startFieldId]: { $eq: start }},
        ]
      },
      {
        $or: [
          {[this.startFieldId]: { $lt: end }},
          {[this.startFieldId]: { $eq: end }},
        ]
      }
    ]

    if (this.endFieldId == null) {
      return parseFilter({
        $and: [...startOnlyFilter]
      })
    }

    // If the calendar has an endField, then there are two possibilities :
    // 1: the entry has no end, then the start needs to be in the range
    // 2: the entry has an end then we need to test if its range overlaps
    //    (startFieldId <= end) and (endFieldId >= start)
    const filter = {
      $or: [
        {
          $and: [
            {[this.endFieldId]: { $isEmpty: true }},
            ...startOnlyFilter
          ]
        },
        {
          $and: [
            {
              $or: [
                {[this.startFieldId]: { $lt: end }},
                {[this.startFieldId]: { $eq: end }},
              ]
            },
            {
              $or: [
                {[this.endFieldId]: { $gt: start }},
                {[this.endFieldId]: { $eq: start }},
              ]
            },
          ]
        }
      ]
    }

    return parseFilter(filter)
  }
}