import FlowNode from '@/store/models/flow/nodes/FlowNode.js'
import { nodeTypes } from '@/constants/nodeTypes'
import TemplateInput from '@/components/flow/TemplateInput.vue'

export default class FlowParallelNode extends FlowNode {
  constructor(data, eventType) {
    super(data, eventType)

    this.typeName = nodeTypes.parallel.typeName
    this.description = nodeTypes.parallel.description
    this.icon = nodeTypes.parallel.icon
    this.image = nodeTypes.parallel.image
    this.graphIcon = nodeTypes.parallel.graphIcon
    this.color = nodeTypes.parallel.color
    this.docuLink = nodeTypes.parallel.docuLink
  }

  async loadSetupView(space) {
    return this.loadSetupBlock(space)
  }

  _component(key, label, componentType, options, space) {
    if (key === 'collection') {
      return {
        component: TemplateInput,
        props: {
          label,
          name: label,
          key,
          space
        }
      }
    }
    return super._component(...arguments)
  }


}