<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="inputModel"
        v-bind="$attrs"
        @blur="onBlur"
      >
        <template v-slot:append>
          <v-btn v-on="on" icon>
            <v-sheet width="20" height="20" class="outline" rounded :color="externalModel"></v-sheet>
          </v-btn>
        </template>
      </v-text-field>
    </template>

    <v-color-picker v-model="externalModel" mode="hexa"/>
  </v-menu>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'
export default {
  mixins: [externalModel],
  data() {
    return {
      menu: false,
      inputModel: undefined
    }
  },
  watch: {
    externalModel: {
      immediate: true,
      handler(newVal) {
        this.inputModel = newVal
      }
    }
  },
  methods: {
    onBlur() {
      this.externalModel = this.inputModel
      this.$emit('blur')
    }
  }
}
</script>

<style>
.outline {
  border: 1px solid #a4a4a4 !important;

}
</style>
