<template>
  <div>
    <BaseLinkView 
      v-if="link"
      :key="link.redirectUri"
      :uri="link.redirectUri"
      :buttons="buttons"
      :moreItems="moreMenuItems"
    />
    <QrCodeDialog
      v-model="qrCodeDialog"
      :uri="sharedUrl()"
      :showMobileApp="false"
    />
    <LinkRestrictionsDialog
      v-model="restrictionDialog"
      :link="link"
    />
</div>
</template>

<script>
import { sharedLinksMethods } from '@/mixins/sharedLinksMethods.js'
import BaseLinkView from '@/components/BaseLinkView.vue'
import QrCodeDialog from '@/components/QrCodeDialog.vue'
import LinkRestrictionsDialog from '@/components/LinkRestrictionsDialog.vue'

export default {
  mixins: [sharedLinksMethods],
  props: {
    virtualGridUri: null,
    statefulViewUri: null,
    link: null
  },
  data() {
    return {
      loading: false,
      qrCodeDialog: false,
      restrictionDialog: false
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    uri() {
      return this.link.redirectUri
    },
    absoluteUri() {
      if (this.uri == null) {
        return
      }
      return `${window.location.origin}${this.uri}`
    },
    buttons() {
      return [
        {
          icon: 'mdi-share',
          color: 'primary',
          tooltip: this.$t('forms.shareForm.buttons.open'),
          onClick: this.open,
          testId: 'openSharedFormButton'
        },
        {
          icon: 'mdi-content-copy',
          color: 'primary',
          onClick: this.copyLink,
          tooltip: this.$t('forms.shareForm.buttons.copy'),
          testId: 'copySharedFormLinkButton'
        },
        {
          icon: 'mdi-qrcode',
          color: 'primary',
          onClick: () => { this.qrCodeDialog = true },
          tooltip: this.$t('forms.shareForm.buttons.qrCode'),
          testId: 'showFormLinkQrCodeButton'
        },
        {
          icon: this.link.requiresPassword ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline',
          color: 'primary',
          onClick: () => { this.restrictionDialog = true },
          tooltip: this.$t('forms.shareForm.buttons.setRestrictions'),
          testId: 'showFormLinkRestrictionsDialogButton'
        },
      ]
    },
    moreMenuItems() {
      return [
        {
          icon: 'mdi-application-brackets-outline',
          text: this.$t('forms.shareForm.embed.open'),
          onClick: this.openEmbeddedEditor,
          testId: 'openEmbeddedEditorButton'
        },
        {
          icon: 'mdi-delete-outline',
          text: this.$t('virtualGrid.qrCode.dialog.buttons.remove'),
          color: 'red',
          onClick: this.deleteLink,
          testId: 'deleteLinkButton'
        },
      ]
    }
  },
  methods: {
    showRestrictionsDialog() {
      this.restrictionDialog = true
    },
    openEmbeddedEditor() {
      const routeData = this.$router.resolve({
        name: 'EmbeddedEditor',
        params: {
          sharedUrl: this.absoluteUri
      }})
      window.open(routeData.href, '_blank')
    },
  },
  components: {
    BaseLinkView,
    QrCodeDialog,
    LinkRestrictionsDialog
  }
}
</script>

<style scoped>
.fit-parent-img {
  width: auto;
  height: 40px;
}

.one-line {
  overflow: auto;
  overflow-wrap: normal;
}

.full-width {
  outline: none;
  width: 100%;
}
</style>
