<template>
<v-dialog eager v-model="externalModel" max-width="448" :fullscreen="$vuetify.breakpoint.smAndDown">
  <v-card ref="content">
    <v-card-title class="d-flex justify-space-between">
      <span>{{$t('dialogs.signatureTitle')}}</span>
      <v-btn :color="color" icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <div class="d-flex flex-column ml-4 mr-4">
      <canvas class="signature-canvas" ref="canvas"></canvas>
      <div class="d-flex flex-grow-1 justify-space-between pa-3">
        <v-btn :disabled="signaturePadIsEmpty" text @click="clear">{{$t('dialogs.signatureClearButton')}}</v-btn>
        <v-btn :disabled="signaturePadIsEmpty" :loading="saveLoading" text :color="color" @click="save">{{$t('dialogs.signatureSaveButton')}}</v-btn>
      </div>
    </div>
  </v-card>
</v-dialog>
</template>

<script>
import SignaturePad from 'signature_pad'
import externalModel from '../../mixins/externalModel'
import { uploadFileToS3 } from '@/utils/s3Utils.js'
import { v4 as uuidv4 } from 'uuid'

const SIGNATURE_TYPE = 'image/svg+xml'
export default {
  mixins: [externalModel],
  props: ['signatureFile', 'color'],
  mounted() {
    this.signaturePad = new SignaturePad(this.$refs.canvas)
    // resize pad on orientation change
    window.addEventListener('resize', this.resizeCanvas, false)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeCanvas, false)
  },
  data() {
    return {
      signaturePad: undefined,
      savedSignature: undefined,
      saveLoading: false,
      dialog: false
    }
  },
  watch: {
    async value(newVal) {
      if (newVal) {
        await this.$nextTick()
        this.resizeCanvas()
        if (this.externalModel?.url != null) {
          this.signaturePad.fromDataURL(this.externalModel.url)
        }
      }
    }
  },
  computed: {
    signaturePadIsEmpty() {
      return this.signaturePad?.isEmpty()
    },
  },
  methods: {
    clear() {
      this.signaturePad.clear()
    },
    signatureFileName() {
      return `signature_${uuidv4()}.svg`
    },
    async save() {
      this.saveLoading = true
      try {
        const signatureDataUrl = this.signaturePad.toDataURL(SIGNATURE_TYPE)
        const blob = this.dataURItoBlob(signatureDataUrl)
        const name = this.signatureFileName()
        const s3FileUrl = await uploadFileToS3(name, SIGNATURE_TYPE, blob)
        const signatureFile = {
          name,
          type: SIGNATURE_TYPE,
          url: s3FileUrl
        }
        this.$emit('signatureSaved', signatureFile)
        this.close()
      } finally {
        this.saveLoading = false
      }
    },
    resizeCanvas() {
      // https://github.com/szimek/signature_pad#handling-high-dpi-screens
      const canvas = this.$refs.canvas
      const ratio =  Math.max(window.devicePixelRatio || 1, 1)
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext('2d').scale(ratio, ratio)
      this.signaturePad.clear() // otherwise isEmpty() might return incorrect value
    },
    dataURItoBlob(dataURI) {
      // https://stackoverflow.com/questions/13990673/upload-canvas-data-to-s3
      var binary = window.atob(dataURI.split(',')[1])
      var array = []
      for(var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i))
      }
      return new Blob([new Uint8Array(array)], {type: SIGNATURE_TYPE})
    },
    close() {
      this.externalModel = false
    }
  }
}
</script>

<style scoped>
  .signature-canvas {
    background: white;
    border: solid 1px #d3d2d4;
    border-radius: 4px;
    height: 200px;
    width: 100%;
  }
</style>
