<template>
<div>
  <slot name="initial" v-if="state === STATES.initial" :on="{click: () => this.state = STATES.confirm}"/>
  <slot name="confirm" v-if="state === STATES.confirm" :on="{click: onClick}"/>
</div>
</template>


<script>

const STATES = {
  initial: 'INITIAL',
  confirm: 'CONFIRM'
}
  export default {
    props: {
      onClick: {
        type: Function,
        default: () => () => {}
      },
    },
    data() {
      return {
        state: STATES.initial,
        STATES
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>