export default {
  data() {
    return {
      internalValue: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.internalValue = this.formatValue(newVal, this.currency, this._i18n.locale)
      }
    }
  },
  computed: {
    currency() {
      return this.field.type.currency
    }
  },
  methods: {
    handleInput(value) {
      this.internalValue = value
    },
    finishedEditing() {
      const numberParts = Array.from(this.internalValue?.matchAll(/\d+/g) ?? [], match => match[0])
      const parsed = numberParts.length > 1
        ? parseFloat(numberParts.slice(0, -1).join('') + '.' + numberParts.slice(-1))
        : parseFloat(numberParts?.[0])
      if (isNaN(parsed)) {
        this.internalValue = null
        this.$emit('input', null)
      } else {
        this.$emit('input', +parsed.toFixed(2))
      }
      this.$emit('blur')
    },
    formatValue(value, currency, locale) {
      if (value == null) {
        return undefined
      }
      try {
        const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency })
        return formatter.format(value)
      } catch {
        return `${currency} ${value.toFixed(2)}`
      }
    }
  }
}