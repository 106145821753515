<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pa-4 text-subtitle-2">
      {{ title }}
      <template v-slot:actions>
        <v-icon>
          mdi-menu-down
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style></style>