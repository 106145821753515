<template>
  <v-col>
    <div v-if="!disabled">{{ $t("virtualGrid.currencyOptionsTitle") }}</div>
    <v-autocomplete
      :disabled="disabled"
      :items="items"
      v-model="autocompleteModel"
      :label="$t('columnTypes.currency')"
      data-testid="referenceSelect"
    >
    </v-autocomplete>
  </v-col>
</template>


<script>
import currencies from '@/constants/currencies.js'

export default {
  props: {
    value: null,
    disabled: null,
  },
  data() {
    return {
      currencies: currencies.map(item => item.code),
      loading: false
    }
  },
  mounted() {
    if (this.value == null) {
      this.autocompleteModel = this.items[0].value
    }
  },
  computed: {
    items() {
      const localisedName = new Intl.DisplayNames([this._i18n.locale], {type: 'currency'})
      const items = []
      this.currencies.forEach(currency => {
        try {
          items.push({
            text: localisedName.of(currency),
            value: currency
          })
        } catch (error) {
          items.push({
            text: currency,
            value: currency
          })
        }
      })
      return items
    },
    autocompleteModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>
