<template>
 <BaseFormInput v-bind="$props" :hideLabel="true">
    <v-input
      v-model="externalModel"
      :label="label"
      :rules="[rules.required, rules.schema]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      :disabled="disabled"
    >


    <div class="d-flex mt-1" :disabled="disabled">
      <div v-if="previewSrc" class="d-flex align-center justify-start row my-4 ml-2">
      <video
        :src="previewSrc"
        class="video-preview"
        width="85%" preload="metadata"
        
        playsinline webkit-playsinline muted controls
      />
      <v-btn :color="color" @click="externalModel = null" icon><v-icon>mdi-delete-outline</v-icon></v-btn>
    </div>
      <div
        v-else
        class="video-placeholder d-flex align-center justify-center"
      >
      <v-btn @click="openCamera()" text :color="color">
        <v-icon class="pr-2" :color="color">mdi-video-outline</v-icon>
        {{$t('forms.view.tapToRecord')}}
      </v-btn>
      
      </div>
    </div>


    <!-- Button to preview video outside the modal -->
    <FormVideoRecorder
      v-model="dialog"
      :videoFile="externalModel"
      @videoSaved="videoSaved" 
      :label="label"
      :description="description"/>

  </v-input>
</BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import FormVideoRecorder from '@/components/form/FormVideoRecorder.vue'
import externalModel from '@/mixins/externalModel'
import BaseFormInput from '@/components/form/BaseFormInput.vue'

export default {
  mixins: [formFields, externalModel],
  data () {
    return {
      showModal: false,
      showPreviewModal: false,
      dialog: false,
    }
  },
  computed: {
    previewSrc() {
      if (this.externalModel && this.externalModel.length > 0) {
        return this.externalModel[0].url
        }
      return null
    }
  },
  methods: {
    openCamera() {
      this.dialog = true && !this.disabled
    },
    videoSaved(file) {
      this.$emit('input', [ file ] )
    },
  },
  components: {
    FormVideoRecorder,
    BaseFormInput
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  display: unset;
}
.video-preview {
  background-color: black;
  max-height: 200px;
}
.video-placeholder {
  height: 100px;
  width: 100%;
  cursor: pointer;

}
</style>