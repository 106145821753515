export const DATE_DISPLAY_FORMAT = 'DD.MM.YYYY'
export const DATE_MASK = '##.##.####'
export const DATE_EXPORT_FORMAT = 'YYYY-MM-DD'
export const DATE_PICKER_FORMAT = 'YYYY-MM-DD'

export const DATETIME_DISPLAY_FORMAT = 'DD.MM.YYYY HH:mm'
export const DATETIME_SECONDS_DISPLAY_FORMAT = 'DD.MM.YYYY HH:mm:ss'
export const DATETIME_MASK = '##.##.#### ##:##'
export const DATETIME_PICKER_FORMAT = 'YYYY-MM-DD HH:mm'

export const TIME_FORMAT = 'HH:mm'
