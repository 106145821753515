<template>
  <v-navigation-drawer class="drop-shadow" :mini-variant.sync="mini" :width="width" v-model="show" app right clipped>
    <v-row
      class="fill-height"
      no-gutters
    >

      <v-navigation-drawer
        mini-variant
        mini-variant-width="56"
        v-model="show"
      >
      <div class="d-flex justify-center align-center closeButtonContainer">
        <v-btn
          icon
          @click.stop="close"
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

        <v-list>
        <v-list-item class="pl-3" @click="selectApptive(aApptive)" v-for="aApptive in apptives" :key="aApptive.name">
          <div>
            <v-img width="34" height="34" :src="aApptive.icon"></v-img>
            <v-icon v-if="apptive == aApptive" class="active">mdi-circle-small</v-icon>
          </div>
        </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <Apptive class="drop-shadow" v-model="mini" v-if="apptive" :apptive="apptive" :key="apptive.name"/>
  </v-row>
  </v-navigation-drawer>
</template>

<script>
import Apptive from '@/components/apptives/Apptive.vue'

export default {
  props: {
    value: null,
  },
  data: () => ({
    mini: true,
    apptives: null,
    apptive: null,
    width: 320
  }),
  components: {
    Apptive
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val){
        if(val){
          this.getAppsList()
        }
        this.$emit('input', val)
      }
    },
  },
  methods: {
    getAppsList() {
      this.$store.dispatch('loadApptives')
        .then(apptives => { this.apptives = apptives })
    },
    selectApptive(apptive){
      this.width = apptive.minWidth
      this.apptive = apptive
    },
    close() {
      this.show = false
      this.mini = true
    },
  }
}
</script>

<style lang="css" scoped>
.closeButtonContainer {
  height: 47px;
}
.drop-shadow {
  filter: drop-shadow(-1px 0px 6px #e6e5e7);
}
.active {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0px;
  top: calc(50% - 5px);
}
</style>
