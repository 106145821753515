<template>
  <div class="relative">
    <div
      id="text"
      v-bind="$attrs"
      v-on="$listeners"
      :style="textStyle"
      :class="{ellipsised: fullHeight > height}"
    >{{text}}</div>
    <div
      ref="fullText"
      class="full-text-ref"
    >{{text}}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    height: Number
  },
  data() {
    return {
      resizeObserver: undefined,
      fullHeight: undefined
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if(entry.contentBoxSize) {
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize
          this.fullHeight = contentBoxSize.blockSize
        }
      }
    })
    this.resizeObserver.observe(this.$refs.fullText)
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.fullText)
  },
  computed: {
    textStyle() {
      return {
        height: `${this.height}px`,
        overflow: 'hidden',
        '--full-height': `${this.fullHeight}px`
      }
    }
  }
}
</script>

<style scoped>
.relative {
  position: relative;
}

#text {
  transition: height 0.4s;
}

#text:hover {
  height: var(--full-height) !important;
}

.full-text-ref {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding-right: 1em;
  pointer-events: none;
}

.ellipsised {
  position: relative;
  overflow: hidden;
  padding-right: 1em;
}
.ellipsised:not(:hover)::before {
  position: absolute;
  content: "...";
  inset-block-end: 0; /* "bottom" */
  inset-inline-end: 0; /* "right" */
}
.ellipsised:not(:hover)::after {
  content: "";
  position: absolute;
  inset-inline-end: 0; /* "right" */
  width: 1rem;
  height: 1rem;
  background: white;
}
</style>