import Vue from 'vue'
import ApiClient from '../../ApiClient'
import { PERMISSIONS } from '../../utils/halUtils'
import Transition from '../models/flow/Transition'
import HalObject from '../models/HalObject'

export default {
  state() {
    return {
      flows: {}
    }
  },
  mutations: {
    addFlow(state, flow) {
      Vue.set(state.flows, flow.uri, flow)
    },
    removeForm(state, flow) {
      delete state.flows[flow]
    }
  },
  actions: {
    AGListFlowsOperation(context) {
      return context.dispatch('listFlows')
    },
    AGListSpaceFlowsOperation(context, { spaceUri }) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink('flows')
      return ApiClient.getUri(link).then(response => {
        return response.data.items.map( flow => {
          return {
            uri: flow._links.self.href,
            name: flow.name
          }
        })
      })
    },
    async AGReadFlowOperation(context, {flowUri, loadNodes = false}) {
      flowUri = flowUri ?? arguments[1]
      const response = await ApiClient.getUri(flowUri)

      // Load flow trigger
      let hookLink
      try {
        hookLink = new HalObject(response.data).getLink('hook')
      } catch(error) {
        hookLink = null
      }
      let webhook = undefined
      if (hookLink != null) {
        webhook = await context.dispatch(
          'AGReadWebhookOperation',
          hookLink
        )
      }

      // Prevent Circular dependency FlowNode -> store -> Flow -> ExternalTriggerNode -> StartNode -> FlowNode
      const Flow = (await import('@/store/models/flow/Flow.js')).default
      const flow = new Flow(response.data, webhook)
      flow.uri = flowUri
      const oldFlow = context.getters.flowWithUri(flowUri)
      if (oldFlow != null) {
        flow.nodes = oldFlow.nodes
        flow.trigger = oldFlow.trigger
      }
      if (loadNodes) {
        await flow.loadNodes()
      }
      context.commit('addFlow', flow)
      return flow
    },
    async AGReadFlowHookOperation(context, flowUri) {
      const flow = context.getters.flowWithUri(flowUri)
      const webhook = await context.dispatch('AGReadWebhookOperation', flow.getLink('hook'))
      flow.trigger = webhook
      return webhook
    },
    async AGListNodesOperation(contex, flow) {
      const link = flow.getLink('nodes')
      const response = await ApiClient.getUri(link)
      return response.data.items
    },
    async AGAddFlowFlowOperation(context, { name, parentSpaceUri }) {
      let link = await context.getters.userHalLink(PERMISSIONS.addFlow)
      if (parentSpaceUri != null) {
        const space = context.getters.spaceWithUri(parentSpaceUri)
        link = space.getLink(PERMISSIONS.addFlow)
      }
      const response = await ApiClient.postUri(link, {
        name
      })
      const flow = await context.dispatch('AGReadFlowOperation', response.headers.location)
      return flow
    },
    async AGPatchFlowOperation(context, { flow, name, enabled}) {
      const link = flow.getLink(PERMISSIONS.patch)
      return ApiClient.patchUri(link, {
        name,
        enabled
      })
    },
    async AGListNodeTypes() {
      const response = await ApiClient.getUri('/api/nodeTypes')
      return response.data
    },
    async AGAddNodeOperation(context, { flow, node }) {
      const link = flow.getLink('addNode')
      const response = await ApiClient.postUri(link, {
        name: node.name,
        type: node.type,
        tag: node.tag
      })
      return context.dispatch('AGReadNodeOperation', response.headers.location)
    },
    async AGReadNodeOperation(context, uri) {
      const response = await ApiClient.getUri(uri)
      return response.data
    },
    async AGAddTransition(context, { flow, source, target}) {
      const link = flow.getLink('addTransition')
      const response = await ApiClient.postUri(link, { source, target })
      return response.headers.location
    },
    async AGDeleteNodeOperation(context, { node }) {
      const link = node.getLink('remove')
      return ApiClient.deleteUri(link)
    },
    async AGDeleteFlowOperation(context, { flow }) {
      const link = flow.getLink('remove')
      return ApiClient.deleteUri(link)
    },
    async AGPatchNodeOperation(context, { node, name, tag}) {
      const link = node.getLink('patch')
      return ApiClient.patchUri(link, { name, tag })
    },
    async AGPutNodeProperties(context, { node, properties }) {
      let link = node.updatePropertiesLink
      if (link == null) {
        const propertiesLink = node.getLink('properties')
        const propertiesResponse = await ApiClient.getUri(propertiesLink)
        link = (new HalObject(propertiesResponse.data)).getLink('update')
        node.updatePropertiesLink = link
      }
      await ApiClient.putUri(link, properties)
      return properties
    },
    async AGListNodeEventsOperation(context, { node }) {
      const link = node.getLink('events')
      return ApiClient.getUri(link)
    },
    async AGReadTransition(context, { uri }) {
      const response = await ApiClient.getUri(uri)
      return new Transition(response.data)
    },
    async AGDeleteTransition(context, { transition }) {
      const link = transition.getLink('remove')
      return ApiClient.deleteUri(link)
    },
    async AGPatchTransition(context, { transition, description, condition }) {
      const link = transition.getLink('patch')
      return ApiClient.patchUri(link, {
        description,
        condition
      })
    },
    async AGReadNodeSetupView(context, { node }) {
      const link = node.getLink('setupView')
      const response = await ApiClient.getUri(link)
      return response.data
    },
    async AGReadNodeSetupBlock(context, { node }) {
      const link = node.getLink('setupBlock')
      const response = await ApiClient.getUri(link)
      return response.data
    }
  },
  getters: {
    flowWithUri: state => uri => {
      return state.flows[uri]
    },
    nodeWithUri: state => uri => {
      return Object.values(state.flows).flatMap( flow => flow.nodes ).find( node => node.uri == uri)
    }
  }
}
