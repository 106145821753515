import { noValue } from '@/utils/jsUtils'
import { SORTING_FUNCTIONS } from '@/sorting/sortingFunctions'

const ASCENDING = 'ascending'

export function parseSortingCriteria(sortingCriteria) {
  let fieldId, order, sortingFunction
  if (typeof sortingCriteria === 'object' && Object.entries(sortingCriteria).length > 0) {
    Object.entries(sortingCriteria).forEach(entry => {
      fieldId = entry[0]
      if (typeof entry[1] === 'string') {
        order = entry[1]
      } else {
        Object.entries(entry[1]).forEach(sortingEntry => {
          if (sortingEntry[0] === '$order') {
            order = sortingEntry[1]
          } else {
            sortingFunction = new SORTING_FUNCTIONS[sortingEntry[0]](sortingEntry[1])
          }
        })
      }
    })
  }

  return {
    fieldId,
    order,
    sortingFunction
  }
}

export function defaultNoValueSorting(a, b, sortFieldIndex, ascending) {
  // If both are noValue, always keep initial order
  if (noValue(a.fields[sortFieldIndex]) && noValue(b.fields[sortFieldIndex])) return 0
  if (noValue(b.fields[sortFieldIndex])) return 1 * ascending
  if (noValue(a.fields[sortFieldIndex])) return -1 * ascending
}

function singleCriteriaComparison(a, b, sortingCriteria, sortFieldIndex, ascending, type) {
  const noValueSort = defaultNoValueSorting(a, b, sortFieldIndex, ascending)
  if (noValueSort != null) {
    return noValueSort
  }
  const transformedA = sortingCriteria.sortingFunction ? sortingCriteria.sortingFunction.call(a.fields[sortFieldIndex]) : a.fields[sortFieldIndex]
  const transformedB = sortingCriteria.sortingFunction ? sortingCriteria.sortingFunction.call(b.fields[sortFieldIndex]) : b.fields[sortFieldIndex]
  if (type.sortOverride) {
    return type.sortOverride(transformedA, transformedB) * ascending
  } else {
    if (transformedA > transformedB) return 1 * ascending
    if (transformedA < transformedB) return -1 * ascending
  }
  return 0
}

export default function sort(sortings, fields, entities) {
  entities.sort((a, b) => {
    let comparison = 0
    let criteriaIndex = 0
    while(comparison === 0 && criteriaIndex < sortings.length) {
      const sortingCriteria = parseSortingCriteria(sortings[criteriaIndex])
      const sortFieldIndex = fields.findIndex(field => field.id === sortingCriteria.fieldId)
      const sortField = fields[sortFieldIndex]
      const ascending = sortingCriteria.order === ASCENDING ? 1 : -1
      const type = sortField.columnType
      comparison = singleCriteriaComparison(a, b, sortingCriteria, sortFieldIndex, ascending, type)
      criteriaIndex++
    }
    return comparison
  })
}