<template>
  <v-container class="d-flex justify-center align-center full-height" >
    <v-sheet elevation="10" rounded="lg" class="pa-6" width="650" min-height="400">
      <img height="22" src="/icon_name.png" />

      <v-row no-gutters>
      <v-col
        class="d-flex align-center"
        md="6"
        xs="12"
      >
      <div>
        <div class="text-h6">{{ $t("spaceInvitation.inviteText") }}</div>
        <v-btn class="mt-5" @click="acceptInvitation" color="primary">{{ $t("spaceInvitation.inviteAcceptButtonText") }}</v-btn>
      </div>  
      </v-col>
      <v-col
        md="6"
        xs="12"
        class="d-flex justify-center"
      >
      <img height="300" src="/team-spirit-cuate.svg" />

      </v-col>
    </v-row>
  
  </v-sheet>    
  </v-container>


</template>

<script>
import ApiClient from '@/ApiClient'

export default {
  computed: {
    linkUri() {
      return (new URL(location)).searchParams.get('uri')
    },
  },
  methods: {
    async acceptInvitation() {
      let response = await ApiClient.getUri(this.linkUri)
      const uri = response.headers['location']
      let space = await this.$store.dispatch('AGReadSpaceOperation', { spaceUri: uri })
      this.$router.push({ name: 'Space', params: {
        spaceUri: space.realSpace,
        sharedSpaceUri: space.spaceUri
      }})
    },
  }
}
</script>

<style>
.full-height {
  height: 100vh;
}</style>