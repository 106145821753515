<script>
/* global ASParser */
import { RenderFunctionExpressionVisitor } from '../apptivescript/apptivescriptUtils'

export default {
  props: ['value', 'grid'],
  data() {
    return {
      ast: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          return
        }
        try {
          const ast = ASParser.as_parse_(newVal)
          this.ast = ast
        } catch(error) {
          console.error(error)
        }
      }
    }
  },
  render(h) {
    if (!this.ast) {
      return h(
        'div',
      )
    }
    const visitor = new RenderFunctionExpressionVisitor(h, this.grid)
    visitor.as_visit_(this.ast)
    const tokens = visitor.tokens ?? []
    return h(
      'div',
      { },
      tokens
    )
  }
}
</script>
