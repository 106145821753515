<template>
  <div class="template-input-wrapper">
    <div class="label v-label theme--light" v-show="showLabel">{{label}}</div>
    <div class="v-label theme--light mb-1 text-caption" v-show="!showLabel && topLabel">{{label}}</div>
    <div ref="hiddenContent" class="hidden-content" />
    <div ref="input" class="input" :style="inputStyle" :class="{textArea}" />
    <div :class="{visible: error != null}" class="error-message error--text">{{ error || 'invisible' }}</div>
  </div>
</template>


<script>
import externalModel from '@/mixins/externalModel.js'
import { ExpressionEditor } from '@/editor/ExpressionEditor'


export default {
  mixins: [externalModel],
  props: {
    flow: null,
    grid: null,
    textArea: {
      type: Boolean,
      default: () => false
    },
    selected: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    topLabel: {
      type: Boolean,
      default: () => false
    },
    label: null
  },
  data() {
    return {
      editor: undefined,
      textSchema: undefined,
      sections: [],
      focused: false,
      error: undefined
    }
  },
  computed: {
    borderColor() {
      if (this.error != null) {
        return this.$vuetify.theme.themes.light.error
      }
      return this.selected ? this.$vuetify.theme.themes.light.primary : '#D3D3D3'
    },
    inputStyle() {
      return {
        '--border-color': this.borderColor
      }
    },
    showLabel() {
      return (this.value == null || this.value.length === 0) && !this.error && this.label && !this.focused
    },
  },
  mounted() {
    const editorViewNode = this.$refs.input
    const contentNode = this.$refs.hiddenContent

    const domEventHandlers = {
      blur: (editor) => {
        this.focused = false
        this.emitEditorContent(editor)
      },
      focus: () => {
        this.$emit('focus')
        this.focused = true
      },
      keydown: (editor, event) => {
        if (event.key === 'Enter') {
          this.focused = false
          this.emitEditorContent(editor)
        }
      }
    }

    this.editor = new ExpressionEditor({
      flow: this.flow,
      editorViewNode,
      contentNode,
      domEventHandlers,
      multiline: this.textArea
    })
    this.editor.initContentFromString(this.value, this.grid)
  },
  watch: {
    value(newVal) {
      this.editor.initContentFromString(newVal, this.grid)
    },
    'editor.error': {
      handler(newVal) {
        this.error = newVal
      }
    }
  },
  methods: {
    insert(text) {
      const content = this.editor.getContentAsText()
      this.editor.initContentFromString(content + text, this.grid)
      this.emitEditorContent()
    },
    insertToken(tokenProps) {
      this.editor.insertToken(tokenProps)
      this.emitEditorContent()
    },
    insertTokens(tokenPropsArray) {
      tokenPropsArray.forEach(item => {
        this.editor.insertToken(item)
      })
      this.emitEditorContent()
    },
    emitEditorContent() {
      const content = this.editor.getContentAsText()
      this.editor.setContentFromString(content,this.grid)
      if (this.editor.error) {
        return
      }
      this.error = undefined
      this.externalModel = content
      this.$emit('blur')
    },
    focus() {
      this.editor.focus()
    }
  },
}
</script>

<style scoped>
.template-input-wrapper {
  position: relative;
}
.label {
  position: absolute;
  top: 12px;
  left: 12px;
  pointer-events: none;
  z-index: 1;
}
::v-deep.input > div{
  min-height: 40px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 6px;
  border: solid var(--border-color) 1px;
  border-radius: 4px;
  background: white;
  white-space: pre;
  overflow: auto;
  -webkit-user-select: auto;
  user-select: all;
}

::v-deep.textArea > div{
  min-height: 80px;
  resize: vertical;
}

::v-deep.input p {
  margin: 0px;
}

::v-deep.input span {
  margin: 0px;
  vertical-align: bottom;
}

.hidden-content {
  display: none;
}

.error-message {
  font-size: 14px;
  background: transparent;
  opacity: 0;
  transition: opacity 0.2s;
}

.visible {
  opacity: 1;
}
</style>