<template>
  <editable-text 
    class="mb-3 mt-2" 
    :readonly="readonly" 
    :multiline="true" 
    :text="text"
    :singleClick="true" 
    :required="false" 
    :maxLength="2000" 
    @submit="newVal => save(newVal)"
    v-bind="this.textBlock.props"
    ></editable-text>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import Vue from 'vue'

export default {
  name: 'FormTextBlock',
  props: ['textBlock', 'language', 'readonly', 'form'],
  computed: {
    text() {
      if (this.form.i18nFeature && this.textBlock.i18n && this.textBlock.i18n[this.language]) {
        return  this.textBlock.i18n[this.language].text
      }
      return this.textBlock.text
    },
  },
  methods: {
    async save(newText ) {
      if (!this.form.i18nFeature) {
        this.textBlock.text = newText
      }
      else {
        Vue.set(this.textBlock, 'i18n', this.textBlock.i18n ?? {})
        Vue.set(this.textBlock.i18n, this.language, this.textBlock.i18n[this.language] ?? {})
        this.textBlock.i18n[this.language].text = newText
      }
      this.form.updateComponents()
    },
  },
  components: {
    EditableText
  }
}
</script>
