<template>
  <div>
    <FormTextField
      v-if="component.type == 'textfield' && type.name == 'string'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormTextField
      v-if="component.type == 'textfield' && type.name == 'richText'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormIntegerField
      v-else-if="type.name == 'integer'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormDecimalField
      v-else-if="type.name == 'decimal'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormDateField
      v-else-if="component.type == 'datePicker' && type.name == 'date'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormDateAndTimeField
      v-else-if="component.type == 'datePicker' && type.name == 'date-time'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormCheckbox
      v-else-if="component.type == 'checkbox'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormSelect
      v-else-if="component.type == 'selectBox'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormSelectList
      v-else-if="component.type == 'selectList' || component.type == 'multiSelectList'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormEntitySelect
      v-else-if="component.type == 'entitySelect'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormSignaturePad
      v-else-if="component.type == 'filePicker' && type.name === 'signature'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormVideoInput
      v-else-if="component.type == 'filePicker' && typeOverride == 'videoRecorder'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormFileInput
      v-else-if="component.type == 'filePicker'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormLocationPicker
    v-else-if="component.type == 'locationPicker'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormSelect
      v-else-if="component.type == 'multiSelectDropdown' && type.name == 'enumcollection'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormEntitySelect
      v-else-if="component.type == 'multiSelectDropdown' && type.name == 'references'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormUserSelect
      v-else-if="component.type == 'userSelect'"
      v-bind="fieldProps"
      v-model="fieldModel"
      :field="field"
    />
    <FormCurrencyField
      v-else-if="component.type == 'textfield' && type.name === 'currency'"
      v-bind="fieldProps"
      v-model="fieldModel"
      :field="field"
    />
    <FormUriField
      v-else-if="component.type == 'textfield' && type.name === 'uri'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormEmailField
      v-else-if="component.type == 'textfield' && type.name === 'email'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormPhoneNumberField
      v-else-if="component.type == 'textfield' && type.name === 'phoneNumber'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormAddressField
      v-else-if="component.type === 'addressPicker'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
    <FormResourceField
      v-else-if="type.name === 'resource'"
      v-bind="fieldProps"
      v-model="fieldModel"
    />
  </div>
</template>

<script>
import FormTextField from '@/components/form/FormTextField.vue'
import FormIntegerField from '@/components/form/FormIntegerField.vue'
import FormDecimalField from '@/components/form/FormDecimalField.vue'
import FormDateField from '@/components/form/FormDateField.vue'
import FormDateAndTimeField from '@/components/form/FormDateAndTimeField.vue'
import FormCheckbox from '@/components/form/FormCheckbox.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormSelectList from '@/components/form/FormSelectList.vue'
import FormEntitySelect from '@/components/form/FormEntitySelect.vue'
import FormFileInput from '@/components/form/FormFileInput.vue'
import FormVideoInput from '@/components/form/FormVideoInput.vue'

import FormLocationPicker from '@/components/form/FormLocationPicker.vue'
import FormUserSelect from '@/components/form/FormUserSelect.vue'
import FormCurrencyField from './FormCurrencyField.vue'
import FormUriField from './FormUriField.vue'
import FormEmailField from './FormEmailField.vue'
import FormPhoneNumberField from './FormPhoneNumberField.vue'
import FormSignaturePad from './FormSignaturePad.vue'
import FormAddressField from '@/components/form/FormAddressField.vue'
import FormResourceField from '@/components/form/FormResourceField.vue'

export default {
  name: 'FormFieldsFactory',
  props: {
    component: null,
    form: null,
    value: null,
    language: null,
    designer: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    fieldProps() {
      return {
        component: this.component,
        form: this.form,
        hidden: this.hidden,
        language: this.language,
        designer: this.designer,
      }
    },
    fieldModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    field() {
      return this.form.fields.find(field => field.id === this.component.fieldId)
    },
    type() {
      return this.field.columnType
    },
    typeOverride() {
      console.log(this.form.fieldProperties[this.component.fieldId]?.typeOverride)
      return this.form.fieldProperties[this.component.fieldId]?.typeOverride
    }
  },
  components: {
    FormTextField,
    FormIntegerField,
    FormDecimalField,
    FormDateField,
    FormDateAndTimeField,
    FormCheckbox,
    FormSelect,
    FormSelectList,
    FormEntitySelect,
    FormFileInput,
    FormVideoInput,
    FormLocationPicker,
    FormUserSelect,
    FormCurrencyField,
    FormUriField,
    FormEmailField,
    FormPhoneNumberField,
    FormSignaturePad,
    FormAddressField,
    FormResourceField
  }
}
</script>
