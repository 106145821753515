<template>
  <v-dialog v-model="externalModel" :max-width="maxWidth" v-bind="$attrs" v-on="$listeners">
    <v-card class="default-dialog-container">
      <v-card-title class="text-h5">{{ title }}</v-card-title>
      <v-card-text class="default-dialog-body">
        <p v-if="text != null">{{ text }}</p>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" data-testid="okButton" color="primary" text @click="clickOK">{{
          $t("dialogs.okButton")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import externalModel from '../mixins/externalModel'

export default {
  mixins: [externalModel],
  props: {
    title: String,
    text: String,
    okHandler: Function,
    maxWidth: {
      default: () => '290'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {},
  methods: {
    async clickOK() {
      if(!this.okHandler) {
        this.externalModel = false
        return
      }
      this.loading = true
      try {
        await this.okHandler(this.inputModel)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.default-dialog-container {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.default-dialog-body {
  overflow: auto;
}
</style>
