<template>
  <v-sheet class="d-flex flex-column parent-height">
    <v-toolbar flat dense class="flex-grow-0">
      <v-spacer />
    </v-toolbar>
    <div class="scroll-container" >
    <v-list  draggable="false">
      <template
        v-for="(node, index) in nodes"
        >
        <v-list-item
          v-if="node.header != null"
          :key="node.header"
          class="text-h6"
        >
        <v-list-item-title class="bold">{{ node.header }}</v-list-item-title>
        </v-list-item>
        <v-list-item
        v-else
        :data-testid="node.eventType || node.typeOverride || node.type"
        :key="node.name"
        @click="addNode(node)"
        v-on:dragstart="dragStart(node, $event, index)"
        draggable="true"
      >
      <FlowNodeIcon
          class="align-center mr-3"
          :ref="`nodeIcon${index}`"
          size="40"
          :nodeType="node"
        />

        <v-list-item-content>
          <v-list-item-title class="bold">{{ node.typeName }}</v-list-item-title>
          <v-list-item-subtitle>{{ node.description }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </template>
    </v-list>
    <div class="mt-3 mb-3 caption text-center">
      <span class="mr-2">{{$t("flow.nodePicker.featureRequest")}}</span>
      <a href="https://app.apptivegrid.de/api/r/62398e23b8b578b35b971df0/63e630d4f00ac0fe7fb0aab2" target="_blank">{{$t("flow.nodePicker.featureRequestAction")}}</a>

    </div>
  </div>
  </v-sheet>
</template>

<script>
import { nodeTypes } from '@/constants/nodeTypes.js'
import FlowNodeIcon from '@/components/flow/FlowNodeIcon.vue'

export default {
  props: {
    node: null
  },
  computed: {
    alphaMode() {
      return this.$store.getters.alphaMode
    },
    nodes() {
      let nodes = [
        {header: this.$t('flow.nodePicker.triggerHeader')},
        nodeTypes.entityAddedEvent,
        nodeTypes.entityUpdatedEvent,
        nodeTypes.addEntityFormSubmitted,
        nodeTypes.editEntityFormSubmitted,
        nodeTypes.requestTrigger,
        {header: this.$t('flow.nodePicker.actionsHeader')},
        nodeTypes.sendEmail,
        nodeTypes.updateEntity,
        nodeTypes.addEntity,
        nodeTypes.listViewEntities,
        nodeTypes.editLink,
        nodeTypes.flowForm,
        nodeTypes.sendHttpRequest,
        nodeTypes.openAICompletion,
        nodeTypes.datevLodas,
        nodeTypes.slack,
        nodeTypes.teamsMessageWebHook,
        nodeTypes.firebasePush,
        nodeTypes.qrCode,
        nodeTypes.calendarEvent,
        nodeTypes.hubspotAddContact,
        nodeTypes.pdf2text,
        {header: this.$t('flow.nodePicker.helperHeader')},
        nodeTypes.exclusiveGateway,
        nodeTypes.parallel,
      ]

      const alphaNodes = [
        nodeTypes.flowForm
      ]
    
      if (!this.alphaMode) {
       return nodes.filter(node => !alphaNodes.includes(node))
      }
      return nodes
    }
  },
  methods: {
    dragStart(node, event, nodeIndex) {
      event.stopPropagation()
      const img = this.$refs[`nodeIcon${nodeIndex}`][0].$el
      event.dataTransfer.setDragImage(img, 0, 0)
      event.dataTransfer.setData('nodeType', node.type)
      event.dataTransfer.setData('nodeName', node.description)
      if (node.eventType != null) {
        event.dataTransfer.setData('eventType', node.eventType)
      }
      if (node.typeOverride != null) {
        event.dataTransfer.setData('typeOverride', node.typeOverride)
      }
    },
    addNode(node) {
      this.$emit('addNode', node)
    },
    close() {
      this.$emit('close')
    }
  },
  components: {
    FlowNodeIcon
  }
}
</script>

<style lang="css" scoped>
.inputContainer {
  background-color: white;
  border: 2px solid #f2f2f2;
  border-radius: 10px;
}
.nodeIcon {
  width: 50px;
  height: 50px;
  border-radius: 100px;

}
.scroll-container {
  overflow: auto;
}
.parent-height {
  height: 100%;
}
</style>
