<template>
<SingleInputDialog
  :disabled="disabled"
  v-model="dialogModel"
  :title="$t('dialogs.renameVirtualGridTitle', {virtualGridTitle: virtualGridTitle})"
  :text="$t('dialogs.renameVirtualGridText')"
  :okButtonLabel="$t('dialogs.saveButton')"
  :okHandler="save"
  :initialInputValue="virtualGridTitle"
  :max="$apptive.constants.nameLengths.grid"
  max-width="290"
>
  <template v-slot:activator="{ on, attrs }">
    <MenuItem
      :disabled="disabled"
      v-on="on" v-bind="attrs"
      icon="mdi-pencil"
      :text="$t('dialogs.renameVirtualGridButton')"
    />
  </template>
</SingleInputDialog>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import MenuItem from '@/components/MenuItem.vue'

export default {
    props: {
      virtualGrid: null,
      statefulView: null,
      disabled: null
    },
    data() {
      return {
        dialogModel: false
      }
    },
    computed: {
      virtualGridTitle() {
        if (this.statefulView != null) {
          return this.statefulView.name
        }
        if (!this.virtualGrid) {
          return ''
        }
        return this.virtualGrid.title()
      }
    },
    methods: {
      save(newName) {
        // The statefulView's name is synchronised with the parent Grid for now.
        if (this.statefulView != null) {
          this.renameStatefulView(newName)
        } else {
          this.renameVirtualGrid(newName)
        }

      },
      async renameVirtualGrid(newName) {
        if (this.virtualGrid.name === newName) {
          return
        }
        await this.$store.dispatch('AGRenameVirtualGridOperation', {
          virtualGrid: this.virtualGrid,
          newName: newName
        })
        this.$store.dispatch('AGListStatefulViewsOperation', this.virtualGrid.parentGrid())
      },
      renameStatefulView(newName) {
        return this.statefulView.rename(newName)
      }
    },
  components: {
    SingleInputDialog,
    MenuItem
  }
}
</script>

<style lang="css"></style>
