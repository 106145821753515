<template>
  <v-icon
    v-bind="$attrs"
    v-on="$listeners"
    class="space-icon"
    :style="{'background-color': backGroundColor, ...constants}"
    :color="color"
  >{{`mdi-${icon}`}}</v-icon>
</template>


<script>
export const ICON_SIZE = 24

import { darkColor } from '@/utils/color'
  export default {
    props: ['space'],
    computed: {
      icon() {
        return this.space?.icon ?? 'table'
      },
      backGroundColor() {
        return this.space?.color ?? this.$vuetify.theme.themes.light.space
      },
      color() {
        return darkColor(this.backGroundColor) ? 'black' : 'white' 
      },
      constants() {
        return {
          '--icon-size': ICON_SIZE + 'px'
        }
      }
    }
  }
</script>

<style scoped>
.space-icon {
  margin-top: 0px;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 20%;
  font-size: calc(var(--icon-size) - 6px);
}
</style>