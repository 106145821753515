<template>
  <v-dialog
    fullscreen
    class="full-size"
    v-model="externalModel"
  >
  <v-toolbar dark absolute>
    <v-btn icon>
      <v-icon @click="externalModel = false">mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>{{currentFile.name}}</v-toolbar-title>
    <v-spacer />
    <div class="d-flex flex-row align-center">
      <div v-for="(file, fileIndex) in files" class="ml-1 relative" :key="fileIndex">
        <FileThumbnail
          @click="() => goTo(fileIndex)"
          class="pointer"
          :file="file"
        />
        <div :key="`doot${fileIndex}`" v-if="fileIndex === index" class="doot"/>
      </div>
  </div>
  </v-toolbar>

  <div class="file-preview-container" >
    <template v-if="currentFile.url">
      <img v-if="isCurrentFileImage" class="full-image" :src="currentFile.url"/>
      <iframe ref="iframe" v-else class="no-border full-size" :src="currentFile.url"/>
    </template>
    <v-btn fab icon class="previous-button" @click="previous">
      <v-icon color="black">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn fab icon class="next-button" @click="next">
      <v-icon color="black">mdi-chevron-right</v-icon>
    </v-btn>
  </div>
  </v-dialog>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'
import FileThumbnail from '@/components/FileThumbnail.vue'

export default {
  mixins: [externalModel],
  props: {
    files: {
      type: Array,
      default: () => []
    },
    showIndex: {
      type: Number,
      default: () => undefined
    }
  },
  data() {
    return {
      index: 0
    }
  },
  watch: {
    externalModel(newVal) {
      if(newVal) {
        this.goTo(this.showIndex)
        document.addEventListener('keydown', this.onKeyDown)
      } else {
        document.removeEventListener('keydown', this.onKeyDown)
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  },
  computed: {
    currentFile() {
      return this.files[this.index] ?? {}
    },
    isCurrentFileImage() {
      return this.currentFile.type.includes('image')
    }
  },
  methods: {
    previous() {
      this.index = Math.max(0, this.index - 1)
    },
    next() {
      this.index = Math.min(this.files.length - 1, this.index + 1)
    },
    goTo(index) {
      if (this.files[index] != null) {
        this.index = index
      }
    },
    onKeyDown(e) {
      if(e.key === 'ArrowLeft') {
        this.previous()
      } else if (e.key === 'ArrowRight') {
        this.next()
      }
    }
  },
  components: {
    FileThumbnail
  }
}
</script>

<style scoped>
.file-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 64px;
  background: white;
}

.no-border {
  border: none;
}

.full-size {
  height: 100%;
  width: 100%;
}

.full-image {
  max-height: 100%;
  max-width: 100%;
}

.previous-button {
  position: absolute;
  top: calc(50% - 28px);
  left: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.next-button {
  position: absolute;
  top: calc(50% - 28px);
  right: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.doot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: white;
  position: absolute;
  bottom: -2px;
  left: calc(50% - 3px);
}
</style>