<template>
<div>
  <div class="d-flex provisional-row-container">
      <GridRow
        class="flex-grow-0 provisional-row"
        v-bind="$attrs"
        :rowClass="getClassForNotMatchingFilter"
        :entity="entity"
        :virtualGrid="virtualGrid"
        :focusWhenMounted="true"
        :filteredColumnIds="filteredColumnIds"
        @blur="$emit('blur')"
        v-on="$listeners"
      />
  </div>
  <v-menu :value="!entityMatches"
    class="filter-warning-menu"
    top
    offset-y
    :nudge-right="containerScrollLeft + 30"
    max-width="150"
    :transition="false"
    :close-on-click="false"
    :close-on-content-click="false"
    :attach="'.provisional-row-container'"
  >
    <div class="pa-1 d-flex justify-center text-body-2 red white--text no-wrap">
      <v-icon small class="white--text pl-2 pr-2">mdi-filter-variant</v-icon>
      <span data-testid="rowFilteredHint" class="mr-2">{{ $t("virtualGrid.rowFiltered") }}</span>
    </div>
  </v-menu>
</div>
</template>

<script>
import GridRow from '@/components/gridView/GridRow.vue'

export default {
  props: {
    entity: null,
    virtualGrid: null,
    filteredColumnIds: null,
    containerScrollLeft: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    getClassForNotMatchingFilter() {
      return {'not-matching-filter': !this.entityMatches}
    },
    entityMatches() {
      return this.virtualGrid.provisionalEntityMatches(this.entity)
    }
  },
  components: {
    GridRow
  }
}
</script>

<style scoped>
/deep/ .gridRow {
  border-top-style: solid;
}

.no-wrap {
  white-space: nowrap;
}

.provisional-row-container {
  position: relative;
}
</style>
