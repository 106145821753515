<template>
  <v-navigation-drawer
    v-if="renderDrawer"
    class="drawer"
    mini-variant
    app
    dense
    v-model="sideNavigation"
    :mini-variant-width="sideNavigationWidth"
  >
    <div class="d-flex justify-center ma-2 pb-1 pt-1">
      <v-tooltip
        transition="fade-transition"
        nudge-right="10"
        right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn             
          v-on="on"      
          v-bind="attrs" 
          @click="back" 
          icon data-testid="homeButton">
        <v-icon class="">mdi-home-variant-outline</v-icon>
      </v-btn>
      
      </template>
      <span>{{ $t('spaceMenu.home') }}</span>
    </v-tooltip>

    </div>
    <v-list dense nav>
      <v-list-item
        link
        v-if="grids && grids.length"
        color="accent"
        :to="{ name: 'Space', params: { spaceUri: spaceUri } }"
        :exact="false"
        data-testid="gridButton"
      >
      <v-tooltip
        transition="fade-transition"
        nudge-right="10"
        right>
        <template v-slot:activator="{ on, attrs }">

        <v-list-item-icon>
          <SpaceIcon      
            v-on="on"      
            v-bind="attrs"
            :space="space" />
        </v-list-item-icon>
      </template>
      <span>{{ $t('spaceMenu.grids') }}</span>
    </v-tooltip>
        <v-list-item-content />
      </v-list-item>

      <v-list-item
        link
        color="accent"
        :to="{ name: 'FlowPage', params: { spaceUri: spaceUri, scope: 'space' } }"
        data-testid="flowButton"
      >

      <v-tooltip
      transition="fade-transition"
      nudge-right="10"
        right>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-icon>
        <v-icon 
          v-bind="attrs"
          v-on="on"
          style="transform: rotate(-90deg);">
          mdi-sitemap-outline
        </v-icon>
      </v-list-item-icon>
      </template>
      <span>{{ $t('spaceMenu.flows') }}</span>
    </v-tooltip>

      <v-list-item-content />


    </v-list-item>
      <v-list-item
        link
        color="accent"
        :to="{ name: 'BlockEditor', params: { spaceUri: spaceUri } }"
        data-testid="blockButton"
      >

      <v-tooltip
      transition="fade-transition"
      nudge-right="10"
        right>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-icon>
        <v-icon
        v-bind="attrs"
        v-on="on"
        >mdi-file-document-outline</v-icon>
      </v-list-item-icon>
      </template>
      <span>{{ $t('spaceMenu.pages') }}</span>
    </v-tooltip>


      
      <v-list-item-content />
    </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-list dense nav>
        <ShareSpaceDialog
          :disabled="!canAddShare"
          v-model="shareSpaceDialog"
          :spaceUri="spaceUri"
          :spaceName="space.name"
        />
        <v-list-item
        v-if="canAddShare"
        @click="shareSpaceDialog = true"
        data-testid="shareSpaceButton"
        >
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-plus-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content />
      </v-list-item>
      
      <HelpMenu
        v-model="helpMenu"
        right offset-x
      >
      <template v-slot:activator="{on}">
        <v-list-item
          v-on="on"
          data-testid="helpMenuButton"
          color="accent"
        >
          <v-badge dot color="error" :value="showUnreadBadge">
            <v-icon>mdi-lifebuoy</v-icon>
          </v-badge>
        </v-list-item>
      </template>
      </HelpMenu>

        <v-list-item
          :to="{ name: 'GeneralSpaceSettingsPage' }"
          data-testid="spaceSettingsPageButton"
          color="accent">
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import ShareSpaceDialog from '@/components/space/ShareSpaceDialog.vue'
import HelpMenu from '../HelpMenu.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'
import SpaceIcon from './SpaceIcon.vue'

export const SIDE_NAVIGATION_WIDTH = 56

export default {
  props: {
    spaceUri: null
  },
  beforeMount() {
    this.$store.commit('setSideNavigation', !this.$vuetify.breakpoint.mobile)
    this.$store.dispatch('loadUser')
  },
  provide() {
    return {
      spaceUri: this.spaceUri,
    }
  },
  data() {
    return {
      shareSpaceDialog: false,
      helpMenu: false,
      sideNavigationWidth: SIDE_NAVIGATION_WIDTH
    }
  },
  computed: {
    renderDrawer() {
      return this.space && this.$store.getters.userLoggedIn
    },
    canAddShare() {
      return hasPermission(this.space, [PERMISSIONS.addShare])
    },
    sideNavigation: {
      get() {
        return this.$store.state.sideNavigation
      },
      set(newVal) {
        return this.$store.commit('setSideNavigation', newVal)
      }
    },
    hasAvatar() {
      return this.$store.getters.avatarSrc
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    grids() {
      return this.$store.getters.gridsOfSpace(this.spaceUri)
    },
    userId() {
      return this.$store.getters.userId
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    icon() {
      return this.space?.icon ?? 'mdi-table'
    },
    showUnreadBadge() {
      return this.$intercom?.unreadCount != null && this.$intercom?.unreadCount > 0
    },
    alphaMode() {
      return this.$store.getters.alphaMode
    },
  },
  methods: {
    back() {
      this.$router.push({ name: 'Spaces', query: { view: 'all' } })
    },
    openHelpMenu() {
      this.HelpMenu = true
    }
  },
  components: {
    ShareSpaceDialog,
    HelpMenu,
    SpaceIcon
}
}
</script>

<style lang="css" scoped>
.drawer {
  max-height: -webkit-fill-available;
}
</style>
