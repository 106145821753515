import store from '../../store'
import HalObject from '../HalObject'

export default class Transition extends HalObject {

  constructor(data) {
    super(data)
    this.id = data.id
    this.description = data.description
    this.condition = data.condition
    this.source = data.source
    this.target = data.target
  }

  get targetId() {
    return this.target?.split('/')[this.target.split('/').length - 1]
  }

  unconnected() {
    return this.target == null
  }

  async delete() {
    const selfLink = this.getLink('self')
    const fullTransition = await store().dispatch('AGReadTransition', {uri: selfLink })
    return store().dispatch('AGDeleteTransition', {
      transition: fullTransition
    })
  }

  async updateDescription(description) {
    if (description === this.description) {
      return
    }
    const selfLink = this.getLink('self')
    const fullTransition = await store().dispatch('AGReadTransition', {uri: selfLink })
    const patchResponse = await store().dispatch('AGPatchTransition', {
      transition: fullTransition,
      description
    })
    this.description = description
    return patchResponse
  }

  async updateCondition(condition) {
    if (condition === this.condition) {
      return
    }
    const selfLink = this.getLink('self')
    const fullTransition = await store().dispatch('AGReadTransition', {uri: selfLink })
    const patchResponse = await store().dispatch('AGPatchTransition', {
      transition: fullTransition,
      condition
    })
    this.condition = condition
    return patchResponse
  }
}
