import ApiClient from '@/ApiClient/index.js'
import axios from 'axios'
import { config } from '@/plugins/config'

const DEFAULT_CONFIG = {
  unauthenticatedSignedUrlEndpoint: 'https://7zzn3khlt1.execute-api.eu-central-1.amazonaws.com/uploads',
  signedUrlEndpoint: 'https://9t6o4slb79.execute-api.eu-central-1.amazonaws.com/uploads',
  apiEndpoint: 'https://apptiveattachmentsalpha-apptiveattachmentbucket-rg72rfbppffp.s3.eu-central-1.amazonaws.com'
}

function genAttachmentUrl(fileName) {
  const endPoint = config.attachments?.apiEndpoint ?? DEFAULT_CONFIG.apiEndpoint
  return `${endPoint}/${fileName}`
}

export async function uploadFileToS3(name, type, file, unauthenticated = false, progress = () => {}) {
  const unauthenticatedEndpoint = config.attachments.unauthenticatedSignedUrlEndpoint ?? DEFAULT_CONFIG.unauthenticatedSignedUrlEndpoint
  const signedUrlEndpoint = config.attachments.signedUrlEndpoint ?? DEFAULT_CONFIG.signedUrlEndpoint
  //upload to S3
  const endPoint = unauthenticated ? unauthenticatedEndpoint : signedUrlEndpoint
  const uploadUrlResponse = await ApiClient.getUri(endPoint, {
    fileName: name,
    fileType: type
  })
  const uploadUrl = uploadUrlResponse.data.uploadURL
  await axios
    .put(uploadUrl, file, {
      onUploadProgress: progressEvent => {
        progress(
          progressEvent.lengthComputable,
          progressEvent.loaded,
          progressEvent.total
        )
      }
    })
  return genAttachmentUrl(name)
}