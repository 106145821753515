<template>
  <div>
    <SetGridKeyDialog v-model="setGridKeyDialog" :disabled="disableSetKey" :gridUri="gridUri" />
    <v-menu v-model="menu" offset-y >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="black"
          data-testid="showGridMoreMenuButton"
          v-bind="attrs"
          @click.prevent="on.click"
          icon
          x-small
          :disabled="disabled"
        >
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <RenameGridDialog :disabled="disableRename" :gridUri="gridUri" />
        <DeleteGridDialog :disabled="disableDelete" :gridUri="gridUri" @deleteGrid="$emit('deleteGrid')" />
        <SubMenu
          :activatorText="$t('dialogs.more')"
        >
          <MenuItem
            :disabled="disableSetKey"
            icon="mdi-pound"
            :text="this.$t('dialogs.setGridKeyButton')"
            data-testid="setGridKeyActivator"
            @click="setGridKeyDialog = true; menu = false"
          />
          <MenuItem
            @click="setURLClipboard(gridUri)"
            icon="mdi-content-copy"
            :text="$t('dialogs.copyEntryUri')"
            data-testid="copyGridUri"
          />
        </SubMenu>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import RenameGridDialog from '@/components/grid/RenameGridDialog.vue'
import DeleteGridDialog from '@/components/grid/DeleteGridDialog.vue'
import SetGridKeyDialog from '@/components/grid/SetGridKeyDialog.vue'
import MenuItem from '@/components/MenuItem.vue'
import SubMenu from '@/components/SubMenu.vue'

export default {
  name: 'GridMoreMenu',
  props: {
    gridUri: null,
    disabled: null,
    disableDelete: null,
    disableRename: null,
    disableSetKey: null
  },
  data() {
    return {
      menu: false,
      setGridKeyDialog: false
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceIncludesGridWithUri(this.gridUri)
    }
  },
  methods: {
    open() {
      this.menu = true
    },
    setURLClipboard(value) {
      navigator.clipboard.writeText(`${window.location.origin}${value}`)
    }
  },
  components: {
    RenameGridDialog,
    DeleteGridDialog,
    SetGridKeyDialog,
    MenuItem,
    SubMenu
  }
}
</script>

<style lang="css"></style>
