<template>
    <v-dialog v-model="showDialog" persistent max-width="400px">
      <v-sheet class="pa-6 text-center" color="grey lighten-5">
        <div class="text-h5 mb-2">{{ $t("login.title")}}</div>
        <div class="">{{ $t("login.subtitle")}}</div>
          <v-form ref="form" class="mt-5" v-model="valid" lazy-validation>
            <v-text-field
              solo flat
              v-model="login.username"
              :rules="rules.username"
              @keyup.enter="focusNextField('password')" 
              :label="$t('login.username')"
              required

            ></v-text-field>
            <v-text-field
            solo flat
            v-model="login.password"
            ref="password"
            :rules="rules.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="toggleShowPassword"
            @keyup.enter="validate"
            :label="$t('login.password')"
            required
          ></v-text-field>
          </v-form>
          <div class="mt-2 text-red mb-2 red--text">{{ errorMessage }}</div>
          <v-btn :loading=loading block large color="primary darken-1" @click="validate"> <span class="bold">Login</span> </v-btn>
      </v-sheet>
    </v-dialog>
  </template>
  
  <script>
  import ApiClient from '@/ApiClient'
  import { btoaUtf8Hack } from '@/utils/jsUtils'
  import axios from 'axios'

  export default {
    props: ['value', 'resource'], // Use `value` prop for v-model binding
    data: () => ({
      valid: false,
      loading: false,
      showPassword: false,
      login: {
        username: '',
        password: '',
      },
      errorMessage: null,
    }),
    computed: {
      rules() {
        return { 
          username: [
            v => !!v || this.$t('login.rule.username'),
          ],
          password: [
           v => !!v || this.$t('login.rule.password'),
          ]
        }
      },
      showDialog: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
      currentAuth() {
        return axios.defaults.headers
      }
    },
    methods: {
        async validate() {
            if (this.$refs.form.validate()) {
                // set username and passwaor as basic auth 
                this.loading = true
                const valid = await this.check()
                if( valid ){
                    ApiClient.setBasicAuth(this.login.username, this.login.password)
                    this.$emit('credentialsProvided')
                    this.closeDialog()
                    this.errorMessage = ''
                }
                else {
                    this.errorMessage = this.$t('login.error')
                }
                this.loading = false
            }
        },

        async check() {
            try {
            // Here the utf8 hack only needs to be applied to the username
            // as Axios is already applying this transformation to the password before calling btoa
            const response = await axios.get(this.resource, {
                auth: { 
                    username: btoaUtf8Hack(this.login.username),
                    password: this.login.password
                },
            })
            let responseOK = response && response.status === 200
            return responseOK
          } catch (error) {
              return false
          }
        },
        focusNextField(nextFieldRef) {
          this.$refs[nextFieldRef].focus()
        },
        closeDialog() {
            this.$emit('input', false)
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword
        }
    },
}
  </script>
  