<template>
  <v-menu offset-y v-model="dialog" :close-on-content-click="false" max-width="480">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="dialogButton"></slot>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <span v-html="title" />
      </v-card-title>
      <v-card-subtitle >
        {{ $t("virtualGrid.qrCode.dialog.subtitle") }}
      </v-card-subtitle>
      <v-card-text v-if="!loading">
        <div class="text-subtitle-1">{{$t('virtualGrid.shareDialog.shared.title')}}</div>
        <div class="mb-3">{{$t('virtualGrid.shareDialog.shared.explanation')}}</div>
        <div class="mb-3">{{$t('virtualGrid.shareDialog.shared.addForm')}}</div>

        <v-select dense solo
          :placeholder="$t('virtualGrid.shareEntity.selectForm')"
          v-model="formLink"
          :items="formLinks"
          :append-icon="formLink ? 'mdi-close' : ''"
          @click:append="formLink = null"
        >
          <template slot="item" slot-scope="data">
                    <v-list-item-content>
                      <v-list-item-title >{{ data.item.formName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.name }}</v-list-item-subtitle>
                    </v-list-item-content>
          </template>
          <template slot="selection" slot-scope="data">
            {{ data.item.formName }}
          </template>
        </v-select>

        <SViewShare v-if="share" :virtualGridUri="virtualGridUri" :statefulViewUri="statefulViewUri"></SViewShare>
        <SViewExternalHookList :virtualGridUri="virtualGridUri" :statefulViewUri="statefulViewUri"></SViewExternalHookList>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { sharedLinksMethods } from '@/mixins/sharedLinksMethods.js'
import { base64EncodeUrl } from '@/utils/base64Url.js'

import statefulViewTypes from '../../constants/statefulViewTypes'
import SViewShare from './SViewShare.vue'
import SViewExternalHookList from '@/components/gridView/SViewExternalHookList.vue'

export default {
  mixins: [sharedLinksMethods],
  props: {
    virtualGridUri: null,
    statefulViewUri: null,
  },
  data() {
    return {
      dialog: false,
      share: undefined,
      shareLoading: false,
      loading: false,
      links: [],
      uri: null
    }
  },
  watch: {
    dialog: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.loading = true
          try {
            await this.loadShares()
            await this.loadFormLinks()
          } finally {
            this.loading = false
          }
        }
      }
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    title() {
      return this.$t('virtualGrid.qrCode.dialog.title', {viewName: this.statefulView.name})
    },
    link() {
      return `${window.location.origin}/#/view/${base64EncodeUrl(this.statefulView.uri)}`
    },
    isGridView() {
      return this.statefulView.type === statefulViewTypes.spreadsheet.name
    },
    formLinks() {
      return this.links
    },
    grid() {
      return this.$store.getters.gridContainingVirtualGridWithUri(this.virtualGridUri)
    },
    formLink: {
      get() {
        // This is the getter function
        return this.links.find(link => link._links.self.href === this.statefulView.formLink?.uri)
      },
      set(newVal) {
        if(newVal){
          this.statefulView.setFormLink({
            'uri': newVal._links.self.href,
            'redirectUri' : newVal.redirectUri
          })
        }
        else {
          this.statefulView.setFormLink(null)
        }
      }
    }
  },
  methods: {

    async loadShares() {
      const shares = await this.$store.dispatch('AGListViewSharesOperation', {statefulView: this.statefulView})
      this.share = shares[0]?._links.self.href
    },
    addLink() {
      this.uri = this.virtualGridUri
    },
    openLink() {
      var win = window.open(this.link, '_blank')
      win.focus()
    },
    copyLink() {
      this.$refs.linkInput.select()
      try {
        document.execCommand('copy')
      } catch (error) {
        this.$apptiveErrorReporter.captureException(error)
        alert('Unable to copy')
      }
    },
    async loadFormLinks() {
      this.loading = true
      try {
        const allLinks = await this.grid.loadLinks()
        const editLinks = allLinks.filter(link => link.type === 'addEntity')
        this.links = editLinks
      } finally {
        this.loading = false
      }
    },
  },
  components: {
    SViewShare,
    SViewExternalHookList
  }
}
</script>

<style scoped>
.fit-parent-img {
  width: auto;
  height: 40px;
}

.one-line {
  overflow: auto;
  overflow-wrap: normal;
}

.full-width {
  outline: none;
  width: 100%;
}
</style>
