<template>
  <draggable
    v-bind="$attrs"
    v-on="$listeners"
    :delay="100"
    :delayOnTouchOnly="true"
    :touchStartThreshold="5"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
}
}
</script>