<template>
  <v-menu
    v-model="modal"
    :close-on-content-click="false"
    top
    offset-y
    rounded="lg"
    max-width="450"
    :disabled="!paywallActive"
  >

    <template v-slot:activator="{ on }">
      <v-sheet class="ma-0 pa-0" color="rgba(0, 0, 0, 0)" v-on="paywallActive ? on : null">
        <v-sheet class="ma-0 pa-0" color="rgba(0, 0, 0, 0)" :class="paywallActive ? 'disabled' : ''">
          <slot />
        </v-sheet>
      </v-sheet>

    </template>
    <v-sheet rounded="lg">
      <div class="d-flex">
        <v-icon color="purple darken-1" class="ml-4">mdi-arrow-up-circle-outline</v-icon>
        <div class="ma-4">
          <v-list-item-title class="font-weight-medium">{{ $t("paywall.upgradeNowLong") }}</v-list-item-title>
          <v-list-item-subtitle v-if="feature">{{ fetaureLabel }}</v-list-item-subtitle>
        </div>
      </div>
      <v-divider class=""></v-divider>
      <div class="d-flex justify-center pa-3">
        <PaywallButton />
      </div>
    </v-sheet>

  </v-menu>
</template>

<script>
import PaywallButton from '@/components/paywall/PaywallButton.vue'
import paywall from '@/mixins/paywall.js'

export default {
  mixins: [ paywall ],
  props: [ 'feature' ],
  data() {
    return {
      modal: false,
    }
  },
  watch: {
    modal: {
      immediate: true,
      async handler(val) {
        if(val === true && this.paywallActive === true){
          this.$intercom?.trackEvent( 'Paywall' , { feature: this.feature })
        }
      }
    }
  },
  computed: {
    fetaureLabel() {
      return this.$t(`paywall.${this.feature}.shortHint`) || this.feature
    }
  },
  components: {
    PaywallButton
  }
}
</script>

<style lang="css" scoped>
.disabled {
  opacity: 0.77;
  pointer-events: none;
  cursor: help;
}
</style>
