<template>
  <BaseNodeComponent v-model="externalModel" v-bind="$attrs" @blur="$emit('blur')">
    <TemplateMapper
      v-model="externalModel"
      @blur="$emit('blur')"
      ref="templateMapper"
      v-bind="$attrs"
    >
      <v-checkbox
        v-model="innerValue"
        v-bind="$attrs"
        v-on="$listeners"
        @change="onChange"
        hide-details
      />
    </TemplateMapper>
  </BaseNodeComponent>
</template>

<script>
import { createHolder } from '@/apptivescript/model'
import BaseNodeComponent from '@/components/flow/BaseNodeComponent.vue'
import TemplateMapper from '@/components/flow/TemplateMapper.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
    mixins: [externalModel],
    data() {
      return {
        innerValue: true
      }
    },
    watch: {
      externalModel: {
        immediate: true,
        handler(newVal) {
          this.innerValue = newVal?.value
        }
      }
    },
    methods: {
      onChange(newVal) {
        this.externalModel = createHolder(newVal)
        this.$emit('blur')
      },
      insert(text) {
        this.$refs.templateMapper.insert(text)
      }
    },
    components: {
      BaseNodeComponent,
      TemplateMapper
    }
}
</script>