<template>
  <div :style="pageStyle" class="flow-editor-page d-flex overflow-hidden">
    <LoadingOverlay :show="loading" style="z-index: 1"/>
    <div ref="scrollContainer" class=" overflow-scroll" :style="pageStyle" @wheel="wheelHandler">
      <div
        v-if="flow"
        ref="canvas"
        @pointerdown="onCanvasPointerDown"
        @pointermove="onMouseMove"
        @pointerup="onMouseUp"
        @pointerleave="onMouseUp"
        @dragover.prevent
        @drop.prevent="onDrop"
        class="dot-grid d-flex flex-row canvas"
        :style="zoom"
      >
        <template v-for="node in nodes">
          <FlowNode
            :key="`node-${node.id}`"
            @deleteNode="deleteNode"
            @pointerdown.native="(e) => onNodePointerDown(e, node)"
            @showMenu="() => showNodeMenu(node)"
            @indicatorClicked="() => showStepInspector(node)"
            @onNodeIde="() => onNodeIde(node)"
            @mouseenter.native="handleNodeMouseEnter(node)"
            @mouseleave.native="highlightConnectors = undefined"
            :flow="flow"
            :node="node"
            :size="sizeOfNode(node.id)"
            :clickBlocked="canvasState !== CANVAS_STATES.MOUSEDOWN_NODE"
          />
          <FlowTransition
            v-for="transition in node.outgoing"
            :flow="flow"
            :fromNode="node"
            :transition="transition"
            :key="transitionKey(node, transition)"
            :nodeSize="nodeSize"
            :highlightConnectors="highlightConnectors === node.id"
            @endConnectorPointerDown="onEndConnectorPointerDown"
            @delete="() => deleteTransition(transition)"
          />
        </template>
      </div>
    </div>
    <FlowEditorPanel v-if="!panelClosed" @close="closePanel">
      <FlowInstanceStepInspector
        v-if="panelState === PANEL_STATES.STEP_INSPECTOR"
        :flow="flow"
        :node="menuNode"
      />
      <FlowNodeEditor
        v-if="panelState === PANEL_STATES.NODE_CONFIGURATION"
        :space="space"
        :flow="flow"
        :node="menuNode"
        @deleteNode="deleteNode"
      />
      <FlowNodePicker
        v-if="panelState === PANEL_STATES.NODE_PICKER"
        @addNode="addNode"
      />
    </FlowEditorPanel>
    <v-btn
      :disabled="!canAddNode"
      @click="showNodePicker"
      class="mx-2 addNodeButton"
      data-testid="showNodePickerButton"
      fab
      width="48"
      height="48"
      color="primary"
      >
      <v-icon large dark>
        mdi-plus
      </v-icon>
    </v-btn>
    <div class="flowControls mx-2">
      <v-alert transition="slide-y-reverse-transition" dense text v-show="showTestRunSnack" type="success">
      {{ $t("flow.editor.testRunSnack") }}
    </v-alert>
      <v-sheet width="110" class="zoomControl d-flex justify-space-between align-center pl-1 pr-1 mb-3">
        <v-btn color="accent" x-small icon @click="zoomOffset(-0.1)"><v-icon dense>mdi-minus</v-icon></v-btn>
        <span class="subtitle-2">{{ zoomLevelDisplayString }}  </span>
        <v-btn color="accent" x-small icon @click="() => zoomOffset(0.1)"><v-icon dense>mdi-plus</v-icon></v-btn>
      </v-sheet >
      <FlowTestRunner
        :flow="flow"
        />
    </div>
  </div>
</template>

<script>
import FlowNode from '@/components/flow/FlowNode.vue'
import FlowTransition from '@/components/flow/FlowTransition.vue'
import flowLayoutBus from '@/components/flow/flowLayoutBus.js'
import FlowNodePicker from '@/components/flow/FlowNodePicker.vue'
import FlowInstanceStepInspector from '@/components/flow/FlowInstanceStepInspector.vue'
import FlowNodeEditor from '@/components/flow/FlowNodeEditor.vue'
import FlowEditorPanel from './FlowEditorPanel.vue'
import FlowTestRunner from '@/components/flow/FlowTestRunner.vue'
import { parseExpression } from '@/constants/expressions.js'
import { AGStepOutput } from '@/constants/expressions/index.js'

import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import LoadingOverlay from '../LoadingOverlay.vue'

const TABS_HEIGHT = 48
const MIN_ZOOM = 0.6
const MAX_ZOOM = 2

export default {
  props: {
    flowUri: null,
    spaceUri: null
  },
  data() {
    return {
      loading: true,
      selectedNode: null,
      nodeSize: 80,
      startX: undefined,
      startY: undefined,
      currentZoom: 1,
      scrollPositionInitialised: false,
      emptyFlowInitialised: false,
      PANEL_STATES: {
        HIDDEN: 0,
        STEP_INSPECTOR: 1,
        NODE_CONFIGURATION: 2,
        NODE_PICKER: 3
      },
      CANVAS_STATES: {
        IDLE: 'IDLE',
        MOUSEDOWN_NODE: 'MOUSEDOWN_NODE',
        MOUSEDOWN_CANVAS: 'MOUSEDOWN_CANVAS',
        DRAGGING_CANVAS: 'DRAGGING_CANVAS',
        DRAGGING_NODE: 'DRAGGING_NODE',
        DRAGGING_TRANSITION: 'DRAGGING_TRANSITION',
      },
      panelState: 0,
      canvasState: 0,
      menuX: 0,
      menuY: 0,
      menuNode: undefined,
      layoutSaveTimeout: undefined,
      showTestRunSnack: false,
      highlightedNodeSize: 95,
      highlightedNode: undefined,
      highlightConnectors: undefined
    }
  },
  mounted() {
    document.addEventListener('highlightExpression', this.handleHighlightEvent)

  },
  beforeDestroy() {
    document.removeEventListener('highlightExpression', this.handleHighlightEvent)
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    pageStyle() {
      return {
        height: `calc(100vh - ${TABS_HEIGHT}px - ${this.$vuetify.application.top}px)`,
        position: 'relative'
      }
    },
    panelClosed() {
      return this.panelState === this.PANEL_STATES.HIDDEN
    },
    nodes() {
      return this.flow.nodes
    },
    flow() {
      return this.$store.getters.flowWithUri(this.flowUri)
    },
    zoom() {
      const scrollContainer = this.$refs.scrollContainer
      return {
        transform: `scale(${this.currentZoom})`,
        'transform-origin': `${scrollContainer?.clientWidth ?? 2 / 2}px ${scrollContainer?.clientHeight ?? 2 / 2}px`
      }
    },
    zoomLevelDisplayString() {
      return `${parseInt(this.currentZoom * 100)} %`
    },
    trigger() {
      return this.flow?.trigger
    },
    canAddNode() {
      return hasPermission(this.flow, [PERMISSIONS.addNode])
    }
  },
  watch: {
    trigger(newVal, oldVal) {
      if (newVal != null && oldVal != null && !this.flow.testInstance) {
        this.showTestRunSnack = true
        setTimeout(() => {
          this.showTestRunSnack = false
        }, 3000)
      }
    },
    flow: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.initPositions()
        }, 0)
      }
    }
  },
  methods: {
    async initPositions() {
      if (this.flow == null || this.emptyFlowInitialised) {
        return
      }
      this.loading = true
      await this.flow?.nodesLoadedPromise
      if (!this.scrollPositionInitialised) {
        const scrollContainer = this.$refs.scrollContainer
        if (this.flow?.nodes.some(node => node.hasDefaultLayout())) {
          this.autoLayout()
        } else {
          const center = this.flow.canvasCenter
          this.setCanvasScroll(
            center ? center.x : scrollContainer.scrollWidth / 2,
            center ? center.y : scrollContainer.scrollHeight / 2
          )
        }
      }
      
      if (this.emptyFlowInitialised) {
        this.loading = false
        return
      }
      if (this.flow?.nodes.length === 0) {
        await this.$nextTick()
        this.panelState = this.PANEL_STATES.NODE_PICKER
      }
      this.emptyFlowInitialised = true
      this.loading = false
    },
    closePanel() {
      this.panelState = this.PANEL_STATES.HIDDEN
    },
    moveCanvasScroll(offsetX, offsetY) {
      const scrollContainer = this.$refs.scrollContainer
      this.setCanvasScroll(scrollContainer.scrollLeft + offsetX, scrollContainer.scrollTop + offsetY)
    },
    setCanvasScroll(x, y) {
      const scrollContainer = this.$refs.scrollContainer
      scrollContainer.scrollLeft = x
      scrollContainer.scrollTop = y
      this.flow.canvasCenter = {x, y}
    },
    showNodePicker() {
      this.panelState = this.PANEL_STATES.NODE_PICKER
    },
    showNodeMenu(node) {
      if (this.canvasState !== this.CANVAS_STATES.MOUSEDOWN_NODE && this.canvasState !== this.CANVAS_STATES.IDLE) {
        return
      }
      this.canvasState = this.CANVAS_STATES.IDLE
      this.menuNode = node
      const scrollContainer = this.$refs.scrollContainer
      this.menuX = (node.layout.position.x + 80) - scrollContainer.scrollLeft
      this.menuY = node.layout.position.y - scrollContainer.scrollTop
      this.panelState = this.PANEL_STATES.NODE_CONFIGURATION
      node.loadSetupView(this.space)
    },
    showStepInspector(node) {
      if (this.canvasState !== this.CANVAS_STATES.MOUSEDOWN_NODE) {
        return
      }
      this.canvasState = this.CANVAS_STATES.IDLE
      this.menuNode = node
      const scrollContainer = this.$refs.scrollContainer
      this.menuX = node.layout.position.x + 80 - scrollContainer.scrollLeft
      this.menuY = node.layout.position.y - scrollContainer.scrollTop
      this.panelState = this.PANEL_STATES.STEP_INSPECTOR
    },
    wheelHandler(e) {
      this.scrollPositionInitialised = true

      // Save canvas position if it has not moved since 250ms
      clearTimeout(this.layoutSaveTimeout)
      this.layoutSaveTimeout = setTimeout(() => {
        const scrollContainer = this.$refs.scrollContainer
        this.flow.canvasCenter = {x: scrollContainer.scrollLeft, y: scrollContainer.scrollTop}
      }, 250)

      if (e.ctrlKey) {
        e.preventDefault()
        this.zoomOffset(e.deltaY * -0.005)
      }
    },
    onNodePointerDown(event, node) {
      // Dont start dragging on right click:
      if (event.buttons >= 2) {
        return
      }
      this.canvasState = this.CANVAS_STATES.MOUSEDOWN_NODE
      this.selectedNode = node
      this.startX = event.clientX
      this.startY = event.clientY
    },
    onNodeIde() {
      this.canvasState = this.CANVAS_STATES.IDLE
      this.selectedNode = null
    },
    onMouseMove(event) {
      if (this.canvasState === this.CANVAS_STATES.MOUSEDOWN_NODE) {
        this.canvasState = this.CANVAS_STATES.DRAGGING_NODE
      }
      if (this.canvasState === this.CANVAS_STATES.DRAGGING_NODE && this.selectedNode != null) {
        this.selectedNode.layout = {
          position: {
            x: this.selectedNode.layout.position.x + ((event.clientX - this.startX) / this.currentZoom),
            y: this.selectedNode.layout.position.y + ((event.clientY - this.startY) / this.currentZoom)
          }
        }
        const scrollContainer = this.$refs.scrollContainer
        this.menuX = this.selectedNode.layout.position.x + 80 - scrollContainer.scrollLeft
        this.menuY = this.selectedNode.layout.position.y - scrollContainer.scrollTop
        this.startX = event.clientX
        this.startY = event.clientY
      } else if(this.canvasState === this.CANVAS_STATES.DRAGGING_CANVAS) {
        this.scrollPositionInitialised = true
        this.moveCanvasScroll(
          -((event.clientX - this.startX) / this.currentZoom),
          -((event.clientY - this.startY) / this.currentZoom)
        )
        this.startX = event.clientX
        this.startY = event.clientY
      }
    },
    onMouseUp() {
      if (this.canvasState === this.CANVAS_STATES.MOUSEDOWN_NODE) {
        return
      }
      this.canvasState = this.CANVAS_STATES.IDLE
      this.selectedNode?.saveLayout()
      this.selectedNode = null
    },
    transitionKey(node, transition) {
      const transitionIndex = node.outgoing.indexOf(transition)
      return `from-${node.id}-to-${transition.targetId}-index-${transitionIndex}`
    },
    onDrop(event) {
      const type = event.dataTransfer.getData('nodeType')
      const eventType = event.dataTransfer.getData('eventType')
      const nodeName = event.dataTransfer.getData('nodeName')
      const tag = event.dataTransfer.getData('typeOverride')

      // // get position
      const posX = event.offsetX - this.nodeSize / 2
      const posY = event.offsetY - this.nodeSize / 2
      const layout = {
        position: {
          x: posX,
          y: posY
        }
      }
      this.flow.addNodeOf(type, layout, eventType, nodeName, tag, this.spaceUri)
    },
    onCanvasPointerDown(event) {
      if (this.canvasState !== this.CANVAS_STATES.IDLE) {
        return
      }
      this.canvasState = this.CANVAS_STATES.DRAGGING_CANVAS
      this.startX = event.clientX
      this.startY = event.clientY
      flowLayoutBus.$emit('flow-node-deselect')
    },
    async deleteNode(node) {
      let id = node.id
      await this.flow.deleteNode(node)
      this.$nextTick(() => {
        flowLayoutBus.$emit('flow-draw-transition', { nodeId: id })
        this.closePanel()
      })
    },
    deleteTransition(transition) {
      this.flow.deleteTransition(transition)
    },
    zoomOffset(offset) {
      const newZoom = this.currentZoom + offset
      this.currentZoom = Math.min(Math.max(MIN_ZOOM, newZoom), MAX_ZOOM)
    },
    viewportCenterLayout() {
      const scrollContainer = this.$refs.scrollContainer
      const canvas = this.$refs.canvas
      const rect = canvas.getBoundingClientRect()
      return {
        position: {
          x: (scrollContainer.clientWidth / 2) - rect.left,
          y: (scrollContainer.clientHeight / 2) - rect.top,
        }
      }
    },
    addNode(node) {
      const layout = this.viewportCenterLayout()
       this.flow.addNodeOf(node.type, layout, node.eventType, node.description, node.typeOverride, this.spaceUri)
    },
    async autoLayout() {
      const scrollContainer = this.$refs.scrollContainer
      const canvas = this.$refs.canvas
      this.setCanvasScroll(
        scrollContainer.scrollWidth / 2,
        scrollContainer.scrollHeight / 2
      )
      await this.$nextTick()
      const rect = canvas.getBoundingClientRect()
      this.flow.autoLayout(-(rect.left - 200), (scrollContainer.clientHeight / 2) - rect.top)
    },
    onEndConnectorPointerDown() {
      this.canvasState = this.CANVAS_STATES.DRAGGING_TRANSITION
    },
    sizeOfNode(nodeId) {
      return this.highlightedNode === nodeId ? this.highlightedNodeSize : this.nodeSize
    },
    handleHighlightEvent(event) {
      if (event?.detail == null) {
        this.highlightedNode = undefined
        return
      }

      const expression = parseExpression(event.detail)
      if (expression instanceof AGStepOutput) {
        this.highlightedNode = expression.nodeId
      }
    },
    handleNodeMouseEnter(node) {
      this.highlightConnectors = node.unconnected() ? node.id : undefined
    }
  },
  components: {
    FlowNode,
    FlowTransition,
    FlowNodePicker,
    FlowInstanceStepInspector,
    FlowNodeEditor,
    FlowEditorPanel,
    FlowTestRunner,
    LoadingOverlay
}
}
</script>

<style lang="css" scoped>
.canvas {
  height: 4000px;
  width: 4000px;
  position: relative;
  cursor: move;
}
.addNodeButton {
  position: absolute;
  right: calc(50% - 50px);
  bottom: 10px;
}
.flowControls {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.zoomControl {
  background-color: white;
  border: solid;
  border-width: 1px;
  border-color: #d4d4d4;
  border-radius: 8px;
}

.overflow-scroll {
  overflow: scroll;
}
.overflow-hidden {
  overflow: hidden;
}
.dot-grid {
  background-color: rgb(255, 255, 255);
  background-image: radial-gradient(#d4d4d4 1px, transparent 1px); /* create a radial gradient with dots */
  background-size: 26px 26px; /* set the size of the dots */
  background-repeat: repeat;
  border: none;

}
</style>
