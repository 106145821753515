export default {
  inject: {
    getCurrentEntityPage: {
      default: () => () => undefined
    }
  },
  methods: {
    reloadVirtualGrid() {
      return this.virtualGrid.reload(this.getCurrentEntityPage())
    }
  }
}