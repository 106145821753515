import StatefulView from './StatefulView'
import { columnTypes } from '../../constants/columnTypes'
import store from '../store'
import { parseFilter } from '@/filter/filter.js'

const STATE_FIELD_ID = 'stateFieldId'
const MAP_CENTER = 'mapCenter'
const ZOOM_LEVEL = 'zoomLevel'
const MAX_MARKERS = 200

export default class MapView extends StatefulView {
  constructor(data) {
    super(data)
  }

  reload() {
    return store().dispatch('AGReadStatefulViewOperation', this.uri)
  }

  get stateFieldId() {
    const fieldId = this.properties[STATE_FIELD_ID]
    if (this.parentGrid?.fields.some(field => field.id === fieldId && field.columnType.name === columnTypes.geolocation.name)) {
      return fieldId
    } else {
      return undefined
    }
  }

  setStateFieldId(fieldId) {
    return store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      properties: {
        ...this.properties,
        [STATE_FIELD_ID]: fieldId
      }
    })
      .then(() => this.reload())
  }

  get initialMapCenter() {
    return this.properties[MAP_CENTER]
  }

  get initialZoomLevel() {
    return this.properties[ZOOM_LEVEL]
  }

  saveMapPosition(mapCenter, zoomLevel) {
    return store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      properties: {
        ...this.properties,
        [MAP_CENTER]: mapCenter,
        [ZOOM_LEVEL]: zoomLevel
      }
    })
  }

  entityParams(center) {
    if (!this.stateFieldId) {
      return undefined
    }
    return {
      filter: parseFilter({
        [ this.stateFieldId ]: { $isEmpty: false }
      }),
      sorting: {
        [ this.stateFieldId ]: {
          $distanceTo: {
            location: {
              lat: center.lat(),
              lon: center.lng()
            }
          },
          $order: 'ascending'
        }
      },
      pageSize: MAX_MARKERS
    }
  }

  mapModel() {
    if (this.stateFieldId == null) {
      return
    }
    const stateFieldIndex = this.parentGrid.fields.findIndex(field => field.id === this.stateFieldId)
    return this.parentGrid.entities
      .filter(entity => entity.fields[stateFieldIndex] != null)
      .map(entity => {
        const model = entity.fields[stateFieldIndex]
        return {
          position: {
            lat: model.lat,
            lng: model.lon,
          },
          entity: {
            titleFieldIndex: 0,
            stateFieldIndex: stateFieldIndex,
            entity,
            attributes: entity.fields.map((entityField, index) => {
              return {
                key: this.parentGrid.fields[index].name,
                value: this.parentGrid.displayFormat(entityField, index),
                fieldValue: entityField,
                property: this.parentGrid.fields[index].columnType
              }
            })
          }
        }
      })
  }
}