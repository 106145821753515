<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode">
    <template v-slot:default>
    <div
      ref="contenteditable"
      :contenteditable="contenteditable"
      class="paragraph-text-field"
      :placeholder="$t('blocks.emptyParagraph')"
      @blur="updateBlock"
      @keydown.enter.prevent="addBlockAfter"
      @keydown.backspace="deleteBlock"
    >{{ text }}</div>
  </template>

    <template v-slot:right-controls>
      <ProofRead v-if="developerMode" :originalText="text" @apply="(newText) => text = newText"></ProofRead>
    </template>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import ProofRead from '@/components/block/ProofRead.vue'

  export default {
    props: {
      blockUri: {
        type: String
      },
      editorMode: {
        type: Boolean,
        default: () => false
      }
    },
    computed: {
      block() {
        return this.$store.getters.blockWithUri(this.blockUri)
      },
      contenteditable() {
        return this.editorMode ? 'plaintext-only' : 'false'
      },
      text: {
        get() {
          return this.block.text
        },
        set(newVal) {
          this.block.text = newVal
        }
      },
      developerMode() {
        return this.$store.getters.developerMode
      }
    },
    watch: {
      text: {
        immediate: true,
        async handler(newVal) {
          await this.$nextTick()
          this.$refs.contenteditable.textContent = newVal
        }
      }
    },
    methods: {
      getCursorPosition(element) {
        let position = 0
        const selection = window.getSelection()
        
        if (selection.rangeCount !== 0) {
          const range = selection.getRangeAt(0)
          const preCaretRange = range.cloneRange()
          preCaretRange.selectNodeContents(element)
          preCaretRange.setEnd(range.endContainer, range.endOffset)
          position = preCaretRange.toString().length
        }
        return position
      },
      updateBlock() {
        this.text = this.$refs.contenteditable.textContent
        this.$emit('updateBlock')
      },
      addBlockAfter() {
        const position = this.getCursorPosition(this.$refs.contenteditable)
        const text = this.$refs.contenteditable.textContent
        if (position === text.length) {
          this.$emit('addBlockAfter')
        } else {
          this.text = text.slice(0, position)
          this.$emit('updateBlock')
          this.$emit('addParagraphBlock', text.slice(position))
        }
      },
      deleteBlock() {
        if (!this.$refs.contenteditable.textContent) {
          this.$emit('delete')
        }
      },
      focus() {
        const el = this.$refs.contenteditable
        const selection = window.getSelection()
        const range = document.createRange()
        selection.removeAllRanges()
        range.selectNodeContents(el)
        range.collapse(false)
        selection.addRange(range)
        el.focus()
      }
    },
    components: {
      BaseBlock,
      ProofRead
    }
  }
</script>

<style lang="scss" scoped>
.paragraph-text-field {
  outline: unset;
  width: 100%;
  overflow: hidden;
  height: fit-content;
}

[contenteditable=plaintext-only]:empty:not(:focus):before{
  content: attr(placeholder);
  pointer-events: none;
  color: lightgray;
  display: block; /* For Firefox */
}

.append-menu {
  position: relative;
  left: 100px;
}
</style>
