<template>
  <BaseFormInput v-bind="$props">
     <UserSelect
      class="pt-1"
      v-model="inputValue"
      :field="field"
      :isCollection="isCollection"
      :label="label"
      :rules="[rules.required, rules.schema]"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      small-chips
      clearable
      :disabled="disabled"
      v-bind="fieldStyle"
    />
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import UserSelect from '@/components/gridView/UserSelect.vue'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  mixins: [formFields],
  props: ['field'],
  data() {
    return {
      filter: ''
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isCollection() {
      return this.fieldType.jsonSchema.type === 'array'
    }
  },
  components: {
    UserSelect,
    BaseFormInput
  }
}
</script>

<style lang="css" scoped>
.outlined >>> .v-input__slot {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  background-color: white !important;
}
</style>
