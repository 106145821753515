<template>
<div
  class="d-flex align-center relative"
  :class="{'control-padding': showControls}"
  @mouseenter="showControls = true"
  @mouseleave="showControls = false"
>
  <input
    ref="input"
    step="any"
    class="px-2 grey--text text--darken-3"
    v-model="model"
    :readonly="readonly"
    @keypress="filterAllButDecimals"
    @keyup.exact.enter="nextLine"
    @focus="$emit('focus')"
    @select="$emit('focus')"
    @blur="finishedEditing()"
  />
  <LocationPicker
    :readonly="readonly"
    :value="value"
    @input="locationPicked"
    :mapOptions="mapOptions"
  >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="control-button mx-1"
          :class="{invisible: !showControls}"
          v-bind="attrs"
          v-on="on"
          icon
          x-small
          color="accent"
          data-testid="locationPickerActivator"
        >
          <v-icon small>mdi-map</v-icon>
        </v-btn>
    </template>
  </LocationPicker>
</div>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import inputValidation from '@/utils/inputValidation.js'
import LocationPicker from '@/components/map/LocationPicker.vue'

const DISPLAY_LAT = 0
const DISPLAY_LON = 1

export default {
  mixins: [gridCells],
  data() {
    return {
      showControls: false
    }
  },
  computed: {
    model: {
      get() {
        if (!this.value) {
          return undefined
        }
        // Displayed coordinates are inverted compared to the API format
        // to allow copy pasting from google maps
        return `${this.value.lat},${this.value.lon}`
      },
      set(newVal) {
        if (!newVal || typeof newVal === 'object') {
          this.$emit('input', newVal)
        } else {
          const coordinatesRegexp = new RegExp(/^-?\d+(\.\d+)?,\s?-?\d+(\.\d+)?$/)
          if (!newVal || !coordinatesRegexp.test(newVal)) {
            return
          }
          const parsedValue = newVal.split(',').map(string => parseFloat(string))
          // Displayed coordinates are inverted compared to the API format
          // to allow copy pasting from google maps
          this.$emit('input', {lat: parsedValue[DISPLAY_LAT], lon: parsedValue[DISPLAY_LON]})
        }
      }
    },
    mapOptions() {
      return {
        zoom: 14,
        center: {
          lat: this.model ? parseFloat(this.value.lat) : 50.941248064035904,
          lng: this.model ? parseFloat(this.value.lon) : 6.958303812540371
        },
        mapTypeControl: false,
        streetViewControl: false,
        styles: [{
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }]
      }
    }
  },
  methods: {
    finishedEditing() {
      this.$emit('blur')
    },
    filterAllButDecimals(event) {
      return inputValidation.filterAllButDecimals(event)
    },
    locationPicked(value) {
      this.model = value
      this.finishedEditing()
    }
  },
  components: {
    LocationPicker
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.relative {
  position: relative
}

.control-button {
  position: absolute;
  right: 0;
}

.control-padding {
  padding-right: 32px;
}

.invisible {
  opacity: 0;
}
</style>
