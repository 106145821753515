<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <MenuButton
        v-on="on"
        icon="mdi-cog"
        :text="$t('kanban.settings.menuButton')"
      />
    </template>
    <v-card>
      <KanbanStateSelection :virtualGrid="virtualGrid" v-model="externalModel">
        <CardFieldsSettings class="px-2 pb-4" :virtualGrid="virtualGrid" :statefulViewUri="statefulViewUri" :disabled="disabled" ></CardFieldsSettings>  
      </KanbanStateSelection>
    </v-card>
  </v-menu>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'
import KanbanStateSelection from '@/components/kanbanView/KanbanStateSelection.vue'
import MenuButton from '@/components/MenuButton.vue'
import CardFieldsSettings from '@/components/CardFieldsSettings.vue'

export default {
  mixins: [externalModel],
  props: ['virtualGrid', 'statefulViewUri', 'disabled'],
  components: {
    KanbanStateSelection,
    MenuButton,
    CardFieldsSettings
  }
}
</script>
