<template>
   <v-list-item
    dense
    data-testid="inviteItem"
    >
      <v-list-item-avatar>
        <Avatar :email="invite.email" :userId="invite.id" size="36" />
      </v-list-item-avatar>
      <v-list-item-content class="share-item-content d-flex flex-row justify-space-between">
        <v-list-item-title class="shrink-item" v-text="invite.email"/>   
      </v-list-item-content>
      <span class="subtitle-2 mr-4">{{ roleLabel }}</span>
      <v-list-item-action>
        <v-btn
          small
          icon
          @click="deleteInvitation"
          :loading="deleteLoading"
        >
          <v-icon small>mdi-delete-outline</v-icon>
        </v-btn>
      </v-list-item-action>
      </v-list-item>
</template>

<script>
import Avatar from '@/components/user/Avatar.vue'
export default {
  props: ['spaceUri', 'invite'],
  mounted() {
    this.$store.dispatch('AGReadInviteOperation', this.invite._links.self.href)
  },
  data() {
    return {
      deleteLoading: false
    }
  },
  computed: {
    roleLabel() {
      return this.$te(`roles.${this.invite.role}`) ? this.$t(`roles.${this.invite.role}`) : this.invite.role
    }
  },
  methods: {
    deleteInvitation() {
      this.deleteLoading = true
      this.$store.dispatch('AGDeleteInviteOperation', {
        spaceUri: this.spaceUri,
        invite: this.invite
      }).finally(() => this.deleteLoading = false)
    }
  },
  components: {
    Avatar
  }
}
</script>