<template>
  <div class="d-flex align-center"
    @mouseenter="showControls = true"
    @mouseleave="showControls = false"
  >
      <input
        :readonly="readonly"
        ref="input"
        class="ml-2 input grey--text text--darken-3"
        type="text"
        name=""
        append-icon="mdi-calendar-range-outline"
        v-model="inputModel"
        v-mask="dateMask"
        @focus="$emit('focus')"
        @select="$emit('focus')"
        @blur="finishEditing"
        @keyup.exact.enter="nextLine"
        @paste.prevent="handlePaste"
      />
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="pickerModel"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!readonly"
            v-show="showControls"
            class="mr-1"
            icon
            x-small
            v-bind="attrs"
            v-on="on"
            color="grey"
          >
            <v-icon>mdi-calendar-range-outline</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="pickerModel"
          scrollable
          :locale="_i18n.locale"
          :first-day-of-week="1"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false">
            {{ $t("dialogs.cancelButton") }}
          </v-btn>
          <v-btn text color="primary" @click="saveDatePicker">
            {{ $t("dialogs.okButton") }}
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import dateFieldMixin from '@/mixins/dateFieldMixin.js'

export default {
  mixins: [gridCells, dateFieldMixin],
  data() {
    return {
      showControls: false,
    }
  }
}
</script>
