<template>
<SingleInputDialog
  :disabled="disabled"
  v-model="externalModel"
  :title="$t('dialogs.setSpaceBelongsToTitle', { spaceName })"
  :text="$t('dialogs.setSpaceBelongsToText')"
  :okButtonLabel="$t('dialogs.okButton')"
  :okHandler="save"
  :initialInputValue="belongsTo"
  :max="$apptive.constants.nameLengths.default"
  :nameRequired="false"
  max-width="290"
/>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  props: {
    spaceUri: null,
    spaceName: null,
    belongsTo: null,
    disabled: null
  },
  methods: {
    save(belongsTo) {
      return this.$store.dispatch('AGSetSpaceBelongsToOperation', {
        spaceUri: this.spaceUri,
        belongsTo: belongsTo || null
      })
    }
  },
  components: {
    SingleInputDialog
  }
}
</script>
