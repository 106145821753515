<template>
  <div>
    <FullHeightLoader v-model="loading" />
    <EventHook
      v-if="!loading"
      :title="$t('webhooks.flowTitle')"
      :flowUri="flowUri"
      :hookUri="triggerUri"
      :spaceUri="spaceUri"
      :hideUriInput="true"
      :showCancelButton="false"
      :showNameField="false"
      :event="event"
      class="not-rounded"
      @deleteHook="deleteHook"
      />
  </div>
</template>

<script>

import EventHook from '@/components/webhooks/EventHook.vue'
import FullHeightLoader from '@/components/FullHeightLoader.vue'

export default {
  props: {
    flowUri: null,
    spaceUri: null,
    event: null
  },
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },
  computed: {
    flow() {
      return this.$store.getters.flowWithUri(this.flowUri)
    },
    trigger() {
      return this.flow?.trigger
    },
    triggerUri() {
      return this.trigger?.uri
    },
    triggerName() {
      return this.trigger?.name
    }
  },
  methods: {
    async deleteHook(hook){
      // delete hook. Backend cleans the flow._links.hook 
      await this.$store.dispatch('deleteWebhook', hook)
      this.flow.trigger = null
    },
  },
  components: {
    EventHook,
    FullHeightLoader
  }
}
</script>

<style lang="css" scoped>
.not-rounded {
  border-radius: 0px;
}
</style>
