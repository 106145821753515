<template>
  <BaseFormInput v-bind="$props">
    <v-text-field
      type="number"
      step="any"
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema, rules.decimal]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      @keypress="filterAllButDecimals"
      v-model="inputValue"
      :color="color"
      :disabled="disabled"
      v-bind="fieldStyle"

    >
    </v-text-field>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import inputValidation from '@/utils/inputValidation.js'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  name: 'FormDecimalField',
  mixins: [formFields],
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', parseFloat(value) || value )
      }
    }
  },
  methods: {
    filterAllButDecimals(event) {
      return inputValidation.filterAllButDecimals(event)
    }
  },
  components: {
    BaseFormInput
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
