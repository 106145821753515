<template>
<v-card class="kanban-state-selection">
  <template v-if="items && items.length > 0">
    <v-card-title>{{$t('calendar.stateSelection.title')}}</v-card-title>
    <v-card-subtitle>{{$t('calendar.stateSelection.text')}}</v-card-subtitle>
    <v-card-text>
      <v-select
        v-if="items && items.length > 0"
        flat outlined dense
        :label="$t('calendar.stateSelection.start')"
        v-model="startModel"
        :items="items"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <v-select
        v-if="endItems && endItems.length > 0"
        clearable
        flat outlined dense
        :label="$t('calendar.stateSelection.end')"
        v-model="endModel"
        :items="endItems"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <div class="d-flex justify-end">
        <v-btn
          :disabled="submitDisabled"
          :loading="loading"
          class="mt-3"
          color="primary"
          @click="setStates"
        >{{$t('calendar.stateSelection.submit')}}</v-btn>
      </div>
    </v-card-text>
  </template>
  <div v-show="!items || items.length <= 0">
    <v-card-title>{{$t('calendar.stateSelection.createTitle')}}</v-card-title>
    <v-card-subtitle>{{$t('calendar.stateSelection.noDate')}}</v-card-subtitle>
    <v-card-text>
      <ColumnSettings
        :virtualGrid="virtualGrid"
        createOnSave
        noAdvancedSettings
        noCancel
        flat
        :typeFilter="typeFilter"
        @columnCreated="selectFirstDate"
      />
    </v-card-text>
  </div>
</v-card>
</template>

<script>
import { columnTypes } from '@/constants/columnTypes.js'
import ColumnSettings from '../gridView/ColumnSettings.vue'


export default {
  props: ['calendarView'],
  data() {
    return {
      startModel: undefined,
      endModel: undefined,
      loading: false,
      typeFilter: [
        columnTypes.dateTime,
        columnTypes.date
      ]
    }
  },
  watch: {
    calendarView: {
      immediate: true,
      handler(newVal) {
        this.startModel = newVal.startFieldId
        this.endModel = newVal.endFieldId
      }
    }
  },
  computed: {
    virtualGrid() {
      return this.calendarView?.parentGrid
    },
    items() {
      if (this.virtualGrid == null) {
        return []
      }
      return this.virtualGrid.parentGrid().fields
        .filter(field => this.calendarView.isOfAcceptedType(field))
        .map(field => ({
          text: field.name,
          type: field.columnType,
          value: field.id
        }))
    },
    startField() {
      return this.virtualGrid.parentGrid().fields.find(field => field.id === this.startModel)
    },
    endItems() {
      if (this.startField == null) {
        return []
      }
      return this.items
        .filter(item => item.value !== this.startField.id && item.type.name === this.startField.columnType.name)
    },
    submitDisabled() {
      if (this.startModel == null) {
        return true
      }
      return this.startModel === this.calendarView.startFieldId
        && this.endModel === this.calendarView.endFieldId
    }
  },
  methods: {
    async setStates() {
      this.loading = true
      try {
        await this.calendarView.setStates(this.startModel, this.endModel)
        this.$emit('close')
      } finally {
        this.loading = false
      }
    },
    selectFirstDate() {
      this.calendarView.setStates(this.items[0].value)
    }
  },
  components: {
    ColumnSettings
  }
}
</script>

<style scoped>
.kanban-state-selection {
  max-width: 400px;
}
</style>
