<template functional>
  <div
    class="rogridcurrencycell-cell-content px-1 border-right cell grey--text text--darken-3"
    :class="$options.classesFromData(data)"
    :style="data.style"
    v-bind="data.attrs"
    v-on="listeners"
  >{{$options.currencyDisplay(props.value, props.field.type.currency, parent._i18n.locale)}}</div>
</template>

<script>
import currencyInputMixin from '@/mixins/currencyInputMixin.js'
import { classesFromData } from '@/utils/vueUtils.js'

export default {
  props: ['value', 'field'],
  currencyDisplay(value, currency, locale) {
    return currencyInputMixin.methods.formatValue(value, currency, locale)
  },
  classesFromData
}
</script>

<style>
.rogridcurrencycell-cell-content{
  font-size: 0.9em !important;
  white-space: nowrap;
  color: gray;
  line-height: 31px;
  text-align: right;
}
</style>
