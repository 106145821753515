<template>
  <div
    class="pr-3 mb-3"
    :class="{border: !isRoot}"
    :style="maxHeightFilterContainer"
  >
    <div
      v-if="!isRoot"
      class="d-flex justify-space-between"
    >
      <div class="ma-3 text-caption">{{activeCompositeOperatorExplanation}}</div>
      <v-btn
        :class="{invisible: !showControls}"
        :disabled="disabled"
        class="filter-group-remove ma-1"
        @click="$emit('removeFilter')"
        icon
        data-testid="gridFilterDeleteGroupButton"
      >
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <div
      v-for="(filterEntity, index) in filterArray"
      :key="`filter-${index}`"
      class="d-flex"
    >
      <div class="d-flex flex-column justify-center conjunction">
        <div v-if="index == 0" class="text-caption composite-operator-field px-3">
          {{$t('virtualGrid.filter.conditionalWhere')}}
        </div>
        <div v-else-if="index == 1" class="composite-operator-field px-1">
          <CompactSelect
            :showControls="showControls"
            :value="activeCompositeOperator"
            class="conjunction-select"
            :items="fieldCompositeItems"
            @change="compositeOperatorChanged"
            :label="$t('virtualGrid.filter.composite.label')"
            :lessInnerSpace="true"
            data-testid="gridFilterCompositeSelect"
          />
        </div>
        <div v-else class="text-caption composite-operator-field px-3">
          {{activeCompositeOperatorString}}
        </div>
      </div>
      <FilterGroup
        v-if="isComposite(filterEntity)"
        :showControls="showControls"
        :disabled="disabled"
        :virtualGrid="virtualGrid"
        :filter="filterEntity"
        :nestingLevel="nestingLevel + 1"
        @filterChanged="(filter) => filterChanged(index, filter)"
        @removeFilter="removeFilter(index)"
        data-testid="gridFilter">
      </FilterGroup>
      <SingleConditionFilter
        v-else
        :showControls="showControls"
        :disabled="disabled"
        :virtualGrid="virtualGrid"
        :filter="filterEntity"
        @filterChanged="(filter) => filterChanged(index, filter)"
        @removeFilter="removeFilter(index)">
      </SingleConditionFilter>
    </div>
    <div class="d-flex">
      <v-tooltip :disabled="canAddFilter" right>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            :class="!canAddFilter ? 'disabled-cursor' : ''"
          >
            <v-btn
              :class="{invisible: !isRoot && !showControls}"
              :disabled="disabled || !canAddFilter"
              color="primary"
              @click="addFilter"
              text
              small
              data-testid="gridAddAdditionalFilterButton"
            >
              <v-icon small class="mr-sm-2">mdi-plus</v-icon>
              <span class="d-sm-flex">{{$t('filterToolBar.add')}}</span>
            </v-btn>
          </div>
        </template>
        <span class="small-tooltip">{{$t('filterToolBar.allFiltersValidHint')}}</span>
      </v-tooltip>
      <v-tooltip :disabled="canAddFilterGroup" right>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            :class="disabled || !canAddFilterGroup ? 'disabled-cursor' : ''"
          >
            <v-btn
              :disabled="disabled || !canAddFilterGroup"
              :class="{invisible: !isRoot && !showControls}"
              color="primary"
              @click="addFilterGroup"
              text
              small
              data-testid="addFilterGroupButton"
            >
              <v-icon small class="mr-sm-2">mdi-plus</v-icon>
              <span class="d-sm-flex">{{$t('filterToolBar.addGroup')}}</span>
            </v-btn>
          </div>
        </template>
        <span class="small-tooltip">{{addFilterGroupDisabledHint}}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import SingleConditionFilter from './SingleConditionFilter.vue'
import CompactSelect from '@/components/CompactSelect.vue'
import { AGNoCondition, AGAndCondition, AGOrCondition, AGCompositeCondition } from '@/filter/conditions.js'
import componentOffset from '@/constants/componentOffset.js'

export default {
  props: {
    virtualGrid: null,
    filter: null,
    disabled: null,
    nestingLevel: null,
    showControls: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      compositeFilter: this.filter,
      fieldCompositeItems: [{
          text: this.$t('virtualGrid.filter.composite.and'),
          value: '$and'
        }, {
          text: this.$t('virtualGrid.filter.composite.or'),
          value: '$or'
        }],
    }
  },
  watch: {
    filter: {
      immediate: true,
      handler(newVal) {
        this.compositeFilter = newVal
      }
    }
  },
  computed: {
    filterArray() {
      return this.compositeFilter ? this.compositeFilter.conditions ?? [this.compositeFilter] : []
    },
    canAddFilter() {
      if (!this.compositeFilter) {
        return true
      }
      return this.compositeFilter.isValid()
    },
    canAddFilterGroup() {
      return this.canAddFilter && !this.maxNestingLevel
    },
    addFilterGroupDisabledHint() {
      return this.maxNestingLevel ? this.$t('filterToolBar.maxNestingLevelHint') : this.$t('filterToolBar.allFiltersValidHint')
    },
    activeCompositeOperator() {
      return this.compositeFilter instanceof AGAndCondition ? '$and' : '$or'
    },
    activeCompositeOperatorString() {
      return this.compositeFilter instanceof AGAndCondition ? this.$t('virtualGrid.filter.composite.and') : this.$t('virtualGrid.filter.composite.or')
    },
    activeCompositeOperatorExplanation() {
      return this.compositeFilter instanceof AGAndCondition ? this.$t('virtualGrid.filter.composite.andExplanation') : this.$t('virtualGrid.filter.composite.orExplanation')
    },
    // This will fill the remaining height to allow scrolling
    maxHeightFilterContainer() {
      return {
        'max-height': `calc(100vh - ${this.$vuetify.application.top + componentOffset.gridFilterOffset}px)`
      }
    },
    isRoot() {
      return this.nestingLevel === 0
    },
    maxNestingLevel() {
      return this.nestingLevel === 2
    }
  },
  methods: {
    compositeOperatorChanged(newVal) {
      this.$nextTick(() => {
        let newFilter = (newVal == '$and') ? new AGAndCondition() : new AGOrCondition()
        newFilter.conditions = this.compositeFilter.conditions
        this.$emit('filterChanged', newFilter)
      })
    },
    filterChanged(index, filter) {
      if (this.compositeFilter.conditions) {
        this.$set(this.compositeFilter.conditions, index, filter)
        this.$emit('filterChanged', this.compositeFilter)
      } else {
        this.$emit('filterChanged', filter)
      }
    },
    addFilter() {
      if (this.compositeFilter) {
        this.compositeFilter = this.compositeFilter.add(new AGNoCondition())
      } else {
        this.compositeFilter = new AGNoCondition()
      }
      
    },
    addFilterGroup() {
      if (this.compositeFilter) {
        this.compositeFilter = this.compositeFilter.add(new AGAndCondition())
      } else {
        this.compositeFilter = new AGAndCondition()
        this.compositeFilter.add(new AGAndCondition())
      }
      this.$emit('filterChanged', this.compositeFilter)
    },
    removeFilter(singleConditionFilterIndex) {
      // AC-1621 when a lone undefined filter is present,
      // the change is not yet reflected on the parent component,
      // we can simply reset the internal state of the FilterGroup to undefined
      if (!this.filter && this.compositeFilter instanceof AGNoCondition) {
        this.compositeFilter = undefined
        return
      }
      if (this.compositeFilter.conditions) {
        this.compositeFilter.conditions.splice(singleConditionFilterIndex, 1)
        if (this.compositeFilter.conditions.length > 0) {
          this.$emit('filterChanged', this.compositeFilter)
        } else {
          this.$emit('filterChanged', undefined)
        }
      } else {
        this.$emit('filterChanged', undefined)
      }
    },
    isComposite(condition) {
      return condition instanceof AGCompositeCondition
    }
  },
  components: {
    SingleConditionFilter,
    CompactSelect,
    FilterGroup: () => import('./FilterGroup.vue')
  }
}
</script>

<style lang="scss" scoped>
.disabled-cursor {
  cursor: unset;
}

.small-tooltip {
  font-size: 12px !important;
}

.border {
  border: solid 1px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.02);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  position: relative;
}

.composite-operator-field {
  width: 4.5rem;
}

.conjunction {
  height: 44px;
  text-align: center;
}

.invisible {
  opacity: 0;
}
</style>
