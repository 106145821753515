<template>
  <TemplateMapper
    v-model="externalModel"
    @blur="$emit('blur')"
    ref="templateMapper"
    v-bind="$attrs"
  >
    <div>
      <TemplateInput v-model="fileName" class="mt-1" :flow="flow" :label="$t('flow.templateFilePicker.fileName')" @input="emit" @focus="lastActiveInput = 'fileName'; $emit('focus')" ref="fileName" topLabel/>
      <TemplateInput v-model="mimeType" class="mt-1" :flow="flow" :label="$t('flow.templateFilePicker.fileType')" @input="emit" @focus="lastActiveInput = 'mimeType'; $emit('focus')" ref="mimeType" topLabel/>
      <TemplateInput v-model="contents" class="mt-1" :flow="flow" :label="$t('flow.templateFilePicker.fileData')" @input="emit" @focus="lastActiveInput = 'contents'; $emit('focus')" ref="contents" topLabel/>
    </div>
  </TemplateMapper>
</template>

<script>
import TemplateInput from './TemplateInput.vue'
import externalModel from '../../mixins/externalModel'
import { createHolder } from '@/apptivescript/model'
import TemplateMapper from '@/components/flow/TemplateMapper.vue'

export default {
  mixins: [externalModel],
  props: {
    flow: null
  },
  data() {
    return {
      fileName: undefined,
      contents: undefined,
      mimeType: undefined,
      lastActiveInput: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        const value = newVal?.value
        if (value != null) {
          this.fileName = value.fileName
          this.contents = value.contents
          this.mimeType = value.mimeType
        } else {
          this.fileName = undefined
          this.contents = undefined
          this.mimeType = undefined
        }
      }
    }
  },
  methods: {
    emit() {
      if (this.fileName == null || this.contents == null || this.mimeType == null) return
      const newVal = {
        fileName: this.fileName,
        contents: this.contents,
        mimeType: this.mimeType
      }
      this.$emit('input', createHolder(newVal))
    },
    insert(text) {
      const mapperInserted = this.$refs.templateMapper.insert(text)
      if (!mapperInserted) {
        this.$refs[this.lastActiveInput].insert(text)
        this.$emit('blur')
      }
    },
    focus() {
      this.$refs.fileName.focus()
    }
  },
  components: {
    TemplateInput,
    TemplateMapper
  }
}
</script>

<style scoped>
.input {
  min-height: 40px;
  padding-bottom: 6px;
  padding-top: 6px;
  border: solid #D3D3D3 1px;
  border-radius: 4px;
  background: white;
}
</style>