<template>
  <EntityReferenceCard v-if="isEmpty && showEmptyState" empty/>
  <Draggable
    v-else
    :disabled="!isCollection"
    :value="draggableModel"
    @input="onElementDragged"
    class="flex-column gap-3"
    style="display: flex"
    handle=".handle"
    ghost-class="ghost-card"
    :animation="200"
  >
    <div
      v-for="(uri, index) in selectedEntityUris"
      :key="index"
      class="position-relative"
      @mouseenter="showCardControl = index"
      @mouseleave="showCardControl = -1"
    >
      <div class="d-flex flex-row">
        <v-icon v-if="isCollection && !readonly" class="handle">mdi-drag-vertical</v-icon>
        <v-btn
          v-if="!readonly"
          v-show="$vuetify.breakpoint.mobile || showCardControl === index"
          fab 
          height="18"
          width="18"
          elevation="0"
          class="remove-button"
          :color="color"
          :dark="!isDarkColor"
          @click="() => remove(index)"
          >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        <EntityReferenceCard
          :entityUri="uri"
          :entity="entityWithUri(uri)"
          :grid="referencedVirtualGrid"
          loadEntity
          :editOnClick="editOnClick"
          :selected="referenceCardSelected"
        />
      </div>
    </div>
  </Draggable>
</template>

<script>
import EntityReferenceCard from './EntityReferenceCard.vue'
import Draggable from 'vuedraggable'
import externalModel from '../../mixins/externalModel'
import { darkColor } from '@/utils/color.js'

export default {
  mixins: [externalModel],
  props: {
    readonly: null,
    isCollection: {
      type: Boolean,
      default: () => false
    },
    color: {
      default: () => 'accent'
    },
    editOnClick: {
      type: Boolean,
      default: () => true
    },
    referenceCardSelected: {
      type: Boolean,
      default: () => true
    },
    showEmptyState: {
      type: Boolean,
      default: () => false
    },
    referencedVirtualGrid: null,
    queryEntities: null,
    filter: null
  },
  data() {
    return {
      showCardControl: undefined
    }
  },
  computed: {
    draggableModel() {
      return this.isCollection ? this.externalModel : []
    },
    selectedEntityUris() {
      if (this.externalModel == null) return []
      const refArray = Array.isArray(this.externalModel) ? this.externalModel : [this.externalModel]
      return refArray.map(entity => entity.uri)
    },
    isDarkColor(){
      return darkColor(this.color)
    },
    isEmpty() {
      return this.selectedEntityUris == null || this.selectedEntityUris.length === 0
    }
  },
  methods: {
    onElementDragged(newValue) {
      this.externalModel = newValue
    },
    remove(index) {
      if (!this.isCollection) {
        this.externalModel = null
      } else {
        this.externalModel = this.externalModel.toSpliced(index, 1)
      }
    },
    entityWithUri(uri) {
      // if model contains a suitable entity in displayValue, use that
      const modelArray = Array.isArray(this.externalModel) ? this.externalModel : [this.externalModel]
      // HACK : legacy prefill form preview
      const entityFromDisplay = modelArray.find(item => item.displayValue?.uri === uri)?.displayValue
      // HACK : Not part of the api, This is used in the case of prefilled forms
      // where the entity values cannot be fetched due to permissions
      const fullEntity = modelArray.find(item => item.fullEntity?.uri === uri)?.fullEntity
      return entityFromDisplay ?? fullEntity ?? this.queryEntities.find(entity => entity.uri === uri)
    }
  },
  components: {
    EntityReferenceCard,
    Draggable
  }
}
</script>

<style scoped>
.handle {
  cursor: grab;
}

.ghost-card {
  opacity: 0.5;
  background-color: white;
}

.position-relative {
  position: relative;
}

.remove-button {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 1;
}
</style>