<template>
  <BaseFormInput v-bind="$props">
    <v-textarea
      v-if="multiline"
      rows="2"
      auto-grow
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      v-model="inputValue"
      :color="color"
      v-bind="fieldStyle"
      :disabled="disabled"
    />
    <v-text-field
      v-else
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      v-model="inputValue"
      :color="color"
      v-bind="fieldStyle"
      :disabled="disabled"
      block
    />
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  name: 'FormTextField',
  mixins: [formFields],
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        const sanitizedValue = value === '' ? null : value
        this.$emit('input', sanitizedValue)
      }
    },
    multiline() {
      return this.component.options?.multi
    }
  },
  components: {
    BaseFormInput
  }
}
</script>


