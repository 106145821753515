import Vue from 'vue'
import ApiClient from '@/ApiClient'
import Field from '../models/Field'

export default {
  state() {
    return {
      fields: {}
    }
  },
  mutations: {
    add(state, field) {
      Vue.set(state.fields, field.uri, field)
    }
  },
  actions: {
    async AGReadFieldOperation(context, uri) {
      if (context.state.fields[uri] != null) {
        return context.state.fields[uri]
      }
      const response = await ApiClient.getUri(uri)
      const field = new Field(response.data)
      context.commit('add', field)
      return field
    }
  },
  getters: {
    fieldWithUri: (state) => (uri) => {
      return state.fields[uri]
    }
  }
}