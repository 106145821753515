import Vue from 'vue'
const bus = new Vue()
export default {
  requestPage(index) {
    bus.$emit('showPage', index)
  },
  onPageRequested(handler) {
    bus.$on('showPage', handler)
  },
  requestSuccessPage() {
    bus.$emit('showSuccessPage')
  },
  onSuccessPageRequested(handler) {
    bus.$on('showSuccessPage', handler)
  },
  pageRequestedOff(handler) {
    bus.$off('showPage', handler)
  },
  successPageRequestedOff(handler) {
    bus.$off('showSuccessPage', handler)
  },
}
