<template>
  <v-dialog v-model="dialog" persistent max-width="290" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="disabled"
        class="normal-text"
        data-testid="openDeleteColumnDialogButton"
        v-bind="attrs"
        v-on="on"
        text
      >
        {{ $t("dialogs.deleteButton") }}
      </v-btn>
    </template>
    <v-card color="black">
      <v-card-title
        class="text-h5 white--text word-break"
        v-html="title"
      />
      <v-card-text class="white--text">{{
        $t("dialogs.areYouSure")
      }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="onCancelPressed">
          {{ $t("dialogs.cancelButton") }}
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          :loading="loading"
          :data-testid="`deleteButton-loading-${loading}`"
          @click="onDeletePressed"
        >
          {{ $t("dialogs.deleteButton") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import reloadVirtualGrid from '@/mixins/reloadVirtualGrid.js'

export default {
  props: ['virtualGrid', 'field', 'disabled'],
  mixins: [reloadVirtualGrid],
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  computed: {
    title() {
      return this.$t('dialogs.deleteColumnTitle', { name: this.field.name })
    }
  },
  methods: {
    onCancelPressed() {
      this.dialog = false
    },
    onDeletePressed() {
      this.loading = true
      this.$store
        .dispatch('AGRemoveColumnOperation', {
          virtualGridUri: this.virtualGrid.uri,
          fieldId: this.field.id
        })
        .then(() => {
          this.reloadVirtualGrid()
        })
        .finally( () => {
          this.loading = false
          this.dialog = false
        })
    }
  }
}
</script>

<style scoped lang="css">
.normal-text {
  font-weight: normal;
}

.word-break {
  word-break: break-word;
}
</style>
