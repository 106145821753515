import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import store from '../store'
import HalObject from './HalObject'

const VERSION = 'version'
const FORMLINK = 'formLink'

export default class StatefulView extends HalObject {
  constructor(data) {
    super(data)
    this.name = data.name
    this.type = data.type
    this.properties = data.properties
    this.slotProperties = data.slotProperties
  }

  get parentGridUri() {
    return this.getLink('grid')
  }

  get parentGrid() {
    const gridUri = this.parentGridUri
    return store().getters.virtualGridWithUri(gridUri)
  }

  get version() {
    return this.properties[VERSION] ?? 1
  }

  set version(newVal) {
    this.properties[VERSION] =  newVal
  }

  get formLink() {
    return this.properties[FORMLINK]
  }

  set formLink(newVal) {
    this.properties[FORMLINK] =  newVal
  }

  async setFormLink(link) {
    this.formLink = link
    return store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      properties: this.properties,
    })
  }

  isOfAcceptedType() {
    throw 'Should be implemented by subclass'
  }

  getFieldProperty(property) {
    const fieldId = Object.keys(this.slotProperties).find(key => this.slotProperties[key][property])
    if (this.parentGrid.fields.some(field => field.id === fieldId && this.isOfAcceptedType(field))) {
      return fieldId
    } else {
      return undefined
    }
  }

  deleteFieldProperty(fieldId, property) {
    if (fieldId != null && property in this.slotProperties[fieldId]) {
      delete this.slotProperties[fieldId][property]
    }
  }

  setFieldProperty(fieldId, property, value) {
    if (fieldId != null) {
      this.slotProperties[fieldId] = {
        ...this.slotProperties[fieldId],
        [property]: value
      }
    }
  }

  canRename() {
    return hasPermission(this.parentGrid, [PERMISSIONS.update])
  }

  rename(newName) {
    if (this.parentGrid.name === newName) {
      return
    }
    return store().dispatch('AGRenameVirtualGridOperation', {
      virtualGrid: this.parentGrid,
      newName
    }).then(() => {
      store().dispatch('AGListStatefulViewsOperation', this.parentGrid.parentGrid())
    })
  }

  reload() {
    return store().dispatch('AGReadStatefulViewOperation', this.uri)
  }
}