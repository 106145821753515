import { EXPRESSIONS } from '@/constants/expressions/index.js'

export function parseExpression(expression) {
  if (expression instanceof EXPRESSIONS.AGExpression) {
    return expression
  }
  for (let expressionType of Object.values(EXPRESSIONS)) {
    if (expressionType.prototype.matches(expression)) {
      const expressionArguments = expressionType.prototype.parseArgument(expression)
      return new expressionType(...expressionArguments)
    }
  }
  return new EXPRESSIONS.AGValueExpression(expression)
}