import Vue from 'vue'
import ApiClient from '@/ApiClient'

export default {
  state() {
    return {
      shareLists: {}
    }
  },
  mutations: {
    addShareList(state, { spaceUri, list }) {
      Vue.set(state.shareLists, spaceUri, list)
    }
  },
  actions: {
    loadShareUris(context, spaceUri) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink('shares')
      return ApiClient.getUri(link)
        .then(response => {
          context.commit('addShareList', { spaceUri, list: response.data.items })
          return response.data.items
        })
    }
  },
  getters: {
    sharesOfSpace: (state) => spaceUri => {
      return state.shareLists[spaceUri]?.map((share) => {
        return {
          emails: share.emails,
          users: share.users,
          _links: share._links,
          role: share.role
        }
      }) || []
    }
  }
}
