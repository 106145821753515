<template>
  <div class="d-flex flex-column ma-6">

    <v-sheet rounded elevation="2" color="white" class="profile pa-10">

      <div class="text-h6">{{$t('webhooks.title')}}</div>
      <v-divider class="mt-2 mb-4"></v-divider>
      <div class="mt-5 text-subtitle-1">{{$t('webhooks.description')}}</div>
      <PaywallMenu
        :feature="$apptive.constants.features.WEBHOOKS_PER_SPACE"
        :count="webHooks.length"
      >
        <v-btn
          :disabled="!canAddHook"
          data-testid="addWebhookButton"
          class="mt-5"
          elevation="5"
          rounded
          color="primary"
          depressed
          @click.stop="addWebhook"
        >{{$t('webhooks.buttons.create')}}</v-btn>
      </PaywallMenu>
      <PaywallBanner
       :feature="$apptive.constants.features.WEBHOOKS_PER_SPACE"
       :count="webHooks.length"
       :text="$t('paywall.webhooksPerSpace.longHint')"
       class="my-4"
      />
          <v-list>
            <v-subheader>{{spaceName}}</v-subheader>

            <v-list-item
              v-for="(hook, index) in webHooks"
              :key="index"
              class="d-flex">
              <v-list-item-content>

                <v-list-item-title>
                  <div class="mr-3">{{hook.name}}</div>
                </v-list-item-title>
                <v-list-item-subtitle>

                  <v-icon small>mdi-lightning-bolt</v-icon>
                  <span class="mr-3 ml-2">{{hook.subtitle()}}</span>

                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                  <v-icon small>mdi-arrow-right-thin</v-icon>
                  <span class="ml-2">{{space.name}}</span>
                  <v-icon small>mdi-slash-forward</v-icon>
                  <span class="mr-3">{{objectName( space ,hook)}}</span>

                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon small>{{hookUrlIcon(hook)}}</v-icon>
                  <span class="mr-3 ml-2">{{hookUri(hook)}}</span>
                </v-list-item-subtitle>


              </v-list-item-content>

            <v-list-item-action
              v-if="mobile"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    small
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                      <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in actions"
                    :key="index"
                    :disabled="!item.permission(hook)"
                    :data-testid="item.testId"
                    @click="item.method(hook)"
                  >
                  <v-list-item-icon>
                    <v-icon small v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                    <v-list-item-title>
                      {{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </v-list-item-action>
            <v-list-item-action

              v-else
              v-for="(item, index) in actions"
              :key="index"
              class=""
            >
            <v-btn
              :color="item.color"
              text
              small
              :data-testid="item.testId"
              :disabled="!item.permission(hook)"
              @click="item.method(hook)"
            >
                <v-icon small class="mr-1">{{item.icon}}</v-icon>
                {{item.title}}
            </v-btn>
            </v-list-item-action>
            </v-list-item>
          </v-list>

          <WebhookDialog
            v-model="dialog"
            @deleteHook="deleteHook"
            :hookUri="webhookUri"
            :spaceUri="spaceUri"
          />
    </v-sheet>

  </div>
</template>

<script>

import {textFieldRules} from '@/mixins/textFieldRules.js'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import WebhookDialog from '@/components/webhooks/WebhookDialog.vue'
import PaywallMenu from '../paywall/PaywallMenu.vue'
import PaywallBanner from '@/components/paywall/PaywallBanner.vue'

export default {
  props: {
    spaceUri: {
      type: String
    }
  },
  mixins: [textFieldRules],
  data() {
    return {
      webhookUri: null,
      dialog: false,
      actions: [
        {
          title: this.$t('webhooks.buttons.edit'),
          icon: 'mdi-pencil',
          color: 'primary',
          method: ( (hook) => this.openHook(hook)),
          permission: ( (hook) => this.canEditHook(hook)),
          testId: 'webhookEditButton'
        },
        {
          title: this.$t('webhooks.buttons.delete'),
          icon: 'mdi-delete',
          color: 'red',
          method: ( (hook) => this.deleteHook(hook)),
          permission: ( (hook) => this.canDeleteHook(hook)),
          testId: 'webhookDeleteButton'
        }
      ],
    }
  },
  async mounted() {
    await this.$store.dispatch('AGReadSpaceOperation', { spaceUri: this.spaceUri})
    await this.loadWebhooks()
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri) ?? {}
    },
    spaceName() {
      return this.space?.name
    },
    webHooks() {
      return this.$store.getters.webhooks ?? []
    },
    title() {
      return `${this.spaceName} : ${this.$t('webhooks.title')}`
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile
    },
    canAddHook() {
      return hasPermission(this.space, [PERMISSIONS.addHook])
    },
    user() {
      return this.$store.state.user.user
    },
    paywallDisabled() {
      return (this.webHooks?.length ?? 0) < 1
    }
  },
  methods: {
    objectName(aSpace, webhook) {
      if (!webhook.hasFormEvent) {
        let space = this.$store.getters.spaceWithUri(aSpace.uri)
        let grid = space?._embedded.grids.find( grid => grid._links.self.href == webhook.object )
        let name = grid?.name
        return name
      } else {
        const form = this.$store.getters.formWithUri(webhook.object)
        return form?.name
      }
    },
    addWebhook() {
      this.webhookUri = null
      this.dialog = true
      this.$intercom?.trackEvent( 'User created a webhook draft' )
    },
    canEditHook(hook) {
      return hasPermission(hook, [PERMISSIONS.update])
    },
    canDeleteHook(hook) {
      return hasPermission(hook, [PERMISSIONS.remove])
    },
    openHook(hook){
      this.webhookUri = hook._links.self.href
      this.dialog = true
    },
    async deleteHook(hook){
      await this.$store.dispatch('deleteWebhook', hook)
      await this.loadWebhooks()
    },
    hookUrlIcon(webhook) {
      return webhook.type === 'web' ? 'mdi-link' : 'mdi-sitemap-outline'
    },
    hookUri(webhook){
      return webhook.type === 'internalFlowTrigger' ? webhook.flowUri : webhook.webUri
    },
    async loadWebhooks() {
      const webhooks = await this.$store.dispatch('AGListWebhooksOperation', this.space)
      webhooks.forEach(webhook => {
        if (webhook.hasFormEvent) {
          this.$store.dispatch('AGReadFormOperation', webhook.object)
        }
      })
    }
  },
  components: {
    WebhookDialog,
    PaywallMenu,
    PaywallBanner
}
}
</script>

<style scoped>
.api-keys-container {
  background: #F7F7F7;
}

.content-card {
  max-width: 80%;
}
</style>
