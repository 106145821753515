<template>
  <v-app-bar v-bind="$attrs" v-on="$listeners" :dark="isBarDark" :color="color" flat app>
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  </v-app-bar>
</template>

<script>
import { darkColor } from '../utils/color'

  export default {
    props: ['space'],
    computed: {
      color() {
        return this.space?.color ?? 'space'
      },
      isBarDark() {
        return !darkColor(this.color)
      }
    }
  }
</script>