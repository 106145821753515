<template>
  <DefaultDialog v-model="externalModel" maxWidth="550"
    :title="$t('dialogs.spaceIcon.title')"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <SpaceIconPicker :spaceUri="spaceUri" :active="externalModel"/>
  </DefaultDialog>
</template>

<script>
import externalModel from '../../mixins/externalModel'
import SpaceIconPicker from './SpaceIconPicker.vue'
import DefaultDialog from '@/components/DefaultDialog.vue'

export default {
  props: ['spaceUri'],
  mixins: [externalModel],
  components: {
    SpaceIconPicker,
    DefaultDialog
  }
}
</script>