<template>
    <component
      :is="selectComponent"
      class="enum-select ml-1 font-weight-medium"
      ref="input"
      v-model="model"
      :items="items"
      :readonly="readonly"
      :append-icon="null"
      :clear-icon="clearIcon"
      small-chips
      :clearable="showControls && !readonly"
      :multiple="isCollection"
      hide-details
      :deletable-chips="isCollection"
      :placeholder="placeholder"
      solo
      flat
      :menu-props="['bottom', 'offset-y']"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @mouseenter.native="onMouseEnter"
      @mouseleave.native="onMouseLeave"
    >
      <template v-slot:item="slotProps">
        <v-list-item dense selected v-on="slotProps.on" :key="slotProps.item">
          <v-chip small class="font-weight-medium" :color="chipColor(slotProps.item)">{{ slotProps.item }}</v-chip>
        </v-list-item>
      </template>
      <template v-slot:prepend-item v-if="!isExtended">
        <v-list-item dense>
          <v-text-field
            v-model="filter"
            :placeholder="$t('virtualGrid.enumFilterPlaceholder')"
            solo
            flat
            dense
            clearable
            hide-details
          />
        </v-list-item>
      </template>
    </component>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import {
  VSelect,
  VCombobox,
} from 'vuetify/lib'
export default {
  mixins: [gridCells],
  data() {
    return {
      filter: '',
      showControls: false,
      showControlsTimeout: undefined
    }
  },
  async beforeDestroy() {
    // HACK : let the combobox a chance to emit a change
    // before the cell is destroyed when clicking on a different GridRow
    this.$refs.input.updateSelf?.()
  },
  mounted() {
    //HACK prevents v-select scrolling when clicking
    // combined with overflow: hidden, prevents AC-1402
    const el = this.$el.querySelector('.v-select__selections')
    el.addEventListener('click', () => {
      el.scrollTo(0, 0)
    })
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal ?? null)
        this.$emit('blur')
      }
    },
    isExtended() {
      return this.field.type.extended
    },
    selectComponent() {
      return this.isExtended ? VCombobox : VSelect
    },
    placeholder() {
      return this.isExtended ? this.$t('virtualGrid.enumOtherOption') : undefined
    },
    isCollection() {
      return this.field.type.jsonSchema.type === 'array'
    },
    items() {
      if ((!this.isCollection && !this.field.type.jsonSchema.enum) || (this.isCollection && !this.field.type.jsonSchema.items.enum)) {
        return []
      }
      const options = this.isCollection ? this.field.type.jsonSchema.items.enum : this.field.type.jsonSchema.enum
      return options.filter(
        el => !this.filter || el.includes(this.filter)
      )
    },
    appendIcon() {
      return this.showControls ? '$dropdown' : null
    },
    clearIcon() {
      return this.showControls ? '$clear' : null
    }
  },
  methods: {
    onMouseEnter() {
      if (this.$vuetify.breakpoint.mobile) {
        this.showControlsTimeout = setTimeout(() => this.showControls = true, 100)
      } else {
        this.showControls = true
      }
    },
    onMouseLeave() {
      if (this.showControlsTimeout) {
        clearTimeout(this.showControlsTimeout)
      }
      this.showControls = false
    },
    chipColor(item) {
      return (this.value && this.value === item) ? 'primary' : undefined
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep.enum-select {
  .v-input__slot {
    min-height: unset;
    padding: 0 !important;
    background: unset !important;
  }

  .v-select__selections {
    flex-wrap: unset;
    overflow: hidden;
  }

  .v-input__append-inner {
    background-color: white;
    margin-right: 8px;
    padding-right: 2px;
    border-radius: 2px;
    z-index: 1;
  }

  .v-chip--select {
    flex: 0 0 auto;
  }

  // Allow the chip in the select to be clicked through
  .v-select__selections .v-chip {
    pointer-events: none;
  }

  .v-chip__close {
    pointer-events: all;
  }

  //AC-1441 Improving Readonly Views readability
  .v-chip--disabled {
    opacity: 1;
  }
}
</style>
