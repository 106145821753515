import Link from '@/store/models/Link'
import store from '../../../store'

export default class ExternalFlowTrigger extends Link {
  constructor(data) {
    super(data)
  }

  get eventType() {
    return this.type
  }

  runExternalHook (payload) {
    return store().dispatch('AGFlowRunExternalHook', { webhook: this, payload})
  }

}