import i18n from '@/plugins/i18n'

export default {
  entityAddedEvent: {
    type: 'start',
    typeName: i18n.t('flow.nodes.entityAdded.name'),
    description: i18n.t('flow.nodes.entityAdded.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'entityAddedEvent'
  },
  entityUpdatedEvent: {
    type: 'start',
    typeName: i18n.t('flow.nodes.entityUpdated.name'),
    description: i18n.t('flow.nodes.entityUpdated.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'entityUpdatedEvent'
  },
  entityAdded: {
    type: 'start',
    typeName: i18n.t('flow.nodes.entityAdded.name'),
    description: i18n.t('flow.nodes.entityAdded.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'entityAdded',
    deprecated: true
  },
  entityUpdated: {
    type: 'start',
    typeName: i18n.t('flow.nodes.entityUpdated.name'),
    description: i18n.t('flow.nodes.entityUpdated.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'entityUpdated',
    deprecated: true
  },
  addEntityFormSubmitted: {
    type: 'start',
    typeName: i18n.t('flow.nodes.addEntityFormSubmitted.name'),
    description: i18n.t('flow.nodes.addEntityFormSubmitted.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'addEntityFormSubmitted'
  },
  editEntityFormSubmitted: {
    type: 'start',
    typeName: i18n.t('flow.nodes.editEntityFormSubmitted.name'),
    description: i18n.t('flow.nodes.editEntityFormSubmitted.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'editEntityFormSubmitted'
  },
  requestTrigger: {
    type: 'start',
    typeName: i18n.t('flow.nodes.requestTrigger.name'),
    description: i18n.t('flow.nodes.requestTrigger.description'),
    icon: 'mdi-webhook',
    graphIcon: 'mdi-webhook',
    color: '#C73A63',
    eventType: 'externalFlowTrigger'
  },
  start: {
    type: 'start',
    typeName: i18n.t('flow.nodes.start.name'),
    description: i18n.t('flow.nodes.start.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
  }
}