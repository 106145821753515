import moment from 'moment'
import { DATE_MASK, DATE_DISPLAY_FORMAT, DATE_PICKER_FORMAT } from '@/constants/dateFormats.js'
import { VueMaskDirective } from 'v-mask'
import errorBus from '@/utils/errorBus.js'

export default {
  data() {
    return {
      modal: false,
      pickerModel: undefined,
      inputModel: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.inputModel = newVal ? moment(newVal).format(DATE_DISPLAY_FORMAT) : ''
        this.pickerModel = newVal
          ? moment(newVal).format(DATE_PICKER_FORMAT)
          : moment().format(DATE_PICKER_FORMAT)
      }
    }
  },
  computed: {
    dateMask() {
      return DATE_MASK
    }
  },
  methods: {
    saveDatePicker() {
      this.$refs.dialog.save(this.pickerModel)
      let date = moment(this.pickerModel, DATE_PICKER_FORMAT)
      this.emitInput(date)
    },
    emitInput(momentDate) {
      if (momentDate.isValid()) {
        this.pickerModel = momentDate.format(DATE_PICKER_FORMAT)
        this.$emit('input', this.pickerModel)
      } else {
        this.pickerModel = moment().format(DATE_PICKER_FORMAT)
        this.inputModel = ''
        this.$emit('input', null)
      }
      this.$emit('blur')
    },
    finishEditing() {
      const date = moment(this.inputModel, DATE_DISPLAY_FORMAT)
      this.emitInput(date)
    },
    handlePaste(event) {
      const content = (event.clipboardData || window.clipboardData).getData('text')
      let date = moment(content, DATE_DISPLAY_FORMAT)
      if (!date.isValid()) {
        date = moment(content)
      }
      if (!date.isValid()) {
        errorBus.$emit('ERROR', {message: this.$t('errorMessages.unrecognisedDate', {date: content})})
      }
      this.emitInput(date)
    }
  },
  directives: {
    mask: VueMaskDirective
  }
}