import ApiClient from '@/ApiClient'

const DEFAULT_CONFIG = {
  templateGrid: '/api/users/ff4e47b404a31ecccad154c9e01d39aa/spaces/e9929bce1e879d655aaad3d5ee9ee4b9/grids/b1b9f8c4631868416ddab99fc25e630f/entities',
  templateSpacePrefix: '/api/users/ff4e47b404a31ecccad154c9e01d39aa/spaces/'
}

export default {
  actions: {
    async loadTemplates(context, locale) {
      const templateEntitiesResponse = await ApiClient.getUri(
        DEFAULT_CONFIG.templateGrid,
        {
          layout: 'key'
        }
      )
      return templateEntitiesResponse.data.items
        .filter(item => item.language == null || item.language === locale)
    },
    async copyTemplate(context, {templateId, name}) {
      const templateUri = DEFAULT_CONFIG.templateSpacePrefix + templateId
      const space = await context.dispatch('AGReadSpaceOperation', templateUri)
      const copyResponse = await context.dispatch('AGCopySpaceOperation', {
        spaceUri: templateUri,
        name: name || space.name,
        includeEntities: true
      })
      return copyResponse
    }
  }
}