<template>
  <div style="height: 100%">

    <v-toolbar
      flat
      v-if="!embedded"
      dense
      :style='`width: calc(100% - ${spaceDrawerConsumed}px);`'
      class="toolbar"
      data-tutorial="grid-toolbar">
      <v-btn
        v-if="persistentGrid"
        color="accent"
        text
        small
        class="pl-1 pr-2"
        @click.stop="toggleDrawer"
        >
          <v-icon v-if="showDrawer">mdi-chevron-double-left</v-icon>
          <v-icon  v-else>mdi-chevron-double-right</v-icon>
          <span v-if="!showDrawer && !this.$vuetify.breakpoint.mobile" class="ml-1 text-truncate view-title font-weight-bold">{{viewTitle}}</span>
      </v-btn>
      <div class="d-flex flex-grow-1 align-center justify-space-between toolbar-menu">
        <slot name="toolbar-left" />
        <v-spacer/>
        <slot name="toolbar-center" />
        <v-spacer/>
        <slot name="toolbar-right" />
      </div>

    </v-toolbar>

    <div style="height: 100%;" :class="{'toolbar-padding': toolbarPadding}">
      <slot />
    </div>

    <UpdateAlert v-if="canGetUpdates" :autoreload="isStandalone" :gridUri="grid.uri" @reloadView="reloadView"/>
  </div>
</template>

<script>
import UpdateAlert from '@/components/UpdateAlert.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import { SIDE_NAVIGATION_WIDTH } from '@/components/space/SideNavigation.vue'

export default {
  props: {
    persistentGridUri: null,
    virtualGridUri: null,
    formUri: null,
    toolbarPadding: {
      default: true
    },
    viewTitle: null,
    isStandalone: {
        default: false
    },
    embedded: {
        default: false
    }
  },
  data() {
    return {
      drawerWidth: 240,
    }
  },
  computed: {
    spaceDrawerConsumed() {
      const sideNavigationWidth = this.$store.getters.sideNavigationShown ? SIDE_NAVIGATION_WIDTH : 0
      const showDrawerWidth = this.showDrawer ? this.drawerWidth : 0
      return sideNavigationWidth + showDrawerWidth
    },
    persistentGrid() {
      return this.$store.getters.gridWithUri(this.persistentGridUri)
    },
    showDrawer() {
      return this.persistentGrid && this.$store.state.navigationDrawer
    },
    grid() {
      // Prioritising virtual grid if available, this could eventually go away if forms
      // are changed to a stateful view one day.
      if (this.virtualGridUri != null) {
        return this.$store.getters.virtualGridWithUri(this.virtualGridUri)
      } else {
        return this.$store.getters.gridWithUri(this.persistentGridUri)
      }
    },
    canGetUpdates() {
      return hasPermission(this.grid, [PERMISSIONS.updates])
    },
    canRemoveField() {
      return hasPermission(this.grid, [PERMISSIONS.removeField])
    },
    canUpdateFieldName() {
      return hasPermission(this.grid, [PERMISSIONS.updateFieldName])
    },
    canUpdateFieldType() {
      return hasPermission(this.grid, [PERMISSIONS.updateFieldType])
    }
  },
  provide() {
    return {
      canRemoveField: () => this.canRemoveField,
      canUpdateFieldName: () => this.canUpdateFieldName,
      canUpdateFieldType: () => this.canUpdateFieldType
    }
  },
  methods: {
    reloadView() {
      this.$emit('reloadView')
    },
    toggleDrawer() {
      this.$store.commit('setNavigationDrawer', !this.showDrawer)
      this.$nextTick(
        () => this.$emit('toggledDrawer')
      )
    }
  },
  components: {
    UpdateAlert
  }
}
</script>

<style lang="css" scoped>
.toolbar {
  z-index: var(--grid-toolbar-zindex);
  border-style: none none none none;
  position: fixed;
  background-color: white;
}

.form-list {
  padding-left: 3rem;
}
.v-list-item.selected {
  font-weight: bold;
}
.v-list-item.selected .v-icon {
  color: inherit;
}

.toolbar-padding {
  padding-top: 48px;
}
.view-title {
  max-width: 110px;
  text-overflow: ellipsis;
  letter-spacing: normal;
}

.toolbar-menu {
  overflow-x: auto;
  overflow-y: hidden;
}

</style>
