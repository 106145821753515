<template>
  <div
    class="groupHeaderCell d-flex align-center gap-3 mt-12"
    :style="style"
  >
  <div
    class="d-flex align-center header-content"
    :style="maxWidth"
  >
    
    <span v-if="group.group" small class="group-name text-h6 grey--text text--darken-2 mx-5">{{group.group}}</span>

    <v-spacer></v-spacer>
    <span class="group-count text-subtitle-2 grey--text text--darken-2 mr-5">
      <span class="count">{{$t('grouping.count')}}</span>
      <span>{{group.count}}</span>
    </span>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    virtualGrid: null,
    group: null,
    parentWidth: null
  },
  computed: {
    style() {
      return {
        width: `${this.virtualGrid.gridWidth()}px`,
        height: '48px'
      }
    },
    count() {
      return this.$t('grouping.count', { count: this.group.count })
    },
    maxWidth() {
      return {'max-width': `${this.parentWidth}px`}
    }
  }
}
</script>

<style scoped>
.groupHeaderCell {
  height: 32px;
  border-style: none none solid none;
  border-width: 1px;
  border-color: #d3d2d4;
  margin-left: 0px;
  flex-wrap: nowrap;
  position: relative;
}

.header-content {
  width: 100%;
  position: sticky;
  left: 0px;
}

.group-name {
  overflow: auto;
  white-space: nowrap;
}

.group-count {
  white-space: nowrap;
}

.count {
  font-size: 11px;
  opacity: 0.75;
}
</style>