<script>
/* eslint-disable no-useless-escape */
const HTTP_LINK_REGEX = /(\b(?:https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.]*[-A-Z0-9+&@#\/%=~_|])/gim

function splitLinks(text) {
  return text.split(HTTP_LINK_REGEX)
}

function linkMatches(text) {
  return text.match(HTTP_LINK_REGEX)
}

function textParts(h, text) {
  if (!text) {
    return undefined
  }
  const links = linkMatches(text)
  const splits = splitLinks(text)
  return splits.map(split => {
    if (links?.includes(split)) {
      return h('a', { attrs: { href: split, target: '_blank', class: 'linkified' } }, split)
    } else {
      return h('span', {}, split)
    }
  })
}

export default {
  functional: true,
  props: ['text'],
  render(h, context) {
    return h(
      'div',
      { },
      textParts(h, context.props.text)
    )
  }
}
</script>

<style scoped>
.linkified {
  word-break: break-all;
}
</style>
