import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class PageBlock extends Block {
  constructor(data) {
    super(data)
  }

  get name() {
    return this.page.name
  }

  set name(newVal) {
    this.page.name = newVal
  }

  async patchName(newName) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        page: {
          name: newName
        }
      }
    })
    this.name = newName
  }

  async update() {
    await store().dispatch('AGUpdateBlock', {
      block: this,
      payload: this
    })
  }

  get headerImageUrl() {
    return this.page.headerImage
  }

  set headerImageUrl(newVal) {
    this.page.headerImage = newVal
  }

  get pageBlocks() {
    return this.children.filter( aBlock => aBlock.type === 'page')
  }

  async addChildPage() {
      const newBlock = await store().dispatch('AGAddPageToBlockOrSpace', this)
      this.children.push(newBlock)
      return newBlock
  }

  isSelectedIn(blockUri) {
    return blockUri === this.uri
  }

  isChildSelectedIn(blockUri) {
    // First, check if any immediate child has the matching URI
    const isDirectChildSelected = this.children?.some(child => child.uri === blockUri)
  
    if (isDirectChildSelected) {
      return true
    }
  
    // If not found among direct children, recursively check their children
    return this.children?.some(child => child.isChildSelectedIn?.(blockUri))
  }

  async setHeaderImageUrl(url) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        page: {
          headerImage: url
        }
      }
    })
    this.headerImageUrl = url
  }
}