<template>
  <v-menu
    v-if="virtualGrid"
    v-model="menu"
    content-class="scrollable-menu"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        data-testid="gridOpenShowHideFieldsButton"
        :color="buttonColor"
        text
        v-bind="attrs"
        v-on="on"
        small
      >
        <v-icon small class="mr-sm-2">mdi-eye-off-outline</v-icon>
        <span class="d-none d-sm-flex">
          {{ buttonTitle }}
        </span>
      </v-btn>
    </template>

    <v-card class="pa-4 pt-1">
        <div v-for="(item, index) in items" :key="item.value">
          <v-switch
            class="mt-2"
            v-model="visibleColumns"
            dense
            hide-details
            hide-label
            @change="updateShownFields"
            color="primary"
            :label="item.label"
            :value="item.value"
            :data-testid="`column-visibility-switch-${index}`"
            :disabled="disabled || (visibleColumns.length == 1 && visibleColumns.includes(item.value))"
          >
            <template v-slot:label>
              <v-icon small class="mr-3">{{ item.icon }}</v-icon>
              <span class="text-subtitle-2">{{ item.label }}</span>
            </template>
          </v-switch>
        </div>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  props: {
    virtualGrid: null,
    disabled: null
  },
  data() {
    return {
      menu: false,
      visibleColumns: []
    }
  },
  created() {
    this.visibleColumns = this.items.filter(item => item.shown).map(item => item.value)
  },
  watch: {
    items: {
      handler(newVal, oldVal) {
        // needs to add new items or remove old items, this happens when a new column was added or removed
        // this will not trigger when both contain the same
        if (newVal.length != oldVal.length) {
          let visibleColumnObjects = newVal.filter(item => {
            let newItem = !oldVal.map(item => item.value).includes(item.value)
            return (item.shown || newItem)
          })
          this.visibleColumns = visibleColumnObjects.map(item => item.value)
        }
      },
    },
    menu: {
      handler(newVal) {
        if (newVal) {
          this.visibleColumns = this.items.filter(item => item.shown).map(item => item.value)
        }
      }
    }
  },
  computed: {
    numberOfHiddenColumns() {
      return this.items.filter(element => !element.shown).length
    },
    items() {
      if (this.virtualGrid == null) {
        return []
      }

      return this.virtualGrid.gridFields().map(field => {
        return {
          label: field.name,
          value: field.id,
          shown: this.virtualGrid.fields.some(viewField => viewField.id === field.id),
          icon: this.icon(field)
        }
      })
    },
    buttonColor() {
      return this.numberOfHiddenColumns > 0
        ? 'primary'
        : 'accent'
    },
    buttonTitle() {
      return this.numberOfHiddenColumns > 0
        ? `${this.numberOfHiddenColumns} ${this.$t('virtualGrid.filter.hidenColumnsButton')}`
        : this.$t('virtualGrid.filter.hideColumnButton')
    }
  },
  methods: {
    icon(field) {
      var icon = null
      try {
        icon = field.columnType.typeIcon
      } catch (error) {
        this.$apptiveErrorReporter.captureException(error)
        icon = 'mdi-progress-download'
      }
      return icon
    },
    updateShownFields() {
      // reorder visibleColumns to catch switch array behavior
      this.visibleColumns = this.items.filter(item => this.visibleColumns.includes(item.value)).map(item => item.value)
      this.virtualGrid?.updateShownFields(
        this.visibleColumns
      )
    }
  }
}
</script>

<style scoped>
.scrollable-menu {
  max-height: 80%;
}
</style>
