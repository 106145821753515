<template>
  <v-card
    class="ma-3 pa-3  d-flex flex-column justify-space-between "
    data-testid="openSpaceButton"
    elevation="4"
    :to="spaceRoute"
    min-height="120">
      <div
          class="d-flex flex-row align-center justify-space-between">
        <div>
          <SpaceIconMenu :spaceUri="spaceUri" offset-x right offset-y bottom>
            <template v-slot:activator="{on}">
              <SpaceIcon :space="space" @click.prevent="on.click"/>
            </template>
          </SpaceIconMenu>
        </div>
        <div align="end" >
          <SpaceSettingsMenu
            :spaceUri="spaceUri"
            :spaceName="spaceName"
            :realSpaceUri="realSpace"
          />
        </div>
      </div>
      <div class="d-flex flex-row align-end  justify-space-between">
          <div class="hyphens no-overflow accent--text subtitle-2 font-weight-medium">{{spaceName}}</div>
          <div v-if="this.realSpace">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <Avatar v-if="userId" size="20" :userId="userId" :email="userEmail" class="mr-1"></Avatar>
                  <v-icon v-else color="grey" small>mdi-account-multiple-outline</v-icon>
                </div>
              </template>
              <span>{{ ownerName }}</span>
            </v-tooltip>

        </div>
      </div>
  </v-card>
</template>

<script>
import SpaceSettingsMenu from '@/components/space/SpaceSettingsMenu.vue'
import SpaceIconMenu from './SpaceIconMenu.vue'
import SpaceIcon from './SpaceIcon.vue'
import Avatar from '@/components/user/Avatar.vue'

export default {
  name: 'SpaceThumbnail',
  props: {
    space: null,
    spaceUri: null,
    spaceName: null,
    realSpace: null
  },
  computed: {
    spaceRoute() {
      return {
        name: 'Space',
        params: {
          spaceUri: this.realSpace || this.spaceUri,
          sharedSpaceUri: this.realSpace ? this.spaceUri : undefined
        }
      }
    },
    owner() {
      return this.space.sharedBy
    },
    userId() {
      return this.owner?.id
    },
    userEmail() {
      return this.owner?.email
    },
    ownerName() {
      if (this.owner == null) return ''
      return `${this.owner?.firstName} ${this.owner?.lastName}`
    }
  },
  components: {
    SpaceSettingsMenu,
    SpaceIconMenu,
    SpaceIcon,
    Avatar
}
}
</script>

<style lang="css">
.hyphens {
  hyphens: auto;
}
.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
