<template>
  <EnumOptions
    v-bind="$attrs"
    v-on="$listeners"
    hideExtend
  />
</template>

<script>
import EnumOptions from './EnumOptions.vue'
export default {
  components: {
    EnumOptions
  }
}
</script>