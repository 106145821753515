export default {
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        const sanitizedValue = newVal === '' ? null : newVal
        this.$emit('input', sanitizedValue)
      }
    },
    correctedModel() {
      return this.model.includes(':') ? this.model : 'http://' + this.model
    }
  },
  methods: {
    async finishEditing() {
      if (this.model && !this.model.includes(':')) {
        this.$emit('input', this.correctedModel)
      }
      this.$emit('blur')
    }
  }
}