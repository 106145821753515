<template>
  <v-row>
    <v-col align="center">
      <v-row no-gutters justify="center">
        <v-sheet :width="codeWidth" :height="codeWidth">
          <div ref="qrCode"></div>
        </v-sheet>
      </v-row>
      <v-row justify="center" v-if="showDownloadButtons" class="ma-1">
        <PaywallMenu :feature="$apptive.constants.features.DOWNLOAD_QRCODE_SVG">
          <v-btn @click="downloadQRCodeAsSVG" elevation="0" color="primary" text><v-icon
              dense>mdi-download</v-icon>SVG</v-btn>
        </PaywallMenu>
        <v-btn @click="downloadQRCode" elevation="0" color="primary" text><v-icon dense>mdi-download</v-icon>PNG</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import QRCode from '@/qrcode/qrcode.js'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'

export default {
  props: {
    text: null,
    showDownloadButtons: {
      default: true
    },
    codeWidth: {
      default: 240
    }
  },
  data() {
    return {
      code: null,
    }
  },
  mounted() {
    if (this.text) {
      this.generateQrCode()
    }
  },
  watch: {
    text: {
      handler() {
        this.generateQrCode()
      }
    }
  },
  methods: {
    generateQrCode() {
      this.$nextTick().then(() => {
        // remove svg 
        this.code = new QRCode(this.$refs.qrCode, { text: this.text, useSVG: true, correctLevel: QRCode.CorrectLevel.L })
      })
    },
    async downloadQRCode() {
      // Generate a link for the QR Code
      const svgElement = this.$refs.qrCode.querySelector('svg')
      this.exportSvgToPng(svgElement)
    },
    async downloadQRCodeAsSVG() {
      // Generate a link for the QR Code in SVG
      const svgElement = this.$refs.qrCode.querySelector('svg')
      if (!svgElement) return
      const svgData = new XMLSerializer().serializeToString(svgElement)
      const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'QRCode.svg'
      link.click()
      URL.revokeObjectURL(url)  // Cleanup
    },
    exportSvgToPng(svg, pngName = 'QRCode') {
      const canvas = document.createElement('canvas')
      const width = svg.viewBox.baseVal.width * 3
      const height = svg.viewBox.baseVal.height * 3
      canvas.width = width
      canvas.height = height
      const data = new XMLSerializer().serializeToString(svg)
      const win = window.URL || window.webkitURL || window
      const img = new Image()
      const blob = new Blob([data], { type: 'image/svg+xml' })
      const url = win.createObjectURL(blob)

      img.onload = function () {
        canvas.getContext('2d').drawImage(img, 0, 0)
        win.revokeObjectURL(url)
        const uri = canvas.toDataURL('image/png').replace('image/png', 'octet/stream')
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.href = uri
        a.download = pngName + '.png'
        a.click()
        window.URL.revokeObjectURL(uri)
        document.body.removeChild(a)
      }
      img.src = url
    },
  },
  components: {
    PaywallMenu
  }
}
</script>