<template>
  <div v-if="block" class="block-page-root" data-testid="blockPage">
    <LoadingOverlay :show="loading" />
    <v-img
      v-if="headerImageUrl"
      :src="headerImageUrl"
      class="headerImage"
    />
    <v-container
      v-if="block != null"
      :class="{ 'mobile-container': $vuetify.breakpoint.mobile && canUpdate }"
    >
      <!-- Grid system with a single row and centered content -->
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          md="9"
          lg="8"
        >
        <AGDraggable
          :disabled="!canUpdate"
          v-model="block.children"
          @change="onDragChange"
          handle=".grabbable"
        >
          <div
            class=""
            v-for="(child, i) in block.children"
            :key="i"
          >
            <div class="col-12">

              <component
                :ref="`block_${child.id}`"
                :is="component(child)"
                :blockUri="child.uri"
                :editorMode="canEdit"
                @updateBlock="() => updateBlock(child)"
                @delete="deleteChild(child)"
                @addBlockAfter="() => blockPickerIndex = i + 1"
                @addParagraphBlock="text => addParagraphBlock(text, i + 2, false)"
              />
              <!-- Conditionally display the block picker after the block if it's the chosen index -->
            </div>
            <BlockPicker
              :autofocus="true"
              v-if="blockPickerIndex === i + 1"
              @blockSelected="(type, defaultProps) => addChild(type, i + 2, defaultProps)"
              @addParagraphBlock="text => addParagraphBlock(text, i + 2)"
            />
          </div>
        </AGDraggable>
          <BlockPicker
            v-if="canAddChild"
            data-testid="blockPicker"
            @blockSelected="(type, defaultProps) => addChild(type, block.childCount + 1, defaultProps)"
            @addParagraphBlock="text => addParagraphBlock(text, block.childCount + 1)"
          />
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import BlockPicker from '@/components/block/editor/BlockPicker.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import CellInputBlock from '@/components/block/CellInputBlock.vue'
import ParagraphBlock from '@/store/models/blocks/ParagraphBlock'
import ParagraphBlockVue from '@/components/block/ParagraphBlock.vue'
import HeaderBlock from '@/store/models/blocks/HeaderBlock'
import HeaderBlockVue from '@/components/block/HeaderBlock.vue'
import PageBlockVue from '@/components/block/PageBlockVue.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import AGDraggable from '@/components/AGDraggable.vue'
import EmbedBlock from '@/store/models/blocks/EmbedBlock'
import EmbedBlockVue from '@/components/block/EmbedBlock.vue'
import PageBlock from '@/store/models/blocks/PageBlock'
import VideoBlock from '@/store/models/blocks/VideoBlock'
import VideoBlockVue from '@/components/block/VideoBlock.vue'

export default {
  props: {
    blockUri: {
      type: String
    },
    editorMode: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: false,
      blockPickerIndex: null
    }
  },
  computed: {
    headerImageUrl() {
      return this.block?.headerImageUrl
    },
    canAddChild() {
      return hasPermission(this.block, [ PERMISSIONS.addChild ]) && this.editorMode
    },
    canEdit() {
      return hasPermission(this.block, [ PERMISSIONS.patch ]) && this.editorMode
    },
    canUpdate() {
      return hasPermission(this.block, [ PERMISSIONS.update ]) && this.editorMode
    },
    title() {
      return this.block?.name || this.$t('blocks.emptyPageTitle')
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    }
  },
  watch: {
    blockUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null) {
          return
        }
        this.loading = true
        try {
          this.loadBlock()
        } finally {
          this.loading = false
        }
      }
    },
    title: {
      immediate: true,
         handler(newVal) {
          document.title = newVal
        }
    }
  },
  provide() {
    return {
      canAddChild: () => this.canAddChild
    }
  },
  methods: {
    async loadBlock() {
      try {
        if(this.block) {
          await this.block.reload()
        }
        else {
          console.log('page loads block')
          await this.$store.dispatch('AGReadBlock', { blockUri: this.blockUri, customErrorHandling: [400]})
        }
        // New pages come with an empty header, focus it
        if (this.block.children.length === 1 && this.block.children[0] instanceof HeaderBlock && !this.block.children[0].text) {
          await this.$nextTick()
          this.focus(this.block.children[0])
        }
      } catch (error) { 
          if (error.response?.status === 400) {
            this.$emit('unaccessibleView')
          }
      }
    },
    async deleteChild(child) {
      if (!this.canEdit) {
        return
      }
      child.deleted = true
      await child.deleteBlockIn(this.block)
      this.loadBlock()
    },
    async addChild(type, position, defaultProps = {}) {
      if (!this.canEdit) {
        return
      }
      if (type == null) {
        this.blockPickerIndex = null
        return
      }
      const newBlock = await this.$store.dispatch('AGAddBlockChild', {
        block: this.block,
        type: type,
        position: position,
        blockProps: {
          [type]: {
            ...defaultProps
          }
        }
      })
      this.focus(newBlock)
    },
    async addParagraphBlock(text, position, focusPicker = true) {
      if (!this.canEdit) {
        return
      }
      const newParagraphBlock = await this.$store.dispatch('AGAddBlockChild', {
        block: this.block,
        type: 'paragraph',
        position: position
      })
      if (text) {
        newParagraphBlock.text = text
        await newParagraphBlock.updateValue()
      }

      await this.$nextTick()
      if (focusPicker) {
        this.blockPickerIndex = position
      } else {
        this.focus(newParagraphBlock)
      }
    },
    component(block) {
      if (block instanceof ParagraphBlock) {
        return ParagraphBlockVue
      } else if (block instanceof HeaderBlock) {
        return HeaderBlockVue
      } else if (block instanceof EmbedBlock) {
        return EmbedBlockVue
      }else if (block instanceof PageBlock) {
        return PageBlockVue
      } else if (block instanceof VideoBlock) {
        return VideoBlockVue
      }

      return CellInputBlock
    },
    focus(block) {
      this.$refs[ `block_${block.id}` ][ 0 ].focus?.()
    },
    async updateBlock(block) {
      if (block.deleted || !this.canEdit) {
        return
      }
      block.updateValue()
      const firstHeader = this.block.children.find(child => child instanceof HeaderBlock)
      if (!this.block.name && firstHeader != null && firstHeader.uri === block.uri) {
        await this.block.patchName(block.text)
      }
    },
    onDragChange() {
      this.block.update()
    },
  },
  components: {
    CellInputBlock,
    BlockPicker,
    LoadingOverlay,
    AGDraggable
  }
}
</script>

<style scoped>
.block-page-root {
  width: 100%;
  background: white;
  position: inherit;
  overflow: auto;
}

.headerImage {
  object-fit: cover;
  max-height: 240px;
  min-height: 220px;
}

.mobile-container {
  padding-left: 48px;
}
</style>
