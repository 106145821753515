<template>
  <div>
    <ExtractFieldDialog
      v-model="extractFieldDialog"
      :disabled="disableExtractField"
      :virtualGrid="virtualGrid"
    />
    <GridFieldKeysDialog
      v-model="gridKeysDialog"
      :disabled="disableEditFieldKeys"
      :virtualGrid="virtualGrid"
    />
    <SetGridKeyDialog
      v-model="setGridKeyDialog"
      :disabled="disableSetKey"
      :gridUri="virtualGridUri"
    />
    <v-menu offset-y  v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="accent"
          data-testid="showVirtualGridMoreMenuButton"
          v-bind="attrs"
          v-on="on"
          icon
          small
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <CSVImportEntitiesDialog
          :disabled="disableImport"
          :virtualGrid="virtualGrid"
        />
        <MenuItem
          :disabled="!canExportCSV"
          @click="exportCSVViaBlob"
          icon="mdi-download"
          :text="$t('virtualGrid.moreMenu.csvExportButton')"
          data-testid="exportCsvButton"
        />
        <MenuItem
          :disabled="disableClone"
          @click="cloneVirtualGrid"
          icon="mdi-table-multiple"
          :text="$t('virtualGrid.moreMenu.cloneVirtualGrid')"
          data-testid="gridSaveFilterButton"
        />
        <RenameVirtualGridDialog
          :disabled="disableRename"
          :virtualGrid="virtualGrid"
        />
        <MenuItem
          :disabled="disableExtractField"
          @click="extractFieldDialog = true"
          icon="mdi-table-arrow-right"
          :text="$t('dialogs.extractFieldButton')"
          data-testid="extractFieldActivator"
        />
        <DeleteVirtualGridDialog
          :disabled="disableDelete"
          :virtualGrid="virtualGrid"
          v-on:viewDeleted="viewDeleted"
        />
        <SubMenu
            :activatorText="$t('dialogs.more')"
          >
          <MenuItem
            :disabled="disableEditFieldKeys"
            @click="gridKeysDialog = true"
            icon="mdi-pound"
            :text="$t('dialogs.gridKeysDialogButton')"
          />
          <MenuItem
            :disabled="disableSetKey"
            icon="mdi-pound"
            :text="this.$t('dialogs.setGridKeyButton')"
            data-testid="setGridKeyActivator"
            @click="setGridKeyDialog = true; menu = false"
          />
          <MenuItem
            @click="setURLClipboard(virtualGrid._links.self.href)"
            icon="mdi-content-copy"
            :text="$t('dialogs.copyEntryUri')"
            data-testid="copyVirtualGridUri"
          />
        </SubMenu>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import DeleteVirtualGridDialog from '@/components/gridView/DeleteVirtualGridDialog.vue'
import RenameVirtualGridDialog from '@/components/gridView/RenameVirtualGridDialog.vue'
import GridFieldKeysDialog from '@/components/gridView/GridFieldKeysDialog.vue'
import ExtractFieldDialog from '@/components/gridView/ExtractFieldDialog.vue'
import SetGridKeyDialog from '@/components/grid/SetGridKeyDialog.vue'
import MenuItem from '@/components/MenuItem.vue'
import SubMenu from '@/components/SubMenu.vue'
import CSVImportEntitiesDialog from '@/components/grid/CSVImportEntitiesDialog.vue'
import { axios } from '@/ApiClient/index.js'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'

export default {
  props: {
    virtualGrid: null,
    disableClone: null,
    disableDelete: null,
    disableRename: null,
    disableImport: null,
    disableEditFieldKeys: null,
    disableSetKey: null,
    disableExtractField: null
  },
  data() {
    return {
      menu: false,
      gridKeysDialog: false,
      setGridKeyDialog: false,
      extractFieldDialog: false
    }
  },
  computed: {
    virtualGridUri() {
      return this.virtualGrid?.uri
    },
    canExportCSV() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.exportAsCsv])
    }
  },
  methods: {
    exportCSVViaBlob() {
      axios({
        url: this.virtualGrid.getLink(PERMISSIONS.exportAsCsv),
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const fileName = response.headers['content-disposition']?.match(/filename="(.+)"/)?.[1]
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName ?? 'file.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    cloneVirtualGrid() {
      this.$emit('cloneVirtualGrid')
    },
    viewDeleted() {
      this.menu = false
    },
    setURLClipboard(value) {
      navigator.clipboard.writeText(`${window.location.origin}${value}`)
    }
  },
  components: {
    DeleteVirtualGridDialog,
    RenameVirtualGridDialog,
    MenuItem,
    CSVImportEntitiesDialog,
    GridFieldKeysDialog,
    SetGridKeyDialog,
    ExtractFieldDialog,
    SubMenu
  },
}
</script>

<style lang="css"></style>
