<template>
  <v-treeview dense :search="search" :open.sync="open"  :open-on-click="openOnClick" :items="tree" v-on="$listeners" class="ouput-tree">
    <template v-slot:prepend="{ item }">
      <span
        v-if="!disabled && item.depth > 0"
        class="token font-weight-medium px-1"
        :class="{ highlight: `{{${item.expression}}}` === highlightedExpression }"
        @click="() => onItemClicked(item)"
        @dragstart="() => onItemDragged(item)"
        v-text="item.name"
        :style="`background-color: ${item.color};`"
        :draggable="draggable"
        :data-testid="`variable-${item.name}`"
      />
      <span
        v-else
        class="body-2"
        :style="{ color: item.color }"
      >

      {{ item.name }}:
      <v-btn x-small @click.stop="copyToClipboard(value)" v-show="item.depth == 0" icon color="accent lighten-3"><v-icon small>mdi-content-copy</v-icon></v-btn>

    </span>
    </template>
    <template v-slot:label="{ item }">
      <span class="accent--text body-2 wrap-text ">{{ item.value }}</span>
    </template>
  </v-treeview>
</template>

<script>
import { ASValueTreeNode } from '../../apptivescript/apptivescriptUtils'

export default {
  props: {
    value: null,
    flow: null,
    disabled: null,
    filterNode: null,
    draggable: {
      type: Boolean,
      default: () => false
    },
    openOnClick: {
      type: Boolean,
      default: () => false
    },
    openAll: {
      type: Boolean,
      default: () => false
    },
    search: null
  },
  data() {
    return {
      highlightedExpression: undefined,
      open: []
    }
  },
  watch: {
    openAll: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.expandAll()
        }
        else {
          this.open = []
        }
      }
    }
  },
  mounted() {
    document.addEventListener('highlightExpression', this.handleHighlightEvent)
  },
  beforeDestroy() {
    document.removeEventListener('highlightExpression', this.handleHighlightEvent)
  },
  computed: {
    tree () {
      if( this.value == null || typeof(this.value) !== 'object') return
      return Object.keys(this.value)
        .filter( key => !this.filterNode || this.ancestors.some(node => node.id === key))
        .map(stepId => {
          const node = this.flow.nodes.find(node => node.id === stepId)
          const stepExpression = `step('${stepId}').`
          const name = node ? node.name : stepId
          const color = node ? node.color : 'grey'
          return this.asTreeObject(this.value[stepId], stepExpression, name, color)
        })
    },
    ancestors() {
      return this.flow.ancestorsOf(this.filterNode)
    }
  },
  methods: {
    onItemClicked(item) {
      this.$emit('itemClicked', item)
    },
    onItemDragged(item) {
      this.$emit('itemDragged', item)
    },
    asTreeObject(object, stepExpression, name, color, asValueTreeNode, depth = 0, parentIsArray = false) {
      let expression = ''
      let nodeName = name
      if (!this.disabled) {
        try {
          // HACK : apptivescript.js does not distinguish between
          // Objects and Dictionaries access (.output vs get('output'))
          // Hence i had to manually build the expression for the first level of the tree
          if (depth === 1) {
            if (parentIsArray) {
              stepExpression += `get(${name})`
            } else {
              stepExpression += `${name}`
            }
            expression = () => stepExpression
            nodeName = name
            if (object != null && (typeof object === 'object' || Array.isArray(object))) {
              asValueTreeNode = new ASValueTreeNode(object)
            }
          }
          if (depth > 1) {
            expression = () => stepExpression + '.' + asValueTreeNode.query
          }
        } catch (error) {
          this.$apptiveErrorReporter.captureException(error)
        }
      }

      let treeNode = {
        name: nodeName,
        stepExpression,
        expression,
        depth,
        color,
        id: stepExpression + depth + nodeName
      }

      if (object != null && (typeof object === 'object' || Array.isArray(object))) {
        treeNode.children = Object.keys(object)
          .slice(0, 100)
          .map((key, index) => {
            return this.asTreeObject(
              object[key],
              stepExpression,
              key,
              color,
              asValueTreeNode?.children[index],
              depth + 1,
              Array.isArray(object)
            )
          })
        if (Object.keys(object).length >= 100) {
          treeNode.children.push({
            name: 'Too many items... '
          })
        }
      } else {
        treeNode.value = object
      }

      return treeNode
    },
    async handleHighlightEvent(event) {
      this.highlightedExpression = event.detail
      await this.$nextTick()
      const element = document.querySelector('.highlight')
      element?.scrollIntoView({behavior: 'smooth', block: 'center'})
    },
    expandAll() {
      this.open = this.getAllIds(this.tree)
    },
    getAllIds(items) {
      let ids = []
      items.forEach(item => {
        ids.push(item.id)
        if (item.children) {
          ids = ids.concat(this.getAllIds(item.children))
        }
      })
      return ids
    },
    copyToClipboard(item) {
      navigator.clipboard.writeText(JSON.stringify(item))    
    }
  }
}
</script>

<style scoped>
.token {
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: font-size .3s;
}

.highlight {
  font-size: 24px;
}

.ouput-tree {
  width: fit-content;
  align-content: start;
}

.wrap-text {
  word-wrap: break-word;
  white-space: normal;
}

</style>

<style>
.v-treeview-node__content {
  align-items: start !important;
}
</style>
