<template>
<v-list-item :disabled="disabled" class="d-flex" v-on="$listeners" v-bind="$attrs">
  <v-list-item-icon small class="mr-1">
    <v-badge
      :value="badge"
      dot
      color="error"
    >
      <!-- The top margin is here to center the small icon (16px)
      into the v-list-item-icon fixed size of 24 px -->
      <v-icon class="mt-1" :disabled="disabled" small>{{icon}}</v-icon>
    </v-badge>
  </v-list-item-icon>
  <v-list-item-title :class="{'accent--text': !noTextColor && !disabled}">
    {{text}}
  </v-list-item-title>
  <v-list-item-icon v-if="appendIcon" class="mr-1">
      <v-icon :disabled="disabled" small>{{appendIcon}}</v-icon>
  </v-list-item-icon>
</v-list-item>
</template>

<script>
export default {
  props: {
    icon: null,
    text: null,
    noTextColor: null,
    disabled: null,
    appendIcon: null,
    badge: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>