import FlowInstance from '@/store/models/flow/FlowInstance.js'
import Vue from 'vue'
import ApiClient from '../../ApiClient'

export default {
  state() {
    return {
      flowInstances: {}
    }
  },
  mutations: {
    addFlowInstance(state, flowInstance) {
      Vue.set(state.flowInstances, flowInstance.uri, flowInstance)
    },
    removeForm(state, flowInstance) {
      delete state.flowInstances[flowInstance]
    }
  },
  actions: {
    async AGReadFlowInstanceOperation(context, instance) {
      const link = instance._links.self.href
      const response = await ApiClient.getUri(link)
      const flowInstance = new FlowInstance(response.data)
      context.commit('addFlowInstance', flowInstance)
      return flowInstance
    },
    async AGCreateFlowInstanceLinkOperation(context, instance) {
      const link = instance._links.hookUri.href
      const response = await ApiClient.postUri(link, {})
      return response.data
    },
  },
  getters: {
    flowInstanceWithUri: state => uri => {
      return state.flowInstances[uri]
    }
  }
}
