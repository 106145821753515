<template>
  <div>
    <v-menu
      :disabled="disabled"
      v-model="selectMenu"
      bottom offset-y
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
          small
          
          text 
          color=""
          v-on="on" v-bind="attrs"
          :loading="loading"
          >{{ display }}
          <v-icon v-if="!disabled">mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <slot 
      v-for="(item, index) in items"
      name="item" :item="item" :onClick="() =>$emit('input', item.value)">
      <v-list-item
        class="white"
        two-line
        dense
        :key="index"
        :data-testid="`selectOption:${item.value}`"
      >
          <v-list-item-content @click="onClick">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
            <v-list-item-subtitle>{{
              item.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </slot>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ['value', 'items', 'loading', 'disabled'],
  data() {
    return {
      selectMenu: false
    }
  },
  computed: {
    display() {
      return this.items.find(item => item.value === this.value)?.text ?? ''
    },
    selectModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

