<template>
  <v-menu offset-y v-bind="$attrs" v-model="menuModel">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"> </slot>
    </template>

    <v-list dense>
      <EditEntityDialog
        :virtualGrid="virtualGrid"
        :entity="entity"
        v-model="editEntityDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <MenuItem
            v-on="on"
            v-bind="attrs"
            icon="mdi-arrow-expand"
            :text="$t('dialogs.editEntity')"
            :noTextColor="true"
          />
        </template>
      </EditEntityDialog>
      <EntityShareMenu
        class="flex-grow-0"
        :virtualGrid="virtualGrid"
        :entity="entity"
      >
        <template v-slot:activator="{ on, attrs }">
          <MenuItem
            :disabled="!canShareEntity"
            v-on="on"
            v-bind="attrs"
            icon="mdi-share"
            :text="$t('virtualGrid.rowMoreMenu.share')"
            :noTextColor="true"
            data-testid="shareEntityActivator"
          />
        </template>
      </EntityShareMenu>
      <MenuItem
        @click="setURLClipboard(entity._links.self.href)"
        icon="mdi-content-copy"
        :text="$t('dialogs.copyEntryUri')"
        :noTextColor="true"
        data-testid="copyEntryUri"
      />
      <MenuItem
        :disabled="!canDeleteEntity"
        @click="deleteEntity"
        icon="mdi-delete-outline"
        :text="$t('dialogs.deleteButton')"
        :noTextColor="true"
        data-testid="deleteEntryButton"
      />
    </v-list>
  </v-menu>
</template>

<script>
import EditEntityDialog from '@/components/gridView/EditEntityDialog.vue'
import MenuItem from '@/components/MenuItem.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'
import reloadVirtualGrid from '@/mixins/reloadVirtualGrid.js'

export default {
  mixins: [reloadVirtualGrid],
  props: {
    entity: null,
    virtualGrid: null,
    value: null
  },
  data() {
    return {
      editEntityDialog: false
    }
  },
  computed: {
    menuModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    canDeleteEntity() {
      return hasPermission(this.entity, [PERMISSIONS.remove])
    },
    canShareEntity() {
      return hasPermission(this.entity, [PERMISSIONS.addEditionLink])
    }
  },
  methods: {
    deleteEntity() {
      this.virtualGrid.deleteEntity(this.entity)
    },
    setURLClipboard(value) {
      navigator.clipboard.writeText(`${window.location.origin}${value}`)
    }
  },
  components: {
    EditEntityDialog,
    EntityShareMenu: () => import('@/components/gridView/EntityShareMenu.vue'),
    MenuItem
  }
}
</script>
