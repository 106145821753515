<template>
  <v-menu
    ref="kanbanMenu"
    v-model="modal"
    offset-x
    top
    open-delay="400"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"/>
    </template>

    <v-card
      max-width="320px"
      >
      <v-img height="200" :src="image"></v-img>
      <v-card-title>{{title}}</v-card-title>
      <v-card-subtitle>{{subtitle}}</v-card-subtitle>
      <v-card-text v-html="text"/>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: [ 'title' , 'subtitle', 'text', 'image'],
  data() {
    return {
      modal: false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
