export async function checkFeatureLimit(store, plan, feature, count, spaceUri) {
  if (plan == null || feature == null || count == null) {
    return
  }
  // Hack for having no restrictions in a bmp space
  if (plan === 'unlimited') {
    return false
  }
  const featureLimits = await store.dispatch('AGGetFeatureLimits', spaceUri)
  let limit = featureLimits[plan][feature]
  // some limits are functions that perform a fetch 
  if(typeof limit === 'function')
  {
    limit = await limit()
  }

  if (limit === 'unlimited') {
    return false
  } else {
    return count >= limit
  }
}

export default {
  props: {
    feature: null,
    count: {
      type: Number,
      default: () => 0
    }
  },
  inject: {
    plan: {
      default: () => () => null
    },
    spaceUri: {
      default: undefined
    }
  },
  data() {
    return {
      plans: [ 'plus', 'pro' ],
      paywallActive: true,
      loading: false
    }
  },
  watch: {
    planFeatureCount: {
      immediate: true,
      async handler() {
        this.loading = true
        try {
          const isLimitReached = await checkFeatureLimit(this.$store, this.plan() , this.feature, this.count, this.spaceUri)
          if (isLimitReached != null) {
            this.paywallActive = isLimitReached
          }
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    planFeatureCount() {
      return this.plan?.() + this.feature + this.count
    },
  }
}
