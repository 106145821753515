import i18n from '@/plugins/i18n'
import moment from 'moment'
import store from '@/store/store'
import { columnTypes } from '@/constants/columnTypes'
import { DATE_EXPORT_FORMAT } from '@/constants/dateFormats'
import { ASExpression } from '../../apptivescript/apptivescriptUtils'

export function AGExpression() { }
AGExpression.prototype.matches = () => false
AGExpression.prototype.parseArgument = () => []
AGExpression.prototype.hasArgument = false

export function AGToday() {
  this.displayString = () => i18n.t('expressions.today')
  this.exportValue = () => '{{today()}}'
  this.resolveValue = () => moment().format(DATE_EXPORT_FORMAT)
}
AGToday.prototype = Object.create(AGExpression.prototype)
AGToday.prototype.constructor = AGToday
AGToday.prototype.matches = (string) => string === '{{today()}}'

export function AGYesterday() {
  AGDaysAgo.call(this, 1)
  this.displayString = () => i18n.t('expressions.yesterday')
}
AGYesterday.prototype = Object.create(AGExpression.prototype)
AGYesterday.prototype.constructor = AGYesterday
AGYesterday.prototype.matches = (string) => string === '{{today().subtractDays(1)}}'

export function AGTomorrow() {
  AGDaysFromToday.call(this, 1)
  this.displayString = () => i18n.t('expressions.tomorrow')
}
AGTomorrow.prototype = Object.create(AGExpression.prototype)
AGTomorrow.prototype.constructor = AGTomorrow
AGTomorrow.prototype.matches = (string) => string === '{{today().addDays(1)}}'

export function AGDaysAgo(days) {
  this.internalValue = days
  this.displayString = () => i18n.t('expressions.daysAgo')
  this.exportValue = () => `{{today().subtractDays(${days})}}`
  this.resolveValue = () => {
    if (days == null || days < 0) {
      return undefined
    }
    return moment().subtract({ days: days }).format(DATE_EXPORT_FORMAT)
  }
}
AGDaysAgo.prototype = Object.create(AGExpression.prototype)
AGDaysAgo.prototype.constructor = AGDaysAgo
AGDaysAgo.prototype.hasArgument = true
AGDaysAgo.prototype.expressionArguments = () => [columnTypes.integer]
AGDaysAgo.prototype.matches = (string) => {
  return typeof string === 'string'
    && string?.startsWith('{{today().subtractDays(') && string?.endsWith(')}}')
}
AGDaysAgo.prototype.parseArgument = (string) => {
  const matches = string.match(/\d+/)
  return [parseInt(matches[0])]
}

export function AGDaysFromToday(days) {
  this.internalValue = days
  this.displayString = () => i18n.t('expressions.daysFromToday')
  this.exportValue = () => `{{today().addDays(${days})}}`
  this.resolveValue = () => {
    if (days == null || days < 0) {
      return undefined
    }
    return moment().add({ days }).format(DATE_EXPORT_FORMAT)
  }
}
AGDaysFromToday.prototype = Object.create(AGDaysAgo.prototype)
AGDaysFromToday.prototype.constructor = AGDaysFromToday
AGDaysFromToday.prototype.matches = (string) => {
  return typeof string === 'string'
    && string?.startsWith('{{today().addDays(') && string?.endsWith(')}}')
}



export function AGLoggedInUser() {
  this.displayString = () => i18n.t('expressions.loggedInUser')
  this.exportValue = () => '{{loggedInUser()}}'
  this.resolveValue = () => store().state.user.user
}
AGLoggedInUser.prototype = Object.create(AGExpression.prototype)
AGLoggedInUser.prototype.constructor = AGLoggedInUser
AGLoggedInUser.prototype.matches = (string) => string === '{{loggedInUser()}}'

const StepOutputRegex = /{{(step\('([a-z0-9]+)'\)\.(.+))}}/
export function AGStepOutput(expression, nodeId) {
  this.nodeId = nodeId
  this.expression = expression
  this.color = (flow) => {
    const node = flow.nodes.find(node => node.id === this.nodeId)
    if (node == null) {
      throw new Error(`Error: node ${this.nodeId} not found`)
    }
    return node.color
  }
  this.displayString = () => {
    const expression = new ASExpression(this.expression)
    return expression.displayString
  }
  this.exportValue = () => `{{${this.expression}}}`
  this.resolveValue = () => undefined
}
AGStepOutput.prototype = Object.create(AGExpression.prototype)
AGStepOutput.prototype.constructor = AGStepOutput
AGStepOutput.prototype.matches = (string) => StepOutputRegex.test(string)
AGStepOutput.prototype.parseArgument = (string) => {
  const matches = string.match(StepOutputRegex)
  return [matches[1], matches[2]]
}

export function AGValueExpression(value) {
  this.displayString = (type) => {
    if (type.name === columnTypes.date.name) {
      return i18n.t('expressions.exactDate')
    } else {
      return i18n.t('expressions.exactValue')
    }
  }
  this.internalValue = value
  this.exportValue = () => this.internalValue
  this.resolveValue = () => this.internalValue
}
AGValueExpression.prototype = Object.create(AGExpression.prototype)
AGValueExpression.prototype.constructor = AGValueExpression
AGValueExpression.prototype.hasArgument = true

export function AGFlowGlobalVariableExpression() { }
AGFlowGlobalVariableExpression.prototype = Object.create(AGExpression.prototype)
AGFlowGlobalVariableExpression.prototype.color = () => 'purple'

export function AGOwnerEmailExpression() {
  this.displayString = () => i18n.t('expressions.ownerEmail')
  this.exportValue = () => 'owner.email'
  this.resolveValue = () => i18n.t('expressions.ownerEmail')
}
AGOwnerEmailExpression.prototype = Object.create(AGFlowGlobalVariableExpression.prototype)
AGOwnerEmailExpression.prototype.constructor = AGOwnerEmailExpression
AGOwnerEmailExpression.prototype.matches = (string) => string === '{{owner.email}}'

export function AGOwnerFirstNameExpression() {
  this.displayString = () => i18n.t('expressions.ownerFirstName')
  this.exportValue = () => 'owner.firstName'
  this.resolveValue = () => i18n.t('expressions.ownerFirstName')
}
AGOwnerFirstNameExpression.prototype = Object.create(AGFlowGlobalVariableExpression.prototype)
AGOwnerFirstNameExpression.prototype.constructor = AGOwnerFirstNameExpression
AGOwnerFirstNameExpression.prototype.matches = (string) => string === '{{owner.firstName}}'

export function AGOwnerLastNameExpression() {
  this.displayString = () => i18n.t('expressions.ownerLastName')
  this.exportValue = () => 'owner.lastName'
  this.resolveValue = () => i18n.t('expressions.ownerLastName')
}
AGOwnerLastNameExpression.prototype = Object.create(AGFlowGlobalVariableExpression.prototype)
AGOwnerLastNameExpression.prototype.constructor = AGOwnerLastNameExpression
AGOwnerLastNameExpression.prototype.matches = (string) => string === '{{owner.lastName}}'

export const EXPRESSIONS = {
  AGExpression,
  AGToday,
  AGYesterday,
  AGTomorrow,
  AGDaysAgo,
  AGDaysFromToday,
  AGLoggedInUser,
  AGStepOutput,
  AGValueExpression,
  AGFlowGlobalVariableExpression,
  AGOwnerEmailExpression,
  AGOwnerFirstNameExpression,
  AGOwnerLastNameExpression,
}
