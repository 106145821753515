<template>
<SingleInputDialog
  :disabled="disabled"
  v-model="externalModel"
  :title="$t('dialogs.renameSpaceTitle', { spaceTitle: spaceName })"
  :text="$t('dialogs.renameSpaceText')"
  :okButtonLabel="$t('dialogs.saveButton')"
  :okHandler="save"
  :initialInputValue="spaceName"
  :max="$apptive.constants.nameLengths.space"
  max-width="290"
>
  <template v-slot:activator="{ on, attrs }">
    <slot name="activator" :on="on" :attrs="attrs">
    </slot>
  </template>
</SingleInputDialog>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  name: 'RenameSpaceDialog',
  props: {
    spaceUri: null,
    spaceName: null,
    disabled: null
  },
  methods: {
    save(newName) {
      if (this.spaceName == newName) {
        return
      }
      return this.$store.dispatch('AGRenameSpaceOperation', {
        spaceUri: this.spaceUri,
        newName: newName
      })
    }
  },
  components: {
    SingleInputDialog,
  }
}
</script>

<style lang="css"></style>
