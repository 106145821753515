<template>
  <div
    class="groupFooter d-flex"
    :style="style"
  >
  <div class="add-button-wrapper d-flex justify-center align-center"
    :style="`width: ${virtualGrid.prependCellWidth}px;`"
  >
    <v-btn
       v-if="!loading"
       color="grey"
       class="add-button"
       dark
       icon
       small
       @mousedown="addRow"
       data-testid="addRowButton"
     >
       <v-icon>mdi-plus</v-icon>
     </v-btn>
     <v-progress-circular
       v-else
       indeterminate
       :width="1"
       :size="16"
       color="rgb(158, 158, 158)"
       class="ml-2"
     />
  </div>
    <div
      v-for="(field, index) in virtualGrid.fields"
      :key="`footer-cell-${field.id}`"
      class="cell-content pl-2 border-right cell grey--text text--darken-3"
      :style="readonlyCellStyle(field)"
    >
      <span v-if="sumAt(index) != null" class="sum">{{$t('virtualGrid.sum')}}</span>
      <span>{{sumAt(index)}}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    virtualGrid: null,
    statefulView: null,
    group: null,
    entities: null,
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    readonlyCellStyle(field) {
      return {
        width: `${this.virtualGrid.fieldWidthById(field.id)}px`,
        overflow: 'hidden',
      }
    },
    sumAt(index) {
      return this.sums[index] != null ? ` ${this.sums[index]}` : undefined
    },
    addRow() {
      this.$emit('addRow')
    }
  },
  computed: {
    style() {
      return {
        width: `${this.virtualGrid.gridWidth()}px`,
        height: '32px'
      }
    },
    count() {
      return this.$t('grouping.count', { count: this.group.count })
    },
    sums() {
      const sums = this.virtualGrid.fields.map(field => {
        return field.columnType.sum ? 0 : null
      })
      this.entities?.forEach(entity => {
        entity.fields.forEach((value, index) => {
          if (sums[index] != null) {
            const fieldValue = value && typeof value === 'object' ? value.value : value
            sums[index] += fieldValue
          }
        })
      })
      return sums
    }
  },
}
</script>

<style scoped>
.groupFooter {
  height: 32px;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #d3d2d4;
  background-color: #f9f9f9;
  margin-left: 0px;
  flex-wrap: nowrap;
}

.cell-content{
  font-size: 0.9em !important;
  white-space: nowrap;
  line-height: 31px;
  text-overflow: ellipsis;
}

.border-right {
  border-style: none none none solid;
  border-width: 1px;
  border-color: #DEE1E3;
}

.sum {
  font-size: 11px;
  opacity: 0.75;
}
</style>