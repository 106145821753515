<template>
  <TemplateInput ref="input" :textArea="true" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import TemplateInput from './TemplateInput.vue'

export default {
  methods: {
    getInternalValue() {
      return this.$refs.input.getInternalValue()
    },
    insert(text) {
      this.$refs.input.insert(text)
    }
  },
  components: { TemplateInput },
}
</script>

<style>

</style>