export function noValue(value) {
  // Number.isNaN tests strictly if the value is a Number
  // and if its value is NaN without type coercion, unlike the vanilla
  // JS isNaN function, see :
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
  return value === null || value === undefined || Number.isNaN(value)
}

export function isEmpty(value) {
  return noValue(value) || value === ''
}

// Prepare a UTF-8 encoded string to be correctly encoded to base64 by btoa
// (Mainly useful for setting BasicAuth Authentication headers)
export function btoaUtf8Hack(string) {
  return unescape(encodeURIComponent(string))
}