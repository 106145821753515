/* global google */
const GOOGLE_SCRIPT_ID = 'googleMapScript'

function loadGoogleMaps(locale) {
  let previousScript = document.querySelector('#' + GOOGLE_SCRIPT_ID)
  if (!previousScript) {
    var newScript = document.createElement('script')
    newScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCL_1k6THkOcYPz6JkG6GariBHvaK2hr9I&libraries=places&language=' + locale
    newScript.id = GOOGLE_SCRIPT_ID
    document.head.appendChild(newScript)
  }
}

function reloadGoogleMaps(locale) {
  let previousScript = document.querySelector('#' + GOOGLE_SCRIPT_ID)
  if (previousScript) {
    previousScript.remove()
    delete google.maps
  }
  var newScript = document.createElement('script')
  newScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCL_1k6THkOcYPz6JkG6GariBHvaK2hr9I&libraries=places&language=' + locale
  newScript.id = GOOGLE_SCRIPT_ID
  document.head.appendChild(newScript)
}

function onGoogleMapsLoaded(callback) {
  // if google is already loaded, call the callback immediately
  if (typeof google === 'object' && typeof google.maps === 'object') {
    callback()
  } else {
    let script = document.querySelector('#' + GOOGLE_SCRIPT_ID)
    if (script) {
      script.onload = callback
    }
  }
}

export {
  loadGoogleMaps,
  reloadGoogleMaps,
  onGoogleMapsLoaded
}
