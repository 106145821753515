<template>
  <GridCellFactory
    class="field-cell pa-2"
    solo
    roundedBorders
    :editEntityMode="true"
    :value="externalModel"
    :displayValue="displayValue"
    :type="columnType"
    :field="blockField"
    fontSize="1rem"
    v-on:input="(value) => externalModel = value"
    v-on:blur="$emit('blur')"
  />
</template>

<script>
import GridCellFactory from '@/components/gridView/GridCellFactory.vue'
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [
    externalModel
  ],
  props: {
    blockUri: {
      type: String,
      default: () => null
    },
  },
  computed: {
    columnType() {
      return this.block.columnType
    },
    displayValue() {
      return this.columnType?.displayFormat
        ? this.columnType.displayFormat(this.externalModel)
        : this.externalModel
    },
    blockField() {
      return {
        type: this.block.valueType
      }
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    }
  },
  components: {
    GridCellFactory
  },
}
</script>

<style
  lang="scss"
  scoped
>
.field-cell {
  min-height: 20px;
  width: 100%
}
</style>