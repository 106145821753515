<template>
  <div>
    <v-text-field 
      v-if="fieldItems != null && fieldItems.length > 9"
      hide-details dense flat solo 
      v-model="filter" class="pt-0 pb-1 textField" 
      :placeholder="'Search'"/>
    <v-tabs :style="{maxHeight: `${listMaxHeight}px`, overflow: 'scroll'}">
      <v-tab >{{ searchTab.title }}</v-tab>
      <v-tab v-if="customTabTitle">{{customTabTitle}}</v-tab>
      <v-tab v-for="(tabItem, index) in expressionTabs" :key="index">{{ tabItem.title }}</v-tab>
      <v-tab-item>
        <div class="pa-3 d-flex flex-column gap-5">
          <div
            v-for="expressionType in Object.keys(searchTab.expressionsByType)"
            :key="expressionType"
            class=""
          >
            <div class="mb-3 text-caption">{{ expressionType }}</div>
            <div class="d-flex flex-wrap gap-2">
              <div
                v-if="typeof searchTab.expressionsByType[expressionType] === 'string'"
                class="text-caption"
              >{{ searchTab.expressionsByType[expressionType] }}</div>
              <template v-else>
                <Token
                  v-for="item in searchTab.expressionsByType[expressionType]"
                  :key="item.value"
                  :text="item.text"
                  :value="item.value"
                  :color="item.color"
                  :draggable="false"
                  @click.native="$emit('input', item)"
                  :data-testid="`expression-${item.text}`"
                />
              </template>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item v-if="customTabTitle" :key="customTabTitle">
        <slot name="customTab" :onItemPicked="onItemPicked"/>
      </v-tab-item>
      <v-tab-item
        v-for="(tabItem) in expressionTabs"
        :key="tabItem.title"
      >
      <div class="pa-3 d-flex flex-column gap-5">
        <div
          v-for="expressionType in Object.keys(tabItem.expressionsByType)"
          :key="expressionType"
          class=""
        >
          <div class="mb-3 text-caption">{{ expressionType }}</div>
          <div class="d-flex flex-wrap gap-2">
            <div
              v-if="typeof tabItem.expressionsByType[expressionType] === 'string'"
              class="text-caption"
            >{{ tabItem.expressionsByType[expressionType] }}</div>
            <template v-else>
              <Token
                v-for="item in tabItem.expressionsByType[expressionType]"
                :key="item.value"
                :text="item.text"
                :value="item.value"
                :color="item.color"
                :draggable="false"
                @click.native="$emit('input', item)"
                :data-testid="`expression-${item.text}`"
              />
            </template>
          </div>
        </div>
      </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { columnTypes } from '@/constants/columnTypes.js'
import Token from '@/components/flow/Token.vue'
import { listTypes } from '@/apptivescript/apptivescriptUtils.js'

export default {
  props: {
    grid: null,
    listMaxHeight: {
      default: 300
    },
    supportedTypes: null,
    field: null,
    methods: null,
    showOperators: {
      type: Boolean,
      default: () => false
    },
    customTabTitle: {
      type: String,
      default: () => undefined
    }
  },
  data() {
    return {
      filter: undefined
    }
  },
  computed: {
    fieldItems() {
      return this.grid?.fields
        // filtering out formula fields to prevent recursion
        .filter(field => field.columnType.name !== columnTypes.formula.name)
        .filter(field => !this.supportedTypes || this.supportedTypes.includes(field.columnType) || this.supportedTypes.includes(field.columnType.delegateColumnType))
        .filter(field => field.id !== this.field?.id)
        .map(field => ({
          value: `fieldValue('${field.id}')`,
          text: field.name,
          type: 'Field',
          color: '#007affff',
          icon: field.columnType.typeIcon
        }))
    },
    operatorItems() {
      return [
        {
          value: ' + ',
          text: '+',
          type: 'Operator',
          color: '#5856D6'
        },
        {
          value: ' - ',
          text: '-',
          type: 'Operator',
          color: '#5856D6'
        },
        {
          value: ' * ',
          text: '*',
          type: 'Operator',
          color: '#5856D6'
        },
        {
          value: ' / ',
          text: '/',
          type: 'Operator',
          color: '#5856D6'
        },
      ]
    },
    methodItems() {
      if (this.methods == null) return []
      return Object.keys(this.methods).map(key => {
        const parametersTokens = []
        Object.values(this.methods[key].parameters).forEach((parameter, index, parameters) => {
          parametersTokens.push({
            text: `<${parameter.name}>`,
            color: '#30d158ff',
            parameterType: parameter.type?.name,
            value: '',
            placeholder: true
          })
          if (index < parameters.length - 1) {
              parametersTokens.push({
              text: ',',
              value: ',',
              color: '#616161'
            })
          }
        })
        return {
          value: key,
          text: key,
          type: 'Method',
          color: 'purple',
          tokens: [
            {
              value: '.',
              text: '.',
              color: '#616161'
            },
            {
              value: key,
              text: key,
              type: 'Method',
              color: 'purple',
            },
            {
              value: '(',
              text: '(',
              color: '#616161'
            },
            ...parametersTokens,
            {
              value: ')',
              text: ')',
              color: '#616161'
            },
          ]
        }
      })
    },
    filteredItems() {
      return [...this.fieldItems, ...this.operatorItems, ...this.methodItems].filter(item => !this.filter || item.text.toLowerCase().includes(this.filter.toLowerCase()))
    },
    allOperatorItems() {
      const allScriptTypes = listTypes()
      const allOperatorItems = {}
      Object.keys(allScriptTypes).forEach(scriptType => {
        allOperatorItems[scriptType] = {
          title: scriptType,
          expressionsByType: {
            Methods: Object.keys(allScriptTypes[scriptType]).map(method => 
              this.toMethodItem(method, allScriptTypes[scriptType][method])
            )
          }
        }
      })
      return allOperatorItems
    },
    searchTab() {
      const fieldsSection = (this.fieldItems?.length ?? 0) > 0
        ? this.fieldItems
        : this.$t('formulaOptions.noSupportedField', {types: this.supportedTypes.map(type => type.displayString).join(', ')})
      return {
          title: 'Search',
          expressionsByType: {
            ...(this.grid ? { Fields: fieldsSection } : {}),
            ...(this.showOperators ? { Operators: this.operatorItems } : {}),
            ...(this.methodItems.length > 0 ? {Methods: this.methodItems} : {}),
          }
        }
    },
    expressionTabs() {
      return [
        {
          title: 'Date',
          expressionsByType: {
            Date: this.allOperatorItems.Date.expressionsByType.Methods,
            'Date and Time': this.allOperatorItems.DateAndTime.expressionsByType.Methods,
          }
        },
        {
          title: 'Numbers',
          expressionsByType: {
            Decimal: this.allOperatorItems.Decimal.expressionsByType.Methods,
            Number: this.allOperatorItems.Integer.expressionsByType.Methods,
          }
        },
        this.allOperatorItems.String,
        this.allOperatorItems.Boolean,
        this.allOperatorItems.Collection,
      ]
    }
  },
  methods: {
    toMethodItem(name, methodObject) {
      const parametersTokens = []
        methodObject.arguments?.forEach((parameter, index, parameters) => {
          parametersTokens.push({
            text: `<${parameter.name}>`,
            color: '#30d158ff',
            parameterType: parameter.type?.name,
            value: '',
            placeholder: true
          })
          if (index < parameters.length - 1) {
              parametersTokens.push({
              text: ',',
              value: ',',
              color: '#616161'
            })
          }
        })
        return {
          value: name,
          text: name,
          type: 'Method',
          color: 'purple',
          tokens: [
            {
              value: '.',
              text: '.',
              color: '#616161'
            },
            {
              value: name,
              text: name,
              type: 'Method',
              color: 'purple',
            },
            {
              value: '(',
              text: '(',
              color: '#616161'
            },
            ...parametersTokens,
            {
              value: ')',
              text: ')',
              color: '#616161'
            },
          ]
        }
    },
    onItemPicked(item) {
      this.$emit('input', item)
    }
  },
  components: {
    Token
  }
}
</script>

<style scoped>
.textField >>> .v-input__slot {
  border: 1px solid #f0f0f0 !important;
  background-color: #f9f9f9 !important;
}
.list {
  border-radius: 5px;
  overflow: auto;
}
</style>