  import store from '../store'
  import i18n from '@/plugins/i18n'
  import { columnTypes } from '../../constants/columnTypes'
  import { parseFilter } from '@/filter/filter.js'
  import CardViewBase from './CardViewBase'

  // Version 1 :
  const STATE_FIELD_ID = 'stateFieldId'
  // Version 2 :
  const IS_KANBAN_STATE = 'isKanbanState'
  // Version 2.1 no mgration needed :
  // adding card settinga to order and hide field on a kanbna card
  const KANBAN_CARD_POSITION = 'kanbanCardPosition'
  const KANBAN_ORDERING = 'kanbanCardsOrder'
  const MAX_COLUMN_SIZE = 50

    // Version 3
    // adding card ordering to order an card in a lane
  const INITIAL_ORDER_INCREMENT = 100000 // Initial large increment value
  const MINIMUM_POSITION = 1 // Ensures no position goes below this

  export default class KanbanView extends CardViewBase {
    constructor(data) {
      super(data)
      this.migrate()
    }

    migrate() {
      if (this.version === 1) {
        const stateFieldId = this.properties[STATE_FIELD_ID]
        this.setFieldProperty(stateFieldId, IS_KANBAN_STATE, true)
        this.version = 2
        store().dispatch('AGPatchStatefulViewOperation', {
          statefulView: this,
          properties: this.properties,
          slotProperties: this.slotProperties
        })
      }
      if (this.version === 2) {
        this.version = 3
        // Initialize the KANBAN_ORDERING if it doesn't exist
        if (!this.properties[KANBAN_ORDERING]) {
          this.properties[KANBAN_ORDERING] = {}
        }
      }

    }

    get cardPositionKey() {
      return KANBAN_CARD_POSITION
    }

    isOfAcceptedType(field) {
      return field.hasOneOfTypes([columnTypes.enum])
    }

    get stateFieldId() {
      return this.getFieldProperty(IS_KANBAN_STATE)
    }

  // Method to get the position of an entity, or assign an initial order based on its index
  entityPosition(entityId, entityIndex = 0) {
    // Check if the entity has an existing position
    if (this.properties[KANBAN_ORDERING][entityId] !== undefined) {
      return this.properties[KANBAN_ORDERING][entityId]
    }

    // If no position exists, assign a default based on the entity index
    const initialPosition = (entityIndex + 1) * INITIAL_ORDER_INCREMENT
    this.setEntityPosition(entityId, initialPosition, false) // Persist the initial order
    return initialPosition
  }

    setEntityPosition(entityId, position, persist = true) {
      // Ensure the KANBAN_ORDERING object exists
      if (!this.properties[KANBAN_ORDERING]) {
        this.properties[KANBAN_ORDERING] = {}
      }
      this.properties[KANBAN_ORDERING][entityId] = position
      if( persist ){
        return store().dispatch('AGPatchStatefulViewOperation', {
          statefulView: this,
          properties: this.properties
        })
      }
      // Return undefined if persistence is not required
      return undefined
    }


    reload() {
      return store().dispatch('AGReadStatefulViewOperation', this.uri)
    }

    async setStateFieldId(fieldId) {
      this.deleteFieldProperty(this.stateFieldId, IS_KANBAN_STATE)
      this.setFieldProperty(fieldId, IS_KANBAN_STATE, true)

      // If the field was hidden, we need to add it to the shown fields first
      if (this.parentGrid.hiddenFields.some(field => field.id === fieldId)) {
        const parentFieldIds = this.parentGrid.parentGrid().fields.map(field => field.id)
        const fieldIds = this.parentGrid.fields.map(field => field.id)
        const newFieldIds = parentFieldIds.filter(id => id === fieldId || fieldIds.includes(id))
        await this.parentGrid.updateShownFields(newFieldIds)
      }

      return store().dispatch('AGPatchStatefulViewOperation', {
        statefulView: this,
        slotProperties: this.slotProperties
      })
      .then(() => this.reload())
    }

    entityParams() {
      if (!this.stateFieldId) {
        return undefined
      }
      const stateField = this.parentGrid.fields.find(field => field.id === this.stateFieldId)
      const states = stateField.type.options
      const filters = states.map(state => {
        return parseFilter({
          [ this.stateFieldId ]: state
        })
      })
      // Because of the extended enum,
      // The "Unstacked" column can contain all entities with a value
      // for the stateField that is not in the states
      filters.push(parseFilter({
        $and: states.map(state => ({
          $not: {
            [this.stateFieldId]: { $eq: state }
          }
        }))
      }))
      return filters.map(filter => ({
        filter,
        pageSize: MAX_COLUMN_SIZE
      }))
    }

  reorderEntity(entityId, newIndex, currentTasks) {
     /**
     * 
     * The goal of this function is to reorder entities in a list while maintaining efficient 
     * position management. Rather than renumbering all entities, we assign large gaps between 
     * position values. This approach minimizes the overhead of updating all items when reordering.
     * 
     * We use the following strategy:
     * - For entities moved to the first or last position, we assign a position relative to the 
     *   neighboring entities.
     * - For entities moved between two items, we calculate a midpoint between the previous 
     *   and next entities' positions. 
     * 
     * By leaving gaps between position values (e.g., using increments like 10, 20, 30), we ensure 
     * future reordering flexibility without renumbering the entire list. If gaps become too small, 
     * we shift other tasks to create space.
     */
    let newPosition
    // If the list has only one task, assign a default initial position and return it
    if(currentTasks.length == 1) return INITIAL_ORDER_INCREMENT

    if (newIndex === 0) {
      // If moved to the first position:
      // Find the position of the next entity (the entity that will follow the reordered one)
      const nextEntity = currentTasks[1]
      const nextPosition = this.entityPosition(nextEntity.entity._id)
      // Set new position to half of the next entity's position (midpoint)
      newPosition = Math.floor(nextPosition / 2)
        // If the calculated position is below the minimum allowed value (e.g., zero or negative),
        // shift all current positions forward to make room for this entity at the start
      if (newPosition < MINIMUM_POSITION) {
        this.shiftPositionsForward(currentTasks)
        newPosition = INITIAL_ORDER_INCREMENT
      }
    } else if (newIndex === currentTasks.length - 1) {
      // If moved to the last position:
      // Find the position of the previous entity (the one that precedes the reordered one)
      const previousEntity = currentTasks[currentTasks.length - 2]
      const previousPosition = this.entityPosition(previousEntity.entity._id)
      // Set the new position to be incrementally higher than the previous entity's position
      newPosition = Math.floor(previousPosition + INITIAL_ORDER_INCREMENT)
    } else {
      // If moved between two entities (not first or last):
      // Find the positions of the entities before and after the newly reordered entity
      const previousEntity = currentTasks[newIndex - 1]
      const nextEntity = currentTasks[newIndex + 1]
      const previousPosition = this.entityPosition(previousEntity.entity._id)
      const nextPosition = this.entityPosition(nextEntity.entity._id)
      // Calculate the midpoint between the previous and next entity positions
      newPosition = Math.floor((previousPosition + nextPosition) / 2)
      // Handle the rare case where the positions of the previous and next entities are too close
      // or the new position is equal to one of them (no room to insert in between)
      if (newPosition === previousPosition || newPosition === nextPosition) {
        // Shift all entities after the reordered entity to make space
        this.shiftPositionsForwardFrom(newIndex + 1, currentTasks)
        newPosition = previousPosition + INITIAL_ORDER_INCREMENT
      }
    }

    // Final position for the reordered entity is stored in newPosition.
    this.setEntityPosition(entityId, newPosition)
  }

  // Utility method to shift all positions forward to make room if the first entity's position reaches 0 or below
  shiftPositionsForward(currentTasks) {
    for (let i = 0; i < currentTasks.length; i++) {
      const entity = currentTasks[i]
      const currentPosition = this.entityPosition(entity.entity._id)
      this.setEntityPosition(entity.entity._id, currentPosition + INITIAL_ORDER_INCREMENT)
    }
  }

  // Utility method to shift positions forward to make room
  shiftPositionsForwardFrom(startIndex, currentTasks) {
    for (let i = startIndex; i < currentTasks.length; i++) {
      const entity = currentTasks[i]
      const currentPosition = this.entityPosition(entity.entity._id)
      this.setEntityPosition(entity.entity._id, currentPosition + INITIAL_ORDER_INCREMENT)
    }
  }

    // Utility method to remove entities no longer present in the grid
  cleanUpOrdering(grid) {
    if(grid?.entities?.length == 0) return
    const currentEntityIds = grid.entities.map(entity => entity._id)
    const orderingEntityIds = Object.keys(this.properties[KANBAN_ORDERING] || {})

    // Filter out the entities in the ordering that no longer exist in the grid
    const updatedOrdering = orderingEntityIds.reduce((ordering, entityId) => {
      if (currentEntityIds.includes(entityId)) {
        ordering[entityId] = this.properties[KANBAN_ORDERING][entityId]
      }
      return ordering
    }, {})
    // Update the KANBAN_ORDERING property with the cleaned-up data
    this.properties[KANBAN_ORDERING] = updatedOrdering
  }

    kanbanModel(grid, includingUnsortedEntities) {
      if (!this.stateFieldId) {
        return
      }

            // Clean up the ordering before constructing the model
      this.cleanUpOrdering(grid)
      console.log('kanbanModel')
      const statesIndex = grid.fields.findIndex(field => field.id === this.stateFieldId)
      const stateField = grid.fields[statesIndex]
      var states = grid.fields[statesIndex].type.options

      if (includingUnsortedEntities) {
        states = [null, ...states]
      }
      const columns = states.map((state) => {
        return {
          title: state || i18n.t('kanban.unstacked'),
          stateField: stateField,
          state: state,
          tasks: grid.entities
            .filter((entity) => {
              // The null state corresponding to the "Unstacked" column
              // can contain all entities with a value
              // for the stateField that is not in the states
              if ((state == null && !states.includes(entity.fields[statesIndex]))) {
                return true
              }
              return entity.fields[statesIndex] === state
            })
            .map((entity, entityIndex) => {
              return {
                stateField: stateField,
                entity: entity,
                position: this.entityPosition(entity._id, entityIndex, false), // Assign initial position if necessary
                attributes: entity.fields.map((entityField, index) => {
                  return {
                    key: grid.fields[index].name,
                    value: grid.displayFormat(entityField, index),
                    fieldValue: entityField,
                    property: grid.fields[index].columnType,
                    fieldId: grid.fields[index].id,
                    hideFieldName: this.hideFieldName
                  }
                })
                .filter( attr => this.fieldIsShown( attr.fieldId ))
                .sort( (a, b) => this.fieldPostion(a.fieldId) - this.fieldPostion(b.fieldId)),
              }
            })
            .sort((a, b) => a.position - b.position)
        }
      })
      return columns
    }
  }