<template>
  <div class="kanban-column my-3" :class="[index > 0 ? 'mr-3' : 'mx-3']">
    <div class="kanban-column-header grey lighten-4 rounded-t text-subtitle-2">
      <div class="px-3 py-2">
        {{ title }}
      </div>
      <v-divider v-if="isScrolled" class="separator" />
    </div>
    <v-sheet
      rounded="b"
      class="kanban-column-body grey lighten-4"
      :class="[`kanban-column-body--${index}`]"
      :style="columnHeight"
      @scroll="handleBodyScroll"
    >
      <!-- forceFallback for safari drag event bug :
          https://github.com/SortableJS/Sortable/issues/1571#issuecomment-566734677 -->
      <draggable
        :list="tasks"
        :animation="200"
        ghost-class="ghost-card"
        group="tasks"
        class="kanban-inner-column-body px-3"
        :style="innerColumnHeight"
        @change="change => $emit('change', change)"
        @end="onDragEnd"
        @start="onDragStart"
        :delay="120"
        :delayOnTouchOnly="true"
        :forceFallback="true"
        draggable=".cursor-move"
      >
        <!-- Each element from here will be draggable and animated.
            Note :key is very important here to be unique both for draggable
            and animations to be smooth & consistent. -->
        <KanbanCard
          v-for="(task) in tasks"
          :key="task.entity._id"
          :entity="task"
          @click.native="() => $emit('entityClicked', task.entity)"
          :class="{ 'cursor-move': canUpdateEntity( task.entity) }"
          class="kanban-card mt-1 mb-3"
        />
        <div
          v-if="!addButtonDisabled && !isDragging"
          slot="footer"
          class="mb-3 column-footer grey lighten-4 d-flex justify-center"
        >
          <v-btn
            icon
             v-if="!addButtonDisabled && !isDragging"
            color="accent"
            @click="() => $emit('addButtonClicked')"
            :data-testid="`kanban-column-add-${index}`"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </draggable>
    </v-sheet>
  </div>
</template>

<script>
import KanbanCard from '@/components/kanbanView/KanbanCard.vue'
import draggable from 'vuedraggable'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'

const kanbanColumnOffset = 110
const kanbanInnerColumnOffset = 122

export default {
  props: {
    index: {
      type: Number
    },
    title: {
      type: String
    },
    tasks: null,
    addButtonDisabled: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    isDragging: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            document.body.classList.add('no-select')
          }
          else {
            document.body.classList.remove('no-select')
          }
        }
      }
    },
  data() {
    return {
      isScrolled: false,
      isDragging: false
    }
  },
  computed: {
    columnHeight() {
      return {
        'max-height': `calc(100vh - ${this.$vuetify.application.top +
          kanbanColumnOffset}px)`
      }
    },
    innerColumnHeight() {
      return {
        'min-height': `calc(100vh - ${this.$vuetify.application.top +
          kanbanInnerColumnOffset}px)`
      }
    }
  },
  methods: {
    handleBodyScroll(event) {
      this.isScrolled = event.target.scrollTop > 0
    },
    onDragStart() {
      this.isDragging = true
      this.$emit('startDragging')
   },
   onDragEnd() {
    this.isDragging = false
    this.$emit('stoppedDragging')
   },
    canUpdateEntity(entity) {
      return hasPermission(entity, [PERMISSIONS.update])
    }
  },
  components: {
    KanbanCard,
    draggable
  }
}
</script>

<style scoped>
.kanban-column-body {
  width: fit-content;
  height: 100vh;
  overflow: scroll;
  min-width: 320px;
  width: 320px;
}
.kanban-inner-column-body {
  height: fit-content;
}
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.cursor-move {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.cursor-move:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.separator {
  margin-top: -1px;
}

/* negative x margins to hide underlying shadows */
.column-footer {
  position: sticky;
  bottom: 0;
  margin-left: -4px;
  margin-right: -4px;
}
</style>