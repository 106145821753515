<template>

          <v-expansion-panels v-model="layoutPanel" multiple accordion flat>

          <FormFieldListSection :title="$t('forms.builder.textsSectionHeader')">
            <div class="pa-0">
              <v-text-field
                :label="$t('forms.builder.formTitleLabel')"
                v-model="formTitle"
                @blur="saveForm"
                data-testid="form-builder-title"
              />
            </div>

            <div class="pa-0">
              <v-textarea
                :label="$t('forms.builder.descriptionLabel')"
                v-model="formDescription"
                rows="1"
                auto-grow
                @blur="saveForm"
                data-testid="form-builder-description"
              ></v-textarea>
            </div>
            <v-text-field
              :label="$t('forms.builder.buttonTitleLabel')"
              v-model="buttonTitle"
              @blur="saveForm"
              data-testid="form-builder-buttonTitle"
            />
          </FormFieldListSection>

          <FormFieldListSection :title="$t('forms.builder.fieldStyleSectionHeader')">
            <v-list dense flat>
              <v-list-item-group v-model="fieldStyleSelect">
                <v-list-item
                  v-for="(item, i) in formFieldStyles"
                  :key="i" 
                >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(`forms.builder.fieldStyle_${item}`) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon small v-show="active">mdi-check</v-icon>
                  </v-list-item-action>
            </template>
                </v-list-item>
              </v-list-item-group>
          </v-list>
          </FormFieldListSection>

          <FormFieldListSection :title="$t('forms.builder.imageSectionHeader')">
            <FileUrlInput
              :label="$t('forms.builder.headerImageLabel')"
              :fixedFileName="$t('forms.builder.headerImageLabel')"
              v-model="headerImage"
              @blur="saveForm"
              data-testid="form-builder-headerImage"
            />
            <PaywallMenu :feature="$apptive.constants.features.FORM_LOGO">
            <FileUrlInput
              :label="$t('forms.builder.logoImageLabel')"
              :fixedFileName="$t('forms.builder.logoImageLabel')"
              v-model="logoImage"
              @blur="saveForm"
              data-testid="form-builder-logoImage"
            />
           </PaywallMenu>
          </FormFieldListSection>
          <FormFieldListSection :title="$t('forms.builder.colorSectionHeader')">
            <ColorInput
              v-model="primaryColor"
              :label="$t('forms.builder.primaryColorLabel')"
              @blur="saveForm"
            />
            <ColorInput
              v-model="buttonColor"
              :label="$t('forms.builder.buttonColorLabel')"
              @blur="saveForm"
            />
            <ColorInput
              v-model="headerColor"
              :label="$t('forms.builder.headerColorLabel')"
              @blur="saveForm"
            />
            <ColorInput
              v-model="backgroundColor"
              :label="$t('forms.builder.backgroundColorLabel')"
              @blur="saveForm"
            />
            <ColorInput
              v-model="cardColor"
              :label="$t('forms.builder.cardColorLabel')"
              @blur="saveForm"
            />
          </FormFieldListSection>

          <FormFieldListSection :title="$t('forms.builder.moreSettingsSection')">

            <v-switch
              v-model="flatCard"
              @change="saveForm"
              inset
              dense
              left
              :label="$t('forms.builder.removeCardShadowLabel')"
              ></v-switch>

          </FormFieldListSection>
        </v-expansion-panels>

</template>

<script>

import FormFieldListSection from '@/components/form/formbuilder/FormFieldListSection.vue'
import FileUrlInput from '@/components/form/FileUrlInput.vue'
import ColorInput from '@/components/form/ColorInput.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'

export default {
  props: {
    gridUri: null,
    formUri: null
  },
  data() {
    return {
      layoutPanel: [0],
      fieldStyleSelect: null
    }
  },
  watch: {
    fieldStyleSelect: {
      immediate: false,
      handler(newVal) {
        let newStyle = this.formFieldStyles[newVal]
        if(newStyle != this.fieldStyle){
          this.fieldStyle = newStyle
          this.saveForm()
        }
      }
    },
    form: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.fieldStyleSelect = this.formFieldStyles.indexOf( this.fieldStyle )
        }
      }
    }
  },
  computed: {
    form() {
      return this.$store.getters.formWithUri(this.formUri)
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    headerImage: {
      get() {
        return this.form.headerImage
      },
      set(value) {
        this.form.headerImage = value
      }
    },
    logoImage: {
      get() {
        return this.form.logoImage
      },
      set(value) {
        this.form.logoImage = value
      }
    },
    formTitle: {
      get() {
        return this.form.title
      },
      set(value) {
        this.form.title = value
      }
    },
    formDescription: {
      get() {
        return this.form.description
      },
      set(value) {
        this.form.description = value
      }
    },
    buttonTitle: {
      get() {
        return this.form.buttonTitle
      },
      set(value) {
        this.form.buttonTitle = value
      }
    },
    buttonColor: {
      get() {
        return this.form.buttonColor ?? this.primaryColor
      },
      set(value) {
        this.form.buttonColor = value
      }
    },
    primaryColor: {
      get() {
        return this.form.primaryColor ?? this.$vuetify.theme.themes.light.primary
      },
      set(value) {
        this.form.primaryColor = value
      }
    },
    cardColor: {
      get() {
        return this.form.cardColor
      },
      set(value) {
        this.form.cardColor = value
      }
    },
    backgroundColor: {
      get() {
        return this.form.backgroundColor
      },
      set(value) {
        this.form.backgroundColor = value
      }
    },
    headerColor: {
      get() {
        return this.form.headerColor
      },
      set(value) {
        this.form.headerColor = value
      }
    },
    flatCard: {
      get() {
        return this.form.flatCard ?? false
      },
      set(value) {
        this.form.flatCard = value
      }
    },
    adsHidden: {
      get() {
        return this.form.adsHidden ?? false
      },
      set(value) {
        this.form.adsHidden = value
      }
    },
    fieldStyle: {
      get() {
        return this.form.fieldStyle ?? 'default'
      },
      set(value) {
        this.form.fieldStyle = value
      }
    },
    formFieldStyles() {
      return [ 'default' , 'outlined' ]
    }
  },
  methods: {
    saveForm() {
      this.form.updateComponents()
    },
  },
  components: {
    FileUrlInput,
    ColorInput,
    FormFieldListSection,
    PaywallMenu
  }
}
</script>
