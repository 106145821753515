import { render, staticRenderFns } from "./VideoBlock.vue?vue&type=template&id=e701f6fa&scoped=true&"
import script from "./VideoBlock.vue?vue&type=script&lang=js&"
export * from "./VideoBlock.vue?vue&type=script&lang=js&"
import style0 from "./VideoBlock.vue?vue&type=style&index=0&id=e701f6fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e701f6fa",
  null
  
)

export default component.exports