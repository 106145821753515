<template>
<v-dialog max-width="600" v-model="externalModel" v-bind="$attrs" persistent>
  <EventHook
    :title="$t('webhooks.createHookTitle')"
    :flowUri="flowUri"
    :hookUri="hookUri"
    :spaceUri="spaceUri"
    @closeDialog="externalModel = false"
    @deleteHook="deleteHook"
    />
</v-dialog>
</template>

<script>
import EventHook from '@/components/webhooks/EventHook.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  props: {
    hookUri: null,
    flowUri: null,
    spaceUri: null,
  },
  methods: {
    deleteHook(hook) {
      this.$emit('deleteHook', hook)
      this.externalModel = false
    }
  },
  components: {
    EventHook
  }
}
</script>

<style lang="css"></style>
