<template>
  <div>
    <div class="d-flex align-center">
      <EditableText
        :maxLength="200"
        :multiline="true"
        :readonly="!canChangeLabel"
        :text="label"
        :singleClick="true"
        @submit="saveFieldLabel"
        :required="false"
        :placeholder="$t('forms.builder.formFieldLabelPlaceholder')"
      />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'

export default {
  props: {
    blockUri: {
      type: String,
      default: () => null
    },
    editorMode: {
      type: Boolean,
      default: () => false
    },
    showControls: {
      type: Boolean,
      default: () => false
    },
  },
  computed: {
    canChangeLabel() {
      return this.editorMode && hasPermission(this.block, [ PERMISSIONS.patch ])
    },
    label() {
      return this.block?.label
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    }
  },
  methods: {
    async saveFieldLabel(newValue) {
      this.block.setLabel(newValue)
    }
  },
  components: {
    EditableText
  },
}
</script>

<style scoped>
.control {
  opacity: 0;
  transition: opacity 0.3s;
}

.shown {
  opacity: 1;
}
</style>