<template>
<div>
  <VueFilePond
    :disabled="disabled"
    class="pointer"
    ref="filepond"
    v-bind="{...$attrs, ...localeLabels, ...thumbnailGenerationProps}"
    :key="pondKey"
    :files="initialFiles"
    :server="server"
    :allowReplace="true"
    :allowRevert="false"
    :allowRename="!disableRename && !disabled"
    credits="false"
    @addfilestart="addFile"
    @activatefile="onFileClicked"
  />
  <AttachmentsPreviewDialog v-model="showPreviewDialog" :files="existingFiles" :showIndex="fileClickedIndex"/>
  <RenameAttachmentDialog v-model="showRenameDialog" :attachment="clickedFile" @newName="fileRename" />
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import vueFilePond from 'vue-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginGetFile from '@/components/attachments/downloadplugin/index.js'
import FilePondPluginCopyPath from '@/components/attachments/copypathplugin/index.js'


// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import '@/components/attachments/downloadplugin/download.scss'
import '@/components/attachments/copypathplugin/copypath.scss'

import de from 'filepond/locale/de-de.js'
import RenameAttachmentDialog from '@/components/attachments/RenameAttachmentDialog.vue'
import AttachmentsPreviewDialog from './AttachmentsPreviewDialog.vue'
import { uploadFileToS3 } from '@/utils/s3Utils.js'
import axios from 'axios'


const VueFilePond = vueFilePond(
  FilePondPluginCopyPath,
  FilePondPluginGetFile,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
)

export default {
  props: ['existingFiles', 'unauthenticated', 'disabled', 'disableRename'],
  data() {
    return {
      clickedFile: undefined,
      showRenameDialog: false,
      pondKey: 0,
      filesAdded: [],
      createdFilesPool: [],
      showPreviewDialog: undefined,
      fileClickedIndex: undefined,
      currentFileType: undefined
    }
  },
  mounted() {
    this.$refs.filepond.$el.addEventListener('FilePond:rename', this.fileClicked)
    this.$refs.filepond.$el.addEventListener('FilePond:addfile', this.replaceNames)
  },
  beforeDestroy() {
    this.$refs.filepond.$el.removeEventListener('FilePond:rename', this.fileClicked)
    this.$refs.filepond.$el.removeEventListener('FilePond:addfile', this.replaceNames)
  },
  computed: {
    server() {
      return {
        process: this.vueFilePondProcess,
        load: this.load,
        remove: this.remove
      }
    },
    initialFiles() {
      return this.existingFiles?.map(file => {
        return {
          source: file.url,
          fileName: file.name,
          options: {
            type: 'local'
          }
        }
      })
    },
    localeLabels() {
      return this._i18n.locale.includes('de') ? de : {}
    },
    thumbnailGenerationProps() {
      return {
        imageResizeMode: 'contain',
        imageTransformOutputMimeType: this.currentFileType || 'image/jpeg',
        imageTransformVariantsIncludeOriginal: true,
        imageResizeTargetWidth: 256,
        imageTransformVariants: {
          largeThumbnail: transforms => {
            transforms.resize.size.height = 256
            return transforms
          },
          smallThumbnail: transforms => {
            transforms.resize.size.height = 64
            return transforms
          }
        }
      }
    }
  },
  methods: {
    async uploadFileToS3(file, progress) {
      const fileId = uuidv4()
      return uploadFileToS3(fileId, file.type, file, this.unauthenticated, progress)
    },
    async vueFilePondProcess(fieldName, files, metadata, load, error, progress) {
      let newAttachment
      if(Array.isArray(files)) {
        await Promise.all(files
          .filter(file => file.name)
          .map(async file => {
            file.url = await this.uploadFileToS3(file.file, progress)
        }))
        const originalFile = files.find(file => file.name === 'original_')
        const smallThumbnail = files.find(file => file.name === 'smallThumbnail')
        const largeThumbnail = files.find(file => file.name === 'largeThumbnail')
        newAttachment = {
          url: originalFile.url,
          type: originalFile.file.type,
          name: originalFile.file.name,
          largeThumbnail: largeThumbnail.url,
          smallThumbnail: smallThumbnail.url
        }
      } else {
        const fileUrl = await this.uploadFileToS3(files, progress)
        newAttachment = {
          url: fileUrl,
          type: files.type,
          name: files.name
        }
      }
      this.createdFilesPool.push(newAttachment)
      this.filesAdded.splice(this.filesAdded.indexOf(newAttachment.url), 1)
      if (this.filesAdded.length === 0) {
        this.filesProcessed()
      }
      load(fieldName)
    },
    addFile({source}) {
      // vue file pont calls that method also for inital exsiting files with source as a string
      // To be shure that only new added files are added, check for object type
      if( typeof source === 'object' ){
        this.currentFileType = source.type
        this.filesAdded.push(source)
      }
    },
    filesProcessed() {
      const newFiles = [
        ...(this.existingFiles ?? []),
        ...this.createdFilesPool
      ]
      this.$emit('fileUploaded', newFiles)
      this.createdFilesPool = []
    },
    remove(source) {
      this.$emit('fileDeleted', source)
    },
    load(source, load, error, progress, abort) {
      axios.get(source, { responseType: 'blob' }).then(response => {
        // Use name from props, not from blob
        const file = this.initialFiles.find(file => file.source === source)
        response.data.name = file.fileName
        this.filesAdded.splice(this.filesAdded.indexOf(file.source), 1)
        load(response.data)
      })
      return {
        abort: () => {
          abort()
        }
      }
    },
    fileRename(newName) {
      this.$emit('fileRenamed', this.clickedFile.url, newName)
      this.showRenameDialog = false
      // Manually reload the pond to show the new name
      this.pondKey = performance.now()
      // Add a small delay to ensure DOM is updated after the pond reload
      setTimeout(() => this.replaceNames(), 0)
    },
    fileClicked(event) {
      const source = event.detail.source
      const file = this.existingFiles.find(file => file.url === source)
      this.clickedFile = file
      this.showRenameDialog = true
    },
    onFileClicked(event) {
      this.fileClickedIndex = this.existingFiles.findIndex(file => file.url === event.serverId)
      this.showPreviewDialog = true
    },
    // HACK : manually edit filepond dom to display correct names
    replaceNames() {
      const names = document.querySelectorAll('.filepond--file-info-main')
      const files = this.$refs.filepond?.files || []

      names.forEach((nameElement, index) => {
        if (index < files.length && files[index]?.fileName) {
          // Get the text node (last child) and update only its content
          const textNode = Array.from(nameElement.childNodes)
            .find(node => node.nodeType === Node.TEXT_NODE)
          if (textNode) {
            textNode.textContent = files[index].fileName
          }
        }
      })
    }
  },
  components: {
    VueFilePond,
    RenameAttachmentDialog,
    AttachmentsPreviewDialog,
  }
}
</script>

<style scoped lang="scss">
::v-deep .filepond--root {
  margin-bottom: 0;
}

.pointer {
  cursor: pointer;

  ::v-deep label {
    cursor: pointer;
  }
}
</style>
