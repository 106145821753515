<template>
  <v-menu
    ref=""
    v-model="modal"
    offset-y
    bottom
    v-bind="$attrs"
    :close-on-content-click="false"
    min-width="285"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"/>
    </template>

    <v-card
      max-width="320px"
      >
      <v-card-text>
        <div class="subtitle-2 accent--text mb-5">{{ $t('dialogs.createGridMenu.title')}}</div>
        
        <EmojiTextField
        solo outlined flat dense
        autofocus
        @focus="$event.target.select()"
        clearable
        :rules="rules.maxLength(maxNameLength)"
        :counter="maxNameLength"
        @keyup.enter.native="addGrid"
        @input="$emit('textFieldInput', gridName)"
        v-model="gridName"
        :disabled="loading"
      />
      <v-list dense>
        <div class="subtitle-2 accent--text mb-2">{{ $t('dialogs.createGridMenu.importFromData')}}</div>

        <MenuItem
          v-for="(dialog, index) in dialogs"
          :key="`menuItem${index}`"
          :disabled="dialog.disabled()"
          v-bind="dialog.menuItemProps"
          @mouseup="modal = false"
          @click="dialog.model = true"
          
        />
      </v-list>
      </v-card-text>
     
      <v-card-actions >
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" color="accent" @click="modal = false">{{ $t('dialogs.cancelButton')}}</v-btn>
        <v-btn text :loading="loading" data-testid="addGridButton" color="primary" @click="addGrid">{{ $t('dialogs.createButton')}}</v-btn>
      </v-card-actions>
    </v-card>
    <component
      v-for="(dialog, index) in dialogs"
      :key="`dialog${index}`"
      :is="dialog.component"
      v-model="dialog.model"
      :disabled="dialog.disabled()"
      :spaceUri="spaceUri"
    />
  </v-menu>
</template>

<script>
import { textFieldRules } from '@/mixins/textFieldRules.js'
import nameLengths from '@/constants/nameLengths.js'
import EmojiTextField from '@/components/EmojiTextField.vue'
import CreateGridFromCsvDialog from '@/components/space/CreateGridFromCsvDialog.vue'
import MenuItem from '@/components/MenuItem.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'

export default {
  mixins: [textFieldRules],
  props: [ 'spaceUri'],
  data() {
    return {
      modal: false,
      loading: false,
      gridName: null,
      maxNameLength: nameLengths.grid,
      dialogs: [
        {
            model: false,
            disabled: () => !this.canUploadCsv,
            menuItemProps: {
              icon: 'mdi-file-document-outline',
              text: this.$t('dialogs.gridFromCsvButton'),
              'data-testid': 'createGridFromCsvDialogActivator'
            },
            component: CreateGridFromCsvDialog
        }
      ]
    }
  },
  watch: {
    grids() {
      // Update the default name whenever the grids change
      this.gridName = this.defaultGridName
    }
  },
  computed: {
    defaultGridName() {
      return `Grid ${this.grids.length + 1}`
    },
    grids() {
      return this.$store.getters.gridsOfSpace(this.spaceUri)
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    canUploadCsv() {
      return hasPermission(this.space, [PERMISSIONS.uploadCSV])
    },
  },
  methods: {
    navigate(gridUri) {
      this.$router.push({name: 'Grid', params: {gridUri: gridUri}})
    },
    async addGrid() {
      this.loading = true
      try {
        const grid = await this.$store.dispatch('AGAddGridOperation', {
          spaceUri: this.spaceUri,
          newName: this.gridName
        })
        this.navigate(grid.uri)
        this.modal = false
      } finally {
        this.loading = false
      }
    },
  },
  components: {
    EmojiTextField,
    MenuItem
  }
}
</script>

<style lang="css" scoped>
</style>
