import ApiClient from '@/ApiClient'

export default {
  state() {
    return {
       flowInstances: []
    }
  },
  mutations: {
    setFlowInstancesList(state, instances) {
      state.flowInstances = instances
    }
  },
  actions: {
    async AGListFlowsInstancesOperation(context, { flow, pageSize, pageIndex } ) {
      const link = await flow.getLink('instances')
      const response = await ApiClient.getUri(link, { pageSize, pageIndex })
      return new Promise((resolve) => {
        context.commit('setFlowInstancesList', response.data.items)
        resolve(response.data)
      })
    }
  },
  getters: {
    flowsInstancesUris(state) {
      return state.flowInstances.map((instance) => {
        return instance._links.self.href
      }) || []
    },
    flowInstancesList(state) {
      return state.flowInstances.map((instance) => {
        return {
          uri: instance._links.self.href,
        }
      }) || []
    }
  }
}
