import HalObject from '@/store/models/HalObject'
import Vue from 'vue'
import store from '../../store'

export const stepStates = {
  created: 'created',
  done: 'done',
  error: 'error'
}

export default class Step extends HalObject {
  constructor(data) {
    super(data)
    this.environment = data.environment
    this.state = data.state
    this.timestamp = data.timestamp
    this.id = data.id
    this.output = data.output
    this.metadata = data.metadata
    this.events = []
  }

  async loadEvents() {
    const response = await store().dispatch('AGListNodeEventsOperation', {node: this})
    Vue.set(this, 'events', response.data.items)
  }
}
