import Vuex from 'vuex'
import Vue from 'vue'
import user from './modules/user.js'
import spacesModule from './modules/spacesModule.js'
import spacesListModule from './modules/spacesListModule.js'
import gridsModule from './modules/gridsModule.js'
import gridListModule from './modules/gridListModule.js'
import virtualGridsModule from './modules/virtualGridsModule.js'
import virtualGridListModule from './modules/virtualGridListModule.js'
import formsModule from './modules/formsModule.js'
import formListModule from './modules/formListModule.js'
import webhooksModule from './modules/webhooksModule.js'
import webhooksListModule from './modules/webhooksListModule.js'
import sharesModule from './modules/sharesModule.js'
import sharesListModule from './modules/sharesListModule.js'
import templatesModule from './modules/templatesModule.js'
import statefulViewsModule from './modules/statefulViewsModule.js'
import Form from './models/Form.js'
import flowModule from './modules/flowModule.js'
import flowListModule from './modules/flowListModule.js'
import flowInstanceListModule from './modules/flowInstanceListModule.js'
import flowInstanceModule from './modules/flowInstanceModule.js'
import {paywallModule, paywallDowngradPlugin} from './modules/paywallModule.js'
import invitesModule from './modules/invitesModule.js'
import invitesListModule from './modules/invitesListModule.js'
import fieldsModule from './modules/fieldsModule.js'
import linksModule from '@/store/modules/linksModule.js'
import blocksModule from '@/store/modules/blocksModule.js'
import ApiClient from '@/ApiClient'
import axios from 'axios'

Vue.use(Vuex)

let storeInstance = new Vuex.Store({
  state() {
    return {
      form: null,
      apiVersion: null,
      navigationDrawer: true,
      sideNavigation: true,
      spacesSideNavigation: true,
      apptivesDrawer: false,
      config: undefined
    }
  },
  modules: {
    user,
    spacesModule,
    spacesListModule,
    gridsModule,
    gridListModule,
    virtualGridListModule,
    virtualGridsModule,
    fieldsModule,
    formListModule,
    formsModule,
    webhooksListModule,
    webhooksModule,
    sharesModule,
    sharesListModule,
    templatesModule,
    statefulViewsModule,
    flowListModule,
    flowModule,
    flowInstanceListModule,
    flowInstanceModule,
    paywallModule,
    invitesListModule,
    invitesModule,
    linksModule,
    blocksModule
  },
  plugins: [ paywallDowngradPlugin ],
  mutations: {
    addForm(state, form) {
      state.form = form
    },
    removeForm(state) {
      state.form = null
    },
    setApiVersion(state, version) {
      state.apiVersion = version
    },
    setWebVersion(state, version) {
      state.webVersion = version
    },
    setNavigationDrawer(state, payload) {
      state.navigationDrawer = payload
    },
    setSideNavigation(state, payload) {
      state.sideNavigation = payload
    },
    setSpacesSideNavigation(state, payload) {
      state.spacesSideNavigation = payload
    },
    setApptivesDrawer(state, payload) {
      state.apptivesDrawer = payload
    },
    setConfig(state, payload) {
      state.config = payload
    }
  },
  actions: {
    loadForm(context, { formUri, customErrorHandling }) {
      return ApiClient.getUri(formUri, undefined, {customErrorHandling}).then(response => {
        response.data['uri'] = formUri
        const form = new Form(response.data)
        context.commit('addForm', form)
      })
    },
    loadWebVersion(context){
      return axios.get('/version.txt').then(response => {
        context.commit('setWebVersion', response.data)
        return response.data
      })     
    },
    LoadVersion(context) {
      context.dispatch('loadWebVersion')
      ApiClient.getUri('/api/version').then(response => {
        context.commit('setApiVersion', response.data)
      })
    },
    loadApptives() {
      return axios.get('/apptives/apptivesRegistry.js')
        .then(response => response.data)
    },
  },
  getters: {
    apiVersion(state) {
      return state.apiVersion
    },
    sideNavigationShown(state, getters, rootState, rootGetters) {
      return state.sideNavigation && rootGetters.userLoggedIn
    },
    spacesSideNavigationShown(state, getters, rootState, rootGetters) {
      return state.spacesSideNavigation && rootGetters.userLoggedIn
    },
  }
})

export function setStore(otherStore) {
  storeInstance = otherStore
}

export default function store() {
  return storeInstance
}
