<template>
  <div class="">
    <v-select
      :disabled="disabled || !webhook.isLocal()"
      v-model="selectedSpaceUri"
      :items="spaces"
      item-value="uri" item-text="name"
      :label="$t('webhooks.trigger.chooseSpace')"
      data-testid="webhookTriggerSelectSpace"
    >
      <template slot="item" slot-scope="data">
        <span data-testid="webhookTriggerSelectSpaceItem">{{
        data.item.name
        }}</span>
      </template>
    </v-select>

    <v-select
    :disabled="disabled"
    v-model="webhook.event"
    :items="events"
    :label="$t('webhooks.trigger.chooseEvent')"
    @input="onEventSelected"
    data-testid="webhookTriggerSelectEvent"
    >
    <template slot="item" slot-scope="data">
      <span data-testid="webhookTriggerSelectEventItem">{{ data.item.text }}</span>
      <span v-if="data.item.disabled" class="ml-3 warning--text">&#9888; {{$t('webhooks.trigger.deprecated')}}</span>
    </template>
    <template slot="selection" slot-scope="data">
      <span data-testid="webhookTriggerSelectEventItem">{{ data.item.text }}</span>
      <span v-if="data.item.disabled" class="ml-3 warning--text">&#9888; {{$t('webhooks.trigger.deprecated')}}</span>
    </template>
  </v-select>
  <v-select
    v-if="!formEventSelected"
    :disabled="disabled"
    v-model="webhook.object"
    :items="grids"
    item-text="name"
    item-value="_links.self.href"
    :label="$t('webhooks.trigger.chooseGrid')"
    :loading="selectedSpaceLoading"
    data-testid="webhookTriggerSelectGrid"
  >
    <template slot="item" slot-scope="data">
      <span data-testid="webhookTriggerSelectGridItem">{{
        data.item.name
      }}</span>
    </template>
  </v-select>
    <v-select
      v-if="formEventSelected"
      :disabled="disabled"
      v-model="webhook.object"
      :items="formItems"
      item-text="name"
      item-value="_links.self.href"
      :label="$t('webhooks.trigger.chooseForm')"
      :loading="listFormsLoading"
      data-testid="webhookTriggerSelectForm"
    >
      <template slot="item" slot-scope="data">
        <span data-testid="webhookTriggerSelectFormItem">{{
          data.item.name
        }}</span>
      </template>
    </v-select>
    <EntityUpdatedCondition
      v-if="selectedEvent != null && selectedEvent.hasCondition"
      v-model="webhook.condition"
      :gridUri="webhook.object"
    />
  </div>
</template>

<script>
import EntityUpdatedCondition from './EntityUpdatedCondition.vue'
import { isFormEvent } from '@/store/models/flow/webhooks/Webhook'

export default {
  name: 'WebhookTriggerEvent',
  props: {
    webhook: null,
    spaceUri: null,
    event: null,
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  mounted(){
    this.selectedSpaceUri = this.webhook.spaceUri() ?? this.spaceUri
    if (this.event != null) {
      this.webhook.event = this.event
    }
  },
  data() {
    return {
      scopes: ['Space', 'Grid'],
      selectedSpaceUri: null,
      selectedSpace: null,
      selectedSpaceLoading: false,
      spacePromise: undefined,
      listFormsLoading: false,
      formItems: undefined
    }
  },
  watch: {
    selectedSpaceUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null) {
          return
        }
        this.selectedSpaceLoading = true
        try {
          this.spacePromise = this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: newVal,
            cached: true
          })
          const space = await this.spacePromise
          this.selectedSpace = await space.getRealSpace()
        } finally {
          this.selectedSpaceLoading = false
        }
      }
    },
    'webhook.event': {
      immediate: true,
      async handler(newVal) {
        if (!isFormEvent(newVal)) {
          return
        }
        this.listFormsLoading = true
        try {
          await this.spacePromise
          const allForms = await this.$store.dispatch('AGListSpaceFormsOperation', this.selectedSpaceUri)
          let formItems = []
          for (let index = 0; index < allForms.length; index++) {
            const formGroup = allForms[index]
            formItems.push({header: this.grids[index].name})
            formItems = formItems.concat(formGroup)
          }
          this.formItems = formItems
        } finally {
          this.listFormsLoading = false
        }
      }
    }
  },
  computed: {
    spaces() {
      return this.$store.getters.spacesList
    },
    grids() {
      if(this.selectedSpace == null ) return []
      return this.selectedSpace._embedded.grids ?? []
    },
    formEventSelected() {
      return isFormEvent(this.webhook.event)
    },
    forms() {
      if(!this.formEventSelected) return []
      return this.selectedGrid?._embedded.forms ?? []
    },
    events() {
      return [
          {
            text: this.$t('webhooks.trigger.entityAdded'),
            value: 'entityAddedEvent',
          },
          {
            text: this.$t('webhooks.trigger.entityUpdated'),
            value: 'entityUpdatedEvent',
            hasCondition: true
          },
          ...(this.webhook.event === 'entityAdded' ? [{
            text: this.$t('webhooks.trigger.entityAdded'),
            value: 'entityAdded',
            disabled: true
          }] : []),
          ...(this.webhook.event === 'entityUpdated' ? [{
            text: this.$t('webhooks.trigger.entityUpdated'),
            value: 'entityUpdated',
            disabled: true
          }] : []),
          {
            text: this.$t('webhooks.trigger.addEntityFormSubmitted'),
            value: 'addEntityFormSubmitted'
          },
          {
            text: this.$t('webhooks.trigger.editEntityFormSubmitted'),
            value: 'editEntityFormSubmitted',
            hasCondition: true
          }
        ]
    },
    selectedEvent() {
      if (!this.webhook.event) {
        return undefined
      }
      return this.events.find(event => event.value === this.webhook.event)
    }
  },
  methods: {
    onEventSelected() {
      this.webhook.object = null
      this.webhook.condition = null
    }
  },
  components: {
    EntityUpdatedCondition
  }
}
</script>

<style lang="css"></style>
