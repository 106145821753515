import FormInputBlock from '@/store/models/blocks/FormInputBlock'
import store from '@/store/store'

export default class StatefulFormInputBlock extends FormInputBlock {
  constructor(data) {
    super(data)
  }

  async patchType(attributes) {
    const newType = {
      name: this.valueType.name,
      ...this.columnType.state.typePatchPayloadFrom(attributes)
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        [this.type]: {
          type: newType
        }
      }
    })
    this.reload()
  }
}