import store from '@/store/store.js'
import { parseFilter } from '@/filter/filter.js'
import sortEntities from '@/sorting/sorting.js'
import { AGCondition, AGNoCondition } from '@/filter/conditions.js'
import Field from './Field'
import i18n from '@/plugins/i18n'
import PersistentGrid from '@/store/models/PersistentGrid.js'
import Entity from '@/store/models/Entity.js'
import Pager from '@/store/models/Pager.js'
import gridFilteredBus from '../../utils/gridFilteredBus'
import batchDelete from '@/constants/batchDelete.js'

const FIELD_WIDTH_PREFIX = 'fieldWidths'

export default class VirtualGrid extends PersistentGrid {

    constructor(data) {
      super(data)
      this.entities = []
      this.entitiesPager = undefined
      this.fields = data.fields?.map(field => new Field(field)) ?? []
      this.hiddenFields = data.hiddenFields?.map(field => new Field(field)) ?? []
      this.uri = data.uri
      this.name = data.name
      this.key = data.key
      this.sorting = data.sorting ?? []
      this.filter = data.filter instanceof AGCondition ? data.fiter : parseFilter(data.filter)
      this.isDefault = false
      this._cellWidth = 168
      this.prependCellWidth = 48
      this.fieldWidths = {}
      this.id = data.id
      this.loadSavedFieldWidths()
      this.reloading = false
      this.scrollerKey = 1
      this.localEntityParams = undefined
      this.selectedEntities = []
    }

    loadSavedFieldWidths() {
      const savedFieldWidthsJson = localStorage.getItem(FIELD_WIDTH_PREFIX + this.uri)
      if (savedFieldWidthsJson) {
        const savedFieldWidths = JSON.parse(savedFieldWidthsJson)
        this.fieldWidths = this.fields.map(field => field.id)
          .reduce((obj, key) => ({ ...obj, [key]: savedFieldWidths[key] }), {})
      }
    }

    saveFieldWidths() {
      localStorage.setItem(FIELD_WIDTH_PREFIX + this.uri, JSON.stringify(this.fieldWidths))
    }

    async reload(pageIndex = 1) {
      this.reloading = true
      await this.parentGrid()?.reload()
      try {
        const virtualGrid = await store().dispatch('AGReadVirtualGridOperation', {
          virtualGridUri: this.uri,
          loadEntities: false,
          pageIndex
        })
        await virtualGrid.loadEntities(pageIndex)
      } finally {
        this.reloading = false
      }
      //HACK AC-1993 hack that forces the RecycleScroller to be re-rendered
      // after a full reload. This prevents a bug where the scroller sometimes does
      // not immediately render changes made to its items array. This also ensures that
      // the scroll position is back to the top.
      if (pageIndex == 1) {
        this.scrollerKey ++
      }
    }

    async addColumn(position, properties) {
      const newFieldId = await store().dispatch('AGColumnAddOperation', {
        virtualGridUri: this.uri,
        position,
        ...properties
      })
      var fieldIds = [...this.fields.map(field => field.id)]
      if (!position) {
        fieldIds.push(newFieldId)
      } else {
        fieldIds.splice(position - 1, 0, newFieldId)
      }
      return store().dispatch('AGUpdateVirtualGridFieldsOperation', {
        virtualGrid: this,
        fieldIds: fieldIds
      })
    }

    shownFieldIds() {
      return this.fields.map(field => field.id)
    }
    async updateShownFields(fieldIds) {
      await store().dispatch('AGUpdateVirtualGridFieldsOperation', {
        virtualGrid: this,
        fieldIds
      })
      return this.reload()
    }

  setFilter(filter) {
    return store().dispatch('AGUpdateVirtualGridFilterOperation', {
      virtualGrid: this,
      filterObject: filter ? filter.exportObject() : AGNoCondition.exportObject(),
    }).then(() => {
      this.filter = filter
      if (!(filter?.conditions && filter?.conditions[1] instanceof AGNoCondition)) {
        this.reload()
      }
      gridFilteredBus.$emit('filterChanged')
    })
  }

  parentProvisionalEntity(entity) {
    // Fields from the parent are not set hence null
    const fields = this.parentGrid().fields.map(field => {
      const childIndex = this.positionOf(field)
      return childIndex >= 0 ? entity.fields[childIndex] : null
    })
    return {
      fields
    }
  }

  provisionalEntityMatches(entity) {
    var filterObject = this.filterObject()
    if (filterObject) {
      return filterObject.matches(this.parentGrid(), this.parentProvisionalEntity(entity).fields)
    }
    return true
  }

  entityMatches(entity) {
    if (!this.filterObject() || this.filterHasHiddenFieldReference()) {
      return true
    }
    return this.filterObject().matches(this, entity.fields)
  }

  parentGrid() {
    return store().getters.gridContainingVirtualGridWithUri(this.uri)
  }

  fieldWidthById(fieldId) {
    return this.fieldWidths[fieldId] ?? this._cellWidth
  }

  filterHasHiddenFieldReference() {
    return this.hiddenFields.some(field => this.filterObject().hasFieldReference(field.id))
  }

  isSorted() {
    return (typeof this.sorting === 'object' && Object.keys(this.sorting).length > 0) || (Array.isArray(this.sorting) && this.sorting.length > 0)
  }

  setSorting(sorting) {
    return store().dispatch('AGUpdateVirtualGridSortingOperation', {
      virtualGrid: this,
      sorting
    }).then(() => {
      this.reload()
    })
  }

  sortedColumnIds() {
    const criterias = Array.isArray(this.sorting) ? this.sorting : [this.sorting]
    const fieldIds = []
    criterias.forEach(criteria => {
      if (typeof criteria === 'object') {
        fieldIds.push(Object.keys(criteria)[0])
      }
    })
    return fieldIds
  }

  sort() {
    sortEntities(this.sorting, this.fields, this.entities)
  }

  newFieldName() {
    const allNames = this.allFields().map(field => field.name)
    const newFieldPrefix = i18n.t('virtualGrid.newField')
    const regex = new RegExp('^' + newFieldPrefix + '(\\d+)$')

    const newFieldIndices = allNames
      .map(name => parseInt(regex.exec(name)?.[1]))
      .filter(match => match != null && !isNaN(match))

    newFieldIndices.sort((a, b) => a - b)
    const nextIndex = (newFieldIndices[newFieldIndices.length - 1] ?? 0) + 1

    return newFieldPrefix + nextIndex
  }

  allFields() {
    return [...this.fields, ...this.hiddenFields]
  }

  async setLocalEntityParams(params ) {
    this.localEntityParams = params
  }

  async loadEntities(pageIndex = 1, pageSize) {
    var params = this.localEntityParams
    if (!Array.isArray(params)) {
      params = [ params ]
    }
    const loadPage = async (index) => {
      const promises = params.map(param => {
        return store().dispatch('AGListVirtualGridEntitiesOperation', {
          virtualGrid: this,
          pageIndex: index,
          pageSize,
          ...param
        })
      })
      const allResponses = await Promise.all(promises)
      return {
        data: {
          ...allResponses[0].data,
          items: allResponses.map(response => response.data.items).flat()
        }
      }
    }
    const updateItems = (items) => {
      this.entities = items.map((entity) => new Entity(entity))
    }
    const loadReponse = await loadPage(pageIndex)
    this.entitiesPager = new Pager(loadReponse.data, loadPage, updateItems)
    updateItems(loadReponse.data.items)
    this.reloading = false

  }

  numberOfEntities() {
    return this.entitiesPager?.numberOfItems
  }

  async getEntityCount() {

    const response = await store().dispatch('AGListVirtualGridEntitiesOperation', {
      virtualGrid: this,
      pageSize: 1
    })
    return response.data.numberOfItems
  }

  selectEntity(entity) {
    this.selectedEntities.push(entity)
  }

  selectEntitiesTo(entity) {
    const first = [...this.selectedEntities].reverse().pop()
    const indexOfFirstSelected = this.entities.indexOf(first)
    const indexClicked = this.entities.indexOf(entity)

    if (indexOfFirstSelected === -1 || indexClicked === -1) {
      throw new Error('first selected entity or clicked entity not found in the entities array.')
    }
    var itemsToAdd = this.entities.slice(indexOfFirstSelected, indexClicked)
    
    // prevent form adding twice
    itemsToAdd = itemsToAdd.filter( item => !this.selectedEntities.includes(item))
    
    // add max 20 items 
    itemsToAdd.length = batchDelete.maxEntities - this.selectedEntities.length

    // Check if each item in itemsToAdd already exists in selectedEntities
    itemsToAdd.forEach((item) => {
      this.selectedEntities.push(item)
    })
  }

  deselectEntity(entity) {
    const index = this.selectedEntities.indexOf(entity)
    if(index > -1) {
      this.selectedEntities.splice(index, 1)
    }
  }

  isEntitySelected ( entity) {
    return this.selectedEntities.includes(entity)
  }

  deselectAllEntities() {
    this.selectedEntities = []
  }

  async renameColumn(oldName, newName) {
    await this.parentGrid().renameColumn(oldName, newName)
    this._applyColumnRename(oldName, newName)
  }
}