<template>
<v-dialog v-model="externalModel" max-width="500">
  <v-card>
    <v-card-title class="text-h5 mb-3">{{ $t('paywall.entitiesPerGrid.shortHint') }}</v-card-title>
    <PaywallBanner v-bind="$attrs" multiline class="banner"/>
  </v-card>
</v-dialog>
</template>

<script>
import externalModel from '../../mixins/externalModel'
import PaywallBanner from '../paywall/PaywallBanner.vue'

export default {
  mixins: [externalModel],
  components: { PaywallBanner }
}
</script>

<style scoped>
.banner {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
</style>