<template>
    <v-sheet v-if="form != null" class="">

        <div style="max-width: 450px;">
          <v-autocomplete
            v-model="defaultLanguage"
            :items="languages"
            item-value="code"
            item-text="name"
            :label="$t('forms.builder.i18n.dialog.defaultLanguage')"
            :hint="$t('forms.builder.i18n.dialog.defaultLanguageDescription')"
            persistent-hint
            outlined
            dense
            @change="save"
          />
          <v-autocomplete
            class="mt-5"
            v-show="defaultLanguage"
            v-model="formAdditionalLanguages"
            multiple chips deletable-chips
            :items="languages"
            item-value="code"
            item-text="name"
            
            :label="$t('forms.builder.i18n.dialog.additionalLanguages')"
            :hint="$t('forms.builder.i18n.dialog.additionalLanguagesDescription')"
            persistent-hint
            outlined
            @change="save"
          />
        </div>
    </v-sheet>
</template>

<script>
import languages from '@/constants/languages.js'
import Vue from 'vue'

export default {
    props: {
        form: null,
    },
    data() {
        return {
            languages,
            internalModel: undefined,
            dirty: false
        }
    },
    watch: {
        form: {
            immediate: true,
            handler(newVal) {
                if (newVal == null) {
                  return
                }
                this.internalModel = JSON.parse(JSON.stringify(newVal.i18n ?? {}))
                this.currentLanguage = newVal.additionalLanguages?.[0]
                for (const language of newVal.additionalLanguages) {
                    if (this.internalModel[language] == null) {
                        Vue.set(this.internalModel, language, this.form.blankI18nLanguageModel())
                    }
                }
            }
        }
    },
    computed: {
        defaultLanguage: {
            get() {
                return this.form.defaultLanguage
            },
            set(newVal) {
                this.form.defaultLanguage = newVal
            }
        },
        formAdditionalLanguages: {
            get() {
                return this.form.additionalLanguages
            },
            set(newVal) {
                this.form.additionalLanguages = newVal
                for (const language of newVal) {
                    if (this.internalModel[language] == null) {
                        Vue.set(this.internalModel, language, this.form.blankI18nLanguageModel())
                    }
                }
                if (this.currentLanguage == null) {
                    this.currentLanguage = newVal[0]
                }
            }
        },
        currentLanguageI18n() {
            return this.internalModel?.[this.currentLanguage] ?? {}
        },
    },
    methods: {

        defaultValue(fieldId, key) {
            const component = this.form.components.find(item => item.fieldId === fieldId)
            return component.options[key]
        },
        save() {
            this.form.i18n = this.internalModel
            return this.form.updateComponents()
        }
    },
    components: { }
}
</script>

<style lang="scss" scoped>
</style>