<template>
  <div class="pa-3">
    <v-select
      v-model="referenceModel"
      @input="emit"
      :items="referenceItems"
      :label="$t('lookupOptions.referenceLabel')"
    />
    <v-select
      v-if="referenceModel != null"
      v-model="lookupModel"
      @input="emit"
      :items="lookupItems"
      :loading="lookupLoading"
      :label="$t('lookupOptions.lookupLabel', {gridName: referencedGridName})"
    />
  </div>
</template>

<script>
import {columnTypes} from '@/constants/columnTypes.js'

export default {
  props: {
    value: null,
    grid: null
  },
  data() {
    return {
      referenceModel: undefined,
      lookupModel: undefined,
      lookupItems: [],
      lookupLoading: false,
      referencedGrid: undefined
    }
  },
  computed: {
    referenceItems() {
      if (this.grid == null) return []
      return this.grid.fields
        .filter(field => field.type.name === columnTypes.crossReference.name)
        .map(field => ({text: field.name, value: field.uri}))
    },
    referencedGridName() {
      return this.referencedGrid?.name
    }
  },
  watch: {
    async referenceModel(newVal) {
      if (newVal == null) {
        this.lookupItems = []
      }
      const refField = this.grid.fields.find(field => field.uri === this.referenceModel)
      const referencedGridUri = refField.type.gridUri

      this.lookupLoading = true
      try {
        this.referencedGrid = await this.$store.dispatch('AGReadGridOperation', {
          uri: referencedGridUri,
        })
      } finally {
        this.lookupLoading = false
      }
      this.lookupItems = this.referencedGrid.fields
        .map(field => ({text: field.name, value: field.uri}))
    },
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal == null) return
        this.lookupModel = newVal.lookupField
        this.referenceModel = newVal.referenceField
      }
    }
  },
  methods: {
    emit() {
      if (this.lookupModel == null || this.referenceModel == null) {
        return
      }
      this.$emit('input', {
        lookupField: this.lookupModel,
        referenceField: this.referenceModel
      })
    }
  }
}
</script>