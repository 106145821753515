import ApiClient from '@/ApiClient'
import Vue from 'vue'
import Invite from '@/store/models/Invite.js'

export default {
  state() {
    return {
      invites: {}
    }
  },
  mutations: {
    addInvite(state, invite) {
      Vue.set(state.invites, invite.getLink('self'), invite)
    },
    removeInvite(state, invite) {
      delete state.invites[invite]
    }
  },
  actions: {
    AGReadInviteOperation(context, inviteUri) {
      return ApiClient.getUri(inviteUri).then(response => {
        const invite = new Invite(response.data)
        context.commit('addInvite', invite)
        return invite
      })
    },
    AGDeleteInviteOperation(context, {spaceUri, invite}) {
      const link = invite.getLink('remove')
      return ApiClient.deleteUri(link).then(() => {
        return context.dispatch('loadInvitesUris', spaceUri)
      })
    },
    AGInviteSpaceOperation(context, { spaceUri, email, role }) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink('invite')
      return ApiClient.postUri(link, { email, role })
        .then(() => {
          return context.dispatch('loadInvitesUris', spaceUri)
        })
    },
  },
  getters: {
    inviteWithUri: state => uri => {
      return state.invites[uri]
    },
    fullInvitesOfSpace: (state, getters) => spaceUri => {
      const invites = getters.invitesOfSpace(spaceUri)
      return invites
        .map(invite => state.invites[invite._links.self.href] ?? invite)
        .filter(invite => invite !== undefined)
    }
  }
}
