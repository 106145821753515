import { noValue } from '@/utils/jsUtils.js'
import { AGExpression, AGValueExpression } from '@/constants/expressions/index.js'
import { parseExpression } from '@/constants/expressions.js'

export function AGCondition() {}
AGCondition.prototype.add = function(condition) {
  const andCondition = new AGAndCondition()
  andCondition.add(this)
  andCondition.add(condition)
  return andCondition
}
AGCondition.prototype.hasFieldReference = function (fieldId) {
  return this?.left?.fieldId === fieldId
}
AGCondition.prototype.referencedFieldIds = function () {
  const fieldId = this?.left?.fieldId
  return fieldId ? [fieldId] : []
}
AGCondition.prototype.isValid = () => true

export function AGNoCondition() {
  this.matches = () => true
  this.add = condition => condition
  this.exportObject = () => {
    return {}
  }
}
AGNoCondition.prototype = Object.create(AGCondition.prototype)
AGNoCondition.exportObject = () => {
  return {}
}
AGCondition.prototype.isValid = () => false

export function AGComparisonCondition(left, right) {
  this.left = left
  this.right = parseExpression(right)
  this.compare = () => false
}
AGComparisonCondition.prototype = Object.create(AGCondition.prototype)
AGComparisonCondition.prototype.matches = function(virtualGrid, entity) {
  const valueFromEntity = this.left.resolveValue(virtualGrid, entity)
  if (valueFromEntity === undefined) {
    return false
  }
  return this.compare(valueFromEntity, this.right.resolveValue())
}
AGComparisonCondition.prototype.rightExportValue = function () {
  return this.right instanceof AGExpression ? this.right.exportValue() : this.right
}
AGComparisonCondition.prototype.exportObject = function() {
  let filter = {}
  filter[this.left.fieldId] = {}
  filter[this.left.fieldId][this.operatorString] = this.rightExportValue()
  return filter
}
AGComparisonCondition.prototype.isValid = function() {
  if (!(this.right instanceof AGExpression)) {
    return false
  }
  const rightValue = this.right.resolveValue()
  return !(rightValue === undefined || rightValue === null || Number.isNaN(rightValue))
}

export function AGEqualCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) => {
    return left.equals ? left.equals(left.value, right) : left.value === right
  }
  this.displayString = () => 'virtualGrid.filter.equalCondition'
  this.operatorString = '$eq'
}
AGEqualCondition.prototype = Object.create(AGComparisonCondition.prototype)
AGEqualCondition.prototype.exportObject = function() {
  let filter = {}
  filter[this.left.fieldId] = this.rightExportValue()
  return filter
}

export function AGNotEqualCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) => {
    return left.equals ? !left.equals(left.value, right) : left.value !== right
  }
  this.displayString = () => 'virtualGrid.filter.notEqualCondition'
  this.operatorString = '$eq'
}
AGNotEqualCondition.prototype = Object.create(AGComparisonCondition.prototype)
AGNotEqualCondition.prototype.exportObject = function () {
  let filter = {}
  filter[this.left.fieldId] = this.rightExportValue()
  return {
    $not: filter
  }
}

export function AGIsActorCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.displayString = () => 'virtualGrid.filter.isActor'
  this.compare = (left, right) => {
    return left.equals ? left.equals(left.value, right) : left.value === right
  }
  this.operatorString = '$isActor'
}
AGIsActorCondition.prototype = Object.create(AGComparisonCondition.prototype)

export function AGIsNotActorCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) => {
    return left.equals ? !left.equals(left.value, right) : left.value !== right
  }
  this.displayString = () => 'virtualGrid.filter.isNotActor'
  this.operatorString = '$isActor'
}
AGIsNotActorCondition.prototype = Object.create(AGComparisonCondition.prototype)
AGIsNotActorCondition.prototype.exportObject = function () {
  let filter = {}
  filter[this.left.fieldId] = {}
  filter[this.left.fieldId][this.operatorString] = this.rightExportValue()
  return {
    $not: filter
  }
}

export function AGLessCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) => {
    return left.isLessThan
      ? left.isLessThan(left.value, right)
      : left.value < right
  }
  this.displayString = (type) => {
    const override = type.filterConditionDisplayStringOverrides?.AGLessCondition
    return override ?? 'virtualGrid.filter.lessCondition'
  }
  this.operatorString = '$lt'
}
AGLessCondition.prototype = Object.create(AGComparisonCondition.prototype)

export function AGGreaterCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) => {
    return left.isGreaterThan
      ? left.isGreaterThan(left.value, right)
      : left.value > right
  }
  this.displayString = (type) => {
    const override = type.filterConditionDisplayStringOverrides?.AGGreaterCondition
    return override ?? 'virtualGrid.filter.greaterCondition'
  }
  this.operatorString = '$gt'
}
AGGreaterCondition.prototype = Object.create(AGComparisonCondition.prototype)

export function AGSubstringCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) =>
    typeof left.value === 'string' && left.value.includes(right)
  this.displayString = () => 'virtualGrid.filter.contains'
  this.operatorString = '$substring'
}
AGSubstringCondition.prototype = Object.create(AGComparisonCondition.prototype)
AGSubstringCondition.prototype.valueType = 'string'

export function AGNotSubstringCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) =>
    noValue(left.value) || (typeof left.value === 'string' && !left.value.includes(right))
  this.displayString = () => 'virtualGrid.filter.doesNotContain'
  this.operatorString = '$substring'
}
AGNotSubstringCondition.prototype = Object.create(AGComparisonCondition.prototype)
AGNotSubstringCondition.prototype.valueType = 'string'
AGNotSubstringCondition.prototype.exportObject = function () {
  let filter = {}
  filter[this.left.fieldId] = {}
  filter[this.left.fieldId][this.operatorString] = this.rightExportValue()
  return {
    $not: filter
  }
}

export function AGNotCondition() {
  this.operatorString = '$not'
}
AGNotCondition.prototype = Object.create(AGCondition.prototype)
AGNotCondition.prototype.add = function (condition) {
  if (condition instanceof AGSubstringCondition) {
    return new AGNotSubstringCondition(condition.left, condition.right)
  }
  if (condition instanceof AGEqualCondition) {
    return new AGNotEqualCondition(condition.left, condition.right)
  }
  if (condition instanceof AGIsActorCondition) {
    return new AGIsNotActorCondition(condition.left, condition.right)
  }
}

function collectionElementComparison(fieldRef, left, right) {
  const equalsOverride = fieldRef.elementEquals?.()
  return equalsOverride ? equalsOverride(left, right) : left === right
}

export function AGAnyCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) => {
    right = right ?? []
    if (noValue(left.value)) {
      return right.length === 0
    }
    return Array.isArray(left.value) && right.some(el => left.value.some(value => collectionElementComparison(left, el, value)))
  }
  this.displayString = () => 'virtualGrid.filter.anyOf'
  this.operatorString = '$hasAnyOf'
}
AGAnyCondition.prototype = Object.create(AGComparisonCondition.prototype)

export function AGNoneCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) => {
    right = right ?? []
    if (noValue(left.value)) {
      return true
    }
    return Array.isArray(left.value) && right.every(el => !left.value.some(value => collectionElementComparison(left, el, value)))
  }
  this.displayString = () => 'virtualGrid.filter.noneOf'
  this.operatorString = '$hasNoneOf'
}
AGNoneCondition.prototype = Object.create(AGComparisonCondition.prototype)

export function AGAllCondition(left, right) {
  AGComparisonCondition.call(this, left, right)
  this.compare = (left, right) => {
    right = right ?? []
    if (noValue(left.value)) {
      return right.length === 0
    }
    return Array.isArray(left.value) && right.every(el => left.value.some(value => collectionElementComparison(left, el, value)))
  }
  this.displayString = () => 'virtualGrid.filter.allOf'
  this.operatorString = '$hasAllOf'
}
AGAllCondition.prototype = Object.create(AGComparisonCondition.prototype)

export function AGEmptyCondition(left, right) {
  if (right) {
    return new AGIsEmptyCondition(left, right)
  } else {
    return new AGIsNotEmptyCondition(left, right)
  }
}
AGEmptyCondition.prototype = Object.create(AGComparisonCondition.prototype)
AGEmptyCondition.prototype.noArguments = true

export function AGIsEmptyCondition(left) {
  this.left = left
  this.right = new AGValueExpression(true)
  this.compare = (left) => {
    if (Array.isArray(left.value) || typeof left.value === 'string') {
      return left.value.length === 0
    }
    return noValue(left.value)
  }
  this.displayString = () => 'virtualGrid.filter.isEmpty'
  this.operatorString = '$isEmpty'
}
AGIsEmptyCondition.prototype = Object.create(AGEmptyCondition.prototype)

export function AGIsNotEmptyCondition(left) {
  this.left = left
  this.right = new AGValueExpression(false)
  this.compare = (left) => {
    if (Array.isArray(left.value) || typeof left.value === 'string') {
      return left.value.length > 0
    }
    return !noValue(left.value)
  }
  this.displayString = () => 'virtualGrid.filter.isNotEmpty'
  this.operatorString = '$isEmpty'
}
AGIsNotEmptyCondition.prototype = Object.create(AGIsEmptyCondition.prototype)

export function AGCompositeCondition() {
  this.conditions = []
}
AGCompositeCondition.prototype = Object.create(AGCondition.prototype)
AGCompositeCondition.prototype.add = function(condition) {
  this.conditions.push(condition)
  return this
}
AGCompositeCondition.prototype.addAll = function(array) {
  this.conditions = this.conditions.concat(array)
  return this
}
AGCompositeCondition.prototype.hasFieldReference = function (fieldId) {
  return this?.conditions.some(object => object.hasFieldReference(fieldId))
}
AGCompositeCondition.prototype.referencedFieldIds = function () {
  return this?.conditions.reduce(
    (cumul, condition) => [...cumul, ...condition.referencedFieldIds()],
    []
  )
}
AGCompositeCondition.prototype.isValid = function () {
  return this?.conditions.every(condition => condition.isValid())
}

export function AGAndCondition() {
  this.conditions = []
  this.matches = (virtualGrid, entity) => {
    return (
      this.conditions.filter(condition => !condition.matches(virtualGrid, entity))
        .length === 0
    )
  }
}
AGAndCondition.prototype = Object.create(AGCompositeCondition.prototype)
AGAndCondition.prototype.exportObject = function() {
  
  let filter = this.conditions.filter(condition => !(condition instanceof AGNoCondition))
    .map(condition => {
      return condition.exportObject()
    })
  return {'$and' : filter}
}

export function AGOrCondition() {
  this.conditions = []
  this.matches = (virtualGrid, entity) => {
    return (
      this.conditions.filter(condition => condition.matches(virtualGrid, entity))
        .length > 0
    )
  }
}
AGOrCondition.prototype = Object.create(AGCompositeCondition.prototype)
AGOrCondition.prototype.exportObject = function() {
  let filter = this.conditions.filter(condition => !(condition instanceof AGNoCondition))
    .map(condition => {
      return condition.exportObject()
    })
  return {'$or' : filter}
}

export function AGFieldReference(key) {
  this.fieldId = key
  this.resolveValue = (virtualGrid, entity) => {
    const fieldType = virtualGrid.fieldTypeFor(this.fieldId)
    let value = entity[virtualGrid.fields.findIndex(field => field.id === this.fieldId)]
    // value here can be an object wrapping a value
    // (in the case of a Formula), we are only interested in the actual value
    value = (typeof value ==='object' && value != null && 'value' in value) ? value.value : value
    return {
      value,
      singleType: fieldType.singleType,
      ...fieldType.compareOverrides
    }
  }
}

export const CONDITIONS = {
  $substring: AGSubstringCondition,
  $or: AGOrCondition,
  $and: AGAndCondition,
  $eq: AGEqualCondition,
  $gt: AGGreaterCondition,
  $lt: AGLessCondition,
  $hasAnyOf: AGAnyCondition,
  $hasNoneOf: AGNoneCondition,
  $hasAllOf: AGAllCondition,
  $isEmpty: AGEmptyCondition,
  $not: AGNotCondition,
  $isActor: AGIsActorCondition,
}
