<template>

      <!-- Expand button with dynamic icon -->
       <div v-if="block" class="d-flex flex-column flex-grow-1" :data-testid="`blockPageItem-${level}`" :class="`ml-${level}`">
        <v-hover v-slot="{ hover }" >

       <div class="d-flex align-center py-2 px-1" :class="{ 'on-hover': hover }" >
          <v-btn v-if="hasChildPages" @click.prevent="togglExpanded" icon x-small color="accent" class="mr-0">
            <v-icon>{{ isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
          </v-btn>
          <div v-else class="ml-5"></div>

          <!-- Block title and editable text with router connection -->
          <router-link :to="{ name: 'BlockPage', params: { blockUri: block.uri } }" 
          class="flex-grow-1 pr-1 text-decoration-none black--text block-name-container"
          :class="{ 'font-weight-bold': isSelected }"
          >
            <EditableText
              :textClass="block.isActive ? 'font-weight-bold' : ''"
              :text="blockName(block, index)"
              @submit="newName => patchName(block, newName)"
              :readonly="!(block instanceof PageBlock)"
            />
          </router-link>
          <!-- Block menu actions -->
          <v-btn :loading="loading" v-if="(hover && isSelected && level < 4) || loading" @click.prevent="addChildPage" icon x-small color="accent" class="mr-0">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <PageBlockMenu
            v-if="(canPatchBlock(block) || canDeleteBlock(block))"
            :blockUri="block.uri"
            :hideButton="!hover"
            @deleteBlock="() => deleteBlock(block)"
            :parentUri="parentUri"
            class="ml-2"
          />
      </div>
    </v-hover>
              <!-- Child blocks (submenu) -->
          <div v-if="hasChildPages && isExpanded" >
            <BlockNavigationItem
              v-for="(childBlock, childIndex) in block.pageBlocks"
              :key="childBlock.id || childIndex"
              :index="childIndex"
              :blockUri="childBlock.uri" 
              :parentUri="block.uri"
              :level="level + 1"
              />      
          </div>
      </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import PageBlockMenu from '@/components/block/PageBlockMenu.vue'
import PageBlock from '@/store/models/blocks/PageBlock'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'

export default {
  name: 'BlockNavigationItem',

  props: {
    spaceUri: null,
    blockUri: null,
    parentUri: null,
    index: null,
    level: {
      type: Number,
      default: () => 1
    },
  },
  data() {
    return {
      loading: false,
      expanded: false,
      PageBlock
    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    parentBlock() {
      return this.$store.getters.blockWithUri(this.parentUri)
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    canAddPage() {
      return hasPermission(this.space, [PERMISSIONS.addBlock])
    },
    hasChildPages() {
      return this.block?.pageBlocks.length > 0
    },
    isSelected() {
      return this.block?.isSelectedIn(this.$route.params.blockUri)
    },
    isChildSelected() {
      return this.block?.isChildSelectedIn(this.$route.params.blockUri)
    },
    isExpanded() {
      return this.expanded || this.isChildSelected
    }
  },
  methods: {
    togglExpanded() {
      this.expanded = !this.expanded
      // if a nested page is selected and the menu is expaned 
      // select this item 
      if(this.isChildSelected){
        this.$router.push({name: 'BlockPage', params: {blockUri: this.blockUri}})
      }
    },    
    canPatchBlock(block) {
      return hasPermission(block, [PERMISSIONS.patch])
    },
    canDeleteBlock(block) {
      return hasPermission(block, [PERMISSIONS.remove])
    },
    blockName(block, index) {
      return block.name || `${this.$t('blocks.emptyPageTitle')} ${index + 1}`
    },
    async patchName(block, newName) {
      await block.patchName(newName)
      block.name = newName
    },
    deleteBlock(block) {
      block.deleteBlockIn(this.parentBlock || this.space)
    },
    async addChildPage() {
      this.loading = true
      try {
        const newBlock = await this.block.addChildPage()
        this.$router.push({ name: 'BlockPage', params: { blockUri: newBlock.uri } }) 
      } finally {
        this.loading = false
      }
    }
   },
  components: {
    EditableText,
    PageBlockMenu,
  }
}
</script>

<style
  lang="scss"
  scoped
>

.block-name-container {
  overflow: hidden;
  text-overflow: ellipsis;
}

.on-hover {
  background-color: #f4f4f4;
  border-radius: 12px;
}

</style>