<template>
  <BaseFormInput v-bind="$props">
    <v-text-field
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema, rules.email]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      v-model.trim="inputValue"
      :color="color"
      :disabled="disabled"
      v-bind="fieldStyle"
    />
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components/form/BaseFormInput.vue'

export default {
  mixins: [formFields],
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        const sanitizedValue = value === '' ? null : value
        this.$emit('input', sanitizedValue)
      }
    },
  },
  components: {
    BaseFormInput
  }
}
</script>
