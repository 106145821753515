<template>
  <v-banner
   v-if="paywallActive"
   class="paywall-banner"
   :icon="multiline ? 'mdi-creation' : ''"
   :icon-color="multiline ? 'yellow darken-2' : ''"
   :single-line="!multiline"
   outlined
   rounded
 >
  <v-icon v-if="!multiline" color="yellow darken-2" small class="mr-2">mdi-creation</v-icon>
  <span class="font-weight-medium">{{text}}</span>
   <template v-slot:actions>
     <PaywallButton />
   </template>
 </v-banner>
</template>

<script>
import PaywallButton from '@/components/paywall/PaywallButton.vue'
import paywall from '@/mixins/paywall.js'

export default {
  mixins: [paywall],
  props: {
    text: null,
    multiline: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    PaywallButton
  }
}
</script>

<style lang="scss">
.paywall-banner .v-banner__actions {
  align-self: center !important;
  margin-bottom: 0px !important;
}
</style>
