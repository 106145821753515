<template>
  <BaseNodeComponent
    v-model="externalModel"
    v-bind="$attrs"
    :data-testid="`input_${$attrs.testKey}`"
  >
    <TemplateMapper
      v-model="externalModel"
      @blur="$emit('blur')"
      ref="templateMapper"
      v-bind="$attrs"
    >
      <v-select
        v-model="innerValue"
        v-bind="$attrs"
        v-on="$listeners"
        hide-details
        solo
        dense
        :multiple="multiple"
        clearable
      >
        <template
          slot="item"
          slot-scope="data"
        >
          <span :data-testid="`item_${data.item}`">{{
            data.item
            }}</span>
        </template>
      </v-select>
    </TemplateMapper>
  </BaseNodeComponent>
</template>

<script>
import { createHolder } from '@/apptivescript/model'
import BaseNodeComponent from '@/components/flow/BaseNodeComponent.vue'
import TemplateMapper from '@/components/flow/TemplateMapper.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [ externalModel ],
  props: {
    multiple: {
      type: Boolean,
      default: () => false
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.externalModel?.value
      },
      set(newVal) {
        this.externalModel = createHolder(newVal)
        this.$emit('blur')
      }
    }
  },
  methods: {
    onInput(newVal) {
      this.externalModel = createHolder(newVal)
      this.$emit('blur')
    },
    insert(text) {
      this.$refs.templateMapper.insert(text)
    }
  },
  components: {
    BaseNodeComponent,
    TemplateMapper,
  }
}
</script>