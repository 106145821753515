<template>
<v-dialog v-model="dialog" max-width="400">
    <v-card>
    <v-card-title class="headline">{{ $t('paywall.seatsConsumed.title') }}</v-card-title>
    <v-card-text>
      <p>{{ $t('paywall.seatsConsumed.moreUsersInvited') }}</p>
      <p v-if="this.numberOfNeededSeats > 1" >{{ $t('paywall.seatsConsumed.needAdditionalPlaces', {numberOfNeededSeats: numberOfNeededSeats}) }}</p>
      <p v-else >{{ $t('paywall.seatsConsumed.needAdditionalPlace') }} </p>
      <p>{{ $t('paywall.seatsConsumed.changeUserPermissions') }}</p>
    </v-card-text>

    <v-card-actions class="justify-end">
        <v-btn color="primary" text @click="openChat">  {{ $t('paywall.upgradeTeaserChatButton') }} <v-icon class="ml-2" small>mdi-share</v-icon></v-btn>
        <PaywallButton />
    </v-card-actions>
  </v-card>

  </v-dialog>
</template>

<script>
import PaywallButton from '@/components/paywall/PaywallButton.vue'

export default {
    props: ['spaceUri'],
    data() {
        return {
            dialog: false
        }
    },
    watch: {
        showDialog: {
            immediate: true,
            async handler(newVal) {
                this.dialog = newVal 
            }
         }
    },
    methods: {
        openChat() {
            this.$intercom.showNewMessage()
         },
    },
    computed: {
        isOwner() {
            return this.space?.owner.id == this.user?.id
        },
        owner() {
            return this.space?.owner
        },
        user() {
            return this.$store.state.user?.user
        },
        showDialog() {
            return this.availableSeats < 0 && this.isOwner
        },
        availableSeats() {
            return this.space.availableSeats
        },
        numberOfNeededSeats() {
            return this.space.availableSeats * -1
        },
        space() {
            return this.$store.getters.spaceWithUri(this.spaceUri)
        }
    },
    components: {
        PaywallButton
    }
}
</script>

<style></style>