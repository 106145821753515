<template>
  <div class="">

  <div class="pa-0">
    <v-text-field
      :label="$t('forms.builder.formSuccessTitleLabel')"
      v-model="successTitle" dense
      @blur="saveForm"
      data-testid="form-builder-success-title"
    />
  </div>

  <div class="pa-0">
    <v-textarea
      :label="$t('forms.builder.successMessageLabel')"
      v-model="successMessage"
      rows="1"
      auto-grow
      dense
      @blur="saveForm"
      data-testid="form-builder-success-message"
    ></v-textarea>
  </div>

    <v-sheet
      color="grey lighten-4"
      class="pa-2 ml-n2 mr-n2"
      rounded="lg">
    <div class="subtitle-2 accent--text">{{$t('forms.builder.afterSubmitAction.actionLabel')}}</div>

    <v-select
      class="mt-1"
      v-model="action"
      :items="actions"
      solo dense flat
      hide-details
      @change="saveForm"
    >
    <template v-slot:item="{ item, on }" >
      <v-list-item class="pa-0 ma-0">
        <v-list-item-content class="pa-0 ma-0 flex-grow-1">
        <PaywallMenu :feature="$apptive.constants.features.FORM_SUCCESS_REDIRECT" :count="item.value === 'redirect' ? 0 : -1">
            <v-list-item-title v-on="on" class="ml-4 mr-4 mb-3 mt-3">{{item.text}}</v-list-item-title>
          </PaywallMenu >
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>

    <div class="pa-0" v-if="action != 'none'">

    <div class="mt-3" v-if="action == 'redirect'">
    <div class="subtitle-2 accent--text">{{$t('forms.builder.afterSubmitAction.formRedirectUrlLabel')}}</div>
    <v-text-field
      class="mt-1"
      :placeholder="$t('forms.builder.afterSubmitAction.formRedirectUrlPlaceholder')"
      v-model="targetUrl"
      @blur="saveForm"
      data-testid="form-builder-redirect-url"
      hint="https://apptivegrid.de"
      solo dense flat
      hide-details
    />
    </div>

    <div class="mt-2 subtitle-2 accent--text">{{$t('forms.builder.afterSubmitAction.triggerLabel')}}</div>

    <v-select
      v-model="trigger"
      class="mt-1"
      :items="triggers"
      solo dense flat
      hide-details
      @change="saveForm"
    ></v-select>

    <div class="mt-3" v-if="action == 'additionalAnswer' && trigger == 'button'">
      <div class="subtitle-2 accent--text">{{$t('forms.builder.afterSubmitAction.sendMoreButton')}}</div>
      <v-text-field
        :placeholder="$t('forms.view.submitMoreButton')"
        v-model="buttonTitle"
        class="mt-1"
        @blur="saveForm"
        data-testid="form-builder-additional-answer"
        solo dense flat
        hide-details
      />
    </div>

    <div class="mt-3" v-if="action == 'redirect' && trigger == 'button'">
      <div class="subtitle-2 accent--text">{{$t('forms.builder.afterSubmitAction.redirectButton')}}</div>
      <v-text-field
      :placeholder="$t('forms.view.submitMoreButton')"
        v-model="buttonTitle"
        class="mt-1"
        @blur="saveForm"
        data-testid="form-builder-redirect-button"
        solo dense flat
        hide-details
      />
    </div>

  </div>
</v-sheet>

  <PaywallMenu :feature="$apptive.constants.features.FORM_HIDE_AD">
    <v-switch
      v-model="adsHidden"
      @change="saveForm"
      inset
      dense
      left
      >
    <template v-slot:label>
      <span class="subtitle-2">{{$t('forms.builder.adsSwitchLabel')}}</span>
    </template></v-switch>
  </PaywallMenu>
    </div>
</template>

<script>
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'
import { actions, triggers } from '@/constants/afterSubmitTypes.js'

export default {
  props: {
    spaceUri: null,
    formUri: null,
  },
  data() {
    return {
      actions: [
        {
          text: this.$t('forms.builder.afterSubmitAction.additionalAnswer'),
          value: actions.ADDITIONAL_ANSWER,
        },
        {
          text: this.$t('forms.builder.afterSubmitAction.redirect'),
          value: actions.REDIRECT,
        },
        {
          text: this.$t('forms.builder.afterSubmitAction.none'),
          value: actions.NONE,
        }
      ],
      triggers: [
        {
          text: this.$t('forms.builder.afterSubmitAction.triggerButton'),
          value: triggers.BUTTON,
        },
        {
          text: this.$t('forms.builder.afterSubmitAction.triggerAuto'),
          value: triggers.AUTO,
        },
        {
          text: this.$t('forms.builder.afterSubmitAction.triggerDelay'),
          value: 'delay',
        }
      ],
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri) ?? {}
    },
    form() {
      return this.$store.getters.formWithUri(this.formUri)
    },
    successMessage: {
      get() {
        return this.form.successMessage
      },
      set(value) {
        this.form.successMessage = value
      }
    },
    successTitle: {
      get() {
        return this.form.successTitle
      },
      set(value) {
        this.form.successTitle = value
      }
    },
    action: {
      get() {
        return this.form.afterSubmitAction
      },
      set(value) {
        this.form.afterSubmitAction = value
      }
    },
    trigger: {
      get() {
        let sanitized = this.form.afterSubmitTrigger
        if( sanitized == triggers.AUTO && this.form.afterSubmitTriggerDelay ){
          sanitized = 'delay'
        }
        return sanitized
      },
      set(value) {
        let sanitized = value
        if( value == 'delay'){
          sanitized = triggers.AUTO
          this.form.afterSubmitTriggerDelay = 5
        }
        else {
          this.form.afterSubmitTriggerDelay = 0
        }
        this.form.afterSubmitTrigger = sanitized
      }
    },
    buttonTitle: {
      get() {
        return this.form.afterSubmitActionButtonTitle
      },
      set(value) {
        this.form.afterSubmitActionButtonTitle = value
      }
    },
    targetUrl: {
      get() {
        return this.form.afterSubmitTargetUrl
      },
      set(value) {
        this.form.afterSubmitTargetUrl = value
      }
    },
    adsHidden: {
      get() {
        return this.form.adsHidden
      },
      set(value) {
        this.form.adsHidden = value
      }
    },
  },
  methods: {
    saveForm() {
      this.form.updateComponents()
    },
  },
  components: {
    PaywallMenu,
  }
}
</script>

<style lang="css" scoped>
</style>
