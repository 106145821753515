<template>
  <div class="version text-caption">
    API {{ apiVersion }} - Web {{ webVersion }}
  </div>
</template>

<script>
export default {
  name: 'Version',
  mounted() {
    this.$store.dispatch('LoadVersion')
  },
  computed: {
    apiVersion() {
      return this.$store.getters.apiVersion
    },
    webVersion() {
      return this.$webVersion
    }
  }
}
</script>

<style lang="css"></style>
