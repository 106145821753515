<template>
  <v-dialog v-model="dialogModel" max-width="460">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon>
          <v-icon @click="dialogModel = false">mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{$t('columnTypes.attachments')}}</v-toolbar-title>
      </v-toolbar>
      <file-pond
        :label-idle="$t('attachments.pondLabel')"
        :allow-multiple="true"
        :existingFiles="files"
        :disabled="readonly"
        @fileUploaded="onFileUploaded"
        @fileDeleted="onFileDeleted"
        @fileRenamed="onFileRenamed"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import FilePond from '@/components/attachments/FilePond.vue'

export default {
  props: ['files', 'value', 'readonly'],
  data() {
    return {
      myFiles: []
    }
  },
  computed: {
    dialogModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    onFileUploaded(fileUrl) {
      this.$emit('fileUploaded', fileUrl)
    },
    onFileDeleted(fileUrl) {
      this.$emit('fileDeleted', fileUrl)
    },
    onFileRenamed(fileUrl, newName) {
      this.$emit('fileRenamed', fileUrl, newName)
    }
  },
  components: {
    FilePond
  }
}
</script>

