<template>
  <v-menu :disabled="disabled" offset-y v-model="dialog" :close-on-content-click="false" max-width="480">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="dialogButton"></slot>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <ViewIcon class="mr-2" :type="ViewIconTypes.FORM" />
        {{ $t("forms.shareForm.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("forms.shareForm.text") }}
      </v-card-subtitle>

      <v-card-text style="position: relative">
        <LoadingOverlay :show="loading" />
        <div class="d-flex flex-column gap-3 link-container">
          <FormLinkView
            v-for="(link, index) in links"
            :key="index"
            :link="link"
            @showQrCode="() => showQrCode(link)"
            @showPrefillFormEditor="() => showPrefillFormEditor(link)"
            @linkDeleted="loadLinks"
          />
        </div>
      </v-card-text>
      <v-card-actions class="justify-start">
        <PaywallMenu :feature="$apptive.constants.features.ADD_EXTERNAL_LINKS" :count="links.length">
          <v-btn
            text
            color="primary"
            @click="newLinkDialog = true"
          >{{$t('forms.shareForm.buttons.addLink')}}</v-btn>
        </PaywallMenu>
      </v-card-actions>

    </v-card>
    <SingleInputDialog
      v-model="newLinkDialog"
      max-width="450"
      :title="$t('forms.shareForm.addLinkDialog.title')"
      :text="$t('forms.shareForm.addLinkDialog.text')"
      :max="25"
      :okHandler="addLink"
      :additionalRules="noDuplicateNameRule"
    />

    <PrefillFormEditor
      v-model="prefillFormEditor"
      :formUri="formUri"
      :gridUri="gridUri"
      :uri="currentLink != null ? currentLink.redirectUri : undefined"
    />

    <QrCodeDialog
      v-model="qrCodeDialog"
      :uri="absoluteUri"
    />
  </v-menu>
</template>

<script>
import {ViewIconTypes} from '@/constants/viewIconTypes.js'
import PrefillFormEditor from './PrefillFormEditor.vue'
import FormLinkView from './FormLinkView.vue'
import QrCodeDialog from '@/components/QrCodeDialog.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'

export default {
  props: {
    formUri: null,
    gridUri: null,
    buttonTemplate: null,
    disabled: null
  },
  data() {
    return {
      currentLink: undefined,
      qrCodeDialog: false,
      newLinkDialog: false,
      dialog: false,
      links: [],
      tab: 0,
      ViewIconTypes,
      prefillFormEditor: false,
      loading: false
    }
  },
  computed: {
    form() {
      return this.$store.getters.formWithUri(this.formUri)
    },
    noDuplicateNameRule() {
      const linkNames = this.links.map(link => link.name)
      return [
        v => !linkNames.includes(v) || this.$t('validation.nameExistsError')
      ]
    },
    absoluteUri() {
      if (this.currentLink == null) {
        return
      }
      return `${window.location.origin}${this.currentLink.redirectUri}`
    },
  },
  watch: {
    async dialog(newVal) {
      if (!newVal) {
        return
      }
      this.loading = true
      try {
        await this.initLinks()
      } finally {
        this.loading = false
      }
    }
  },
  methods: {
    showQrCode(link) {
      this.currentLink = link
      this.qrCodeDialog = true
    },
    showPrefillFormEditor(link) {
      this.currentLink = link
      this.prefillFormEditor = true
    },
    async loadLinks() {
      if (this.form == null) {
        return
      }
      const allLinks = await this.form.loadLinks()
      this.links = allLinks.filter(link => link.type === 'addEntity')
    },
    async addLink(name) {
      await this.$store
        .dispatch('AGWriteAddFormEntityOperation', {
          name,
          formUri: this.formUri,
          gridUri: this.gridUri
        })
      this.loadLinks()
    },
    async initLinks() {
      await this.loadLinks()
      if(this.disabled || this.links.length > 0) {
        return
      }
      this.addLink()
    },
  },
  components: {
    ViewIcon: () => import('@/components/ViewIcon.vue'),
    PrefillFormEditor,
    // QRCode,
    FormLinkView,
    QrCodeDialog,
    LoadingOverlay,
    SingleInputDialog,
    PaywallMenu
},
}
</script>

<style scoped>
.link-container {
  max-height: 400px;
  overflow: auto;
}
</style>
