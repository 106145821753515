<template>
  <BaseFormInput v-bind="$props">
    <v-text-field
      :readonly="readonly"
      v-model="inputModel"
      @blur="emitInputValue"
      v-mask="dateTimeMask"
      :rules="[rules.required, rules.schema, rules.dateTime]"
      :label="fieldStyle.labelAbove ? null : label"
      :hide-details="description || errors ? 'auto' : false"
      :color="color"
      :disabled="disabled"
      v-bind="fieldStyle"
    >
      <template v-if="!disabled" v-slot:append>
        <DateAndTimePicker
          v-model="pickerModel"
          :color="color"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon>
              <v-icon v-on="on" v-bind="attrs"
                >mdi-calendar-clock</v-icon
              >
            </v-btn>
          </template>
        </DateAndTimePicker>
      </template>
    </v-text-field>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import DateAndTimePicker from '@/components/DateAndTimePicker.vue'
import moment from 'moment'
import { DATETIME_MASK, DATETIME_DISPLAY_FORMAT } from '@/constants/dateFormats.js'
import { VueMaskDirective } from 'v-mask'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  mixins: [formFields],
  name: 'FormDateAndTimeField',
  data() {
    return {
      inputModel: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.inputModel = newVal
         ? moment(newVal).format(DATETIME_DISPLAY_FORMAT)
         : undefined
      }
    }
  },
  computed: {
    pickerModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
        return newVal
      }
    },
    dateTimeMask() {
      return DATETIME_MASK
    }
  },
  methods: {
    emitInputValue() {
      const inputMoment = moment(this.inputModel, DATETIME_DISPLAY_FORMAT)
      if (inputMoment.isValid()) {
        this.$emit('input', inputMoment.toISOString())
      } else {
        this.$emit('input', null)
      }
    }
  },
  components: {
    DateAndTimePicker,
    BaseFormInput
  },
  directives: {
    mask: VueMaskDirective
  },
}
</script>
