<template>
  <div>
    <SingleInputDialog
      v-model="editDescriptionDialog"
      :title="$t('flow.condition.editConditionLabel')"
      :okButtonLabel="$t('dialogs.saveButton')"
      :okHandler="updateDescription"
      :initialInputValue="transition.description"
      max-width="350"
      area
      :nameRequired="false"
    />
    <FlowTransitionConditionDialog
      v-model="editConditionDialog"
      :flow="flow"
      :transition="transition"
    />
    <v-menu
      v-model="menu"
      offset-y
      open-on-hover
      :close-on-content-click="false"
      close-delay="200"
      open-delay="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="visible || menu"
          class="filterButton"
          color="accent"
          v-on="on"
          v-bind="attrs"
          icon
          small
        >
          <v-icon small>{{icon}}</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <MenuItem
          :disabled="!canPatchTransition"
          icon="mdi-filter"
          :text="$t('flow.condition.editConditionButton')"
          @click="editConditionDialog = true"
        />
        <MenuItem
          :disabled="!canPatchTransition"
          icon="mdi-text"
          :text="$t('flow.condition.editConditionLabelButton')"
          @click="editDescriptionDialog = true"
        />
        <MenuItem
          :disabled="!canRemoveTransition"
          icon="mdi-link-off"
          :text="$t('flow.condition.deleteConditionButton')"
          @click="$emit('deleteTransition')"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import FlowTransitionConditionDialog from './FlowTransitionConditionDialog.vue'


export default {
  props: {
    flow: null,
    transition: null,
    visible: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      menu: false,
      editDescriptionDialog: false,
      editConditionDialog: false
    }
  },
  computed: {
    canPatchTransition() {
      return hasPermission(this.flow, [PERMISSIONS.patch])
    },
    canRemoveTransition() {
      return hasPermission(this.flow, [PERMISSIONS.remove])
    },
    icon() {
      return this.transition?.condition ? 'mdi-filter' : 'mdi-cog'
    }
  },
  methods: {
    updateDescription(description) {
      this.transition.updateDescription(description)
    }
  },
  components: {
    MenuItem,
    SingleInputDialog,
    FlowTransitionConditionDialog
  }
}
</script>
<style scoped>
.filterButton {
  background-color: rgb(240, 240, 240);
}
</style>

