<template>
<SingleInputDialog
  :disabled="disabled"
  v-model="externalModel"
  :title="$t('dialogs.setGridKeyTitle', { gridTitle })"
  :text="$t('dialogs.setGridKeyText')"
  :okButtonLabel="$t('dialogs.okButton')"
  :okHandler="save"
  :initialInputValue="gridKey"
  :max="$apptive.constants.nameLengths.default"
  :nameRequired="false"
  max-width="290"
/>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  props: {
    disabled: null,
    gridUri: null,
  },
  computed: {
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    gridKey() {
      return this.grid?.key
    },
    gridTitle() {
      return this.grid?.title()
    }
  },
  methods: {
    save(key) {
      return this.grid.setKey(key)
    }
  },
  components: {
    SingleInputDialog
  }
}
</script>
