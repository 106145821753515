<template>
<SingleInputDialog
  :title="$t('dialogs.renameAttachmentTitle', { title: attachment.name })"
  :text="$t('dialogs.renameAttachmentText')"
  :okButtonLabel="$t('dialogs.saveButton')"
  :okHandler="save"
  :initialInputValue="attachment.name"
  v-bind="$attrs"
  v-on="$listeners"
  max-width="290"
/>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'

export default {
  props: {
    attachment: {
      type: Object,
      default: () => {return {}}
    }
  },
  methods: {
    save(newName) {
      if (this.attachment.name == newName) {
        return
      }
      this.$emit('newName', newName)
    }
  },
  components: {
    SingleInputDialog
  }
}
</script>

