<template>
  <v-avatar :size="size" v-ripple class="avatar-container" :style="fallbackStyle">
    <img
      v-if="!displayFallback"
      :src="avatarUrl"
      :style="imageStyle"
    >
    <div class="fallback-avatar" v-else>
      {{fallbackText}}
    </div>
  </v-avatar>
</template>

<script>
import axios from 'axios'
import { darkColor } from '@/utils/color'

export default {
  props: [ 'userId', 'size', 'email' ],
  data() {
    return {
      displayFallback: true,
      fallbackColors: [
        '#ff453aff', '#007affff', '#5856D6', '#30d158ff', '#ffd60aff'
      ]
    }
  },
  computed: {
    avatarUrl(){
      if (this.userId) {
        return this.$store.getters.avatarUrlOf(this.userId)
      }
      return this.$store.getters.avatarSrc || 'avatar.svg'
    },
    imageStyle() {
      return {
        height:`${this.size}px !important`,
        width: 'unset'
      }
    },
    fallbackText() {
      return this.email?.slice(0, 2).toUpperCase() ?? '?'
    },
    fallbackBackground() {
      return this.fallbackColors[this.fallbackText.charCodeAt(0) % this.fallbackColors.length]
    },
    fallbackTextColor() {
      return darkColor(this.fallbackBackground) ? 'rgba(0, 0, 0, 0.54)' : 'white'
    },
    fallbackStyle() {
      return this.displayFallback ? {
        color: this.fallbackTextColor,
        background: this.fallbackBackground
      } : {}
    }
  },
  watch: {
    avatarUrl: {
      immediate: true,
      async handler(newVal) {
        try {
          await axios.head(newVal, {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          })
          this.displayFallback = false
        } catch {
          this.displayFallback = true
        }
      }
    }
  }
}
</script>

<style scoped>
.avatar-container {
  container-type: inline-size;
}

.fallback-avatar {
  font-size: 50cqi;
}
</style>