<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    @keydown.esc="close()"
    transition="slide-x-reverse-transition"
  >
    <v-card class="no-border-radius full-height">
      <div class="pa-8 full-height d-flex flex-column">
        <div class="d-flex py-3 align-center">
          <v-btn icon light large color="primary" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span>{{ $t("dialogs.createSpace") }}</span>
        </div>
        <v-row class="flex-grow-0">
          <v-col cols="12" sm="6">
            <span class="text-h4">{{ $t("dialogs.createSpaceText") }}</span>
              <v-text-field
                ref="nameInput"
                v-model="name"
                label="Name"
                data-testid="createSpaceInputName"
                class="pt-12 pb-5"
                outlined
                autofocus
                @keyup.enter.native="createSpace"
                :counter="maxNameLength"
                :rules="[...rules.maxLength(maxNameLength), ...rules.spaces]"
              />
            <v-btn
              depressed
              :disabled="saveButtonDisabled"
              color="primary"
              data-testid="createSpaceSubmitButton"
              @click="createSpace"
              :loading="loading"
            >
              {{ $t("dialogs.createButton") }}
            </v-btn>
          </v-col>
        </v-row>
        <TemplateList
          v-if="show"
          class="mt-5 flex-grow-1"
          @templateSelected="createSpaceFromTemplate"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { textFieldRules } from '@/mixins/textFieldRules.js'
import TemplateList from '@/components/templates/TemplateList.vue'
import nameLengths from '@/constants/nameLengths.js'

export default {
  name: 'CreateSpaceDialog',
  props: {
    show: Boolean
  },
  components: {
    TemplateList
  },
  mixins: [textFieldRules],
  data() {
    return {
      name: null,
      loading: false,
      maxNameLength: nameLengths.space
    }
  },
  computed: {
    saveButtonDisabled() {
      return (
        !this.name ||
        this.name.length > this.maxNameLength ||
        this.$store.getters.spaces.some(el => el.name === this.name)
      )
    }
  },
  methods: {
    close() {
      this.$emit('showSpaceDialog', false)
    },
    createSpace() {
      this.loading = true
      this.$store.dispatch('AGAddSpaceOperation', this.name)
        .then(response => {
          this.$intercom?.trackEvent( 'User created a Space' )
          this.$emit('createGridDialog', response)
          this.close()
        })
        .finally(() => this.loading = false)
    },
    createSpaceFromTemplate(template) {
      this.loading = true
      this.$store.dispatch('copyTemplate', {
        templateId: template.spaceId,
        name: this.name || template.title
      })
        .then((uri)=> 
          this.$router.push({
            name: 'Space',
            params: {spaceUri: uri}
          }))
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>
.no-border-radius {
  border-radius: 0;
}

.full-height {
  height: 100%;
  overflow: scroll;
}
</style>
