<template functional>
  <div
    class="cell-content pl-2 border-right cell grey--text text--darken-3"
    :class="$options.classesFromData(data)"
    :style="data.style"
    v-bind="data.attrs"
    v-on="listeners"
    v-html="props.displayValue"
  ></div>
</template>

<script>
import { classesFromData } from '@/utils/vueUtils.js'

export default {
  props: ['displayValue'],
  classesFromData
}
</script>

<style>
.cell-content{
  font-size: 0.9em !important;
  white-space: nowrap;
  line-height: 31px;
  text-overflow: ellipsis;
}
</style>
