import StartNode from '@/store/models/flow/nodes/StartNode'
import { nodeTypes } from '@/constants/nodeTypes'

export default class ExternalTriggerNode extends StartNode {
  constructor(data) {
    super(data)

    this.typeName = nodeTypes.requestTrigger.typeName
    this.description = nodeTypes.requestTrigger.description
    this.icon = nodeTypes.requestTrigger.icon
    this.graphIcon = nodeTypes.requestTrigger.graphIcon
    this.color = nodeTypes.requestTrigger.color
  }
}