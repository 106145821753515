<template>
  <div class="d-flex">
    <CompactSelect
      v-show="showExpressionSelect"
      :showControls="showControls"
      :disabled="disabled"
      v-model="expression"
      class="expression-select ma-0"
      :items="expressionItems"
      data-testid="gridFilterExpressionSelect"
    />
    <GridCellFactory
      v-if="!expressionItems || (selectedExpression && selectedExpression.prototype.hasArgument)"
      class="grid-filter-value-cell"
      :class="{'no-left-border': cellValueValid}"
      :readonly="disabled"
      :solo="true"
      :value="cellValue"
      @input="(newVal) => cellValue = newVal"
      v-bind="cellProps"
      :error="!cellValueValid"
      data-testid="gridFilterValueCell"
    />
  </div>
</template>

<script>
import GridCellFactory from '@/components/gridView/GridCellFactory.vue'
import CompactSelect from '@/components/CompactSelect.vue'
import { AGExpression, AGValueExpression } from '@/constants/expressions/index.js'
import typeValidation from '@/utils/typeValidation.js'

export default {
  props: {
    value: null,
    type: null,
    field: null,
    disabled: null,
    showControls: {
      type: Boolean,
      default: () => true
    },
    cellValueValid: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    expression: {
      get() {
        if (this.value) {
          return this.value.displayString(this.type)
        }
        const expressionType = this.expressionFrom(this.expressionItems[0])
        // If the pre-selected expression has no argument, we can submit it right away
        if (!expressionType.hasArgument) {
          const newExpression = new expressionType()
          this.$emit('input', newExpression)
        }
        return this.expressionItems[0]
      },
      set(newVal) {
        const sanitizedValue = typeValidation.sanitizedEntityValue(this.type, this.cellValue)
        const expressionType = this.expressionFrom(newVal)
        const newExpression = new expressionType(sanitizedValue)
        this.$emit('input', newExpression)
      }
    },
    expressionArgumentType() {
      if (this.selectedExpression && this.selectedExpression.prototype.expressionArguments) {
        return this.selectedExpression.prototype.expressionArguments()[0]
      }
      return this.type
    },
    cellProps() {
      if (this.selectedExpression && this.selectedExpression.prototype.expressionArguments) {
        return {
          type: this.expressionArgumentType
        }
      } else {
        return {
          field: this.field,
          type: this.type.filterOperandType?.() ?? this.type,
        }
      }
    },
    cellValue: {
      get() {
        return this.value instanceof AGExpression ? this.value.internalValue : this.value
      },
      set(newVal) {
        const sanitizedValue = typeValidation.sanitizedEntityValue(this.expressionArgumentType, newVal)
        if(this.selectedExpression?.prototype.hasArgument) {
          const newExpression = new this.selectedExpression(sanitizedValue)
          this.$emit('input', newExpression)
        } else {
          this.$emit('input', sanitizedValue)
        }
      }
    },
    availableExpressions() {
      return this.type?.filterExpressions ?? [ AGValueExpression ]
    },
    expressionItems() {
      return this.availableExpressions
        .map(expression => this.$t((new expression()).displayString(this.type)))
    },
    selectedExpression() {
      return this.expressionFrom(this.expression)
    },
    showExpressionSelect() {
      return (this.type?.filterExpressions ?? []).length >= 1
    }
  },
  methods: {
    expressionFrom(expressionItem) {
      if (!expressionItem) {
        return
      }
      return this.availableExpressions[this.expressionItems.indexOf(expressionItem)]
    }
  },
  components: {
    GridCellFactory,
    CompactSelect
  }
}
</script>

<style scoped>
.grid-filter-value-cell {
  height: 32px;
  flex-grow: 1;
  border-color: #d3d2d4
}

.no-left-border {
  border-left-style: none;
}

.expression-select {
  border-radius: 0;
  border-left-style: none;
}
</style>