<template>
  <div class="">

  <v-list >

    <v-list-item>
     <v-list-item-content>
       <v-select
        v-model="style"
        :items="styleItems"
       />
      <v-textarea
        v-model="text"
        class="mb-1"
        dense
        elevation="0"
        hide-details
        :placeholder="$t('forms.builder.textBlockDetails.textInput')"
        data-testid="builder-field-label-input"
       />
     </v-list-item-content>
    </v-list-item>
    <div class="d-flex justify-end">
      <v-btn
        class="ma-3"
        text
        color="primary"
        @click="done"
      >
        {{ $t("dialogs.saveButton") }}
        </v-btn>
      </div>

  </v-list>
    </div>
</template>

<script>
import { STYLES } from '@/store/models/TextBlock.js'

export default {
  props: ['component'],
  computed: {
    styleItems() {
      return Object.keys(STYLES).map(key => ({
        text: this.$t(`forms.builder.textBlockDetails.styles.${key}`),
        value: STYLES[key]
      }))
    },
    text: {
      get () {
        return this.component.text
      },
      set (value) {
        this.component.text = value || null
      }
    },
    style: {
      get () {
        return this.component.style
      },
      set (value) {
        this.component.style = value || null
      }
    },
  },
  methods: {
    done() {
      this.$emit('finishTextBlockDetails')
    }
  }
}
</script>
