<template>
  <v-dialog
    v-model="dialogModel"
    max-width="290"
    @click:outside="close"
    @keydown="close"
  >
    <v-card>
      <div class="animated-icon">
        <lottie
          v-if="error"
          :options="errorAnimationOptions"
          :height="100"
          :width="100"
          v-on:animCreated="handleErrorAnimation"
        />
        <lottie
          v-if="info"
          :options="infoAnimationOptions"
          :height="100"
          :width="100"
          v-on:animCreated="handleInfoAnimation"
        />
        <lottie
          v-if="success"
          :options="successAnimationOptions"
          :height="100"
          :width="100"
          v-on:animCreated="handleSuccessAnimation"
        />
      </div>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onOkClick">{{
          $t("dialogs.okButton")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Lottie from 'vue-lottie'
import * as errorAnimationData from '@/assets/error.json'
import * as infoAnimationData from '@/assets/info.json'
import * as successAnimationData from '@/assets/success.json'
export default {
  props: {
    error: Boolean,
    info: Boolean,
    success: Boolean,
    value: Boolean,
    text: String,
    okHandler: Function
  },
  data() {
    return {
      errorAnimationOptions: {
        animationData: errorAnimationData.default,
        loop: false
      },
      infoAnimationOptions: {
        animationData: infoAnimationData.default,
        loop: false
      },
      successAnimationOptions: {
        animationData: successAnimationData.default,
        loop: false
      },
    }
  },
  computed: {
    dialogModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    close() {
      this.dialogModel = false
    },
    onOkClick() {
      this.okHandler?.()
      this.close()
    },
    handleErrorAnimation: function(anim) {
      this.errorAnimation = anim
    },
    handleInfoAnimation: function(anim) {
      this.infoAnimation = anim
    },
    handleSuccessAnimation: function(anim) {
      this.successAnimation = anim
    },
  },
  components: {
    Lottie
  }
}
</script>

<style scoped>
.animated-icon {
  padding: 20px;
}
</style>
