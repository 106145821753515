<template>
  <v-col>
    <div v-if="!disabled">{{ $t("virtualGrid.crossReferenceOptionsTitle") }}</div>
    <v-autocomplete
      :disabled="disabled"
      :items="items"
      v-model="autocompleteModel"
      :label="$t('virtualGrid.view')"
      item-text="name"
      item-value="uri"
      :loading="loading"
      @click="loadItems"
      data-testid="referenceSelect"
    >
      <template v-slot:item="data">
        <v-list-item-content :data-testid="`reference-option-${items.indexOf(data.item)}`">
          <v-list-item-title
            v-text="data.item.name"
          ></v-list-item-title>
          <v-list-item-subtitle v-text="data.item.group"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-col>
</template>


<script>
export default {
  props: {
    space: null,
    grid: null,
    value: null,
    disabled: null
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  mounted() {
    this.loadItems()
  },
  computed: {
    autocompleteModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    loadItems() {
      if(this.loading) {
        return
      }

      this.loading = true
      const newItems = []
      this.$store.dispatch('AGListSpaceVirtualGridsOperation', {space: this.space, excludedGrid: this.grid})
        .then((allVirtualGrids) => {
          allVirtualGrids.forEach((virtualGrid) => {
            const group = virtualGrid.parentGrid().name
            if (!newItems.length || newItems[newItems.length - 1].group !== group) {
              newItems.push({ divider: true })
              newItems.push({ header: group })
            }
            newItems.push({
              ...virtualGrid,
              group
            })
          })
          this.items = newItems
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
