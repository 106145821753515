<template>
  <v-container fluid grid-list-xl class="px-0">
    <!-- <FullHeightLoader v-if ="loading" v-model="loading"/> -->
    <div v-if="!loading && templates && templates.length > 0">
      <span class="ml-0">{{ $t('templates.title') }}</span>
      <v-row no-gutters class="mt-4">
        <v-col
          v-for="template in templates"
          :key="template.name"
          cols="12"
          sm="6"
          md="4"
          lg="2"
          xl="1"
        >
          <v-card
            class="ma-3 pa-3 d-flex flex-column"
            data-testid="useTemplateButton"
            @click="() => use(template)"
          >
            <div>
              <v-icon
                class="templateIcon"
                :style="`background-color: ${template.color}`"
                color="white"
                >mdi-{{ template.icon }}</v-icon
              >
            </div>
            <div class="mt-3 mb-2">
              <div class="hyphens no-overflow grey--text text--darken-1 bold">
                {{ template.title }}
              </div>
            </div>
            <EllipsisedParagraph
              class="hyphens grey--text text--darken-1 text-body-2"
              :text="template.description"
              :height="120"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import EllipsisedParagraph from '@/components/EllipsisedParagraph.vue'
// TODO show loader when templates are officially supported
// import FullHeightLoader from '@/components/FullHeightLoader.vue'

export default {
  data: () => ({
    templates: [],
    loading: false
  }),
  mounted() {
    this.loadTemplates()
  },
  methods: {
    loadTemplates() {
      this.loading = true
      this.$store
        .dispatch('loadTemplates', this._i18n.locale)
        .then(templates => (this.templates = templates))
        .finally(() => (this.loading = false))
    },
    use(template) {
      this.$emit('templateSelected', template)
    }
  },
  components: {
    // FullHeightLoader,
    EllipsisedParagraph
  }
}
</script>

<style lang="css" scoped>
.templateIcon {
  margin-top: 0px;
  width: 28.75px;
  height: 28.75px;
  border-radius: 20%;
}

.template-card {
  height: 120px;
  transition: height 0.4s;
}

.template-card:hover {
  min-height: 120px;
  height: unset;
}

.truncate-overflow {
  --lh: 1.4rem;
  --max-lines: 5;
  position: relative;
  line-height: var(--lh);
  height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.truncate-overflow::before {
  position: absolute;
  content: '...';
  inset-block-end: 0; /* "bottom" */
  inset-inline-end: 0; /* "right" */
}
.truncate-overflow::after {
  content: '';
  position: absolute;
  inset-inline-end: 0; /* "right" */
  width: 1rem;
  height: 1rem;
  background: white;
}
</style>
