<template>
  <span>
    <div
      v-if="showInfoWindowContent"
      ref="infoWindowContent"
    >
      <slot name="infoWindow" />
    </div>
  </span>
</template>

<script>
/* global google */
export default {
  props: [
    'mapPromise',
    'lat',
    'lng',
    'title',
    'icon',
    'onClickHandler',
    'selected',
    'label',
    'initialSize'
  ],
  data() {
    return {
      internalMarkerObject: undefined,
      initialZIndex: 0,
      infoWindow: undefined,
      infoWindowListener: undefined,
      showInfoWindowContent: false
    }
  },
  computed: {
    scaledIcon() {
      if (!this.icon) {
        return undefined
      }
      let scaledIcon = {}
      Object.assign(scaledIcon, this.icon)
      scaledIcon.scaledSize = new google.maps.Size(
        this.initialSize.width * 2,
        this.initialSize.height * 2
      )
      if (this.icon.anchor) {
        scaledIcon.anchor = new google.maps.Point(
          this.icon.anchor.x * 2,
          this.icon.anchor.y * 2
        )
      }
      if (this.icon.labelOrigin) {
        scaledIcon.labelOrigin = new google.maps.Point(
          this.icon.labelOrigin.x * 2,
          this.icon.labelOrigin.y * 2
        )
      }
      return scaledIcon
    },
    currentIcon() {
      return this.selected ? this.scaledIcon : this.icon
    },
    currentPosition() {
      if (!this.lat || !this.lng) {
        return undefined
      }
      return {
        lat: this.lat,
        lng: this.lng
      }
    }
  },
  mounted() {
    this.mapPromise.then(map => {
      this.internalMarkerObject = this.createMarker(map)
      this.infoWindow = new google.maps.InfoWindow()
      this.infoWindow.addListener('close', () => this.showInfoWindowContent = false)
      this.infoWindowListener = map.addListener('click', this.closeInfoWindow)

      this.initialZIndex = this.internalMarkerObject.getZIndex()
      google.maps.event.addListener(
        this.internalMarkerObject,
        'mousedown',
        async event => {
          if (this.onClickHandler) {
            this.onClickHandler(event)
          }
          this.showInfoWindowContent = true
          await this.$nextTick()
          this.infoWindow.setContent(this.$refs.infoWindowContent)
          this.infoWindow.open(map, this.internalMarkerObject)
        }
      )
    })
  },
  watch: {
    selected() {
      this.internalMarkerObject.setIcon(this.currentIcon)
      this.setZIndex()
    },
    currentPosition(newVal) {
      this.internalMarkerObject.setPosition(newVal)
    }
  },
  beforeDestroy() {
    if (this.internalMarkerObject) {
      this.internalMarkerObject.setMap(null)
    }
    this.infoWindowListener.remove()
  },
  methods: {
    svgToDataURL(svgStr) {
      const encoded = encodeURIComponent(svgStr)
        .replace(/'/g, '%27')
        .replace(/"/g, '%22')

      const header = 'data:image/svg+xml,'
      const dataUrl = header + encoded

      return dataUrl
    },
    createMarker(map) {
      let markerOptions = {
        position: this.currentPosition,
        map: map,
        title: this.title,
        icon: this.currentIcon,
        label: this.label
      }
      return new google.maps.Marker(markerOptions)
    },
    setZIndex() {
      if (this.selected) {
        this.initialZIndex = this.internalMarkerObject.getZIndex()
        this.internalMarkerObject.setZIndex(google.maps.Marker.MAX_ZINDEX)
      } else {
        this.internalMarkerObject.setZIndex(this.initialZIndex)
      }
    },
    closeInfoWindow() {
      this.showInfoWindowContent = false
      this.infoWindow?.close()
    }
  }
}
</script>