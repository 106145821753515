import Form from '@/store/models/Form.js'
import ApiClient from '@/ApiClient'
import Vue from 'vue'
import i18n from '@/plugins/i18n'

export default {
  state() {
    return {
      forms: {}
    }
  },
  mutations: {
    addForm(state, form) {
      Vue.set(state.forms, form.uri, form)
    },
    removeForm(state, form) {
      delete state.forms[form]
    }
  },
  actions: {
    AGListFormsOperation(context, gridUri) {
      return context.dispatch('loadFormUris', gridUri)
    },
    async AGListSpaceFormsOperation(context, spaceUri) {
      const space = context.getters.spaceWithUri(spaceUri)
      const grids = space._embedded.grids
      await Promise.all(grids.map(grid => context.dispatch('AGReadGridOperation', { uri: grid._links.self.href, cached: true })))
      return Promise.all(grids.map(grid => context.dispatch('AGListFormsOperation', grid._links.self.href)))
    },
    AGReadFormOperation(context, { formUri, cached = false }) {
      formUri = formUri ?? arguments[1]
      const cachedForm = context.getters.formWithUri(formUri)
      if (cached && cachedForm) {
        return Promise.resolve(cachedForm)
      }
      return ApiClient.getUri(formUri).then(response => {
        response.data['uri'] = formUri
        const form = new Form(response.data)
        context.commit('addForm', form)
        return form
      })
    },
    AGAddFormOperation(context, { gridUri }) {
      const formCount = context.getters.formsOfGrid(gridUri).length
      const grid = context.getters.gridWithUri(gridUri)
      const link = grid.getLink('addForm')
      const formCountLabel = formCount > 0 ? ` ${formCount + 1}` : ''
      const name = `${i18n.t('forms.defaultFormTitle')} ${grid.title()}${formCountLabel}`
      return ApiClient.postUri(link, {
        name: name,
      }).then(response => {
        const uri = response.headers['location']
        return context.dispatch('AGReadFormOperation', uri)
      })
    },
    async AGRemoveFormOperation(context, { formUri }) {
      const grid = context.rootGetters.gridContainingFormUri(formUri)
      const form = context.getters.formWithUri(formUri)
      const link = form.getLink('remove')
      const response = await ApiClient.deleteUri(link)
      context.commit('removeForm', formUri)
      await context.dispatch('loadFormUris', grid.uri)
      return response
    },
    AGRenameFormOperation(context, form) {
      const grid = context.rootGetters.gridContainingFormUri(form.uri)
      return context.dispatch('AGUpdateFormOperation', form)
        .then(() => {
          context.dispatch('loadFormUris', grid.uri)
        })
    },
    AGUpdateFormOperation(context, form) {
      const link = form.getLink('update')
      var payload = {
        headerImage: form.headerImage ?? undefined,
        title: form.title,
        description: form.description ?? undefined,
        name: form.name,
        components: form.components,
        properties: form.properties,
        fieldProperties: form.fieldProperties,
      }
      return ApiClient.putUri(link, payload)
    },
    AGSubmitFormOperation(context, {form, payload}) {
      const link = form.getLink('submit')
      return ApiClient.postUri(link, payload)
    },
    AGWriteAddFormEntityOperation(context, { name, formUri, gridUri }) {
      const form = context.getters.formWithUri(formUri)
      const id = form.id
      const grid = context.getters.gridWithUri(gridUri)
      const link = grid.getLink('addLink')
      return ApiClient.postUri(link, {
        name,
        formId: id 
      })
    }
  },
  getters: {
    formWithUri: state => uri => {
      return state.forms[uri]
    }
  }
}
