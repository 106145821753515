import axios from 'axios'

const AVATAR_SIGNEDURL_API_ENDPOINT = 'https://6csgir6rcj.execute-api.eu-central-1.amazonaws.com/uploads'
const AVATAR_API_ENDPOINT = 'https://apptiveavatarupload-apptiveavataruploadbucket-17hw58ak4gvs6.s3.eu-central-1.amazonaws.com/'

function getSignedUrl(userId) {
  // get signed url from aws lambda to upload file to s3
  return axios.get(AVATAR_SIGNEDURL_API_ENDPOINT, {
    params: { fileName: userId }
  }).then((response) => {
    return response.data['uploadURL']
  })
}

function genAvatarUrl(userId) {
  return `${AVATAR_API_ENDPOINT}${userId}.jpg?${performance.now()}`
}

// Unlike genAvatarUrl, this function returns the same url for each
// userId to ensure the image is being cached by the browser
function stableAvatarUrl(userId) {
  return `${AVATAR_API_ENDPOINT}${userId}.jpg`
}

export default {
  state() {
    return {
      avatarSrc: undefined
    }
  },
  mutations: {
    setAvatarSrc(state, src) {
      state.avatarSrc = src
    }
  },
  actions: {
    initAvatarUrl(context, userId) {
      const url = genAvatarUrl(userId)
      axios.head(url)
        .then(() => context.commit('setAvatarSrc', url))
        .catch(error => console.log(error))
    },
    async uploadAvatar(context, imageFile) {
      const signedUrl = await getSignedUrl(context.getters.userId)
      await axios.put(signedUrl, imageFile)
      context.commit('setAvatarSrc', genAvatarUrl(context.getters.userId))
    },
    loadAvatarOf(context, userId) {
      const url = genAvatarUrl(userId)
      return axios.get(url)
    }
  },
  getters: {
    avatarUrlOf: () => userId => stableAvatarUrl(userId)
  }
}