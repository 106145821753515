import ApiClient from '@/ApiClient'

export default {
  methods: {
    async loadResources() {
      this.loading = true
      try {
        const response = await ApiClient.getUri(this.field._links.resources.href)
        const allItems = response.data
        const itemsByType = {}
        for (let item of allItems) {
          if (itemsByType[item.metaType] != null) {
            itemsByType[item.metaType].push(item)
          } else {
            itemsByType[item.metaType] = [item]
          }
        }
        const itemsWithHeaders = []
        for (let metaType of Object.keys(itemsByType)) {
          itemsWithHeaders.push({header: this.$t(`resourceTypes.${metaType}`)})
          for (let item of itemsByType[metaType]) {
            itemsWithHeaders.push(item)
          }
        }
        this.items = itemsWithHeaders
        console.log(itemsWithHeaders)
      } finally {
        this.loading = false
      }
    }
  }
}