export default [
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Afghani',
    'code': 'AFN'
  },
  {
    'name': 'Lek',
    'code': 'ALL'
  },
  {
    'name': 'Algerian Dinar',
    'code': 'DZD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Kwanza',
    'code': 'AOA'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Argentine Peso',
    'code': 'ARS'
  },
  {
    'name': 'Armenian Dram',
    'code': 'AMD'
  },
  {
    'name': 'Aruban Florin',
    'code': 'AWG'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Azerbaijan Manat',
    'code': 'AZN'
  },
  {
    'name': 'Bahamian Dollar',
    'code': 'BSD'
  },
  {
    'name': 'Bahraini Dinar',
    'code': 'BHD'
  },
  {
    'name': 'Taka',
    'code': 'BDT'
  },
  {
    'name': 'Barbados Dollar',
    'code': 'BBD'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Belize Dollar',
    'code': 'BZD'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Bermudian Dollar',
    'code': 'BMD'
  },
  {
    'name': 'Indian Rupee',
    'code': 'INR'
  },
  {
    'name': 'Ngultrum',
    'code': 'BTN'
  },
  {
    'name': 'Boliviano',
    'code': 'BOB'
  },
  {
    'name': 'Mvdol',
    'code': 'BOV'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Convertible Mark',
    'code': 'BAM'
  },
  {
    'name': 'Pula',
    'code': 'BWP'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Brazilian Real',
    'code': 'BRL'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Brunei Dollar',
    'code': 'BND'
  },
  {
    'name': 'Bulgarian Lev',
    'code': 'BGN'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Burundi Franc',
    'code': 'BIF'
  },
  {
    'name': 'Cabo Verde Escudo',
    'code': 'CVE'
  },
  {
    'name': 'Riel',
    'code': 'KHR'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Canadian Dollar',
    'code': 'CAD'
  },
  {
    'name': 'Cayman Islands Dollar',
    'code': 'KYD'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Chilean Peso',
    'code': 'CLP'
  },
  {
    'name': 'Unidad de Fomento',
    'code': 'CLF'
  },
  {
    'name': 'Yuan Renminbi',
    'code': 'CNY'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Colombian Peso',
    'code': 'COP'
  },
  {
    'name': 'Unidad de Valor Real',
    'code': 'COU'
  },
  {
    'name': 'Comorian Franc',
    'code': 'KMF'
  },
  {
    'name': 'Congolese Franc',
    'code': 'CDF'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Costa Rican Colon',
    'code': 'CRC'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Kuna',
    'code': 'HRK'
  },
  {
    'name': 'Cuban Peso',
    'code': 'CUP'
  },
  {
    'name': 'Peso Convertible',
    'code': 'CUC'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Czech Koruna',
    'code': 'CZK'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'Djibouti Franc',
    'code': 'DJF'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Dominican Peso',
    'code': 'DOP'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Egyptian Pound',
    'code': 'EGP'
  },
  {
    'name': 'El Salvador Colon',
    'code': 'SVC'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Nakfa',
    'code': 'ERN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lilangeni',
    'code': 'SZL'
  },
  {
    'name': 'Ethiopian Birr',
    'code': 'ETB'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Falkland Islands Pound',
    'code': 'FKP'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'Fiji Dollar',
    'code': 'FJD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Dalasi',
    'code': 'GMD'
  },
  {
    'name': 'Lari',
    'code': 'GEL'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Ghana Cedi',
    'code': 'GHS'
  },
  {
    'name': 'Gibraltar Pound',
    'code': 'GIP'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Quetzal',
    'code': 'GTQ'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'Guinean Franc',
    'code': 'GNF'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Guyana Dollar',
    'code': 'GYD'
  },
  {
    'name': 'Gourde',
    'code': 'HTG'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lempira',
    'code': 'HNL'
  },
  {
    'name': 'Hong Kong Dollar',
    'code': 'HKD'
  },
  {
    'name': 'Forint',
    'code': 'HUF'
  },
  {
    'name': 'Iceland Krona',
    'code': 'ISK'
  },
  {
    'name': 'Indian Rupee',
    'code': 'INR'
  },
  {
    'name': 'Rupiah',
    'code': 'IDR'
  },
  {
    'name': 'SDR (Special Drawing Right)',
    'code': 'XDR'
  },
  {
    'name': 'Iranian Rial',
    'code': 'IRR'
  },
  {
    'name': 'Iraqi Dinar',
    'code': 'IQD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'New Israeli Sheqel',
    'code': 'ILS'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Jamaican Dollar',
    'code': 'JMD'
  },
  {
    'name': 'Yen',
    'code': 'JPY'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'Jordanian Dinar',
    'code': 'JOD'
  },
  {
    'name': 'Tenge',
    'code': 'KZT'
  },
  {
    'name': 'Kenyan Shilling',
    'code': 'KES'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'North Korean Won',
    'code': 'KPW'
  },
  {
    'name': 'Won',
    'code': 'KRW'
  },
  {
    'name': 'Kuwaiti Dinar',
    'code': 'KWD'
  },
  {
    'name': 'Som',
    'code': 'KGS'
  },
  {
    'name': 'Lao Kip',
    'code': 'LAK'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lebanese Pound',
    'code': 'LBP'
  },
  {
    'name': 'Loti',
    'code': 'LSL'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'Liberian Dollar',
    'code': 'LRD'
  },
  {
    'name': 'Libyan Dinar',
    'code': 'LYD'
  },
  {
    'name': 'Swiss Franc',
    'code': 'CHF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Pataca',
    'code': 'MOP'
  },
  {
    'name': 'Denar',
    'code': 'MKD'
  },
  {
    'name': 'Malagasy Ariary',
    'code': 'MGA'
  },
  {
    'name': 'Malawi Kwacha',
    'code': 'MWK'
  },
  {
    'name': 'Malaysian Ringgit',
    'code': 'MYR'
  },
  {
    'name': 'Rufiyaa',
    'code': 'MVR'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Ouguiya',
    'code': 'MRU'
  },
  {
    'name': 'Mauritius Rupee',
    'code': 'MUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'ADB Unit of Account',
    'code': 'XUA'
  },
  {
    'name': 'Mexican Peso',
    'code': 'MXN'
  },
  {
    'name': 'Mexican Unidad de Inversion (UDI)',
    'code': 'MXV'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Moldovan Leu',
    'code': 'MDL'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Tugrik',
    'code': 'MNT'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Moroccan Dirham',
    'code': 'MAD'
  },
  {
    'name': 'Mozambique Metical',
    'code': 'MZN'
  },
  {
    'name': 'Kyat',
    'code': 'MMK'
  },
  {
    'name': 'Namibia Dollar',
    'code': 'NAD'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Nepalese Rupee',
    'code': 'NPR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Cordoba Oro',
    'code': 'NIO'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Naira',
    'code': 'NGN'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Rial Omani',
    'code': 'OMR'
  },
  {
    'name': 'Pakistan Rupee',
    'code': 'PKR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Balboa',
    'code': 'PAB'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Kina',
    'code': 'PGK'
  },
  {
    'name': 'Guarani',
    'code': 'PYG'
  },
  {
    'name': 'Sol',
    'code': 'PEN'
  },
  {
    'name': 'Philippine Peso',
    'code': 'PHP'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Zloty',
    'code': 'PLN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Qatari Rial',
    'code': 'QAR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Romanian Leu',
    'code': 'RON'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUB'
  },
  {
    'name': 'Rwanda Franc',
    'code': 'RWF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Saint Helena Pound',
    'code': 'SHP'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Tala',
    'code': 'WST'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Dobra',
    'code': 'STN'
  },
  {
    'name': 'Saudi Riyal',
    'code': 'SAR'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Serbian Dinar',
    'code': 'RSD'
  },
  {
    'name': 'Seychelles Rupee',
    'code': 'SCR'
  },
  {
    'name': 'Leone',
    'code': 'SLL'
  },
  {
    'name': 'Singapore Dollar',
    'code': 'SGD'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Sucre',
    'code': 'XSU'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Solomon Islands Dollar',
    'code': 'SBD'
  },
  {
    'name': 'Somali Shilling',
    'code': 'SOS'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'South Sudanese Pound',
    'code': 'SSP'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Sri Lanka Rupee',
    'code': 'LKR'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDG'
  },
  {
    'name': 'Surinam Dollar',
    'code': 'SRD'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Swedish Krona',
    'code': 'SEK'
  },
  {
    'name': 'Swiss Franc',
    'code': 'CHF'
  },
  {
    'name': 'WIR Euro',
    'code': 'CHE'
  },
  {
    'name': 'WIR Franc',
    'code': 'CHW'
  },
  {
    'name': 'Syrian Pound',
    'code': 'SYP'
  },
  {
    'name': 'New Taiwan Dollar',
    'code': 'TWD'
  },
  {
    'name': 'Somoni',
    'code': 'TJS'
  },
  {
    'name': 'Tanzanian Shilling',
    'code': 'TZS'
  },
  {
    'name': 'Baht',
    'code': 'THB'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Pa\'anga',
    'code': 'TOP'
  },
  {
    'name': 'Trinidad and Tobago Dollar',
    'code': 'TTD'
  },
  {
    'name': 'Tunisian Dinar',
    'code': 'TND'
  },
  {
    'name': 'Turkish Lira',
    'code': 'TRY'
  },
  {
    'name': 'Turkmenistan New Manat',
    'code': 'TMT'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Uganda Shilling',
    'code': 'UGX'
  },
  {
    'name': 'Hryvnia',
    'code': 'UAH'
  },
  {
    'name': 'UAE Dirham',
    'code': 'AED'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar (Next day)',
    'code': 'USN'
  },
  {
    'name': 'Peso Uruguayo',
    'code': 'UYU'
  },
  {
    'name': 'Uruguay Peso en Unidades Indexadas (UI)',
    'code': 'UYI'
  },
  {
    'name': 'Unidad Previsional',
    'code': 'UYW'
  },
  {
    'name': 'Uzbekistan Sum',
    'code': 'UZS'
  },
  {
    'name': 'Vatu',
    'code': 'VUV'
  },
  {
    'name': 'Bolívar Soberano',
    'code': 'VES'
  },
  {
    'name': 'Dong',
    'code': 'VND'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'Moroccan Dirham',
    'code': 'MAD'
  },
  {
    'name': 'Yemeni Rial',
    'code': 'YER'
  },
  {
    'name': 'Zambian Kwacha',
    'code': 'ZMW'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWL'
  },
  {
    'name': 'Bond Markets Unit European Composite Unit (EURCO)',
    'code': 'XBA'
  },
  {
    'name': 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
    'code': 'XBB'
  },
  {
    'name': 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
    'code': 'XBC'
  },
  {
    'name': 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
    'code': 'XBD'
  },
  {
    'name': 'Codes specifically reserved for testing purposes',
    'code': 'XTS'
  },
  {
    'name': 'The codes assigned for transactions where no currency is involved',
    'code': 'XXX'
  },
  {
    'name': 'Gold',
    'code': 'XAU'
  },
  {
    'name': 'Palladium',
    'code': 'XPD'
  },
  {
    'name': 'Platinum',
    'code': 'XPT'
  },
  {
    'name': 'Silver',
    'code': 'XAG'
  },
  {
    'name': 'Afghani',
    'code': 'AFA'
  },
  {
    'name': 'Markka',
    'code': 'FIM'
  },
  {
    'name': 'Old Lek',
    'code': 'ALK'
  },
  {
    'name': 'Andorran Peseta',
    'code': 'ADP'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESP'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Kwanza',
    'code': 'AOK'
  },
  {
    'name': 'New Kwanza',
    'code': 'AON'
  },
  {
    'name': 'Kwanza Reajustado',
    'code': 'AOR'
  },
  {
    'name': 'Austral',
    'code': 'ARA'
  },
  {
    'name': 'Peso Argentino',
    'code': 'ARP'
  },
  {
    'name': 'Peso',
    'code': 'ARY'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Schilling',
    'code': 'ATS'
  },
  {
    'name': 'Azerbaijan Manat',
    'code': 'AYM'
  },
  {
    'name': 'Azerbaijanian Manat',
    'code': 'AZM'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYB'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYR'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Convertible Franc',
    'code': 'BEC'
  },
  {
    'name': 'Belgian Franc',
    'code': 'BEF'
  },
  {
    'name': 'Financial Franc',
    'code': 'BEL'
  },
  {
    'name': 'Peso boliviano',
    'code': 'BOP'
  },
  {
    'name': 'Dinar',
    'code': 'BAD'
  },
  {
    'name': 'Cruzeiro',
    'code': 'BRB'
  },
  {
    'name': 'Cruzado',
    'code': 'BRC'
  },
  {
    'name': 'Cruzeiro',
    'code': 'BRE'
  },
  {
    'name': 'New Cruzado',
    'code': 'BRN'
  },
  {
    'name': 'Cruzeiro Real',
    'code': 'BRR'
  },
  {
    'name': 'Lev A/52',
    'code': 'BGJ'
  },
  {
    'name': 'Lev A/62',
    'code': 'BGK'
  },
  {
    'name': 'Lev',
    'code': 'BGL'
  },
  {
    'name': 'Kyat',
    'code': 'BUK'
  },
  {
    'name': 'Croatian Dinar',
    'code': 'HRD'
  },
  {
    'name': 'Croatian Kuna',
    'code': 'HRK'
  },
  {
    'name': 'Cyprus Pound',
    'code': 'CYP'
  },
  {
    'name': 'Krona A/53',
    'code': 'CSJ'
  },
  {
    'name': 'Koruna',
    'code': 'CSK'
  },
  {
    'name': 'Sucre',
    'code': 'ECS'
  },
  {
    'name': 'Unidad de Valor Constante (UVC)',
    'code': 'ECV'
  },
  {
    'name': 'Ekwele',
    'code': 'GQE'
  },
  {
    'name': 'Kroon',
    'code': 'EEK'
  },
  {
    'name': 'European Currency Unit (E.C.U)',
    'code': 'XEU'
  },
  {
    'name': 'Markka',
    'code': 'FIM'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Georgian Coupon',
    'code': 'GEK'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Mark der DDR',
    'code': 'DDM'
  },
  {
    'name': 'Deutsche Mark',
    'code': 'DEM'
  },
  {
    'name': 'Cedi',
    'code': 'GHC'
  },
  {
    'name': 'Ghana Cedi',
    'code': 'GHP'
  },
  {
    'name': 'Drachma',
    'code': 'GRD'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Syli',
    'code': 'GNE'
  },
  {
    'name': 'Syli',
    'code': 'GNS'
  },
  {
    'name': 'Guinea Escudo',
    'code': 'GWE'
  },
  {
    'name': 'Guinea-Bissau Peso',
    'code': 'GWP'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Old Krona',
    'code': 'ISJ'
  },
  {
    'name': 'Irish Pound',
    'code': 'IEP'
  },
  {
    'name': 'Pound',
    'code': 'ILP'
  },
  {
    'name': 'Old Shekel',
    'code': 'ILR'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Pathet Lao Kip',
    'code': 'LAJ'
  },
  {
    'name': 'Latvian Lats',
    'code': 'LVL'
  },
  {
    'name': 'Latvian Ruble',
    'code': 'LVR'
  },
  {
    'name': 'Loti',
    'code': 'LSM'
  },
  {
    'name': 'Financial Rand',
    'code': 'ZAL'
  },
  {
    'name': 'Lithuanian Litas',
    'code': 'LTL'
  },
  {
    'name': 'Talonas',
    'code': 'LTT'
  },
  {
    'name': 'Luxembourg Convertible Franc',
    'code': 'LUC'
  },
  {
    'name': 'Luxembourg Franc',
    'code': 'LUF'
  },
  {
    'name': 'Luxembourg Financial Franc',
    'code': 'LUL'
  },
  {
    'name': 'Malagasy Franc',
    'code': 'MGF'
  },
  {
    'name': 'Kwacha',
    'code': 'MWK'
  },
  {
    'name': 'Maldive Rupee',
    'code': 'MVQ'
  },
  {
    'name': 'Mali Franc',
    'code': 'MLF'
  },
  {
    'name': 'Maltese Lira',
    'code': 'MTL'
  },
  {
    'name': 'Maltese Pound',
    'code': 'MTP'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Ouguiya',
    'code': 'MRO'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Mexican Peso',
    'code': 'MXP'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Mozambique Escudo',
    'code': 'MZE'
  },
  {
    'name': 'Mozambique Metical',
    'code': 'MZM'
  },
  {
    'name': 'Netherlands Guilder',
    'code': 'NLG'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Cordoba',
    'code': 'NIC'
  },
  {
    'name': 'Sol',
    'code': 'PEH'
  },
  {
    'name': 'Inti',
    'code': 'PEI'
  },
  {
    'name': 'Nuevo Sol',
    'code': 'PEN'
  },
  {
    'name': 'Sol',
    'code': 'PES'
  },
  {
    'name': 'Zloty',
    'code': 'PLZ'
  },
  {
    'name': 'Portuguese Escudo',
    'code': 'PTE'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Leu A/52',
    'code': 'ROK'
  },
  {
    'name': 'Old Leu',
    'code': 'ROL'
  },
  {
    'name': 'New Romanian Leu',
    'code': 'RON'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Dobra',
    'code': 'STD'
  },
  {
    'name': 'Serbian Dinar',
    'code': 'CSD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Slovak Koruna',
    'code': 'SKK'
  },
  {
    'name': 'Tolar',
    'code': 'SIT'
  },
  {
    'name': 'Financial Rand',
    'code': 'ZAL'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDG'
  },
  {
    'name': 'Rhodesian Dollar',
    'code': 'RHD'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESA'
  },
  {
    'name': '\'A\' Account (convertible Peseta Account)',
    'code': 'ESB'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESP'
  },
  {
    'name': 'Sudanese Dinar',
    'code': 'SDD'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDP'
  },
  {
    'name': 'Surinam Guilder',
    'code': 'SRG'
  },
  {
    'name': 'Lilangeni',
    'code': 'SZL'
  },
  {
    'name': 'WIR Franc (for electronic)',
    'code': 'CHC'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Tajik Ruble',
    'code': 'TJR'
  },
  {
    'name': 'Rupiah',
    'code': 'IDR'
  },
  {
    'name': 'Timor Escudo',
    'code': 'TPE'
  },
  {
    'name': 'Old Turkish Lira',
    'code': 'TRL'
  },
  {
    'name': 'New Turkish Lira',
    'code': 'TRY'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Turkmenistan Manat',
    'code': 'TMM'
  },
  {
    'name': 'Uganda Shilling',
    'code': 'UGS'
  },
  {
    'name': 'Old Shilling',
    'code': 'UGW'
  },
  {
    'name': 'Karbovanet',
    'code': 'UAK'
  },
  {
    'name': 'Rouble',
    'code': 'SUR'
  },
  {
    'name': 'US Dollar (Same day)',
    'code': 'USS'
  },
  {
    'name': 'Old Uruguay Peso',
    'code': 'UYN'
  },
  {
    'name': 'Uruguayan Peso',
    'code': 'UYP'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Bolivar',
    'code': 'VEB'
  },
  {
    'name': 'Bolivar Fuerte',
    'code': 'VEF'
  },
  {
    'name': 'Bolivar',
    'code': 'VEF'
  },
  {
    'name': 'Bolívar',
    'code': 'VEF'
  },
  {
    'name': 'Old Dong',
    'code': 'VNC'
  },
  {
    'name': 'Yemeni Dinar',
    'code': 'YDD'
  },
  {
    'name': 'New Yugoslavian Dinar',
    'code': 'YUD'
  },
  {
    'name': 'New Dinar',
    'code': 'YUM'
  },
  {
    'name': 'Yugoslavian Dinar',
    'code': 'YUN'
  },
  {
    'name': 'New Zaire',
    'code': 'ZRN'
  },
  {
    'name': 'Zaire',
    'code': 'ZRZ'
  },
  {
    'name': 'Zambian Kwacha',
    'code': 'ZMK'
  },
  {
    'name': 'Rhodesian Dollar',
    'code': 'ZWC'
  },
  {
    'name': 'Zimbabwe Dollar (old)',
    'code': 'ZWD'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWD'
  },
  {
    'name': 'Zimbabwe Dollar (new)',
    'code': 'ZWN'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWR'
  },
  {
    'name': 'Gold-Franc',
    'code': 'XFO'
  },
  {
    'name': 'RINET Funds Code',
    'code': 'XRE'
  },
  {
    'name': 'UIC-Franc',
    'code': 'XFU'
  },
  {
    'name': 'Currency',
    'code': 'AlphabeticCode'
  },
  {
    'name': 'Afghani',
    'code': 'AFN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lek',
    'code': 'ALL'
  },
  {
    'name': 'Algerian Dinar',
    'code': 'DZD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Kwanza',
    'code': 'AOA'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Argentine Peso',
    'code': 'ARS'
  },
  {
    'name': 'Armenian Dram',
    'code': 'AMD'
  },
  {
    'name': 'Aruban Florin',
    'code': 'AWG'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Azerbaijan Manat',
    'code': 'AZN'
  },
  {
    'name': 'Bahamian Dollar',
    'code': 'BSD'
  },
  {
    'name': 'Bahraini Dinar',
    'code': 'BHD'
  },
  {
    'name': 'Taka',
    'code': 'BDT'
  },
  {
    'name': 'Barbados Dollar',
    'code': 'BBD'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Belize Dollar',
    'code': 'BZD'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Bermudian Dollar',
    'code': 'BMD'
  },
  {
    'name': 'Indian Rupee',
    'code': 'INR'
  },
  {
    'name': 'Ngultrum',
    'code': 'BTN'
  },
  {
    'name': 'Boliviano',
    'code': 'BOB'
  },
  {
    'name': 'Mvdol',
    'code': 'BOV'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Convertible Mark',
    'code': 'BAM'
  },
  {
    'name': 'Pula',
    'code': 'BWP'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Brazilian Real',
    'code': 'BRL'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Brunei Dollar',
    'code': 'BND'
  },
  {
    'name': 'Bulgarian Lev',
    'code': 'BGN'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Burundi Franc',
    'code': 'BIF'
  },
  {
    'name': 'Cabo Verde Escudo',
    'code': 'CVE'
  },
  {
    'name': 'Riel',
    'code': 'KHR'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Canadian Dollar',
    'code': 'CAD'
  },
  {
    'name': 'Cayman Islands Dollar',
    'code': 'KYD'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Chilean Peso',
    'code': 'CLP'
  },
  {
    'name': 'Unidad de Fomento',
    'code': 'CLF'
  },
  {
    'name': 'Yuan Renminbi',
    'code': 'CNY'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Colombian Peso',
    'code': 'COP'
  },
  {
    'name': 'Unidad de Valor Real',
    'code': 'COU'
  },
  {
    'name': 'Comorian Franc',
    'code': 'KMF'
  },
  {
    'name': 'Congolese Franc',
    'code': 'CDF'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Costa Rican Colon',
    'code': 'CRC'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Kuna',
    'code': 'HRK'
  },
  {
    'name': 'Cuban Peso',
    'code': 'CUP'
  },
  {
    'name': 'Peso Convertible',
    'code': 'CUC'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Czech Koruna',
    'code': 'CZK'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'Djibouti Franc',
    'code': 'DJF'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Dominican Peso',
    'code': 'DOP'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Egyptian Pound',
    'code': 'EGP'
  },
  {
    'name': 'El Salvador Colon',
    'code': 'SVC'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Nakfa',
    'code': 'ERN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lilangeni',
    'code': 'SZL'
  },
  {
    'name': 'Ethiopian Birr',
    'code': 'ETB'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Falkland Islands Pound',
    'code': 'FKP'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'Fiji Dollar',
    'code': 'FJD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Dalasi',
    'code': 'GMD'
  },
  {
    'name': 'Lari',
    'code': 'GEL'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Ghana Cedi',
    'code': 'GHS'
  },
  {
    'name': 'Gibraltar Pound',
    'code': 'GIP'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Quetzal',
    'code': 'GTQ'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'Guinean Franc',
    'code': 'GNF'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Guyana Dollar',
    'code': 'GYD'
  },
  {
    'name': 'Gourde',
    'code': 'HTG'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lempira',
    'code': 'HNL'
  },
  {
    'name': 'Hong Kong Dollar',
    'code': 'HKD'
  },
  {
    'name': 'Forint',
    'code': 'HUF'
  },
  {
    'name': 'Iceland Krona',
    'code': 'ISK'
  },
  {
    'name': 'Indian Rupee',
    'code': 'INR'
  },
  {
    'name': 'Rupiah',
    'code': 'IDR'
  },
  {
    'name': 'SDR (Special Drawing Right)',
    'code': 'XDR'
  },
  {
    'name': 'Iranian Rial',
    'code': 'IRR'
  },
  {
    'name': 'Iraqi Dinar',
    'code': 'IQD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'New Israeli Sheqel',
    'code': 'ILS'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Jamaican Dollar',
    'code': 'JMD'
  },
  {
    'name': 'Yen',
    'code': 'JPY'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'Jordanian Dinar',
    'code': 'JOD'
  },
  {
    'name': 'Tenge',
    'code': 'KZT'
  },
  {
    'name': 'Kenyan Shilling',
    'code': 'KES'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'North Korean Won',
    'code': 'KPW'
  },
  {
    'name': 'Won',
    'code': 'KRW'
  },
  {
    'name': 'Kuwaiti Dinar',
    'code': 'KWD'
  },
  {
    'name': 'Som',
    'code': 'KGS'
  },
  {
    'name': 'Lao Kip',
    'code': 'LAK'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lebanese Pound',
    'code': 'LBP'
  },
  {
    'name': 'Loti',
    'code': 'LSL'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'Liberian Dollar',
    'code': 'LRD'
  },
  {
    'name': 'Libyan Dinar',
    'code': 'LYD'
  },
  {
    'name': 'Swiss Franc',
    'code': 'CHF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Pataca',
    'code': 'MOP'
  },
  {
    'name': 'Denar',
    'code': 'MKD'
  },
  {
    'name': 'Malagasy Ariary',
    'code': 'MGA'
  },
  {
    'name': 'Malawi Kwacha',
    'code': 'MWK'
  },
  {
    'name': 'Malaysian Ringgit',
    'code': 'MYR'
  },
  {
    'name': 'Rufiyaa',
    'code': 'MVR'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Ouguiya',
    'code': 'MRU'
  },
  {
    'name': 'Mauritius Rupee',
    'code': 'MUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'ADB Unit of Account',
    'code': 'XUA'
  },
  {
    'name': 'Mexican Peso',
    'code': 'MXN'
  },
  {
    'name': 'Mexican Unidad de Inversion (UDI)',
    'code': 'MXV'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Moldovan Leu',
    'code': 'MDL'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Tugrik',
    'code': 'MNT'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Moroccan Dirham',
    'code': 'MAD'
  },
  {
    'name': 'Mozambique Metical',
    'code': 'MZN'
  },
  {
    'name': 'Kyat',
    'code': 'MMK'
  },
  {
    'name': 'Namibia Dollar',
    'code': 'NAD'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Nepalese Rupee',
    'code': 'NPR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Cordoba Oro',
    'code': 'NIO'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Naira',
    'code': 'NGN'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Rial Omani',
    'code': 'OMR'
  },
  {
    'name': 'Pakistan Rupee',
    'code': 'PKR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Balboa',
    'code': 'PAB'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Kina',
    'code': 'PGK'
  },
  {
    'name': 'Guarani',
    'code': 'PYG'
  },
  {
    'name': 'Sol',
    'code': 'PEN'
  },
  {
    'name': 'Philippine Peso',
    'code': 'PHP'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Zloty',
    'code': 'PLN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Qatari Rial',
    'code': 'QAR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Romanian Leu',
    'code': 'RON'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUB'
  },
  {
    'name': 'Rwanda Franc',
    'code': 'RWF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Saint Helena Pound',
    'code': 'SHP'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Tala',
    'code': 'WST'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Dobra',
    'code': 'STN'
  },
  {
    'name': 'Saudi Riyal',
    'code': 'SAR'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Serbian Dinar',
    'code': 'RSD'
  },
  {
    'name': 'Seychelles Rupee',
    'code': 'SCR'
  },
  {
    'name': 'Leone',
    'code': 'SLL'
  },
  {
    'name': 'Singapore Dollar',
    'code': 'SGD'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Sucre',
    'code': 'XSU'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Solomon Islands Dollar',
    'code': 'SBD'
  },
  {
    'name': 'Somali Shilling',
    'code': 'SOS'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'South Sudanese Pound',
    'code': 'SSP'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Sri Lanka Rupee',
    'code': 'LKR'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDG'
  },
  {
    'name': 'Surinam Dollar',
    'code': 'SRD'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Swedish Krona',
    'code': 'SEK'
  },
  {
    'name': 'Swiss Franc',
    'code': 'CHF'
  },
  {
    'name': 'WIR Euro',
    'code': 'CHE'
  },
  {
    'name': 'WIR Franc',
    'code': 'CHW'
  },
  {
    'name': 'Syrian Pound',
    'code': 'SYP'
  },
  {
    'name': 'New Taiwan Dollar',
    'code': 'TWD'
  },
  {
    'name': 'Somoni',
    'code': 'TJS'
  },
  {
    'name': 'Tanzanian Shilling',
    'code': 'TZS'
  },
  {
    'name': 'Baht',
    'code': 'THB'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Pa\'anga',
    'code': 'TOP'
  },
  {
    'name': 'Trinidad and Tobago Dollar',
    'code': 'TTD'
  },
  {
    'name': 'Tunisian Dinar',
    'code': 'TND'
  },
  {
    'name': 'Turkish Lira',
    'code': 'TRY'
  },
  {
    'name': 'Turkmenistan New Manat',
    'code': 'TMT'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Uganda Shilling',
    'code': 'UGX'
  },
  {
    'name': 'Hryvnia',
    'code': 'UAH'
  },
  {
    'name': 'UAE Dirham',
    'code': 'AED'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar (Next day)',
    'code': 'USN'
  },
  {
    'name': 'Peso Uruguayo',
    'code': 'UYU'
  },
  {
    'name': 'Uruguay Peso en Unidades Indexadas (UI)',
    'code': 'UYI'
  },
  {
    'name': 'Unidad Previsional',
    'code': 'UYW'
  },
  {
    'name': 'Uzbekistan Sum',
    'code': 'UZS'
  },
  {
    'name': 'Vatu',
    'code': 'VUV'
  },
  {
    'name': 'Bolívar Soberano',
    'code': 'VES'
  },
  {
    'name': 'Dong',
    'code': 'VND'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'Moroccan Dirham',
    'code': 'MAD'
  },
  {
    'name': 'Yemeni Rial',
    'code': 'YER'
  },
  {
    'name': 'Zambian Kwacha',
    'code': 'ZMW'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWL'
  },
  {
    'name': 'Bond Markets Unit European Composite Unit (EURCO)',
    'code': 'XBA'
  },
  {
    'name': 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
    'code': 'XBB'
  },
  {
    'name': 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
    'code': 'XBC'
  },
  {
    'name': 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
    'code': 'XBD'
  },
  {
    'name': 'Codes specifically reserved for testing purposes',
    'code': 'XTS'
  },
  {
    'name': 'The codes assigned for transactions where no currency is involved',
    'code': 'XXX'
  },
  {
    'name': 'Gold',
    'code': 'XAU'
  },
  {
    'name': 'Palladium',
    'code': 'XPD'
  },
  {
    'name': 'Platinum',
    'code': 'XPT'
  },
  {
    'name': 'Silver',
    'code': 'XAG'
  },
  {
    'name': 'Afghani',
    'code': 'AFA'
  },
  {
    'name': 'Markka',
    'code': 'FIM'
  },
  {
    'name': 'Old Lek',
    'code': 'ALK'
  },
  {
    'name': 'Andorran Peseta',
    'code': 'ADP'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESP'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Kwanza',
    'code': 'AOK'
  },
  {
    'name': 'New Kwanza',
    'code': 'AON'
  },
  {
    'name': 'Kwanza Reajustado',
    'code': 'AOR'
  },
  {
    'name': 'Austral',
    'code': 'ARA'
  },
  {
    'name': 'Peso Argentino',
    'code': 'ARP'
  },
  {
    'name': 'Peso',
    'code': 'ARY'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Schilling',
    'code': 'ATS'
  },
  {
    'name': 'Azerbaijan Manat',
    'code': 'AYM'
  },
  {
    'name': 'Azerbaijanian Manat',
    'code': 'AZM'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYB'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYR'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Convertible Franc',
    'code': 'BEC'
  },
  {
    'name': 'Belgian Franc',
    'code': 'BEF'
  },
  {
    'name': 'Financial Franc',
    'code': 'BEL'
  },
  {
    'name': 'Peso boliviano',
    'code': 'BOP'
  },
  {
    'name': 'Dinar',
    'code': 'BAD'
  },
  {
    'name': 'Cruzeiro',
    'code': 'BRB'
  },
  {
    'name': 'Cruzado',
    'code': 'BRC'
  },
  {
    'name': 'Cruzeiro',
    'code': 'BRE'
  },
  {
    'name': 'New Cruzado',
    'code': 'BRN'
  },
  {
    'name': 'Cruzeiro Real',
    'code': 'BRR'
  },
  {
    'name': 'Lev A/52',
    'code': 'BGJ'
  },
  {
    'name': 'Lev A/62',
    'code': 'BGK'
  },
  {
    'name': 'Lev',
    'code': 'BGL'
  },
  {
    'name': 'Kyat',
    'code': 'BUK'
  },
  {
    'name': 'Croatian Dinar',
    'code': 'HRD'
  },
  {
    'name': 'Croatian Kuna',
    'code': 'HRK'
  },
  {
    'name': 'Cyprus Pound',
    'code': 'CYP'
  },
  {
    'name': 'Krona A/53',
    'code': 'CSJ'
  },
  {
    'name': 'Koruna',
    'code': 'CSK'
  },
  {
    'name': 'Sucre',
    'code': 'ECS'
  },
  {
    'name': 'Unidad de Valor Constante (UVC)',
    'code': 'ECV'
  },
  {
    'name': 'Ekwele',
    'code': 'GQE'
  },
  {
    'name': 'Kroon',
    'code': 'EEK'
  },
  {
    'name': 'European Currency Unit (E.C.U)',
    'code': 'XEU'
  },
  {
    'name': 'Markka',
    'code': 'FIM'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Georgian Coupon',
    'code': 'GEK'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Mark der DDR',
    'code': 'DDM'
  },
  {
    'name': 'Deutsche Mark',
    'code': 'DEM'
  },
  {
    'name': 'Cedi',
    'code': 'GHC'
  },
  {
    'name': 'Ghana Cedi',
    'code': 'GHP'
  },
  {
    'name': 'Drachma',
    'code': 'GRD'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Syli',
    'code': 'GNE'
  },
  {
    'name': 'Syli',
    'code': 'GNS'
  },
  {
    'name': 'Guinea Escudo',
    'code': 'GWE'
  },
  {
    'name': 'Guinea-Bissau Peso',
    'code': 'GWP'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Old Krona',
    'code': 'ISJ'
  },
  {
    'name': 'Irish Pound',
    'code': 'IEP'
  },
  {
    'name': 'Pound',
    'code': 'ILP'
  },
  {
    'name': 'Old Shekel',
    'code': 'ILR'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Pathet Lao Kip',
    'code': 'LAJ'
  },
  {
    'name': 'Latvian Lats',
    'code': 'LVL'
  },
  {
    'name': 'Latvian Ruble',
    'code': 'LVR'
  },
  {
    'name': 'Loti',
    'code': 'LSM'
  },
  {
    'name': 'Financial Rand',
    'code': 'ZAL'
  },
  {
    'name': 'Lithuanian Litas',
    'code': 'LTL'
  },
  {
    'name': 'Talonas',
    'code': 'LTT'
  },
  {
    'name': 'Luxembourg Convertible Franc',
    'code': 'LUC'
  },
  {
    'name': 'Luxembourg Franc',
    'code': 'LUF'
  },
  {
    'name': 'Luxembourg Financial Franc',
    'code': 'LUL'
  },
  {
    'name': 'Malagasy Franc',
    'code': 'MGF'
  },
  {
    'name': 'Kwacha',
    'code': 'MWK'
  },
  {
    'name': 'Maldive Rupee',
    'code': 'MVQ'
  },
  {
    'name': 'Mali Franc',
    'code': 'MLF'
  },
  {
    'name': 'Maltese Lira',
    'code': 'MTL'
  },
  {
    'name': 'Maltese Pound',
    'code': 'MTP'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Ouguiya',
    'code': 'MRO'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Mexican Peso',
    'code': 'MXP'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Mozambique Escudo',
    'code': 'MZE'
  },
  {
    'name': 'Mozambique Metical',
    'code': 'MZM'
  },
  {
    'name': 'Netherlands Guilder',
    'code': 'NLG'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Cordoba',
    'code': 'NIC'
  },
  {
    'name': 'Sol',
    'code': 'PEH'
  },
  {
    'name': 'Inti',
    'code': 'PEI'
  },
  {
    'name': 'Nuevo Sol',
    'code': 'PEN'
  },
  {
    'name': 'Sol',
    'code': 'PES'
  },
  {
    'name': 'Zloty',
    'code': 'PLZ'
  },
  {
    'name': 'Portuguese Escudo',
    'code': 'PTE'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Leu A/52',
    'code': 'ROK'
  },
  {
    'name': 'Old Leu',
    'code': 'ROL'
  },
  {
    'name': 'New Romanian Leu',
    'code': 'RON'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Dobra',
    'code': 'STD'
  },
  {
    'name': 'Serbian Dinar',
    'code': 'CSD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Slovak Koruna',
    'code': 'SKK'
  },
  {
    'name': 'Tolar',
    'code': 'SIT'
  },
  {
    'name': 'Financial Rand',
    'code': 'ZAL'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDG'
  },
  {
    'name': 'Rhodesian Dollar',
    'code': 'RHD'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESA'
  },
  {
    'name': '\'A\' Account (convertible Peseta Account)',
    'code': 'ESB'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESP'
  },
  {
    'name': 'Sudanese Dinar',
    'code': 'SDD'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDP'
  },
  {
    'name': 'Surinam Guilder',
    'code': 'SRG'
  },
  {
    'name': 'Lilangeni',
    'code': 'SZL'
  },
  {
    'name': 'WIR Franc (for electronic)',
    'code': 'CHC'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Tajik Ruble',
    'code': 'TJR'
  },
  {
    'name': 'Rupiah',
    'code': 'IDR'
  },
  {
    'name': 'Timor Escudo',
    'code': 'TPE'
  },
  {
    'name': 'Old Turkish Lira',
    'code': 'TRL'
  },
  {
    'name': 'New Turkish Lira',
    'code': 'TRY'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Turkmenistan Manat',
    'code': 'TMM'
  },
  {
    'name': 'Uganda Shilling',
    'code': 'UGS'
  },
  {
    'name': 'Old Shilling',
    'code': 'UGW'
  },
  {
    'name': 'Karbovanet',
    'code': 'UAK'
  },
  {
    'name': 'Rouble',
    'code': 'SUR'
  },
  {
    'name': 'US Dollar (Same day)',
    'code': 'USS'
  },
  {
    'name': 'Old Uruguay Peso',
    'code': 'UYN'
  },
  {
    'name': 'Uruguayan Peso',
    'code': 'UYP'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Bolivar',
    'code': 'VEB'
  },
  {
    'name': 'Bolivar Fuerte',
    'code': 'VEF'
  },
  {
    'name': 'Bolivar',
    'code': 'VEF'
  },
  {
    'name': 'Bolívar',
    'code': 'VEF'
  },
  {
    'name': 'Old Dong',
    'code': 'VNC'
  },
  {
    'name': 'Yemeni Dinar',
    'code': 'YDD'
  },
  {
    'name': 'New Yugoslavian Dinar',
    'code': 'YUD'
  },
  {
    'name': 'New Dinar',
    'code': 'YUM'
  },
  {
    'name': 'Yugoslavian Dinar',
    'code': 'YUN'
  },
  {
    'name': 'New Zaire',
    'code': 'ZRN'
  },
  {
    'name': 'Zaire',
    'code': 'ZRZ'
  },
  {
    'name': 'Zambian Kwacha',
    'code': 'ZMK'
  },
  {
    'name': 'Rhodesian Dollar',
    'code': 'ZWC'
  },
  {
    'name': 'Zimbabwe Dollar (old)',
    'code': 'ZWD'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWD'
  },
  {
    'name': 'Zimbabwe Dollar (new)',
    'code': 'ZWN'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWR'
  },
  {
    'name': 'Gold-Franc',
    'code': 'XFO'
  },
  {
    'name': 'RINET Funds Code',
    'code': 'XRE'
  },
  {
    'name': 'UIC-Franc',
    'code': 'XFU'
  },
  {
    'name': 'Currency',
    'code': 'AlphabeticCode'
  },
  {
    'name': 'Afghani',
    'code': 'AFN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lek',
    'code': 'ALL'
  },
  {
    'name': 'Algerian Dinar',
    'code': 'DZD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Kwanza',
    'code': 'AOA'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Argentine Peso',
    'code': 'ARS'
  },
  {
    'name': 'Armenian Dram',
    'code': 'AMD'
  },
  {
    'name': 'Aruban Florin',
    'code': 'AWG'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Azerbaijan Manat',
    'code': 'AZN'
  },
  {
    'name': 'Bahamian Dollar',
    'code': 'BSD'
  },
  {
    'name': 'Bahraini Dinar',
    'code': 'BHD'
  },
  {
    'name': 'Taka',
    'code': 'BDT'
  },
  {
    'name': 'Barbados Dollar',
    'code': 'BBD'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Belize Dollar',
    'code': 'BZD'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Bermudian Dollar',
    'code': 'BMD'
  },
  {
    'name': 'Indian Rupee',
    'code': 'INR'
  },
  {
    'name': 'Ngultrum',
    'code': 'BTN'
  },
  {
    'name': 'Boliviano',
    'code': 'BOB'
  },
  {
    'name': 'Mvdol',
    'code': 'BOV'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Convertible Mark',
    'code': 'BAM'
  },
  {
    'name': 'Pula',
    'code': 'BWP'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Brazilian Real',
    'code': 'BRL'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Brunei Dollar',
    'code': 'BND'
  },
  {
    'name': 'Bulgarian Lev',
    'code': 'BGN'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Burundi Franc',
    'code': 'BIF'
  },
  {
    'name': 'Cabo Verde Escudo',
    'code': 'CVE'
  },
  {
    'name': 'Riel',
    'code': 'KHR'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Canadian Dollar',
    'code': 'CAD'
  },
  {
    'name': 'Cayman Islands Dollar',
    'code': 'KYD'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Chilean Peso',
    'code': 'CLP'
  },
  {
    'name': 'Unidad de Fomento',
    'code': 'CLF'
  },
  {
    'name': 'Yuan Renminbi',
    'code': 'CNY'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Colombian Peso',
    'code': 'COP'
  },
  {
    'name': 'Unidad de Valor Real',
    'code': 'COU'
  },
  {
    'name': 'Comorian Franc',
    'code': 'KMF'
  },
  {
    'name': 'Congolese Franc',
    'code': 'CDF'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Costa Rican Colon',
    'code': 'CRC'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Kuna',
    'code': 'HRK'
  },
  {
    'name': 'Cuban Peso',
    'code': 'CUP'
  },
  {
    'name': 'Peso Convertible',
    'code': 'CUC'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Czech Koruna',
    'code': 'CZK'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'Djibouti Franc',
    'code': 'DJF'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Dominican Peso',
    'code': 'DOP'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Egyptian Pound',
    'code': 'EGP'
  },
  {
    'name': 'El Salvador Colon',
    'code': 'SVC'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Nakfa',
    'code': 'ERN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lilangeni',
    'code': 'SZL'
  },
  {
    'name': 'Ethiopian Birr',
    'code': 'ETB'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Falkland Islands Pound',
    'code': 'FKP'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'Fiji Dollar',
    'code': 'FJD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFA Franc BEAC',
    'code': 'XAF'
  },
  {
    'name': 'Dalasi',
    'code': 'GMD'
  },
  {
    'name': 'Lari',
    'code': 'GEL'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Ghana Cedi',
    'code': 'GHS'
  },
  {
    'name': 'Gibraltar Pound',
    'code': 'GIP'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Danish Krone',
    'code': 'DKK'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Quetzal',
    'code': 'GTQ'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'Guinean Franc',
    'code': 'GNF'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Guyana Dollar',
    'code': 'GYD'
  },
  {
    'name': 'Gourde',
    'code': 'HTG'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lempira',
    'code': 'HNL'
  },
  {
    'name': 'Hong Kong Dollar',
    'code': 'HKD'
  },
  {
    'name': 'Forint',
    'code': 'HUF'
  },
  {
    'name': 'Iceland Krona',
    'code': 'ISK'
  },
  {
    'name': 'Indian Rupee',
    'code': 'INR'
  },
  {
    'name': 'Rupiah',
    'code': 'IDR'
  },
  {
    'name': 'SDR (Special Drawing Right)',
    'code': 'XDR'
  },
  {
    'name': 'Iranian Rial',
    'code': 'IRR'
  },
  {
    'name': 'Iraqi Dinar',
    'code': 'IQD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'New Israeli Sheqel',
    'code': 'ILS'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Jamaican Dollar',
    'code': 'JMD'
  },
  {
    'name': 'Yen',
    'code': 'JPY'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'Jordanian Dinar',
    'code': 'JOD'
  },
  {
    'name': 'Tenge',
    'code': 'KZT'
  },
  {
    'name': 'Kenyan Shilling',
    'code': 'KES'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'North Korean Won',
    'code': 'KPW'
  },
  {
    'name': 'Won',
    'code': 'KRW'
  },
  {
    'name': 'Kuwaiti Dinar',
    'code': 'KWD'
  },
  {
    'name': 'Som',
    'code': 'KGS'
  },
  {
    'name': 'Lao Kip',
    'code': 'LAK'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Lebanese Pound',
    'code': 'LBP'
  },
  {
    'name': 'Loti',
    'code': 'LSL'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'Liberian Dollar',
    'code': 'LRD'
  },
  {
    'name': 'Libyan Dinar',
    'code': 'LYD'
  },
  {
    'name': 'Swiss Franc',
    'code': 'CHF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Pataca',
    'code': 'MOP'
  },
  {
    'name': 'Denar',
    'code': 'MKD'
  },
  {
    'name': 'Malagasy Ariary',
    'code': 'MGA'
  },
  {
    'name': 'Malawi Kwacha',
    'code': 'MWK'
  },
  {
    'name': 'Malaysian Ringgit',
    'code': 'MYR'
  },
  {
    'name': 'Rufiyaa',
    'code': 'MVR'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Ouguiya',
    'code': 'MRU'
  },
  {
    'name': 'Mauritius Rupee',
    'code': 'MUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'ADB Unit of Account',
    'code': 'XUA'
  },
  {
    'name': 'Mexican Peso',
    'code': 'MXN'
  },
  {
    'name': 'Mexican Unidad de Inversion (UDI)',
    'code': 'MXV'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Moldovan Leu',
    'code': 'MDL'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Tugrik',
    'code': 'MNT'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Moroccan Dirham',
    'code': 'MAD'
  },
  {
    'name': 'Mozambique Metical',
    'code': 'MZN'
  },
  {
    'name': 'Kyat',
    'code': 'MMK'
  },
  {
    'name': 'Namibia Dollar',
    'code': 'NAD'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Nepalese Rupee',
    'code': 'NPR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Cordoba Oro',
    'code': 'NIO'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Naira',
    'code': 'NGN'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Rial Omani',
    'code': 'OMR'
  },
  {
    'name': 'Pakistan Rupee',
    'code': 'PKR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Balboa',
    'code': 'PAB'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Kina',
    'code': 'PGK'
  },
  {
    'name': 'Guarani',
    'code': 'PYG'
  },
  {
    'name': 'Sol',
    'code': 'PEN'
  },
  {
    'name': 'Philippine Peso',
    'code': 'PHP'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Zloty',
    'code': 'PLN'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Qatari Rial',
    'code': 'QAR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Romanian Leu',
    'code': 'RON'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUB'
  },
  {
    'name': 'Rwanda Franc',
    'code': 'RWF'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Saint Helena Pound',
    'code': 'SHP'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'East Caribbean Dollar',
    'code': 'XCD'
  },
  {
    'name': 'Tala',
    'code': 'WST'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Dobra',
    'code': 'STN'
  },
  {
    'name': 'Saudi Riyal',
    'code': 'SAR'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'Serbian Dinar',
    'code': 'RSD'
  },
  {
    'name': 'Seychelles Rupee',
    'code': 'SCR'
  },
  {
    'name': 'Leone',
    'code': 'SLL'
  },
  {
    'name': 'Singapore Dollar',
    'code': 'SGD'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Sucre',
    'code': 'XSU'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Solomon Islands Dollar',
    'code': 'SBD'
  },
  {
    'name': 'Somali Shilling',
    'code': 'SOS'
  },
  {
    'name': 'Rand',
    'code': 'ZAR'
  },
  {
    'name': 'South Sudanese Pound',
    'code': 'SSP'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Sri Lanka Rupee',
    'code': 'LKR'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDG'
  },
  {
    'name': 'Surinam Dollar',
    'code': 'SRD'
  },
  {
    'name': 'Norwegian Krone',
    'code': 'NOK'
  },
  {
    'name': 'Swedish Krona',
    'code': 'SEK'
  },
  {
    'name': 'Swiss Franc',
    'code': 'CHF'
  },
  {
    'name': 'WIR Euro',
    'code': 'CHE'
  },
  {
    'name': 'WIR Franc',
    'code': 'CHW'
  },
  {
    'name': 'Syrian Pound',
    'code': 'SYP'
  },
  {
    'name': 'New Taiwan Dollar',
    'code': 'TWD'
  },
  {
    'name': 'Somoni',
    'code': 'TJS'
  },
  {
    'name': 'Tanzanian Shilling',
    'code': 'TZS'
  },
  {
    'name': 'Baht',
    'code': 'THB'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFA Franc BCEAO',
    'code': 'XOF'
  },
  {
    'name': 'New Zealand Dollar',
    'code': 'NZD'
  },
  {
    'name': 'Pa\'anga',
    'code': 'TOP'
  },
  {
    'name': 'Trinidad and Tobago Dollar',
    'code': 'TTD'
  },
  {
    'name': 'Tunisian Dinar',
    'code': 'TND'
  },
  {
    'name': 'Turkish Lira',
    'code': 'TRY'
  },
  {
    'name': 'Turkmenistan New Manat',
    'code': 'TMT'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'Australian Dollar',
    'code': 'AUD'
  },
  {
    'name': 'Uganda Shilling',
    'code': 'UGX'
  },
  {
    'name': 'Hryvnia',
    'code': 'UAH'
  },
  {
    'name': 'UAE Dirham',
    'code': 'AED'
  },
  {
    'name': 'Pound Sterling',
    'code': 'GBP'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar (Next day)',
    'code': 'USN'
  },
  {
    'name': 'Peso Uruguayo',
    'code': 'UYU'
  },
  {
    'name': 'Uruguay Peso en Unidades Indexadas (UI)',
    'code': 'UYI'
  },
  {
    'name': 'Unidad Previsional',
    'code': 'UYW'
  },
  {
    'name': 'Uzbekistan Sum',
    'code': 'UZS'
  },
  {
    'name': 'Vatu',
    'code': 'VUV'
  },
  {
    'name': 'Bolívar Soberano',
    'code': 'VES'
  },
  {
    'name': 'Dong',
    'code': 'VND'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'US Dollar',
    'code': 'USD'
  },
  {
    'name': 'CFP Franc',
    'code': 'XPF'
  },
  {
    'name': 'Moroccan Dirham',
    'code': 'MAD'
  },
  {
    'name': 'Yemeni Rial',
    'code': 'YER'
  },
  {
    'name': 'Zambian Kwacha',
    'code': 'ZMW'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWL'
  },
  {
    'name': 'Bond Markets Unit European Composite Unit (EURCO)',
    'code': 'XBA'
  },
  {
    'name': 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
    'code': 'XBB'
  },
  {
    'name': 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
    'code': 'XBC'
  },
  {
    'name': 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
    'code': 'XBD'
  },
  {
    'name': 'Codes specifically reserved for testing purposes',
    'code': 'XTS'
  },
  {
    'name': 'The codes assigned for transactions where no currency is involved',
    'code': 'XXX'
  },
  {
    'name': 'Gold',
    'code': 'XAU'
  },
  {
    'name': 'Palladium',
    'code': 'XPD'
  },
  {
    'name': 'Platinum',
    'code': 'XPT'
  },
  {
    'name': 'Silver',
    'code': 'XAG'
  },
  {
    'name': 'Afghani',
    'code': 'AFA'
  },
  {
    'name': 'Markka',
    'code': 'FIM'
  },
  {
    'name': 'Old Lek',
    'code': 'ALK'
  },
  {
    'name': 'Andorran Peseta',
    'code': 'ADP'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESP'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Kwanza',
    'code': 'AOK'
  },
  {
    'name': 'New Kwanza',
    'code': 'AON'
  },
  {
    'name': 'Kwanza Reajustado',
    'code': 'AOR'
  },
  {
    'name': 'Austral',
    'code': 'ARA'
  },
  {
    'name': 'Peso Argentino',
    'code': 'ARP'
  },
  {
    'name': 'Peso',
    'code': 'ARY'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Schilling',
    'code': 'ATS'
  },
  {
    'name': 'Azerbaijan Manat',
    'code': 'AYM'
  },
  {
    'name': 'Azerbaijanian Manat',
    'code': 'AZM'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYB'
  },
  {
    'name': 'Belarusian Ruble',
    'code': 'BYR'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Convertible Franc',
    'code': 'BEC'
  },
  {
    'name': 'Belgian Franc',
    'code': 'BEF'
  },
  {
    'name': 'Financial Franc',
    'code': 'BEL'
  },
  {
    'name': 'Peso boliviano',
    'code': 'BOP'
  },
  {
    'name': 'Dinar',
    'code': 'BAD'
  },
  {
    'name': 'Cruzeiro',
    'code': 'BRB'
  },
  {
    'name': 'Cruzado',
    'code': 'BRC'
  },
  {
    'name': 'Cruzeiro',
    'code': 'BRE'
  },
  {
    'name': 'New Cruzado',
    'code': 'BRN'
  },
  {
    'name': 'Cruzeiro Real',
    'code': 'BRR'
  },
  {
    'name': 'Lev A/52',
    'code': 'BGJ'
  },
  {
    'name': 'Lev A/62',
    'code': 'BGK'
  },
  {
    'name': 'Lev',
    'code': 'BGL'
  },
  {
    'name': 'Kyat',
    'code': 'BUK'
  },
  {
    'name': 'Croatian Dinar',
    'code': 'HRD'
  },
  {
    'name': 'Croatian Kuna',
    'code': 'HRK'
  },
  {
    'name': 'Cyprus Pound',
    'code': 'CYP'
  },
  {
    'name': 'Krona A/53',
    'code': 'CSJ'
  },
  {
    'name': 'Koruna',
    'code': 'CSK'
  },
  {
    'name': 'Sucre',
    'code': 'ECS'
  },
  {
    'name': 'Unidad de Valor Constante (UVC)',
    'code': 'ECV'
  },
  {
    'name': 'Ekwele',
    'code': 'GQE'
  },
  {
    'name': 'Kroon',
    'code': 'EEK'
  },
  {
    'name': 'European Currency Unit (E.C.U)',
    'code': 'XEU'
  },
  {
    'name': 'Markka',
    'code': 'FIM'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Georgian Coupon',
    'code': 'GEK'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Mark der DDR',
    'code': 'DDM'
  },
  {
    'name': 'Deutsche Mark',
    'code': 'DEM'
  },
  {
    'name': 'Cedi',
    'code': 'GHC'
  },
  {
    'name': 'Ghana Cedi',
    'code': 'GHP'
  },
  {
    'name': 'Drachma',
    'code': 'GRD'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Syli',
    'code': 'GNE'
  },
  {
    'name': 'Syli',
    'code': 'GNS'
  },
  {
    'name': 'Guinea Escudo',
    'code': 'GWE'
  },
  {
    'name': 'Guinea-Bissau Peso',
    'code': 'GWP'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Old Krona',
    'code': 'ISJ'
  },
  {
    'name': 'Irish Pound',
    'code': 'IEP'
  },
  {
    'name': 'Pound',
    'code': 'ILP'
  },
  {
    'name': 'Old Shekel',
    'code': 'ILR'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Pathet Lao Kip',
    'code': 'LAJ'
  },
  {
    'name': 'Latvian Lats',
    'code': 'LVL'
  },
  {
    'name': 'Latvian Ruble',
    'code': 'LVR'
  },
  {
    'name': 'Loti',
    'code': 'LSM'
  },
  {
    'name': 'Financial Rand',
    'code': 'ZAL'
  },
  {
    'name': 'Lithuanian Litas',
    'code': 'LTL'
  },
  {
    'name': 'Talonas',
    'code': 'LTT'
  },
  {
    'name': 'Luxembourg Convertible Franc',
    'code': 'LUC'
  },
  {
    'name': 'Luxembourg Franc',
    'code': 'LUF'
  },
  {
    'name': 'Luxembourg Financial Franc',
    'code': 'LUL'
  },
  {
    'name': 'Malagasy Franc',
    'code': 'MGF'
  },
  {
    'name': 'Kwacha',
    'code': 'MWK'
  },
  {
    'name': 'Maldive Rupee',
    'code': 'MVQ'
  },
  {
    'name': 'Mali Franc',
    'code': 'MLF'
  },
  {
    'name': 'Maltese Lira',
    'code': 'MTL'
  },
  {
    'name': 'Maltese Pound',
    'code': 'MTP'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Ouguiya',
    'code': 'MRO'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Mexican Peso',
    'code': 'MXP'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Mozambique Escudo',
    'code': 'MZE'
  },
  {
    'name': 'Mozambique Metical',
    'code': 'MZM'
  },
  {
    'name': 'Netherlands Guilder',
    'code': 'NLG'
  },
  {
    'name': 'Netherlands Antillean Guilder',
    'code': 'ANG'
  },
  {
    'name': 'Cordoba',
    'code': 'NIC'
  },
  {
    'name': 'Sol',
    'code': 'PEH'
  },
  {
    'name': 'Inti',
    'code': 'PEI'
  },
  {
    'name': 'Nuevo Sol',
    'code': 'PEN'
  },
  {
    'name': 'Sol',
    'code': 'PES'
  },
  {
    'name': 'Zloty',
    'code': 'PLZ'
  },
  {
    'name': 'Portuguese Escudo',
    'code': 'PTE'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Leu A/52',
    'code': 'ROK'
  },
  {
    'name': 'Old Leu',
    'code': 'ROL'
  },
  {
    'name': 'New Romanian Leu',
    'code': 'RON'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'French Franc',
    'code': 'FRF'
  },
  {
    'name': 'Italian Lira',
    'code': 'ITL'
  },
  {
    'name': 'Dobra',
    'code': 'STD'
  },
  {
    'name': 'Serbian Dinar',
    'code': 'CSD'
  },
  {
    'name': 'Euro',
    'code': 'EUR'
  },
  {
    'name': 'Slovak Koruna',
    'code': 'SKK'
  },
  {
    'name': 'Tolar',
    'code': 'SIT'
  },
  {
    'name': 'Financial Rand',
    'code': 'ZAL'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDG'
  },
  {
    'name': 'Rhodesian Dollar',
    'code': 'RHD'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESA'
  },
  {
    'name': '\'A\' Account (convertible Peseta Account)',
    'code': 'ESB'
  },
  {
    'name': 'Spanish Peseta',
    'code': 'ESP'
  },
  {
    'name': 'Sudanese Dinar',
    'code': 'SDD'
  },
  {
    'name': 'Sudanese Pound',
    'code': 'SDP'
  },
  {
    'name': 'Surinam Guilder',
    'code': 'SRG'
  },
  {
    'name': 'Lilangeni',
    'code': 'SZL'
  },
  {
    'name': 'WIR Franc (for electronic)',
    'code': 'CHC'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Tajik Ruble',
    'code': 'TJR'
  },
  {
    'name': 'Rupiah',
    'code': 'IDR'
  },
  {
    'name': 'Timor Escudo',
    'code': 'TPE'
  },
  {
    'name': 'Old Turkish Lira',
    'code': 'TRL'
  },
  {
    'name': 'New Turkish Lira',
    'code': 'TRY'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Turkmenistan Manat',
    'code': 'TMM'
  },
  {
    'name': 'Uganda Shilling',
    'code': 'UGS'
  },
  {
    'name': 'Old Shilling',
    'code': 'UGW'
  },
  {
    'name': 'Karbovanet',
    'code': 'UAK'
  },
  {
    'name': 'Rouble',
    'code': 'SUR'
  },
  {
    'name': 'US Dollar (Same day)',
    'code': 'USS'
  },
  {
    'name': 'Old Uruguay Peso',
    'code': 'UYN'
  },
  {
    'name': 'Uruguayan Peso',
    'code': 'UYP'
  },
  {
    'name': 'Russian Ruble',
    'code': 'RUR'
  },
  {
    'name': 'Bolivar',
    'code': 'VEB'
  },
  {
    'name': 'Bolivar Fuerte',
    'code': 'VEF'
  },
  {
    'name': 'Bolivar',
    'code': 'VEF'
  },
  {
    'name': 'Bolívar',
    'code': 'VEF'
  },
  {
    'name': 'Old Dong',
    'code': 'VNC'
  },
  {
    'name': 'Yemeni Dinar',
    'code': 'YDD'
  },
  {
    'name': 'New Yugoslavian Dinar',
    'code': 'YUD'
  },
  {
    'name': 'New Dinar',
    'code': 'YUM'
  },
  {
    'name': 'Yugoslavian Dinar',
    'code': 'YUN'
  },
  {
    'name': 'New Zaire',
    'code': 'ZRN'
  },
  {
    'name': 'Zaire',
    'code': 'ZRZ'
  },
  {
    'name': 'Zambian Kwacha',
    'code': 'ZMK'
  },
  {
    'name': 'Rhodesian Dollar',
    'code': 'ZWC'
  },
  {
    'name': 'Zimbabwe Dollar (old)',
    'code': 'ZWD'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWD'
  },
  {
    'name': 'Zimbabwe Dollar (new)',
    'code': 'ZWN'
  },
  {
    'name': 'Zimbabwe Dollar',
    'code': 'ZWR'
  },
  {
    'name': 'Gold-Franc',
    'code': 'XFO'
  },
  {
    'name': 'RINET Funds Code',
    'code': 'XRE'
  },
  {
    'name': 'UIC-Franc',
    'code': 'XFU'
  }
]