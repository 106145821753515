export const sharedLinksMethods = {
  methods: {
    open() {
      var win = window.open(this.sharedUrl(), '_blank')
      win.focus()
    },
    openEmbeddedEditor() {
      const routeData = this.$router.resolve( {name: 'EmbeddedEditor', params: { sharedUrl: this.sharedUrl() } } )
      window.open(routeData.href, '_blank')
    },
    copyLink() {
      navigator.clipboard.writeText(this.sharedUrl())
    },
    showQrCode() {
      this.$emit('showQrCode')
    },
    sharedUrl() {
      var getUrl = window.location
      var baseUrl =
        getUrl.protocol + '//' + getUrl.host + getUrl.pathname.split('/')[1]
      return baseUrl + this.uri
    },
    async deleteLink() {
      await this.link.delete()
      this.$emit('linkDeleted')
    }
  }
}
