<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <MenuButton
        v-on="on"
        icon="mdi-calendar-edit-outline"
        :text="$t('calendar.stateSelection.activtor')"
      />
    </template>
    <CalendarStateSelection
      :calendarView="calendarView"
      @close="close"
    />
  </v-menu>
</template>

<script>
import CalendarStateSelection from '@/components/calendar/CalendarStateSelection.vue'
import MenuButton from '@/components/MenuButton.vue'

export default {
  props: ['calendarView'],
  data() {
    return {
      menu: false
    }
  },
  methods: {
    close() {
      this.menu = false
    }
  },
  components: {
    CalendarStateSelection,
    MenuButton
  }
}
</script>
