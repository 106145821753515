<template>
  <v-sheet class="panel d-flex" transition="slide-x-transition" :style="panelStyle">
    <v-btn class="close-button" data-testid="closeFlowEditorPanel" icon @click="close"><v-icon>mdi-window-close</v-icon></v-btn>
      <div
        class="resize-handle d-flex align-center"
        @pointerdown="startResize"
      >
        <div class="ml-1 resize-handle-indicator"/>
      </div>
      <div :style="panelSlotStyle">
        <slot />
      </div>
  </v-sheet>
</template>

<script>
const DEFAULT_PANEL_WIDTH = 380
const MIN_PANEL_WIDTH = 320

export default {
  props: {
    node: null
  },
  data() {
    return {
      panelWidth: DEFAULT_PANEL_WIDTH,
      resizingRef: undefined
    }
  },
  computed: {
    panelStyle() {
      return {
        width: `${this.panelWidth}px`,
        minWidth: `${MIN_PANEL_WIDTH}px`
      }
    },
    panelSlotStyle() {
      return {
        width: `${this.panelWidth - 8}px`,
        minWidth: `${MIN_PANEL_WIDTH - 8}px`
      }
    }
  },
  methods: {
    startResize(event) {
      this.resizingRef = event.clientX
      document.addEventListener('pointermove', this.resizing)
      document.addEventListener('pointerup', this.stopResize)
    },
    resizing(event) {
      const newSize = this.panelWidth + this.resizingRef - event.clientX
      if (newSize >= MIN_PANEL_WIDTH) {
        this.panelWidth = newSize
      }
      this.resizingRef = event.clientX
    },
    stopResize() {
      this.resizingRef = undefined
      document.removeEventListener('pointermove', this.resizing)
      document.removeEventListener('pointerup', this.stopResize)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="css" scoped>
.close-button {
  position: absolute;
  top: 6px;
  left: 5px;
  z-index: 1;
}

.panel {
  position: absolute;
  height: 100%;
  right: 0px;
  top: 0px;
  background-color: #ffffff;
  animation-name: pannelIn;
  animation-duration: 0.4s;
  border-left: 1px solid #e0e0e0;
  box-shadow: 5px -1px 8px 0px rgba(0,0,0,1);
}

@keyframes pannelIn {
  0% {
    right: -380px;
    top: 0px;
  }

  100% {
    right: 0px;
    top: 0px;
  }
}

.resize-handle {
  height: 100%;
  width: 8px;
  cursor: col-resize;
  flex-shrink: 0;
}

.resize-handle-indicator {
  width: 6px;
  height: 80px;
  border-radius: 4px;
  background-color: #D9D9D9;
}

</style>
