<template>
<UserSelect
  ref="input"
  v-model="model"
  :field="field"
  :isCollection="isCollection"
  :readonly="readonly"
  @focus="$emit('focus')"
  @blur="$emit('blur')"
  :showControls="showControls"
  small-chips
  :clearable="showControls"
  hide-details
  solo
  flat
  preventSelectionScroll
  @mouseenter.native="onMouseEnter"
  @mouseleave.native="onMouseLeave"
/>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import UserSelect from './UserSelect.vue'

export default {
  mixins: [gridCells],
  data() {
    return {
      showControls: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal ?? null)
        this.$emit('blur')
      }
    },
    isCollection() {
      return this.field.type.jsonSchema.type === 'array'
    }
  },
  methods: {
    onMouseEnter() {
      if (this.$vuetify.breakpoint.mobile) {
        this.showControlsTimeout = setTimeout(() => this.showControls = true, 100)
      } else {
        this.showControls = true
      }
    },
    onMouseLeave() {
      if (this.showControlsTimeout) {
        clearTimeout(this.showControlsTimeout)
      }
      this.showControls = false
    }
  },
  components: {
    UserSelect
  }
}
</script>

<style scoped lang="scss">
</style>
