<script>
import FileThumbnail from '@/components/FileThumbnail.vue'
import {noValue} from '@/utils/jsUtils.js'
import { classesFromData } from '@/utils/vueUtils.js'

function thumbnails(h, value) {
  if(noValue(value)) {
    return []
  }
  const array = Array.isArray(value) ? value : [value]
  return array.map(item => {
    return h(FileThumbnail, {
      class: 'ml-1',
      props: { file: item }
    })
  })
}

export default {
  functional: true,
  props: ['value'],
  render(h, context) {
    let files = Array.isArray(context.props.value) ? context.props.value : [context.props.value]
    files = files.filter(file => file != null)
    return h(
      'div',
      {
        class: 'border-right cell attachment-list d-flex align-center ' + classesFromData(context.data),
        style: context.data.style,
        on: context.data.on,
        attrs: {
          ...context.data.attrs
        }
      },
      thumbnails(h, files)
    )
  }
}
</script>

<style>
.attachment-list {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}
</style>