<template>
  <BaseFormInput v-bind="$props">
    <component
      :is="selectComponent"
      v-model="inputValue"
      :label="label"
      :items="items"
      :rules="[rules.required, rules.schema]"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      :menu-props="['bottom', 'offset-y']"

      clearable
      :multiple="isCollection"
      :deletable-chips="isCollection"
      :color="color"
      :placeholder="placeholder"
      :disabled="disabled"
      v-bind="fieldStyle"
    >
      <template v-slot:item="slotProps">
        <v-list-item dense v-on="slotProps.on" :key="slotProps.item">
          {{ slotProps.item }}
        </v-list-item>
      </template>
      <template v-slot:prepend-item v-if="!isExtended && items.count > 5">
        <v-list-item dense>
          <v-text-field
            v-model="filter"
            :placeholder="$t('virtualGrid.enumFilterPlaceholder')"
            solo
            flat
            dense
            clearable
            hide-details
            :color="color"
          />
        </v-list-item>
      </template>
    </component>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components//form/BaseFormInput.vue'
import {
  VSelect,
  VCombobox,
} from 'vuetify/lib'

export default {
  mixins: [formFields],
  data() {
    return {
      filter: '',
    }
  },
  computed: {
    isExtended() {
      return this.fieldType.extended
    },
    selectComponent() {
      return this.isExtended ? VCombobox : VSelect
    },
    placeholder() {
      return this.isExtended ? this.$t('virtualGrid.enumOtherOption') : undefined
    },
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isCollection() {
      return this.fieldType.jsonSchema.type === 'array'
    },
    items() {
      if ((!this.isCollection && !this.fieldType.jsonSchema.enum) || (this.isCollection && !this.fieldType.jsonSchema.items.enum)) {
        return []
      }
      const options = this.isCollection ? this.fieldType.jsonSchema.items.enum : this.fieldType.jsonSchema.enum
      return options.filter(
        el => !this.filter || el.includes(this.filter)
      )
    }
  },
  methods: {
    chipColor(item) {
      return (this.value && this.value === item) ? this.color : undefined
    },
  },
  components: {
    BaseFormInput
  }
}
</script>
