<template>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="mt-3 text-h5">
          {{$t('links.shareView.success.title')}}
        </v-card-title>
        <v-card-text>
          <v-alert class="mt-3" type="success">{{$t('links.shareView.success.message')}}</v-alert>
          <div class="bold mt-3">{{$t('links.shareView.success.textBold')}}</div>
          <div>{{$t('links.shareView.success.textBody')}}</div>
          <div class="d-flex align-center mt-5">
            <v-text-field
              class="display-field"
              ref="usernameField"
              :label="$t('links.shareView.success.usernameFieldLabel')"
              outlined
              readonly
              hide-details
              v-model="username"
            />
            <v-btn class="ma-3" icon @click="() => copy('usernameField')">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center mt-5">
            <v-text-field
              class="display-field"
              ref="passwordField"
              :label="$t('links.shareView.success.passwordFieldLabel')"
              outlined
              readonly
              hide-details
              v-model="password"
            />
            <v-btn class="ma-3" icon @click="() => copy('passwordField')">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions class="pr-5 pb-5">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('input', false)">
            {{$t("dialogs.closeButton")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      credentials: Object,
      value: undefined
    },
    data() {
      return {
        inputModel: undefined
      }
    },
    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(newVal) {
          this.$emit('input', newVal)
        }
      },
      username() {
        return this.credentials?.username
      },
      password() {
        return this.credentials?.password
      }
    },
    methods: {
      copy(ref) {
        const input = this.$refs[ref].$el.querySelector('input')
        input.select()
        try {
          document.execCommand('copy')
        } catch (error) {
          this.$apptiveErrorReporter.captureException(error)
          alert('Oops, unable to copy')
        }
      }
    }
  }
  </script>
  
  <style lang="css">
  .display-field {
    max-width: 300px;
  }
  </style>
  