import validation from '@/utils/inputValidation.js'

function missingValue(value) {
  if (value === undefined || value === null) {
    return true
  }

  if (typeof value === 'string' && value === '') {
    return true
  }

  if (Array.isArray(value) && value.length <= 0) {
    return true
  }
  return false
}

export const textFieldRules = {
  data() {
    return {
      rules: {
        required: [
          value => !missingValue(value) || this.$t('validation.required')
        ],
        nameRequired: [
          value => !!value || this.$t('validation.nameRequired'),
        ],
        maxLength: (max) => [
          value =>
            ((value?.length ?? 0) <= max) ||
            this.$t('validation.maxLength', { max })
        ],
        spaces: [
          value =>
            !this.$store.getters.spacesList.some(el => el.name === value) ||
            this.$t('validation.nameExistsError')
        ],
        loginEmail: [value => !!value || this.$t('validation.emailRequired')],
        loginPassword: [
          value => !!value || this.$t('validation.passwordRequired')
        ],
        registrationEmail: [
          value => (value == null || validation.validEmail(value)) || this.$t('validation.emailError')
        ],
        registrationPassword: [
          value => !!value || this.$t('validation.passwordRequired'),
          // regex minimum one small letter, one big letter, one number and one special character: ()[]{}?!$%&/=*+~#,.;:<>@_-
          // eslint-disable-next-line
          value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[()\[\]{}?!$%&/=*+~#,.;:<>@_-])[A-Za-z\d()\[\]{}?!$%&/=*+~#,.;:<>@_-]{8,}$/.test(value) || this.$t('validation.passwordError')
        ],
        phoneNumber: [
          value => !value || /^[0-9+\s#*]*$/.test(value) || this.$t('validation.invalidPhoneNumber'),
          value => !value || value.startsWith('+') || this.$t('validation.phoneNumberInternationalIndicator'),
        ]
      }
    }
  }
}
