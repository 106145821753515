import * as Conditions from '@/filter/conditions.js'

function parseFilter(filter, accumulator) {
  if (filter === undefined) {
    return undefined
  }
  return Object.entries(filter).reduce((accumulator, entry) => {
    return accumulator.add(read(entry[0], entry[1]))
  }, accumulator ?? new Conditions.AGNoCondition())
}

function read(key, condition) {
  const keyCondition = lookupCondition(key)
  if (keyCondition) {
    var newCondition = new keyCondition()
    // Added the ability to use arrays
    if (Array.isArray(condition)) {
      condition.map(singleCondition => {
        newCondition.add(parseFilter(singleCondition))
      })
      return newCondition
    }
    return parseFilter(condition, newCondition)
  } else {
    return readField(key, condition)
  }
}

function lookupCondition(key) {
  return Conditions.CONDITIONS[key]
}

function readField(key, condition) {
  const fieldReference = new Conditions.AGFieldReference(key)
  // If it has a uri then it is a reference value and not a nested condition
  const isObject = typeof condition === 'object' && condition !== null && !condition.uri
  if (isObject) {
    const conditionType = lookupCondition(Object.keys(condition)[0])
    return new conditionType(fieldReference, Object.values(condition)[0])
  } else {
    return new Conditions.AGEqualCondition(fieldReference, condition)
  }
}

export { parseFilter }
