<template>
    <v-snackbar
      v-model="snackbar"
      color="white"
      light
      vertical
      max-width="320"
      center
      multi-line
      :timeout="timeout"
      rounded="lg"
      transition="slide-y-reverse-transition"
    >
      <div class="subtitle-2">{{$t('forms.builder.saveForLater.restoreFormTitle')}} </div>
      <div class="grey--text">{{$t('forms.builder.saveForLater.restoreFormSubtitle')}}</div>
      <v-list width="320" dense>
                <v-list-item @click="prefillForm(set, timestamp)" v-for="(set, timestamp) in dataSets" :key="timestamp">
          <v-list-item-content>
            <v-list-item-title>
              {{ formatTimestamp(timestamp)  }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ preview(set) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="deleteDataWith(timestamp)"><v-icon color="destructive" small>mdi-delete-outline</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="deleteAll"
        >
        {{$t('forms.builder.saveForLater.deleteAllButton')}}
        </v-btn>
        <v-btn
          text
          color="primary"
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{$t('forms.builder.saveForLater.closeButton')}}
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import moment from 'moment'
export default {
  props: ['form' , 'show'],
  data: () => ({
    snackbar: false,
    timeout: 20000,
  }),
  watch: {
    form: {
      immediate: true,
      handler(newVal, oldValue) {
        if(oldValue) return
        this.$nextTick(() => {
          setTimeout(() => {
            this.snackbar = newVal != null && Object.keys(newVal.localData).length != 0
          }, 800)
        })
      }
    },
    show: {
      handler(newVal) {
        this.snackbar = newVal
      }
    }
  },
  computed: {
    dataSets() {
      return this.form.localData
    }
  },
  methods: {
    prefillForm(set, timestamp) {
      // hold info that the form was prefilled to delete date after submission
      this.form.prefilledWithLocalDataKey = timestamp
      this.form.prefillWithLocalData(set)
      this.snackbar = false
    },
    deleteAll() {
      this.form.clearLocalSavedData()
    },
    deleteDataWith(key) {
      this.form.deleteSavedDataWith(key)
    },
    formatTimestamp(timestamp) {
      moment.locale(this._i18n.locale)
      const createdAt = moment(timestamp)
      const duration = moment.duration(moment().diff(createdAt))
      return duration.hours() < 1 ? createdAt.fromNow() : createdAt.format('ddd DD MMM HH:MM')
    },
    preview(set) {
      const valuesArray = Object.values(set).filter(val => !Array.isArray(val) && typeof val !== 'object')

      return valuesArray.join(', ')
    }
  }
}
</script>

<style lang="css" scoped>
</style>
