<template>
<input
  ref="input"
  step="any"
  class="px-1 grey--text text--darken-3 right-align"
  type="text"
  :value="internalValue"
  @input="event => handleInput(event.target.value)"
  :readonly="readonly"
  @keyup.exact.enter="nextLine"
  @focus="$emit('focus')"
  @select="$emit('focus')"
  @blur="finishedEditing()"
/>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import currencyInputMixin from '@/mixins/currencyInputMixin.js'

export default {
  mixins: [gridCells, currencyInputMixin]
}
</script>

<style scoped>
.right-align {
  text-align: right;
}
</style>
