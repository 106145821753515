<template>
  <BaseKanbanAttribute v-bind="{...$props, ...$attrs}" :height="height" :titleHeight="titleHeight">
    <template v-slot:default="{ attribute }">
      <v-chip small class="mt-1"><strong>{{attribute.value}}</strong></v-chip>
    </template>
  </BaseKanbanAttribute>
</template>

<script>
import BaseKanbanAttribute from './BaseKanbanAttribute.vue'

export default {
  data() {
    return {
      height: 55,
      titleHeight: 18
    }
  },
  components: {
    BaseKanbanAttribute
  }
}
</script>
