import HalObject from '@/store/models/HalObject.js'
import store from '@/store/store'

export default class Block extends HalObject {
  constructor(data) {
    super(data)
    this.initFromData(data)
    this.isDirty = false
    this.deleted = false
  }

  initFromData(data) {
    this.type = data.type
    this[this.type] = data[this.type]
    this.block = data.block
    this.actions = data.actions
    this.has_children = data.has_children
  }

  validate() {
    return true
  }

  get id() {
    return this.getLink('self').split('/').pop()
  }

  async reload() {
    const block = await store().dispatch('AGReadBlock',{ blockUri:  this.getLink('self')})
    for (let property in block) {
      this[property] = block[property]
    }
  }

  addChildAtPosition(position, newBlock) {
    if (position >= 0 && position <= this.children.length) {
      // Insert the new block at the specific position
      this.children.splice(position, 0, newBlock)
    } else {
      // If the position is out of bounds, append the block at the end
      this.children.push(newBlock)
    }
  }

  async deleteBlockIn(parentBlockOrSpace) {
      await store().dispatch('AGDeleteBlock', { block: this, parent: parentBlockOrSpace})
  }

  childRemoved(child) {
    const index = this.children.indexOf(child)
    if (index !== -1) {
      this.children.splice(index, 1)
    }
  }

  payload() {
    return this.addPayload({})
  }

  addPayload(payload) {
    if( this.formKey !== undefined) {
      const valueKey = this[this.type].type.name 
      payload[this.label] = this[this.type][valueKey]
    }
    if(this.has_children) {
      this.children.forEach(block => block.addPayload(payload))
    }
    return payload
  }

  get childCount() {
    return this.children?.length ?? 0
  }
 }