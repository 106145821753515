<template>
  <PlansTeaser>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="gradient font-weight-bold"
        text
        dark
        v-on="on"
        v-bind="attrs"
      >
      <v-icon color="yellow darken-1" small class="mr-2">mdi-creation</v-icon>
      {{ $t("paywall.upgradeNow") }}
      </v-btn>
    </template>
  </PlansTeaser>
</template>

<script>
import PlansTeaser from '@/components/paywall/PlansTeaser.vue'
export default {
  components: {
    PlansTeaser
  }
}
</script>

<style lang="css" scoped>
.gradient {
  background-image: linear-gradient(to right, #1664c0, #9801fc);
}
</style>
