<template>
  <v-dialog
    v-model="externalModel"
    v-bind="$attrs"
    max-width="400"
  >
  <v-card>
    <v-card-title class="text-h5" v-text="$t('dialogs.gridFromCsvTitle')"/>
    <v-card-text>
      <div>{{$t('dialogs.gridFromCsvText')}}</div>
      <div>{{$t('csvImport.steps.loadFile.fileFormatConstrainsInfo')}}</div>
      <v-file-input
      class="mt-5"
        v-model="csvFile"
        :label="$t('dialogs.gridFromCsvFileInputLabel')"
      />
      <v-checkbox
        v-model="hasHeader"
        :label="$t('dialogs.gridFromCsvHasHeaderLabel')"
        persistent-hint
        :hint="$t('dialogs.gridFromCsvHasHeaderHint')"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey darken-1" text @click="externalModel = false">
        {{$t("dialogs.cancelButton")}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click="okPressed"
        :loading="loading"
        data-testid="dialogOkButton"
      >
        {{$t('dialogs.okButton')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  props: {
    spaceUri: null,
  },
  data() {
    return {
      loading: false,
      hasHeader: false,
      csvFile: undefined
    }
  },
  methods: {
    async okPressed() {
      this.loading = true
      try {
        const createResponse = await this.$store.dispatch('AGCreateGridFromCsvOperation', {
          spaceUri: this.spaceUri,
          csvFile: this.csvFile,
          hasHeader: this.hasHeader
        })
        await this.$store.dispatch('loadGridsUris', this.spaceUri)
        const gridUri = createResponse.headers.location
        const grid = await this.$store.dispatch('AGReadGridOperation', { uri: gridUri })
        const view = await this.$store.dispatch('AGAddSpreadsheetViewOperation', { grid })
        this.externalModel = false
        this.$router.push({name: 'VirtualGrid', params: { gridUri, statefulViewUri: view.uri }})
      } finally {
        this.loading = false
      }
    }
  }
}
</script>