<template>
<v-menu
  v-model="menu"
  :close-on-content-click="false"
>
  <template v-slot:activator="{ on }">
    <v-text-field
      v-model="fileUrl"
      @blur="$emit('blur')"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:append>
        <v-btn icon>
          <v-icon v-on="on">mdi-file-image-plus-outline</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </template>

  <FilePond
    class="pointer"
    :unauthenticated="true"
    :label-idle="$t('attachments.pondLabel')"
    :allow-multiple="false"
    :disableRename="fixedFileName"
    :existingFiles="files"
    @fileUploaded="onFileUploaded"
    @fileDeleted="onFileDeleted"
  />
</v-menu>
</template>

<script>
import FilePond from '@/components/attachments/FilePond.vue'

export default {
  props: ['value', 'fixedFileName'],
  data() {
    return {
      menu: false
    }
  },
  computed: {
    fileUrl: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    files() {
      return this.fileUrl ? [{url: this.fileUrl, name: this.fixedFileName}] : []
    }
  },
  methods: {
    onFileUploaded(files) {
      this.$emit('input', files[0].url)
      this.$emit('blur')
      this.menu = false
      console.log(files)
    },
    onFileDeleted() {
      this.$emit('input', undefined)
      this.$emit('blur')
    }
  },
  components: {
    FilePond
  }
}
</script>
