<template>
  <v-dialog
    v-model="externalModel"
    scrollable
    max-width="680px"
    transition="dialog-transition"
    
  >
  <template v-slot:activator="{ on, attrs }">
      <div @click="addLink" v-bind="attrs" v-on="on">
        <slot name="prefillFormEditor"></slot>
      </div>
    </template>

    <v-card rounded>
        <v-card-title primary-title>
            <div>{{ $t("forms.shareForm.prefillEditor.title") }}</div>
        </v-card-title>
        <v-card-subtitle class="pt-2">
            {{ $t("forms.shareForm.prefillEditor.subtitle") }}
        </v-card-subtitle>
        <v-divider></v-divider>
       
        <v-card-text>
            <v-row>
            <v-col cols="8">
                <v-row
                    v-for="component in formComponents"
                    v-bind:key="component.fieldId"
                    class="ma-3"
                >
                <FormFieldsFactory
                    class="full-width"
                    :key="component.fieldId"
                    :component="settingsComponent(component)"
                    :form="form"
                    v-model="prefilledValues[component.fieldId]"
                    :fieldStyle="fieldStyle"
                    :hidden="false"
                    data-testid="builder-field-defaultValue-input"
                ></FormFieldsFactory>
                </v-row>
            </v-col> 
            <v-col cols="4">
                <QRCode class="ma-4" codeWidth="100%" v-if="uri" :text="qrCodeValue"/>
            </v-col>       
        </v-row>      
  
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn @click="externalModel = false" text class="mr-5" color="accent">{{ $t("forms.shareForm.prefillEditor.close") }}</v-btn>
            <v-spacer></v-spacer>
            <v-sheet elevation="0" width="100%" rounded class="mt-2 mb-2 pa-1 grey lighten-4 one-line d-flex justify-space-between ">
              <input ref="linkInput"  class="full-width" readonly id="url" :value="prefilledUrl" />
              <v-btn color="grey lighten-2"  depressed small @click="copyPrefilledUrl()">
                <v-icon small class="mr-1">mdi-content-copy</v-icon>
                {{ $t("forms.shareForm.buttons.copy") }}
              </v-btn>
            </v-sheet>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormFieldsFactory from './FormFieldsFactory.vue'
import FormFieldStyles from '@/constants/formFieldStyles.js'
import { sharedLinksMethods } from '@/mixins/sharedLinksMethods.js'
import {columnTypes} from '@/constants/columnTypes.js'
import QRCode from '@/components/QRCode.vue'
import { base64EncodeUrl } from '@/utils/base64Url.js'
import externalModel from '../../mixins/externalModel'

export default {
    props: ['formUri', 'gridUri' , 'disabled', 'uri'],
    mixins: [sharedLinksMethods, externalModel],
    data() {
        return {
            prefilledValues: {},
            timeoutID: null,
            qrCodeValue: null
        }
    },
    watch: {
        prefilledUrl: {
          handler(newVal) {
            if (this.timeoutID) {
              clearTimeout(this.timeoutID) // Clear the existing timer if the prop changes before .4 seconds
            }
            this.timeoutID = setTimeout(() => {
              this.qrCodeValue = newVal
            }, 400) // Wait for .4 seconds
          }
        }
    },
    computed: {
        form() {
            return this.$store.getters.formWithUri(this.formUri)
        },
        formComponents () {
            return this.form.components
                        .filter( comp => {
                            const columnName = this.form.fieldWith(comp.fieldId).columnType.name
                            return  columnName != columnTypes.crossReference.name &&
                                    columnName != columnTypes.references.name })
        },
        
        fieldStyle() {
            return FormFieldStyles.default
        },
        prefilledUrl() {
            const data = JSON.stringify(this.prefilledValues)
            return this.sharedUrl() + '?data=' + base64EncodeUrl(data)
        }
    },
    methods: {
        settingsComponent(component) {
            const comp = Object.assign( {} , component )
            comp.settingMode = true
            return  comp
        },
        copyPrefilledUrl() {
            navigator.clipboard.writeText(this.prefilledUrl)
        },
         addLink() {
            if(this.disabled || this.uri) {
                return
            }
            this.$store
                .dispatch('AGWriteAddFormEntityOperation', {
                 formUri: this.formUri,
                 gridUri: this.gridUri
                })
                 .then(response => {
                    this.uri = response.data['uri']
                })
            },
    },
    components: { FormFieldsFactory, QRCode }
}
</script>

<style>
.full-width {
  outline: none;
  width: 100%;
}
</style>