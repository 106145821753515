<template>
  <div v-if="form.saveProgress">
    <div class="mr-2">
      <v-btn
        v-if="hasLocalChanges && state"
        plain
        @click="state.next"
        :loading="state == states.SAVEING"
        :color="state.color"
        >
          <v-icon v-if="state.icon" dense class="mr-2">{{state.icon}}</v-icon>
          <span >{{state.title}}</span>
        </v-btn>
        <v-btn 
          v-if="hasSavedData" 
          dense class="mr-2" icon 
          @click="showRestoreState = true">
            <v-icon color="accent lighten-3" dense>mdi-history</v-icon>
          </v-btn>
        </div>
      <RestoreSavedProgress :show="state == states.SAVED || showRestoreState" :form="form"/>
</div>

</template>

<script>
import RestoreSavedProgress from '@/components/form/RestoreSavedProgress.vue'

export default {
  props: [ 'form' ],
  data() {
    return {
      showRestoreState: false,
      states: {
        toState: (state) => {
          this.state = state
          this.state.enter()
        },
        INITIAL: {
          title: this.$t('forms.builder.saveForLater.formButtonTitle'),
          color: 'accent',
          enter: () => {},
          next: () => {
            this.states.toState(this.states.SAVEING)
          }
        },
        SAVEING: {
          title: '',
          color: 'primary',
          enter: () => {
            this.saveProgress()
          },
          next: () => {
            this.states.toState(this.states.SAVED)
          }
        },
        SAVED: {
          title: this.$t('forms.builder.saveForLater.formSuccessButtonTitle'),
          color: 'success',
          icon: 'mdi-check',
          enter: () => {
            setTimeout(this.state.next, 10000)
          },
          next: () => {
            this.states.toState(this.states.INITIAL)
          }
        },
      },
      state: null,
    }
  },
  mounted() {
    this.states.toState(this.states.INITIAL)
  },
  computed: {
    hasLocalChanges() {
      return Object.keys(this.form._formData()).length != 0
    },
    hasSavedData() {
      return Object.keys(this.form.localData).length != 0
    }
  },
  methods: {
    saveProgress() {
      // save in local store
      this.form.saveLocalData()
      setTimeout(this.state.next, 1000)
    },
  },
  components: {
    RestoreSavedProgress
  }
}
</script>

<style lang="css" scoped>
</style>
