<template>
  <BaseFormInput v-bind="$props">
    <v-text-field
      type="text"
      step="any"
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema, rules.decimal]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      :value="internalValue"
      @input="handleInput"
      @blur="finishedEditing"
      :color="color"
      :disabled="disabled"
      v-bind="fieldStyle"
    >
    </v-text-field>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import currencyInputMixin from '@/mixins/currencyInputMixin.js'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  mixins: [formFields, currencyInputMixin],
  props: ['field'],
  components: {
    BaseFormInput
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
