<template>
  <div>
    <!-- <div class="d-flex justify-end align-center">
      <span class="text-caption mr-1">Map value</span>
      <v-switch
        v-model="map"
        dense
        hide-details
        class="ma-0"
      />
    </div> -->
    <TemplateInput
      v-if="map"
      v-model="externalModel"
      v-bind="$attrs"
      @blur="$emit('blur')"
    />
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
import TemplateInput from '@/components/flow/TemplateInput.vue'
import externalModel from '@/mixins/externalModel'

  export default {
    mixins: [externalModel],
    data() {
      return {
        map: false
      }
    },
    components: { TemplateInput }
}
</script>

<style lang="scss" scoped>

</style>