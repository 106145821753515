<template>
  <v-sheet min-height="82" elevation="4" rounded v-if="deleteFlowState" class="pa-2 mb-3" ref="item" @mouseenter="() => (controls = true)"
    @mouseleave="() => (controls = false)">
    <v-row no-gutters class="align-center">
      <v-col style="cursor: pointer;" @click="navigateTo(flow.uri)" cols="10">

        <v-row no-gutters class="mb-1">
          <EditableText v-if="flow" class="font-weight-medium subtitle-1  textOverflow pa-1" ref="nameLabel" :text="flowName"
            @click.native="onTitleClick()" @submit="(newName) => renameFlow(flow, newName)"
            data-testid="headerCell-text" />
          <v-skeleton-loader width="100px" v-else type="heading" class="mt-2" />
        </v-row>

        <v-row no-gutters class="align-center mb-1">
          <v-col cols="auto" class="mr-auto">
            <div class="d-flex ml-1">
              <FlowNodeIcon
                v-for="(node, index) in nodes"
                :key="index"
                class="align-center mr-1"
                size="22"
                :nodeType="node"
              />
            </div>
          </v-col>
          <v-col cols="auto align-center">
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="2">
        <v-row no-gutters class="align-center justify-end">
          <v-btn v-show="controls" class="edit-button" icon text :color="deleteFlowState.color"
            @click.prevent="() => deleteFlowState.action(flow)">
            <v-icon small>{{ deleteFlowState.icon }}</v-icon>
          </v-btn>
          <v-switch v-if="flow" v-model="flow.enabled" @change="saveFlow" :disabled="!canEnableFlow" />
        </v-row>
      </v-col>

    </v-row>
  </v-sheet>
</template>
  
<script>
import EditableText from '@/components/EditableText.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import errorBus from '@/utils/errorBus.js'
import FlowNodeIcon from '@/components/flow/FlowNodeIcon.vue'

export default {
  props: ['flowUri'],
  data() {
    return {
      controls: false,
      clickTimeout: undefined,
      deleteFlowState: null,
      deleteFlowStates: {
        shouldDelete: {
          action: () => {
            this.deleteFlowState = this.deleteFlowStates.doDelete
          },
          icon: 'mdi-delete',
          color: 'accent'
        },
        doDelete: {
          action: (flow) => this.deleteFlow(flow),
          icon: 'mdi-delete-empty-outline',
          color: 'destructive'
        }
      }
    }
  },
  async mounted() {
    this.deleteFlowState = this.deleteFlowStates.shouldDelete
    await this.$store.dispatch('AGReadFlowOperation', this.flowUri)
  },
  computed: {
    flow() {
      return this.$store.getters.flowWithUri(this.flowUri)
    },
    flowName() {
      return this.flow?.name ?? ''
    },
    nodes() {
      return this.flow?.nodes ?? []
    },
    trigger() {
      return this.flow.trigger
    },
    canEnableFlow() {
      return hasPermission(this.flow, [PERMISSIONS.patch])
    }
  },
  methods: {
    navigateTo(flowUri) {
      this.$router.push({ name: 'FlowEditor', params: { flowUri: flowUri } })
    },
    async saveFlow() {
      await  this.$store.dispatch('AGPatchFlowOperation', {
       flow: this.flow,
       enabled: this.flow.enabled
      })
    },
    async renameFlow(flow, newName) {
      const fullFlow = await this.$store.dispatch(
        'AGReadFlowOperation',
        flow.uri
      )
      if (!hasPermission(fullFlow, [PERMISSIONS.patch])) {
        errorBus.$emit('ERROR', {
          message: this.$t('errorMessages.notAllowed')
        })
        return
      }
      await fullFlow.rename(newName)
      this.$emit('loadFlows')
    },
    async deleteFlow(flow) {
      const fullFlow = await this.$store.dispatch(
        'AGReadFlowOperation',
        flow.uri
      )
      if (!hasPermission(fullFlow, [PERMISSIONS.remove])) {
        errorBus.$emit('ERROR', {
          message: this.$t('errorMessages.notAllowed')
        })
        return
      }
      await this.$store.dispatch('AGDeleteFlowOperation', { flow: fullFlow })
      this.$emit('loadFlows')
    },
    onTitleClick() {
      const editableText = this.$refs.nameLabel
      if (editableText.editMode) {
        return
      }
      // Delaying normal click action to eventually allow a double click
      if (this.clickTimeout != null) {
        clearTimeout(this.clickTimeout)
        this.clickTimeout = undefined
        return
      }
      this.clickTimeout = setTimeout(() => {
        this.clickTimeout = undefined
        this.$refs.item.click({})
      }, 200)
    }
  },
  components: {
    EditableText,
    FlowNodeIcon
  }
}
</script>
  
<style></style>