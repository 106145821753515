<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    content-class="scrollable-menu"
    nudge-width="180"
  >
    <template v-slot:activator="{ on }">
      <MenuButton
        v-on="on"
        icon="mdi-cog"
        :text="$t('gallery.settings.menuButton')"
      />
    </template>
    <v-card class="pl-4 pb-4 pt-2">
      <div class="d-flex align-center justify-space-between mb-2">
      <span class="subtitle-2 accent--text">{{$t('gallery.settings.imageMode')}}</span>
      
      <v-menu offset-y v-model="imageModeMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click.prevent="on.click"
            small
            :disabled="disabled"
          > 
            <span class="subtitle-2">{{ $t(`gallery.settings.${imageMode}`) }}</span>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item  v-for="(mode, index) in imageModes" :key="index" @click="imageMode = mode ">
            {{ $t(`gallery.settings.${mode}`) }}
          </v-list-item>
        </v-list>
      </v-menu>

      </div>

      <div class="d-flex align-center justify-space-between mb-2">
      <span class="subtitle-2 accent--text">{{$t('gallery.settings.cardSize')}}</span>
      
      <v-menu offset-y v-model="cardSizeMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click.prevent="on.click"
            small
            :disabled="disabled"
          > 
            <span class="subtitle-2">{{ $t(`gallery.settings.${cardSize}`) }}</span>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item  v-for="(size, index) in cardSizes" :key="index" @click="cardSize = size ">
            {{ $t(`gallery.settings.${size}`) }}
          </v-list-item>
        </v-list>
      </v-menu>
      </div>
      <div class="d-flex align-center justify-space-between mb-2">
        <span class="subtitle-2 accent--text">{{$t('gallery.settings.fieldIcon')}}</span>
        <v-switch
        class="mt-0 pt-0"
        dense inset 
        v-model="showTypeIcon"
        hide-details
        :disabled="disabled"
        hide-label>
          
        </v-switch>
      </div>
      <CardFieldsSettings :virtualGrid="virtualGrid" :statefulViewUri="statefulViewUri" :disabled="disabled" ></CardFieldsSettings>
    </v-card>
  </v-menu>
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'
import { GALLERY_CARD_IMAGE_MODES, GALLERY_CARD_SIZES  } from '@/constants/galleryCardTypes.js'
import CardFieldsSettings from '@/components/CardFieldsSettings.vue'

export default {
  props: ['virtualGrid', 'statefulViewUri', 'disabled'],
  data() {
    return {
      fields: [],
      shownFieldIds: [],
      value: null,
      imageModes: GALLERY_CARD_IMAGE_MODES,
      imageModeMenu: null,
      cardSizes: GALLERY_CARD_SIZES,
      cardSizeMenu: null
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    imageMode: {
      get() {
        return this.statefulView.imageMode || GALLERY_CARD_IMAGE_MODES[0]
      },
      set(value) {
        this.statefulView.imageMode = value
        this.statefulView.saveProperties()
      }
    },
    cardSize: {
      get() {
        return this.statefulView.cardSize || GALLERY_CARD_SIZES[0]
      },
      set(value) {
        this.statefulView.cardSize = value
        this.statefulView.saveProperties()
      }
    },
    showTypeIcon: {
      get() {
        return !this.statefulView.hideTypeIcon || true
      },
      set(value) {
        this.statefulView.hideTypeIcon = !value
        this.statefulView.saveProperties()
      }
    }

  },
  components: {
    MenuButton,
    CardFieldsSettings
  }
}
</script>

<style>
.select {
  max-width: 100px;
}

.scrollable-menu {
  max-height: 80%;
}

.cursor-grab {
    cursor: -webkit-grab;
    cursor: grab;
}
</style>