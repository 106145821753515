/* global ASParser */
import {EditorState, TextSelection} from 'prosemirror-state'
import {EditorView} from 'prosemirror-view'
import {Schema, DOMParser, Slice} from 'prosemirror-model'
import { inputRules, InputRule } from 'prosemirror-inputrules'
import { baseKeymap } from 'prosemirror-commands'
import {keymap} from 'prosemirror-keymap'
import { schema } from 'prosemirror-schema-basic'
import 'prosemirror-view/style/prosemirror.css'

import Token from '@/components/flow/Token.vue'
import Parameter from '@/components/flow/Parameter.vue'
import Vue from 'vue'

import { parseExpression } from '@/constants/expressions.js'
import { AGStepOutput, AGFlowGlobalVariableExpression } from '@/constants/expressions/index.js'
import apptiveErrorReporter from '@/plugins/apptiveErrorReporter'
import { annotateStepOutputTokens, ExpressionTokenDisplayVisitor } from '@/apptivescript/apptivescriptUtils'
import i18n from '@/plugins/i18n'

const tokenSpec = {
  attrs: {
    value: {default: 'unknownExpression'},
    text: {default: 'unknownExpression'},
    color: {default: 'red'},
  },
  inline: true,
  group: 'inline',
  draggable: true,

  toDOM: node => {
    const element = document.createElement('div')
    const component = new Vue({
      render: h =>h(Token, {props: {text: node.attrs.text, value: node.attrs.value, color: node.attrs.color}})
    }).$mount(element)
    return component.$el
  },
  parseDOM: [{
    tag: 'button[data-value]',
    getAttrs: dom => {
      let value = dom.getAttribute('data-value')
      let color = dom.getAttribute('data-color')
      let text = dom.textContent
      return {
        value,
        text,
        color
      }
    }
  }],
  leafText: node => {
    return node.attrs.value
  }
}

const parameterSpec = {
  attrs: {
    parameterType: {default: 'unknownExpression'},
    text: {default: 'unknownExpression'},
    color: {default: 'red'},
  },
  inline: true,
  group: 'inline',
  content: 'text*',

  toDOM: node => {
    const element = document.createElement('div')
    const component = new Vue({
      render: h =>h(Parameter, {props: {
        text: node.attrs.text,
        parameterType: node.attrs.parameterType,
        color: node.attrs.color
      }})
    }).$mount(element)
    return component.$el
  },
  parseDOM: [{
    tag: 'span[data-type]',
    getAttrs: dom => {
      let parameterType = dom.getAttribute('data-type')
      let color = dom.getAttribute('data-color')
      let value = dom.getAttribute('data-value')
      let text = dom.textContent
      return {
        parameterType,
        text,
        value,
        color
      }
    }
  }],
  toApptiveScript: node => {
    return node.attrs.value
  }
}

class ParameterView {
  constructor(node) {
    const element = document.createElement('div')
    const component = new Vue({
      render: h =>h(Parameter, {props: {
        text: node.attrs.text,
        parameterType: node.attrs.parameterType,
        color: node.attrs.color
      }})
    }).$mount(element)
    this.dom = this.contentDOM = component.$el
  }

  update(node) {
    if (node.type.name != 'parameter') return false
    node.attrs.text = node.content.content[0]?.text ?? ''
    const isString = node.attrs.parameterType === 'ASString'
    node.attrs.value = isString ? `'${node.attrs.text}'` : node.attrs.text
    return true
  }
}

const paragraphSpec = {
  content: 'inline*',
  marks: '',
  group: 'block',
  code: true,
  defining: true,
  parseDOM: [{tag: 'p', preserveWhitespace: 'full'}],
  toDOM() { return ['p', 0] }
}

const nodesSchema = new Schema({
  nodes: schema.spec.nodes
    .update('paragraph', paragraphSpec)
    .addBefore('button', 'token', tokenSpec)
    .addBefore('div', 'parameter', parameterSpec)
})

export function isExpression(text) {
  const expression = parseExpression(text)
  return (expression instanceof AGStepOutput || expression instanceof AGFlowGlobalVariableExpression)
}

export function nodeToApptiveScript(node) {
  if (node.isText)
    return node.text

  if (node.isLeaf) {
    return node.type.spec.leafText(node)
  }

  let joiner = node.content.content[0]?.isBlock ? '\n\n' : ''
  let text = node.content.content.map(c => nodeToApptiveScript(c)).join(joiner)
  if (!node.type) // just a slice
    return text
  if (node.type.spec.toApptiveScript)
    return node.type.spec.toApptiveScript(node)
  return text
}








export default class Editor {
  constructor({flow, editorViewNode, contentNode, domEventHandlers, multiline = false}) {
    this.editorView = this.initEditor({flow, editorViewNode, contentNode, domEventHandlers, multiline})
    this.contentNode = contentNode
    this.multiline = multiline
    this.flow = flow
  }

  initEditor({flow, editorViewNode, contentNode, domEventHandlers, multiline = false}) {

    const keyMapCopy = {...baseKeymap}
    if (!multiline) {
      delete keyMapCopy.Enter
    }
    const editorKeymap = keymap(keyMapCopy)
  
    return new EditorView(editorViewNode, {
      state: EditorState.create({
        doc: DOMParser.fromSchema(nodesSchema)
          .parse(contentNode, {preserveWhitespace: multiline ? 'full' : true}),
        plugins: [
          inputRules({
            rules: [new InputRule(
              new RegExp('({{.+?}})'),
              (state, match, start, end) => {
                const tokenType = nodesSchema.nodes.token
                const tokenAttrs = this.constructor.tokenPropsFromString(match[1], flow)
                if (tokenAttrs == null) {
                  return undefined
                }
                const newToken = tokenType.create(tokenAttrs)
                return state.tr
                  .replaceWith(start, end, newToken)
              }
            )]
          }),
          editorKeymap
        ]
      }),
      handleDOMEvents: domEventHandlers,
      clipboardTextSerializer(slice) {
        return nodeToApptiveScript(slice)
      },
      nodeViews: {
        parameter(node) {
          return new ParameterView(node)
        }
      }
    })
  }

static tokenPropsFromString(text, flow) {
  if (!text.startsWith('{{') || !text.endsWith('}}')) {
    return text
  }
  let color = 'blue'
  let pathString = i18n.t('expressions.unknown')
  const expression = text.slice(2, text.length - 2)
  if (!expression.trim()) {
    return [{
      text: i18n.t('expressions.empty'),
      value: text,
      color: 'lightgray'
    }]
  }
  try {
    const ast = ASParser.as_parse_(expression)
    annotateStepOutputTokens(ast)
    const visitor = new ExpressionTokenDisplayVisitor(flow)
    visitor.as_visit_(ast)
    color = visitor.color || color
    pathString = visitor.pathString
  } catch(error) {
    color = 'red'
    pathString = 'Invalid Expression'
    apptiveErrorReporter.captureException(error)
  }
  return [{
    text: pathString,
    value: text,
    color: color
  }]
}

  clearContent() {
    const transaction = this.editorView.state.tr
    transaction.setSelection(TextSelection.create(this.editorView.state.doc, 0, this.editorView.state.doc.content.size))
    this.editorView.dispatch(transaction.deleteSelection())
  }

  refreshContent() {
    const document = DOMParser.fromSchema(nodesSchema)
          .parse(this.contentNode, {preserveWhitespace: this.multiline ? 'full' : true})
    const state = this.editorView.state
    this.editorView.dispatch(state.tr.replace(0, state.doc.content.size, new Slice(document.content, 0, 0)))
  }

  insertExpression(string) {
    const tokenPropsArray = this.constructor.tokenPropsFromString(string, this.flow)
    tokenPropsArray.forEach(token => this.insertToken(token))
  }

  insertExpressionAt(string, position) {
    const insideExpression = this.isInsideExpression(position)
    const tokenPropsArray = this.constructor.tokenPropsFromString(string, this.flow, insideExpression)
    tokenPropsArray
      .reverse()
      .forEach(tokenProps => {
        const transaction = this.insertTokenAtTransaction(tokenProps, position)
        transaction(this.editorView.state, this.editorView.dispatch)
      })
  }

  insertText(string) {
    const transaction = this.editorView.state.tr.insertText(string)
    this.editorView.dispatch(transaction)
  }

  insertToken(tokenProps) {
    const transaction = this.insertTokenTransaction(tokenProps)
    transaction(this.editorView.state, this.editorView.dispatch)
  }

  insertTokenTransaction(tokenProps) {
    const tokenType = 'parameterType' in tokenProps ? nodesSchema.nodes.parameter : nodesSchema.nodes.token
    return function(state, dispatch) {
      let {$from} = state.selection, index = $from.index()
      if (!$from.parent.canReplaceWith(index, index, tokenType))
        return false
      if (dispatch) {
        const newToken = tokenType.create({...tokenProps})
        dispatch(state.tr.replaceSelectionWith(newToken))
      }
      return true
    }
  }


  insertTokenAtTransaction(tokenProps, position) {
    const tokenType = 'parameterType' in tokenProps ? nodesSchema.nodes.parameter : nodesSchema.nodes.token
    return function(state, dispatch) {
      if (dispatch) {
        const newToken = tokenType.create({...tokenProps})
        dispatch(state.tr.insert(position.pos, newToken))
      }
      return true
    }
  }

  isInsideExpression(position) {
    const nodesBefore = []
    this.editorView.state.doc.nodesBetween(0, position.pos, node => nodesBefore.push(node.textContent))
    let hasOpeningBefore
    for(let i = nodesBefore.length - 1 ; hasOpeningBefore == null && i > 0 ; i--) {
      const nodeContent = nodesBefore[i]
      if (nodeContent === '{{') {
        hasOpeningBefore = true
      }
      if (nodeContent === '}}') {
        hasOpeningBefore = false
      }
    }
    hasOpeningBefore = hasOpeningBefore ?? false

    if (!hasOpeningBefore) {
      return false
    }

    const nodesAfter = []
    this.editorView.state.doc.nodesBetween(position.pos, this.editorView.state.doc.content.size - 1, node => nodesAfter.push(node.textContent))
    let hasClosingAfter
    for(let i = 0 ; hasClosingAfter == null && i < nodesAfter.length ; i++) {
      const nodeContent = nodesAfter[i]
      if (nodeContent === '{{') {
        hasClosingAfter = false
      }
      if (nodeContent === '}}') {
        hasClosingAfter = true
      }
    }
    hasClosingAfter = hasClosingAfter ?? false
    return hasClosingAfter
  }

  getContentAsText() {
    return nodeToApptiveScript(this.editorView.state.doc)
  }

  focus() {
    this.editorView.focus()
  }

  replaceExpressionNode(nodePosition, expression) {
    const tokenPropsArray = this.constructor.tokenPropsFromString(`{{${expression}}}`, this.flow)
    const newNode = nodesSchema.nodes.token.create({...tokenPropsArray[0]})
    const transaction = this.editorView.state.tr
    transaction.setSelection(TextSelection.create(this.editorView.state.doc, nodePosition.inside, nodePosition.inside + 1))
    this.editorView.dispatch(transaction.replaceSelectionWith(newNode))
  }

}

