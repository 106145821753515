import HalObject from '@/store/models/HalObject'
import Step from '@/store/models/flow/Step.js'
import store from '@/store/store'

export const instanceStates = {
    'created':'created' ,
    'waiting':'waiting' ,
    'done':'done' ,
    'error':'error' ,
}

export default class FlowInstance extends HalObject {

  constructor(data) {
    super(data)
    this.update(data)
  }

  update(data) {
    // Update properties with new data
    this.createdAt = data.createdAt
    this.state = data.state
    this.steps = data.steps?.map(step => new Step(step)) ?? []
  }

  finished(){
    return this.state === instanceStates.error || this.state === instanceStates.done
  }

  hasError() {
    return this.state === instanceStates.error
  }

  getFlowFormLink() {
    return store().dispatch('AGCreateFlowInstanceLinkOperation',  this )
  }
}
