<template>
  <div class="inter-wrapper">
    <div
      class="add-button d-flex flex-column align-center"
      :style="contentStyle"
      no-gutters
    >
      <v-btn :disabled="disabled" class="plus-section" color="secondary" :loading="loading">
        <v-icon size="16" color="white">mdi-plus</v-icon>
      </v-btn>
      <div class="separator secondary" />
    </div>
  </div>
</template>

<script>
export default {
  inject: ['canAddField'],
  props: {
    showContent: null,
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    contentStyle() {
      return {
        opacity: this.showContent ? 'unset' : 0
      }
    },
    disabled() {
      return !this.canAddField()
    }
  }
}
</script>

<style scoped>
.inter-wrapper {
  position: relative;
}

.add-button {
  width: 32px;
  height: 88px;
  position: absolute;
  top: 0px;
  left: -16px;
  z-index: var(--grid-toolbar-zindex);
  pointer-events: none;
}

.plus-section {
  width: 16px;
  min-width: unset !important;
  height: 16px !important;
  padding: 0px !important;
  border-radius: 20%;
  pointer-events: all;
}

.separator {
  width: 2px;
  transform: translateX(-0.5px);
  height: 72px;
}
</style>
