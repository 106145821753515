import { render, staticRenderFns } from "./GridHeaderAddColumnCell.vue?vue&type=template&id=20225f68&scoped=true&"
import script from "./GridHeaderAddColumnCell.vue?vue&type=script&lang=js&"
export * from "./GridHeaderAddColumnCell.vue?vue&type=script&lang=js&"
import style0 from "./GridHeaderAddColumnCell.vue?vue&type=style&index=0&id=20225f68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20225f68",
  null
  
)

export default component.exports