<template>
    <div class="d-flex flex-column ma-6">
        <v-sheet rounded elevation="2" color="white" class="pa-10">
            <div class="text-h6">{{ $t('settings.generalSpaceSettings.title') }}</div>
            <v-divider class="mt-2 mb-4"></v-divider>
            <div v-if="owner" class="d-flex flex-column gap-3">
                <v-subheader>{{ $t('roles.owner') }}</v-subheader>
                <v-list-item>
                    <v-list-item-avatar>
                        <Avatar :size="36" :userId="owner.id" :email="owner.email" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ owner.firstName }} {{ owner.lastName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ owner.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-subheader>{{ $t('settings.generalSpaceSettings.info') }}</v-subheader>

                <span class="subtitle-2 ">
                    {{ $t('settings.generalSpaceSettings.availableSeats') }} <v-chip x-small color="primary"
                        text-color="white">
                        <b class="text-capitalize">{{ availableSeats }}</b>
                    </v-chip>
                </span>
                <span class="body-2 text--secondary">
                    {{ $t('settings.generalSpaceSettings.availableSeatsDescription') }}
                </span>
                <div v-show="canUpdatePlan">
                    <v-btn text v-if="paymentConfig" :href="prefilledCustomerPortalLink" target="_blank" color="primary">
                        {{ $t('settings.generalSpaceSettings.changeAvailableSeatsButton') }}
                        <v-icon class="ml-2" small>mdi-share</v-icon>
                    </v-btn>
                </div>

                <span class="subtitle-2">

                    {{ $t('settings.generalSpaceSettings.spacePlan') }} <v-chip x-small color="primary" text-color="white">
                        <b class="text-capitalize">{{ plan }}</b>
                    </v-chip>
                </span>

                <span class="body-2 text--secondary">
                    {{ $t('settings.generalSpaceSettings.spacePlanDescription') }}
                </span>
                <div v-show="canUpdatePlan">
                    <v-btn text v-if="paymentConfig" :href="prefilledCustomerPortalLink" target="_blank" color="primary">
                        {{ $t('settings.generalSpaceSettings.changePlanButton') }}
                        <v-icon class="ml-2" small>mdi-share</v-icon>
                    </v-btn>
                </div>

                <span class="body-2 text--secondary">
                    {{ $t('settings.generalSpaceSettings.spaceId') }}: {{ spaceId }}
                </span>
            </div>
        </v-sheet>
    </div>
</template>

<script>
import Avatar from '@/components/user/Avatar.vue'

export default {
    props: ['spaceUri'],
    data: () => ({
        paymentConfig: null
    }),
    async mounted() {
        this.paymentConfig = this.$settings.payment
    },
    computed: {
        canUpdatePlan() {
            return this.space?.owner.id == this.user?.id
        },
        owner() {
            return this.space?.owner
        },
        user() {
            return this.$store.state.user?.user
        },
        space() {
            return this.$store.getters.spaceWithUri(this.spaceUri)
        },
        spaceId() {
            return this.space?.id
        },
        availableSeats() {
            return this.space?.availableSeats
        },
        plan() {
            return this.space?.plan
        },
        prefilledCustomerPortalLink() {
            return `${this.paymentConfig.customerPortal}?prefilled_email=${this.user.email}`
        },
    },
    components: {
        Avatar
    }
}
</script>

<style></style>