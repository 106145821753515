<template>
  <BaseStatefulView
    class="white"
    :persistentGridUri="gridUri"
    :virtualGridUri="virtualGridUri"
    :viewTitle="statefulView ? statefulView.name : ''"
    @reloadView="reloadView"
    :embedded="embedded"
  >
    <template
      v-slot:toolbar-left
      v-if="!loading && virtualGrid"
      v-show="!$vuetify.breakpoint.mobile"
    >
      <MenuButton
        @click="includingUnsortedEntities = !includingUnsortedEntities"
        icon="mdi-eye-off"
        :text="
          includingUnsortedEntities
            ? $t('kanban.hideUnstackedButton')
            : $t('kanban.unstackedButton')
        "
      />
      <KanbanSettingsMenu
        v-if="canPatchView"
        v-model="stateFieldId"
        :virtualGrid="virtualGrid"
        :statefulViewUri="statefulViewUri"
        :disabled="!canPatchView"
      />
      <VirtualGridToolbar
        :virtualGrid="virtualGrid"
        :statefulViewUri="statefulViewUri"
        @filterMouseEnter="highlightFilteredColumns = true"
        @filterMouseLeave="highlightFilteredColumns = false"
      />
    </template>
    <div
      v-if="!loading && virtualGrid && !stateFieldId"
      class="d-flex full-height justify-center align-center"
    >
      <KanbanStateSelection
        v-if="canPatchView"
        :virtualGrid="virtualGrid"
        v-model="stateFieldId"
      />
    </div>
    <div v-if="!loading && virtualGrid" class="kanban-board d-flex">
      <KanbanColumn
        v-for="(column, index) in kanbanModel"
        :key="column.title"
        :index="index"
        :title="column.title"
        :tasks="column.tasks"
        :addButtonDisabled="!canAddEntity || !showAddEntityButton"
        @startDragging="showAddEntityButton = false"
        @stoppedDragging="showAddEntityButton = true"
        @change="(change) => handleListChanged(column, change)"
        @entityClicked="editEntity"
        @addButtonClicked="() => addCard(column)"
      />
    </div>
    <FullHeightLoader v-model="loading" />
    <EditEntityDialog
      :virtualGrid="virtualGrid"
      :entity="selectedEntity"
      v-model="editEntityDialog"
    />
  </BaseStatefulView>
</template>

<script>
import BaseStatefulView from '@/views/BaseStatefulView.vue'
import KanbanColumn from '@/components/kanbanView/KanbanColumn.vue'
import KanbanStateSelection from '@/components/kanbanView/KanbanStateSelection.vue'
import KanbanSettingsMenu from '@/components/kanbanView/KanbanSettingsMenu.vue'
import FullHeightLoader from '@/components/FullHeightLoader.vue'
import EditEntityDialog from '@/components/gridView/EditEntityDialog.vue'
import VirtualGridToolbar from '@/components/gridView/VirtualGridToolbar.vue'
import MenuButton from '@/components/MenuButton.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'

export default {
  props: ['gridUri', 'statefulViewUri', 'embedded'],
  data() {
    return {
      columnScrollPosition: [],
      loading: false,
      stateLoading: false,
      selectedEntity: undefined,
      editEntityDialog: false,
      includingUnsortedEntities: false,
      showAddEntityButton: true,
    }
  },
  beforeDestroy() {
    this.virtualGrid.setLocalEntityParams(undefined)
  },
  watch: {
    statefulViewUri: {
      immediate: true,
      async handler() {
        this.loading = true
        try {
          const statefulView = await this.$store.dispatch(
            'AGReadStatefulViewOperation',
            this.statefulViewUri
          )
          await this.$store.dispatch('AGReadVirtualGridOperation', {
            virtualGridUri: statefulView.parentGridUri,
            loadEntities: false
          })
          statefulView.initShownFields()
          await this.$nextTick()
          this.virtualGrid.setLocalEntityParams(statefulView.entityParams())
          this.virtualGrid.loadEntities()
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    stateFieldId: {
      get() {
        return this.statefulView?.stateFieldId
      },
      set(newVal) {
        if (this.stateLoading) {
          return
        }
        this.stateLoading = true
        this.statefulView
          .setStateFieldId(newVal)
          .finally(() => (this.stateLoading = false))
      }
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    virtualGrid() {
      return this.statefulView?.parentGrid
    },
    virtualGridUri() {
      return this.statefulView?.parentGridUri
    },
    canAddEntity() {
      return hasPermission(this.grid, [PERMISSIONS.addEntity])
    },
    canPatchView() {
      return hasPermission(this.statefulView, [PERMISSIONS.patch])
    },
    kanbanModel() {
      if (!this.virtualGrid || !this.statefulView) {
        return
      }
      return this.statefulView.kanbanModel(
        this.virtualGrid,
        this.includingUnsortedEntities
      )
    },
    canUpdateFieldKey() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.updateFieldKey])
    }
  },
  provide() {
    return {
      canUpdateFieldKey: () => this.canUpdateFieldKey
    }
  },
  methods: {
    handleListChanged(column, change) {
      if (change.added) {
        let entityId = change.added.element.entity._id
        this.virtualGrid.entityChanged(
          change.added.element.entity,
          column.state,
          change.added.element.stateField
        )
        this.virtualGrid.updateEntity(change.added.element.entity)
        this.statefulView.reorderEntity(entityId, change.added.newIndex , column.tasks)
      }
      else if (change.moved) {
        let entityId = change.moved.element.entity._id
        this.statefulView.reorderEntity(entityId, change.moved.newIndex, column.tasks)
      }
    },
    async addCard(column) {
      const entity = this.virtualGrid.emptyEntity()
      entity.fields[this.virtualGrid.positionOf(column.stateField)] =
        column.state
      const fetchedEntity = await this.virtualGrid.addEntity(entity)
      this.editEntity(fetchedEntity)
    },
    onMoveCallback(evt) {
      // prevent dragging inside the same list
      if (
        evt.from === evt.to &&
        evt.draggedContext.index !== evt.draggedContext.futureIndex
      ) {
        return false
      }
    },
    reloadView() {
      this.virtualGrid.reload()
      this.statefulView.reload()
    },
    editEntity(entity) {
      this.selectedEntity = entity
      this.editEntityDialog = true
    }
  },
  components: {
    BaseStatefulView,
    KanbanColumn,
    FullHeightLoader,
    EditEntityDialog,
    KanbanStateSelection,
    KanbanSettingsMenu,
    VirtualGridToolbar,
    MenuButton
  }
}
</script>

<style scoped>
.kanban-board {
  overflow-x: scroll;
}

.full-height {
  height: 100%;
  background-color: white;

}
</style>