<template>
  <BaseFormInput v-bind="$props" forceLabel>
    <v-input
      v-model="inputValue"
      :rules="[rules.required, rules.schema]"
      :hide-details="description || errors ? 'auto' : false"
    >
      <EntityPicker
        class="pt-3"
        v-model="inputValue"
        :gridUri="gridUri"
        :readonly="disabled"
        :isCollection="isCollection"
        :color="color"
        :disabled="disabled"
        :class="fieldStyle.class"
        :paywall="false"
        :fieldName="fieldName"
        :editOnClick="true"
        :showAddEntry="false"
     />
    </v-input>
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import EntityPicker from '@/components/gridView/EntityPicker.vue'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  mixins: [formFields],
  data() {
    return {
      filter: ''
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    gridUri() {
      return this.fieldType.gridUri
    },
    isCollection() {
      return this.fieldType.jsonSchema.type === 'array'
    }
  },
  components: {
    EntityPicker,
    BaseFormInput
  }
}
</script>
