<template>
  <div class="trigger" :style="style">
    <v-icon :color="iconColor" :size="size / 1.6">{{icon}}</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    trigger: null,
    color: null,
    nodeSize: null
  },
  computed: {
    iconColor() {
      return !this.trigger ? 'white' : this.color
    },
    borderColor() {
      return !this.trigger ? '#ff0000' : this.color
    },
    backgroundColor() {
      return !this.trigger ? '#ff0000' : 'white'
    },
    icon(){
      return !this.trigger ? 'mdi-alert-circle' : 'mdi-webhook'
    },
    size() {
      return this.nodeSize / 2.6
    },
    style() {
      return {
        'border': `2px solid ${this.borderColor}`,
        'background': this.backgroundColor,
        top: `${this.nodeSize / 1.5}px`,
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    }
  }
}
</script>

<style lang="css" scoped>
.trigger {
  border-radius: 50%;
  position: absolute;
  left: 0px;
  opacity: 0.8;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
