<template>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                :dark="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-1">mdi-web</v-icon> 
                {{nativeName(externalModel)}}
                <v-icon class="mr-1">mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                @click="externalModel = item"
              >
                <v-list-item-title>{{ nativeName(item)}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
</template>

<script>
import externalModel from '@/mixins/externalModel'
import languages from '@/constants/languages.js'

export default {
  props: ['items', 'dark'],
  mixins: [externalModel],
  methods: {
    nativeName(languageCode) {
      const language = languages.find(item => item.code === languageCode )
      return language?.nativeName ?? languageCode
    }
  }
}
</script>

<style lang="scss" scoped>

</style>