import i18n from '@/plugins/i18n.js'
import httpOverrides from '@/constants/httpOverrides'
import startNodes from '@/constants/startNodes'

export const nodeTypes = {
  ...startNodes,
  entityAddedEvent: {
    type: 'start',
    typeName: i18n.t('flow.nodes.entityAdded.name'),
    description: i18n.t('flow.nodes.entityAdded.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'entityAddedEvent'
  },
  entityUpdatedEvent: {
    type: 'start',
    typeName: i18n.t('flow.nodes.entityUpdated.name'),
    description: i18n.t('flow.nodes.entityUpdated.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'entityUpdatedEvent'
  },
  entityAdded: {
    type: 'start',
    typeName: i18n.t('flow.nodes.entityAdded.name'),
    description: i18n.t('flow.nodes.entityAdded.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'entityAdded',
    deprecated: true
  },
  entityUpdated: {
    type: 'start',
    typeName: i18n.t('flow.nodes.entityUpdated.name'),
    description: i18n.t('flow.nodes.entityUpdated.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'entityUpdated',
    deprecated: true
  },
  addEntityFormSubmitted: {
    type: 'start',
    typeName: i18n.t('flow.nodes.addEntityFormSubmitted.name'),
    description: i18n.t('flow.nodes.addEntityFormSubmitted.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'addEntityFormSubmitted'
  },
  editEntityFormSubmitted: {
    type: 'start',
    typeName: i18n.t('flow.nodes.editEntityFormSubmitted.name'),
    description: i18n.t('flow.nodes.editEntityFormSubmitted.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
    eventType: 'editEntityFormSubmitted'
  },
  requestTrigger: {
    type: 'start',
    typeName: i18n.t('flow.nodes.requestTrigger.name'),
    description: i18n.t('flow.nodes.requestTrigger.description'),
    icon: 'mdi-webhook',
    graphIcon: 'mdi-webhook',
    color: '#C73A63',
    eventType: 'externalFlowTrigger'
  },
  start: {
    type: 'start',
    typeName: i18n.t('flow.nodes.start.name'),
    description: i18n.t('flow.nodes.start.description'),
    icon: 'mdi-table',
    graphIcon: 'mdi-table',
    color: '#3063BA',
  },
  end: {
    type: 'end',
    typeName: i18n.t('flow.nodes.end.name'),
    description: i18n.t('flow.nodes.end.description'),
    icon: 'mdi-stop',
    graphIcon: 'mdi-stop',
    color: '#3063BA'
  },
  exclusiveGateway: {
    type: 'exclusiveGateway',
    typeName: i18n.t('flow.nodes.exclusiveGateway.name'),
    description: i18n.t('flow.nodes.exclusiveGateway.description'),
    icon: 'mdi-source-branch',
    graphIcon: 'mdi-plus-circle-outline',
    image: 'nodes/router.svg',
    color: '#5DC44D'
  },
  parallel: {
    type: 'parallel',
    typeName: i18n.t('flow.nodes.parallel.name'),
    description: i18n.t('flow.nodes.parallel.description'),
    icon: 'mdi-source-branch',
    graphIcon: 'mdi-plus-circle-outline',
    image: 'nodes/parallel.svg',
    color: '#5DC44D'
  },
  sendEmail: {
    type: 'sendEmail',
    typeName: i18n.t('flow.nodes.sendEmail.name'),
    description: i18n.t('flow.nodes.sendEmail.description'),
    icon: 'mdi-email',
    graphIcon: 'mdi-email-edit-outline',
    color: '#D5513F'
  },
  sendHttpRequest: {
    type: 'sendHttpRequest',
    typeName: i18n.t('flow.nodes.sendHttpRequest.name'),
    description: i18n.t('flow.nodes.sendHttpRequest.description'),
    icon: 'mdi-web',
    graphIcon: 'mdi-web',
    color: '#2295ee'
  },
  updateEntity: {
    type: 'updateEntity',
    typeName: i18n.t('flow.nodes.updateEntity.name'),
    description: i18n.t('flow.nodes.updateEntity.description'),
    icon: 'mdi-table-edit',
    graphIcon: 'mdi-table-edit',
    color: '#3063BA'
  },
  editLink: {
    type: 'editLink',
    typeName: i18n.t('flow.nodes.editLink.name'),
    description: i18n.t('flow.nodes.editLink.description'),
    docuLink: 'https://intercom.help/apptivegrid/de/articles/9145469-vorausgefullter-formular-link',
    icon: 'mdi-form-textbox',
    graphIcon: 'mdi-form-textbox',
    color: '#e54b4b'
  },
  addEntity: {
    type: 'addEntity',
    typeName: i18n.t('flow.nodes.addEntity.name'),
    description: i18n.t('flow.nodes.addEntity.description'),
    icon: 'mdi-table-plus',
    graphIcon: 'mdi-table-plus',
    color: '#3063BA'
  },
  flowForm: {
    type: 'flowForm',
    typeName: i18n.t('flow.nodes.flowForm.name'),
    description: i18n.t('flow.nodes.flowForm.description'),
    icon: 'mdi-file-document-outline',
    graphIcon: 'mdi-file-document-outline',
    color: '#3063BA'
  },
  listViewEntities: {
    type: 'listViewEntities',
    typeName: i18n.t('flow.nodes.listViewEntities.name'),
    description: i18n.t('flow.nodes.listViewEntities.description'),
    icon: 'mdi-table-arrow-down',
    graphIcon: 'mdi-table-arrow-down',
    color: '#3063BA'
  },
  ...httpOverrides
}
