import store from '@/store/store.js'

export const ALPHA_MODE_KEY = 'ALPHA_MODE'
export const DEVELOPER_MODE_KEY = 'DEVELOPER_MODE_KEY'

export default {
  initialize() {
    const alphaMode = localStorage.getItem(ALPHA_MODE_KEY)
    store().commit('setAlphaMode', alphaMode ? JSON.parse(alphaMode) : false)

    const developerMode = localStorage.getItem(DEVELOPER_MODE_KEY)
    store().commit('setDeveloperMode', developerMode ? JSON.parse(developerMode) : false)
  }
}
