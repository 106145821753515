<template>
        <v-dialog
            v-model="dialog"
            scrollable
            :fullscreen="fullscreen"
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="primary"
            rounded outlined
            large
            elevation="5"
            @click="open"
            v-on="on" v-bind="attrs"
            class="ml-2"
            >
                <v-icon data-testid="testRunFlowButton_Waiting" >
                mdi-file-document-outline
                </v-icon>
                <span>Open</span>
            </v-btn>
        </template>
        <v-sheet class="d-flex flex-column">
            <v-toolbar flat >
            <v-spacer></v-spacer>
            <v-btn icon @click="fullscreen = !fullscreen" color="accent"><v-icon>mdi-arrow-expand</v-icon></v-btn>
            <v-btn icon @click="dialog = false" color="accent"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <!-- browser window -->
        <safari-window :url="url" class="ma-3 browser">
            <FlowInstanceLinkView v-if="uri" :uri="uri"></FlowInstanceLinkView>
        </safari-window>
        </v-sheet>

        </v-dialog>
</template>

<script>
import FlowInstanceLinkView from '@/components/FlowInstanceLinkView.vue'
import SafariWindow from '@/components/SafariWindow.vue'

export default {
    props: {
        flow: null
  },

  data() {
    return {
        dialog: false,
        fullscreen: false,
        uri: undefined
    }
  },
  methods: {
    async open() {
      
      if(this.flow.hasFlowFormTrigger() ) {
        // use link that shows the first flow page that will start the flow instance
        this.uri = this.flowTriggerLink
      }
      else {
        // get the flow instance link that is pointing to the next flow node
        const link =  await this.instance.getFlowFormLink() 
        this.uri = link.uri
      }
    },
  },
  computed: {
    url() {
        return window.location.origin + this.uri
    },
    flowTriggerLink() {
      return this.flow?.trigger?.uri
    },
    instance() {
      return this.flow?.testInstance
    },
  },
  components: {
    FlowInstanceLinkView,
    SafariWindow
  }
}
</script>

<style>
.browser {
    min-height: 70vh;
}
</style>