<template>
<div
  v-if="value"
  class="d-flex justify-center align-center"
  :class="computedClass"
>
  <v-progress-circular indeterminate color="primary" width='2'/>
</div>
</template>

<script>
export default {
  props: ['value', 'fullScreen'],
  computed: {
    computedClass() {
      return this.fullScreen ? 'full-screen' : 'full-height'
    }
  }
}
</script>

<style scoped>
.full-height {
  height: 100%;
}

.full-screen {
  height: 100vh;
  width: 100vw;
}
</style>