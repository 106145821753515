<template>
<div class="full">
  <AppBar :space="space" dense>
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      class=""
      @click="sideNavigation = !sideNavigation"
    />
    <v-toolbar-title class="font-weight-medium">{{ $t("settings.spaceSettingsTitle") }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <UserProfile v-if="userLoggedIn"/>

  </AppBar>

  <v-tabs
    v-model="tab"
    v-if="this.$vuetify.breakpoint.mobile"
  >
    <v-tabs-slider color="accent"></v-tabs-slider>

    <v-tab

      v-for="item in navigationItems"
      :key="item.text"
      :to="item.to"
    >
    {{ item.text }}
    </v-tab>
  </v-tabs>


  <div style="height:100%;" class="d-flex flex-row" >

  <v-navigation-drawer
     v-if="!this.$vuetify.breakpoint.mobile"
    width="280px"
    v-model="drawer"
    :permanent="$vuetify.breakpoint.smAndUp"
    >
    <v-list nav dense>
      <v-list-item
        v-for="(item, i) in navigationItems"
        :key="i"
        :to="item.to"
        :data-testid="item.testId"
        color="accent">
        <v-list-item-icon class="mr-3">
          <v-icon dense v-text="item.icon"/>
        </v-list-item-icon>
        <v-list-item-title  v-text="item.text"/>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <div :style="{ height: '100%', width: '100%', overflow: 'scroll' }" class="flex-shrink-1">
    <router-view />
  </div>
    </div>
</div>
</template>

<script>
import UserProfile from '@/components/user/UserProfile.vue'
import AppBar from '../components/AppBar.vue'

export default {
  props: ['spaceUri'],
  data() {
    return {
      drawer: !this.$vuetify.breakpoint.mobile,
      tab: 0,
      from: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.from = from
    })
  },
  async mounted() {
    this.$store.dispatch('loadUser')
    await this.$store.dispatch('AGReadSpaceOperation', { spaceUri: this.spaceUri})
    await this.$store.dispatch('loadGridsUris', this.spaceUri)
  },
  provide() {
    return {
      plan: () => this.plan,
    }
  },
  computed: {
    alphaMode() {
      return this.$store.getters.alphaMode
    },
    navigationItems() {
      return [
        {
          text: this.$t('settings.generalSpaceSettings.title'),
          icon: 'mdi-cog-outline',
          to: {name: 'GeneralSpaceSettingsPage'},
          testId: 'GeneralSpaceSettingsPage'
        },
        {
          text: this.$t('settings.webhooks'),
          icon: 'mdi-webhook',
          to: {name: 'WebhooksPage'},
          testId: 'WebhooksPage'
        },
        ...(this.alphaMode ? [{
          text: this.$t('settings.links'),
          icon: 'mdi-link',
          to: {name: 'LinksPage'},
          testId: 'LinksPage'
        }] : []),
      ]
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    sideNavigation: {
      get() {
        return this.$store.state.sideNavigation
      },
      set(newVal) {
        return this.$store.commit('setSideNavigation', newVal)
      }
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    plan() {
      return this.space?.plan
    },
  },
  components: {
    UserProfile,
    AppBar
}
}
</script>

<style scoped>
.full {
  height: 100%;
  width: 100%;
}
</style>
