import { logTarget } from '@/plugins/apptiveErrorReporter.js'
import apptiveErrorReporter from '@/plugins/apptiveErrorReporter.js'

function initErrorReporter (form, version, vueContext) {
  let reporter = null
  if (form != null) {
    let target = getLogTarget()
    apptiveErrorReporter.init({ 
      app: 'ApptiveGrid', 
      form: form, 
      version: version, 
      target: target, 
      beforeSend: (errorEvent) => beforeSendError(errorEvent, vueContext)})
    reporter = apptiveErrorReporter
  }
  return reporter
}

// Function to load the configuration from the web

function beforeSendError(errorEvent, vueContext) {
  const response = errorEvent?.error?.response
  if ( response ) {
    errorEvent.properties['response-status'] = response.status || null
    // don't log 401
    if (response.status === 401) {
      let target = (process.env.NODE_ENV === 'development') ? logTarget.CONSOLE : logTarget.IGNORE
      errorEvent.target = target
    }
  }
  // ignore Uncaught TypeError https://github.com/vuetifyjs/vuetify/issues/15977 
  if (typeof errorEvent?.error === 'string' && errorEvent?.error?.includes('.hasAttribute is not a function')) {
    errorEvent.target = logTarget.IGNORE
  }

  // Add additional information from Vue context 
  if (vueContext) {
    errorEvent.properties['vue-route'] =  vueContext.$route?.name || null
    errorEvent.properties['page-url'] = window.location.href || null
    errorEvent.properties['apptive-Id'] = vueContext.$store.state.user?.user?.id || null
    errorEvent.properties['apptive-email'] = vueContext.$store.state.user?.user?.email || null
  }

  // in case of a api call error log url, method, errorcode
  const config = errorEvent?.error?.config
  if (config) { 
    errorEvent.properties['request-url'] =  config?.url || null
    errorEvent.properties['request-method'] = config?.method || null
  }

  // send error if it is triggered via test or forced send with sendAlways e.g. in profile page
  if(errorEvent.error?.sendAlways) errorEvent.target = logTarget.CLOUD
  return errorEvent
}

function getLogTarget() {
  // log to console not cloud while develop
  return process.env.NODE_ENV === 'development' ? logTarget.CONSOLE : logTarget.CLOUD
}

export { initErrorReporter }