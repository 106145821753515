<template functional>
<div
  class="pl-2 cell border-right d-flex align-center"
  :class="$options.classesFromData(data)"
  :style="data.style"
  v-bind="data.attrs"
  v-on="listeners"
>
  <v-icon
    :color="$options.color(props.value)"
  >{{$options.icon(props.value)}}</v-icon>
</div>
</template>

<script>
import { classesFromData } from '@/utils/vueUtils.js'

export default {
  props: ['value'],
  icon(value) {
    if (value == null) {
      return '$checkboxIndeterminate'
    } else if (value === true) {
      return '$checkboxOn'
    } else {
      return '$checkboxOff'
    }
  },
  color(value) {
    return value == null ? undefined : 'primary'
  },
  classesFromData
}
</script>
