import Vue from 'vue'
import ApiClient from '@/ApiClient'

export default {
  state() {
    return {
      invitesList: {}
    }
  },
  mutations: {
    addInviteList(state, { spaceUri, list }) {
      Vue.set(state.invitesList, spaceUri, list)
    }
  },
  actions: {
    loadInvitesUris(context, spaceUri) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink('invitations')
      return ApiClient.getUri(link)
        .then(response => {
          context.commit('addInviteList', { spaceUri, list: response.data.items })
          return response.data.items
        })
    }
  },
  getters: {
    invitesOfSpace: (state) => spaceUri => {
      return state.invitesList[spaceUri]?.map((invite) => {
        return {
          email: invite.email,
          link: invite.link,
          _links: invite._links,
          role: invite.role
        }
      }) || []
    }
  }
}
