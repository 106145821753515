<template>
    <v-text-field
      ref="textField"
      v-model="internalValue"
      v-bind="$attrs"
      v-on="$listeners"
      :append-icon="emojiPickerIcon"
      @click:append="toggleEmojiPicker"
      @keydown="updateCursorPosition"
    >
      <template v-slot:append>
        <v-menu  :close-on-content-click="false" v-model="showEmojiPicker" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn small style="margin-top: -2px;" icon v-on="on" @click.stop="toggleEmojiPicker">
              <v-icon >{{ emojiPickerIcon }}</v-icon>
            </v-btn>
          </template>
          <emoji-picker @emoji-click="addEmoji" />
        </v-menu>
      </template>
    </v-text-field>
  </template>
  
  <script>
  import 'emoji-picker-element'
  
  export default {
    props: {
      value: {
        type: String,
        default: '',
      },
      emojiPickerIcon: {
        type: String,
        default: 'mdi-emoticon-outline',
      },
    },
    data() {
      return {
        internalValue: this.value,
        showEmojiPicker: false,
        cursorPosition: 0, // Store cursor position
      }
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue
      },
      internalValue(newValue) {
        this.$emit('input', newValue)
      },
    },
    methods: {
      toggleEmojiPicker() {
        this.showEmojiPicker = !this.showEmojiPicker
      },
      updateCursorPosition(event) {
        // Capture and update cursor position before each key event
        this.cursorPosition = event.target.selectionStart
      },
      addEmoji(event) {
        const emoji = event.detail.unicode
        const beforeCursor = this.internalValue.slice(0, this.cursorPosition)
        const afterCursor = this.internalValue.slice(this.cursorPosition)
        
        // Insert emoji at the cursor position
        this.internalValue = `${beforeCursor}${emoji}${afterCursor}`
        
        // Update cursor position to be right after the newly added emoji
        this.$nextTick(() => {
          const input = this.$refs.textField.$el.querySelector('input')
          const newCursorPosition = this.cursorPosition + emoji.length
          input.setSelectionRange(newCursorPosition, newCursorPosition)
          this.cursorPosition = newCursorPosition
        })
        
        this.showEmojiPicker = false // Close picker after selecting an emoji
      },
      validate() {
      return this.$refs.textField.validate()
    },
    resetValidation() {
      this.$refs.textField.resetValidation()
    }
    },
  }
  </script>
  
  <style scoped>
  /* Optional custom styling for the emoji picker */
  </style>
  