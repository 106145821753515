<template>
  <div>
  <div class="d-flex align-center">
    <div class="d-flex flex-column flex-grow-1 flow-transition-condition">
      <OperandInput
        v-model="leftOperand"
        @input="updateCondition"
        :label="$t('flow.condition.leftOperandLabel')"
        :flow="flow"
        :filterNode="filterNode"
        :pickerValue="pickerValue"
        data-testid="condition-left-operand"
      />
      <v-autocomplete
        v-model="operator"
        :items="filteredOperators"
        @input="operatorChanged"
        :filter="autocompleteFilter"
        :label="operator ?  '' : $t('flow.condition.operatorLabel')"
        outlined hide-details dense
        data-testid="condition-operator"
      />
      <OperandInput
        v-if="hasRightOperand"
        v-model="rightOperand"
        @input="updateCondition"
        :flow="flow"
        :label="$t('flow.condition.rightOperandLabel')"
        noScript
        :pickerValue="pickerValue"
        data-testid="condition-right-operand"
      />
    </div>
    <v-btn
      @click="emitDelete"
      class="ml-5 compact-button"
      color="white"
      depressed
      data-testid="gridFilterDeleteButton"
      >
      <v-icon small>mdi-close</v-icon>
    </v-btn>
  </div>
  <div class="red--text text-caption mt-3 ml-3" v-if="unrecognizedExpression">{{$t('flow.condition.unrecognizedExpression')}}</div>
  <v-list dense>
    <v-list-group v-model="rawGroup">
      <template v-slot:activator>
        <div class="text-caption">{{$t('flow.condition.expression')}}</div>
      </template>
      <FlowVariablePickerMenu
        v-if="alphaMode"
        v-model="menu"
        :pickerValue="pickerValue"
        :flow="flow"
        :filterNode="filterNode"
        @pickerInput="insertRawExpression"
        attach
      >
        <template v-slot:activator>
          <v-textarea
            ref="rawInput"
            v-model="externalModel"
            @click.native="menu = true"
            @focus="menu = true"
            class="mx-3 text-caption raw-condition"
            solo flat outlined dense
          />
        </template>
      </FlowVariablePickerMenu>
      <div v-else class="mx-3 text-caption raw-condition">{{externalModel}}</div>
    </v-list-group>
  </v-list>
</div>
</template>

<script>
import OperandInput from './OperandInput.vue'
import externalModel from '../../mixins/externalModel'
import Operator from '@/store/models/apptivescript/Operator.js'
import { ASExpression, listTypes  } from '@/apptivescript/apptivescriptUtils.js'
import FlowVariablePickerMenu from './FlowVariablePickerMenu.vue'

const supportedOperators = [
  'Number',
  'String',
  'Boolean',
  'Date',
  'DateAndTime',
  'Decimal',
  'Integer',
  'KeyValueObject',
  'Undefined'
]

const quoteTypes = [
  'String',
  'Date',
  'DateAndTime'
]

export default {
  mixins: [externalModel],
  props: {
    flow: null,
    transition: null
  },
  data() {
    return {
      leftOperand: '',
      rightOperand: '',
      rightOperandType: undefined,
      operator: undefined,
      operatorItems: [],
      operatorTypeFilter: undefined,
      menu: false,
      unrecognizedExpression: false,
      rawGroup: false
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler(newVal) { 
        this.operatorItems = this.toOperatorItems(listTypes())
        if (!newVal) {
          this.leftOperand = ''
          this.operator = undefined
          this.rightOperand = ''
          return
        }
        try {
          const expression = new ASExpression(newVal)
  
          if (!expression.isFunctionCall) {
            return
          }
  
          this.leftOperand = expression.receiverCodeString
          const operatorString = expression.functionName
          const type = expression.receiverTypeInFlowTestInstance(this.flow)
          this.rightOperand = this.withoutQuotes(expression.firstArgument?.toString())
          const operatorItem = this.operatorItems.find(item => {
            if (item.value?.name !== operatorString) {
              return false
            }
          if (item.value?.arguments?.length > 1) {
            return false
          }
          if (type !== 'Undefined' && item.value?.callerType !== type) {
            return false
          }
          return true
        })
        if (operatorItem != null) {
          this.operator = operatorItem.value
          this.rightOperandType = operatorItem.value?.arguments[0]
        }
        this.unrecognizedExpression = false
      } catch(error) {
        console.error(error)
        this.unrecognizedExpression = true
        this.rawGroup = true
      }
      }
    }
  },
  computed: {
    filteredOperators() {
      if (this.operatorTypeFilter == null || this.operatorTypeFilter.toLowerCase() === 'undefined') {
        return this.operatorItems
      }
      return this.operatorItems.filter(item => item.value?.callerType === this.operatorTypeFilter)
    },
    filterNode() {
      const targetNodeUri = this.transition?.target
      if (!targetNodeUri) {
        return undefined
      }
      return this.flow.nodes.find(node => node.uri === targetNodeUri)
    },
    hasRightOperand() {
      return this.operator != null && this.operator.arguments.length > 0
    },
    alphaMode() {
      return this.$store.getters.alphaMode
    },
    pickerValue (){
      if (this.flow.testInstance == null) return
      return this.flow.testInstance.steps.reduce(
        (acc, step) => {
          // HACK for nodeId
          const key = step._links.node.href.split('/')[step._links.node.href.split('/').length - 1]
          acc[key] = {output: step.output}
          return acc
        },
        {}
      )
    }
  },
  methods: {
    operatorChanged() {
      this.rightOperandType = this.operator?.arguments[0]
      this.updateCondition()
    },
    updateCondition() {
      const left = this.withoutMustache(this.leftOperand)
      let right = this.withoutMustache(this.rightOperand)
      // eslint-disable-next-line quotes
      if (right && quoteTypes.includes(this.rightOperandType) && (!right.startsWith("'") || !right.endsWith("'"))) {
        right = `'${right}'`
      }
      if (left && this.operator != null && (right || !this.hasRightOperand)) {
        const condition = `${left}.${this.operator?.name}(${right})`
        this.$emit('input', condition)
      }

      if (left != null && left.length > 0) {
        const expression = new ASExpression(left)
        const type = expression.typeInFlowTestInstance(this.flow)
        this.operatorTypeFilter = type
      }
    },
    withoutMustache(string) {
      return string?.replace('{{', '').replace('}}', '')
    },
    withoutQuotes(value) {
      if (typeof value !== 'string') {
        return value
      }
      const match =  value.match(/^'?(.+?)'?$/)
      return match[1]
    },
    toOperatorItems(operators) {
      if (operators == null) {
        return
      }
      const items = []
      Object.keys(operators)
        .sort((a) => {if (a === 'String') return -1})
        .forEach(type => {
        if (supportedOperators.includes(type)) {
          items.push({header: type})
          Object.keys(operators[type]).forEach(operator => {
            if (operators[type][operator].return === 'Boolean' && operators[type][operator].type === 'function')
            items.push({
              text: this.$t(`operators.${operator}`),
              value: new Operator(
                operators[type][operator],
                type,
                operator
              ),
            })
          })
        }
      })
      return items
    },
    autocompleteFilter(item, queryText, itemText) {
      const text = itemText.toLowerCase()

      return item.header ||
        text.indexOf(queryText) > -1
    },
    emitDelete() {
      this.$emit('delete')
    },
    async insertRawExpression(expression) {
      const textArea = this.$refs.rawInput.$el.querySelector('textarea')
      const selectionStart = textArea.selectionStart
      const selectionEnd = textArea.selectionEnd
      this.externalModel = this.externalModel.slice(0, selectionStart) + expression + this.externalModel.slice(selectionEnd)
      await this.$nextTick()
      textArea.setSelectionRange(selectionStart + expression.length, selectionStart + expression.length)
      textArea.focus()
    }
  },
  components: {
    OperandInput,
    FlowVariablePickerMenu
  }
}
</script>

<style scoped>
.flow-transition-condition {
  gap: 4px
}

.raw-condition {
  overflow-wrap: anywhere;
  color: black;
  overflow: scroll;
}

.compact-button {
  height: 32px !important;
  padding: 8px !important;
  min-width: unset !important;
  border: solid thin #d3d2d4 !important;
}

</style>
