<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="slide-x-reverse-transition"
  >
    <v-card>
      <div class="pa-8">
        <div class="pa-3">
          <v-btn
            icon
            light
            large
            color="primary"
            @click="close()"
            data-testid="closeCreateGridDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span>{{ $t("dialogs.createGrid") }}</span>
        </div>
        <v-col cols="12" sm="6">
          <span class="text-h4">{{ $t("dialogs.createGridText") }}</span>
          <v-text-field
            v-model="name"
            label="Name"
            data-testid="createGridInputName"
            class="pt-12 pb-5"
            outlined
            autofocus
            @keyup.enter.native="createGrid"
            :counter="maxNameLength"
            :rules="rules.maxLength(maxNameLength)"
          >
          </v-text-field>
          <v-btn
            class="mr-2"
            depressed
            :disabled="saveButtonDisabled"
            color="primary"
            data-testid="createGridSubmitButton"
            @click="createGrid"
            :loading="loading"
          >
            {{ $t("dialogs.createButton") }}
          </v-btn>
          <v-btn
            text
            color="grey"
            data-testid="stayOnSpacesButton"
            @click="stayOnSpaces"
          >
            {{ $t("dialogs.stayOnSpacesButton") }}
          </v-btn>
        </v-col>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { textFieldRules } from '@/mixins/textFieldRules.js'
import nameLengths from '@/constants/nameLengths.js'

export default {
  props: {
    show: Boolean,
    spaceUri: null
  },
  mixins: [textFieldRules],
  data() {
    return {
      name: null,
      loading: false,
      maxNameLength: nameLengths.grid
    }
  },
  computed: {
    saveButtonDisabled() {
      return !this.name || this.name.length > this.maxNameLength
    }
  },
  methods: {
    close() {
      this.$emit('showCreateSpaceDialog', false)
      this.$emit('showGridDialog', false)
    },
    stayOnSpaces() {
      this.close()
    },
    goToSpace() {
      this.$router.push({
        name: 'Space',
        params: {
          spaceUri: this.spaceUri
        }
      })
    },
    createGrid() {
      this.loading = true
      this.$store
        .dispatch('AGAddGridOperation', {
          spaceUri: this.spaceUri,
          newName: this.name
        })
        .then(() => {
          this.goToSpace()
          this.close()
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
