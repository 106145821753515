import ApiClient from '@/ApiClient'

export default {
  state() {
    return {
      webhooks: []
    }
  },
  mutations: {
    setWebhookList(state, webhooks) {
      state.webhooks = webhooks
    }
  },
  actions: {
    listWebhooks(context, webhooksUri) {
      return ApiClient.getUri(webhooksUri)
        .then(response => {
          context.commit('setWebhooksList', response.data.items)
          return response.data
        })
    }
  },
  getters: {
    webhooksUris(state) {
      return state.webhooks.map((webhook) => {
        return webhook._links.self.href
      }) || []
    },
    webhookList(state) {
      return state.webhooks.map((webhook) => {
        return {
          uri: webhook._links.self.href,
          name: webhook.name
        }
      }) || []
    }
  }
}
