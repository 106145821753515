<template>
  <div class="grow">

  <v-app-bar dense flat color="white">
      <v-toolbar-title>{{apptive.name}}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        @click.stop="show = !show"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

    </v-app-bar>
    <v-divider></v-divider>
    <v-container class="fluid pa-0">
      <div class="resp-container grow" :style="styles" >
        <iframe ref="frame" class="resp-iframe" :style="styles"  :src="`${apptive.url}`" frameborder="0"></iframe>
      </div>
    </v-container>
    </div>
</template>

<script>
export default {
  props: ['apptive'],
  data() {
    return {
      statefulViewUri: null,
      eventListenter: null,
      toolbarHeight: 50
    }
  },

  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    virtualGrid() {
      return this.statefulView?.parentGrid
    },
    show: {
      get() {
        return this.value
      },
      set(val){
        this.$emit('input', val)
      }
    },
    contentWindow() {
      var iframe = this.$refs.frame
      return iframe.contentWindow
    },
    styles() {
      return {
        'max-height': `calc(100vh - ${this.$vuetify.application.top + this.toolbarHeight}px)`,
        'min-height': `calc(100vh - ${this.$vuetify.application.top + this.toolbarHeight}px)`,
      }
    },
  },
  mounted() {
    this.$store.subscribeAction((action) => {
      if ( action.type == 'AGUpdateEntityOperation') {
        this.updateData()
      }
    })
    this.statefulViewUriFromRoute(this.$route)
    this.addListener()
  },
  unmounted() {
    this.removeListener()
  },
  methods: {
    updateData() {
      if(!this.virtualGrid) return
      this.contentWindow.postMessage(
        { call: 'virtualGridUpdate', grid: JSON.parse(JSON.stringify(this.virtualGrid)) }
      )
    },
    statefulViewUriFromRoute(route) {
      if (route.name === 'VirtualGrid') {
        this.statefulViewUri = route.params.statefulViewUri
      }
    },
    addListener() {
      // listen to method apptiveLoaded, send from an apptive optionally after an apptive is loaded
      this.eventListenter = (event) => {
        if (event.data.message == 'apptiveLoaded') {
          console.log('apptiveLoaded', this)
          this.updateData()
        }
      }
      this.contentWindow.addEventListener('message', this.eventListenter)
    },
    removeListener() {
      this.contentWindow.removeEventListener('message', this.eventListenter)
    }
  },
  watch: {
    $route(route) {
      this.statefulViewUriFromRoute(route)
    },
    virtualGrid: {
      handler(){
        this.updateData()
      }
    },
    apptive: {
      handler(){
        this.removeListener()
        this.addListener()
        this.updateData()
      }
    }
  }
}
</script>

<style lang="css" scoped>

.resp-container {
    position: relative;
    overflow: hidden;
}
.resp-iframe {
    position: absolute;
    width: 100%;
    border: 0;
    left: 0;
    display: flex;
}
</style>
