import StatefulView from './StatefulView'
import store from '../store'
import { columnTypes } from '../../constants/columnTypes'

export const IS_GROUPBY_FIELD = 'isGroupByField'

export default class SpreadsheetView extends StatefulView {
  constructor(data) {
    super(data)

    this.groups = {}
    this.groupedItems = []
  }

  isOfAcceptedType(field) {
    return field.type.disableSorting !== true
  }

  get groupByFieldId() {
    return this.getFieldProperty(IS_GROUPBY_FIELD)
  }

  async setGroupBy(fieldId) {
    if (fieldId != null) {
      await this.parentGrid.setSorting([{[fieldId]: 'ascending'}])
    } else {
      await this.parentGrid.setSorting([])
    }
    this.deleteFieldProperty(this.groupByFieldId, IS_GROUPBY_FIELD)
    this.setFieldProperty(fieldId, IS_GROUPBY_FIELD, true)
    this.groupEntities()

    return store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      slotProperties: this.slotProperties
    })
      .then(() => this.reload())
  }

  groupEntities() {
    if (!this.groupByFieldId) {
      this.groups = { all: this.parentGrid.entities }
      return
    }
    const groupByIndex = this.parentGrid.positionOf(this.groupByFieldId)
    const displayFunction = value => this.parentGrid.displayFormat(value, groupByIndex)
    this.groups = this.parentGrid.entities.reduce((acc, entity) => {
      const group = displayFunction(entity.fields[groupByIndex]);
      (acc[group] = acc[group] || []).push(entity)
      return acc
    }, {})
    this.groupedItems = this.buildGroupedItems()
  }

  isGrouped() {
    return this.groupByFieldId != null
  }

  buildGroupedItems() {
    if (!this.isGrouped()) {
      return undefined
    }
    const items = []
    const groupByIndex = this.parentGrid.positionOf(this.groupByFieldId)
    const displayFunction = value => this.parentGrid.displayFormat(value, groupByIndex)
    let currentHeader
    let accumulator = 0
    this.parentGrid.entities.forEach(entity => {
      const group = displayFunction(entity.fields[groupByIndex])
      if (currentHeader == null || group != currentHeader?.group) {
        if (currentHeader != null) {
          currentHeader.count = accumulator
          items.push({type: 'footer', group: currentHeader.group, _id: `footer-${currentHeader.group}`})
        }
        currentHeader = {type: 'header', group, _id: `header-${group}`}
        items.push(currentHeader)
        accumulator = 0
      }
      accumulator++
      items.push(entity)
    })
    if (currentHeader != null && this.parentGrid.entitiesPager.page === this.parentGrid.entitiesPager.numberOfPages) {
      currentHeader.count = accumulator
      items.push({type: 'footer', group: currentHeader.group, _id: `footer-${currentHeader.group}`})
    }
    return items
  }

  async addRowToGroup(group) {
    const newEntity = this.parentGrid.emptyEntity()
    
    let groupByFieldIndex = this.parentGrid.fields.findIndex(field => field.id === this.groupByFieldId)
    if (groupByFieldIndex >= 0) {
      const groupByField = this.parentGrid.fields[groupByFieldIndex]
      //Lookup and Sumup fields are readonly, prefill row with refrence field instead
      if ([columnTypes.lookup.name, columnTypes.sumup.name].includes(groupByField.columnType.name)) {
        groupByFieldIndex = this.parentGrid.fields.findIndex(field => field.uri === groupByField.type.referenceField)
      }
      const groupValue = this.groups[group][0].fields[groupByFieldIndex]
      newEntity.changed(this.parentGrid, groupValue, this.parentGrid.fields[groupByFieldIndex])
    }

    const entity = await this.parentGrid.addEntity(newEntity, group)
    const index = this.groupedItems.findIndex(item => item.type === 'footer' && item.group === group)
    this.groupedItems.splice(index, 0, entity)
    return entity
  }
}