<template>
    <button
      class="token font-weight-medium pl-1 pr-1"
      :data-value="value"
      :data-color="color"
      :style="style"
      :contenteditable="editable"
      :draggable="draggable"
      @mouseenter="highlight"
      @mouseleave="clearHighlight"
      @click="onClick"
    >{{text}}</button>
</template>

<script>
export default {
  props: {
    text: null,
    value: null,
    color: null,
    editable: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: Boolean,
      default: () => false
    },
    draggable: {
      type: Boolean,
      default: () => true
    },
  },
  computed: {
    style() {
      return {
        'background-color': this.color,
        cursor: this.draggable ? 'move' : 'pointer'
      }
    }
  },
  methods: {
    highlight() {
      const event = new CustomEvent('highlightExpression', {detail: this.value})
      document.dispatchEvent(event)
    },
    clearHighlight() {
      const event = new CustomEvent('highlightExpression', {detail: undefined})
      document.dispatchEvent(event)
    },
    onClick() {
      if (this.placeholder) {
        this.$emit('replace')
      }
    }
  }
}
</script>

<style scoped>
.token {
  color: white;
  border-radius: 4px;
  margin-left: 1px;
  margin-right: 1px;
}
.invisible {
  opacity: 0;
  width: 0px;
}
</style>
