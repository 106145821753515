<template>
  <div
    class="d-flex headerRow"
    v-bind:style="{ width: rowWidth + 'px', '--prepend-cell-width': `${virtualGrid.prependCellWidth}px` }"
    v-on="$listeners"
  >
    <ColumnSettingsMenu
      ref="columnSettingsMenu"
      :virtualGrid="virtualGrid"
      :position-x="menuX"
      :position-y="menuY"
      :createPosition="createPosition"
      createOnSave
      noActivator
    />
    <GridHeaderPrependCell
      class="flex-grow-1 frozen"
      :cellWidth="this.virtualGrid.prependCellWidth"
      :reloading="virtualGrid.reloading"
    />
    <AGDraggable
      :value="virtualGrid.fields"
      class="d-flex"
      @end="onDragend"
      filter=".resize-area"
      :preventOnFilter="false"
      dragClass="dragging-header-cell"
    >
      <div v-for="(field, index) in virtualGrid.fields" :key="field.id" :class="{'frozen-cell': index === 0 && $vuetify.breakpoint.smAndUp}">
        <GridHeaderInter
          v-if="!disableAddColumn && index < virtualGrid.fields.length"
          :showContent="showAddButtonIndex === index"
          :loading="addColumnLoading === (index + 1)"
          @mouseenter.native="() => addColumnAfterMouseEntered(index)"
          @mouseleave.native="addColumnAfterMouseLeft"
          @click.native="(event) => openColumnSettingsMenu(event, index)"
          @contextmenu.native.prevent="() => interClicked(index)"
        />
        <GridHeaderCell
          class="flex-grow-1"
          :field="field"
          :virtualGrid="virtualGrid"
          :readonly="readonly"
          :width="virtualGrid.fieldWidthById(field.id)"
          :style="cellStyle(field.id)"
          :index="index"
        />
      </div>
    </AGDraggable>
    <GridHeaderAddColumnCell
      :virtualGrid="virtualGrid"
      :disabled="disableAddColumn"
      @addColumn="() => addColumn()"
      :loading="addColumnLoading === 'last'"
    />
  </div>
</template>

<script>
import GridHeaderCell from './GridHeaderCell.vue'
import GridHeaderInter from './GridHeaderInter.vue'
import GridHeaderAddColumnCell from './GridHeaderAddColumnCell.vue'
import GridHeaderPrependCell from './GridHeaderPrependCell.vue'
import ColumnSettingsMenu from '@/components/gridView/ColumnSettingsMenu.vue'
import colors from '@/constants/colors.js'
import AGDraggable from '../AGDraggable.vue'

export default {
  props: {
    virtualGrid: null,
    readonly: {
      type: Boolean,
      default: false
    },
    filteredColumnIds: null,
    sortedColumnIds: null,
    addColumnLoading: {
      default: false
    },
    disableAddColumn: null,
  },
  data() {
    return {
      hideDelay: 200,
      showAddButtonIndex: -1,
      hideTimeoutId: undefined,
      menuX: 0,
      menuY: 0,
      createPosition: undefined
    }
  },
  computed: {
    rowWidth() {
      return this.virtualGrid.gridWidth() + 35
    }
  },
  methods: {
    interClicked(index) {
      if (this.addColumnLoading === false) {
      // Why +1 ?
      // We want to insert after the inter and column positions are 1-based
      this.addColumn(index + 1)
      }
    },
    addColumn(position) {
      this.$emit('addColumn', position)
    },
    addColumnAfterMouseEntered(index) {
      this.clearHideTimeout()
      this.showAddButtonIndex = index
    },
    addColumnAfterMouseLeft() {
      this.clearHideTimeout()
      this.hideTimeoutId = window.setTimeout(() => {
        this.showAddButtonIndex = -1
      }, this.hideDelay)
    },
    clearHideTimeout() {
      if (this.hideTimeoutId) {
        window.clearTimeout(this.hideTimeoutId)
      }
    },
    openColumnSettingsMenu(event, index) {
      this.menuX = event.clientX
      this.menuY = event.clientY
      this.createPosition = index + 1
      this.$nextTick(() => {
        this.$refs.columnSettingsMenu.open()
      })
    },
    cellStyle(fieldId) {
      const colorConditions = [
        {
          condition: this.filteredColumnIds?.includes(fieldId),
          color: colors.filter
        },
        {
          condition: this.sortedColumnIds?.includes(fieldId),
          color: colors.sorting
        },
        {
          condition: this.virtualGrid?.groupBy === fieldId,
          color: colors.grouping
        },
      ]
      const cellColors = colorConditions
        .filter(item => item.condition)
        .map(item => item.color)
      if (cellColors.length === 1) {
        cellColors.push(cellColors[0])
      }
      if (cellColors.length) {
        return {
          background: `linear-gradient(${cellColors.join(', ')})`
        }
      }
    },
    onDragend(event) {
      const fieldIds = this.virtualGrid.fields.map(field => field.id)
      const movedFieldId = fieldIds.splice(event.oldIndex, 1)[0]
      fieldIds.splice(event.newIndex, 0, movedFieldId)
      this.virtualGrid.updateShownFields(fieldIds)
    }
  },
  beforeDestroy() {
    this.clearHideTimeout()
  },
  components: {
    GridHeaderCell,
    GridHeaderAddColumnCell,
    GridHeaderPrependCell,
    GridHeaderInter,
    ColumnSettingsMenu,
    AGDraggable
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headerRow {
  height: 32px;
  border-style: none none solid none;
  border-width: 1px;
  border-color: #d3d2d4;
  background-color: white;
  margin-left: 0px;

  flex-wrap: nowrap;
}

.dragging-header-cell {
  opacity: 0.5;
  background-color: #f4f5f4;
}

.frozen {
  position: sticky;
  left: 0px;
  z-index: 1;
  background-color: white;
}

.frozen-cell {
  position: sticky;
  left: var(--prepend-cell-width);
  z-index: 1;
  background-color: white;
}
</style>
