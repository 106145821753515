<template>
<SingleInputDialog
  :disabled="disabled"
  v-model="dialogModel"
  :title="$t('dialogs.renameGridTitle', { gridTitle: gridTitle })"
  :text="$t('dialogs.renameGridText')"
  :okButtonLabel="$t('dialogs.saveButton')"
  :okHandler="save"
  :initialInputValue="gridTitle"
  :max="$apptive.constants.nameLengths.grid"
  max-width="290"
>
  <template v-slot:activator="{ on, attrs }">
    <MenuItem
      :disabled="disabled"
      v-on="on" v-bind="attrs"
      icon="mdi-pencil"
      :text="$t('dialogs.renameGridButton')"
    />
  </template>
</SingleInputDialog>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import MenuItem from '@/components/MenuItem.vue'

export default {
  name: 'RenameGridDialog',
  props: {
    gridUri: null,
    disabled: null
  },
  data() {
    return {
      dialogModel: false
    }
  },
  computed: {
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    gridTitle() {
      if (!this.grid) {
        return ''
      }
      return this.grid.title()
    }
  },
  methods: {
    save(newName) {
      if (this.grid.name == newName) {
        return
      }
      return this.$store.dispatch('AGRenameGridOperation', {
        gridUri: this.gridUri,
        newName: newName
      })
    }
  },
  components: {
    SingleInputDialog,
    MenuItem
  }
}
</script>

<style lang="css"></style>
