import ApiClient from '@/ApiClient'
import { createWebhook } from '@/store/models/flow/webhooks'
import Vue from 'vue'


export default {
  state() {
    return {
      webhooks: {},
      webhookUris: [],
    }
  },
  mutations: {
    addWebhook(state, webhook) {
      Vue.set(state.webhooks, webhook.uri, webhook)
    },
    removeWebhook(state, webhook) {
      delete state.webhooks[webhook._links.self.href]
    },
  },
  actions: {
    AGListWebhooksOperation(context, space) {
      context.state.webhooks = {}
      context.state.webhookUris = []
      const link = space.getLink('hooks')
      return ApiClient.getUri(link).then(
        response => {
            const promise = new Promise(resolve => {
            const allPromises = []
            response.data.items.forEach(item => {
              const uri = item._links.self.href
              context.state.webhookUris.push(uri)
              allPromises.push(context.dispatch('AGReadWebhookOperation', uri))
            })
            Promise.all(allPromises).then((webhooks) => {
              resolve(webhooks)
            })
          })
          return promise
        })
    },
    AGReadWebhookOperation(context, uri) {
      return ApiClient.getUri(uri)
        .then(response => {
          const webhook = createWebhook(response.data)
          context.commit('addWebhook', webhook)
          return webhook
        })
        .catch(error => {
          context.state.webhookUris = context.state.webhookUris.filter(
            item => item !== uri
          )
          return error
        })
    },
    async AGPostWebhook(context, webhook) {
      let space = context.getters.spaceWithUri(webhook.spaceUri())
      if (space == null) {
        space = await context.dispatch('AGReadSpaceOperation', webhook.spaceUri())
      }
      const response = await ApiClient.postUri(
        space.getLink('addHook'),
        webhook.asPayload()
      )
      const uri = response.headers['location']
      const newWebhook = context.dispatch('AGReadWebhookOperation', uri)
      context.state.webhookUris.push(uri)
      return newWebhook
    },
    async createExternalLinkWebhook(context, {spaceUri, flowUri}) {
      let space = context.getters.spaceWithUri(spaceUri)
      if (space == null) {
        space = await context.dispatch('AGReadSpaceOperation', spaceUri)
      }
      const response = await ApiClient.postUri(
        space.getLink('addHook'),
        {
          type: 'externalFlowTrigger',
          flow: flowUri
        }
      )
      return context.dispatch('AGReadWebhookOperation', response.headers.location)
    },
    saveWebhook(context, webhook) {
      const link = webhook.getLink('update')
      return ApiClient.putUri(link, webhook.asPayload())
    },
    deleteWebhook(context, webhook) {
      const link = webhook.getLink('remove')
      return ApiClient.deleteUri(link).then(response => {
        context.commit('removeWebhook', webhook)
        return response
      })
    },
    AGFlowHookTestOperation(context, webhook) {
      const link = webhook.getLink('test')
      return ApiClient.postUri(link).then(response => {
        return response
      })
    },
    AGFlowRunExternalHook(context,{ webhook, payload} ) {
      const link = webhook.uri
      return ApiClient.postUri(link , payload ).then(response => {
        return response
      })
    }
  },
  getters: {
    webhooks(state) {
      const webhooks = state.webhookUris.map(uri => state.webhooks[uri])
      const filtered = webhooks.filter(webhook => webhook != null)
      return filtered
    },
    webhookWithUri: state => uri => {
      return state.webhooks[uri]
    },
  }
}
