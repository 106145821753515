import HalObject from './HalObject'
import { typeFor } from '@/constants/columnTypes.js'
import store from '@/store/store.js'

function resolveDelegateType(dataType) {
  if (dataType.reducedType != null) {
    return dataType.reducedType
  }

  if (dataType.valueType != null) {
    return dataType.valueType
  }

  if (dataType.lookupType != null) {
    return resolveLookupType(dataType.lookupType)
  }

  return {}
}

function resolveLookupType(dataType) {
  if (dataType.lookupType == null) {
    return dataType
  }
  return resolveLookupType(dataType.lookupType)
}

export default class Field extends HalObject {
  constructor(data) {
    super(data)
    this.id = data.id
    this.key = data.key
    this.name = data.name
    // Only useful for lookup and sumup types
    const delegateType = resolveDelegateType(data.type)
    this.type = {...delegateType, ...data.type}
    this.columnType = typeFor(this.type.name, delegateType)
  }

  async setKey(virtualGridUri, key) {
    const newKey = key || null
    if (this.key === newKey) {
      return
    }
    await store().dispatch('AGUpdateFieldKeyOperation', {
      virtualGridUri,
      fieldId: this.id,
      newKey
    })
    this.key = newKey
    return newKey
  }

  get isCollection() {
    return this.type.jsonSchema.type === 'array'
  }

  hasOneOfTypes(columnTypes) {
    const typeNames = columnTypes.map(type => type.name)
    if (typeNames.includes(this.columnType.name)) {
      return true
    }
    
    if (this.columnType.delegateColumnType != null && typeNames.includes(this.columnType.delegateColumnType.name)) {
      return true
    }

    return false
  }
}