import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class ParagraphBlock extends Block {
  constructor(data) {
    super(data)
    this.isDirty = false
  }

  get text() {
    return this.paragraph.text
  }

  set text(newVal) {
    if (newVal === this.paragraph.text) {
      return
    }
    this.paragraph.text = newVal
    this.isDirty = true
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        paragraph: {
          text: this.text
        }
      }
    })
    this.isDirty = false
  }
}