import VirtualGrid from '../models/VirtualGrid.js'
import ApiClient from '@/ApiClient'
import Vue from 'vue'
import i18n from '@/plugins/i18n'
import { AGNoCondition } from '@/filter/conditions.js'
import Entity from '../models/Entity.js'
import { PERMISSIONS } from '@/utils/halUtils.js'

export default {
  state() {
    return {
      virtualGrids: {},
      deletedEntityIds: []
    }
  },
  mutations: {
    addVirtualGrid(state, virtualGrid) {
      Vue.set(state.virtualGrids, virtualGrid.uri, virtualGrid)
    },
    removeVirtualGrid(state, virtualGridUri) {
      delete state.virtualGrids[virtualGridUri]
    },
    addDeletedEntityId(state, id) {
      state.deletedEntityIds.push(id)
    },
    removeDeletedEntityId(state, id) {
      const index = state.deletedEntityIds.indexOf(id)
      if (index >= 0) {
        state.deletedEntityIds.splice(index, 1)
      }
    }
  },
  actions: {
    AGListVirtualGridsOperation(context, gridUri) {
        const promise = new Promise((resolve) => {
          const allPromises = []
          context.dispatch('loadViewUris', gridUri).then(views => {
            views.forEach( view => {
              // Loading each view is still necessary in order to determine if a Kanban
              // or Calendar view is available.
              // TODO : remove when kanban and calendar are implemented in the backend
              allPromises.push(context.dispatch('AGReadVirtualGridOperation', { virtualGridUri: view.uri, loadEntities: false }))
            })
            Promise.all(allPromises).then(() => {
              resolve(views)
            })
          })
        })
        return promise
    },
    async AGListSpaceVirtualGridsOperation(context, {space, excludedGrid}) {
      const allGrids = await context.dispatch('AGLoadAllGridsOperation', space.uri)
      const grids = excludedGrid ? allGrids.filter(grid => grid.uri !== excludedGrid.uri) : allGrids
      // Load views of all grids
      const allViews = await Promise.all(grids.map(grid => grid.loadViewUris()))
      const allPromises = []
      allViews.forEach((views) => {
        views.forEach( view => {
          if (!context.state.virtualGrids[view.uri]) {
            allPromises.push(context.dispatch('AGReadVirtualGridOperation', {virtualGridUri: view.uri, loadEntities: false}))
          } else {
            allPromises.push(Promise.resolve(context.state.virtualGrids[view.uri]))
          }
        })
      })
      return Promise.all(allPromises)
    },
    // eslint-disable-next-line no-unused-vars
    async AGReadVirtualGridOperation(context, { virtualGridUri, loadEntities = true, cached = false, pageIndex = 1 }) {
      virtualGridUri = virtualGridUri ?? arguments[1]
      const cachedView = context.getters.virtualGridWithUri(virtualGridUri)
      if (cached && cachedView) {
        return Promise.resolve(cachedView)
      }
      const virtualGridResponse = await ApiClient.getUri(
        virtualGridUri,
        undefined,
        { headers: { 'Accept': 'application/vnd.apptivegrid.hal;version=2' } }
      )
      virtualGridResponse.data['uri'] = virtualGridUri
      const virtualGrid = new VirtualGrid(virtualGridResponse.data)
      const oldVirtualGrid = context.getters.virtualGridWithUri(virtualGridUri)
      virtualGrid.localEntityParams = oldVirtualGrid?.localEntityParams
      if (loadEntities) {
        await virtualGrid.loadEntities(pageIndex)
      }
       else {
        virtualGrid.entities = oldVirtualGrid?.entities || []
        virtualGrid.entitiesPager = oldVirtualGrid?.entitiesPager || undefined
        virtualGrid.reloading = oldVirtualGrid?.reloading
      }
      context.commit('addVirtualGrid', virtualGrid)
      return virtualGrid
    },
    AGListVirtualGridEntitiesOperation(context, { virtualGrid, filter, sorting, pageIndex = 1, pageSize = 300}) {
      const link = virtualGrid.getLink('entities')
      return ApiClient.getUri(
        link,
        { layout: 'indexed', pageSize, pageIndex, filter: filter?.exportObject(), sorting },
        { headers: { Accept: 'application/vnd.apptivegrid.hal' } }
      )
    },
    AGAddEntityOperation(context, {uri, entity}) {
      const grid = context.getters.gridWithUri(uri)
      let url = grid.getLink('addEntity')
      return ApiClient.postUri(url, entity, { layout: 'indexed' })
    },
    AGUpdateEntityOperation(context, { virtualGrid, entity }) {
      const link = entity.getLink('partialUpdate')
      return ApiClient.postUri(
        link,
        entity.asPartialUpdateObjectIn(virtualGrid),
        { layout: 'field' }
      )
    },
    AGFetchEntityOperation(context, entityUri) {
      return ApiClient.getUri(entityUri, { layout: 'indexed' }).then( response => {
        if(response.data?.fields){
          return new Entity(response.data)
        }
      })
    },
    AGDeleteEntityOperation(context, entity) {
      const link = entity.getLink('remove')
      context.commit('addDeletedEntityId', entity._id)
      return ApiClient.deleteUri(link)
        .catch((e) => {
          context.commit('removeDeletedEntityId', entity._id)
          throw e
        })
    },
    async AGAddVirtualGridOperation(context, { gridUri, filterObject, sorting, fields } ) {
      const filterParam = filterObject ? filterObject : AGNoCondition.exportObject()
      const grid = context.rootGetters.gridWithUri(gridUri)
      const virtualGridCount = context.rootGetters.viewsOfGrid(gridUri).length
      let name = `${grid.name} ${i18n.t('virtualGrid.defaultName')}`
      if (virtualGridCount > 0) {
        name += ' ' + (virtualGridCount + 1)
      }
      const link = grid.getLink(PERMISSIONS.addView)
      const addViewResponse = await ApiClient.postUri(link, {
        name: name,
        filter: filterParam,
        sorting,
        fields
      })
      const uri = addViewResponse.headers['location']
      context.dispatch('loadViewUris', grid.uri)
      return context.dispatch('AGReadVirtualGridOperation', uri)
    },
    AGUpdateVirtualGridOperation(_, { virtualGrid, name, fields, filter, sorting }) {
      const link = virtualGrid.getLink('update')
      return ApiClient.putUri(link, {
        name: name ?? virtualGrid.name,
        fields: fields ?? virtualGrid.fields.map(field => field.id),
        filter: filter ?? (virtualGrid.filterObject()?.exportObject() ?? AGNoCondition.exportObject()),
        sorting: sorting ?? virtualGrid.sorting
      })
    },
    AGUpdateVirtualGridFilterOperation(context, { virtualGrid, filterObject } ) {
      const link = virtualGrid.getLink('updateFilter')
      return ApiClient.postUri(link, {
        filter: filterObject
      })
    },
    AGUpdateVirtualGridSortingOperation(context, { virtualGrid, sorting } ) {
      return context.dispatch('AGUpdateVirtualGridOperation', {
        virtualGrid,
        sorting
      })
    },
    AGUpdateVirtualGridFieldsOperation(context, { virtualGrid, fieldIds } ) {
      return context.dispatch('AGUpdateVirtualGridOperation', {
        virtualGrid,
        fields: fieldIds
      })
    },
    AGRenameVirtualGridOperation(context, { virtualGrid, newName }) {
      return context.dispatch('AGUpdateVirtualGridOperation', {
        virtualGrid,
        name: newName
      }).then(response => {
        virtualGrid.name = newName
        return response
      })
    },
    async AGRemoveVirtualGridOperation(context, { virtualGridUri }) {
      const grid = context.getters.gridContainingVirtualGridWithUri(virtualGridUri)
      const virtualGrid = context.getters.virtualGridWithUri(virtualGridUri)
      const link = virtualGrid.getLink('remove')
      const deleteResponse = await ApiClient.deleteUri(link)
      await context.dispatch('AGListStatefulViewsOperation', grid)
      context.commit('removeVirtualGrid', virtualGridUri)
      return deleteResponse
    },
    async AGVirtualGridQueryOperation(context, { virtualGridUri, query, pageSize = 50, pageIndex = 1 }) {
      let virtualGrid = context.getters.virtualGridWithUri(virtualGridUri)
      if (!virtualGrid) {
        virtualGrid = await context.dispatch('AGReadVirtualGridOperation', {virtualGridUri, loadEntities: false})
      }
      const link = virtualGrid.getLink('query')
      const queryResult = await ApiClient.getUri(link, {
        matching: query,
        pageSize,
        pageIndex,
        layout: 'indexed'
      })
      const entities = queryResult.data.items.map(entity => new Entity(entity))
      return {
        virtualGrid,
        entities,
        numberOfItems: queryResult.data.numberOfItems,
        page: queryResult.data.page,
      }
    },
    AGQueryUsersOperation(context, { field, query}) {
      const link = field.getLink('collaborators')
      return ApiClient.getUri(link, {
        matching: query || undefined
      }).then((response) => {
        return response.data.map(item => ({
          ...item,
          id: item.uri.split('/').pop()
        }))
      })
    },
    async AGExtractFieldOperation(context, { field }) {
      const link = field.getLink(PERMISSIONS.extractToGrid)
      const extractResponse = await ApiClient.postUri(
        link,
        undefined,
        { createVirtualGrid : false}
      )
      return extractResponse.headers.location
    }
  },
  getters: {
    virtualGrids: (state, getters, rootState, rootGetters) => (grid) => {
      const virtualGrids = rootGetters.viewsOfGrid(grid.uri).map(view => state.virtualGrids[view.uri] )
      const filtered = virtualGrids.filter( virtualGrid => virtualGrid != null)
      return filtered
    },
    virtualGridWithUri : (state) => (uri) => {
      return state.virtualGrids[uri]
    }
  }
}

