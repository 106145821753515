<template>
<div
v-if="virtualGrid && entity.fields"
  class="d-flex gridRow flex-grow-0"
  :class="{'no-bottom-border': noBottomBorder, 'rowSelected': rowSelected}"
  ref="gridRow"
  v-bind:style="{ width: rowWidth + 'px', '--prepend-cell-width': `${virtualGrid.prependCellWidth}px` }"
  @pointerenter="onPointerEnter"
  @pointerleave="onPointerLeave"
>
  <div 
    v-if="!disableDetailButton" 
    class="rowMenu d-flex align-center justify-space-between frozen"
    :style="`width: ${virtualGrid.prependCellWidth}px;`"
    :class="{'rowSelected': rowSelected}">
    
    
    <v-btn 
      v-if="canDeleteEntity"
      v-show="$vuetify.breakpoint.mobile || showExpand || rowSelected" 
      :disabled="virtualGrid.selectedEntities.length >= batchDelete.maxEntities && !rowSelected"
      class="ml-1"
      :color="rowSelected ? 'primary' : 'grey'"
      icon
      x-small
      @click="rowSelected = !rowSelected"
      @click.shift="virtualGrid.selectEntitiesTo(entity)"
      :value="rowSelected"
      data-testid="selectEntity">
      <v-icon v-if="rowSelected">mdi-checkbox-marked</v-icon>
      <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
    </v-btn>

    <v-btn
      v-show="($vuetify.breakpoint.mobile || showExpand) && !entity.updating"
      color="accent"
      dark
      icon
      x-small
      @click="$emit('openEditEntityDialog', entity)"
      data-testid="openEditEntityDialog"
    >
    <v-icon>mdi-arrow-expand</v-icon>
  </v-btn>

  <span
    v-show="!$vuetify.breakpoint.mobile && !showExpand && !rowSelected"
    class="text-caption ml-3"
    :class="preprendTextColor"
    data-tutorial="grid-row-prepend"
    >{{rowIndex}}</span>
    <v-progress-circular
    v-show="entity.updating"
    indeterminate
    :width="1"
    :size="16"
    color="rgb(158, 158, 158)"
    class="ml-2"
    data-testid="entity-updating-indicator"
  />
  </div>

  <template v-for="(field, index) in virtualGrid.fields">
    <GridCellFactory
      v-if="index === selectedCellIndex"
      :key="index"
      :index="index"
      ref="realCell"
      class="border-right"
      :value="entity.fields[index]"
      :displayValue="displayValues[index]"
      :type="field.columnType"
      :field="field"
      :readonly="readonly"
      :style="cellStyle(index)"
      @blur="cellBlured"
      v-on:input="(value) => input(value, field)"
      v-on:nextLine="nextLine(index)"
      :width="virtualGrid.fieldWidthById(field.id)"
      :class="{'frozen-cell': index === 0  && $vuetify.breakpoint.smAndUp}"
      @showError="(event, error) => $emit('showError', event, error)"
      @hideError="$emit('hideError')"
      :showErrorIndicator="showErrorIndicators"
    />
    <component
      v-else
      :is="readonlyCellComponents[index]"
      class="cell border-right"
      :key="index"
      :tabindex="field.columnType.readonly ? -1 : 0"
      @focus="selectCell(index, $event)"
      @click="selectCell(index, $event)"
      ref="cells"
      :style="readonlyCellStyle(field, index)"
      :value="entity.fields[index]"
      :displayValue="displayValues[index]"
      :type="field.type"
      :field="field"
      :data-testid="`cell-${index}`"
      :class="{'frozen-cell': index === 0  && $vuetify.breakpoint.smAndUp}"
      @showError="(event, error) => $emit('showError', event, error)"
      @hideError="$emit('hideError')"
      :showErrorIndicator="showErrorIndicators"
    />
  </template>
</div>
</template>

<script>
import GridCellFactory from './GridCellFactory.vue'
import colors from '@/constants/colors.js'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'
import batchDelete from '@/constants/batchDelete.js'

export default {
  props: {
    entity: null,
    virtualGrid: null,
    focusWhenMounted: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    filteredColumnIds: null,
    noBottomBorder: {
      type: Boolean,
      default: false
    },
    onContextMenu: null,
    disableDetailButton: {
      type: Boolean,
      default: false
    },
    selectedCellIndex: {
      type: Number,
      default: () => undefined
    }
  },
  data() {
    return {
      showExpand: false,
      showErrorIndicators: false,
      batchDelete: batchDelete
    }
  },
  mounted() {
    if (this.focusWhenMounted) {
      // $nextTick is necessary in order to properly focus the input of GridTextCell inside the v-menu activator
      this.$nextTick(() => {
        this.selectCell(0)
      })
    }
  },
  watch: {
    async selectedCellIndex(newVal) {
      if (newVal != null) {
        await this.$nextTick()
        this.$refs.realCell?.[0].selectCellInput()
      } else {
        this.$emit('didFinishEditingEntity', this.entity)
      }
    }
  },
  computed: {
    canDeleteEntity() {
      return hasPermission(this.entity, [PERMISSIONS.remove])
    },
    rowWidth() {
      return this.virtualGrid.gridWidth() - (this.disableDetailButton ? this.virtualGrid.prependCellWidth : 0)
    },
    shownFields() {
      return this.virtualGrid.shownFieldsOf(this.entity)
    },
    rowIndex() {
      return this.entity.position
    },
    displayValues() {
      return this.virtualGrid.fields.map((field, index) => {
        const entityValue = this.entity.fields[index]
        return field.columnType.displayFormat
          ? field.columnType.displayFormat(entityValue)
          : entityValue
      })
    },
    readonlyCellComponents() {
      return this.virtualGrid.fields.map(field => {
        return field.columnType.readonlyCellComponent
      })
    },
    preprendTextColor() {
      return this.selectedCellIndex >= 0 ? 'primary--text' : 'accent--text text--lighten-2'
    },
    rowSelected: {
      get () {
        return this.virtualGrid.isEntitySelected(this.entity)
      },
      set (newVal) {
        if(newVal == true) {
        this.virtualGrid.selectEntity(this.entity)
      }
      else {
        this.virtualGrid.deselectEntity(this.entity)
      }
      }
    },
  },
  methods: {
    input(newValue, field) {
      this.$emit('entityChanged', this.entity, newValue, field)
    },
    async selectCell(index) {
      this.$emit('selectCell', index)
    },
    cellBlured() {
      if (!this.entity) return
      this.$emit('cellBlurred')
    },
    nextLine(columnIndex) {
      this.$emit('nextLine', columnIndex)
    },
    cellStyle(index) {
      const partOfFilter = this.filteredColumnIds?.includes(this.virtualGrid.fields[index].id)
      return {
        backgroundColor: partOfFilter ? colors.filter : undefined
      }
    },
    readonlyCellStyle(field, index) {
      return {
        width: `${this.virtualGrid.fieldWidthById(field.id)}px`,
        overflow: 'hidden',
        ...this.cellStyle(index)
      }
    },
    onPointerEnter() {
      this.showExpand = true
      this.showErrorIndicators = true
    },
    onPointerLeave() {
      this.showExpand = false
      this.showErrorIndicators = false
    },
  },
  components: {
    GridCellFactory
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rowMenu {
  overflow: hidden;
}

.gridRow {
  height: 32px;
  border-style: none none solid none;
  border-width: 1px;
  border-color: #DEE1E3;
  margin-left: 0px;
  flex-wrap: nowrap;
  background-color: white;
}

.gridRow:hover {
  background-color: #F8F8F8;
}

.rowSelected {
  background-color: #e9f4ffb9 !important;
}

.no-bottom-border {
  border-bottom: unset;
}

.border-right {
  border-style: none solid none none;
  border-width: 1px;
  border-color: #DEE1E3;
}

.frozen {
  position: sticky;
  left: 0px;
  background: inherit;
  z-index: 1;
}

.frozen-cell {
  position: sticky;
  left: var(--prepend-cell-width);
  background: inherit;
  z-index: 1;
}
</style>
