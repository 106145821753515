<template>
<v-menu
    ref="menu"
    offset-y
    left
    :close-on-content-click="false"
    v-model="menu"
    :content-class="fullScreenMenu ? 'full-screen' :'map-menu'"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          x-small
          color="accent"
        >
          <v-icon small>mdi-map</v-icon>
        </v-btn>
      </slot>
    </template>

    <GoogleMap :options="computedMapOptions" @mapClicked="mapClicked" @placeFound="placeFound">
      <template v-slot:markers="{ mapPromise }">
        <GoogleMapMarker
          v-if="value"
          :mapPromise="mapPromise"
          v-bind="marker"
        />
      </template>
      <template v-slot:bottomLeftWidget>
        <v-btn
          class="fullscreen-button"
          background="white"
          @click="toggleFullScreen">
          <v-icon size="32" color="#666666">mdi-fullscreen</v-icon>
        </v-btn>
      </template>
    </GoogleMap>
  </v-menu>
</template>

<script>
import GoogleMap from '@/components/map/GoogleMap.vue'
import GoogleMapMarker from '@/components/map/GoogleMapMarker.vue'

export default {
  props: ['value', 'mapOptions', 'readonly'],
  data() {
    return {
      menu: false,
      fullScreenMenu: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    computedMapOptions() {
      return {
        ...this.mapOptions,
        fullscreenControl: false
      }
    },
    marker() {
      if(!this.model) {
        return undefined
      }
      return {
        lat: this.model.lat,
        lng: this.model.lon
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    mapClicked(event) {
      if(this.readonly) {
        return
      }
      const location = event?.latLng
      if(location) {
        this.model = {lat: location.lat(), lon: location.lng()}
      }
    },
    placeFound(place) {
      if(this.readonly) {
        return
      }
      const placeCenter = place?.geometry?.location
      if(placeCenter) {
        this.model = {lat: placeCenter.lat(), lon: placeCenter.lng()}
      }
    },
    toggleFullScreen() {
      this.fullScreenMenu = !this.fullScreenMenu
      if (!this.fullScreenMenu) {
        this.$refs.menu.activate()
      }
    },
    onKeyDown(e) {
      if(e.key === 'Escape') {
        if(this.fullScreenMenu) {
          this.fullScreenMenu = false
          this.$refs.menu.activate()
        }
      }
    }
  },
  components: {
    GoogleMap,
    GoogleMapMarker
  }
}
</script>

<style scoped>
.map-menu {
  width: 400px;
  height: 400px;
}

.full-screen {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  max-width: unset;
  top: 0 !important;
  left: 0 !important;
}

.fullscreen-button {
  background-color: white !important;
  border-radius: 2px !important;
  height: 40px !important;
  width: 40px !important;
  min-width: unset !important;
  margin: 10px !important;
}
</style>