<template>
  <div>
    <SViewExternalHook 
      v-for="hook in hooks"
      class="mb-3"
      :key="hook.uri"
      :link="hook"
      @linkDeleted="loadHooks"
    />
    <v-btn v-if="hooks.length == 0" @click="createViewHook" color="primary" :loading="loading">
      {{ $t("virtualGrid.shareDialog.buttons.share")  }}
    </v-btn>
  </div>
</template>

<script>
import SViewExternalHook from '@/components/gridView/SViewExternalHook.vue'

export default {
  props: {
    virtualGridUri: null,
    statefulViewUri: null,
  },
  data() {
    return {
      loading: false,
      hooks: [],
    }
  },
  watch: {
    statefulViewUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.loading = true
          try {
            await this.loadHooks()
          } finally {
            this.loading = false
          }
        }
      }
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
  },
  methods: {
    async loadHooks() {
      const viewLinks = await this.$store.dispatch('AGListViewLinks', {statefulView: this.statefulView})
      const readLinkPromises = viewLinks.map(link => this.$store.dispatch('AGReadLinkOperation', link.getLink('self')))
      this.hooks = await Promise.all(readLinkPromises)
    },
    async createViewHook() {
      // add an externam hook via addHook link of the grid sview
      let username = null
      let password = null
      this.loading = true
      try {
        await this.$store.dispatch('AGCreateViewLink', {statefulView: this.statefulView, role: 'reader', username, password } )
        await this.loadHooks()
      } finally {
        this.loading = false
      }
    },
  },
  components: {
    SViewExternalHook
  }
}
</script>

<style scoped>
.fit-parent-img {
  width: auto;
  height: 40px;
}

.one-line {
  overflow: auto;
  overflow-wrap: normal;
}

.full-width {
  outline: none;
  width: 100%;
}
</style>
