import { ASHolder } from '@/apptivescript/model/ASHolder'

export default class ASValueHolder extends ASHolder{
  constructor(data) {
    super()
    this._value = data._value
    return new Proxy(this, {
      get(target, prop) {
        if (prop === 'value' || prop === '_value') {
          return target._value
        }
        if (prop in target) {
          return target[prop]
        }
        // Access nested properties directly without .value
        return target._value?.[prop]
      }
    })
  }
}