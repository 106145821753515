import Vue from 'vue'
const bus = new Vue()
let dragged
export default {
  tokenDragStart(expression) {
    dragged = expression
  },
  getDraggedExpression() {
    const temp = dragged
    dragged = null
    return temp
  },
  editExpression(expression, doneHandler, event) {
    bus.$emit('editExpression', expression, doneHandler, event)
  },
  onExpressionEdit(handler) {
    bus.$on('editExpression', handler)
  },
  expressionEditOff(handler) {
    bus.$off('editExpression', handler)
  }
}
