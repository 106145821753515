import { columnTypes } from '@/constants/columnTypes'

function distanceBetween(a, b) {
  const  earthRadius = 6371000

  // Converting lat or lng from decimal degrees into radians
  const lat1 = a.lat / 57.2958
  const lon1 = a.lon / 57.2958
  const lat2 = b.lat / 57.2958
  const lon2 = b.lon / 57.2958

  // distance = circle radius from center to Northeast corbr of bounds
  return earthRadius * Math.acos(Math.sin(lat1) * Math.sin(lat2) +
  Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1))
}

export function AGDistanceToFunction({location}) {
  this.call = value => distanceBetween(location, value)
  this.displayString = 'sorting.distanceTo'
  this.args = [
    {
      name: 'location',
      type: () => columnTypes.geolocation,
      value: location
    }
  ]
  this.exportFormat = () => {
    return {
      $distanceTo: {location}
    }
  }
}
AGDistanceToFunction.displayString = 'sorting.distanceTo'
AGDistanceToFunction.args = [
  {
    name: 'location',
    type: () => columnTypes.geolocation
  }
]


export const SORTING_FUNCTIONS = {
  $distanceTo: AGDistanceToFunction
}