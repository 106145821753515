<template>
  <v-input
    v-model="inputValue"
    :rules="[this.rules.required, this.rules.schema]"
    :hide-details="description || errors ? 'auto' : false"
    :disabled="disabled"

  >
    <BaseFormInput v-bind="$props" class="full-width">
      <div v-if="!fieldStyle.labelAbove" class="v-label mt-1">{{label}}</div>
      <v-list :color="cardColor"  flat class="pb-0">
      <v-list-item-group
        v-model="groupModel"
        @change="value => this.inputValue = value"
        :multiple="isCollection"
        :color="color"
        :disabled="disabled"
              >
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :value="item"
          :disabled="disabled"
                  >
        <template v-slot:default="{ active }">
          <v-list-item-action class="mr-4">
            <v-checkbox
              dense
              v-if="isCollection"
              :input-value="active"
              :color="color"
              :disabled="disabled"

            />
            <v-icon
              small
              v-else
              :color="radioColor(active)"
            >{{radioIcon(active)}}</v-icon>
          </v-list-item-action>
                <v-list-item-content>
                  {{item}}
                </v-list-item-content>
              </template>
        </v-list-item>
        <v-list-item
          v-if="allowOtherOption"
          :value="otherOptionSelected"

        >
        <template v-slot:default="{ active }">
          <v-list-item-action class="mr-4">
            <v-checkbox
              v-if="isCollection"
              :input-value="active"
              :color="color"
              dense
              :disabled="disabled"
            />
            <v-icon
              v-else
              small
              :color="radioColor(active)"
            >{{radioIcon(active)}}</v-icon>
          </v-list-item-action>
                <v-list-item-content>
                  <v-text-field
                    v-model="otherOption"
                    :disabled="disabled"
                    @input="value => inputValue = value"
                    dense :hide-details="'auto'"
                    :placeholder="$t('virtualGrid.enumOtherOption')"
                  />
                </v-list-item-content>
              </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    </BaseFormInput>
  </v-input>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components//form/BaseFormInput.vue'

export default {
  mixins: [formFields],
  data() {
    return {
      groupModel: undefined,
      otherOption: undefined
    }
  },
  watch: {
    inputValue: {
      immediate: true,
      handler(newVal) {
        if (newVal == null) {
          return
        }
        if (this.containsAll(newVal)) {
          this.groupModel = newVal
        } else {
          this.otherOption = newVal
          this.groupModel = this.otherOptionSelected
        }
      }
    }
  },
  computed: {
    otherOptionSelected() {
      return 'eabd433d-e47b-44bd-8abf-f60e2985c3fe'
    },
    allowOtherOption() {
      return this.fieldType.extended
    },
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value === this.otherOptionSelected ? this.otherOption : value)
      }
    },
    isCollection() {
      return this.fieldType.jsonSchema.type === 'array'
    },
    items() {
      if ((!this.isCollection && !this.fieldType.jsonSchema.enum) || (this.isCollection && !this.fieldType.jsonSchema.items.enum)) {
        return []
      }
      return this.isCollection ? this.fieldType.jsonSchema.items.enum : this.fieldType.jsonSchema.enum
    },
    otherOptionRules() {
      return this.groupModel === this.otherOptionSelected
         ? [this.rules.required, this.rules.schema]
         : []
    }
  },
  methods: {
    radioIcon(active) {
      return active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'
    },
    radioColor(active) {
      return active ? this.color : undefined
    },
    containsAll(newVal) {
      if (!this.isCollection) {
        return this.items.includes(newVal)
      }
      return newVal.every(item => this.items.includes(item))
    }
  },
  components: {
    BaseFormInput
  }
}
</script>
<style scoped>
.full-width {
  outline: none;
  width: 100%;
}
</style>
