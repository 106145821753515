<template>
  <div class="canvas">
    <svg ref="connector" class="connector" :class="{scaled: highlight}">
      <circle
        stroke="#f7f7f7"
        stroke-width="3"
        class="circle"
        :class="{draggable: !disabled}"
        :r="radius - 3"
        :cx="radius"
        :cy="radius"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    center: null,
    radius: null,
    unconnected: null,
    end: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    },
    color: null
  },
  data() {
    return {
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      selected: false,
    }
  },
  mounted() {
    if(this.unconnected == true && !this.disabled) {
      this.$refs.connector.onmousedown = this.dragMouseDown
    }
  },
  watch: {
    center(newVal) {
      if(newVal) {
        this.$refs.connector.style.top = (newVal.y - this.radius ) + 'px'
        this.$refs.connector.style.left = (newVal.x - this.radius) + 'px'
        this.$refs.connector.setAttribute('height', ( this.radius * 2 ) + 'px')
        this.$refs.connector.setAttribute('width', ( this.radius * 2 ) + 'px')
      }
    }
  },
  methods: {
    dragMouseDown(e) {
      e = e || window.event
      e.preventDefault()
      e.stopPropagation()
      // set selected state
      this.selected = true

      // get the mouse cursor position at startup:
      this.pos3 = e.clientX
      this.pos4 = e.clientY

      window.document.onmouseup = this.closeDragElement
      // call a function whenever the cursor moves:
      window.document.onmousemove = this.elementDrag
    },
    elementDrag(e) {
      e = e || this.window.event
      e.preventDefault()

      // set the element's new position:
      this.pos1 = this.pos3 - e.clientX
      this.pos2 = this.pos4 - e.clientY
      this.pos3 = e.clientX
      this.pos4 = e.clientY

      let top = (parseInt(this.$refs.connector.style.top) - this.pos2)
      let left = (parseInt(this.$refs.connector.style.left) - this.pos1)
      this.$refs.connector.style.top = top + 'px'
      this.$refs.connector.style.left = left + 'px'

      if(this.unconnected) {
        this.$emit('moved', { x: left + parseInt(this.radius) , y: top + parseInt(this.radius) })
      }

    },
    closeDragElement(e) {
      e = e || this.window.event
      e.preventDefault()
      e.stopPropagation()
      // stop moving when mouse button is released:
      window.document.onmouseup = null
      window.document.onmousemove = null
      // move back to inital position if not connected
      if(this.unconnected) {
        this.$refs.connector.style.top = (this.center.y - this.radius ) + 'px'
        this.$refs.connector.style.left = (this.center.x - this.radius) + 'px'
        this.$emit('moved', { x: this.center.x , y: this.center.y })
        this.$emit('movedFinish', { x: this.center.x , y: this.center.y })
      }
    },
  },
}
</script>

<style lang="css" scoped>
.canvas {
  width:100%;
  height:100%;
}
.connector {
  position: absolute;
  z-index: 2;

}
.draggable:hover {
  cursor: cell;
}

.scaled {
  transition: transform .2s;
  transform: scale(1.3);
}
</style>
