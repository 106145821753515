<template>
  <div>
    <div class="d-flex justify-end align-center gap-3" v-if="!disabled">
      <v-label>Map</v-label>
      <v-btn
        small
        icon
        @click="toggleScriptMode"
      >
        <v-icon :color="toggleColor">{{ toggleIcon }}</v-icon>
      </v-btn>
    </div>
    <div v-if="!disabled && scriptMode">
      <TemplateInput
        v-bind="$attrs"
        ref="scriptInput"
        :value="value"
        @input="newVal => setScriptValue(newVal)"
        @blur="$emit('blur')"
      />
    </div>
    <slot v-else />
  </div>
</template>

<script>
import ASScriptHolder from '@/apptivescript/model/ASScriptHolder'
import ASTemplateHolder from '@/apptivescript/model/ASTemplateHolder'
import TemplateInput from '@/components/flow/TemplateInput.vue'
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [ externalModel ],
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      scriptMode: false
    }
  },
  computed: {
    toggleIcon() {
      return this.scriptMode ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline'
    },
    toggleColor() {
      return this.scriptMode ? 'primary' : 'grey'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal instanceof ASScriptHolder || newVal instanceof ASTemplateHolder) {
          this.scriptMode = true
        } else {
          this.scriptMode = false
        }
      }
    }
  },
  methods: {
    async toggleScriptMode() {
      const currentScriptMode = this.scriptMode
      this.externalModel = null
      await this.$nextTick()
      this.scriptMode = !currentScriptMode
    },
    setScriptValue(newVal) {
      this.externalModel = newVal
    },
    insert(text) {
      if (this.disabled) {
        return false
      }

      if (this.scriptMode) {
        this.$refs.scriptInput.insert(text)
        return true
      }
      return false
    },
  },
  components: {
    TemplateInput,
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 14px;
}
</style>