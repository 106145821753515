export default {
  default: {
    solo: false,
    dense: false,
    flat: false,
    class: null,
    labelAbove: false,
  },
  outlined: {
    solo: true,
    dense: true,
    flat: true,
    class: 'outlined',
    labelAbove: true,
    light: true
  },
  outlinedWithoutLabel: {
    solo: true,
    dense: true,
    flat: true,
    class: 'outlined',
    labelAbove: false,
    autofocus: true
  },
}