<template>
    <v-dialog v-model="dialog" persistent max-width="290" v-bind="$attrs" :disabled="disabled">
      <template v-slot:activator="{ on, attrs }">
        <MenuItem
          :disabled="disabled"
          v-on="on" v-bind="attrs"
          icon="mdi-delete"
          :text="$t('dialogs.deleteGridButton')"
          data-testid="openDeleteGridDialogButton"
        />
      </template>
      <v-card color="black">
        <v-card-title
          class="text-h5 white--text"
          v-html="$t('dialogs.deleteGridTitle', { gridTitle })"
        />
        <v-card-text class="white--text">{{
          $t("dialogs.areYouSure")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialog = false">
            {{ $t("dialogs.cancelButton") }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            data-testid="deleteButton"
            @click="deleteGrid"
          >
            {{ $t("dialogs.deleteButton") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'

export default {
  name: 'DeleteGridDialog',
  props: {
    gridUri: null,
    disabled: null
  },
  data() {
    return {
      dialog: false,
      newName: null
    }
  },
  computed: {
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    gridTitle() {
      return this.grid?.title()
    }
  },
  methods: {
    deleteGrid() {
      this.$emit('deleteGrid')
      this.dialog = false
    }
  },
  components: {
    MenuItem
  }
}
</script>

<style lang="css"></style>
