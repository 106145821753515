import userAvatar from './userAvatar'
import ApiClient from '@/ApiClient'
import { ALPHA_MODE_KEY, DEVELOPER_MODE_KEY } from '@/plugins/feature'
import * as auth from '@/utils/auth.js'
import apptiveErrorReporter from '@/plugins/apptiveErrorReporter.js'

export default {
  modules: {
    userAvatar
  },
  state() {
    return {
      user: undefined,
      jsonWebToken: null,
      id: null,
      alphaMode: false,
      apiKeys: [],
      developerMode: false,
    }
  },
  mutations: {
    setUser(state, user) {
      user.uri = user._links.self.href
      state.user = user
    },
    setJsonWebToken(state, payload) {
      state.jsonWebToken = payload
    },
    setAuthConfig(state, payload) {
      state.authConfig = payload
    },
    setAlphaMode(state, payload) {
      state.alphaMode = payload
      localStorage.setItem(ALPHA_MODE_KEY, payload)
    },
    setDeveloperMode(state, payload) {
      state.developerMode = payload
      localStorage.setItem(DEVELOPER_MODE_KEY, payload)
    },
    setId(state, payload) {
      state.id = payload
    },
    setApiKeys(state, apiKeys) {
      state.apiKeys = apiKeys
    },
    deleteCredentials(state, credentials) {
      const index = state.apiKeys.indexOf(credentials)
      if (index >= 0) {
        state.apiKeys.splice(index, 1)
      }

    }
  },
  actions: {
    async loadUser(context, reload) {
      if (context.state.id && !reload) {
        return
      }
      var response = null
      try {
        response = await context.dispatch(
          'AGReadUserOperation',
          '/api/users/me'
        )
        context.commit('setId', response.data.id)
        context.commit('setUser', response.data)
        context.commit('setSpacesList', response.data._embedded.spaces)
        context.dispatch('initAvatarUrl', response.data.id)
      } catch (error) {
        error.okHandler = () => {
          auth.logout()
        }
        apptiveErrorReporter.captureException(error)
      }
      return response
    },
    AGReadUserOperation(context, uri) {
      ApiClient.onTokenChanged( token => {
        context.commit('setJsonWebToken', token)
      })
      return ApiClient.getUri(uri).then(response => {
        return response
      })
    },
    loadApiKeys(context) {
      const uri = context.getters.accessCredentialsUri
      if(!uri) {
        context.commit('setApiKeys', [])
        return Promise.resolve([])
      }
      return ApiClient.getUri(uri)
        .then(response => {
          context.commit('setApiKeys', response.data.items)
          return response.data.items
        })
    },
    createApiKey(context, {name, role}) {
      return ApiClient.postUri(
        context.getters.createCredentialsUri,
        { name, role }
      ).then(response => response.data)
    },
    deleteCredentials(context, credentials) {
      return ApiClient.deleteUri(credentials._links.self.href)
        .then(() => context.commit('deleteCredentials', credentials))
    },
    deleteUser() {
      return auth.deleteUser()
        .then(() => auth.logout())
    }
  },
  getters: {
    userLoggedIn(state) {
      return state.user != null
    },
    userUri(state) {
      return '/api/users/' + state.id
    },
    userId(state){
      return state.id
    },
    userMail(state){
      return state.user?.email
    },
    userHalLink: (state) => (link) => {
      return state.user?._links[link]?.href
    },
    spacesUri(state) {
      return state.user?._links.spaces.href
    },
    accessCredentialsUri(state) {
      return state.user?._links.accessCredentials?.href
    },
    createCredentialsUri(state) {
      return state.user?._links.addAccessCredentials?.href
    },
    alphaMode(state) {
      return state.alphaMode
    },
    developerMode(state) {
      return state.developerMode
    },
    avatarSrc(state) {
      return state.userAvatar.avatarSrc
    },
    userPlan(state){
      return state.user?.plan
    },
    availableSeats(state) {
      return state.user?.availableSeats
    },
    jsonWebToken(state) {
      return state.jsonWebToken
    }
  }
}
