<template>
  <v-menu
    v-if="virtualGrid"
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    :nudge-width="$vuetify.breakpoint.mobile ? 300 : 0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-1"
        data-testid="gridFilterButton"
        :color="buttonColor"
        v-bind="attrs"
        v-on="on"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
        text
        :style="buttonBackground"
        small>
        <v-icon small class="mr-sm-2">mdi-filter-variant</v-icon>
        <span class="d-none d-sm-flex">{{filterText}}</span>
      </v-btn>
    </template>

    

    <v-card
      class="pa-1 pl-3 overflow-auto"
      @mouseenter="showControls = true"
      @mouseleave="showControls = false"
    >
      <div v-if="this.isActive" class="ma-3 text-caption">{{$t('virtualGrid.filter.explanation')}}</div>
      <FilterGroup
        :showControls="showControls"
        :disabled="disabled"
        class="pa-1"
        :class="{'mt-3': !this.isActive}"
        :virtualGrid="virtualGrid"
        :filter="filter"
        @filterChanged="filterChanged"
        :nestingLevel="0"
        data-testid="gridFilter">
      </FilterGroup>
    </v-card>
  </v-menu>
</template>

<script>
import FilterGroup from './FilterGroup.vue'
import colors from '@/constants/colors.js'
import { AGNoCondition } from '@/filter/conditions.js'

export default {
  props: {
    virtualGrid: null,
    disabled: null
  },
  data() {
    return {
      menu: false,
      showControls: true,
      filter: undefined
    }
  },
  watch: {
    menu(newVal) {
      if(newVal) {
        this.filter = this.virtualGrid?.filter instanceof AGNoCondition ? undefined : this.virtualGrid?.filter
      }
    },
  },
  mounted() {
    this.filter = this.virtualGrid?.filter instanceof AGNoCondition ? undefined : this.virtualGrid?.filter
  },
  computed: {
    isActive() {
      return this.virtualGrid?.isFiltered()
    },
    buttonColor() {
      return this.isActive ? 'primary' : 'accent'
    },
    buttonBackground() {
      return {
        background: this.isActive ? colors.filter : undefined
      }
    },
    filteredColumnIds() {
      return this?.virtualGrid.filteredColumnIds() ?? []
    },
    filterText() {
      return this.$tc('filterToolBar.filter', this.filteredColumnIds.length)
    }
  },
  methods: {
    filterChanged(filter) {
      this.virtualGrid.setFilter(filter)
      this.filter = filter
    }
  },
  components: {
    FilterGroup
  }
}
</script>

<style scoped>
.overflow-auto {
  overflow: auto;
}
</style>