import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class EmbedBlock extends Block {
  constructor(data) {
    super(data)
    this.IFrame = data.IFrame
    this.isDirty = false
  }

  get embedUri() {
    return this.IFrame?.uri
  }

  setEmbedUri(newVal) {
    if (newVal === this.IFrame.uri) {
      return
    }
    this.IFrame.uri = newVal
    this.isDirty = true
  }

  get height() {
    return this.IFrame?.height
  }

  setHeight(newVal) {
    if (newVal === this.IFrame.height) {
      return
    }
    this.IFrame.height = newVal
    this.isDirty = true
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        IFrame: {
          uri: this.embedUri,
          height: this.height
        }
      }
    })
    this.isDirty = false
  }
}