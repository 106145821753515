<template>
  <BaseKanbanAttribute v-bind="$attrs" :height="80" :titleHeight="18">
    <template v-slot:default="{ attribute }">
      <div class="textOverflow text-body-2" v-html="attribute.value"/>
    </template>
  </BaseKanbanAttribute>
</template>

<script>
import BaseKanbanAttribute from './BaseKanbanAttribute.vue'

export default {
    components: { BaseKanbanAttribute }
}
</script>

<style scoped>
.kanban-column-title {
  color: grey;
}

.textOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  word-break: break-word;
}

::v-deep.textOverflow h1 {
  font-size: 1.4em;
}

::v-deep.textOverflow h2 {
  font-size: 1.3em;
}
</style>
