<template>
  <div class="d-flex flex-column gap-3">
    <component
      v-for="(component, index) in children"
      :key="index"
      :selected="isSelected(component)"
      :ref="`input-${component.props.key}`"
      :is="component.component" v-bind="component.props" :flow="flow"
      :value="internalModel[component.props.key]"
      @input="newVal => update(component.props.key, newVal)"
      :disabled="disabled"
      @click.native="(event) => componentClicked(event, component)"
      @componentClicked="(event, childComponent) => componentClicked(on, event, childComponent)"
      @focus="() => componentFocused(component)"
      @componentFocused="(childComponent) => componentFocused(childComponent)"
      @blur="onBlur"
      @patchNodeGrid="patchNodeGrid"
      :data-testid="`input_${component.props.key}`"
      topLabel
    />
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import Vue from 'vue'
import externalModel from '@/mixins/externalModel'

  export default {
    mixins: [externalModel],
    props: {
      children: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: () => false
      },
      flow: null
    },
    data() {
      return {
        openPanels: this.children.map((item, i) => i),
        lastSelectedComponentKey: undefined,
        internalModel: undefined
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          if (newVal == null || typeof newVal !== 'object') {
            this.internalModel = this.initialModel()
          } else {
            this.internalModel = {...newVal}
          }
        }
      }
    },
    methods: {
      initialModel() {
        if (this.children == null) {
          return {}
        }
        let model = {}
        this.children
          .forEach(child => model[child.component.props.key] = '')
        return model
      },
      update(key, newVal) {
        Vue.set(this.internalModel, key, newVal)

        // Allow setting all values to null :
        if (Object.values(this.internalModel).every(val => val == null || (val?.length ?? 0) === 0)) {
          this.externalModel = null
          return
        }

        this.externalModel = this.internalModel
      },
      insert(text) {
        const lastActiveInput = this.$refs[`input-${this.lastSelectedComponentKey}`][0]
        lastActiveInput.insert(text)
      },
      isSelected(component) {
        return isEqual(component.props.key, this.lastSelectedComponentKey)
      },
      componentClicked(event, component) {
        this.$emit('componentClicked', event, component)
      },
      componentFocused(component) {
        this.lastSelectedComponentKey = component.props.key
        this.$emit('componentFocused', component)
      },
      onBlur() {
        this.$emit('blur')
      },
      patchNodeGrid() {
        this.$emit('patchNodeGrid')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>