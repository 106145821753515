<template>
  <BaseFormInput v-bind="$props">
    <v-text-field
    :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema, uriRule]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      @blur="finishEditing"
      v-model="model"
      :color="color"
      :disabled="disabled"
      v-bind="fieldStyle"
    />
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import uriInputMixin from '@/mixins/uriInputMixin.js'
import BaseFormInput from '@/components/form/BaseFormInput.vue'

export default {
  mixins: [formFields, uriInputMixin],
  data() {
    return {
      uriRule: v => (v == null || /.+:.+/.test(v)) || this.$t('validation.uri')
    }
  },
  components: {
    BaseFormInput
  }
}
</script>
