<template>
<div
  class="px-1 d-flex align-center cell-content"
  v-on="$listeners"
  v-bind="$attrs"
>
  <v-chip
    v-if="this.value && currentType"
    pill
    small
  >
    <v-avatar
      left
      max-height="24"
      max-width="24"
    >
      <v-img
        v-if="currentType.avatarUrl"
        :src="currentType.avatarUrl"
        lazy-src="avatar.svg"
        max-height="24"
        max-width="24"
        @error="onAvatarError"
      />
      <v-icon
        v-else
        small
        class="iconBackground"
        :style="`background-color: ${currentType.iconType.color}`"
        color='white'
      >{{currentType.iconType.icon}}</v-icon>
    </v-avatar>
    {{currentType.displayValue}}
  </v-chip>
</div>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import {ViewIconTypes} from '@/constants/viewIconTypes.js'

export default {
  mixins: [gridCells],
  data() {
    return {
      avatarError: false
    }
  },
  computed: {
    types() {
      return {
        user: {
          displayValue: this.value?.displayValue,
          avatarUrl: this.avatarError ? 'avatar.svg' : this.$store.getters.avatarUrlOf(this.id)
        },
        link: {
          displayValue: this.value?.displayValue || this.$t('createdBy.formLink'),
          iconType: ViewIconTypes.FORM
        },
        accesscredentials: {
          displayValue: this.value?.name || this.$t('createdBy.accessKey'),
          iconType: ViewIconTypes.ACCESS_KEY
        }
      }
    },
    currentType() {
      return this.types[this.value?.type] ?? this.types.user
    },
    id() {
      return this.value?.id ?? this.value?.uri.split('/').pop()
    }
  },
  methods: {
    onAvatarError() {
      this.avatarError = true
    },
    onFocus() {
      console.log('focus')
      this.$emit('focus')
    },
    onBlur() {
      console.log('blur')
      this.$emit('blur')
    }
  }
}
</script>