<template>
<v-checkbox
  :style="conditionalVisibility"
  :indeterminate="indeterminate"
  :disabled="readonly"
  ref="input"
  small
  class="mt-0 ml-2"
  type="text"
  name=""
  hide-details="auto"
  @click="$emit('focus')"
  v-click-outside="onClickOutside"
  v-model="model"
  @mouseenter.native="showControls = true"
  @mouseleave.native="showControls = false"
/>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import { noValue } from '@/utils/jsUtils.js'

export default {
  name: 'GridBooleanCell',
  mixins: [gridCells],
  props: {
    focused: null
  },
  mounted() {
    if (this.editEntityMode) return
    this.$emit('focus')
  },
  beforeDestroy() {
    this.$emit('blur')
  },
  data() {
    return {
      showControls: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    indeterminate() {
      return noValue(this.model)
    },
    conditionalVisibility() {
      return noValue(this.model) && !this.showControls ? {opacity: '0'} : undefined
    }
  },
  methods: {
    onClickOutside() {
      // TODO remove when the fix for v-checkbox focus/blur is deployed in vuetify
      if (this.focused) {
        this.$emit('blur')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
