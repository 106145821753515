<template>
  <div style="height: 100%;">
    <SpaceAppBar :spaceUri="spaceUri" />
    <v-toolbar
      flat
      dense
      class="toolbar"
      v-if="this.$vuetify.breakpoint.mobile"
    >
      <v-btn
        class="collapse-button"
        @click="showNavigationList = !showNavigationList"
        color="accent"
        text
        small
      >
        <v-icon v-if="showNavigationList">mdi-chevron-double-left</v-icon>
        <v-icon  v-else>mdi-chevron-double-right</v-icon>
      </v-btn>
    </v-toolbar>
    <div
      class="d-flex flex-row"
      style="height: 100%; background-color: white;"
    >
      <div
        class="navigation-list"
        :class="{collapsed: !showNavigationList}"
        v-if="hasBlocks"
      >
        <div class="d-flex align-center px-3">
          <v-subheader class="pl-0 pr-0">{{ $t('blocks.pages') }}</v-subheader>
          <v-spacer />
          <v-btn
            v-if="canAddPage"
            :loading="loading"
            x-small
            icon
            @click="addPage"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

        <div >

          <BlockNavigation
            :spaceUri="spaceUri"
            :showNavigationList="showNavigationList"
            >

          </BlockNavigation>
    </div>

      </div>
        
      <v-progress-circular
        indeterminate
        v-if="loading"
        color="primary"
        width="2"
        size="36"
        class="loader"
      />
        <router-view v-else-if="hasBlocks" />

        <v-container v-else>
          <div class="d-flex flex-column align-center justify-center mt-12">
           <v-img  src="/blocks_empty_space.svg"></v-img>
           <div class="text-h6">
               {{ $t("blocks.emptyStateBlocksHeader") }}
           </div>
           <div style="max-width: 420px;" class="text-center accent--text mt-2">
               {{ $t("blocks.emptyStateBlocksDescription") }}
           </div>
           <v-btn
             :disabled="!canAddPage"
             data-testid="addPageButtonEmptyState"
             color="primary"
             class="mt-6"
             @click="addPage"
           >
               {{ $t("blocks.emptyStateBlocksAddPage") }}</v-btn>
          </div>
        </v-container>
        </div>
  </div>
</template>

<script>

import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import SpaceAppBar from '@/views/SpaceAppBar.vue'
import BlockNavigation from '@/components/block/editor/BlockNavigation.vue'


export default {
  props: {
    spaceUri: null,
  },
  data() {
    return {
      loading: false,
      showNavigationList: true,
    }
  },
  mounted() {
    document.title = `${this.space?.title()} / ${this.$t('blocks.pages')}`
  },
  watch: {
    spaceUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: newVal
          })
          await this.listBlocks()
          if ( this.$route.name === 'BlockEditor' ) {
            this.initialNavigation()
          }
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    hasBlocks() {
      return this.blocks.length > 0
    },
    canAddPage() {
      return hasPermission(this.space, [PERMISSIONS.addBlock])
    },
    blocks () {
      return this.$store.getters.blocks
    }
  },
  methods: {
    initialNavigation() {
      if (this.blocks.length >= 1) {
        this.$router.replace({ name: 'BlockPage', params: { blockUri: this.blocks[ 0 ].uri } })
      }
      else if (this.$route.name !== 'BlockEditor') {
        // once the last blockpage was deleted, switch to the BlockEditor route
        this.$router.replace({ name: 'BlockEditor'})
      }
    },
    async listBlocks() {
        await this.$store.dispatch('AGListSpaceBlocks', { space: this.space})
    },
    async addPage() {
      this.loading = true
      try {
        const newBlock = await this.$store.dispatch('AGAddPageToBlockOrSpace', this.space)
        await this.listBlocks()
        this.$router.push({ name: 'BlockPage', params: { blockUri: newBlock.uri } }) 
      } finally {
        this.loading = false
      }
    },
    async patchName(block, newName) {
      await block.patchName(newName)
      block.name = newName
    },
    async blockDeleted(block) {
      await this.listBlocks()
      if (this.$route.name === 'BlockPage' && this.$route.params.blockUri === block.uri) {
        this.initialNavigation()
      }
    }
  },
  components: {
    SpaceAppBar,
    BlockNavigation
  }
}
</script>

<style
  lang="scss"
  scoped
>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
}
.navigation-list {
  background-color: white;
  border-right: 1px solid #e4e5e4;
  width: 240px;
  flex-shrink: 0;
  overflow: auto;
  transition: width 0.2s;
}

.navigation-list.collapsed {
  width: 0px;
  border: none;
}

.toolbar {
  z-index: var(--grid-toolbar-zindex);
  border-style: none none solid none;
  border-width: 1px;
  border-color: #d3d2d4;
  background-color: white;
}
</style>