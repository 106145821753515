import { ASHolder } from '@/apptivescript/model/ASHolder'

export default class ASScriptHolder extends ASHolder{
  constructor(data) {
    super()
    this._script = data._script
  }

  get value() {
    return this._script
  }

  // HACK : this is used to keep the curly braces around
  // an expression when building a SpecialisedHttpRequestNode mapped property
  // (see SpecialisedHttpRequestNode:updateProperties)
  get componentOutputValue() {
    return `{{${this.value}}}`
  }
}