<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="320"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div>
        <Avatar
          v-if="hasAvatar"
          size="36"
          @click.native="on.click"
          style="cursor: pointer;"
          :email="email"
        />
        <v-btn v-else data-testid="userInfoButton" icon v-on="on">
          <v-icon>mdi-account-circle-outline</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card>
      <div class="d-flex pa-3">
        <AvatarEditor />
        <div class="d-flex flex-column">
          <div class="bold" data-testid="userFullName">{{ fullName }}</div>
          <div data-testid="userEmail">{{ email }}</div>
          <router-link class="settings-link" :to="{ name: 'Profile' }">{{
            $t('settings.accountSettingsButton')
          }}</router-link>
        </div>
      </div>

      <v-divider></v-divider>

      <v-btn text class="ml-3 mt-3" @click="logout">{{
        $t('views.logoutButton')
      }}</v-btn>
      <Version class="mr-2 text-right"></Version>
    </v-card>
  </v-menu>
</template>

<script>
import Version from '@/components/Version.vue'
import AvatarEditor from '@/components/user/AvatarEditor.vue'
import Avatar from '@/components/user/Avatar.vue'
import { logout } from '@/utils/auth'

export default {
  name: 'UserProfile',
  props: {},
  data: () => ({
    menu: false
  }),
  computed: {
    email() {
      return this.$store.state.user.user?.email
    },
    fullName() {
      return `${this.$store.state.user.user?.firstName} ${this.$store.state.user.user?.lastName}`.trim()
    },
    userId() {
      return this.$store.getters.userId
    },
    hasAvatar() {
      return this.$store.getters.avatarSrc
    }
  },
  methods: {
    logout() {
      logout()
    }
  },
  components: {
    Version,
    AvatarEditor,
    Avatar
  }
}
</script>

<style lang="css">
.bold,
.settings-link {
  font-weight: bold;
}

.settings-link {
  text-decoration: unset;
}
</style>
