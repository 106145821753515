<template>
   <v-sheet width="164" height="28" rounded class="pa-1 pr-2" color="white" elevation="3">
        <a
            href="https://www.apptivegrid.de?utm_source=app-apptivegrid&utm_medium=shared-link&utm_campaign=apptive-form-badge"
            class="font font-weight-medium d-flex justify-start align-center"
            target="_blank"
            rel="noopener noreferrer"
        >
            <v-img
            src="/Icon.svg"
            width="18"
            height="18"
            contain
            ></v-img>
            <span class="ml-1">Made in ApptiveGrid</span>
        </a>
   </v-sheet>
</template>

<script>
export default {
}
</script>

<style scoped>
.font {
    font-size: 13px !important;
    color: rgb(53, 53, 53) !important;
    text-decoration: none !important;
}
</style>