import FormInputBlock from '@/store/models/blocks/FormInputBlock'
import HeaderBlock from '@/store/models/blocks/HeaderBlock'
import PageBlock from '@/store/models/blocks/PageBlock'
import ParagraphBlock from '@/store/models/blocks/ParagraphBlock'
import EmbedBlock from '@/store/models/blocks/EmbedBlock'
import StatefulFormInputBlock from '@/store/models/blocks/StatefulFormInputBlock'
import VideoBlock from '@/store/models/blocks/VideoBlock'
const typeMap = {
  page: PageBlock,
  paragraph: ParagraphBlock,
  header: HeaderBlock,
  enum: StatefulFormInputBlock,
  IFrame: EmbedBlock,
  videoInput: VideoBlock,
}

export function createBlock(blockData) {
  var block
  if (blockData.type in typeMap) {
    block = new typeMap[blockData.type](blockData)
  } else {
    block = new FormInputBlock(blockData)
  }
  block.children = blockData.children ? blockData.children.map(child => createBlock(child)) : []
  return block
}
