/* global ASParser ApptiveScript ASFlowInstanceScope
ASMethodChainAnnotation ASAnyPropertyChainAnnotation ASTokenMatcher
ASFunctionCallNode ASFormattingVisitor ASEntityScope */

export class ASExpression {
  constructor(string) {
    this.originalString = string
    this.value = ASParser.as_parse_(string)
  }

  get displayString() {
    if (this.value.arguments != null) {
      return this.value.arguments[0].value.toString()
    }

    return this.value.property
  }

  typeInFlowTestInstance(flow) {
    const scope = scopeFrom(flow)
    return ApptiveScript.as_returnType_inScope_(this.originalString, scope)
  }

  receiverTypeInFlowTestInstance(flow) {
    const scope = scopeFrom(flow)
    return ApptiveScript.as_receiverReturnType_inScope_(this.originalString, scope)
  }

  get isFunctionCall() {
    return this.value instanceof ASFunctionCallNode
  }

  get receiverCodeString() {
    return this.value.function.receiver.as_codeString()
  }

  get functionName() {
    return this.value.function.property
  }

  get firstArgument() {
    return this.value.arguments[0]?.value
  }
}

export function scopeFrom(flow) {
  const scope = new ASFlowInstanceScope()
  scope.as_onStepDo_((nodeId) => flow.testInstance.steps.find(step => step._links.node.href.split('/').pop() === nodeId))
  return scope
}

export function listTypes() {
  return  ApptiveScript.as_listTypes()
}

export class ASValueTreeNode {
  constructor(object) {
    this.decorated = object
    if (object == null) {
      return
    }
    this.value = 'as_apptiveScriptQuery' in object ? object : object.as_asApptiveScriptValueTreeNode()
  }

  get query() {
    return this.value?.as_apptiveScriptQuery()
  }

  get children() {
    return this.value?.as_children().map(child => new ASValueTreeNode(child))
  }
}

import Token from '@/components/flow/Token.vue'

export function annotateStepOutputTokens(ast) {
  const getRule = new ASMethodChainAnnotation()
    getRule.as_from_('step')
    getRule.as_to_('get')
    getRule.as_greedy_(true)

    const atRule = new ASMethodChainAnnotation()
    atRule.as_from_('step')
    atRule.as_to_('at')
    atRule.as_greedy_(true)

    const dotRule = new ASAnyPropertyChainAnnotation()
    dotRule.as_from_('step')

    const matcher = new ASTokenMatcher
    matcher.as_match_with_(ast, getRule)
    matcher.as_match_with_(ast, atRule)
    matcher.as_match_with_(ast, dotRule)
}

function lookupStepId(node) {
  if (node == null) {
    return null
  }
  if (node.receiver?.function?.property === 'step') {
    return node.receiver?.arguments[0]?.value
  }
  return lookupStepId(node.receiver ?? node.function.receiver)
}

export class ExpressionVisitor extends ASFormattingVisitor {
  constructor(grid, flow) {
    super()
    this.grid = grid
    this.flow = flow
    this.tokens = []
  }

  renderToken(tokenProps) {
    return tokenProps
  }

  as_visitFunctionCallNode_(node) {
    if (node.function.property === 'fieldValue') {
      const field = this.grid.fields.find(field => field.id === node.arguments[0].value)
      const div = this.renderToken({
          text: (field?.name) ?? 'Unknown Field',
          color: '#007affff',
          value: node.as_codeString()
      })
      this.tokens.push(div)
    } else {
      super.as_visitFunctionCallNode_(node)
    }
  }

  as_visitPropertyLookupNode_(node) {
    const div = this.renderToken({
      text: node.property,
      color: 'purple',
      value: node.property
    })
    super.as_visitPropertyLookupNode_(node)
    this.tokens.push(div)
  }

  as_visitLiteralValueNode_(node) {
    const div = this.renderToken({
      text: node.value,
      color: '#30d158ff',
      value: node.as_codeString()
    })
    this.tokens.push(div)
    super.as_visitLiteralValueNode_(node)
  }

  as_processVariable_(variable) {
    const div = this.renderToken({
      text: variable,
      color: 'purple',
      value: variable
    })
    this.tokens.push(div)
    super.as_processVariable_(variable)
  }

  as_processArgumentListSeparator() {
    const div = this.renderToken({
      text: ',',
      color: '#616161',
      value: ','
    })
    this.tokens.push(div)
  }

  as_processBinarySelector_(node) {
    const div = this.renderToken({
      text: node,
      color: '#5856D6',
      value: node
    })
    this.tokens.push(div)
  }

  as_processCloseParentheses() {
    const div = this.renderToken({
      text: ')',
      color: '#616161',
      value: ')',
    })
    this.tokens.push(div)
  }

  as_processDot() {
    const div = this.renderToken({
      text: '.',
      color: '#616161',
      value: '.',
    })
    this.tokens.push(div)
  }

  as_processOpenParentheses() {
    const div = this.renderToken({
      text: '(',
      color: '#616161',
      value: '(',
    })
    this.tokens.push(div)
  }

  as_visitNode_(node) {
    super.as_visitNode_(node)
  }

  as_visit_(node) {
    if(node.as_propertyAt_('tokenEnd')) {
      const name = node.property ?? node.arguments?.[0].name ?? node.arguments?.[0].value
      const stepId = lookupStepId(node)
      const flowNode = this.flow.nodes.find(item => item.id === stepId)
      const div = this.renderToken({
          text: name ?? 'Unknown Node',
          color: flowNode?.color ?? 'red',
          value: node.as_codeString()
      })
      this.tokens.push(div)
    } else {
      super.as_visit_(node)
    }
  }
}

export class RenderFunctionExpressionVisitor extends ExpressionVisitor {
  constructor(h, grid) {
    super()
    this.h = h
    this.grid = grid
    this.tokens = []
  }

  renderToken(tokenProps) {
    return this.h(Token, {
      props: tokenProps
    })
  }
}

export class EntityScope extends ASEntityScope {
  constructor(grid, entity) {
    super()
    this.grid = grid
    this.entity = entity
  }

  as_fieldValueForFieldId_(fieldId) {
    const fieldIndex = this.grid.fields.findIndex(field => field.id === fieldId)
    return this.entity.fields[fieldIndex]
  }
}

export class PathDisplayVisitor extends ASFormattingVisitor {
  constructor(flow) {
    super()
    this.pathStrings = []
    this.flow = flow
  }

  get pathString() {
    return this.pathStrings.join('.')
  }

  as_visitFunctionCallNode_(node) {
    if (node.function.property === 'step') {
      const flowNode = this.flow.nodes.find(item => item.id === node.arguments[0].value)
      this.pathStrings.push(`[${flowNode?.name ?? 'Unknown Node'}]`)
    } else {
      super.as_visitFunctionCallNode_(node)
    }
  }

  as_processVariable_(variable) {
    this.pathStrings.push(variable)
    super.as_processVariable_(variable)
  }

  as_visitPropertyLookupNode_(node) {
    super.as_visitPropertyLookupNode_(node)
    if (node.property !== 'get') {
      this.pathStrings.push(`${node.property}`)
    }
  }

  as_visitLiteralValueNode_(node) {
    this.pathStrings.push(`${node.value}`)
    super.as_visitLiteralValueNode_(node)
  }

  as_visitNode_(node) {
    super.as_visitNode_(node)
  }
}

export class ExpressionTokenDisplayVisitor extends ASFormattingVisitor {
  constructor(flow) {
    super()
    this.pathStrings = []
    this.color = ''
    this.flow = flow
  }

  get pathString() {
    return this.pathStrings.join(' ')
  }

  as_visitFunctionCallNode_(node) {
    super.as_visitFunctionCallNode_(node)
  }

  as_processVariable_(variable) {
    this.pathStrings.push(variable)
    this.color = 'purple'
    super.as_processVariable_(variable)
  }

  as_visitPropertyLookupNode_(node) {
    super.as_visitPropertyLookupNode_(node)
    if (node.property !== 'get') {
      this.pathStrings.push(`${node.property}`)
      this.color = 'purple'
    }
  }

  as_visitLiteralValueNode_(node) {
    this.pathStrings.push(`${node.value}`)
    super.as_visitLiteralValueNode_(node)
  }

  as_visitNode_(node) {
    super.as_visitNode_(node)
  }

  as_visit_(node) {
    if(node.as_propertyAt_('tokenEnd')) {
      const name = node.property ?? node.arguments?.[0].name ?? node.arguments?.[0].value
      const stepId = lookupStepId(node)
      const flowNode = this.flow.nodes.find(item => item.id === stepId)
      this.pathStrings.push(name ?? 'Unknown Node')
      this.color = flowNode?.color ?? 'red'
    } else {
      super.as_visit_(node)
    }
  }
}